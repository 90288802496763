//React
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import IngredientsPricingTab from './IngredientsPricingTab';
import IngredientsVolumeTab from './NIU_IngredientsVolumeTab';

//Files
import { current } from '../../../utils/selectors';


const ProcurementTabSelect = ({ currentTab, ...rest }) => {
  if (currentTab === 'ingredients') return <IngredientsPricingTab {...rest} />
  //if (currentTab === 'ingredients-volume') return <IngredientsVolumeTab {...rest} />  
}

const IngredientsTabs = ({ accounts, currentTab, ...rest }) => {
  const {accountId, filterProps, supplierMap, ingredients, ingredientCategories} = accounts;
  const {filters} = filterProps;

  const optionList = useSelector((state) => current(state, 'supplierOptions', accountId));
  //console.log(optionList.length, 'nbsupplierOption')

  const filteredOptions = useMemo(() => filters.length > 0 ?
  optionList.filter(o => filters.includes(supplierMap[o.supplierId].name)) : optionList,
  [optionList, filters, supplierMap]);

  const filteredIngredients = useMemo(() => filters.length > 0 ?
    ingredients.filter(i => filteredOptions.some(o => o.ingredientId === i.id)) : ingredients,
    [ingredients, filteredOptions]);
    
  const filteredCategories = (filters['ingredientCategories'] && filters['ingredientCategories'].length > 0) ?
    ingredientCategories.filter(i => filters['ingredientCategories'].indexOf(i.value) > -1) :
    ingredientCategories;

  const [category, setCategory] = useState(filteredCategories[0].value);
  
  const data = {accounts, optionList, supplierMap, ingredients, ingredientCategories, ...rest};
  
  return (
    <>
    <Pane flex="1 0 0" display="flex" flexDirection="column" minHeight={450} marginBottom={majorScale(2)}>
      <ProcurementTabSelect
          filters={filters}
          isOpen={true}
          isMobile={false}
          currentTab={currentTab}
          ings={filteredIngredients}
          {...data}
      />
    </Pane>
    </>
  )
}

export default IngredientsTabs;
