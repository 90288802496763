const FINANCIALS_LOAD_SUCCESS = 'FINANCIALS_LOAD_SUCCESS';
const FINANCIALS_LOAD_FAILURE = 'FINANCIALS_LOAD_FAILURE';
const FINANCIALS_CLEAR = 'FINANCIALS_CLEAR';
const ADD_FINANCIAL = 'ADD_FINANCIAL';
const REMOVE_FINANCIAL = 'REMOVE_FINANCIAL';
const UPDATE_FINANCIAL = 'UPDATE_FINANCIAL';

export const actionTypes = {
  FINANCIALS_LOAD_SUCCESS,
  FINANCIALS_LOAD_FAILURE,
  FINANCIALS_CLEAR,
  ADD_FINANCIAL,
  REMOVE_FINANCIAL,
  UPDATE_FINANCIAL
};


const financialsLoadSuccess = (payload, account) => {
  return {
    type: FINANCIALS_LOAD_SUCCESS,
    payload,
    account
  };
};

const financialsLoadFailure = (error) => {
  return {
    type: FINANCIALS_LOAD_FAILURE,
    error
  };
};

const financialsClear = () => {
  return {
    type: FINANCIALS_CLEAR
  };
};

const addFinancial = (accountId, financialInfo, callback) => {
  return {
    type: ADD_FINANCIAL,
    financialInfo,
    callback,
    accountId,
  };
};

const removeFinancial = (accountId, financialId) => {
  return {
    type: REMOVE_FINANCIAL,
    accountId,
    financialId,
  };
};

const updateFinancial = (accountId, financialInfo, callback) => {
  return {
    type: UPDATE_FINANCIAL,
    financialInfo,
    accountId,
    callback,
  };
};

export const actions = {
  financialsLoadSuccess,
  financialsLoadFailure,
  financialsClear,
  addFinancial,
  removeFinancial,
  updateFinancial
};
