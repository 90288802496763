//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { Dialog, SelectField, Pane, majorScale } from 'evergreen-ui';

//Components
import CustomDialog from '../../../components/Dialog/Dialog';

const AreasTabDialog = ({
  addingDept,
  setAddingDept,
  updateChildDepartment,
  setErrors,
  setEditingDept,
  editingDept,
  areaCats,
  childDeptArea,
  setChildDeptArea
}) => {
  const confirmWord = editingDept ? 'Edit' : 'Add';
  return (
    <CustomDialog
      isOpen={addingDept}
      title={`${confirmWord} Area`}
      confirmLabel={confirmWord}
      onConfirm={() => {
        if (childDeptArea) {
          updateChildDepartment()
          if (setAddingDept) setAddingDept(false);
          if (setChildDeptArea) setChildDeptArea(false);
          if (setEditingDept) setEditingDept(null);
        }
        else {
          setErrors((prevValues) => ({ ...prevValues, newDept: 'You must select an area'}));
        }
      }}
      onClose={() => {
        if (setAddingDept) setAddingDept(false);
        if (setChildDeptArea) setChildDeptArea(false);
        if (setEditingDept) setEditingDept(null);
      }}
    >
      <Pane marginBottom={majorScale(3)}>
      <SelectField
          flex="1 1 0"
          label="Area name"
          placeholder="Select from areas ..."
          value={childDeptArea || ''}
          onChange={(e) => setChildDeptArea(e.target.value)}
      >
        <option value=""></option>
        {areaCats.map((category) => (<option key={category.id} value={category.name}>{category.name}</option>))}
      </SelectField>
      </Pane>
    </CustomDialog>
  );
}

AreasTabDialog.propTypes = {
  addingDept: PropTypes.bool,
  editingDept: PropTypes.bool,
  setChildDeptArea: PropTypes.func.isRequired,
  setAddingDept: PropTypes.func,
  childDeptArea: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setErrors: PropTypes.func.isRequired,
  updateChildDepartment: PropTypes.func.isRequired,
  setEditingDept: PropTypes.func
}

export default AreasTabDialog;
