import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { TabNavigation as EvergreenTabNav, majorScale } from 'evergreen-ui';


const TabNavigation = (props) => {
  const { children, ...otherProps } = props;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <EvergreenTabNav
        display="flex"
        flexFlow={`row ${isMobile ? 'wrap' : 'nowrap'}`}
        flexShrink={0}
        width="100%"
        overflowX="auto"
        paddingBottom={majorScale(2)}
        {...otherProps}
    >
      {children}
    </EvergreenTabNav>
  );
};

export default TabNavigation;