//React + Libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Card, Heading, Paragraph, TextInputField, minorScale, Text } from 'evergreen-ui';
import { v4 as uuidv4 } from 'uuid';

//Files
import { accountTypes, APP_TITLE, colors } from '../../utils/constants';
import { actions } from '../../store/actions';
import useAuth from '../../hooks/useAuth';

//Components
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import Accounts from './Accounts';
import CustomDialog from '../../components/Dialog/Dialog';
import CustomHeading from '../../components/Headings/Headings';

const analyticsPlan = {
  type: 'analyticsPlan',
  name: 'Analytics plan',
  cost: 30,
  nbLocations: 1
}

const AccountSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasOwnership } = useAuth();

  const isMobile = useMediaQuery({ maxWidth: 460 });
  const headAccounts = useSelector((state) => _.filter(state.accounts, { 'type': accountTypes.OWNER }));
  const locationAccounts = useSelector((state) => _.filter(state.accounts, { 'type': accountTypes.LOCATION }));
  const supplierAccounts = useSelector((state) => _.filter(state.accounts, { 'type': accountTypes.SUPPLIER }));
  const newRegister = locationAccounts.length === 0 && headAccounts.length === 0;
  const profile = useSelector((state) => state.profile);

  const [addingAccount, setAddingAccount] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const setProfileAccount = (accountId) => dispatch(actions.profile.profileSetAccount(accountId));

  const validateAccountName = (accountName) => {
    if (_.isEmpty(accountName)) {
      setValidationErrors({
        'accountName': 'Group name cannot be empty'
      });
      return false;
    }

    setValidationErrors({});
    return true;
  };

  const callback = (account) => {
    setProfileAccount(account.id);
    navigate('/account', { tab: 'billing' });
  }

  const createAccount = (accountName) => {
    if (_.isEmpty(accountName)) {
      return;
    }

    const uuid = uuidv4();
    const userInvoiceEmail = `${uuid}@misenplace.ai`;

    dispatch(actions.accounts.accountCreate(
      {
        name: accountName,
        ownerName: profile.name,
        ownerEmail: profile.email,
        ownerId: profile.id,
        type: accountTypes.OWNER,
        plan: analyticsPlan,
        userInvoiceEmail: userInvoiceEmail,
      },
      callback
    ));
  };

  if (newRegister && !profile) return null;

  const WelcomeBlock = () => {

    return (
      <Pane marginBottom={majorScale(4)}>
        <Heading
          is="h1"
          marginBottom={majorScale(2)}
          size={800}
          fontSize={36}
        >Welcome to Misenplace, {profile.name}!
        </Heading>
        <Paragraph marginBottom={majorScale(2)}>Select the head office or location you’d like to access.</Paragraph>
        <Paragraph marginBottom={majorScale(4)}>If no accounts are available, please contact your <strong>organisation</strong> or <strong>support@misenplace.ai</strong></Paragraph>
        
        {newRegister && (
          <Pane marginTop={majorScale(2)} display='flex' alignItems='center'>
            <Text fontSize={24} fontWeight='bold'>New to Misenplace?</Text>
            <Text textDecoration='underline' fontSize={24} color={colors.orange} cursor='pointer' marginLeft={minorScale(2)} appearance="minimal" onClick={() => setAddingAccount(true)}>Create a new head office</Text>
          </Pane>
        )}
      </Pane>
    )
  }

  return (
    <Page title={APP_TITLE}>
      <Block padding={majorScale(4)} width='90vw'>
        <WelcomeBlock />
        {!_.isEmpty(headAccounts) && !isMobile && (
          <Accounts
            label='Head offices'
            accounts={headAccounts}
            setProfileAccount={setProfileAccount}
            setAddingAccount={setAddingAccount}
            hasOwnership={hasOwnership}
            isMobile={isMobile}
            buttonName={'Access head office'}
          />
        )}

        {!_.isEmpty(supplierAccounts) && !isMobile && (
          <Accounts
            label='Supplier accounts'
            accounts={supplierAccounts}
            setProfileAccount={setProfileAccount}
            setAddingAccount={setAddingAccount}
            hasOwnership={hasOwnership}
            isMobile={isMobile}
            buttonName={'Access supplier account'}
          />
        )}
        {!newRegister && (
          <Pane>
            <CustomHeading level="3" marginBottom={majorScale(2)}>Locations</CustomHeading>
            <Pane
                display="grid"
                gridTemplateColumns={(isMobile) ? 'auto' : 'repeat(3, auto)'}
                gap={majorScale(2)}
                marginBottom={majorScale(2)}
            >
              {_.map(locationAccounts, (account) => (
                <Card
                    key={account.id}
                    border="default"
                    padding={minorScale(2)}
                >
                  <Pane
                      padding={minorScale(2)}
                  >
                    <CustomHeading level="4">{account.name}</CustomHeading>
                  </Pane>
                  <Pane padding={minorScale(2)}>
                    <Button
                        appearance="primary"
                        onClick={() => 
                            setProfileAccount(account.id)}
                    >Access location</Button>
                  </Pane>
                </Card>
              ))}
            </Pane>
          </Pane>
        )}       
      </Block>

      <CustomDialog
            isOpen={addingAccount}
            title="Create a new head office"
            onClose={() => {
              setAddingAccount(false);
              setNewAccountName('');
            }}
            onConfirm={() => {
              if (validateAccountName(newAccountName)) {
                createAccount(newAccountName);
                setAddingAccount(false);
                setNewAccountName('');
              }
            }}
            confirmLabel="Create"
            intent="success"
        >
          <Pane>
            <TextInputField
                required
                label="Head office name"
                onChange={(e) => setNewAccountName(e.target.value)}
                isInvalid={!_.isEmpty(validationErrors['accountName'])}
                validationMessage={validationErrors['accountName']}
            />
          </Pane>
          <Pane marginBottom={majorScale(3)}>
            <Paragraph>By clicking “Create” above, you acknowledge that you agree to be enrolled automatically to Misenplace’s free “Start” plan.</Paragraph>
          </Pane>
        </CustomDialog>
    </Page>
  );
};

export default AccountSelect;
