//React
import React, { createContext, useState, useContext } from 'react';

//Libraries
import moment from 'moment';

const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(moment.utc().startOf('isoWeek'));
  const [endDate, setEndDate] = useState(moment.utc().endOf('isoWeek'));
  // Ensure proper logging
  console.log('Start Date (UTC):', startDate.format(), 'End Date (UTC):', endDate.format());


  const setWeek = (start, end) => {
    setStartDate(moment.utc(start));
    setEndDate(moment.utc(end));
  };

  const setDay = (day) => {
    const startOfDay = moment.utc(day).startOf('day');
    const endOfDay = moment.utc(day).endOf('day');
    setStartDate(startOfDay);
    setEndDate(endOfDay);
  };

  const setHour = (hour) => {
    const startOfHour = moment.utc(hour).startOf('hour');
    const endOfHour = moment.utc(hour).endOf('hour');
    setStartDate(startOfHour);
    setEndDate(endOfHour);
  };

  return (
    <DateContext.Provider value={{ startDate, endDate, setWeek, setDay, setHour }}>
      {children}
    </DateContext.Provider>
  );
};

export const useDate = () => useContext(DateContext);
