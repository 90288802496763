//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Pane, majorScale, Heading } from 'evergreen-ui';

//Components
import DataTable from '../../../../components/DataTable/DataTable';
import FilterBar  from '../../../../components/FilterBar/FilterBar';
import CustomDialog from '../../../../components/Dialog/Dialog';

//Files
import { generateSearchFieldsFn} from '../../../../utils/functions';
import { selectNonDeletedModifiers } from '../../../../utils/selectors';


const tableHeaders = [
  { label: 'Name', field: 'name', type: 'actionField', action: 'select', width: 5  },
  { label: 'Category', field: 'category', type: 'field', width: 3 },
];

const EditModifierDialog = ({ accountId, onClose, isShown, onConfirm, existingModifier }) => {
  const modifierList = useSelector(state => _.sortBy(selectNonDeletedModifiers(state, accountId), 'name'));
  const [selectedModifier, selectModifier] = useState(existingModifier);
  const [filters, setFilters] = useState({});

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };
  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  const dialogConfirm = (close) => {
    onConfirm({ ...selectedModifier });
    close();
  };

  const dialogClose = () => {
    selectModifier(false);
    searchOnChange('');
    if (onClose) {
      onClose();
    }
  };

  return (
    <CustomDialog
        isOpen={isShown}
        hasHeader={false}
        intent="none"
        confirmLabel={'Add Modifier'}
        onConfirm={dialogConfirm}
        onClose={dialogClose}
        onCloseComplete={dialogClose}
        preventBodyScrolling
        width={700}
    >
      <Pane>
        <Heading>Modifier</Heading>
        <Pane display="flex" flexDirection="column">
          <FilterBar searchPlaceholder="Search Modifiers" searchOnChange={searchOnChange} paddingY={majorScale(2)} />
          <DataTable filters={filters} headers={tableHeaders} items={modifierList} onSelect={(ing) => selectModifier(ing)} columnProps={{justifyContent: 'flex-start'}} />
        </Pane>
      </Pane>
    </CustomDialog>
  );
};

EditModifierDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  isShown: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  existingModifier: PropTypes.object
};


export default EditModifierDialog;
