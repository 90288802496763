import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Pane, Button, TextInputField } from 'evergreen-ui';

import Page from '../../components/Page/Page';
import { actions } from '../../store/actions';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';


const SectionEdit = (props) => {
  const { sectionId } = props;
  const dispatch = useDispatch();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const section = useSelector((state) => {
    if (sectionId) {
      return _.find(state.sections, { 'id': sectionId });
    }
    return false;
  });
  const [sectionInfo, setSectionInfo] = useState({});
  const pageTitle = (section) ? section.name : 'New Section';
  requiresWorkingAccount();

  const onFieldChange = (field, newValue) => {
    setSectionInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };
  const saveSection = () => {
    if (section) {
      dispatch(actions.sections.updateSection(sectionInfo));
    }
    else {
      dispatch(actions.sections.addSection(sectionInfo));
    }
  };

  useEffect(() => {
    if (_.isEmpty(sectionInfo) && section) {
      setSectionInfo(section);
    }
  }, [sectionInfo, section]);

  return (
    <Page title={pageTitle}>
      <TextInputField
          required
          label="Name"
          value={sectionInfo.name || ''}
          onChange={(e) => onFieldChange('name', e.target.value)}
      />
      <Pane display="flex" justifyContent="center">
        <Button appearance="primary" onClick={() => saveSection()}>{(section) ? 'Update' : 'Add'} Section</Button>
      </Pane>
    </Page>
  );
};

SectionEdit.propTypes = {
  sectionId: PropTypes.string
};

SectionEdit.defaultProps = {
  sectionId: ''
};

export default SectionEdit;
