//React
import React from 'react';
import { useLocation, Link } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Sales from './SalesComponents/Sales';
import LocationSales from './SalesComponents/LocationSales';
// import DepartmentSales from './SalesComponents/DepartmentSales';
import ProductSales from './SalesComponents/ProductSales';

import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import PeriodNavigation from './components/PerdiodNavigation';
import ReportFilters from './components/ReportFilters';

//Files
import { buildAccountsData, buildAggregatedSalesData, buildWeekDates } from '../../utils/reports';


const tabs = {
  overview: 'Overview',
  locations: 'Locations',
  // departments: 'By department',
  products: 'Products sales'
};

const SalesTabs = ({ accounts, weekProps, filterProps }) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/')[3] || 'overview';
  // Safety check for weekProps
  if (!weekProps || !weekProps.weekInfo) {
    console.error('weekProps is not properly initialized');
    return <div>Loading...</div>; // or any other fallback UI
  }
  const weekdates = buildWeekDates(weekProps.weekInfo.start);
  console.log(weekdates, weekProps, "weekdates")
  const newAccounts = {...accounts, filterProps }
  const accountsData = buildAccountsData({accounts: newAccounts, options: { sales: true }, ...weekdates});
  console.log(accountsData)
  const sales = buildAggregatedSalesData(accountsData);
  console.log(sales)
  const data = {accounts: newAccounts, accountsData, sales, weekdates};
  console.log(data)

  const tabComponents = {
    overview: <Sales {...data} type={currentTab} />,
    locations: <LocationSales {...data} type={currentTab} limit={false} />,
    "products": <ProductSales {...data} type={currentTab} limit={false} />,
  };

  return (
    <>
      <TabNavigation paddingBottom={majorScale(2)}>
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/analytics/sales/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>
      <PeriodNavigation 
          weekInfo={weekProps.weekInfo}
          seeNextWeek={weekProps.seeNextWeek}
          seePrevWeek={weekProps.seePrevWeek}
      />
      <ReportFilters accounts={newAccounts} />
      {tabComponents[currentTab]}
    </>
  );
};

SalesTabs.propTypes = {
  accounts: PropTypes.object.isRequired,
  weekProps: PropTypes.object.isRequired
};

export default SalesTabs;
