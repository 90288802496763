import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import config from './firebaseConfig';

firebase.initializeApp(config);
const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore();
firestore.settings({});
const serverTime = firebase.firestore.FieldValue.serverTimestamp;

export default {
  auth,
  storage,
  firestore,
  serverTime,
  FieldValue: firebase.firestore.FieldValue,
};
