const ORDER_ATTACHMENTS_LOAD_SUCCESS = 'ORDER_ATTACHMENTS_LOAD_SUCCESS';
const ORDER_ATTACHMENTS_LOAD_FAILURE = 'ORDER_ATTACHMENTS_LOAD_FAILURE';
const ORDER_ATTACHMENTS_CLEAR = 'ORDER_ATTACHMENTS_CLEAR';
const UPDATE_ORDER_ATTACHMENTS = 'UPDATE_ORDER_ATTACHMENTS';

export const actionTypes = {
  ORDER_ATTACHMENTS_LOAD_SUCCESS,
  ORDER_ATTACHMENTS_LOAD_FAILURE,
  ORDER_ATTACHMENTS_CLEAR,
  UPDATE_ORDER_ATTACHMENTS
};


const orderAttachmentsLoadSuccess = (payload, account) => {
  return {
    type: ORDER_ATTACHMENTS_LOAD_SUCCESS,
    payload,
    account
  };
};

const orderAttachmentsLoadFailure = (error) => {
  return {
    type: ORDER_ATTACHMENTS_LOAD_FAILURE,
    error
  };
};

const orderAttachmentsClear = () => {
  return {
    type: ORDER_ATTACHMENTS_CLEAR
  };
};

const updateOrderAttachments = (accountId, orderId, attachments, callback) => {
  return {
    type: UPDATE_ORDER_ATTACHMENTS,
    orderId,
    accountId,
    attachments,
    callback
  };
};

export const actions = {
  orderAttachmentsLoadSuccess,
  orderAttachmentsLoadFailure,
  orderAttachmentsClear,
  updateOrderAttachments
};
