//React + Firebase
import React, { useState } from 'react';
import fb from 'firebase/app';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

//Libraries
import { minorScale, Pane, Text, Paragraph, TextInputField, Alert, majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';

//Files
import firebase from '../../store/firebase';
import { actions } from '../../store/actions';
import { messageTypes } from '../../utils/constants';


const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});

  const onFieldChange = (field, newValue) => {
    if (errorMsg) {
      setErrorMsg(false);
    }
    setLoginInfo((prevValues) => {
      return { ...prevValues, [field]: newValue };
    });
  }

  const signIn = () => {
    const { email, password } = loginInfo;
    if (!email) {
      setErrorMsg('Please enter a valid email address');
      return;
    }
    if (!password) {
      setErrorMsg('Please enter your password');
      return;
    }
    firebase.auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        dispatch(actions.applicationLoading());
        navigate('/', true);
      })
      .catch((err) => {
        setErrorMsg(err.message);
        console.log(err);
      });
  };

  const signInWithGoogle = () => {
    firebase.auth.signInWithPopup(new fb.auth.GoogleAuthProvider())
      .then(() => {
        dispatch(actions.applicationLoading());
        navigate('/', true);
      })
      .catch((err) => {
        setErrorMsg(err.message);
        console.log(err);
      });
  };

  const doForgotPassword = () => {
    if (!loginInfo.email) {
      dispatch(actions.appMessageSet('Please enter your email address', messageTypes.WARNING));
      return;
    }
    firebase.auth.sendPasswordResetEmail(loginInfo.email)
      .then(() => {
        dispatch(actions.appMessageSet('Email with password reset link has been sent', messageTypes.SUCCESS));
      })
      .catch((err) => {
        dispatch(actions.appMessageSet(err.message, messageTypes.ERROR));
      });
  }


  return (
    <Page top="0" display="flex" justifyContent="center" alignItems="center" width="100%">
      <Block margin={majorScale(2)} padding={majorScale(3)} width={600} maxWidth={940} >
        <Pane textAlign="initial" marginBottom={minorScale(4)}>
          <CustomHeading level="1" marginBottom={majorScale(2)} textAlign='center'>Sign in</CustomHeading>
          <Pane width={300} marginX='auto' marginY={majorScale(2)}>
            <TextInputField
                required
                type="email"
                label="Email address"
                placeholder="Enter your email address"
                value={loginInfo.email || ''}
                onChange={(e) => onFieldChange('email', e.target.value)}
            />
            <TextInputField
                required
                type="password"
                label="Password"
                placeholder="Enter your password"
                value={loginInfo.password || ''}
                onChange={(e) => onFieldChange('password', e.target.value)}
            />
            <Pane marginBottom={majorScale(2)} width='100%'>
              <Button width='100%' appearance="primary" onClick={signIn} marginBottom={minorScale(4)}>Sign in</Button>
              <Pane marginBottom={minorScale(4)}>
                <Pane borderBottom={'1px solid black'}>
                </Pane>
              </Pane>
              <Pane marginBottom={minorScale(4)}>
                <Button width='100%' appearance="primary" onClick={signInWithGoogle}>Sign in with Google</Button>
              </Pane>
            </Pane>
            <Pane marginBottom={majorScale(2)} display='flex' alignItems='center' justifyContent='space-between'>
              <Link to='/signup'><Text>Don't have an account?</Text></Link>
              <Text  onClick={doForgotPassword} textDecoration='underline' cursor='pointer'>Forgot password?</Text>
            </Pane>
          </Pane>
          <Paragraph textAlign="center" color="rgba(0, 0, 0, 0.5)" margin={minorScale(2)}>By clicking Sign in with Email or Google” above, you acknowledge that you agree to Misenplace's Terms & Conditions.</Paragraph>
          {errorMsg && <Alert intent="danger" appearance="card" marginBottom={minorScale(4)}>{errorMsg}</Alert>}
        </Pane>
      </Block>
    </Page>
  );
};

export default SignIn;
