const AREAS_LOAD_SUCCESS = 'AREAS_LOAD_SUCCESS';
const AREAS_LOAD_FAILURE = 'AREAS_LOAD_FAILURE';
const AREAS_CLEAR = 'AREAS_CLEAR';
const ADD_AREA = 'ADD_AREA';
const REMOVE_AREA = 'REMOVE_AREA';
const UPDATE_AREA = 'UPDATE_AREA';

export const actionTypes = {
  AREAS_LOAD_SUCCESS,
  AREAS_LOAD_FAILURE,
  AREAS_CLEAR,
  ADD_AREA,
  REMOVE_AREA,
  UPDATE_AREA
};


const areasLoadSuccess = (payload, account) => {
  return {
    type: AREAS_LOAD_SUCCESS,
    payload,
    account
  };
};

const areasLoadFailure = (error) => {
  return {
    type: AREAS_LOAD_FAILURE,
    error
  };
};

const areasClear = () => {
  return {
    type: AREAS_CLEAR
  };
};

const addArea = (accountId, areaInfo, callback) => {
  return {
    type: ADD_AREA,
    areaInfo,
    callback,
    accountId,
  };
};

const removeArea = (accountId, areaId) => {
  return {
    type: REMOVE_AREA,
    accountId,
    areaId,
  };
};

const updateArea = (accountId, areaInfo, callback) => {
  return {
    type: UPDATE_AREA,
    areaInfo,
    accountId,
    callback,
  };
};

export const actions = {
  areasLoadSuccess,
  areasLoadFailure,
  areasClear,
  addArea,
  removeArea,
  updateArea
};
