//React
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

//Libraries
import _ from "lodash";
import moment from "moment";
import { FilePicker, majorScale, minorScale, Pane, Text, TextInputField, useTheme } from "evergreen-ui";

//Components
import Block from "../../../components/ui/Block/Block";
import Button from "../../../components/ui/Button/Button";
import CustomHeading from "../../../components/Headings/Headings";
import IconWrapper from "../../../components/Icons/Icons";

//Files
import { actions } from "../../../store/actions";
import { invoiceUploadStatuses } from "../../../utils/constants";
import { requestInvoiceUploadUrl } from "../../../utils/functions";


const UploadNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const theme = useTheme();
  const [attachments, setAttachments] = useState([]);
  const [invoiceInfo, setInvoiceInfo] = useState({
      date: moment().toDate(),
      supplierId: null,
      invoiceNumber: null
  });

  const onFieldChange = (fieldName, newValue) => {
    setInvoiceInfo({ ...invoiceInfo, [fieldName]: newValue });
  };

  const addAttachments = (files) => {
    setAttachments(() => {
      return [
        ...(_.map(files, (file) => ({ file })))
      ]
    });
  };

  const isValid = () => {
    const {invoiceNumber} = invoiceInfo;

    if (!invoiceNumber || invoiceNumber.trim('') === '') return false;

    if (_.isEmpty(attachments)) return false;

    return true;
  }

  const submitInvoice = () => {
    if (!isValid()) {
      dispatch(actions.appMessageError('Upload not valid'))
    }

    dispatch(actions.invoiceUploads.addInvoiceUpload(accountId, {
      ...invoiceInfo,
      status: invoiceUploadStatuses.SUBMITTED,
      date: moment(invoiceInfo.date).format('YYYY-MM-DD')
    }, (id, upload) => {
      const callback = async(filename) => {
        const public_url = await requestInvoiceUploadUrl(filename);
        dispatch(actions.invoiceUploads.updateInvoiceUpload(accountId, {
          ...upload,
          id,
          public_url
        }));
      }
      // We save the attachments in the callback as we don't have an orderId before then!
      dispatch(actions.invoiceUploads.updateInvoiceUploadAttachments(accountId, id, upload, attachments, callback));
      navigate(`/${accountId}/procurement/uploads`);
    }));
  }


  return (
    <React.Fragment>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          height={48}
      >
        <IconWrapper
          appearance="clickable"
          name="arrowLeft"
          onClick={() => window?.history.back()}
          marginRight={majorScale(2)}
        />
        <CustomHeading level="3" >Upload file</CustomHeading>
      </Block>
      <Block>
        <Pane padding={minorScale(4)}>
          <Pane display='flex' flex={1} justifyContent={'space-between'} paddingX={majorScale(1)}>
            <Text color={theme.colors.warning}>
              If you take a picture, please ensure that: a) the picture is taken straight b) all corners and data is visible c) there is a good light
            </Text>
            <Button appearance="primary" padding={minorScale(2)} onClick={submitInvoice}  >Send</Button>
          </Pane>
          <Pane display='flex' alignItems='center'>
            <TextInputField
              required
              label="Invoice number"
              placeholder={'Please add'}
              value={invoiceInfo.invoiceNumber || ''}
              padding={minorScale(2)}
              onChange={(e) => onFieldChange('invoiceNumber',  e.target.value )}
            />
          </Pane>
          <FilePicker
            multiple
            accept="image/*;capture=camera"
            onChange={(files) => addAttachments(files)}
            width={260}
            padding={minorScale(2)}
          />
        </Pane>
      </Block>
    </React.Fragment>
  )
}

export default UploadNew;
