//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { Pane, majorScale, Text } from 'evergreen-ui';

//Components
import Block from '../../../../components/ui/Block/Block';
import CustomHeading from '../../../../components/Headings/Headings';
import DataTable from '../../../../components/DataTable/DataTable';

const customHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 8 },
];

const RecipeUsedIn = ({ usageInfo }) => {

  const renderSection = (title, items) => (
    <Block marginBottom={majorScale(4)} padding={majorScale(2)}>
      <CustomHeading level="3" marginBottom={majorScale(2)}>{title}</CustomHeading>
      <Pane marginBottom={majorScale(2)}>
        {items.length > 0 ? (
          <DataTable
            items={items}
            headers={customHeaders}
            filters={[]}
          />
        ) : (
          <Text>No items found</Text>
        )}
      </Pane>
    </Block>
  );

  return (
    <Pane marginBottom={majorScale(2)}>
      {usageInfo.menusUsingRecipe.length > 0 && renderSection('Menus using this recipe', usageInfo.menusUsingRecipe)}
      {usageInfo.recipesUsingRecipe.length > 0 && renderSection('Recipes using this sub-recipe', usageInfo.recipesUsingRecipe)}
      {usageInfo.modifiersUsingRecipe.length > 0 && renderSection('Modifiers using this recipe', usageInfo.modifiersUsingRecipe)}
    </Pane>
  );
};

RecipeUsedIn.propTypes = {
  usageInfo: PropTypes.shape({
    menusUsingRecipe: PropTypes.array.isRequired,
    recipesUsingRecipe: PropTypes.array.isRequired,
    modifiersUsingRecipe: PropTypes.array.isRequired,
  }).isRequired,
};

export default RecipeUsedIn;
