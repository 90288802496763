//React
import React from 'react';
import { Link, useLocation, Routes, Route, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, useTheme} from 'evergreen-ui';


//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../components/ui/Tab/Tab';
import DatabaseEdit from './SupplierEditComponents/DatabaseEdit';
import DetailsEdit from './SupplierEditComponents/DetailsEdit';
import UnapprovedEdit from './SupplierEditComponents/UnapprovedEdit';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { current } from '../../utils/selectors';


const SupplierEdit = () => {
  const { supplierId } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const account = useSelector((state) => state.accounts[state.currentAccount]);
  
  const supplier = useSelector((state) => {
    if (supplierId) {
      return _.find(current(state, 'suppliers'), { 'id': supplierId });
    }
    return false;
  });

  const currentTab = location.pathname.split('/')[3] || 'details';
  const pageTitle = (supplier) ? supplier.name : 'New Supplier';
  const hasUnaproved = useSelector(state => current(state, 'supplierOptions').findIndex(s => s.un_verified) > -1)
  
  const supplierTabs = {
    'details': 'Supplier Details',
    'database': 'Supplier Database',
  };

  if (hasUnaproved && account.type && account.type === 'SUPPLIER') {
    supplierTabs['unapproved'] = 'Unapproved items';
  }

  return (
    <Page title={pageTitle}>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginBottom={majorScale(2)}
          height={48}
      >
        <IconWrapper
            appearance="primary"
            is={Link}
            to="/suppliers"
            name="arrowLeft"
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3" flex="1 0 auto">{(supplierId) ? supplier?.name : 'New Supplier'}</CustomHeading>
      </Block>

      {supplierId && (
        <TabNavigation
            overflowX="auto"
            paddingBottom={majorScale(2)}
        >
          {_.map(supplierTabs, (tabLabel, tabKey) => (
            <Tab
                key={tabKey}
                is={Link}
                to={`/suppliers/${supplierId}/${tabKey}`}
                isSelected={currentTab === tabKey}
                onSelect={() => console.log(`Navigating to /suppliers/${supplierId}/${tabKey}`)}
            >{tabLabel}</Tab>
          ))}
        </TabNavigation>
      )}
      <Routes>
        <Route path="database" element={<DatabaseEdit supplierId={supplierId} />} />
        <Route path="unapproved" element={<UnapprovedEdit supplierId={supplierId} />} />
        <Route path="details" element={<DetailsEdit supplierId={supplierId} />} />
        <Route path="/" element={<DetailsEdit supplierId={supplierId} />} />
      </Routes>
    </Page>
  );
};

SupplierEdit.propTypes = {
  supplierId: PropTypes.string,
  // tab: PropTypes.oneOf(_.keys(supplierTabs)),
};

SupplierEdit.defaultProps = {
  supplierId: '',
  tab: 'details',
};

export default SupplierEdit;
