//React
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, Table, useTheme } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page'
import Block from '../../components/ui/Block/Block';
import Comment from '../../components/Comment/Comment';
import TaskCommentList from '../../components/TaskCommentList/TaskCommentList';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { colors, orderStatuses } from '../../utils/constants';
import { datePeriodFormat, currency } from '../../utils/format';
import {  getOrdersForPeriod, getSalesForPeriod, calcStocktakeCost, getStocktakeDate, getWastageDate, calcPercentage, 
    castFloat, periodTransferCosts, getProcurementTotal } from '../../utils/functions';
import { current, selectReportIncidentals } from '../../utils/selectors';
import { getWC, getWE } from '../../utils/time';
import { actions } from '../../store/actions';
import { calculcateWeekTheoreticalStock } from '../../utils/stock';


const ReportPeriodDetail = () => {
  const { accountId, periodStart, periodEnd } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const startDate = moment.utc(periodStart, 'YYYY-MM-DD');
  const endDate = moment.utc(periodEnd, 'YYYY-MM-DD');
  const periodFormatted = datePeriodFormat(startDate.toDate(), endDate.toDate());
  //console.log(periodFormatted)
  const periodSales = getSalesForPeriod(accountId, startDate, endDate);
  const periodWastageCost = useSelector((state) => _.sumBy(
    _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      if (!wastageDate) {
        // Ignore any wastage records without a date
        return false;
      }
      return (moment(wastageDate).isBetween(startDate, endDate, 'day', '[]'));
    }),
    (wastage) => ( (isNaN(wastage.cost)) ? 0 : wastage.cost )
  ));
  const openingStocktake = useSelector((state) => {
    return _.find(_.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }), (stocktake) => (moment(getStocktakeDate(stocktake)).isSame(periodStart, 'day')))
  });
  const closingStocktake = useSelector((state) => {
    return _.find(_.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }), (stocktake) => (moment(getStocktakeDate(stocktake)).isSame(moment(periodEnd).add(1, 'day'), 'day')))
  });
  const openingStocktakeCost = calcStocktakeCost(openingStocktake);
  const closingStocktakeCost = calcStocktakeCost(closingStocktake);
  const theoreticalStock = calculcateWeekTheoreticalStock(startDate, endDate, accountId);
  const stockVariance = closingStocktakeCost - theoreticalStock.expectedStockValue;
  const allowance = useSelector((state) => {
    const incidentalStart = getWC(startDate.toDate());
    const periodWeekEnd = getWE(endDate.toDate());
    const incidentalEnd = (endDate.isSame(periodWeekEnd, 'day')) ? periodWeekEnd : getWE(moment(periodEnd, 'YYYY-MM-DD').add(-1, 'week').toDate());
    return _.sumBy(
      _.filter(
        current(state, 'reportsIncidentals', accountId),
        (incidentals) => (moment(incidentals.weekOf, 'YYYY-MM-DD').isBetween(incidentalStart, endDate, 'day', '[]'))
      ),
      (item) => castFloat(item.stockAllowances)
    );
  });
  const comments = useSelector((state) => _.sortBy(
    _.filter(
      current(state, 'reportsComments', accountId),
      (comment) => comment.reportDate === periodStart
    ),
    'created'
  ));
  const userInfo = useSelector((state) => ({ name: state.profile.name, uid: state.profile.uid }));
  // const ordersTotal = _.sumBy(periodOrders, (order) => calcOrderTotal(order));
  const reportIncidentals = useSelector((state) => selectReportIncidentals({ state, accountId, startDate }));
  const weekTransfers = periodTransferCosts(accountId, startDate, endDate);
  const weekOrders = getOrdersForPeriod(accountId, startDate, endDate, orderStatuses.RECEIVED);
  const procurementTotal = getProcurementTotal(weekOrders, weekTransfers, reportIncidentals);
  const salesCost = _.sumBy(periodSales, 'totalcost');
  const salesNet = _.sumBy(periodSales, 'totalnet');
  const expectedGross = salesNet - salesCost;
  const expectedGP = calcPercentage(expectedGross, salesNet, 1);
  const expectedGrossWA = salesNet - salesCost - allowance;
  const expecteGPWA = calcPercentage(expectedGrossWA, salesNet, 1);
  const actualGross = salesNet - openingStocktakeCost - procurementTotal + closingStocktakeCost;
  const actualGP = calcPercentage(actualGross, salesNet, 1);
  const varianceGross = actualGross - expectedGross;
  const varianceGP = (actualGP - expectedGP).toFixed(2);
  const reconciliation = varianceGross.toFixed(2) - (stockVariance - periodWastageCost - allowance).toFixed(2);
  const onCommentSave = (newComment) => {
    // Include common bits of info
    const commentInfo = {
      ...newComment,
      reportDate: periodStart,
      uid: userInfo.uid,
      userName: userInfo.name,
    };
    // Do the save
    dispatch(actions.reportsComments.addReportComment(accountId, commentInfo));
  };

  return (
    <React.Fragment>
      <Page>
      <Block
          display="flex"
          alignItems="center"
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginBottom={majorScale(2)}
          height={48}
          background={theme.colors.offwhite}
      >
        <IconWrapper
            appearance="clickable"
            is={Link}
            to={`/${accountId}/reports/gross-profit`}
            name="arrowLeft"
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3" >Period Report - {periodFormatted.from} to {periodFormatted.to}</CustomHeading>
      </Block>

      <Block
          padding={majorScale(2)}
          marginBottom={majorScale(2)}
          display="flex"
      >
        <Pane
            flex={1}
            display="flex"
            flexDirection="column"
            marginRight={majorScale(4)}
        >
          <CustomHeading level="4" marginBottom={majorScale(2)}>Actual GP</CustomHeading>
          <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={majorScale(2)}
          >
            <Text size={600} color={colors.orange}>{actualGP} %</Text>
            <Text>or</Text>
            <Text size={600} color={colors.orange}>£ {currency(actualGross)}</Text>
          </Pane>
          <Table border="none" marginBottom={majorScale(2)}>
            <Table.Row height={48} borderBottom="none">
              <Table.TextCell borderBottom="muted">Net Sales</Table.TextCell>
              <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(salesNet)}</Table.TextCell>
            </Table.Row>
            <Table.Row height={48} borderBottom="none">
              <Table.TextCell borderBottom="muted">- Actual Cost of Sales</Table.TextCell>
              <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(openingStocktakeCost + procurementTotal - closingStocktakeCost)}</Table.TextCell>
            </Table.Row>
          </Table>

          <CustomHeading level="4" marginBottom={majorScale(2)}>Variance on profit</CustomHeading>
          <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
          >
            <Text size={600} color={colors.orange}>{varianceGP} %</Text>
            <Text>or</Text>
            <Text size={600} color={colors.orange}>£ {currency(varianceGross)}</Text>
          </Pane>
        </Pane>

        <Pane
            flex={1}
            display="flex"
            flexDirection="column"
        >
          <CustomHeading level="4" marginBottom={majorScale(2)}>Theoretical GP</CustomHeading>
          <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={majorScale(2)}
          >
            <Text size={600} color={colors.orange}>{expectedGP} %</Text>
            <Text>or</Text>
            <Text size={600} color={colors.orange}>£ {currency(expectedGross)}</Text>
          </Pane>
          <Table border="none" marginBottom={majorScale(2)}>
            <Table.Row height={48} borderBottom="none">
              <Table.TextCell borderBottom="muted">Net Sales</Table.TextCell>
              <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(salesNet)}</Table.TextCell>
            </Table.Row >
            <Table.Row height={48} borderBottom="none">
              <Table.TextCell borderBottom="muted">- Theoretical Cost of Sales</Table.TextCell>
              <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(salesCost)}</Table.TextCell>
            </Table.Row>
          </Table>
        </Pane>
      </Block>

      <Pane display="flex" marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            flexDirection="column"
            marginRight={majorScale(2)}
            width="50%"
        >
          <Block marginBottom={majorScale(2)}>
            <Pane display="flex" alignItems="center" margin={majorScale(2)}>
              <CustomHeading level="4" flex={1} marginRight={majorScale(2)}>Actual Cost of Sales</CustomHeading>
              <Text color={colors.orange} flex={1}>£ {currency(openingStocktakeCost + procurementTotal - closingStocktakeCost)}</Text>
            </Pane>
            <Table border="none" marginBottom={majorScale(2)}>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">Opening Stocktake</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(openingStocktakeCost)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">+ Procurement Balance</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(procurementTotal)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">- Closing Stocktake</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(closingStocktakeCost)}</Table.TextCell>
              </Table.Row>
            </Table>
          </Block>

          <Block padding={majorScale(2)} flex={1}>
            <CustomHeading level="4" marginBottom={majorScale(2)}>Feedback</CustomHeading>
            <TaskCommentList comments={comments} />
            <Comment onSave={(comment) => onCommentSave({ comment })} />
          </Block>
        </Pane>

        <Pane
            display="flex"
            flexDirection="column"
            marginLeft={majorScale(2)}
            width="50%"
        >
          <Block marginBottom={majorScale(2)}>
            <Pane display="flex" alignItems="center" margin={majorScale(2)}>
              <CustomHeading level="4" flex={1} marginRight={majorScale(2)}>Variance on Profit</CustomHeading>
              <Text color={colors.orange} flex={1}>£ {currency(varianceGross)}</Text>
            </Pane>
            <Table border="none" marginBottom={majorScale(2)}>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">Wastage</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(-periodWastageCost)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">Stock Variance</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(stockVariance)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">Allowance</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(-allowance)}</Table.TextCell>
              </Table.Row>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">Reconcilliation</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(reconciliation)}</Table.TextCell>
              </Table.Row>
            </Table>
          </Block>

          <Block>
            <CustomHeading level="4" margin={majorScale(2)}>Th. GP with allowances</CustomHeading>
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={majorScale(2)}
                marginX={majorScale(2)}
            >
              <Text color={colors.orange}>{expecteGPWA} %</Text>
              <Text>or</Text>
              <Text color={colors.orange}>£ {currency(expectedGrossWA)}</Text>
            </Pane>
            <Table border="none" marginBottom={majorScale(2)}>
              <Table.Row height={48} borderBottom="none">
                <Table.TextCell borderBottom="muted">Allowance</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(allowance)}</Table.TextCell>
              </Table.Row>
            </Table>
          </Block>
        </Pane>
      </Pane>
      </Page>
    </React.Fragment>
  );
};

export default ReportPeriodDetail;
