//React
import React from 'react';

//Libraries
import { Pane, UnorderedList, ListItem, majorScale } from 'evergreen-ui';

//Components
import CustomHeading from '../Headings/Headings'


const UsageInfoForDialog = ({ usageInfo }) => (
  <Pane marginBottom={majorScale(2)}>
    {usageInfo.menusUsingRecipe && usageInfo.menusUsingRecipe.length > 0 && (
      <Pane marginTop={majorScale(1)}>
        <CustomHeading level="3">Menus used in:</CustomHeading>
        <UnorderedList>
          {usageInfo.menusUsingRecipe.map((menu, index) => (
            <ListItem key={index}>{menu.name}</ListItem>
          ))}
        </UnorderedList>
      </Pane>
    )}
    {usageInfo.recipesUsingRecipe && usageInfo.recipesUsingRecipe.length > 0 && (
      <Pane marginTop={majorScale(1)}>
        <CustomHeading level="3">Recipes used in:</CustomHeading>
        <UnorderedList>
          {usageInfo.recipesUsingRecipe.map((recipe, index) => (
            <ListItem key={index}>{recipe.name}</ListItem>
          ))}
        </UnorderedList>
      </Pane>
    )}
    {usageInfo.modifiersUsingRecipe && usageInfo.modifiersUsingRecipe.length > 0 && (
      <Pane marginTop={majorScale(1)}>
        <CustomHeading level="3">Modifiers used in:</CustomHeading>
        <UnorderedList>
          {usageInfo.modifiersUsingRecipe.map((modifier, index) => (
            <ListItem key={index}>{modifier.name}</ListItem>
          ))}
        </UnorderedList>
      </Pane>
    )}
    {usageInfo.recipesUsingIngredient && usageInfo.recipesUsingIngredient.length > 0 && (
      <Pane>
        <CustomHeading level="3">Recipes used in:</CustomHeading>
        <UnorderedList>
          {usageInfo.recipesUsingIngredient.map((recipe, index) => (
            <ListItem key={index}>{recipe.name}</ListItem>
          ))}
        </UnorderedList>
      </Pane>
    )}
    {usageInfo.supplierOptionsUsingIngredient && usageInfo.supplierOptionsUsingIngredient.length > 0 && (
      <Pane marginTop={majorScale(2)}>
        <CustomHeading level="3">Supplier options used in:</CustomHeading>
        <UnorderedList>
          {usageInfo.supplierOptionsUsingIngredient.map((option, index) => (
            <ListItem key={index}>{option.name}</ListItem>
          ))}
        </UnorderedList>
      </Pane>
    )}
  </Pane>
);

export default UsageInfoForDialog;
