//React
import React from 'react'
import { Link as LinkRouter } from 'react-router-dom';

//Libraries
import { Link, majorScale, minorScale, Pane} from 'evergreen-ui';

//Components
import Button from '../../../components/ui/Button/Button'


const ViewMore = ({ to }) => {
  return (
    <Pane marginY={majorScale(2)}>
      <Link
          is={LinkRouter}
          to={`/analytics/sales/${to}`}
          textDecoration="none"
          marginLeft={majorScale(2)}
          //margin={minorScale(2)}
          //marginY={majorScale(2)}
      >
        <Button
          appearance="primary"
          margin={0}
          padding={0}
          //marginY={majorScale(2)}
        >View more</Button>
      </Link>
    </Pane>
  )
}

export default ViewMore;
