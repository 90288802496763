//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import { majorScale, TextInputField, Pane } from 'evergreen-ui';

//Component
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { accountTypes } from '../../utils/constants';
import { actions } from '../../store/actions';

const typeText = {
  [accountTypes.OWNER]: 'Head Office',
  [accountTypes.LOCATION]: 'Location',
  [accountTypes.DEPARTMENT]: 'Department',
};

const AccountCreate = ({ accountType }) => {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const currentAccount = useSelector((state) => state.currentAccount);
  const currentAccountInfo = useSelector((state) => state.accounts[currentAccount]);
  const profile = useSelector((state) => state.profile);
  const [accountName, setAccountName] = useState('');
  const [errors, setErrors] = useState([]);

  const onSave = () => {
    if (!!accountName) {
      const accountInfo = {
        name: accountName,
        type: accountType,
        ownerName: profile.name,
        ownerEmail: profile.email,
        ownerId: profile.id,
      };
      if (accountType !== accountTypes.OWNER) {
        accountInfo['parents'] = [currentAccount];
      }
      dispatch(actions.accounts.accountCreate(accountInfo, (newAccountData) => {
        if (accountType !== accountTypes.OWNER) {
          const children = currentAccountInfo.children || [];
          children.push(newAccountData.id);
          dispatch(actions.accounts.accountUpdate({
            ...currentAccountInfo,
            children
          }, () => navigate('/account')));
        }
      }));
    }
    else {
      setErrors([`${typeText[accountType]} name cannot be empty`]);
    }
  }

  return (
    <Page title="Account" containerProps={{ maxWidth: 600, width: '100%' }}>
      <Block
          padding={majorScale(2)}
      >
        <Pane display="flex" alignItems="center" justifyContent="flex-start" marginBottom={majorScale(2)}>
          <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            onClick={() => navigate(-1)}  
            marginRight={majorScale(2)}
          />
          <CustomHeading level="3">New {typeText[accountType]}</CustomHeading>
        </Pane>
        <TextInputField
            label={`${typeText[accountType]} Name`}
            required
            value={accountName || ''}
            onChange={(e) => {
              setAccountName(e.target.value);
              if (errors.length > 0) {
                setErrors([]); // Clear errors when user starts typing
              }
            }}
            isInvalid={!_.isEmpty(errors)}
            validationMessage={!_.isEmpty(errors) ? errors[0] : null}
            marginBottom={majorScale(2)}
        />
        <Button
            appearance="primary"
            intent="success"
            onClick={onSave}
        >Create</Button>
      </Block>
    </Page>
  );
};

AccountCreate.propTypes = {
  accountType: PropTypes.string,
};

AccountCreate.defaultProps = {
  accountType: accountTypes.OWNER,
};

export default AccountCreate;
