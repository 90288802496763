//React
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
//import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { majorScale, IconButton, Heading, Pane, Text } from 'evergreen-ui';

//Components
import { createOptPrice } from './ProcurementPricingTab';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { colors, TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { getIngredientStockUOM, getOptionOrdersFromDate } from '../../../utils/functions';
import { current } from '../../../utils/selectors';


const tableHeaders = [
  { label: 'Price', field: 'unitprice', type: 'text' },
  { label: 'Date', field: 'date', type: 'text' },
];

const createListPrice = (price, index) => ({
  ...price,
  date: price.date ? price.date.format('DD/MM') : '-',
  id: index
})

const createPricesList = (option, orders) => {
  orders = orders.slice(0, 9);
  return orders.map((o, i) => createListPrice(createOptPrice(option, o), i))
}

const buildOptionPrices = (
  accountId,
  option
) => {
  if (!option) return null;

  const orders = getOptionOrdersFromDate(accountId, option, moment());
  return createPricesList(option, orders);
}

const ProcurementPricingDetail = ({ accountId, optionId }) => {
  const navigate = useNavigate();
  const option = useSelector((state) => _.find(current(state, 'supplierOptions', accountId), { id: optionId }));
  const ing = useSelector((state) => _.find(current(state, 'ingredients', accountId), { id: option ? option.ingredientId : null }));
  const supplier = useSelector((state) => _.find(current(state, 'suppliers', accountId), { id: option ? option.supplierId : null }));
  const uom = ing ? getIngredientStockUOM(ing) : null;
  const optionPricing = buildOptionPrices(accountId, option);
  const title = `${option ? option.name : ''} - ${uom} - ${supplier ? supplier.name : ''}`;
  const exit = () => {
    navigate(`/${accountId}/reports`, false, { tab: 'procurement' });
  }
  return (
    <React.Fragment>
      <Block
        background={colors.twilightBlue05}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        marginBottom={majorScale(2)}
        padding={majorScale(2)}
      >
        <IconWrapper
          onClick={exit}
          name="arrowLeft"
          appearance="clickable"
          marginRight={majorScale(4)}
        />
        <CustomHeading level="4" >{title}</CustomHeading>
      </Block>
      {_.isEmpty(optionPricing) && (
        <Block padding={majorScale(2)}>
          <Text>The requested ingredient option could not be found.</Text>
        </Block>
      )}
      {!_.isEmpty(optionPricing) && (
        <Pane marginBottom={majorScale(2)} flex="1 0 auto" display="flex" flexDirection="column">
          <Pane
            padding={majorScale(2)}
          >
            <DataTable
              items={optionPricing}
              headers={tableHeaders}
              tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
              listSize={7}
            />
          </Pane>
        </Pane>
      )}
    </React.Fragment>
  )
}

export default ProcurementPricingDetail;
