// React
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import { Pane, Text, majorScale, useTheme, Paragraph } from 'evergreen-ui';
import moment from 'moment';
import _ from 'lodash';

//Components
import DataTable from '../../components/DataTable/DataTable';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';
import Block from '../../components/ui/Block/Block'
import CustomDialog from '../../components/Dialog/Dialog';

//Files
import { actions } from '../../store/actions';


const SalesImportHistory = ({ importHistory }) => {
    const { accountId } = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [expandedRows, setExpandedRows] = useState([]);
    const [deletingImport, setDeletingImport] = useState(false);

    const mainTableHeaders = [
      { label: 'Period start', field: 'importDate', type: 'custom', width: 3 },
      { label: 'File name', field: 'filename', type: 'text', width: 6 },
      { label: 'Period', field: 'period', type: 'custom', width: 5 },
      { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 }
    ];
  
    const unmatchedTableHeaders = [
      { label: 'PLU', field: 'plu', type: 'text', width: 3 },
      { label: 'Quantity', field: 'qty', type: 'text', width: 3 },
    ];

    const truncateFileName = (name, maxLength = 40) => {
      if (name.length > maxLength) {
        return name.substring(0, maxLength - 3) + '...';
      }
      return name;
    };

    const toggleRow = (rowId) => {
        if (_.includes(expandedRows, rowId)) {
          setExpandedRows(_.without(expandedRows, rowId));
        }
        else {
          setExpandedRows(_.concat(expandedRows, rowId));
        }
    };

    const confirmDeleteImport = (importItem) => {
        setDeletingImport(importItem);
    };
    
    const removeImport = (importItem) => {
        dispatch(actions.sales.removeSales(accountId, importItem.id));
    };
  
    const renderMainRow = (item) => {
      const isExpanded = expandedRows.includes(item.id);
      const isSelectable = !_.isEmpty(item.unmatched);
  
      return {
        ...item,
        importDate: moment.utc(item.start).format('DD/MM/YYYY'),
        filename: truncateFileName(item.filename),
        period: `from ${moment.utc(item.start).format('DD/MM/YYYY')} to ${moment.utc(item.end).format('DD/MM/YYYY')}`,
        actions: (
          <Pane display="flex" alignItems="center">
            {isSelectable && (
              <IconWrapper
                name={isExpanded ? 'caretUp' : 'caretDown'}
                color={theme.colors.black}
                size={16}
                onClick={() => toggleRow(item.id)}
              />
            )}
            <IconWrapper appearance="danger" name="trash" onClick={() => confirmDeleteImport(item)} />
          </Pane>
        ),
        nested: isExpanded && isSelectable ? (
          <Pane padding={majorScale(2)}>
            <Text padding={majorScale(1)}>Unmatched Items</Text>
            <DataTable
              borderRadius={5}
              items={item.unmatched}
              headers={unmatchedTableHeaders}
            />
          </Pane>
        ) : null
      };
    };

    // Sort the import history by start date in descending order
    const sortedImportHistory = _.sortBy(importHistory, [(item) => -new Date(item.start)]);

    return (
    <>
      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="3" padding={majorScale(2)}>Product sales import history</CustomHeading>
        {importHistory.length > 0 ? (
            <Pane flex="1" overflowY="auto" display="flex" flexDirection="column" height={420}>
                <DataTable
                    borderRadius={5}
                    items={sortedImportHistory.map(renderMainRow)}
                    headers={mainTableHeaders}
                    listHeight={400}
                    onDelete={(importItem) => confirmDeleteImport(importItem)}
                />
          </Pane>
        ) : (
          <Paragraph padding={majorScale(2)}>No imports to display</Paragraph>
        )}
      </Block>

      <CustomDialog
        isOpen={!!deletingImport}
        title="Delete Import?"
        onClose={() => setDeletingImport(false)}
        onConfirm={() => {
          removeImport(deletingImport);
          setDeletingImport(false);
        }}
      >
        <Pane marginBottom={majorScale(4)}>
          <Paragraph>Are you sure you wish to delete the following import?</Paragraph>
          {!!deletingImport && (
            <Paragraph>{deletingImport.filename}, imported {moment.utc(deletingImport.start).format('DD/MM/YYYY')}</Paragraph>
          )}
        </Pane>
      </CustomDialog>
    </>
    );
  };  

export default SalesImportHistory;
