//React
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Table, Text } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { orderStatuses } from '../../../utils/constants';
import { supplierOptionUOM } from '../../../utils/functions';
import { currency, dateFormat } from '../../../utils/format';
import { dateYearFormat } from '../../../utils/time';
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';


const DraftOrders = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const supplierOptionMap = useSelector((state) => _.keyBy(current(state, 'supplierOptions', accountId), 'id'));
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const draftOrders = useSelector((state) => {
    return _.map(
      _.sortBy(
        _.filter({ ...current(state, 'orders', accountId) }, (order) => (order.status === orderStatuses.DRAFT)),
        'date'
      ),
      (item) => {
        let estimatedCost = _.reduce(item.items, (sum, ing) => {
          let ingOrdered = supplierOptionMap[ing.id];
          if (isNaN(ing.quantity) || !ing.quantity || isNaN(ingOrdered.unitprice) || !ingOrdered.unitprice) {
            return sum;
          }
          return (sum + (parseFloat(ing.quantity) * parseFloat(ingOrdered.unitprice)));
        }, 0)

        return { ...item, estimatedCost }
      }
    );
  });
  
  const [expandedRows, setExpandedRows] = useState([]);
  const [deletingOrder, setDeletingOrder] = useState(false);
  const [sendingOrder, setSendingOrder] = useState(false);

  const doOrderDelete = () => {
    dispatch(actions.orders.removeOrder(accountId, deletingOrder.id));
  };

  const doOrderSend = () => {
    // Before sending a PO, check if there is an email address for the supplier
    let supplier = supplierMap[sendingOrder.supplierId];
    if (!supplier || !supplier.email) {
      dispatch(actions.appMessageError(`Could not send PO: no email available for ${(supplier) ? supplier.name : 'supplier'}`));
    }
    else {
      dispatch(actions.orders.updateOrder(accountId, {
        ...sendingOrder,
        status: orderStatuses.AWAIT
      }));
    }
  };

  const toggleRow = (orderId) => {
    if (_.includes(expandedRows, orderId)) {
      setExpandedRows(_.without(expandedRows, orderId));
    }
    else {
      setExpandedRows(_.concat(expandedRows, orderId));
    }
  };


  const renderOrderRow = (po) => {
    const isExpanded = _.includes(expandedRows, po.id);
    return (
      <React.Fragment key={po.id}>
        <Table.Row
            isSelectable
            onSelect={() => toggleRow(po.id)}
            flexFlow={(isMobile) ? 'row wrap' : 'row nowrap'}
            height={(isMobile) ? 'auto' : 48}
            paddingY={(isMobile) ? majorScale(1) : 0}
        >
          <Table.TextCell {...((isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : { flexGrow: 3 })}>{(supplierMap[po.supplierId]) ? supplierMap[po.supplierId].name : '[undefined]'}</Table.TextCell>
          <Table.TextCell flexGrow={1}>{dateYearFormat(moment(po.date, 'YYYY-MM-DD').toDate())}</Table.TextCell>
          {!isMobile && (<Table.TextCell>{po.items.length}</Table.TextCell>)}
          <Table.TextCell justifyContent="flex-end" textProps={{ textAlign: 'right' }}>£ {currency(po.estimatedCost)}</Table.TextCell>
          <Table.Cell flexBasis={56} flexGrow={0} flexShrink={0}>
            <IconWrapper 
              name="sendMessage" 
              appearacne="clickable"
              onClick={() => setSendingOrder(po)} />
          </Table.Cell>
          <Table.Cell flexBasis={56} flexGrow={0} flexShrink={0}>
            <IconWrapper 
              name="edit" 
              appearance="clickable"
              is={Link} 
              to={`/${accountId}/procurement/newOrder/${po.supplierId}/${po.date}`} />
          </Table.Cell>
          {!isMobile && (
            <Table.Cell flexBasis={56} flexGrow={0} flexShrink={0}>
              <IconWrapper 
                name="trash" 
                appearance="danger" 
                onClick={() => setDeletingOrder(po)} />
            </Table.Cell>
          )}
          <Table.Cell flexBasis={majorScale(5)} flexGrow={0} flexShrink={0}>
            <IconWrapper 
              name={isExpanded ? 'caretUp' : 'caretDown'} 
              appearance='clickable'
              marginRight={majorScale(2)}
              size={18} 
            />
          </Table.Cell>
        </Table.Row>
        {isExpanded && (
          <Table.Row
              key={`${po.id}-details`}
              height="auto"
              paddingLeft={(isMobile) ? majorScale(4) : majorScale(8)}
              paddingRight={(isMobile) ? 0 : majorScale(8)}
          >
            {renderOrderDetails(po.items)}
          </Table.Row>
        )}
      </React.Fragment>
    );
  };

  const renderOrderDetails = (items) => {
    return (
      <Table flex="1 0 auto">
        <Table.Head>
          <Table.TextHeaderCell flexBasis={5}>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={5}>UOM</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={1} justifyContent="flex-end" textProps={{ textAlign: 'right' }}>Qty</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.map(items, (orderItem) => {
            const option = supplierOptionMap[orderItem.id];
            return (
              <Table.Row key={option.id}>
                <Table.TextCell flexBasis={5}>{option.name}</Table.TextCell>
                <Table.TextCell flexBasis={5}>{supplierOptionUOM(option)}</Table.TextCell>
                <Table.TextCell flexBasis={1} justifyContent="flex-end" textProps={{ textAlign: 'right' }}>{orderItem.quantity}</Table.TextCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <React.Fragment>
      <Block>
        <CustomHeading level="3" paddingLeft={majorScale(1)} margin={majorScale(2)}>Draft POs</CustomHeading>
        <Table>
          <Table.Head
              flexFlow={(isMobile) ? 'row wrap' : 'row nowrap'}
              height={(isMobile) ? 'auto' : 48}
              paddingY={(isMobile) ? majorScale(1) : 0}
          >
            <Table.TextHeaderCell {...((isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : { flexGrow: 3 })}>Supplier</Table.TextHeaderCell>
            <Table.TextHeaderCell flexGrow={1}>Selected Date</Table.TextHeaderCell>
            {!isMobile && (<Table.TextHeaderCell>Nb Items</Table.TextHeaderCell>)}
            <Table.TextHeaderCell justifyContent="flex-end" textProps={{ textAlign: 'right' }}>Cost</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0} flexShrink={0}>Send</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0} flexShrink={0}>Edit</Table.TextHeaderCell>
            {!isMobile && (<Table.TextHeaderCell flexBasis={56} flexGrow={0} flexShrink={0}>Delete</Table.TextHeaderCell>)}
            <Table.TextHeaderCell flexBasis={majorScale(5)} flexGrow={0} flexShrink={0}></Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(draftOrders) && (
              <Table.Row>
                <Table.TextCell>No orders to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(draftOrders, renderOrderRow)}
          </Table.Body>
        </Table>
      </Block>

      <CustomDialog
          isOpen={!!deletingOrder}
          title="Delete Pending PO"
          intent="danger"
          confirmLabel="Delete PO"
          onClose={() => setDeletingOrder(false)}
          onConfirm={() => {
            doOrderDelete()
            setDeletingOrder(false)
          }}
      >
        <Pane marginBottom={majorScale(4)}>
          {deletingOrder && <Text>Are you sure you wish to delete the PO dated {dateFormat(moment(deletingOrder.date, 'YYYY-MM-DD'))} from {supplierMap[deletingOrder.supplierId].name}?</Text>}
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!sendingOrder}
          title="Send PO"
          confirmLabel="Send PO"
          onClose={() => setSendingOrder(false)}
          onConfirm={() => {
            doOrderSend();
            setSendingOrder(false);
          }}
      >
        <Pane marginBottom={majorScale(4)}>
          {sendingOrder && <Text>Are you sure you wish to send the PO dated {dateFormat(moment(sendingOrder.date, 'YYYY-MM-DD'))} to {supplierMap[sendingOrder.supplierId].name}?</Text>}
        </Pane>
      </CustomDialog>
    </React.Fragment>
  );
};

export default DraftOrders;
