//React
import React, { useState, useEffect } from 'react';

//Libraries
import PropTypes from 'prop-types';
import { minorScale, Text, TextInput, majorScale } from 'evergreen-ui';

//Components
import IconWrapper from '../../components/Icons/Icons';


const EditableText = (props) => {
  const { value, onChange, ...otherProps } = props;
  const [isEditMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState(value);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const cancelEdit = () => {
    setEditMode(false);
    setEditValue(value);
  };

  const acceptEdit = () => {
    onChange(editValue);
    setEditMode(false);
  };

  if (isEditMode) {
    return (
      <Text display="flex" alignItems="center" {...otherProps}>
        <TextInput
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
        />
        <IconWrapper
            name="tick"
            appearance="clickable"
            onClick={() => acceptEdit()}
            marginLeft={minorScale(2)}
        />
        <IconWrapper
            name="cross"
            appearance="danger"
            onClick={() => cancelEdit()}
            marginLeft={minorScale(2)}
        />
      </Text>
    );
  }

  return (
    <Text display="flex" alignItems="center" marginRight={majorScale(2)} {...otherProps}>
      {value}
      <IconWrapper
          name="edit"
          appearance='clickable'
          onClick={() => setEditMode(true)}
          marginLeft={minorScale(2)}
      />
    </Text>
  );
};

EditableText.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

EditableText.defaultProps = {
  value: '',
  onChange: () => {}
};

export default EditableText;
