import { takeEvery, call, put, select } from 'redux-saga/effects';
import { addSupplierOption, importSupplierOptions, processSupplierOptions, removeSupplierOption, 
  archiveSupplierOption, updateSupplierOption, uploadSupplierOptions, removeSupplierOptionFromCollection } from '../firebase/supplierOptions';
import { actionTypes, actions } from '../actions';
import { activeAccount, currentUserName } from '../../utils/selectors';
import { findAccountForObject } from '../../utils/functions';
import { addIngredient } from '../firebase/ingredients';

export default function* supplierOptionsSaga() {
  yield takeEvery(actionTypes.supplierOptions.ADD_SUPPLIER_OPTION, function* (action) {
    const userName = yield select(currentUserName);
    const accountId = yield select(activeAccount);
    try {
      const option = {...action.ingredient};
      const newOption = yield call(addSupplierOption, option, accountId, userName);
      yield put(actions.appMessageSuccess('Supplier Ingredient successfully added'));
      if (action.callback) {
        yield call(action.callback, newOption.id);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Supplier Ingredient could not be saved'));
    }
  });
  yield takeEvery(actionTypes.supplierOptions.UPDATE_SUPPLIER_OPTION, function* (action) {
    const { id, ingredientId, ...ingredient } = action.ingredient;
    const userName = yield select(currentUserName);
    const accountId = yield findAccountForObject({ itemId: id, collection: 'supplierOptions' }) || select(activeAccount);
    try {
      yield call(updateSupplierOption, id, { ...ingredient, ingredientId }, accountId, userName);
      yield put(actions.appMessageSuccess('Supplier Ingredient successfully updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Supplier Ingredient could not be updated'));
    }
  });
  yield takeEvery(actionTypes.supplierOptions.ARCHIVE_SUPPLIER_OPTION, function* (action) {
    const userName = yield select(currentUserName);
    const accountId = yield select(activeAccount);
    try {
      yield call(archiveSupplierOption, action.ingredientId, accountId, userName);
      yield put(actions.appMessageSuccess('Supplier Ingredient Archived'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Supplier Ingredient could not be removed'));
    }
  });
  yield takeEvery(actionTypes.supplierOptions.REMOVE_SUPPLIER_OPTION, function* (action) {
    const userName = yield select(currentUserName);
    const accountId = yield select(activeAccount);
    try {
      yield call(removeSupplierOption, action.ingredientId, accountId, userName);
      yield put(actions.appMessageSuccess('Supplier Ingredient Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Supplier Ingredient could not be removed'));
    }
  });
  yield takeEvery(actionTypes.supplierOptions.REMOVE_SUPPLIER_OPTION_FROM_COLLECTION, function* (action) {
      const userName = yield select(currentUserName);
      try {
        const { accountId, ingredientId, optionId, shouldArchive } = action.payload;
        yield call(removeSupplierOptionFromCollection, accountId, ingredientId, optionId, userName, shouldArchive);
        yield put(actions.appMessageSuccess('Supplier Ingredient has been removed from collections'));
      } 
      catch (err) {
        console.error(err);
        yield put(actions.appMessageError('Supplier Ingredient could not be removed from collections'));
      }
    }
  );
  yield takeEvery(actionTypes.supplierOptions.ADD_UPLOAD, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(uploadSupplierOptions, accountId, accountId, action.file);
      yield put(actions.appMessageSuccess('Supplier Options file uploaded'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Error uploading file'));
    }
  });
  yield takeEvery(actionTypes.supplierOptions.SUPPLIER_OPTIONS_LOAD_SUCCESS, function* (action) {
    try {
      //console.log('Received action for SUPPLIER_OPTIONS_LOAD_SUCCESS:', action);
      //console.log('Supplier Account:', action.payload.supplierAccount);  // Add this line
      //console.log('Processing supplier options with payload:', action.payload);
      const accountId = yield select(activeAccount);
      //console.log('Processing supplier options with accounId:', accountId)
      // Call the processing function with the payload
      const processed = yield call(processSupplierOptions, action.payload, accountId);
      //console.log('Processed supplier options:', processed);
  
      // Log the result of the processing function
      //console.log('Processed supplier options:', processed);
  
      if (!processed) {
        console.log('No supplier options processed, skipping further actions');
        return;
      }
  
      // Dispatch the action to update the state with the processed supplier options
      yield put(actions.supplierOptions.supplierOptionsLoadProcessed(processed, action.account));
    }
    catch (err) {
      console.error('Error during supplier options loading:', err);
      yield put(actions.appMessageError('Suppliers could not be loaded'));
    }
  });
  yield takeEvery(actionTypes.supplierOptions.IMPORT_SUPPLIER_OPTIONS, function* (action) {
    const userName = yield select(currentUserName);
    const accountId = yield select(activeAccount);
    try {
      yield call(importSupplierOptions, accountId, action.options, userName);
      yield put(actions.appMessageSuccess('Supplier Options imported'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Error importing supplier options'));
    }
  });
};
