//React
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Table, TextInputField } from 'evergreen-ui';

//Components
import RemovingTask from './RemovingTask';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomDialog from '../../../components/Dialog/Dialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { current } from '../../../utils/selectors';
import { actions } from '../../../store/actions';
import useTaskSettings from '../../../hooks/useTaskSettings';


const OpenClose = ({ filterTasks }) => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const openTasks = useSelector((state) => _.filter(current(state, 'tasks', accountId), { 'type': 'OPEN' }));
  const closeTasks = useSelector((state) => _.filter(current(state, 'tasks', accountId), { 'type': 'CLOSE' }));

  const {
    setEditingTask,
    editingTask,
    setRemovingTask,
    removingTask,
    onFieldChange,
    removeConfirmed
  } = useTaskSettings({ accountId });

  const filteredOpenTasks = filterTasks(openTasks);
  const filteredCloseTasks = filterTasks(closeTasks);

  const saveTask = (close) => {
    if (editingTask.id) {
      dispatch(actions.tasks.updateTask(accountId, editingTask));
    }
    else {
      dispatch(actions.tasks.addTask(accountId, { ...editingTask, date: new Date() }));
    }
    close();
  };
  return (
    <React.Fragment >
      <Block marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <CustomHeading level="4" flex="1 0 auto">Opening</CustomHeading>
          <Button appearance="primary" onClick={() => setEditingTask({ type: 'OPEN' })}>New Task</Button>
        </Pane>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell flex="0 1 80%">Task</Table.TextHeaderCell>
            <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(filteredOpenTasks) && (
              <Table.Row alignItems="center">
                <Table.TextCell padding={majorScale(1)}>No tasks to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(filteredOpenTasks, (item) => (
              <Table.Row key={item.id}>
                <Table.TextCell flex="0 1 80%">{item.action}</Table.TextCell>
                <Table.Cell>
                  <IconWrapper name="edit" appearance="clickable" onClick={() => setEditingTask(item)} />
                </Table.Cell>
                <Table.Cell>
                  <IconWrapper name="trash" appearance="danger" onClick={() => setRemovingTask(item)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>

      <Block marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <CustomHeading level="4" flex="1 0 auto">Closing</CustomHeading>
          <Button appearance="primary" onClick={() => setEditingTask({ type: 'CLOSE' })}>New Task</Button>
        </Pane>
        <Table marginBottom={majorScale(2)}>
          <Table.Head>
            <Table.TextHeaderCell flex="0 1 80%">Task</Table.TextHeaderCell>
            <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(filteredCloseTasks) && (
              <Table.Row>
                <Table.TextCell padding={majorScale(1)}>No tasks to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(filteredCloseTasks, (item) => (
              <Table.Row key={item.id}>
                <Table.TextCell flex="0 1 80%">{item.action}</Table.TextCell>
                <Table.Cell>
                  <IconWrapper name="edit" appearance="clickable" onClick={() => setEditingTask(item)} />
                </Table.Cell>
                <Table.Cell>
                  <IconWrapper name="trash" appearance="danger" onClick={() => setRemovingTask(item)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>

      <CustomDialog
          isOpen={!!editingTask && ['OPEN','CLOSE'].includes(editingTask.type)}
          title={(editingTask.id) ? 'Update Task' : 'Add New Task'}
          confirmLabel="Save"
          onClose={() => setEditingTask(false)}
          onConfirm={saveTask}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="TO DO"
              value={editingTask.action || ''}
              onChange={(e) => onFieldChange('action', e.target.value)}
          />
        </Pane>
      </CustomDialog>
      <RemovingTask
        removingTask={removingTask}
        setRemovingTask={setRemovingTask}
        removeConfirmed={removeConfirmed}
      />
    </React.Fragment>
  )
}

OpenClose.propTypes = {
  accountId: PropTypes.string.isRequired
}

export default OpenClose;
