import { getCollectionRef } from './common';

export const addMessagesSeenTimes = (messagesSeenTimes, accountId) => {
  const messagesSeenTimesRef = getCollectionRef('messagesSeenTimes', accountId);
  return messagesSeenTimesRef.add(messagesSeenTimes);
};

export const updateMessagesSeenTimes = (messagesSeenTimesId, messagesSeenTimes, accountId) => {
  const messagesSeenTimesRef = getCollectionRef('messagesSeenTimes', accountId);
  return messagesSeenTimesRef.doc(messagesSeenTimesId).update(messagesSeenTimes);
};
