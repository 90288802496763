//React
import React from 'react';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import OrderArchive from './components/OrderArchive';
import SupplierOrderArchive from './components/SupplierOrderArchive';
import SupplierOrderDetail from './components/SupplierOrderDetail';
import StockTakeArchive from './components/StockTakeArchive';
import WastageArchive from './components/WastageArchive';
import StockTakeDetail from './components/StockTakeDetail';
import WastageDetail from './components/WastageDetail';
import DueDiligenceArchive from './components/DueDiligenceArchive';
import DueDiligenceDetail from './components/DueDiligenceDetail';
import useConditionalRedirect from 'hooks/useConditionalRedirect';
import TransferArchive from './components/TransferArchive';
import TransferDetail from './components/TransferDetail';

const archiveTabs = {
  'reports': 'GP analysis',
  'supplier-statements': 'Orders by supplier',
  'stock-take': 'Stocktakes',
  'wastage': 'Wastage',
  'transfers': 'Transfers',
  'due-diligence': 'Due Diligence'
};


const Archive = () => {
  const { requiresWorkingAccount } = useConditionalRedirect();
  const location = useLocation(); 
  const { accountId } = useParams();
  const currentPath = location.pathname;
  const currentTab = currentPath.split('/')[3] || 'supplier-statements';

  requiresWorkingAccount();

  return (
    <Page title={archiveTabs[currentTab]} flex="1 0 auto" justifyContent="flex-start">
      <Routes>
        <Route path="stocktake/:stockTakeId" element={<StockTakeDetail accountId={accountId} />} />
        <Route path="stocktake" element={<StockTakeArchive accountId={accountId} />} />
        <Route path="wastage/:weekStart" element={<WastageDetail accountId={accountId} />} />
        <Route path="wastage" element={<WastageArchive accountId={accountId} />} />
        <Route path="transfers" element={<TransferArchive accountId={accountId} />} />
        <Route path="transfers/:transferId" element={<TransferDetail accountId={accountId} />} />
        <Route path="supplier-statements/detail/:orderId" element={<SupplierOrderDetail accountId={accountId} />} />
        <Route path="supplier-statements/:supplierId" element={<SupplierOrderArchive accountId={accountId} />} />
        <Route path="supplier-statements" element={<OrderArchive accountId={accountId} />} />
        <Route path="due-diligence" element={<DueDiligenceArchive accountId={accountId} />} />
        <Route path="due-diligence/:weekOf" element={<DueDiligenceDetail accountId={accountId} />} />
        <Route path="*" element={<StockTakeArchive accountId={accountId} />} />
      </Routes>
    </Page>
  );
};

export default Archive;
