const MENU_CATEGORIES_LOAD_SUCCESS = 'MENU_CATEGORIES_LOAD_SUCCESS';
const MENU_CATEGORIES_LOAD_FAILURE = 'MENU_CATEGORIES_LOAD_FAILURE';
const MENU_CATEGORIES_CLEAR = 'MENU_CATEGORIES_CLEAR';
const ADD_MENU_CATEGORY = 'ADD_MENU_CATEGORY';
const REMOVE_MENU_CATEGORY = 'REMOVE_MENU_CATEGORY';
const UPDATE_MENU_CATEGORY = 'UPDATE_MENU_CATEGORY';

export const actionTypes = {
  MENU_CATEGORIES_LOAD_SUCCESS,
  MENU_CATEGORIES_LOAD_FAILURE,
  MENU_CATEGORIES_CLEAR,
  ADD_MENU_CATEGORY,
  REMOVE_MENU_CATEGORY,
  UPDATE_MENU_CATEGORY
};


const menuCategoriesLoadSuccess = (payload, account) => {
  return {
    type: MENU_CATEGORIES_LOAD_SUCCESS,
    payload,
    account
  };
};

const menuCategoriesLoadFailure = (error) => {
  return {
    type: MENU_CATEGORIES_LOAD_FAILURE,
    error
  };
};

const menuCategoriesClear = () => {
  return {
    type: MENU_CATEGORIES_CLEAR
  };
};

const addCategory = (categoryInfo) => {
  return {
    type: ADD_MENU_CATEGORY,
    categoryInfo
  };
};

const removeCategory = (categoryId) => {
  return {
    type: REMOVE_MENU_CATEGORY,
    categoryId
  };
};

const updateCategory = (categoryInfo) => {
  return {
    type: UPDATE_MENU_CATEGORY,
    categoryInfo
  };
};

export const actions = {
  menuCategoriesLoadSuccess,
  menuCategoriesLoadFailure,
  menuCategoriesClear,
  addCategory,
  removeCategory,
  updateCategory
};
