//React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table, Pane, Select, TextInput, Text } from 'evergreen-ui';

//Components
import DataTable from "../../../components/DataTable/DataTable";
import FilterBar from "../../../components/FilterBar/FilterBar";
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CommentPane from './CommentPane';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { generateSearchFieldsFn } from '../../../utils/functions';
import { foodRecordTypes, foodRecordTargets } from '../../../utils/constants';
import useValidation, { validationRules } from '../../../hooks/useValidation';
import { current } from '../../../utils/selectors';
import { TODAY } from '../../../utils/time';

const recordRules = {
  type: [
    validationRules.NotEmpty
  ],
  time: [
    validationRules.NotEmpty
  ],
  temperature: [
    validationRules.NotEmpty,
    validationRules.IsFloat,
  ],
  ingredient: [
    validationRules.NotEmpty
  ]
};

const FoodRecording = ({ date }) => {
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const dispatch = useDispatch();
  const ingredients = useSelector((state) => (
    _.sortBy(
      _.concat(
        current(state, 'ingredients', accountId),
        current(state, 'recipes', accountId)
      ),
      'name'
    )
  ));

  const ongoingRecords = useSelector((state) => {
    return _.keyBy(
      _.sortBy(
        _.filter(
          current(state, 'foodRecords', accountId),
          (record) => {
            return (record.date && moment(record.date.toDate()).isSame(date, 'day') && record.ongoing);
          }
        ),
        'time'
      ),
      'id'
    );
  });
  //console.log(ongoingRecords)
  const userInfo = useSelector((state) => ({ userName: state.profile.name, profileId: state.profile.uid }));
  const [filters, setFilters] = useState();
  const [newRecords, setNewRecords] = useState([]);
  const [newSteps, setNewSteps] = useState(_.keyBy(
    _.map(ongoingRecords, (rec) => ({ ..._.last(rec.steps), recordId: rec.id, time: moment().format('HH:mm') })),
    'recordId'
  ));
  const { allValid, isValid } = useValidation(recordRules);

  useEffect(() => {
    if ( !_.isEmpty(ongoingRecords) && _.isEmpty(newSteps) ) {
      setNewSteps(_.keyBy(
        _.map(ongoingRecords, (rec) => ({ ..._.last(rec.steps), recordId: rec.id, time: moment().format('HH:mm') })),
        'recordId'
      ));
    }
  }, [ongoingRecords, newSteps]);

  const tableHeaders = [
    { label: 'Name', field: 'name', width: 7 },
    { label: 'Record', field: 'record', type: 'action', icon: 'plus', appearance: 'clickable', width: 5 }
  ];

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters({});
    }
    else {
      setFilters({ 'search': generateSearchFieldsFn(['name'], newSearchValue)});
    }
  };

  const addFoodRecord = (ing = null) => {
    setNewRecords((records) => ([ ...records, { ingredient: ing?.name, time: moment().format('HH:mm'), manual: ing?.name ? false : true }]));
  };

  const setRecordField = (recordIndex, fieldName, value) => {
    setNewRecords((records) => {
      records[recordIndex] = {
        ...records[recordIndex],
        [fieldName]: value
      };
      return [ ...records ];
    });
  };

  const saveNewFoodRecord = (record) => {
    
    const { addStep, ...recordInfo } = record;
    if ( !allValid(recordInfo) ) {
      return;
    }
    let foodRecord = {
      ...recordInfo,
      ...userInfo,
      date
    };
    
    if ( !_.isEmpty(addStep) ) {
      foodRecord.ongoing = true;
      foodRecord.steps = [{ type: addStep }];
    }
    dispatch(actions.foodRecords.addFoodRecord(accountId, foodRecord));
    setNewRecords((records) => _.without(records, record));
  };

  const setRecordStepField = (recordId, fieldName, value) => {
    setNewSteps((steps) => {
      steps[recordId] = {
        ...steps[recordId],
        [fieldName]: value
      };
      return { ...steps };
    });
  };

  const saveNewFoodRecordStep = (step) => {
    const { recordId, addStep, ...stepInfo } = step;
    console.log("Attempting to save step:", step);
    if ( !allValid(stepInfo) ) {
      return;
    }
    const rec = ongoingRecords[recordId];
    const stepIndex = rec.steps.length - 1;
    let steps = [ ..._.slice(rec.steps, 0, stepIndex), { ...stepInfo, ...userInfo } ];
    if ( !_.isEmpty(addStep) ) {
      steps.push({ type: addStep });
      setNewSteps((steps) => {
        steps[recordId] = {
          type: addStep
        };
        return { ...steps };
      });
    }

    dispatch(actions.foodRecords.updateFoodRecord(accountId, {
      ...rec,
      ongoing: !_.isEmpty(addStep),
      steps
    }).then(() => {
      console.log("Record updated successfully");
    }).catch(error => {
      console.error("Error updating record:", error);
    }))
  };


  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)}>
        <Pane padding={majorScale(2)}>
          <Pane
              display="flex"
              alignItems="center"
              marginBottom={majorScale(2)}
          >
            <CustomHeading level="3" flex="1 0 auto">Food temperature logs</CustomHeading>
            <Button
                appearance='primary'
                disabled={date < TODAY}
                onClick={addFoodRecord}
            >Add manually</Button>
          </Pane>
          <FilterBar
              searchPlaceholder="Search Ingredients and Recipes"
              searchOnChange={searchOnChange}
          />
        </Pane>
        <DataTable
            listHeight={200}
            items={ingredients}
            headers={tableHeaders}
            filters={filters}
            onRecord={(item) => addFoodRecord(item)}
        />
        <Table>
          <Table.Head
            flexFlow="row wrap"
            height="auto"
            minHeight={48}
            paddingY={majorScale(1)}
          >
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {} )}
            >Name</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '25%' } : {} )}
            >Type</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '25%' } : {} )}
            >Target</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '25%' } : {} )}
            >Time</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '25%' } : {} )}
            >Temp.</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '50%', marginTop: majorScale(1) } : {} )}
            >Add. step</Table.TextHeaderCell>
            <Table.TextHeaderCell>Save</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(newRecords) && (
              <Table.Row>
                <Table.TextCell>No Food Records to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(newRecords, (record, index) => (
              <Table.Row
                  key={index}
                  flexFlow="row wrap"
                  height="auto"
                  minHeight={48}
                  paddingY={majorScale(1)}
              >
                <Table.Cell
                    {...( (isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {} )}
                >
                  {record.manual && (
                    <TextInput
                        value={record.ingredient||''}
                        onChange={(e) => setRecordField(index, 'ingredient', e.target.value)}
                        isInvalid={!isValid('ingredient', record.ingredient)}
                        maxWidth="100%"
                    />
                  )}
                  {!record.manual && (
                    <Text>{record.ingredient}</Text>
                  )}
                </Table.Cell>
                <Table.Cell
                    {...( (isMobile) ? { flexBasis: '25%' } : {} )}
                >
                  <Select
                      value={record.type||''}
                      onChange={(e) => setRecordField(index, 'type', e.target.value)}
                      isInvalid={!isValid('type', record.type)}
                  >
                    <option value=""></option>
                    {_.map(foodRecordTypes, (value, key) => (
                      <option value={key} key={key}>{value}</option>
                    ))}
                  </Select>
                </Table.Cell>
                <Table.TextCell
                    {...( (isMobile) ? { flexBasis: '25%' } : {} )}
                >
                  {record.type && (
                    <React.Fragment>
                      {foodRecordTargets[record.type]}
                    </React.Fragment>
                  )}
                </Table.TextCell>
                <Table.Cell
                    {...( (isMobile) ? { flexBasis: '25%' } : {} )}
                >
                  <TextInput
                      value={record.time||''}
                      onChange={(e) => setRecordField(index, 'time', e.target.value)}
                      isInvalid={!isValid('time', record.time)}
                      maxWidth="100%"
                      width="6rem"
                  />
                </Table.Cell>
                <Table.Cell
                    {...( (isMobile) ? { flexBasis: '25%' } : {} )}
                >
                  <TextInput
                      value={record.temperature||''}
                      onChange={(e) => setRecordField(index, 'temperature', e.target.value)}
                      isInvalid={!isValid('temperature', record.temperature)}
                      maxWidth="100%"
                      width="6rem"
                      maxLength={5}
                  />
                </Table.Cell>
                <Table.Cell
                    {...( (isMobile) ? { flexBasis: '50%', marginTop: majorScale(1) } : {} )}
                >
                  <Select
                      value={record.addStep||''}
                      onChange={(e) => setRecordField(index, 'addStep', e.target.value)}
                  >
                    <option value=""></option>
                    {_.map(foodRecordTypes, (value, key) => (
                      <option value={key} key={key}>{value}</option>
                    ))}
                  </Select>
                </Table.Cell>
                <Table.Cell>
                  <IconWrapper
                      name="tick"
                      appearance='clickable'
                      onClick={() => saveNewFoodRecord(record)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>
      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="3" padding={majorScale(2)}>Ongoing logs</CustomHeading>
        <Table>
          <Table.Head
              flexFlow="row wrap"
              height="auto"
              minHeight={48}
              paddingY={majorScale(1)}
          >
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Target</Table.TextHeaderCell>
            <Table.TextHeaderCell>Time</Table.TextHeaderCell>
            <Table.TextHeaderCell>Temp.</Table.TextHeaderCell>
            <Table.TextHeaderCell>Add. step</Table.TextHeaderCell>
            <Table.TextHeaderCell>Save</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(ongoingRecords) && (
              <Table.Row>
                <Table.TextCell>No ongoing records to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(ongoingRecords, (record) => (
              <React.Fragment key={record.id}>
                <Table.Row
                    flexFlow="row wrap"
                    height="auto"
                    minHeight={48}
                    paddingY={majorScale(1)}
                >
                  <Table.TextCell
                      {...( (isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {} )}
                  >{record.ingredient}</Table.TextCell>
                  <Table.TextCell>{foodRecordTypes[record.type]}</Table.TextCell>
                  <Table.TextCell>{foodRecordTargets[record.type]}</Table.TextCell>
                  <Table.TextCell>{record.time}</Table.TextCell>
                  <Table.TextCell>{record.temperature}°C</Table.TextCell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                {_.map(record.steps, (step, index) => {
                  const isLatest = (index + 1 === record.steps.length) && newSteps[record.id];
                  if ( !isLatest ) {
                    return (
                      <Table.Row key={index}>
                        {!isMobile && (<Table.Cell></Table.Cell>)}
                        <Table.TextCell>{foodRecordTypes[step.type]}</Table.TextCell>
                        <Table.TextCell>{foodRecordTargets[step.type]}</Table.TextCell>
                        <Table.TextCell>{step.time}</Table.TextCell>
                        <Table.TextCell>{step.temperature}°C</Table.TextCell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    );
                  }
                  return (
                    <Table.Row key={index}>
                      {!isMobile && (<Table.Cell></Table.Cell>)}
                      <Table.Cell>
                        <Select
                            value={newSteps[record.id].type}
                            onChange={(e) => setRecordStepField(record.id, 'type', e.target.value)}
                            isInvalid={!isValid('type', newSteps[record.id].type)}
                        >
                          {_.map(foodRecordTypes, (value, key) => (
                            <option value={key} key={key}>{value}</option>
                          ))}
                        </Select>
                      </Table.Cell>
                      <Table.TextCell>{foodRecordTargets[newSteps[record.id].type]}</Table.TextCell>
                      <Table.Cell>
                        <TextInput
                            value={newSteps[record.id].time||''}
                            onChange={(e) => setRecordStepField(record.id, 'time', e.target.value)}
                            isInvalid={!isValid('time', newSteps[record.id].time)}
                            maxWidth="100%"
                            width="6rem"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TextInput
                            value={newSteps[record.id].temperature||''}
                            onChange={(e) => setRecordStepField(record.id, 'temperature', e.target.value)}
                            isInvalid={!isValid('temperature', newSteps[record.id].temperature)}
                            maxWidth="100%"
                            width="6rem"
                            maxLength={5}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                            value={newSteps[record.id].addStep||''}
                            onChange={(e) => setRecordStepField(record.id, 'addStep', e.target.value)}
                        >
                          <option value=""></option>
                          {_.map(foodRecordTypes, (value, key) => (
                            <option value={key} key={key}>{value}</option>
                          ))}
                        </Select>
                      </Table.Cell>
                      <Table.Cell>
                        <IconWrapper
                            name="tick"
                            appearance='clickable'
                            onClick={() => saveNewFoodRecordStep(newSteps[record.id])}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </Block>
      <Block>
        <CommentPane
          accountId={accountId}
          date={date}
          type='FOOD'
        />
      </Block>
    </React.Fragment>
  );
};

FoodRecording.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
};

export default FoodRecording;
