//React
import React from 'react';
import { useSelector } from 'react-redux';

//Components
import IngredientsPricingDetail from './IngredientsPricingDetail';

//Files
import { buildAccountData } from '../../../utils/accounts';

const IngredientsPricing = (props) => {
  const currentAccount = useSelector((state) => state.currentAccount);
  const accountId = props.accountId || currentAccount;
  //console.log(accountId)
  const account = useSelector((state) => state.accounts[accountId]);
  const data = buildAccountData(accountId, {});
  //console.log(data, "ingredientpricing")
  const accounts = {...data, account}
  
  return (
    <IngredientsPricingDetail
      {...props}
      accounts={accounts}
      accountId={accountId}
    />
  )
}

export default IngredientsPricing;
