const FORM_CHANGE = 'FORM_CHANGE';
const FORM_IMAGE_CHANGE = 'FORM_IMAGE_CHANGE';
const FORM_CLEAR = 'FORM_CLEAR';

export const actionTypes = {
  FORM_CHANGE,
  FORM_IMAGE_CHANGE,
  FORM_CLEAR
};

const formChange = (data, form) => {
  return {
    type: FORM_CHANGE,
    form,
    data
  };
};

const formImageChange = (image, form) => {
  return {
    type: FORM_IMAGE_CHANGE,
    form,
    image
  };
};

const formClear = (form) => {
  return {
    type: FORM_CLEAR,
    form
  };
};

export const actions = {
  formChange,
  formImageChange,
  formClear
};
