import { getCollectionRef } from './common'

export const addBudget = (budget, accountId) => {
  const budgetsRef = getCollectionRef('budgets', accountId);
  return budgetsRef.add(budget);
};

export const removeBudget = (budgetId, accountId) => {
  const budgetsRef = getCollectionRef('budgets', accountId);
  return budgetsRef.doc(budgetId).delete();
};

export const updateBudget = (budgetId, budget, accountId) => {
  const budgetsRef = getCollectionRef('budgets', accountId);
  return budgetsRef.doc(budgetId).update(budget);
};
