import fb from './';
import { getCollectionRef } from './common';

export const addTasksComment = (taskItem, accountId) => {
  const tasksCommentsRef = getCollectionRef('tasksComments', accountId);
  return tasksCommentsRef.add({ ...taskItem, created: fb.serverTime() });
};

export const removeTasksComment = (taskItemId, accountId) => {
  const tasksCommentsRef = getCollectionRef('tasksComments', accountId);
  return tasksCommentsRef.doc(taskItemId).delete();
};

export const updateTasksComment = (taskItemId, taskItem, accountId) => {
  const tasksCommentsRef = getCollectionRef('tasksComments', accountId);
  return tasksCommentsRef.doc(taskItemId).update(taskItem);
};
