//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, minorScale, Pane, Table, Text, TextInputField, Popover, Position, Menu, Paragraph } from 'evergreen-ui';

//Components
import RemovingTask from './RemovingTask';
import EditableText from '../../../components/EditableText/EditableText';
import FilterSelect from '../../../components/FilterSelect/FilterSelect';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomDialog from '../../../components/Dialog/Dialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';


const PrepTab = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [addingSection, setAddingSection] = useState(false);
  const [removingSection, setRemovingSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');
  const sections = useSelector((state) => _.filter(current(state, 'sections', accountId), { 'type': 'PREP' }));
  const sectionOptions = _.map(sections, (section) => ({ label: section.name, value: section.name }));
  const prepTasks = useSelector((state) => current(state, 'prepTasks', accountId));
  const [sectionFilter, setSectionFilter] = useState(false);
  const [editingTask, setEditingTask] = useState(false);
  const [removingTask, setRemovingTask] = useState(false);
  const filteredRecipes = useSelector((state) => {
    if (!editingTask || !editingTask.recipeFilter) {
      return [];
    }
    else {
      return _.filter(current(state, 'recipes', accountId), (item) => _.includes(_.lowerCase(item.name), _.lowerCase(editingTask.recipeFilter)));
    }
  });

  const onFieldChange = (field, newValue) => {
    setEditingTask((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const onRecipeSelect = (recipe) => {
    setEditingTask((prevValues) => {
      if (prevValues.action || prevValues.recipe) {
        let steps = _.concat(prevValues.steps || [], { recipe: recipe.name });
        return { ...prevValues, steps, recipeFilter: '' };
      }
      return { ...prevValues, recipe: recipe.name, recipeFilter: '' };
    });
  };

  const addStep = () => {
    setEditingTask((prevValues) => {
      if (prevValues.action || prevValues.recipe) {
        let steps = prevValues.steps || [];
        steps.push({ action: editingTask.recipeFilter });
        return { ...prevValues, steps, recipeFilter: '' };
      }
      else {
        return { ...prevValues, action: editingTask.recipeFilter, recipeFilter: '' };
      }
    });
  };

  const saveTask = (close) => {
    const { recipeFilter, ...task } = editingTask;
    if (task.id) {
      dispatch(actions.prepTasks.updatePrepTask(accountId, task));
    }
    else {
      dispatch(actions.prepTasks.addPrepTask(accountId, { ...task, type: 'PREP' }));
    }
    close();
  };

  const removeConfirmed = (close) => {
    dispatch(actions.prepTasks.removePrepTask(accountId, removingTask.id));
    setRemovingTask(false);
    close();
  };

  const cancelNewSection = () => {
    setAddingSection(false);
    setNewSectionName('');
  };

  const createNewSection = () => {
    if (!newSectionName) {
      dispatch(actions.appMessageError('You must enter a name for the section'));
      return;
    }
    dispatch(actions.sections.addSection(accountId, { name: newSectionName, type: 'PREP' }));
  };

  const onSectionNameChange = (section, newValue) => {
    dispatch(actions.sections.updateSection(accountId, { ...section, displayName: newValue }));
  };

  const removeSectionConfirmed = (close) => {
    // TODO: Remove all tasks here as well for cleanup?
    dispatch(actions.sections.removeSection(accountId, removingSection.id));
    setRemovingSection(false);
    close();
  };

  const filteredSections = _.sortBy(
    (sectionFilter) ? _.filter(sections, { 'name': sectionFilter}) : sections,
    (section) => (_.lowerCase(section.name))
  );

  const filteredTasks = _.groupBy(
    (sectionFilter) ? _.filter(prepTasks, { 'section': sectionFilter }) : prepTasks,
    'section'
  );

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Preparation lists</CustomHeading>
          <Button appearance="primary" onClick={() => setAddingSection(true)}>New Section</Button>
        </Pane>
        <FilterSelect
            name="section"
            label="Section"
            options={sectionOptions}
            selected={_.find(sectionOptions, { 'value': sectionFilter })}
            setFilter={(name, value) => setSectionFilter(value)}
        />
      </Block>

      {_.map(filteredSections, (section) => (
        <Block marginBottom={majorScale(2)} key={section.id}>
          <Pane
              display="flex"
              alignItems="center"
              justifyContent='space-between'
              marginBottom={majorScale(2)}
              padding={majorScale(2)}
          >
            <Pane display="flex">
            <EditableText
                marginRight={majorScale(2)}
                value={section.displayName || section.name}
                onChange={(newValue) => onSectionNameChange(section, newValue)}
            />
            <IconWrapper
                appearance="danger"
                name="trash"
                marginRight="auto"
                onClick={() => setRemovingSection(section)}
            />
            </Pane>
            <Button appearance='primary' onClick={() => setEditingTask({ section: section.name })}>New Task</Button>
          </Pane>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell flex="0 1 75%">Name</Table.TextHeaderCell>
              <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
              <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {_.isEmpty(filteredTasks[section.name]) && (
                <Table.Row>
                  <Table.TextCell padding={majorScale(1)}>No tasks to display</Table.TextCell>
                </Table.Row>
              )}
              {_.map(filteredTasks[section.name], (item) => (
                <Table.Row key={item.id} height="auto">
                  <Table.Cell
                      flex="0 1 75%"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                      padding={majorScale(1)}
                  >
                    <Paragraph>{item.recipe || item.action}</Paragraph>
                    {_.map(item.steps, (step, index) => (
                      <Paragraph key={index} marginLeft={minorScale(4)}>{step.recipe || step.action}</Paragraph>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    <IconWrapper name="edit" appearance="clickable" onClick={() => setEditingTask(item)} />
                  </Table.Cell>
                  <Table.Cell>
                    <IconWrapper name="trash" appearance="danger" onClick={() => setRemovingTask(item)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Block>
      ))}

      <CustomDialog
          isOpen={addingSection}
          title="New Section"
          confirmLabel="Add"
          onClose={() => cancelNewSection()}
          onConfirm={() => {
            createNewSection();
            cancelNewSection();
          }}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              label="Section Name"
              value={newSectionName || ''}
              onChange={(e) => setNewSectionName(e.target.value)}
              marginRight={majorScale(2)}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={removingSection}
          title="Remove Section"
          confirmLabel="Remove"
          onClose={() => setRemovingSection(false)}
          onConfirm={removeSectionConfirmed}
      >
        <Pane marginBottom={majorScale(2)}>
          {removingSection && (
            <React.Fragment>
              <Paragraph>Are you sure you wish to remove the section "{removingSection.displayName || removingSection.name}"?</Paragraph>
              <Paragraph color="danger">This will permanently remove the section and any tasks associated with it!</Paragraph>
            </React.Fragment>
          )}
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!editingTask}
          title={(editingTask.id) ? 'Update Task' : 'Add New Task'}
          confirmLabel="Save"
          isConfirmDisabled={!editingTask.recipe && !editingTask.action}
          onClose={() => setEditingTask(false)}
          onConfirm={saveTask}
      >
        <Pane marginBottom={majorScale(2)}>
          {(editingTask.recipe || editingTask.action) && (
            <Pane marginBottom={minorScale(4)} borderBottom="default">
              <Paragraph>{editingTask.recipe || editingTask.action}</Paragraph>
              {!_.isEmpty(editingTask.steps) && _.map(editingTask.steps, (step, index) => (
                <Paragraph key={index} marginLeft={minorScale(4)}>{step.recipe || step.action}</Paragraph>
              ))}
            </Pane>
          )}
          <Popover
              position={Position.BOTTOM_LEFT}
              isShown={!_.isEmpty(filteredRecipes)}
              content={(
                <Menu>
                  <Menu.Group>
                    {_.map(filteredRecipes, (recipe) => (
                      <Menu.Item key={recipe.id} onSelect={() => onRecipeSelect(recipe)}>{recipe.name}</Menu.Item>
                    ))}
                  </Menu.Group>
                </Menu>
              )}
          >
            <TextInputField
                required
                label="TO DO"
                placeholder="Enter a task, or begin typing a recipe name ..."
                value={editingTask.recipeFilter || ''}
                onChange={(e) => onFieldChange('recipeFilter', e.target.value)}
            />
          </Popover>
          <Pane textAlign="right">
            <Button appearance="primary" onClick={() => addStep()}>Add Step</Button>
          </Pane>
        </Pane>
      </CustomDialog>

      <RemovingTask
        removingTask={removingTask}
        setRemovingTask={setRemovingTask}
        removeConfirmed={removeConfirmed}
      />
    </React.Fragment>
  );
};

PrepTab.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default PrepTab;
