import { getCollectionRef } from './common';

export const addTasksHistoryItem = (taskItem, accountId) => {
  const tasksHistoryRef = getCollectionRef('tasksHistory', accountId);
  return tasksHistoryRef.add(taskItem);
};

export const removeTasksHistoryItem = (taskItemId, accountId) => {
  const tasksHistoryRef = getCollectionRef('tasksHistory', accountId);
  return tasksHistoryRef.doc(taskItemId).delete();
};

export const updateTasksHistoryItem = (taskItemId, taskItem, accountId) => {
  const tasksHistoryRef = getCollectionRef('tasksHistory', accountId);
  return tasksHistoryRef.doc(taskItemId).update(taskItem);
};
