//React
import React from 'react';
import { useLocation, Link, Routes, Route, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, TabNavigation } from 'evergreen-ui';

//Component
import Page from '../../components/Page/Page';
import Tab from '../../components/ui/Tab/Tab';
import WastageTab from './components/WastageTab';
import StocktakeTab from './components/StocktakeTab';
import StocktakeEditTab from './components/StocktakeEditTab';
import LiveStockTab from './components/LiveStockTab';
import TransferTab from './components/TransferTab';

//Files
import useConditionalRedirect from '../../hooks/useConditionalRedirect';

const tabs = {
  wastage: 'Wastage',
  stocktake: 'Stocktake',
  transfer: 'Transfer',
  livestock: 'Theorectical Stocktake'
};


const Stocks = () => {
  const { accountId } = useParams();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const location = useLocation();
  const currentTab = location.pathname.split('/')[3] || 'wastage';
  requiresWorkingAccount();

  return (
    <Page title={tabs[currentTab]} flex="1 0 auto" justifyContent="flex-start">
      <TabNavigation
          display="flex"
          flexShrink={0}
          overflowX="auto"
          paddingBottom={majorScale(2)}
      >
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/${accountId}/inventory/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>
      <Routes>
        <Route path="wastage" element={<WastageTab accountId={accountId} />} />
        <Route path="stocktake/add" element={<StocktakeEditTab accountId={accountId} />} />
        <Route path="stocktake/:stocktakeId" element={<StocktakeEditTab accountId={accountId} />} />
        <Route path="stocktake" element={<StocktakeTab accountId={accountId} />} />
        <Route path="livestock" element={<LiveStockTab accountId={accountId} />} />
        <Route path="transfer" element={<TransferTab accountId={accountId} />} />
        <Route index element={<WastageTab accountId={accountId} />} />
      </Routes>
    </Page>
  );
};

export default Stocks;
