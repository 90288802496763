//React
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Table,} from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//files
import { shortDateDisplayFormat } from '../../../utils/format';
import { getStocktakeDate } from '../../../utils/functions';
import { current } from '../../../utils/selectors';


const StocktakeTab = () => {
  const { accountId } = useParams();
  const drafts = useSelector((state) => _.sortBy(
    _.filter(current(state, 'stockTakes', accountId), { 'isDraft': true }),
    (draft) => getStocktakeDate(draft)
  ));

  return (
    <Block>
      <Pane display="flex" alignItems="center" marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="3" flex="1 0 auto">Draft Stocktakes</CustomHeading>
        <Button
            appearance="primary"
            is={Link}
            to={`/${accountId}/inventory/stocktake/add`}
        >New Stocktake</Button>
      </Pane>
      <Table>
        <Table.Head height={48}>
          <Table.TextHeaderCell flex="1 0 50%">Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(drafts) && (
            <Table.Row height={48}>
              <Table.TextCell padding={majorScale(1)}>No drafts to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(drafts, (draft) => (
            <Table.Row height={48} key={draft.id}>
              <Table.TextCell flex="1 0 50%">{shortDateDisplayFormat(getStocktakeDate(draft))}</Table.TextCell>
              <Table.Cell>
                <IconWrapper
                    is={Link}
                    to={`/${accountId}/inventory/stocktake/${draft.id}`}
                    name="edit"
                    appearance="clickable"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Block>
  );
};

export default StocktakeTab;
