import { getCollectionRef } from './common';
import fb from './';

export const addModifier = (modifier, accountId, userName) => {
  const modifiersRef = getCollectionRef('modifiers', accountId);

  // Set default values if not provided
  const defaultModifier = {
    min: modifier.min || '1',
    max: modifier.max || '1',
    no_max: modifier.no_max || false,
    multiselect: modifier.multiselect || false,
    description: modifier.description || '',
    ...modifier,
    createdBy: userName,
    createdAt: fb.serverTime(),
    updatedBy: null,
    updatedAt: null,
    deleted: false
  };

  return modifiersRef.add(defaultModifier);
};

export const updateModifier = async(modifierId, modifier, accountId, userName) => {
  const modifiersRef = getCollectionRef('modifiers', accountId);
  await modifiersRef.doc(modifierId).update({
    ...modifier,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
  console.log(`Modifier ${modifierId} updated in modifiers collection.`);
};

export const updateRelatedRecipes = async (modifierId, modifier, accountId, userName) => {
  //console.log(`Updating related recipes for modifier: ${modifierId}, Account: ${accountId}, User: ${userName}`);
  // Get all recipes
  const recipesRef = getCollectionRef('recipes', accountId);
  const snapshot = await recipesRef.get();

  //console.log(`Found ${snapshot.size} recipes in total`);
  const batch = recipesRef.firestore.batch();

  snapshot.forEach(doc => {
    const recipe = doc.data();
    console.log(`Processing recipe: ${recipe.id}`);
    let ingredientFound = false;

    if (!Array.isArray(recipe.ingredients)) {
      console.log(`No ingredients found for recipe: ${recipe.id}`);
      return;
    }

    const updatedIngredients = recipe.ingredients.map(ingredient => {
      //console.log(`Processing ingredient: ${ingredient.id}`);
      if (ingredient.id === modifierId) {
        ingredientFound = true;
        //console.log(`Found matching modifier ingredient in recipe: ${recipe.id}`);

        // Update or remove nested ingredients if they exist
        const updatedSubIngredients = Array.isArray(ingredient.ingredients) ? ingredient.ingredients.reduce((acc, subIngredient) => {
          const matchingModifierIngredient = modifier.ingredients.find(modIng => modIng.id === subIngredient.id);
          if (matchingModifierIngredient) {
            acc.push({
              ...subIngredient,
              name: matchingModifierIngredient.name,
              quantity: matchingModifierIngredient.quantity,
            });
          }
          // If no matchingModifierIngredient, subIngredient is removed
          return acc;
        }, []) : ingredient.ingredients;

        const updatedIngredient = {
          ...ingredient,
          ingredients: updatedSubIngredients, 
          min: modifier.min !== undefined ? modifier.min : 0, // Provide a default value
          max: modifier.max !== undefined ? modifier.max : 0, // Provide a default value
          no_max: modifier.no_max !== undefined ? modifier.no_max : false, // Provide a default value
          multiselect: modifier.multiselect !== undefined ? modifier.multiselect : false, // Provide a default value
        };

        //console.log(`Updated ingredient: ${JSON.stringify(updatedIngredient)}`);
        return updatedIngredient;
      }
      return ingredient;
    });

    if (ingredientFound) {
      const updateData = {
        ingredients: updatedIngredients,
        updatedBy: userName || null,
        updatedAt: fb.serverTime(),
      };

      // Replace undefined values with null
      Object.keys(updateData).forEach(key => {
        if (updateData[key] === undefined) {
          updateData[key] = null;
        }
      });

      console.log('Update Data:', updateData);

      batch.update(doc.ref, updateData);
    }
  });

  await batch.commit();
  console.log(`Batch update committed for modifier: ${modifierId}`);
};

export const removeModifier = async (modifierId, accountId, userName) => {
  const modifiersRef = getCollectionRef('modifiers', accountId);
  await modifiersRef.doc(modifierId).update({
    deleted: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

export const removeRelatedRecipes = async (modifierId, accountId, userName) => {
  // Get all recipes
  const recipesRef = getCollectionRef('recipes', accountId);
  const snapshot = await recipesRef.get();

  const batch = recipesRef.firestore.batch();

  snapshot.forEach(doc => {
    const recipe = doc.data();
    //console.log(`Processing recipe: ${recipe.id}`);
    let ingredientFound = false;

    if (!Array.isArray(recipe.ingredients)) {
      //console.log(`No ingredients found for recipe: ${recipe.id}`);
      return; // Skip to the next recipe if ingredients are not an array
    }

    const updatedIngredients = recipe.ingredients.filter(ingredient => {
      if (ingredient.id === modifierId) {
        ingredientFound = true;
        //console.log(`Found matching modifier ingredient in recipe: ${recipe.id}`);
        return false; // Remove the ingredient with the matching modifierId
      }
      return true; // Keep the ingredient
    });

    if (ingredientFound) {
      //console.log(`Updated ingredients for recipe: ${recipe.id}, ingredients: ${JSON.stringify(updatedIngredients)}`);
      batch.update(doc.ref, {
        ingredients: updatedIngredients,
        updatedBy: userName,
        updatedAt: fb.serverTime(),
      });
    }
  });

  await batch.commit();
  console.log(`Batch update committed for removing modifier: ${modifierId}`);
};

