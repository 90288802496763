//React
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, toaster, useTheme } from 'evergreen-ui';

//Components
import RecipeEditForm from './RecipeEditForm';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import TabNavigation from '../../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../../components/ui/Tab/Tab';
import RecipeUsedIn from './RecipeComponents/RecipeUsedIn';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import  { getMenusUsingRecipe, getRecipesUsingRecipe, getModifiersUsingRecipe } from '../../../utils/recipes';
import  { formatDate } from '../../../utils/format';

//accountId here is = to the groupId where recipes belong to
const RecipeEdit = ({ accountId }) => {
  const  { recipeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const menus = useSelector((state) => state.menus[accountId] || []);
  const recipes = useSelector((state) => state.recipes[accountId] || []);
  const modifiers = useSelector((state) => state.modifiers[accountId] || []);
  const [selectedTab, setSelectedTab] = useState('recipe');
  const [errors, setErrors] = useState({});

  const parentPage = `/products/${accountId}/setup/recipes`;
  const recipe = useSelector((state) => {
    if (recipeId) {
      return _.find(current(state, 'recipes', accountId), { id: recipeId }) || false;
    }
    return false;
  });

  const formId = recipeId ? `recipe_${recipeId}` : 'new_recipe';
  const recipeForm = useSelector((state) => {
    return state.forms[formId] ? state.forms[formId] : {}
  });

  const recipeInfo = recipeForm.data;
  const recipeImg = recipeForm.image;

  useEffect(() => {
    if (_.isEmpty(recipeInfo) && recipe) {
      dispatch(actions.forms.formChange(recipe || {}, formId))
    }
  }, [recipeInfo, recipe, formId, dispatch]);

  const changeRecipe = (recipe) => dispatch(actions.forms.formChange(recipe, formId));
  const changeRecipeImage = (image) => dispatch(actions.forms.formImageChange(image, formId));
  const isReadonly = !!recipe.readonly;

  const callback = () => {
    dispatch(actions.forms.formClear(formId))
    navigate(parentPage);
  }

  const validateFields = () => {
    const newErrors = {};
  
    if (!recipeInfo.name || recipeInfo.name.trim() === '') {
      newErrors.name = 'Name is required.';
    }
    if (!recipeInfo.categoryId) {
      newErrors.category = 'Category is required.';
    }
    if (!recipeInfo.departments || recipeInfo.departments.length === 0) {
      newErrors.departments = 'At least one department is required.';
    }
    if (!recipeInfo.yield) {
      newErrors.yield = 'Yield is required.';
    } else if (isNaN(recipeInfo.yield) || !isFinite(recipeInfo.yield)) {
      newErrors.yield = 'Yield must be a valid number.';
    }
    if (!recipeInfo.yieldDescription || recipeInfo.yieldDescription.trim() === '') {
      newErrors.yieldDescription = 'Yield Description is required.';
    }
    if (recipeInfo.menuprice && recipeInfo.menuprice.trim() !== '') {
      if (!recipeInfo.vat || recipeInfo.vat.trim() === '') {
        newErrors.vat = 'VAT is required when Gross price is filled.';
      }
      if (!recipeInfo.wastage || recipeInfo.wastage.trim() === '') {
        newErrors.wastage = 'Wastage is required when Gross price is filled.';
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toaster.danger(
        <div>
          {Object.values(newErrors).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      );
      return false;
    }

    return true;
  };

  const saveRecipe = () => {
    if (validateFields()) {
      if (recipeId) {
        dispatch(actions.recipes.updateRecipe(accountId, recipeInfo, recipeImg, callback));
      } else {
        dispatch(actions.recipes.addRecipe(accountId, recipeInfo, recipeImg, callback));
      }
    }
  };

  const resetRecipe = () => {
    const resetTo = recipe || {};
    dispatch(actions.forms.formChange(resetTo, formId));
  }

  const lastEditedText = () => {
    if (recipe?.updatedBy || recipe?.createdBy) {
      const editedBy = recipe?.updatedBy ? recipe?.updatedBy : recipe?.createdBy;
      const editedAt = recipe?.updatedAt ? formatDate(recipe?.updatedAt) : formatDate(recipe?.createdAt);
      if (editedAt) {
        return `Last edited by: ${editedBy} on: ${editedAt}`;
      }
    }
    return '';
  };

  const usageInfo = {
    menusUsingRecipe: getMenusUsingRecipe(menus, recipeId),
    recipesUsingRecipe: getRecipesUsingRecipe(recipes, recipeId),
    modifiersUsingRecipe: getModifiersUsingRecipe(modifiers, recipeId),
  };

  const backNav = () => {
    window.history.back();
  }

  return (
    <>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(2)}
      >
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center">
            <IconWrapper
                appearance="clickable"
                onClick={backNav}
                name="arrowLeft"
                marginRight={majorScale(2)}
            />
            <CustomHeading
                level="3"
                flex="1 0 auto"
            >
              {(recipeId) ? recipe.name || 'Recipe Name' : 'New Recipe'}
            </CustomHeading>
          </Pane>
          {lastEditedText() && (
            <Text marginTop={majorScale(1)}>{lastEditedText()}</Text>
          )}
        </Pane>
        <Pane display="flex" alignItems="center">
          <Button
              appearance="primary"
              onClick={() => saveRecipe()}
              disabled={isReadonly || _.isEmpty(recipeInfo)}
          >Save</Button>
          <Button
              appearance="minimal"
              onClick={resetRecipe}
              disabled={isReadonly || _.isEmpty(recipeInfo)}
              marginLeft={majorScale(2)}
          >{recipeId ? 'reset' : 'clear'}</Button>
        </Pane>
      </Block>

      <Pane>
        <TabNavigation>
          <Tab key="recipe" isSelected={selectedTab === 'recipe'} onSelect={() => setSelectedTab('recipe')}>
            Recipe
          </Tab>
          <Tab key="usedIn" isSelected={selectedTab === 'usedIn'} onSelect={() => setSelectedTab('usedIn')}>
            Used In
          </Tab>
        </TabNavigation>
        <Pane>
          {selectedTab === 'recipe' && (
            <RecipeEditForm
              accountId={accountId}
              recipe={recipeInfo}
              onChange={changeRecipe}
              onImgChange={changeRecipeImage}
              errors={errors}
            />
          )}
          {selectedTab === 'usedIn' && (
            <>
            <RecipeUsedIn usageInfo={usageInfo} theme={theme} /> 
            </>
          )}
        </Pane>
      </Pane>
    </>
  )
};

RecipeEdit.propTypes = {
  accountId: PropTypes.string.isRequired,
  recipeId: PropTypes.string,
};

RecipeEdit.defaultProps = {
  recipeId: '',
};

export default RecipeEdit;
