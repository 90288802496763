//React
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment, { isMoment } from 'moment';

//Files
import { getStocktakeDate } from '../utils/functions';
import { current } from '../utils/selectors';
import { getWC, getWE, maxDate, minDate, parseDate, TODAY, TODAYC, TODAYE } from '../utils/time';
import { useDate } from '../context/DateContext';

const dateStoreFormat = 'YYYY-MM-DD';

const useReportWeeks = ({ accountId }) => {
  const { startDate, endDate, setWeek } = useDate();

  // ignore stock?
  const firstStockTakeDate = useSelector((state) => {
    if (!accountId) return null;

    const firstStockTake = _.head(
      _.sortBy(
        _.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }),
        (item) => getStocktakeDate(item)
      )
    );

    return (firstStockTake) ? getStocktakeDate(firstStockTake) : TODAY;
  });

  useEffect(() => {
    //console.log("Initial start date from context:", startDate);
    //console.log("Initial end date from context:", endDate);
  }, [startDate, endDate]);

  const weekInfo = {
    start: startDate.toDate(),
    end: endDate,
  };

  const setWeekInfo = useCallback(({ start, end }) => {
    //console.log("Setting week info:", { start, end });
    setWeek(start, end);
  }, [setWeek]);

  const seePrevWeek = () => {
    const prevWeek = moment.utc(weekInfo.start).add(-7, 'day');
    // Move to the previous week, or to the date of the first stocktake
    const prevStart = firstStockTakeDate ?
      maxDate(prevWeek, moment.utc(firstStockTakeDate || TODAYC, dateStoreFormat)) :
      prevWeek;
    setWeekInfo({
      start: getWC(prevStart),
      end: minDate(getWE(prevStart), TODAY),
    });
  };

  const seeNextWeek = () => {
    // Advance to the next week, but not if we run past TODAY
    const nextStart = minDate(moment.utc(weekInfo.start).add(7, 'day'), TODAYC);
    setWeekInfo({
      start: getWC(nextStart),
      end: minDate(getWE(nextStart), TODAY),
    });
  };
  return {
    weekInfo,
    seeNextWeek,
    seePrevWeek
  }
}

export default useReportWeeks;
