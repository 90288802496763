//React
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Checkbox, Text, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import DataTable from '../../../components/DataTable/DataTable';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { current } from '../../../utils/selectors';
import { ingredientCompleteAllergens } from '../../../utils/functions';


const IngredientEdit = () => {
  const { accountId, ingredientId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const isTablet = useMediaQuery({ minWidth: 461, maxWidth: 768 });
  const allergenList = useSelector((state) => state.allergens);
  const categoryMap = useSelector((state) => _.keyBy(current(state, 'ingredientCategories', accountId), 'id'));
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const ingInfo = useSelector((state) => {
    if (ingredientId) {
      return _.find(current(state, 'ingredients', accountId), { id: ingredientId }) || false;
    }
    return false;
  });
  const supplierOptionList = useSelector((state) => _.filter(
    current(state, 'supplierOptions', accountId),
    { 'ingredientId': ingredientId }
  ));
  const ingredientAllergens = ingredientCompleteAllergens(ingInfo);

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Supplier', field: 'supplierId', type: 'text', format: (supplierId) => ((supplierMap[supplierId]) ? supplierMap[supplierId].name : ''), width: 3 },
    { label: 'UOM', field: 'uom', type: 'text', width: 2 },
    { label: 'Price', field: 'unitprice', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
  ];

  const displayFields = [
    { label: 'Item Code', field: 'itemcode', width: 3 },
    { label: 'Category', displayFn: (ing) => ((ing.categoryId && categoryMap[ing.categoryId]) ? categoryMap[ing.categoryId].name : ''), width: 3 },
    { label: 'Recipe UOM', field: 'recipeunit', width: 2 },
  ];

  return (
    <React.Fragment>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          height={48}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={Link}
            to={`/${accountId}/front-of-house/ingredients`}
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3" flex="1 1 auto" color={theme.colors.black}>{ingInfo.name}</CustomHeading>
      </Block>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="grid"
            gridTemplateColumns={(isMobile) ? 'auto' : (isTablet) ? 'auto auto' : 'repeat(3, auto)'}
            columnGap={majorScale(4)}
            rowGap={majorScale(2)}
            marginBottom={majorScale(2)}>
          {_.map(displayFields, (fieldDesc, key) => (
            <Pane
                key={key}
                display="flex"
            >
              <Text flex="1 1 auto" marginRight={majorScale(4)}>{fieldDesc.label}</Text>
              <Text color={theme.colors.tertiary100}>{(fieldDesc.field) ? ingInfo[fieldDesc.field] : fieldDesc.displayFn(ingInfo)}</Text>
            </Pane>
          ))}
        </Pane>

        <CustomHeading level='4' marginBottom={majorScale(2)}>Linked to</CustomHeading>
        <DataTable
            headers={tableHeaders}
            items={supplierOptionList}
            tableProps={{ marginBottom: majorScale(2) }}
        />

        <CustomHeading level="4"marginBottom={majorScale(2)}>Allergens</CustomHeading>
        <Pane
            paddingLeft={majorScale(2)}
            display="flex"
            flexFlow="row wrap"
        >
          {_.map(allergenList, (allergen) => {
            const isChecked = _.includes(ingredientAllergens, allergen.name);
            return (
              <Checkbox
                  key={allergen.id}
                  label={allergen.name}
                  checked={isChecked}
                  disabled={true}
                  readOnly
                  flexBasis="15%"
                  marginRight={majorScale(2)}
              />
            )
          })}
        </Pane>
      </Block>
    </React.Fragment>
  );
};

IngredientEdit.defaultProps = {
  ingredientId: '',
};

export default IngredientEdit;
