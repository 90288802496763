//React
import React from 'react';
import { useParams } from 'react-router-dom';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';

//Components
import Comment from '../../../components/Comment/Comment';
import TaskCommentList from '../../../components/TaskCommentList/TaskCommentList';
import useTaskComments from '../../../hooks/useTaskComments';

const CommentPane = ({ type, date }) => {
  const { accountId } = useParams();
  const {
    onCommentSave,
    tasksComments
  } = useTaskComments({ accountId, date });
  return (
    <Pane padding={majorScale(1)}>
      <TaskCommentList comments={tasksComments[type]} />
      <Comment onSave={(text) => onCommentSave({ type, comment: text })} />
    </Pane>
  )
}

export default CommentPane;
