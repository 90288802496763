//React
import React from 'react';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import MultiselectFilterBar from '../../../components/MultiselectFilterBar/MultiselectFilterBar';

const createOptions = (options) => {
  return _.map(
    _.sortBy(
      _.uniqBy(
        options,
        'name'
      ),
      'name'
    ),
    (item) => ({ label: item.name, value: item.name })
  );
}

const createSupplierOptions = (map) => {
  let options = [];
  for (const s in map) {
    options.push(map[s]);
  }
  return createOptions(options);
}

const buildFilterFields = (filterFields, options) => {
  return filterFields.filter(field => {
    if (options[field.name]) return false;

    return true;
  })
}

const ReportFilters = ({accounts, hideFields = {suppliers: true, ingredientCategories: true}}) => {
  const { filters, updateFilters } = accounts.filterProps || {};
  const locationOptions = createOptions(accounts.allLocations);
  const departmentOptions = createOptions(accounts.allDepartments);
  const areasOptions = createOptions(accounts.allAreas);
  const categoryOptions = createOptions(accounts.allCategories);
  const ingCatOptions = accounts.ingredientCategories || [];
  const supplierOptions = accounts.supplierMap ? createSupplierOptions(accounts.supplierMap) : [];
  const filterFields = [
    { label: 'Locations', name: 'locations', options: locationOptions },
    { label: 'Areas', name: 'areas', options: areasOptions },
    { label: 'Groups', name: 'departments', options: departmentOptions },
    { label: 'Categories', name: 'categories', options: categoryOptions },
    { label: 'Suppliers', name: 'suppliers', options: supplierOptions },
    { label: 'Categories', name: 'ingredientCategories', options: ingCatOptions }
  ];
  const finalFields = buildFilterFields(filterFields, hideFields);

  return (
    <Pane>
      <MultiselectFilterBar
          filterFields={finalFields}
          filters={filters}
          hideSearch
          setFilter={updateFilters}
          marginBottom={majorScale(2)}
      />
    </Pane>
  )
}

export default ReportFilters;
