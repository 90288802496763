import moment from 'moment'
import _ from 'lodash';

// Set locale to English for consistent date formatting
moment.locale('en')
const userTimeZone = 'Europe/London';

/**
 * Rounds a floating-point number to a specified number of decimal places.
 * @param {number} _float - The float to round.
 * @param {number} _digits - The number of decimal places to round to.
 * @return {number} The rounded number.
 */

function roundedToFixed (_float, _digits) {
  var rounder = Math.pow(10, _digits)
  return Math.round(_float * rounder) / rounder
}

/**
 * Calculates the difference in hours between two timestamps.
 * @param {Date|string} startTS - Start timestamp.
 * @param {Date|string} endTS - End timestamp.
 * @return {number} The difference in hours, rounded to one decimal place.
 */
export function differenceHours (startTS, endTS) {
  var end = moment.utc(endTS)
  var start = moment.utc(startTS)
  var duration = moment.duration(start.diff(end))
  const hours = duration.asHours()
  return roundedToFixed(hours, 1)
}

/**
 * Parses a date string into a JavaScript Date object.
 * @param {string} dateStr - The date string in 'DD MMM YYYY' format.
 * @return {Date|null} The parsed date or null if input is falsy.
 */
export function parseDate (dateStr) {
  if (dateStr) {
    var date = moment.utc(dateStr, 'DD MMM YYYY')
    return date.toDate()
  }
  return null
}

/**
 * Returns the start of the week (Monday) for a given date.
 * @param {Date} date - The date from which the week should start.
 * @return {Date} The date representing the start of the week.
 */
export function getWC(date) {
  return moment.utc(date).startOf('isoWeek').toDate(); // ISO week starts on Monday
}

/**
 * Returns the end of the week (Sunday) for a given date, including the entire day.
 * @param {Date} date - The date from which the week should end.
 * @return {Date} The date representing the end of the week.
 */
export function getWE(date) {
  return moment.utc(date).endOf('isoWeek').toDate(); // End of week (Sunday), including the whole day
}

/**
 * Returns the start of the previous week (Monday) for a given date.
 * @param {Date} date - The date from which the previous week should start.
 * @return {Date} The date representing the start of the previous week.
 */
export function getPreviousWC(date) {
  return moment.utc(date).subtract(1, 'week').startOf('isoWeek').toDate(); // Subtract a week and then get the start
}

/**
 * Returns the end of the previous week (Sunday) for a given date, including the entire day.
 * @param {Date} date - The date from which the previous week should end.
 * @return {Date} The date representing the end of the previous week.
 */
export function getPreviousWE(date) {
  return moment.utc(date).subtract(1, 'week').endOf('isoWeek').toDate(); // Subtract a week and then get the end
}

/**
 * Formats a timestamp into a day of the week.
 * @param {Date|string} timestamp - The timestamp to format.
 * @return {string} The formatted day string.
 */
export function dayFormat(timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('ddd');
  } else {
    return '';
  }
}

/**
 * Formats a timestamp into a more detailed date and time string.
 * @param {Date|string} timestamp - The timestamp to format.
 * @return {string} The formatted date and time string.
 */
export function datetimeFormat(timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMM HH:mm');
  } else {
    return '';
  }
}

/**
 * Formats a timestamp into a concise date string.
 * @param {Date|string} timestamp - The timestamp to format.
 * @return {string} The formatted date string.
 */
export function dateFormat(timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMM');
  } else {
    return '';
  }
}

/**
 * Formats a timestamp into a date string with day of the week.
 * @param {Date|string} timestamp - The timestamp to format.
 * @return {string} The formatted date string with day.
 */
export function dateExtFormat(timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('ddd D MMM');
  } else {
    return '';
  }
}

/**
 * Formats a timestamp into a date string with the year.
 * @param {Date|string} timestamp - The timestamp to format.
 * @return {string} The formatted date string with year.
 */
export function dateYearFormat(timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('DD MMM YYYY');
  } else {
    return '';
  }
}

/**
 * Formats a timestamp into a time string.
 * @param {Date|string} timestamp - The timestamp to format.
 * @return {string} The formatted time string.
 */
export function timeFormat(timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('HH:mm');
  } else {
    return '';
  }
}

// Today's date at 00:00:00, used as a reference point for "today".
export const TODAY = moment.utc({ hours: 0, minutes: 0, seconds: 0 }).toDate();
export const TODAYC = moment.utc().startOf('day').toDate(); // Today at 00:00:00
export const TODAYE = moment.utc().endOf('day').toDate();   // Today at 23:59:59

// Convert UTC dates to the user's local timezone for display
/*export const localTodayStart = moment(TODAY).tz(userTimeZone).format();
export const localTodayEnd = moment(TODAYE).tz(userTimeZone).format();
console.log(localTodayStart, localTodayEnd )*/

/**
 * Return the earliest Date
 * @param  {...any} args List of Dates/Timestamps to compare
 */
export function minDate(...args) {
  return new Date(Math.min(..._.filter(args)));
}

/**
 * Return the latest Date
 * @param  {...any} args List of Dates/Timestamps to compare
 */
export function maxDate(...args) {
  return new Date(Math.max(..._.filter(args)));
}
