import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addDepartment, updateDepartment, removeDepartment } from '../firebase/departments';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* departmentsSaga() {
  yield takeEvery(actionTypes.departments.ADD_DEPARTMENT, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addDepartment, action.departmentInfo, accountId);
      yield put(actions.appMessageSuccess('Department Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Department could not be added'));
    }
  });
  yield takeEvery(actionTypes.departments.REMOVE_DEPARTMENT, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeDepartment, action.departmentId, accountId);
      yield put(actions.appMessageSuccess('Department Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Department could not be removed'));
    }
  });
  yield takeEvery(actionTypes.departments.UPDATE_DEPARTMENT, function* (action) {
    const { id, ...departmentInfo } = action.departmentInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateDepartment, id, departmentInfo, accountId);
      yield put(actions.appMessageSuccess('Department Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Department could not be updated'));
    }
  });
};
