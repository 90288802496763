const DEPARTMENTS_LOAD_SUCCESS = 'DEPARTMENTS_LOAD_SUCCESS';
const DEPARTMENTS_LOAD_FAILURE = 'DEPARTMENTS_LOAD_FAILURE';
const DEPARTMENTS_CLEAR = 'DEPARTMENTS_CLEAR';
const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';
const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';

export const actionTypes = {
  DEPARTMENTS_LOAD_SUCCESS,
  DEPARTMENTS_LOAD_FAILURE,
  DEPARTMENTS_CLEAR,
  ADD_DEPARTMENT,
  REMOVE_DEPARTMENT,
  UPDATE_DEPARTMENT
};


const departmentsLoadSuccess = (payload, account) => {
  return {
    type: DEPARTMENTS_LOAD_SUCCESS,
    payload,
    account
  };
};

const departmentsLoadFailure = (error) => {
  return {
    type: DEPARTMENTS_LOAD_FAILURE,
    error
  };
};

const departmentsClear = () => {
  return {
    type: DEPARTMENTS_CLEAR
  };
};

const addDepartment = (accountId, departmentInfo, callback) => {
  return {
    type: ADD_DEPARTMENT,
    departmentInfo,
    callback,
    accountId,
  };
};

const removeDepartment = (accountId, departmentId) => {
  return {
    type: REMOVE_DEPARTMENT,
    accountId,
    departmentId,
  };
};

const updateDepartment = (accountId, departmentInfo, callback) => {
  return {
    type: UPDATE_DEPARTMENT,
    departmentInfo,
    accountId,
    callback,
  };
};

export const actions = {
  departmentsLoadSuccess,
  departmentsLoadFailure,
  departmentsClear,
  addDepartment,
  removeDepartment,
  updateDepartment
};
