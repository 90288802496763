const WASTAGES_LOAD_SUCCESS = 'WASTAGES_LOAD_SUCCESS';
const WASTAGES_LOAD_FAILURE = 'WASTAGES_LOAD_FAILURE';
const WASTAGES_CLEAR = 'WASTAGES_CLEAR';
const ADD_WASTAGE = 'ADD_WASTAGE';
const REMOVE_WASTAGE = 'REMOVE_WASTAGE';
const UPDATE_WASTAGE = 'UPDATE_WASTAGE';

export const actionTypes = {
  WASTAGES_LOAD_SUCCESS,
  WASTAGES_LOAD_FAILURE,
  WASTAGES_CLEAR,
  ADD_WASTAGE,
  REMOVE_WASTAGE,
  UPDATE_WASTAGE
};


const wastagesLoadSuccess = (payload, account) => {
  return {
    type: WASTAGES_LOAD_SUCCESS,
    payload,
    account
  };
};

const wastagesLoadFailure = (error) => {
  return {
    type: WASTAGES_LOAD_FAILURE,
    error
  };
};

const wastagesClear = () => {
  return {
    type: WASTAGES_CLEAR
  };
};

const addWastage = (accountId, wastage) => {
  return {
    type: ADD_WASTAGE,
    wastage,
    accountId,
  };
};

const removeWastage = (accountId, wastageId) => {
  return {
    type: REMOVE_WASTAGE,
    wastageId,
    accountId,
  };
};

const updateWastage = (accountId, wastage) => {
  return {
    type: UPDATE_WASTAGE,
    wastage,
    accountId,
  };
};

export const actions = {
  wastagesLoadSuccess,
  wastagesLoadFailure,
  wastagesClear,
  addWastage,
  removeWastage,
  updateWastage
};
