import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const printContent = (element) => {
  if (!element) {
    console.error('Print content element is not provided.');
    return;
  }

  html2canvas(element, { scale: 2, useCORS: true }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height]
    });
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    
    // This outputs the PDF to a Blob
    pdf.output('bloburi', {filename: 'printContent.pdf'}).then((blobUrl) => {
      const printWindow = window.open(blobUrl, '_blank');
      if (printWindow) {
        printWindow.focus(); // Necessary for some browsers to trigger the print dialog
        printWindow.addEventListener('load', () => {
          printWindow.print();
          // Give it time to print before closing
          setTimeout(() => printWindow.close(), 1000);
        }, true);
      } else {
        console.error('Unable to open new window for printing.');
      }
    });
  }).catch((error) => {
    console.error('Error occurred during the print content generation:', error);
  });
};


export const generatePDF = (element, title = 'download', filename) => {
  if (!element) return;

  const pdfFilename = filename || `${title.replace(/\s+/g, '_').toLowerCase()}.pdf`;

  html2canvas(element, { scale: 2, useCORS: true }).then((canvas) => {
    const contentDataURL = canvas.toDataURL('image/png');
    
    const pdfWidth = 210;  // A4 width in mm
    const pdfHeight = 297;  // A4 height in mm
    const scale = 0.85;  // Scale down by 85%
    const scaledWidth = pdfWidth * scale;  // Calculate scaled width
    const topMargin = 5;  // Top margin in mm

    let imgWidth = scaledWidth;  // The image width on the PDF
    let imgHeight = canvas.height * imgWidth / canvas.width;  // The image height on the PDF

    const pdf = new jsPDF('p', 'mm', 'a4');
    const xOffset = (pdfWidth - scaledWidth) / 2;  // Calculate horizontal offset to center the image

    let position = topMargin;  // Initialize the vertical position for the first page

    // Add content to the first page
    pdf.addImage(contentDataURL, 'PNG', xOffset, position, imgWidth, imgHeight);
    
    // Calculate the remaining content height after the first page
    let heightLeft = imgHeight + topMargin - pdfHeight;

    // Add new pages if the content is longer than one page
    while (heightLeft > 0) {
      pdf.addPage();
      position = -1 * (imgHeight - heightLeft) + topMargin;  // Calculate position for the next page
      pdf.addImage(contentDataURL, 'PNG', xOffset, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save(pdfFilename);  // Save the PDF with the specified filename
  });
};
