//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';

//Components
import Page from '../../components/Page/Page';
import Dashboard from './Dashboard';
import Financials from './Financials';
import SalesTabs from './SalesTabs';
import CostsTabs from './CostsTabs';
import Receipts from './components/Receipts';

//Files
import useReportWeeks from '../../hooks/useReportWeeks';
import { buildAccountData } from '../../utils/accounts';


const unsetHierarchicalFilters = (filterName, prevFilters) => {
  // unset filters hierarchically
  if (filterName === 'locations') {
    prevFilters['departments'] = [];
    prevFilters['areas'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'areas') {
    prevFilters['departments'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'departments') {
    prevFilters['categories'] = [];
  }
  return {...prevFilters};
}

const initFilters = {
  locations: [],
  areas: [],
  departments: [],
  categories: [],
  suppliers: [],
  ingredientCategories: []
}

const Reports = ({ accountId: propAccountId }) => {
  const currentAccount = useSelector((state) => state.currentAccount);
  const accountId = propAccountId || currentAccount;
  const [filters, setFilters] = useState(initFilters);
  const weekProps = useReportWeeks({});
  console.log(weekProps, "weekProps Reports")
  const account = useSelector(state => state.accounts[accountId]);
  const data = buildAccountData(accountId, filters);
  //console.log(data, "DATTTA")

  const updateFilters = (filterName, value) => {
    setFilters(prevFilters => {
      let updatedFilters = {...prevFilters}; // Shallow copy of previous filters
      const currentValues = updatedFilters[filterName] || [];
      const index = currentValues.indexOf(value);
      if (index === -1) {
        updatedFilters[filterName] = [...currentValues, value];
      } else {
        updatedFilters[filterName] = currentValues.filter(item => item !== value);
      }
      return unsetHierarchicalFilters(filterName, updatedFilters);
    });
  };

  // Check if the current account and component are valid before rendering
  if (!currentAccount) {
    return null;
  }

  return (
    <Page title="Reports" flex="1 0 auto">
      <Routes>
        <Route path="dashboard/*" element={<Dashboard account={account} accounts={data} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        <Route path="financials/*" element={<Financials account={account} accounts={data} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        <Route path="sales/*" element={<SalesTabs account={account} accounts={data} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        <Route path="costs/*" element={<CostsTabs account={account} accounts={data} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        {account?.has_deliverect && (
          <Route path="orders/*" element={<Receipts account={account} data={data} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        )}
      </Routes>
    </Page>
  );
};

Reports.propTypes = {
  accountId: PropTypes.string
};

export default Reports;
