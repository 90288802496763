//React
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

//Libraries
import { Dialog, majorScale, minorScale, Pane, Switch, Text } from 'evergreen-ui';
import moment from 'moment';

//Components
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Button from '../../../components/ui/Button/Button';
import Block from '../../../components/ui/Block/Block';

//Files
import { actions } from '../../../store/actions';
import { createMomentTime, requestLightspeedAccess, requestSalesLink } from '../../../utils/functions';


const now = new Date();
const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);


const LightspeedLSeries = ({accountId, account, accountInfo}) => {
  const dispatch = useDispatch();
  const [openAdd, setOpen] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [requestDate, setRequestDateDate] = useState(yesterday);
  const openTime = account.opening_time || '08:00';
  const time = createMomentTime(openTime, moment(requestDate));
  const from = moment(time).format("YYYY-MM-DDTHH:mm");
  const to = moment(time).add(1,'day').format("YYYY-MM-DDTHH:mm");
  const doRequestSales = async () => requestSalesLink(accountId, from, to);
  const doDeactivate = () => {
    dispatch(actions.accounts.accountUpdate({
      ...accountInfo,
      has_lightspeed: false,
      access_token: null,
      refresh_token: null
    }));
  };
  const toggleLightspeed = () => {
    if (!accountInfo.has_lightspeed) requestLightspeedAccess(accountId);
    else setOpenDeactivate(true);
  }
  return (
    <Block marginY={majorScale(2)} padding={majorScale(2)}>
      <Pane display='flex'>
        <Text fontWeight='bold' marginBottom={majorScale(2)}>Lightspeed L-series</Text>
        <Switch
          onChange={toggleLightspeed}
          checked={!!accountInfo.has_lightspeed}
          marginX={majorScale(2)}
        />
      </Pane>
      <Button margin={minorScale(2)} appearance="primary" type='button' onClick={toggleLightspeed}>{accountInfo.has_lightspeed ? 'De-a' : 'A'}ctivate</Button>
      {
        account.has_lightspeed && account.refresh_token && (
          <Button margin={minorScale(2)} appearance="primary" type='button' onClick={() => setOpen(true)}>Request sales</Button>
        )
      }
      <Dialog
          isShown={openAdd}
          title="Request Lightspeed day"
          confirmLabel="Request"
          onCloseComplete={() => setOpen(false)}
          onConfirm={(close) => {
            close();
            doRequestSales();
          }}
      >
        <Pane padding={minorScale(4)}>
          <DatePickerInput
              required
              label="Sales date"
              placeholder="Please choose a date"
              // min={tomorrow}
              value={requestDate}
              onChange={(newDate) => setRequestDateDate(newDate)}
          />
        </Pane>
      </Dialog>
      <Dialog
          isShown={openDeactivate}
          title="Deactivate Lightspeed"
          confirmLabel="confirm"
          onCloseComplete={() => setOpenDeactivate(false)}
          onConfirm={(close) => {
            close();
            doDeactivate();
          }}
      >
        <Text>Are you sure you want to de-activate this Lightspeed integration?</Text>
      </Dialog>
    </Block>
  )
}

export default LightspeedLSeries;
