//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link as LinkRouter, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Paragraph, Link } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import LivestockCategory from './LiveStockCategory';
import CustomHeading from '../../../components/Headings/Headings'

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { getStocktakeDate } from '../../../utils/functions';
import moment from 'moment';
import { current } from '../../../utils/selectors';
import { buildLivestock } from '../../../utils/stock';


const LiveStockTab = () => {
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const { accountId } = useParams();
  const [ingFilters, setIngFilters] = useState({});
  const isSearching = !!(ingFilters.search)
  const categoryOptions = useSelector((state) => {
    return _.map(current(state, 'ingredientCategories', accountId), (cat) => ({ label: cat.name, value: cat.name, id: cat.id }));
  });
  const category = ingFilters.category ? _.find(categoryOptions, { value: ingFilters.category }) : categoryOptions[0];
  const ingFilterFields = [
    { label: 'Category', name: 'category', options: categoryOptions }
  ];
  const updateIngFilters = (filterName, value) => {
    setIngFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };
  const ingSearchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateIngFilters('search', '');
    }
    else {
      updateIngFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  // GET LATEST STOCKTAKE, AND ORDERS, SALES, WASTAGE IN THE PERIOD SINCE LATEST STOCKTAKE TO DATE
  const latestStocktake = useSelector((state) => {
    return _.head(
      _.reverse(
        _.sortBy(
          _.filter(
            { ...current(state, 'stockTakes', accountId) },
            { 'isDraft': false }
          ),
          (stocktake) => getStocktakeDate(stocktake)
        )
      )
    );
  });
  if (!latestStocktake) {
    return (
      <React.Fragment>
        <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
          <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
            <CustomHeading level="3" marginRight={majorScale(2)}>Theoretical Stock</CustomHeading>
          </Pane>
          <Pane>
            <Paragraph>Please add your first <Link to={`/${accountId}/inventory/stocktake/add`} is={LinkRouter}>stocktake</Link> to continue.</Paragraph>
          </Pane>
        </Block>
      </React.Fragment>
    );
  }

  const livestock = buildLivestock(accountId, latestStocktake);

  const ingTableHeaders = [
    { label: 'Name', field: 'name', width: 3 },
    { label: 'UOM', field: 'startRecordUOM', width: 1 },
    { label: 'Open. Stock', field: 'unitStockDisplay', type: 'numeric', width: 2 },
    { label: 'Purchase', field: 'orderQuantityDisplay', type: 'numeric', width: 2 },
    { label: 'Sales', field: 'saleQuantityDisplay', type: 'numeric', width: 2 },
    { label: 'Wastage', field: 'wastageQuantityDisplay', type: 'numeric', width: 2 },
    { label: 'Transfers', field: 'transferQuantityDisplay', type: 'numeric', width: 2 },
    { label: 'Th. Stock', field: 'livestockDisplay', type: 'numeric', width: 2 }
  ];  

  const mobileTableHeaders = [
    { label: 'Name', field: 'name', width: 10 },
    { label: 'UOM', field: 'startRecordUOM', width: 2 },
    { label: 'Open', field: 'unitStockDisplay', type: 'numeric', width: 2, row: 2 },
    { label: 'Purch.', field: 'orderQuantityDisplay', type: 'numeric', width: 2, row: 2 },
    { label: 'Sales', field: 'saleQuantityDisplay', type: 'numeric', width: 2, row: 2 },
    { label: 'Wast.', field: 'wastageQuantityDisplay', type: 'numeric', width: 2, row: 2 },
    { label: 'Trans.', field: 'transferQuantityDisplay', type: 'numeric', width: 2, row: 2 },
    { label: 'Theor.', field: 'livestockDisplay', type: 'numeric', width: 2, row: 2 }
  ];  

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="3" marginRight={majorScale(2)}>Theoretical Stock - {moment().format('DD/MM/YY')}</CustomHeading>
        </Pane>
        <FilterBar
            filterFields={ingFilterFields}
            filters={ingFilters}
            setFilter={updateIngFilters}
            searchPlaceholder="Search Ingredients"
            searchOnChange={ingSearchOnChange}
        />
      </Block>
      <Pane marginBottom={majorScale(2)} flex={`1 0 auto`} display="flex" flexDirection="column">
        {
          _.map(categoryOptions, (categoryOpt) =>
            <LivestockCategory
              key={categoryOpt.value}
              categoryOpt={categoryOpt}
              filters={ingFilters}
              tableHeaders={ingTableHeaders}
              mobileTableHeaders={mobileTableHeaders}
              isOpen={category.value === categoryOpt.value}
              setCategory={(category) => updateIngFilters('category', category)}
              isMobile={isMobile}
              items={livestock}
              isSearching={isSearching}
            />
          )
        }
      </Pane>
    </React.Fragment>
  );
};

export default LiveStockTab;
