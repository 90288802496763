//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, TextInputField, SelectField, FormField, Checkbox, Textarea, Table, Text } from 'evergreen-ui';

//Components
import EditIngredientDialog from './ModifierEditFormComponents/EditIngredientDialog';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomDialog from '../../components/Dialog/Dialog';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { actions } from '../../store/actions';
import { recipeCosting, getIngredientById, getRecipeById, ingRecipePrice } from '../../utils/functions';
import { currency, toFixed } from '../../utils/format';
import { messageTypes } from '../../utils/constants';
import { current } from '../../utils/selectors';


const ModifierEditForm = ({ accountId, modifier, onChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => current(state, 'modifierCategories', accountId));
  const [editingIngredient, setEditingIngredient] = useState(false);
  const [removingIngredient, setRemovingIngredient] = useState(false);
  const isReadonly = !!modifier.readonly;

  const onFieldChange = (field, newValue) => {
    const newModifier = { ...modifier, [field]: newValue };
    onChange(newModifier);
  };
  const enforceNumeric = (value) => {
    // TODO: Restrict characters to numeric and [.,]
    return value;
  };
  const addIngredient = (newIng) => {
    const editingIndex = editingIngredient.index;
    const listedIndex = _.findIndex(modifier.ingredients || [], { 'id': newIng.id });
    if (listedIndex >= 0 && editingIndex !== listedIndex) {
      // Ingredient is already part of the modifier!
      dispatch(actions.appMessageSet(`${newIng.name} is already in this modifier`, messageTypes.ERROR));
      return;
    }

    let ingredients;
    if (editingIndex >= 0) {
      // Splice the new ingredient info into the index being edited
      ingredients = _.concat(_.slice(modifier.ingredients, 0, editingIndex), newIng, _.slice(modifier.ingredients, editingIndex + 1));
    }
    else {
      // Add the ingredient to the list
      ingredients = _.concat(modifier.ingredients || [], newIng);
    }
    const newModifier =  { ...modifier, ingredients };
    onChange(newModifier);
  };
  const confirmRemove = (ingInfo) => {
    const ingredients = _.without(modifier.ingredients, ingInfo.item);
    const newModifier = { ...modifier, ingredients };
    if (newModifier.max && newModifier.max > ingredients.length) {
      newModifier.max = ingredients.length;
    }
    if (newModifier.min && newModifier.min > ingredients.length) {
      newModifier.min = ingredients.length;
    }
    onChange(newModifier);
  };
  const changeMutliSelect = (checked) => {
    const newModifier = { ...modifier, multiselect: checked };
    if (!checked) {
      newModifier.min = null;
      newModifier.max = null;
      newModifier.no_max = null;
    }
    onChange(newModifier);
  }
  const changeNoMax = (checked) => {
    const newModifier = { ...modifier, no_max: checked };
    if (checked) newModifier.max = null;
    onChange(newModifier);
  }
  const changeMultiValue = (field, value) => {
    if (parseInt(value) <= modifier.ingredients.length) {
      onFieldChange(field, value)
    }
  }

  const category = modifier.categoryId ?
    categoryList.find(c => c.id === modifier.categoryId) :
    modifier.category ?
    categoryList.find(c => c.name === modifier.category) :
    null;

  const setCategory = (name) => {
    const cat = categoryList.find(c => c.name === name);
    onFieldChange('category', name);
    onFieldChange('categoryId', cat?.id);
  }

  const handleRowSelect = (item) => {
    let path;
    const type = item.type || 'ingredient'; 
  
    switch (type) {
      case 'ingredient':
        path = `/ingredients/${item.id}`;
        break;
      case 'subrecipe':
        path = `/products/${accountId}/setup/recipes/${item.id}`;
        break;
      default:
        path = '/';
        break;
    }
    navigate(path);
  };

  return (
    <>
      <EditIngredientDialog
          accountId={accountId}
          isShown={!!editingIngredient}
          existingIngredient={editingIngredient || null}
          onConfirm={addIngredient}
          onClose={() => {
            setEditingIngredient(false);
          }}
      />
      <CustomDialog
          isOpen={!!removingIngredient}
          title="Remove Modifier Ingredient"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={() => {
            confirmRemove(removingIngredient);
            setRemovingIngredient(false);
          }}
          onClose={() => setRemovingIngredient(false)}
      >
        <Pane margin={majorScale(2)}>
          <Text>Are you sure you wish to remove {(removingIngredient.item) ? removingIngredient.item.name : 'ingredient'} from this modifier?</Text>
        </Pane>
      </CustomDialog>

      <Block
          display="flex"
          alignItems="stretch"
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
      >
        <Pane flex="1 1 0">
          <TextInputField
              label="Name"
              placeholder="Modifier Name"
              value={modifier.name || ''}
              onChange={(e) => onFieldChange('name', e.target.value)}
          />
          <Pane display="flex">
            <SelectField
                flex="1 1 0"
                label="Group modifier category"
                placeholder="Please select ..."
                value={category?.name || ''}
                onChange={(e) => setCategory(e.target.value)}
            >
              <option value=""></option>
              {categoryList.map((category) => (<option key={category.id} value={category.name}>{category.name}</option>))}
            </SelectField>
            <TextInputField
                flex="1 1 0"
                marginLeft={majorScale(2)}
                label="Group PLU"
                placeholder="Group PLU"
                value={modifier.plu || ''}
                onChange={(e) => onFieldChange('plu', e.target.value)}
            />
          </Pane>
          <FormField label="Description">
            <Textarea
                placeholder="Short description of plate for customers"
                value={modifier.description || ''}
                onChange={(e) => onFieldChange('description', e.target.value)}
            />
          </FormField>
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading level="4" flex="1 0 auto">Modifiers</CustomHeading>
          <Button
              icon="add"
              appearance='primary'
              onClick={() => setEditingIngredient({})}
              disabled={isReadonly}
          >Add Modifier</Button>
        </Pane>
        <Table>
          <Table.Head paddingRight={0}>
            <Table.TextHeaderCell >Ing</Table.TextHeaderCell>
            <Table.TextHeaderCell >Qty</Table.TextHeaderCell>
            <Table.TextHeaderCell >UOM</Table.TextHeaderCell>
            <Table.TextHeaderCell >Cost</Table.TextHeaderCell>
            {/*<Table.TextHeaderCell >PLU</Table.TextHeaderCell>
            <Table.TextHeaderCell >Price</Table.TextHeaderCell>
            <Table.TextHeaderCell >VAT</Table.TextHeaderCell>*/}
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>View</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>Del</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {modifier.ingredients && modifier.ingredients.map((item, index) => {
              const itemDetails = (item.type === 'subrecipe') ? getRecipeById(item.id) : getIngredientById(item.id);
              const calculatedFields = {
                recipeUnit: (item.type === 'subrecipe') ? 'portion or g' : itemDetails.recipeunit,
                cost: item.quantity * ((item.type === 'subrecipe') ? recipeCosting(itemDetails) : ingRecipePrice(itemDetails))
              };
              return (
                <Table.Row key={index}>
                  <Table.TextCell >{itemDetails ? itemDetails.name : 'N/A'}</Table.TextCell>
                  <Table.TextCell >{item.quantity}</Table.TextCell>
                  <Table.TextCell >{calculatedFields.recipeUnit}</Table.TextCell>
                  <Table.TextCell
                      // flexBasis={100}
                      // flexGrow={0}
                  >{toFixed(calculatedFields.cost, 4)}</Table.TextCell>
                  {/*<Table.TextCell >{item.plu}</Table.TextCell>
                  <Table.TextCell >{item.price}</Table.TextCell>
                  <Table.TextCell >{item.vat}</Table.TextCell>*/}
                  <Table.Cell flexBasis={56} flexGrow={0}>
                    <IconWrapper 
                      name="eye" 
                      appearance="clickable" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRowSelect(item);                      
                      }}
                      disabled={isReadonly} 
                    />
                  </Table.Cell>
                  <Table.Cell flexBasis={56} flexGrow={0}>
                    <IconWrapper 
                      name="edit" 
                      appearance="clickable" 
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingIngredient({ item, index });
                      }}
                      disabled={isReadonly} 
                    />
                  </Table.Cell>
                  <Table.Cell flexBasis={56} flexGrow={0}>
                    <IconWrapper 
                      name="trash" 
                      appearance="danger" 
                      onClick={(e) => {
                        e.stopPropagation();
                        setRemovingIngredient({ item, index });
                      }}
                      disabled={isReadonly} 
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Block>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
        >
          <CustomHeading level="4" flex='0 0 150px'>Multiselection</CustomHeading>
          <Checkbox
            checked={modifier.multiselect}
            onChange={(e) => changeMutliSelect(e.target.checked)}
            // flexBasis="15%"
            // marginRight={majorScale(2)}
          />
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
        >
          <Text flex='0 0 150px' paddingLeft={majorScale(1)} height={70}>Multi select</Text>
          <TextInputField
            flex="0 0 80px"
            // marginLeft={majorScale(2)}
            // label="Min"
            placeholder="Min"
            value={modifier.min || ''}
            onChange={(e) => changeMultiValue('min', e.target.value)}
            disabled={!modifier.multiselect}
            height={70}
          />
          <TextInputField
            flex="0 0 80px"
            marginLeft={majorScale(2)}
            // label="Min"
            placeholder="Max"
            value={modifier.max || ''}
            onChange={(e) => changeMultiValue('max', e.target.value)}
            disabled={!modifier.multiselect || modifier.no_max}
            height={70}
          />
          <Checkbox
            checked={modifier.no_max}
            onChange={(e) => changeNoMax(e.target.checked)}
            label='No Max'
            disabled={!modifier.multiselect}
            marginLeft={majorScale(2)}
            height={70}
            // flexBasis="15%"
            // marginRight={majorScale(2)}
          />
        </Pane>
      </Block>
    </>
  )
};

ModifierEditForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  modifier: PropTypes.object,
  onChange: PropTypes.func,
  onImgChange: PropTypes.func,
};

ModifierEditForm.defaultProps = {
  modifier: {},
  onChange: () => {},
  onImgChange: () => {},
};

export default ModifierEditForm;

