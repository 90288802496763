import _ from 'lodash';
import store from '../redux';
import fb from './'
import { actions } from '../actions';

let accountUsersListener = {};
const accountUsersRef = fb.firestore.collection('accountUsers')

// exported methods & fields
// Detailed loadAccountUsers to manage account user data efficiently
export const loadAccountUsers = (accountId) => {
  if (accountUsersListener[accountId]) {
    //For debugging purpose
    //console.log(`Detaching existing listener for account users: ${accountId}`);
    accountUsersListener[accountId]();
  }
  //For debugging purpose
  //console.log(`Loading account users for: ${accountId}`);
  store.dispatch(actions.applicationLoadStart(`${accountId}-accountUsers`));
  accountUsersListener[accountId] = accountUsersRef
    .where('account', '==', accountId)
    .onSnapshot((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      //For debugging purpose
      //console.log(`Loaded ${results.length} users for account: ${accountId}`);
      store.dispatch(actions.accountUsers.accountUsersLoadSuccess(results, accountId));
      store.dispatch(actions.applicationLoadComplete(`${accountId}-accountUsers`));
    });
};

// Clears all local listeners for account user data.
export const clearAccountUsers = () => {
  _.forIn(accountUsersListener, (listener) => listener());
  store.dispatch(actions.accountUsers.accountUsersClear());
};

// Adds a new user to the specified account.
export const addToAccount = (accountUser) => {
  return accountUsersRef.add(accountUser);
};

// Removes a user from an account by deleting the user's document in Firestore.
export const removeFromAccount = (accountUserId) => {
  return accountUsersRef.doc(accountUserId).delete();
};

// Removes all users associated with a given account ID from Firestore.
export const removeByAccountId = (accountId) => {
  if (accountUsersListener[accountId]) {
    accountUsersListener[accountId]();
  }
  return new Promise((resolve, reject) => {
    accountUsersRef.where('account', '==', accountId)
      .get()
      .then((querySnapshot) => {
        // Remove each account-user entry associated with the provided account id
        const { docs } = querySnapshot;
        for (let x = 0, ln = docs.length; x < ln; x++) {
          accountUsersRef.doc(docs[x].id).delete();
        }
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        reject(err.message);
      })
  })
}
