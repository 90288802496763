import _ from 'lodash';
import moment from 'moment';
import store from 'store/redux';
import { getModifierItemByPlu, modifierCost, modifierMargin, modifierNetPrice, recipeCosting, recipeMargin, recipeNetPrice } from './functions';
import { current } from './selectors';

const createImportName = (saleDate, dyanmic) => {
  return `${moment.utc(saleDate).format('DD-MM-YYYY')} Lightspeed Sales Import${dyanmic ? ` - imported at ${moment.utc().format('HH:mm')}` : ''}`;
}

const dateFormat = 'YYYY-MM-DD';

export const createLightspeedSale = (sales, date, accountId, dynamic = true) => {
  const state = store.getState();
  const importHistory = _.reverse(_.sortBy(current(state, 'sales', accountId), 'imported'));
  const recipePLUMap = _.keyBy(_.filter(current(state, 'recipes', accountId), (recipe) => !_.isEmpty(recipe.plu)), 'plu');
  const saleDate = moment.utc(date, dateFormat);
  let salesData = {
    filename: createImportName(saleDate, dynamic),
    items: [],
    unmatched: [],
    type: dynamic ? 'dynamic lightspeed' : 'lightspeed',
    start: saleDate.format(dateFormat),
    end: saleDate.format(dateFormat),
    imported: dynamic ? moment.utc() : new Date(date)
  };

  if (dynamic) salesData.id = 'dynamic_lightspeed';

  // Check for a conflicting import
  const conflicts = _.filter(importHistory, (item) => (
    item.type && ['dynamic','lightspeed'].includes(item.type) &&
    ((moment.utc(item.start, dateFormat).isSame(saleDate, 'day'))
    || (moment.utc(item.end, dateFormat).isSame(saleDate, 'day')))
  ));
  if (conflicts.length > 0) {
    // dispatch(actions.appMessageError('Conflict with an existing report! Could not complete import.'));
    console.warn('sales import conflict dates found!')
    return;
  }

  // Parse complete - now we move on to trying to match everything up with the recipes
  _.forEach(sales, (item) => {
    const plu = item.pid;
    // check for modifier first
    if (!recipePLUMap[plu]) {
      const modifier = getModifierItemByPlu(plu, accountId);
      // price changes between lightspeed and MEP
      if (modifier) {
        const menuprice = modifier.price || 0;
        const priceIssues = parseFloat(menuprice) !== parseFloat(item.price);
        salesData.items.push({
          plu,
          qty: item.amount,
          menuprice: modifier.price || 0,
          modifierId: modifier.modifierId,
          recipeId: modifier.id,
          cost: modifierCost(modifier),
          netprice: modifierNetPrice(modifier),
          margin: modifierMargin(modifier),
          type: 'modifier',
          name: modifier.name,
          priceIssues,
          vatIssues: false
          // wastage undefined will do a look up
          // wastage: 0
        });
      }
      else {
        salesData.unmatched.push({
          plu,
          qty: item.amount,
          name: item.name
        });
      }
    }
    else {
      const { id, menuprice, name, wastage, vat } = recipePLUMap[plu];
      const priceIssues = parseFloat(menuprice) !== parseFloat(item.price);
      const vatIssues = item.vatRate ? parseFloat(vat) !== parseFloat(item.vatRate) : null;
      salesData.items.push({
        qty: item.amount,
        recipeId: id,
        menuprice: parseFloat(menuprice) || 0,
        name,
        plu,
        wastage: wastage || 0,
        netprice: recipeNetPrice(recipePLUMap[plu]),
        margin: recipeMargin(recipePLUMap[plu]),
        cost: recipeCosting(recipePLUMap[plu]),
        priceIssues,
        vatIssues
      });
    }
  });
  return salesData;
}
