import { takeEvery, call, put, select } from 'redux-saga/effects';
import { addIngredient, updateIngredient, removeIngredient, archiveIngredient, uploadIngredients, removeIngredientFromRecipes } from '../firebase/ingredients';
import { actionTypes, actions } from '../actions';
import { activeAccount, currentUserName } from '../../utils/selectors';

export default function* ingredientsSaga() {
  yield takeEvery(actionTypes.ingredients.ADD_INGREDIENT, function* (action) {
    const accountId = yield select(activeAccount);
    const userName = yield select(currentUserName);
    try {
      const ingredient = yield call(addIngredient, action.ingredient, accountId, userName);
      yield put(actions.appMessageSuccess('Ingredient successfully added'));
      if (action.callback) {
        //need ingredient in the callback
        yield call(action.callback, ingredient);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Ingredient could not be added'));
    }
  });
  yield takeEvery(actionTypes.ingredients.UPDATE_INGREDIENT, function* (action) {
    const { id, ...ingredient } = action.ingredient;
    try {
      const accountId = yield select(activeAccount);
      const userName = yield select(currentUserName);
      yield call(updateIngredient, id, ingredient, accountId, userName);
      yield put(actions.appMessageSuccess('Ingredient successfully updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Ingredient could not be updated'));
    }
  });
  yield takeEvery(actionTypes.ingredients.REMOVE_INGREDIENT, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      const userName = yield select(currentUserName);
      yield call(removeIngredient, action.ingredientId, accountId, userName);
      yield put(actions.appMessageSuccess('Ingredient deleted'));
    }
    catch (err) {
      yield put(actions.appMessageError('Ingredient could not be deleted'));
    }
  });
  // Saga to handle archiving an ingredient
  yield takeEvery(actionTypes.ingredients.ARCHIVE_INGREDIENT, function* (action) {
    try {
        const accountId = yield select(activeAccount);
        const userName = yield select(currentUserName);
        yield call(archiveIngredient, action.ingredientId, accountId, userName);
        yield put(actions.appMessageSuccess('Ingredient deactivated')); 
    } catch (err) {
        yield put(actions.appMessageError('Ingredient could not be deactivated'));
    }
  });
  // Saga for removing ingredient from collections//
  yield takeEvery(actionTypes.ingredients.REMOVE_INGREDIENT_FROM_RECIPES, function* (action) {
    try {
      const state = yield select();
      const accountId = yield select(activeAccount);
      const userName = yield select(currentUserName);
      const { ingredientId } = action;
      yield call(removeIngredientFromRecipes, ingredientId, accountId, userName, state); // Pass the state
      yield put(actions.appMessageSuccess('Ingredient deleted from recipes successfully'));
    } catch (error) {
      console.error(error);
      yield put(actions.appMessageError('Failed to deleted ingredient from recipes'));
    }
  });
  yield takeEvery(actionTypes.ingredients.ADD_UPLOAD, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(uploadIngredients, accountId, accountId, action.file);
      yield put(actions.appMessageSuccess('Ingredients file uploaded'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Error uploading file'));
    }
  });
};
