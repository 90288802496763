//React
import React, { useState } from 'react';

//Libraries
import { majorScale, minorScale, Pane, Text, useTheme } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Header from '../components/Header';
import ViewMore from '../components/ViewMore';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block';

//Files
import { calcPercentage, recipeMargin, recipeCosting } from '../../../utils/functions';
import { aggregateFilterWeekSales } from '../../../utils/reports';


const calcSalesGp = (salesQty, salesNet, salesCost, recipe) => {
  if (salesQty === 0) return recipeMargin(recipe, true);

  return calcPercentage(salesNet - salesCost, salesNet, 1)
}

const summaryProductSales = ({ recipes, categories, accountsData, sales, limit }) => {
  //console.log(recipes)
  return recipes.reduce((salesData, recipe) => {
    const filters = {categories, ids: [recipe.id]};
    const prodSales = aggregateFilterWeekSales(accountsData, 'weekSales', filters);
    if (prodSales.weekSalesQty === 0 && (!recipe.plu || recipe.archive)) return salesData;

    // Calculate the total cost of the recipe
    const recipeCost = recipeCosting(recipe, false, true);
    const totalRecipeCost = recipeCost * prodSales.weekSalesQty;

    let extra = {};
    if (!limit) {
      const prevProdSales = aggregateFilterWeekSales(accountsData, 'prevWeekSales', filters);
      const gp = calcSalesGp(prodSales.weekSalesQty, prodSales.weekSalesNet, prodSales.weekSalesCost, recipe);
      //console.log(gp, 'GP')
      const prevGp = calcSalesGp(prevProdSales.weekSalesQty, prevProdSales.weekSalesNet, prevProdSales.weekSalesCost, recipe);
      //const cost = prodSales.weekSalesNet * ((100 - gp) / 100);

      extra.gp = gp;
      extra.prevGp = prevGp;
      extra.vat = recipe.vat || 20;
      extra.averageGross = prodSales.weekSalesQty > 0 ? prodSales.weekSalesTotal / prodSales.weekSalesQty : 0;
      extra.averageNet = prodSales.weekSalesQty > 0 ? prodSales.weekSalesNet / prodSales.weekSalesQty : 0;
      extra.salesTotal = calcPercentage(prodSales.weekSalesNet, sales.net, 1);
      //extra.cost = cost; // Adding the calculated cost to the extra object
    }
    const processed = {
      name: recipe.name,
      gross: prodSales.weekSalesTotal,
      net: prodSales.weekSalesNet,
      qty: prodSales.weekSalesQty,
      id: recipe.id,
      recipeCost,
      totalRecipeCost,
      ...extra
    }
    return salesData.concat(processed);
  }, []);
}

const PaneHeader = ({ title, primary = true }) => {
  const theme = useTheme();
  return (
    <Pane marginY={majorScale(2)} marginLeft={majorScale(2)}>
      <Text fontSize="18px" color={theme.colors.black} fontWeight={400}>{title}</Text>
    </Pane>
  )
}

const buildFinalCategories = (filterCategories, categories) => {
  categories = _.uniq(categories);
  if (!filterCategories) return categories;

  return categories.filter(c => filterCategories.indexOf(c) > -1);
}

const DepartmentProducts = ({ department, setDeprtmentView, isOpen, accounts, filterCategories, index, sales, ...rest }) => {
  const toggleOpen = () => isOpen ? setDeprtmentView(null) : setDeprtmentView(department);
  const categories = buildFinalCategories(filterCategories, accounts.categories.filter(c => c.department.id === department.id)?.map(c => c.name));
  if (categories.length === 0) return null;
  const sortedCategories = _.sortBy(categories); 

  const tableHeaders = [
    { label: 'Recipe Name', field: 'name', type: 'text', width: 4},
    { label: 'Gross', field: 'averageGross', type: 'numeric', prefix: '£', width: 1 },
    { label: 'VAT', field: 'vat', type: 'numeric', suffix: '%', width: 1 },
    { label: 'Net', field: 'averageNet', type: 'numeric', prefix: '£', width: 1 },
    { label: 'Qty', field: 'qty', type: 'numeric', width: 1},
    { label: 'Net revenue', field: 'net', type: 'numeric', prefix: '£', width: 2 },
    { label: 'R. Cost', field: 'recipeCost', type: 'numeric', prefix: '£', width: 1 },
    { label: 'T. Cost', field: 'totalRecipeCost', type: 'numeric', prefix: '£', width: 2 },
    { label: 'GP%', field: 'gp', type: 'text', suffix: '%', width: 1 },
    { label: 'vs LW%', field: 'prevGp', type: 'text', suffix: '%', width: 1 },
    { label: 'Sales %', field: 'salesTotal', type: 'numeric', suffix: '%', width: 1 }
  ]
  const totalHeaders = [
    { label: 'Recipe Name', field: 'name', type: 'text', width: 7 },
    //{ label: 'Gross', field: 'averageGross', type: 'text', prefix: '£', width: 2 },
    //{ label: 'VAT', field: 'vat', type: 'text', sufix: '%', width: 1 },
    //{ label: 'Net', field: 'averageNet', type: 'text', prefix: '£', width: 2 },
    { label: 'Qty', field: 'qty', type: 'numeric', width: 1 },
    { label: 'Net revenue', field: 'net', type: 'numeric', format: 'currency', prefix: '£', width: 3 },
    { label: 'Cost', field: 'totalRecipeCost', type: 'numeric', format: 'currency', prefix: '£', width: 4 },
    //{ label: 'GP%', field: 'gp', type: 'text', suffix: '%', width: 1 },
    //{ label: 'vs LW%', field: 'prevGp', type: 'text', suffix: '%', width: 1 },
    { label: 'Sales %', field: 'salesTotal', type: 'numeric', suffix: '%', width: 1 }
  ];

  return (
    <>
      <>
        {/* <PaneHeader title={department.name} /> */}
          {sortedCategories.map(category => {
            const recipes = accounts.recipes.filter(r =>
              r.department?.id === department.id &&
              r.category === category &&
              r.plu && !r.archive
            );
            //console.log({recipes, aR: accounts.recipes},'recipes');

            if (recipes.length === 0) {
              return null;
            }

            const catSales = summaryProductSales({
              ...rest,
              recipes,
              sales,
              categories: [category]
            });

            let final = _.sortBy(catSales, (s) => 1/s.net);

            const net = _.sumBy(final, 'net');
            const totalRecipeCost = _.sumBy(final, 'totalRecipeCost');
            const totals = {
              name: 'Total',
              qty: _.sumBy(final, 'qty'),
              net: net,
              totalRecipeCost: totalRecipeCost,
              salesTotal: calcPercentage(net, sales.net, 1),
              id: 1
            };

            return (
                <Pane key={category} marginBottom={majorScale(2)}>
                  <PaneHeader title={category} primary={false}/>
                  <DataTable
                    headers={tableHeaders}
                    items={final}
                  />
                  <DataTable
                    //tableProps={{ marginBottom: minorScale(2) }}
                    headers={totalHeaders}
                    items={[totals]}
                    hideHeader={true}
                    isTotals={true}
                  />
                </Pane>
            )
          })}
      </>
    </>
  )
}

const DepartmentsView = ({ accounts, categories, ...rest }) => {
  const [departmentView, setDeprtmentView] = useState(accounts.departments ? accounts.departments[0] : null);
  return (
    <>
    {
      accounts.departments.map((department, index) =>
        <DepartmentProducts
          key={department.id}
          index={index}
          department={department}
          accounts={accounts}
          filterCategories={categories}
          departmentView={departmentView}
          setDeprtmentView={setDeprtmentView}
          isOpen={department.id === departmentView?.id}
          {...rest}
        />
      )
    }
    </>
  )
}

const SummaryProductSales = ({ accounts, filterCategories, ...rest }) => {
  const sales = summaryProductSales({
    ...rest,
    recipes: accounts.recipes,
    categories: filterCategories,
    limit: true
  });

  let final = _.orderBy(sales, ['net'], ['desc']);

  const tableHeaders = [
    { label: 'Product Name', field: 'name', type: 'text', width: 5 },
    { label: 'Qty', field: 'qty', type: 'text', width: 3},
    { label: 'Net Sales', field: 'net', type: 'numeric', prefix: '£', width: 3 }
  ]
  return (
    <>
      <Pane height="500px" display="flex" flexDirection="column" justifyContent="space-between">
        <Pane height="400px" overflow="auto">
          <DataTable
            headers={tableHeaders}
            items={final}
            listHeight={340}
          />
        </Pane>
        <ViewMore to='products' />
      </Pane>
    </>
  )
}

const ProductSales = ({ accounts, limit = true, ...rest }) => {
  const categories = accounts.filterProps.filters?.categories?.length > 0 ?
    accounts.filterProps.filters?.categories :
    null;
  return (
    <Block width='100%'>
        <Header title='Sales by product' />
        {limit && (
          <SummaryProductSales
            accounts={accounts}
            filterCategories={categories}
            {...rest}
          />
        )}

        {!limit && (
          <DepartmentsView
            accounts={accounts}
            categories={categories}
            {...rest}
          />
        )}
    </Block>
  )
}

export default ProductSales;
