const INGREDIENTS_LOAD_REQUESTED = 'INGREDIENTS_LOAD_REQUESTED';
const INGREDIENTS_LOAD_SUCCESS = 'INGREDIENTS_LOAD_SUCCESS';
const INGREDIENTS_LOAD_FAILURE = 'INGREDIENTS_LOAD_FAILURE';
const INGREDIENTS_CLEAR = 'INGREDIENTS_CLEAR';
const ADD_INGREDIENT = 'ADD_INGREDIENT';
const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
const ARCHIVE_INGREDIENT = 'ARCHIVE_INGREDIENT';
const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT';
const REMOVE_INGREDIENT_FROM_RECIPES = 'REMOVE_INGREDIENT_FROM_RECIPES';
const ADD_UPLOAD = 'ADD_UPLOAD';

export const actionTypes = {
  INGREDIENTS_LOAD_REQUESTED,
  INGREDIENTS_LOAD_SUCCESS,
  INGREDIENTS_LOAD_FAILURE,
  INGREDIENTS_CLEAR,
  ADD_INGREDIENT,
  REMOVE_INGREDIENT,
  ARCHIVE_INGREDIENT,
  UPDATE_INGREDIENT,
  REMOVE_INGREDIENT_FROM_RECIPES,
  ADD_UPLOAD,
};


const ingredientsLoadRequest = (account) => {
  return {
    type: INGREDIENTS_LOAD_REQUESTED,
    account
  };
};

const ingredientsLoadSuccess = (payload, account) => {
  return {
    type: INGREDIENTS_LOAD_SUCCESS,
    payload,
    account
  };
};

const ingredientsLoadFailure = (error) => {
  return {
    type: INGREDIENTS_LOAD_FAILURE,
    error
  };
};

const ingredientsClear = () => {
  return {
    type: INGREDIENTS_CLEAR
  };
};

const addIngredient = (ingredientData, callback) => {
  return {
    type: ADD_INGREDIENT,
    ingredient: ingredientData,
    callback
  };
};

const removeIngredient = (ingredientId) => {
  return {
    type: REMOVE_INGREDIENT,
    ingredientId
  };
};

const archiveIngredient = (ingredientId) => {
  return {
    type: ARCHIVE_INGREDIENT,
    ingredientId
  };
};

const updateIngredient = (ingredientData, callback) => {
  return {
    type: UPDATE_INGREDIENT,
    ingredient: ingredientData,
    callback
  };
};

const removeIngredientFromRecipes = (ingredientId, usageInfo) => ({
  type: REMOVE_INGREDIENT_FROM_RECIPES,
  ingredientId,
  usageInfo,
});

const addUpload = (file, callback) => {
  return {
    type: ADD_UPLOAD,
    file,
    callback
  };
};

export const actions = {
  ingredientsLoadRequest,
  ingredientsLoadSuccess,
  ingredientsLoadFailure,
  ingredientsClear,
  addIngredient,
  removeIngredient,
  archiveIngredient,
  updateIngredient,
  removeIngredientFromRecipes,
  addUpload,
};
