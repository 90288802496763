import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Paragraph, Text, Avatar } from 'evergreen-ui';

import { timeDisplayFormat } from '../../utils/format';

const TaskCommentList = (props) => {
  const { comments } = props;

  if ( _.isEmpty(comments) ) {
    return null;
  }

  return _.map(comments, (comment) => (
    <Pane
        border="default"
        borderRadius={3}
        padding={majorScale(1)}
        marginTop={majorScale(2)}
        marginBottom={majorScale(2)}
        key={comment.id}
    >
      <Pane
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginBottom={majorScale(1)}
      >
        <Avatar
            size={24}
            name={comment.userName}
            marginRight={majorScale(1)}
        />
        <Text>{comment.created ? timeDisplayFormat(comment.created.toDate()) : ''}</Text>
      </Pane>
      <Paragraph size={300}>{comment.comment}</Paragraph>
    </Pane>
  ));
};

TaskCommentList.propTypes = {
  comments: PropTypes.array.isRequired
};

TaskCommentList.defaultProps = {
  comments: []
};

export default TaskCommentList;
