//React
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table} from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { getWC, getWE } from '../../../utils/time';
import { currency, dateFormat, datePeriodFormat } from '../../../utils/format';
import { colors } from '../../../utils/constants';
import { getWastageDate } from '../../../utils/functions';
import { current } from '../../../utils/selectors';


const WastageArchive = () => {
  const { accountId } = useParams();
  const weeklyWastages = useSelector((state) => {
    return _.mapValues(
      _.groupBy(
        _.reverse(
          _.sortBy(current(state, 'wastages', accountId), (wastage) => getWastageDate(wastage))
        ),
        (wastage) => dateFormat(getWC(getWastageDate(wastage)))
      ),
      (wastageGroup) => _.reduce(wastageGroup, (sum, wastage) => (sum + (isNaN(wastage.cost) ? 0 : wastage.cost)), 0)
    );
  });


  return (
    <Block marginBottom={majorScale(2)}>
      <CustomHeading level="3" marginY={majorScale(2)} paddingX={majorScale(2)}>Wastage</CustomHeading>
      <Table>
        <Table.Head height={48}>
          <Table.TextHeaderCell>Period</Table.TextHeaderCell>
          <Table.TextHeaderCell>Value</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={50} flexGrow={0}>View</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(weeklyWastages) && (
            <Table.Row height={48}>
              <Table.TextCell>No wastage to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(weeklyWastages, (wastageCost, periodStart) => {
            const startDate = moment.utc(periodStart, 'YYYY-MM-DD').toDate();
            const periodFormat = datePeriodFormat(startDate, getWE(startDate));
            return (
              <Table.Row height={48} key={periodStart}>
                <Table.TextCell>{periodFormat.from} - {periodFormat.to}</Table.TextCell>
                <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(wastageCost)}</Table.TextCell>
                <Table.Cell flexBasis={50} flexGrow={0} padding={0}>
                  <IconWrapper
                      is={Link}
                      to={`/${accountId}/review/wastage/${periodStart}`}
                      name="clipboard"
                      appearance="clickable"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Block>
  );
};

export default WastageArchive;
