//React
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useConditionalRedirect = () => {
  const workingAccount = useSelector((state) => state.workingAccount);
  const navigate = useNavigate();

  const requiresWorkingAccount = (target = '/') => {
    if (!workingAccount) {
      setTimeout(() => navigate(target, true), 0);
    }
  };

  return {
    requiresWorkingAccount,
  };
};

export default useConditionalRedirect;
