import { all, takeEvery, select, put, call, fork } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import _ from 'lodash';
//
import { accountTypes, messageTypes } from '../../utils/constants';
import { getAccessibleAccountIds, findOwnerAccount } from '../../utils/accounts';
import { actionTypes, actions } from '../actions';
import { loadCollection, clearCollection, clearAccountCollections } from '../firebase/common';
import { loadAccounts, updateAccount, addAccount, clearAccount, clearAccounts } from '../firebase/accounts';
import { loadProfile, updateProfile, addProfile } from '../firebase/profile';
import { loadAccountUsers, clearAccountUsers, addToAccount, removeFromAccount, removeByAccountId } from '../firebase/accountUsers';

import foodRecordsSaga from './foodRecords';
import orderRecordsSaga from './orderRecords';
import invoiceUploadsSaga from './invoiceUploads';
import ingredientsSaga from './ingredients';
import ingredientCategoriesSaga from './ingredientCategories';
import supplierCategoriesSaga from './supplierCategories';
import recipesSaga from './recipes';
import recipeCategoriesSaga from './recipeCategories';
import modifiersSaga from './modifiers';
import modifierCategoriesSaga from './modifierCategories';
import departmentsSaga from './departments';
import menuCategoriesSaga from './menuCategories';
import suppliersSaga from './suppliers';
import supplierOptionsSaga from './supplierOptions';
import sectionsSaga from './sections';
import messagesSaga from './messages';
import messagesSeenTimesSaga from './messagesSeenTimes';
import miseEnPlacesSaga from './miseEnPlaces';
import stockTakeSaga from './stockTake';
import wastageSaga from './wastage';
import transferSaga from './transfer';
import ordersSaga from './orders';
import taskSaga from './tasks';
import tasksCommentsSaga from './tasksComments';
import taskHistorySaga from './tasksHistory';
import prepTaskSaga from './prepTasks';
import menusSaga from './menus';
import salesSaga from './sales';
import receiptsSaga from './receipts';
import reportsCommentsSaga from './reportsComments';
import reportsIncidentalsSaga from './reportsIncidentals';
import areasSaga from './areas';
import financialsSaga from './financials';
import budgetsSaga from './budgets';

const clearCollections = () => {
  clearAccountUsers();
  clearCollection('ingredients');
  clearCollection('ingredientCategories');
  clearCollection('supplierCategories');
  clearCollection('recipes');
  clearCollection('recipeCategories');
  clearCollection('modifiers');
  clearCollection('modifierCategories');
  clearCollection('departments');
  clearCollection('areas');
  clearCollection('financials');
  clearCollection('budgets');
  clearCollection('menus');
  clearCollection('menuCategories');
  clearCollection('deliveryCompanies');
  clearCollection('foodRecords');
  clearCollection('orderRecords');
  clearCollection('invoiceUploads');
  clearCollection('miseEnPlaces');
  clearCollection('suppliers');
  clearCollection('supplierOptions');
  clearCollection('sections');
  clearCollection('uploads');
  clearCollection('messages');
  clearCollection('messagesSeenTimes');
  clearCollection('wastages');
  clearCollection('transfers');
  clearCollection('stockTake');
  clearCollection('orderAttachments');
  clearCollection('orders');
  clearCollection('tasks');
  clearCollection('tasksComments');
  clearCollection('tasksHistory');
  clearCollection('prepTasks');
  clearCollection('sales');
  clearCollection('receipts');
  clearCollection('reportsComments');
  clearCollection('reportsIncidentals');
};

const loadCollections = (accountId) => {
  loadCollection('ingredients', accountId);
  loadCollection('ingredientCategories', accountId);
  loadCollection('supplierCategories', accountId);
  loadCollection('recipes', accountId);
  loadCollection('recipeCategories', accountId);
  loadCollection('modifiers', accountId);
  loadCollection('modifierCategories', accountId);
  loadCollection('departments', accountId);
  loadCollection('areas', accountId);
  loadCollection('financials', accountId);
  loadCollection('budgets', accountId);
  loadCollection('menus', accountId);
  loadCollection('menuCategories', accountId);
  loadCollection('deliveryCompanies', accountId);
  loadCollection('foodRecords', accountId);
  loadCollection('orderRecords', accountId);
  loadCollection('invoiceUploads', accountId);
  loadCollection('miseEnPlaces', accountId);
  loadCollection('suppliers', accountId);
  loadCollection('supplierOptions', accountId);
  loadCollection('sections', accountId);
  loadCollection('uploads', accountId);
  loadCollection('messages', accountId);
  loadCollection('messagesSeenTimes', accountId);
  loadCollection('wastages', accountId);
  loadCollection('transfers', accountId);
  loadCollection('stockTake', accountId);
  loadCollection('orderAttachments', accountId);
  loadCollection('orders', accountId);
  loadCollection('tasks', accountId);
  loadCollection('tasksComments', accountId);
  loadCollection('tasksHistory', accountId);
  loadCollection('prepTasks', accountId);
  loadCollection('sales', accountId);
  loadCollection('receipts', accountId);
  loadCollection('reportsComments', accountId);
  loadCollection('reportsIncidentals', accountId);
};

const loadingTasks = [];
const accountsLoading = [];

// Responds to account load requests, fetching user's accounts from Firestore.
function* accountsSaga() {

  // Listens for a request to load all accounts associated with a user's email and calls the loadAccounts function.
  yield takeEvery(actionTypes.accounts.ACCOUNTS_LOAD_REQUESTED, function* (action) {
    console.log('account load')
    try {
      yield call(loadAccounts, action.userEmail);
      console.log('account load end')
    }
    catch(err) {
      console.log(err,'err account load')
    }
  });

  // Handles requests to clear all account information from the Redux store.
  yield takeEvery(actionTypes.accounts.ACCOUNTS_CLEAR, function* (action) {
    yield call(clearAccounts);
  });

  // Notifies the saga that an account is being loaded, used to track loading state.
  yield takeEvery(actionTypes.accounts.ACCOUNT_LOAD_REQUEST, function* (action) {
    yield accountsLoading.push(action.accountId);
  });

  // Once an account is loaded, it triggers loading of related users and eventually requests the user's profile.
  yield takeEvery(actionTypes.accounts.ACCOUNT_LOADED, function* (action) {
    const accountId = action.payload.id;
    try {
      yield fork(loadAccountUsers, accountId);
      _.pull(accountsLoading, accountId);
      if (accountsLoading.length === 0) {
        yield fork(function* () {
          yield put(actions.profile.profileLoadRequest());
        });
      }
    } catch (err) {
      console.error(err, action);
      yield put(actions.appMessageError('Failed to load account data'));
    }
  });

  // Clears specific account data.
  yield takeEvery(actionTypes.accounts.ACCOUNT_CLEAR, function* (action) {
    const accountId = action.payload;
    try {
      yield call(clearAccount, accountId);
      yield call(clearAccountCollections, accountId);
    }
    catch (err) {
      console.error(err, action);
      yield put(actions.appMessageError('Failed to clear account data'));
    }
  });

  // Updates account information in Firestore.
  yield takeEvery(actionTypes.accounts.ACCOUNT_UPDATE, function* (action) {
    try {
      const { callback, accountInfo } = action;
      const { id, ...moreInfo } = accountInfo;
      yield call(updateAccount, id, moreInfo);
      yield put(actions.appMessageSuccess('Account updated'));
      if (callback) {
        yield call(callback, accountInfo);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Account could not be updated: ' + err.message));
    }
  });

  // Adds a new account to Firestore.
  yield takeEvery(actionTypes.accounts.ACCOUNT_CREATE, function* (action) {
    try {
      const { accountInfo, callback } = action;
      const accountDoc = yield call(addAccount, accountInfo);
      yield put(actions.accountUsers.addUser({ email: accountInfo.ownerEmail, account: accountDoc.id }));
      yield put(actions.appMessageSuccess('Account Added'));
      if (callback) {
        yield call(callback, { id: accountDoc.id, ...accountInfo });
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Account could not be added: ' + err.message));
    }
  });

  // Handles adding a user to an account.
  yield takeEvery(actionTypes.accountUsers.ADD_USER, function* (action) {
    try {
      yield call(addToAccount, action.userInfo);
      yield put(actions.appMessageSuccess('Account User added'));
    }
    catch (err) {
      yield put(actions.appMessageError('User could not be added to account: ' + err.message));
    }
  });

  // Handles removing a user from an account.
  yield takeEvery(actionTypes.accountUsers.REMOVE_USER, function* (action) {
    try {
      yield call(removeFromAccount, action.userId);
      yield put(actions.appMessageSuccess('Account User removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('User could not be removed from account: ' + err.message));
    }
  });
  //
  // Removes all users associated with an account.
  yield takeEvery(actionTypes.accountUsers.REMOVE_ACCOUNT, function* (action) {
    try {
      yield call(removeByAccountId, action.accountId);
      yield put(actions.appMessageSuccess('Account Users removed successfully.'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Users could not be removed from account: ' + err.message));
    }
  });
}

function* loadRecursive(accountId) {
  const account = yield select(state => state.accounts[accountId]);
  if (account) {
    yield call(loadCollections, accountId);  // Load the collections for the current account

    // If the account has departments, load them
    if (account.departments) {
      for (const deptId of account.departments) {
        yield call(loadCollections, deptId);
      }
    }

    // Recursively load for children
    if (account.children) {
      for (const childId of account.children) {
        yield call(loadRecursive, childId);  // Recursive call for children
      }
    }
  }
}

function* profileSaga() {
  // Listens for PROFILE_LOAD_REQUESTED actions and loads the user profile.
  yield takeEvery(actionTypes.profile.PROFILE_LOAD_REQUESTED, function* (action) {
    //console.log('PROFILE_LOAD_REQUESTED initiated for user:', action);
    const user = yield select((state) => (state.user));
    //console.log('Selected user state:', user);
    yield call(loadProfile, user);
  });

  // After loading the profile successfully, this saga loads additional related collections in parallel.
  yield takeEvery(actionTypes.profile.PROFILE_LOAD_SUCCESS, function* (action) {
    //console.log('PROFILE_LOAD_SUCCESS received:', action.payload);
      const { currentaccount, uid } = action.payload;
      //console.log(`Starting async loading for profile ${uid} with account ${currentaccount}`);

      try {
        // Start by dispatching an action indicating that profile related loading is starting
        yield put(actions.applicationLoading(`profile:${uid}`));
        //console.log(`Collections loaded for current account: ${currentaccount}`);
        yield put(actions.profile.asyncProfileLoadingStart(`profile:${uid}`));
        //console.log('Account info selected:', accountInfo);

        if (currentaccount) {
          // Load collections for the current account and wait for it to complete.
          yield call(loadCollections, currentaccount);

          // Once the current account collections are loaded, dispatch a completion action
          yield put(actions.profile.asyncProfileLoadingComplete(`profile:${uid}`));

          const accountInfo = yield select((state) => (state.accounts[currentaccount]));

          if (accountInfo) {
            let collectionLoadTasks = [];
  
            // Load parent collections if accountInfo.parents exists
            if (accountInfo.parents) {
              accountInfo.parents.forEach(parentId => {
                collectionLoadTasks.push(call(loadCollections, parentId));
              });
            }

            if (accountInfo.children) {
              for (const childId of accountInfo.children) {
                yield call(loadRecursive, childId);  // Start recursive loading for each child
              }
            }
  
            // Execute the loading tasks for all related collections in parallel.
            yield all(collectionLoadTasks);
          }
        }
        yield put(actions.applicationLoadComplete(`profile:${uid}`))
        
      } catch (err) {
        console.error(err);
        // Regardless of error, dispatch the completion action to indicate loading is done
        yield put(actions.appMessageError('Unable to load collections from Profile load.'));
      }
  });

  yield takeEvery(actionTypes.profile.PROFILE_CLEAR, function* () {
    // When we clear the profile, clear the collections
    yield call(clearCollections);
    yield put(actions.applicationLoaded());
  });

  // Sets the current account in the profile.
  yield takeEvery(actionTypes.profile.PROFILE_SET_ACCOUNT, function* (action) {
    // Set the current account, and update the profile
    yield put(actions.accounts.currentAccountSet(action.accountId));
    const profile = yield select((state) => (state.profile));
    profile.currentaccount = action.accountId;
    yield put(actions.profile.profileUpdate(profile));
  });

  // Updates the user profile.
  yield takeEvery(actionTypes.profile.PROFILE_UPDATE, function* (action) {
    const { id, ...profile } = action.profile;
    //console.log(`Updating profile with ID: ${id}`);
    try {
      yield call(updateProfile, id, profile);
      yield put(actions.profile.profileUpdateSuccess({id, ...profile}));
      yield put(actions.appMessageSuccess('Profile updated'));

      // Reload collections if the current account has changed
      const currentProfile = yield select((state) => state.profile);
      //console.log('Profile update initiated. Checking if account context has changed...');
      if (currentProfile.currentaccount !== profile.currentaccount) {
        //console.log(`Account change detected: from ${currentProfile.currentaccount} to ${profile.currentaccount}. Reloading data...`);
        yield put(actions.profile.profileLoadRequest());  // Triggers reload of profile and associated data
      } else {
        console.log('No account change detected in profile update.');
      }
    } catch (err) {
      console.error('Profile update error', err);
      yield put(actions.appMessageError('Unable to update profile'));
    }
  });

  // Adds a new profile.
  yield takeEvery(actionTypes.profile.PROFILE_ADD, function* (action) {
    try {
      yield call(addProfile, action.profileId, action.profile);
      yield put(actions.appMessageSuccess('Account Created'));
    }
    catch (err) {
      console.error('Profile add error', err);
      yield put(actions.appMessageError('Unable to add profile'));
    }
  });
}

// Listens for the APP_LOAD_STARTED action to track loading tasks.
function* appSaga() {
  yield takeEvery(actionTypes.APP_LOAD_STARTED, function* (action) {
    // Logs the task that has started loading and adds it to the loadingTasks array.
    //For debugging purpose
    //console.log(action.task,'action task load')
    yield loadingTasks.push(action.task);
  });

  // Listens for the APP_LOAD_COMPLETE action to remove tasks from the loading array.
  yield takeEvery(actionTypes.APP_LOAD_COMPLETE, function* (action) {
    // Removes the completed task from the loadingTasks array.
    _.pull(loadingTasks, action.task);

    // If there are no more loading tasks, the application is considered fully loaded.
    if (loadingTasks.length === 0) {
      console.log('=== APPLICATION LOADED ===');
      // Dispatches an action to indicate that the application has fully loaded.
      yield put(actions.applicationLoaded());
    }
  });

   // Listens for APP_MESSAGE_SET actions to display messages using the toaster.
  yield takeEvery(actionTypes.APP_MESSAGE_SET, function* (action) {
    switch (action.messageType) {
      case messageTypes.SUCCESS:
        yield call(toaster.success, action.messageText);
        break;
      case messageTypes.WARNING:
        yield call(toaster.warning, action.messageText);
        break;
      case messageTypes.ERROR:
        yield call(toaster.danger, action.messageText);
        break;
      case messageTypes.INFO:
      default:
        yield call(toaster.notify, action.messageText);
        break;
    }
  });

  // Listens for APP_MESSAGE_CLEAR actions to close all open toast notifications.
  yield takeEvery(actionTypes.APP_MESSAGE_CLEAR, function* () {
    yield call(toaster.closeAll);
  });
}


export default function* rootSaga() {
  yield all([
    appSaga(),
    accountsSaga(),
    profileSaga(),
    foodRecordsSaga(),
    orderRecordsSaga(),
    invoiceUploadsSaga(),
    ingredientsSaga(),
    ingredientCategoriesSaga(),
    supplierCategoriesSaga(),
    recipesSaga(),
    recipeCategoriesSaga(),
    modifiersSaga(),
    modifierCategoriesSaga(),
    departmentsSaga(),
    areasSaga(),
    financialsSaga(),
    budgetsSaga(),
    menuCategoriesSaga(),
    suppliersSaga(),
    supplierOptionsSaga(),
    sectionsSaga(),
    menusSaga(),
    messagesSaga(),
    messagesSeenTimesSaga(),
    miseEnPlacesSaga(),
    stockTakeSaga(),
    wastageSaga(),
    transferSaga(),
    ordersSaga(),
    taskSaga(),
    tasksCommentsSaga(),
    taskHistorySaga(),
    prepTaskSaga(),
    salesSaga(),
    receiptsSaga(),
    reportsCommentsSaga(),
    reportsIncidentalsSaga(),
  ]);
};
