import fb from './';
import { getCollectionRef } from './common';

export const addRecipe = (recipe, accountId, userName) => {
  const recipesRef = getCollectionRef('recipes', accountId);
  return recipesRef.add({
    ...recipe,
    createdBy: userName,
    createdAt: fb.serverTime(),
    updatedBy: null,
    updatedAt: null,
    archived: false,
    deleted: false
  });
}

export const archiveRecipe = (recipeId, accountId, userName) => {
  const recipesRef = getCollectionRef('recipes', accountId);
  return recipesRef.doc(recipeId).update({
    archived: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

export const removeRecipe = (recipeId, accountId, userName) => {
  const recipesRef = getCollectionRef('recipes', accountId);
  return recipesRef.doc(recipeId).update({
    deleted: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

export const updateRecipe = (recipeId, recipe, accountId, userName) => {
  const recipesRef = getCollectionRef('recipes', accountId);
  return recipesRef.doc(recipeId).update({
    ...recipe,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
};

export const uploadRecipeImage = (recipeId, bucket, file, accountId) => {
  const recipesRef = getCollectionRef('recipes', accountId);
  const recipeDocRef = recipesRef.doc(recipeId);
  const ext = file.name.split('.').pop();
  const filename = `${bucket}/images/recipes/${recipeId}.${ext}`;
  recipeDocRef.update({ 'original_imagefilename': filename });
  return fb.storage.ref().child(filename).put(file);
};

export const removeRecipeFromMenus = async (accountId, recipeId, menusUsingRecipe) => {
  const db = fb.firestore; 
  const batch = db.batch();

  for (const menu of menusUsingRecipe) {
    const menuRef = db.collection('accounts').doc(accountId).collection('menus').doc(menu.id);
    const menuDoc = await menuRef.get();

    if (menuDoc.exists) {
      const menuData = menuDoc.data();
      const updatedSections = menuData.sections.map((section) => {
        const updatedRecipes = section.recipes.filter((id) => id !== recipeId);
        return { ...section, recipes: updatedRecipes };
      });

      batch.update(menuRef, { sections: updatedSections });
    }
  }

  await batch.commit();
};

export const removeRecipeFromRecipes = async (accountId, recipeId, recipesUsingRecipe) => {
  const db = fb.firestore; 
  const batch = db.batch();

  for (const recipe of recipesUsingRecipe) {
    const recipeRef = db.collection('accounts').doc(accountId).collection('recipes').doc(recipe.id);
    const recipeDoc = await recipeRef.get();

    if (recipeDoc.exists) {
      const recipeData = recipeDoc.data();
      const updatedIngredients = recipeData.ingredients.filter(ingredient => !(ingredient.type === 'subrecipe' && ingredient.id === recipeId));
      batch.update(recipeRef, { ingredients: updatedIngredients });
    }
  }

  await batch.commit();
};

export const removeRecipeFromModifiers = async (accountId, recipeId, modifiersUsingRecipe) => {
  const db = fb.firestore; 
  const batch = db.batch();

  for (const modifier of modifiersUsingRecipe) {
    const modifierRef = db.collection('accounts').doc(accountId).collection('modifiers').doc(modifier.id);
    const modifierDoc = await modifierRef.get();

    if (modifierDoc.exists) {
      const modifierData = modifierDoc.data();
      const updatedIngredients = modifierData.ingredients.filter(ingredient => !(ingredient.type === 'subrecipe' && ingredient.id === recipeId));
      batch.update(modifierRef, { ingredients: updatedIngredients });
    }
  }

  await batch.commit();
};
