const PROFILE_LOAD_REQUESTED = 'PROFILE_LOAD_REQUESTED';
const PROFILE_LOAD_FAILURE = 'PROFILE_LOAD_FAILURE';
const PROFILE_LOAD_SUCCESS = 'PROFILE_LOAD_SUCCESS';
const PROFILE_CLEAR = 'PROFILE_CLEAR';
const PROFILE_SET_ACCOUNT = 'PROFILE_SET_ACCOUNT';
const PROFILE_UPDATE = 'PROFILE_UPDATE';
const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
const PROFILE_ADD = 'PROFILE_ADD';
const ASYNC_PROFILE_LOADING_START = 'ASYNC_PROFILE_LOADING_START';
const ASYNC_PROFILE_LOADING_COMPLETE = 'ASYNC_PROFILE_LOADING_COMPLETE';

export const actionTypes = {
  PROFILE_LOAD_REQUESTED,
  PROFILE_LOAD_FAILURE,
  PROFILE_LOAD_SUCCESS,
  PROFILE_CLEAR,
  PROFILE_SET_ACCOUNT,
  PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_ADD,
  ASYNC_PROFILE_LOADING_START,
  ASYNC_PROFILE_LOADING_COMPLETE,
};

const profileLoadSuccess = (payload) => {
  return {
    type: PROFILE_LOAD_SUCCESS,
    payload
  };
};

const asyncProfileLoadingStart = (payload) => {
  return {
  type: ASYNC_PROFILE_LOADING_START,
  payload
  };
};

const asyncProfileLoadingComplete = (payload) => {
  return {
  type: ASYNC_PROFILE_LOADING_COMPLETE,
  payload
  };
};

const profileLoadRequest = (user) => {
  return {
    type: PROFILE_LOAD_REQUESTED,
    user
  };
};

const profileLoadFailure = () => {
  return {
    type: PROFILE_LOAD_FAILURE
  };
};

const profileClear = () => {
  return {
    type: PROFILE_CLEAR
  };
};

const profileSetAccount = (accountId) => {
  return {
    type: PROFILE_SET_ACCOUNT,
    accountId
  };
};

const profileUpdate = (profile) => {
  return {
    type: PROFILE_UPDATE,
    profile
  };
};

const profileUpdateSuccess = (payload) => {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    payload
  };
};

const profileAdd = (userId, profile) => {
  return {
    type: PROFILE_ADD,
    profileId: userId,
    profile
  };
};
//
export const actions = {
  profileLoadSuccess,
  asyncProfileLoadingStart,
  asyncProfileLoadingComplete,
  profileLoadRequest,
  profileLoadFailure,
  profileClear,
  profileSetAccount,
  profileUpdate,
  profileUpdateSuccess,
  profileAdd
};
