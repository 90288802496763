import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { majorScale, Pane, IconButton, Heading, FormField, FilePicker, Table, Text } from 'evergreen-ui';

import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import { colors } from '../../utils/constants';
import { TODAY } from '../../utils/time';
import { shortDateDisplayFormat } from '../../utils/format';
import { actions } from '../../store/actions';
import { current } from '../../utils/selectors';


const IngredientUpload = (props) => {
  const dispatch = useDispatch();
  const uploadHistory = useSelector((state) => _.reverse(
    _.sortBy(
      current(state, 'uploads'),
      (upload) => (upload.created && upload.created.toDate) ? upload.created.toDate() : TODAY)
    )
  );
  
  const handleUpload = (csvFile) => {
    if (csvFile) {
      dispatch(actions.ingredients.addUpload(csvFile));
    }
  };


  return (
    <Page title="Ingredient Upload">
      <Block
          background={colors.twilightBlue10}
          display="flex"
          alignItems="center"
          paddingY={majorScale(1)}
          paddingX={majorScale(2)}
          marginBottom={majorScale(2)}
      >
        <IconButton
            appearance="minimal"
            icon="arrow-left"
            is={Link}
            to="/menu-setup/ingredients"
            marginRight={majorScale(2)}
        />
        <Heading is="h2">Upload Ingredient CSV</Heading>
      </Block>

      <Block
          padding={majorScale(2)}
          marginBottom={majorScale(2)}
      >
        <FormField
            label="Ingredient Upload"
            required
            marginBottom={majorScale(2)}
        >
          <FilePicker
              multiple={false}
              accept="*.csv"
              onChange={(files) => handleUpload(files[0] || false)}
          />
        </FormField>
        <Pane display="flex" alignItems="center">
          <Text marginRight={majorScale(2)}>Download CSV Template</Text>
          <Button
              appearance="primary"
              iconBefore="download"
              is="a"
              href="/csv/ingredient-upload-template.csv"
              download="ingredient-upload-template.csv"
          >Template</Button>
        </Pane>
      </Block>

      <Block>
        <Heading is="h3" margin={majorScale(2)}>Uploads</Heading>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell flexBasis={200} flexGrow={0}>Import Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Details</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={150} flexGrow={0}>Status</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(uploadHistory) && (
              <Table.Row>
                <Table.TextCell>No uploads to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(uploadHistory, (upload) => (
              <Table.Row key={upload.id}>
                <Table.TextCell flexBasis={200} flexGrow={0}>{shortDateDisplayFormat((upload.created && upload.created.toDate) ? upload.created.toDate() : TODAY)}</Table.TextCell>
                <Table.Cell
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                  <Text>{upload.uploadname}</Text>
                  <Text is="em" marginLeft={majorScale(2)}>
                    {upload.status === 'processing' && 'Please wait while the file is processed'}
                    {upload.status === 'error' && `There has been an error processing this file, please check the formatting is correct (${upload.error})`}
                    {upload.status === 'success' && `The file has been processed and ${upload.count} ingredient(s) added`}
                  </Text>
                </Table.Cell>
                <Table.TextCell flexBasis={150} flexGrow={0}>{upload.status}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>
    </Page>
  );
};

export default IngredientUpload;
