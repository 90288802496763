import { orderStatuses } from '../../utils/constants';
import fb from './';
import { getCollectionRef } from './common';

export const addOrder = (order, accountId) => {
  const ordersRef = getCollectionRef('orders', accountId);
  return ordersRef.add({ ...order, created: fb.serverTime(), lastModified: fb.serverTime() });
};

export const removeOrder = (orderId, accountId) => {
  const ordersRef = getCollectionRef('orders', accountId);
  return ordersRef.doc(orderId).delete();
};

export const updateOrder = (orderId, order, accountId) => {
  const ordersRef = getCollectionRef('orders', accountId);
  return ordersRef.doc(orderId).update({ ...order, lastModified: fb.serverTime() });
};

export const toggleOrderFinal = (orderId, order, accountId) => {
  const ordersRef = getCollectionRef('orders', accountId);
  const status = order.status === orderStatuses.FINALISED ? orderStatuses.RECEIVED : orderStatuses.FINALISED;
  return ordersRef.doc(orderId).update({ status, lastModified: fb.serverTime() });
};
