import { takeEvery, put, call, select } from 'redux-saga/effects';
//import { useNavigate } from 'react-router-dom';
import { addMenuCategory, removeMenuCategory, updateMenuCategory } from '../firebase/menuCategories';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* menuCategoriesSaga() {
  yield takeEvery(actionTypes.menuCategories.ADD_MENU_CATEGORY, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addMenuCategory, action.categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Menu Category Added'));
      //yield call(navigate, '/menu-categories');
    }
    catch (err) {
      yield put(actions.appMessageError('Menu Category could not be added'));
    }
  });
  yield takeEvery(actionTypes.menuCategories.REMOVE_MENU_CATEGORY, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(removeMenuCategory, action.categoryId, accountId);
      yield put(actions.appMessageSuccess('Menu Category Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Menu Category could not be removed'));
    }
  });
  yield takeEvery(actionTypes.menuCategories.UPDATE_MENU_CATEGORY, function* (action) {
    const { id, ...categoryInfo } = action.categoryInfo;
    try {
      const accountId = yield select(activeAccount);
      yield call(updateMenuCategory, id, categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Menu Category Updated'));
      //yield call(navigate, '/menu-categories');
    }
    catch (err) {
      yield put(actions.appMessageError('Menu Category could not be updated'));
    }
  });
};

