import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addIngredientCategory, removeIngredientCategory, updateIngredientCategory } from '../firebase/ingredientCategories';
import { actions, actionTypes } from '../actions';
import { activeAccount, current } from '../../utils/selectors';
import _ from 'lodash';

export default function* ingredientCategoriesSaga() {
  yield takeEvery(actionTypes.ingredientCategories.ADD_INGREDIENT_CATEGORY, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addIngredientCategory, action.categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Ingredient Category Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Ingredient Category could not be added'));
    }
  });
  yield takeEvery(actionTypes.ingredientCategories.REMOVE_INGREDIENT_CATEGORY, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      const catMap = yield select((state) => _.groupBy(current(state, 'ingredientCategories'), 'parent'));
      const recursiveRemove = function* (catId) {
        if (catMap[catId]) {
          for (let i = 0, ln = catMap[catId].length; i < ln; i++) {
            yield call(recursiveRemove, catMap[catId][i].id);
          }
        }
        return yield call(removeIngredientCategory, catId, accountId);
      };
      yield call(recursiveRemove, action.categoryId);
      yield put(actions.appMessageSuccess('Ingredient Category Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Ingredient Category could not be removed'));
    }
  });
  yield takeEvery(actionTypes.ingredientCategories.UPDATE_INGREDIENT_CATEGORY, function* (action) {
    const { id, ...categoryInfo } = action.categoryInfo;
    try {
      const accountId = yield select(activeAccount);
      yield call(updateIngredientCategory, id, categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Ingredient Category Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Ingredient Category could not be updated'));
    }
  });
};

