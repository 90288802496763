//React
import React from 'react';

//Libraries
import { Card, Heading, majorScale, minorScale, Pane, Text } from 'evergreen-ui';
import _ from 'lodash';

//Files
import { colors } from '../../utils/constants';

//Components
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';

const Accounts = ({
  label,
  isMobile,
  accounts,
  setProfileAccount,
  hasOwnership,
  setAddingAccount,
  buttonName
}) => {

  return (
    <Pane marginBottom={majorScale(4)}>

      <CustomHeading level="3" marginBottom={majorScale(2)}>
        {label}
      </CustomHeading>

      <Pane
          display="grid"
          gridTemplateColumns={(isMobile) ? 'auto' : 'repeat(4, auto)'}
          gap={majorScale(2)}
          marginBottom={majorScale(2)}
      >
        {_.map(accounts, (account) => {
          return (
            <Card
                key={account.id}
                border="default"
                padding={minorScale(2)}
            >
              <Pane padding={minorScale(2)}>
                <CustomHeading level="4">{account.name}</CustomHeading>
              </Pane>

              <Pane padding={minorScale(2)}>
                <Button appearance="primary" onClick={() => setProfileAccount(account.id)}>{buttonName}</Button>
              </Pane>
            </Card>
          );
        })}
      </Pane>
      
      {/* {(hasOwnership() || accounts.length === 0) && ( */}
        <Pane marginY={majorScale(4)}>
          <Text fontSize={18} fontWeight='bold'>Need more?</Text>
          <Text textDecoration='underline' fontSize={18} color={colors.orange} cursor='pointer' marginLeft={minorScale(2)} appearance="minimal" onClick={() => setAddingAccount(true)}>Create a new head office</Text>
        </Pane>
      {/* )} */}
      
    </Pane>
  )
}

export default Accounts;
