import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addMenu, removeMenu, updateMenu } from '../firebase/menus';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* menusSaga() {
  yield takeEvery(actionTypes.menus.ADD_MENU, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addMenu, action.menu, accountId);
      yield put(actions.appMessageSuccess('Menu Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Menu could not be added'));
    }
  });
  yield takeEvery(actionTypes.menus.REMOVE_MENU, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeMenu, action.menuId, accountId);
      yield put(actions.appMessageSuccess('Menu Removed'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Menu could not be removed'));
    }
  });
  yield takeEvery(actionTypes.menus.UPDATE_MENU, function* (action) {
    try {
      const { id, ...menu } = action.menu;
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateMenu, id, menu, accountId);
      yield put(actions.appMessageSuccess('Menu Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Menu could not be updated'));
    }
  });
};
