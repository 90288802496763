//React
import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Table, IconButton, Avatar, Pane, Dialog, TextInputField, Text } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CommentPane from './CommentPane';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import useTasks from '../../../hooks/useTasks';

// TODO rename to temperature logs
const FridgeTab = ({ date }) => {
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => ({ name: state.profile.name, uid: state.profile.uid }));
  const [editingTask, setEditingTask] = useState(false);

  const onFieldChange = (field, newValue) => {
    setEditingTask((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const closeDialog = () => {
    setEditingTask(false);
  };

  const saveTask = (close) => {
    if (!tasksHistory[editingTask.id]) {
      const taskHistoryItem = {
        taskId: editingTask.id,
        target: editingTask.target,
        action: editingTask.action,
        type: editingTask.type,
        amUid: '',
        amUsername: '',
        amTemperature: '',
        pmUid: '',
        pmUsername: '',
        pmTemperature: '',
        date
      }

      const taskUser = editingTask.edit === 'am' ? {
        amUid: userInfo.uid, 
        amUsername: userInfo.name, 
        amTemperature: editingTask.amTemperature
      } : {
        pmUid: userInfo.uid, 
        pmUsername: userInfo.name, 
        pmTemperature: editingTask.pmTemperature
      }

      dispatch(actions.tasksHistory.addTaskHistoryItem(accountId, {
        ...taskHistoryItem,
        ...taskUser
      }));
    }
    else {
      const taskUser = editingTask.edit === 'am' ? {
        amUid: userInfo.uid, 
        amUsername: userInfo.name, 
        amTemperature: editingTask.amTemperature
      } : {
        pmUid: userInfo.uid, 
        pmUsername: userInfo.name, 
        pmTemperature: editingTask.pmTemperature
      }
      
      dispatch(actions.tasksHistory.updateTaskHistoryItem(accountId, {
        ...tasksHistory[editingTask.id],
        ...taskUser
      }));
    }
    closeDialog();
  };

  const {
    tasksHistory,
    completedOnDay,
    fridgeTasks,
    isMobile
  } = useTasks({ accountId, date });
  return (
    <React.Fragment>
      <Block>
        <CustomHeading level="3" padding={majorScale(2)}>Fridge &amp; freezer temperature logs</CustomHeading>
        <Table>
          {!isMobile && (
            <Table.Head>
              <Table.TextHeaderCell flex="0 1 20%">Description</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 20%">Target</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 20%">AM</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 10%">Initials</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 20%">PM</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 10%">Initials</Table.TextHeaderCell>
            </Table.Head>
          )}
          {isMobile && (
            <Table.Head height={48}>
              <Table.TextHeaderCell flex="0 1 40%">
                Description<br />
                <em>Target</em>
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 30%">AM</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 30%">PM</Table.TextHeaderCell>
            </Table.Head>
          )}
          <Table.Body>
            {_.isEmpty(fridgeTasks) && (
              <Table.Row>
                <Table.TextCell>No tasks to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(fridgeTasks, (task) => {
              const isComplete = completedOnDay(task);
              const amTemperature = isComplete && tasksHistory[task.id].amTemperature ? tasksHistory[task.id].amTemperature : '';
              const amUsername = isComplete && tasksHistory[task.id].amUsername ? tasksHistory[task.id].amUsername : '';
              const pmTemperature = isComplete && tasksHistory[task.id].pmTemperature ? tasksHistory[task.id].pmTemperature : '';
              const pmUsername = isComplete && tasksHistory[task.id].pmUsername ? tasksHistory[task.id].pmUsername : '';

              if (isMobile) {
                return (
                  <Table.Row key={task.id}>
                    <Table.TextCell flex="0 1 40%">
                      {task.action}<br />
                      <em>{task.target}</em>
                    </Table.TextCell>
                    <Table.Cell flex="0 1 30%">
                      {amTemperature && (<Text>{amTemperature}°C</Text>)}
                      <IconWrapper
                          marginLeft={majorScale(1)}
                          name="edit"
                          appearance='clickable'
                          onClick={() => setEditingTask({ ...task, edit: 'am', amTemperature })}
                      />
                    </Table.Cell>
                    <Table.Cell flex="0 1 30%">
                      {pmTemperature && (<Text>{pmTemperature}°C</Text>)}
                      <IconWrapper
                          marginLeft={majorScale(1)}
                          name="edit"
                          appearance='clickable'
                          onClick={() => setEditingTask({ ...task, edit: 'pm', pmTemperature })}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              }

              return (
                <Table.Row key={task.id}>
                  <Table.TextCell flex="0 1 20%">{task.action}</Table.TextCell>
                  <Table.TextCell flex="0 1 20%">{task.target}</Table.TextCell>
                  <Table.Cell flex="0 1 20%">
                    {amTemperature && (<Text>{amTemperature}°C</Text>)}
                    <IconWrapper 
                      marginLeft={majorScale(1)}
                      name="edit"
                      appearance='clickable' 
                      onClick={() => setEditingTask({...task, edit:'am', amTemperature:amTemperature})} 
                    />
                  </Table.Cell>
                  <Table.Cell flex="0 1 10%">
                    {amUsername && (<Avatar size={24} name={amUsername} />)}
                  </Table.Cell>
                  <Table.Cell flex="0 1 20%">
                    {pmTemperature && (<Text>{pmTemperature}°C</Text>)}
                    <IconWrapper
                      marginLeft={majorScale(1)}
                      name="edit"
                      appearance='clickable'
                      onClick={() => setEditingTask({...task, edit:'pm', pmTemperature:pmTemperature})} 
                    />
                  </Table.Cell>
                  <Table.Cell flex="0 1 10%">
                    {pmUsername && (<Avatar size={24} name={pmUsername} />)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <CommentPane
          accountId={accountId}
          date={date}
          type='FRIDGE'
        />
      </Block>

      <CustomDialog
          isOpen={!!editingTask && editingTask.type === "FRIDGE" && editingTask.edit === 'am'}
          title="Update AM temperature"
          confirmLabel="Save"
          onClose={closeDialog}
          onConfirm={saveTask}
      >
        <Pane>
          <TextInputField
              required
              label="AM temperature"
              value={editingTask.amTemperature || ''}
              onChange={(e) => onFieldChange('amTemperature', e.target.value)}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!editingTask && editingTask.type === "FRIDGE" && editingTask.edit === 'pm'}
          title="Update PM temperature"
          confirmLabel="Save"
          onClose={closeDialog}
          onConfirm={saveTask}
      >
        <Pane>
          <TextInputField
              required
              label="PM temperature"
              value={editingTask.pmTemperature || ''}
              onChange={(e) => onFieldChange('pmTemperature', e.target.value)}
          />
        </Pane>
      </CustomDialog>

    </React.Fragment>
  );
};

FridgeTab.propTypes = {
  accountId: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

export default FridgeTab;
