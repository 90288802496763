//React
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { Card, Heading, majorScale, minorScale, Pane, Text } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import CustomHeading from '../../components/Headings/Headings';

//Files
import { accountTypes } from '../../utils/constants';
import { actions } from '../../store/actions/index';
import { getRelevantDepartments } from '../../utils/selectors'


const GroupSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  
  // const { hasOwnership } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  const account = useSelector((state) => state.accounts[state.currentAccount]);
  const departments = useSelector((state) => getRelevantDepartments(state));

  
  return (
    <>
      <Page>
        <Block padding={majorScale(2)}>
          <CustomHeading level="3" marginBottom={majorScale(2)}>Products groups</CustomHeading>
          <Pane
              display="grid"
              alignItems="stretch"
              gridTemplateColumns={(isMobile) ? 'auto' : 'repeat(3, 1fr)'}
              columnGap={majorScale(2)}
              rowGap={majorScale(2)}
              marginBottom={majorScale(2)}
          >
            {_.isEmpty(departments) && (
              <Text>No items to display</Text>
            )}
            {_.map(departments, (dept) => (
              <Card
                  key={dept.id}
                  border="default"
              >
                <Pane
                    paddingY={majorScale(2)}
                    paddingX={majorScale(2)}
                >
                  <CustomHeading level="4" >{dept.name}</CustomHeading>
                </Pane>
                <Pane paddingY={minorScale(2)} paddingX={majorScale(2)} marginBottom={majorScale(2)} display='flex' alignItems='center'>
                  <Button
                      appearance="primary"
                      onClick={() => {
                        if (account.type === accountTypes.LOCATION) {
                          dispatch(actions.workingAccountSet(dept.id));
                          navigate(`/${dept.id}/tasks`);
                        }
                        else {
                          navigate(`/products/${dept.id}/setup`);
                        }
                      }}
                  >Access group</Button>
                </Pane>
              </Card>
            ))}
          </Pane>
          <Pane display="flex" justifyContent="flex-start" alignItems="center">
              <Button 
                  appearance="primary" 
                  onClick={() => navigate(`/products/manage-groups`)}
                  minWidth="150px"
              >
                Manage groups
              </Button>
          </Pane>
        </Block>   
      </Page>
    </>
  );
};

export default GroupSelect;
