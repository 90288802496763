
export const messageTypes = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  INFO: 'INFO'
};

export const accountTypes = {
  OWNER: 'OWNER',
  LOCATION: 'LOCATION',
  DEPARTMENT: 'DEPARTMENT',
  SUPPLIER: 'SUPPLIER',
};

export const accountPlans = {
  START: 'startPlan',
  ANALYTICS: 'analyticsPlan',
  RAISE: 'raisePlan',
  GROW: 'growPlan',
};

export const userTypes = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  GUEST: 'GUEST',
  SUPPORT: 'SUPPORT',
};

export const orderStatuses = {
  DRAFT: 'DRAFT',
  AWAIT: 'AWAIT',
  SENDING: 'SENDING',
  SENT: 'SENT',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  ATTACHED: 'ATTACHED',
  RECEIVING: 'RECEIVING',
  MATCH: 'MATCH',
  MATCHED: 'MATCHED',
  UNMATCH: 'UNMATCH',
  RECEIVED: 'RECEIVED',
  FINALISED: 'FINALISED'
};

export const invoiceUploadStatuses = {
  SUBMITTED: 'SUBMITTED',
  PRCOESSING: 'PRCOESSING',
  PROCESSED: 'PROCESSED'
};

export const foodRecordTypes = {
  COOKING: 'Cooking',
  COOLING: 'Cooling',
  REHEATING: 'Reheating',
  HOLDING: 'Holding',
  COLD: 'Cold',
  DEFROSTING: 'Defrosting'
};

export const foodRecordTargets = {
  COOKING: '> 75°C',
  COOLING: '< 8°C',
  REHEATING: '> 75°C',
  HOLDING: '> 63°C',
  COLD: '< 8°C'
};

export const stockRecordingUnits = {
  g: 'g',
  kg: 'kg',
  ml: 'ml',
  l: 'L',
  unit: 'Unit',
  lot: 'Lot',
  batch: 'Batch'
};

export const APP_TITLE = 'Misenplace';
export const TABLE_MIN_HEIGHT = 320;

export const colors = {
  white: '#fefefe',
  twilightBlue: '#0c354b',
  twilightBlue05: 'rgba(12, 53, 75, 0.05)',
  twilightBlue10: 'rgba(12, 53, 75, 0.1)',
  twilightBlue20: 'rgba(12, 53, 75, 0.2)',
  lightTeal: '#89d2dc',
  lightTeal10: 'rgba(137, 210, 220, 0.1)',
  lightTeal50: 'rgba(137, 210, 220, 0.5)',
  orange: '#ff8552',
  orange10: 'rgba(255, 133, 82, 0.1)',
  orangeBorder: 'rgba(255, 133, 82, 0.5)',
  slate14: 'rgba(67, 90, 111, 0.14)',
  slate: 'rgba(35, 67, 97, 0.2)',
  black: '#343434',
  black80: 'rgba(52, 52, 52, 0.8)',
  textDark: '#0C354B',
  red: '#EC4C47',
  teal: '#ACDFE680'
};

export const shadows = {
  main: 'inset 0px -1px 0px rgba(67, 90, 111, 0.09)'
}

export const TABLE_MAX_WIDTH = 500;

export default {
  colors,
  messageTypes,
  orderStatuses,
  foodRecordTypes,
  foodRecordTargets,
  APP_TITLE,
  TABLE_MIN_HEIGHT
};

export const TAB_INGREDIENTS = 'ingredients';
export const TAB_RECIPES = 'recipes';

export const LS_ACCOUNT_ID = 'ls_account_id'
