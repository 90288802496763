//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Table, Text } from 'evergreen-ui';

//Files
import { colors } from '../../../utils/constants';
import { currency } from '../../../utils/format';
import { calcStocktakeIngredientCost } from '../../../utils/functions';


const valuesInit = { unitStockDisplay: 0, thisStockDisplay: 0, varianceWorth: 0, livestockDisplay: 0};

// const amountType = (type) => type.replace('Display', 'Amount');

const calculateIngWorth = (type, ing) => {
  if (type === 'varianceWorth') return ing[type];

  // amount type here could be wrong
  return calcStocktakeIngredientCost(
    { ...ing,
      // amount: parseFloat(ing[amountType(type)]),
      amount: parseFloat(ing[type]),
      recordUOM: ing.startRecordUOM,
      recipeunit: ing.ingredient ? ing.ingredient.recipeunit : ing.recipeunit
    }
  );
}

const valuesAdd = (values, ing) => {
  for (const type in values) {
    values[type] = values[type] + calculateIngWorth(type, ing);
  }
  return values;
}

const createValues = (ings) => {
  return _.reduce(ings, (values, ing) => (valuesAdd(values, ing)), { ...valuesInit })
}

const valueRender = (header, values) => {
  if (header.label === 'Name') return 'Values';
  
  if (header.field && values[header.field]) return currency(values[header.field]);

  if (header.field && values[header.field] === 0) return '0.00';
}

const textValueColor = (header) => {
  if (header.label === 'Name') return colors.black;
  
  return colors.orange;
}

const textJusitfyValues = (header, justify) => {
  if (!justify) return 'flex-start';

  if (header.label === 'Name') return 'flex-start';
  
  return 'flex-end';
}

const ValuesTables = ({values, headers, justify}) =>
  <Table background='#F5F6F7'>
    <Table.Body>
      <Table.Row>
        {headers.map(header =>
          <Table.Cell key={header.label} justifyContent={textJusitfyValues(header, justify)} padding={majorScale(2)}>
            <Text color={textValueColor(header)}>{valueRender(header, values)}</Text>
          </Table.Cell>
        )}
      </Table.Row>
    </Table.Body>
  </Table>

const ValuesPane = ({ings, mobileTableHeaders, tableHeaders, isMobile, justify}) => {
  const values = createValues(ings);
  return (
    <Pane>
      <ValuesTables
        values={values}
        headers={(isMobile) ? mobileTableHeaders : tableHeaders}
        justify={justify}
      />
    </Pane>
  )
}

ValuesPane.propTypes = {
  ings: PropTypes.array.isRequired
}

export default ValuesPane;
