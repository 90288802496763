//Libraries
import _ from 'lodash';
import moment from 'moment';

//Files
import store from '../store/redux';
import { orderStatuses } from './constants';
import { calcOrdersTotal, calcPercentage, calcStocktakeCost, findItemCatgeory, getOrdersForPeriod, 
  getProcurementTotal, getSalesForPeriod, getStocktakeDate, getWastageDate, periodTransferCosts,
  denormalizeIngredientQuantity, getAccountOptionOrdersFromDate, getIngredientStockUOM, getOrderDate, ingOrderAmount 
} from './functions';
import { current, selectReportIncidentals } from './selectors';
import { calculcateWeekTheoreticalStock, findNextStocktake } from './stock';
import { getWE, minDate, TODAY, TODAYC } from './time';
import { currency } from './format'


export const buildWeekDates = (ws) => {
  //console.log(ws, "WS")
  const weekStart = moment.utc(ws).startOf('isoWeek').toDate(); // Monday 00:00 UTC
  const weekEnd = moment.utc(ws).endOf('isoWeek').toDate(); // Sunday 23:59 UTC
  const prevWeekStart = moment.utc(weekStart).subtract(1, 'week').toDate();
  const prevWeekEnd = moment.utc(prevWeekStart).endOf('isoWeek').toDate();
  return {
    weekStart,
    weekEnd,
    prevWeekEnd,
    prevWeekStart
  }
}

const filterSalesItems = (accountId, items, filters) => {
  if (!filters) return items;

  let allowed = true;
  return items.filter((item, index) => {
    allowed = true;
    if (filters.ids?.length > 0) {
      allowed = false;
      if (filters.ids.indexOf(item.recipeId) > -1) {
        allowed = true;
      }
      else {
        return false;
      }
    }

    if (filters.categories?.length > 0) {
      allowed = false;
      const category = findItemCatgeory(accountId, item);
      if (category && filters.categories.indexOf(category) > -1) {
        allowed = true;
      }
      else {
        return false;
      }
    }

    return allowed;
  })
}

const aggregateSales = (sales) => {
  return sales.reduce((data, sale) => {
    if (sale.totalcost) {
      data.weekSalesCost += sale.totalcost;
    }
    if (sale.totalgross) {
      data.weekSalesTotal += sale.totalgross;
    }
    if (sale.totalnet) {
      data.weekSalesNet += sale.totalnet;
    }
    if (sale.qty) {
      data.weekSalesQty += sale.qty;
    }
    return data;
  }, {
    weekSalesTotal: 0,
    weekSalesNet: 0,
    weekSalesCost: 0,
    weekSalesQty: 0
  });
}

export const aggregateFilterWeekSales = (accountSales, type = 'weekSales', filters = null) => {
  let sales = [];
  for (const id in accountSales) {
    const account = accountSales[id];
    if (account[type]?.length > 0) {
      const filtered = filterSalesItems(id, account[type], filters);
      sales = sales.concat(filtered);
    }
  }
  return aggregateSales(sales);
}

export const calculateWeekRecipeSales = ({
  accountId,
  weekStart,
  weekEnd,
  categories,
  recipe
}) => {
  const filters = {categories, ids: [recipe.id]}
  const weekSales = getSalesForPeriod(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate(), false, filters);
  console.log("calculateReportsWeekData - weekStart22:", weekStart);
   console.log("calculateReportsWeekData - weekEnd22:", weekEnd);

  const weekSalesTotal = _.sumBy(weekSales, 'totalgross');
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  const weekSalesQty = _.sumBy(weekSales, 'qty');
  return {
    recipe,
    weekSalesTotal,
    weekSalesNet,
    weekSalesCost,
    weekSalesQty
  }
}

export const calculateReportsWeekData = ({
  accountId,
  weekStart,
  weekEnd,
  prevWeekStart,
  prevWeekEnd,
  filters
}) => {
   // Log the input dates
   /*console.log("calculateReportsWeekData - weekStart:", weekStart);
   console.log("calculateReportsWeekData - weekEnd:", weekEnd);
   console.log("calculateReportsWeekData - prevWeekStart:", prevWeekStart);
   console.log("calculateReportsWeekData - prevWeekEnd:", prevWeekEnd);*/
  // const weekdaySales = getWeekDaySales(accountId, weekStart, weekEnd, filters);
  const weekSales = getSalesForPeriod(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate(), false, filters);
  const prevWeekSales = prevWeekStart && prevWeekEnd ? getSalesForPeriod(accountId, moment.utc(prevWeekStart).toDate(), moment.utc(prevWeekEnd).toDate(), false, filters) : null;
  // Log sales data for the weeks
  //console.log("Week Sales Data:", weekSales);
  //console.log("Previous Week Sales Data:", prevWeekSales);
  const weekSalesTotal = _.sumBy(weekSales, 'totalgross');
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  const weekSalesQty = _.sumBy(weekSales, 'qty');
  const expectedGP = calcPercentage(weekSalesNet - weekSalesCost, weekSalesNet, 1);
  const prevWeekSalesNet = prevWeekSales ? _.sumBy(prevWeekSales, 'totalnet') : null;
  const prevWeekSalesCost = prevWeekSales ? _.sumBy(prevWeekSales, 'totalcost'): null;
  const prevWeekSalesTotal = prevWeekSales ? _.sumBy(prevWeekSales, 'totalgross'): null;
  const prevWeekGP = prevWeekSales ? calcPercentage(prevWeekSalesNet - prevWeekSalesCost, prevWeekSalesNet, 1): null;
  return {
    weekSales,
    weekSalesTotal,
    weekSalesNet,
    weekSalesCost,
    weekSalesQty,
    expectedGP,
    prevWeekGP,
    prevWeekSalesNet,
    prevWeekSalesTotal,
    prevWeekSales
  }
}

const filterOrders = (orders, accounts, filters = {}) => {
  return orders.filters((order) => {
    let allowed = true;
    if (filters.suppliers?.length > 0) {
      allowed = false;
      const orderSupplier = accounts.supplierMap[order.supplierId];
      if (filters.suppliers.indexOf(orderSupplier?.name) > -1) {
        allowed = true;
      }
    }
    if (filters.ingredientCategories?.length > 0) {
      allowed = false;
      const orderItemCatIndex = order.items.findIndex(i => {
        const ing = accounts.ingredients.find(ing => ing.id === i.ingredientId);
        return filters.ingredientCategories.indexOf(ing.category) > -1;
      });
      if (orderItemCatIndex > -1) {
        allowed = true;
      }
    }
    return allowed;
  });
}

export const buildReceiptsData = ({
  accountId,
  weekStart,
  weekEnd,
  area
}) => {
  const state = store.getState();
  if (!area.has_deliverect) return {};

  const channels = area.deliverectLocationChannels
  weekStart = moment.utc(weekStart);
  weekEnd = moment.utc(weekEnd);

  const receipts = state.receipts[accountId].filter(receipt => {
    const date = moment.utc(receipt.date, "YYYYMMDD");
    return weekStart.isSameOrBefore(date, 'date') && weekEnd.isSameOrAfter(date, 'date');
  });
  return {receipts, channels};
}

export const buildOrderReportsWeekData = ({
  accountId,
  weekStart,
  weekEnd,
  prevWeekStart,
  prevWeekEnd
}) => {
  const state = store.getState();
  const theoreticalStock = calculcateWeekTheoreticalStock(moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate(), accountId);
  const closingStocktake = findNextStocktake(state, accountId, moment.utc(weekEnd).toDate());
  const closingStocktakeDate = closingStocktake ? moment.utc(getStocktakeDate(closingStocktake)) : null;
  const closingStocktakeCosts = closingStocktake && moment.utc(weekEnd).add(1,'day').isSameOrAfter(closingStocktakeDate, 'date') ? calcStocktakeCost(closingStocktake) : 0;
  const reportIncidentals = selectReportIncidentals({state, accountId, weekStart: moment.utc(weekStart)});
  const weekWastageCost = _.sumBy(
    _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = moment.utc(getWastageDate(wastage));
      if (!wastageDate) {
        return false;
      }
      return wastageDate.isBetween(moment.utc(weekStart), moment.utc(weekEnd), 'day', '[]');
    }),
    (wastage) => ((isNaN(wastage.cost) ? 0 : wastage.cost))
  );
  const weekOrders = getOrdersForPeriod(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate()).filter(o => [orderStatuses.RECEIVED, orderStatuses.SENT, orderStatuses.FINALISED, orderStatuses.MATCHED].includes(o.status));
  //console.log(weekOrders, "Week orders report utils")
  const prevWeekOrders = prevWeekStart && prevWeekEnd ?
    getOrdersForPeriod(accountId, moment.utc(prevWeekStart).toDate(), moment.utc(prevWeekEnd).toDate()).filter(o => [orderStatuses.RECEIVED, orderStatuses.SENT].includes(o.status)) :
    null;
  const weekOrdersCost = calcOrdersTotal(weekOrders);
  const weekOrdersAverage = weekOrders.length > 0 ? weekOrdersCost / weekOrders.length : 0;
  const prevWeekOrdersCost = prevWeekOrders ? calcOrdersTotal(prevWeekOrders) : null;
  const prevWeekOrdersAverage = prevWeekOrders?.length > 0 ? prevWeekOrdersCost / prevWeekOrders.length : 0;
  const prevReportIncidentals = selectReportIncidentals({state, accountId, prevWeekStart: moment.utc(prevWeekStart)});
  const prevWeekTransfers = prevWeekStart && prevWeekEnd ? periodTransferCosts(accountId, moment.utc(prevWeekStart).toDate(), moment.utc(prevWeekEnd).toDate()) : null;
  const prevProcurementTotal = prevWeekOrders ? getProcurementTotal(prevWeekOrders, prevWeekTransfers, prevReportIncidentals) : null;
  const openPOCount = (_.filter(weekOrders, (order) => (order.status === orderStatuses.SENT))).length;
  const approvedPOCount = (_.filter(weekOrders, (order) => (order.status === orderStatuses.RECEIVED))).length;
  const weekTransfers = periodTransferCosts(accountId, moment.utc(weekStart).toDate(), moment.utc(weekEnd).toDate());
  const procurementTotal = getProcurementTotal(weekOrders, weekTransfers, reportIncidentals);
  return {
    weekOrders,
    prevWeekOrders,
    theoreticalStock,
    reportIncidentals,
    weekWastageCost,
    prevProcurementTotal,
    openPOCount,
    approvedPOCount,
    weekTransfers,
    procurementTotal,
    weekOrdersCost,
    weekOrdersAverage,
    prevWeekOrdersCost,
    prevWeekOrdersAverage,
    closingStocktake,
    closingStocktakeCosts,
    closingStocktakeDate
  }
}

const initReportOptions = {
  sales: true,
  purchases: true,
  receipts: false
}

export const buildAccountsData = ({accounts, options = initReportOptions, ...rest}) => {
  // Check for filterProps directly in the accounts object and extract filters
  const { filterProps = { filters: [] } } = accounts;
  const { filters } = filterProps;

  // Continue with your logic
  const { areas } = accounts;
  if (areas) {
    return areas.reduce((acc, area) => {
      const props = {accountId: area.id, filters, area, ...rest}
      const data = options.sales ? calculateReportsWeekData(props) : {};
      const purchases = options.purchases ? buildOrderReportsWeekData(props) : {};
      const receipts = options.receipts ? buildReceiptsData(props) : {}
      acc[area.id] = {...data, ...purchases, ...receipts, area};
      return acc;
    }, {})
  }
  return {};
}

export const buildAggregatedSalesData = (data) => {
  const returnData = {
    gross: 0,
    net: 0,
    grossLw: 0,
    netLw: 0,
    qty: 0
  };
  //console.log("Input data for aggregation:", data);
  for (const id in data) {
    if (data.hasOwnProperty(id)) {
      const val = data[id];

      if (val.weekSalesTotal) {
        returnData.gross += parseFloat(val.weekSalesTotal);
      }

      if (val.weekSalesNet) {
        returnData.net += parseFloat(val.weekSalesNet);
      }

      if (val.prevWeekSalesTotal) {
        returnData.grossLw += parseFloat(val.prevWeekSalesTotal);
      }

      if (val.prevWeekSalesNet) {
        returnData.netLw += parseFloat(val.prevWeekSalesNet);
      }

      if (val.weekSalesQty) {
        returnData.qty += parseFloat(val.weekSalesQty);
      }
    }
  }
  //console.log("Aggregated sales data:", returnData);
  return returnData;
};


export const buildAggregatedOrdersData = (data) => {
  const returnData = {
    openPOCount: 0,
    approvedPOCount: 0,
    weekOrdersCost: 0,
    weekOrdersAverage: 0,
    prevWeekOrdersCost: 0,
    prevWeekOrdersAverage: 0,
    totalPOCount: 0,
    prevWeekTotalPOCount: 0,
    prevProcurementTotal: 0,
    procurementTotal: 0
  }
  for (const id in data) {
    const val = data[id];
    for (const key in returnData) {
      if (val[key]) {
        returnData[key] += val[key];
      }
    }
    if (val.weekOrders) {
      returnData.totalPOCount += val.weekOrders.length;
    }
    if (val.prevWeekOrders) {
      returnData.prevWeekTotalPOCount += val.prevWeekOrders.length;
    }
  }
  returnData.weekOrdersAverage = returnData.totalPOCount > 0 ? returnData.weekOrdersCost / returnData.totalPOCount : 0;
  returnData.prevWeekOrdersAverage = returnData.prevWeekTotalPOCount > 0 ? returnData.prevWeekOrdersCost / returnData.prevWeekTotalPOCount : 0;
  return returnData;
}

const buildReceipts = (receipts, data, accountId) => {
  return receipts.map(receipt => ({
    ...receipt,
    location: data.area.name,
    channel: data.channels.find(c => c.id === receipt.channelLink)?.name,
    accountId
  }))
}

export const buildAggregatedReceipts = (data) => {
  let receipts = [];
  for (const id in data) {
    const val = data[id];
    if (val.receipts) {
      receipts = receipts.concat(buildReceipts(val.receipts, val, id));
    }
  }
  return receipts;
}

// ANALYTICS/COSTS/INGREDIENTS

//VOLUME
// Calculates the order amount for a specific item in an order, matching by option ID.
const orderOptItem = (opt, order, ing) => {
  const item = order.items.find(i => i.id === opt.id);
  return item ? ingOrderAmount(item) : 0;
}

// Aggregates order data for a specified period based on start and end dates.
const buildPeriodOrders = ( orders, start, end, opt, ing, week = false ) => {
  const periodOrders = orders.filter(o => {
    const orderDate = moment.utc(o.date, 'YYYY-MM-DD');
    const isWithinRange = orderDate.isBetween(moment.utc(start), moment.utc(end), 'day', '[]');
    return isWithinRange;
  });
  const sum = periodOrders.reduce((total, order) => orderOptItem(opt, order, ing) + total,  0);
  const displaySum = denormalizeIngredientQuantity({ recipeunit: ing.recipeunit, amount: sum });
  return displaySum;
}

export const buildWeekOrders = ({ orders, weekStart, weekEnd, opt, ing }) => buildPeriodOrders(orders, weekStart, weekEnd, opt, ing, true);

export const buildPrevWeekorders = ({ orders, prevWeekStart, prevWeekEnd, opt, ing }) => buildPeriodOrders(orders, prevWeekStart, prevWeekEnd, opt, ing, true);

const buildDatePeriodOrders = (orders, weekStart, subtract, opt, ing, log = false) => {
  const startDate = moment.utc(weekStart).subtract(subtract.num, subtract.unit);
  return buildPeriodOrders(orders, startDate, weekStart, opt, ing, log);
}

export const buildMonthOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 5, unit: 'weeks'}, opt, ing);
export const buildThreeMonthOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 13, unit: 'weeks'}, opt, ing);
export const buildYearOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 53, unit: 'weeks'}, opt, ing);

//Aggregates totals from different time periods for a particular procurement item.
const calcOptTotal = (item, total) => {
  for (const prop in total) {
    if (item[prop]) {
      total[prop] += item[prop];
    }
  }
  return total;
}

//Formats all numerical values in a total object to two decimal places or sets them to '0' if they are zero.
const setFinalZero = (total) => {
  for (const prop in totalInit) {
    if (total[prop] === 0) {
      total[prop] = '0'
    }
    else if (total[prop]) {
      total[prop] = total[prop].toFixed(2)
    }
  }
  return total;
}

//Initial values for calculating totals, ensuring every category starts from zero.
const totalInit = {
  weekOrders: 0,
  prevWeekOrders: 0,
  monthOrders: 0,
  threeMonthOrders: 0,
  yearOrders: 0
}

//Aggregates procurement data across various options for a single ingredient and applies formatting to the aggregated values.
export const buildIngOptSum = (ing, uom, optProcurments) => {
  const sum = optProcurments.reduce((total, item) => calcOptTotal(item, total), { ...totalInit });
  return { ...ing, uom, ...setFinalZero(sum), opts: optProcurments.map(o => setFinalZero(o)) };
}

//PRICING
// Filters the list of all options to match those that belong to a specific ingredient.
export const buildIngOptions = (ing, optionList) => optionList.filter((opt) => ing.id === opt.ingredientId);

// Converts the unit of measure (UOM) from 'unit' to a numerical scale for calculations.
const uomUnits = (uom) => {
  return uom === 'unit' ? 1 : 1000;
}

// Calculates the final unit price of an ingredient option based on its UOM.
export const finalUnitPrice = (opt, uom, price = null) => {
  price = price || opt.unitprice;
  price = price / parseFloat(opt.gramperportion);
  return price * uomUnits(uom);
}

// Retrieves the price from an order for a specific ingredient option, if available.
export const orderIngPrice = (opt, order) => {
  const item = order?.items.find(i => i.ingredientId === opt.ingredientId);
  return item ? parseFloat(item.finalprice) : 0;
}

// Constructs an object representing the price, unit price, and date for an ingredient option.
export const createOptPrice = (opt, order, uom) => ({
  price: orderIngPrice(opt, order) || opt.unitprice,
  unitprice: finalUnitPrice(opt, uom,  orderIngPrice(opt, order)),
  date: moment.utc(getOrderDate(order))
});

// Determines the current price based on the most recent order or the static unit price if no orders are found.
export const buildOptionCurrentPrice = ({ orders, weekEnd, opt, uom }) => {
  if (!orders ||
      !orders[0]
  ) return {
    price: opt.unitprice,
    unitprice: finalUnitPrice(opt, uom),
    date: moment.utc(weekEnd)
  };
  // first option ordered
  return createOptPrice(opt, orders[0], uom)
}

// Calculates the product of unit price and ordered quantity for an ingredient option.
// Utility to calculate price * volume for an ingredient option
export const calculatePriceVolume = (opt, ing) => {
  const unitPrice = parseFloat(opt.unitPrice);
  const quantity = parseFloat(ing.quantityOrdered);  // Make sure quantityOrdered is being calculated somewhere
  return unitPrice * quantity;
}

// Calculates the total spent on an ingredient option across multiple orders within a specified date range.
export const calculateTotalSpentOnIngredient = (opt, orders, weekStart, weekEnd) => {
  //console.log("Total orders received: ", orders.length);
  // Filter orders based on the date range.
  const filteredOrders = orders.filter(order => {
    const orderDate = moment.utc(getOrderDate(order));
    return orderDate.isSameOrAfter(weekStart) && orderDate.isSameOrBefore(weekEnd);
  });
  //console.log("Filtered orders count: ", filteredOrders.length);

  // Calculate total spent on filtered orders.
  return filteredOrders.reduce((total, order) => {
    const item = order.items.find(i => i.ingredientId === opt.ingredientId);
    if (item) {
      const unitPrice = orderIngPrice(opt, order); // Reuse existing utility to get price.
      const quantity = parseFloat(item.quantity);
      //console.log(`Processing item: ${item.ingredientId}, Unit Price: ${unitPrice}, Quantity: ${quantity}`); // Log each item processed
      total += unitPrice * quantity;
      //console.log("No matching item found in order: ", order.id);
    }
    return total;
  }, 0);
};

// Calculate the total order value across all ingredients within the specified date range.
export const calculateTotalOrderValue = (orders, weekStart, weekEnd) => {
  return orders.reduce((totalValue, order) => {
    if (moment.utc(getOrderDate(order)).isBetween(weekStart, weekEnd, undefined, '[]')) {
      order.items.forEach(item => {
        const unitPrice = parseFloat(item.finalprice || item.unitprice); // Use final price if available, else unit price
        const quantity = parseFloat(item.quantity);
        totalValue += unitPrice * quantity;
      });
    }
    return totalValue;
  }, 0);
};

// A placeholder for when no valid pricing data exists.
const createNullPrice = { unitprice: null, date: null }

// Finds a change in price from previous orders and returns the newer price.
export const buildOptionPrevPrice = ({ orders, opt, uom }) => {
  const initPrice = orders[0] ? orderIngPrice(opt, orders[0]) : parseFloat(opt.unitprice);
  const nextIndex = orders.findIndex(o => orderIngPrice(opt, o) !== initPrice);
  if (nextIndex > -1) return createOptPrice(opt, orders[nextIndex], uom)

  return createNullPrice;
}
