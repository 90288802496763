//React
import React, { useState, useMemo} from 'react';

//Libraries
import { majorScale, Pane, SearchInput } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import { CustomCard } from '../components/Recharts'
import Block from '../../../components/ui/Block/Block'

//Files
import { currency } from '../../../utils/format';
import { getAccountOptionOrdersFromDate, getIngredientStockUOM } from '../../../utils/functions';
import { buildIngOptions, buildOptionCurrentPrice, calculateTotalOrderValue, 
  calculateTotalSpentOnIngredient, buildWeekOrders, buildOptionPrevPrice } from '../../../utils/reports';


// Aggregates current and previous pricing data for a single ingredient option.
const buildOptionPrices = ({ opt, ing, accounts, accountId, weekStart, weekEnd, supplierMap, show, totalOrderValue, ...rest }) => {
  // options ordered from start sorted by date
  const orders = _.reverse(getAccountOptionOrdersFromDate(accounts, accountId, opt, moment(weekEnd)));
  //console.log(orders, "ordersssss")
  //console.log(weekStart, "buildOptionPriceStata")
  //console.log(weekEnd, "buildOptionPriceStata")
  const props = {...rest, opt, orders, weekEnd, ing}
  const totalSpent = calculateTotalSpentOnIngredient(opt, orders, weekStart, weekEnd);

  const percentageOfTotal = totalOrderValue > 0 ? (totalSpent / totalOrderValue) * 100 : 0;

  const currentPrice = buildOptionCurrentPrice(props);
  const lastPrice = buildOptionPrevPrice(props);

  return {
    priceUom: currentPrice.unitprice ? currency(currentPrice.unitprice) : '-',
    supUom: opt.uom,
    currentPrice: currentPrice.price ? currency(currentPrice.price) : '-',
    lastPrice: lastPrice.price ? currency(lastPrice.price) : '-',
    lastDate: lastPrice.date ? lastPrice.date.format('DD/MM/YYYY') : '-',
    opt,
    id: opt.id,
    supplierName: supplierMap[opt.supplierId].name,
    totalSpent: currency(totalSpent),
    percentageOfTotal: percentageOfTotal.toFixed(2) + '%',
  }
}

// Builds a list of pricing data for all ingredient options of an ingredient.
const buildIngOptionPrices = ({ ing, optionList, ...rest }) => {
  const ingsOpts = buildIngOptions(ing, optionList);
  const uom = getIngredientStockUOM(ing);
  const optsData = ingsOpts.map(opt => buildOptionPrices({opt, ing, uom, ...rest}));
  return {
    ...ing,
    priceUom: `Price/${uom}`,
    hideLink: true,
    opts: optsData
  }
}

const buildPricesList = ({ ings, ...rest }) => {
  // Construct detailed data for each ingredient
  const detailedIngData = ings.map(ing => {
    const ingData = buildIngOptionPrices({ ing, ...rest });
    // Find the maximum totalSpent within the options of each ingredient
    const maxTotalSpent = Math.max(...ingData.opts.map(opt => parseFloat(opt.totalSpent.replace(/[^0-9.-]+/g, ""))));
    // Append this max value to the ingredient data for sorting purposes
    return { ...ingData, maxTotalSpent };
  });

  // Sort the ingredients based on the maximum totalSpent found in their options
  const sortedDetailedIngData = detailedIngData.sort((a, b) => b.maxTotalSpent - a.maxTotalSpent);

  // Merge the detailed data into a single list to be displayed
  return sortedDetailedIngData.reduce((list, ingData) => {
    list.push({ ...ingData, opts: null }); // Optionally remove 'opts' if you don't want to display them separately
    list = list.concat(ingData.opts); // Add the options back if needed
    return list;
  }, []);
}

const tableHeaders = ({accounts, accountId}) => [
  { label: 'Name', field: 'name', type: 'text', width: 4 },
  { label: 'Supplier', field: 'supplierName', type: 'text', width: 3 },
  { label: 'Price/UOM', type: 'text', field: 'priceUom', width: 2 },
  { label: 'Sup UOM', type: 'text', field: 'supUom', width: 2 },
  { label: 'Price', type: 'text', field: 'currentPrice', width: 1 },
  { label: 'Total Spent', type: 'text', field: 'totalSpent', width: 2 },
  { label: '%', type: 'text', field: 'percentageOfTotal', width: 1 },
  { label: 'View', type: 'action', link: `/${accounts ? 'analytics' : `reports/${accountId}`}/procurement/`, icon: 'clipboard', width: 1 }
]

const IngredientsPricingTab = ({ optionList, ings, isOpen, setCategory, categoryOpt, isMobile, orders, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter ingredients based on the search term
  const filteredIngredients = useMemo(() => {
    return ings.filter(ing => 
      ing.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [ings, searchTerm]);

  const totalOrderValue = orders.procurementTotal;
  const priceList = buildPricesList({ ings: filteredIngredients, optionList, totalOrderValue, ...rest });
  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt.value);

  return (
    <>
      <Pane display='grid' gridTemplateColumns={'repeat(3, auto)'} gap={majorScale(2)} marginBottom={majorScale(2)}>
        <CustomCard
          title='Nb ingredients'
          data={ings.length.toString()}
        />
        <CustomCard
          title='Nb supplier options'
          data={optionList.length.toString()}
        />
        <CustomCard
          title='Net order value'
          data={[orders.procurementTotal.toString(), 'currency']}
        />
      </Pane>
      <Pane>
        <SearchInput
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search ingredients..."
          marginBottom={majorScale(1)}
        />
        <Block flex={`1 0 auto`} display="flex" height="500px" flexDirection="column" marginBottom={majorScale(2)}>
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
          <DataTable
            items={priceList}
            headers={tableHeaders({...rest})}
            listHeight={450}
          />
          </Pane>
        </Block>
      </Pane>
    </>
  );
}

export default IngredientsPricingTab;
