//React + Libraries
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

//Libraries
import { Pane, useTheme } from 'evergreen-ui';

//Pages
import './App.css';
import AccountSelect from './pages/AccountSelect/AccountSelect';

//Components
import NavMenu from './components/NavMenu/NavMenu';
import NavMenuMobile from './components/NavMenuMobile/NavMenuMobile';
import Header from './components/Header/Header';
import LoadingIndicator from './components/IsLoading/IsLoading';

//TODO - review the loading collection process isLoading vs asyncLoading
const App = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLoading = useSelector((state) => state.loading);
  const user = useSelector((state) => state.user);
  const currentAccountId = useSelector((state) => state.currentAccount);
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const hasActiveAccount = currentAccountId && currentAccountId !== 'pending';

  useEffect(() => {
    if (!user) {
      navigate("/signin", { replace: true });
    }
  }, [user, navigate]);

  return (
    <>
      {hasActiveAccount && user ? (
      <Pane
          display="flex"
          flexDirection="row"
          height="100vh"
          width="100vw"
      >
        <NavMenu />
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          flex="1 1 auto"
          overflow="hidden"
          maxHeight="100vh"
          maxWidth={isDesktop && hasActiveAccount ? 'calc(100% - 230px)' : '100vw'}
        >
          <Header />
          {isLoading ? (
            <LoadingIndicator message={"We are loading your data..."} />
          ) : (
            <Pane is="main" display="flex" flexDirection="column" overflowY="auto" background={theme.colors.white}>
              {hasActiveAccount && <NavMenuMobile style={{ position: 'absolute', bottom: 0, width: '100%' }}/>}
              <Outlet />
            </Pane>
            )}
        </Pane>
      </Pane>
      ) : !hasActiveAccount && user ? (
        <AccountSelect />
      ) : (
        <Outlet /> 
      )}
    </>
  );
};

export default App;
