//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { SelectField, useTheme } from 'evergreen-ui';


const CSelectField = ({ value, onChange, label, placeholder, disabled }) => {
    const theme = useTheme();
    const selectFieldStyle = {
        color: theme.colors.black,
        fontSize: "14px",
    };
    
  return (
    <SelectField
      flex="1 1 0"
      label={label}
      placeholder={placeholder}
      value={value || ''}
      onChange={onChange}
      disabled={disabled}
      style={selectFieldStyle}
    >
    </SelectField>
  );
};

CSelectField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

CSelectField.defaultProps = {
  value: '',
};

export default CSelectField;
