import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkRouter } from 'react-router-dom';
import { minorScale, Pane, Button, Link, UnorderedList, ListItem, IconButton, Dialog, Text } from 'evergreen-ui';

import Page from '../../components/Page/Page';
import { actions } from '../../store/actions';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';


const Sections = () => {
  const dispatch = useDispatch();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const sectionList = useSelector((state) => state.sections);
  const [removingSection, setRemovingSection] = useState(false);
  requiresWorkingAccount();

  return (
    <Page title="Sections" containerProps={{ maxWidth: 600 }}>
      <Dialog
          isShown={!!removingSection}
          title="Remove Section"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={(close) => {
            dispatch(actions.sections.removeSection(removingSection.id));
            close();
          }}
          onCloseComplete={() => setRemovingSection(false)}
      >
        <Text>Are you sure you wish to remove the section {removingSection.name}?</Text>
      </Dialog>
      <Pane display="flex" justifyContent="flex-end">
        <Button is={LinkRouter} iconBefore="add" appearance="primary" to="/sections/add">Add Section</Button>
      </Pane>
      <UnorderedList listStyle="none" margin={0} padding={0}>
        {sectionList && sectionList.map((section) => (
          <ListItem key={section.id} display="flex" alignItems="center">
            <Link
                is={LinkRouter}
                to={`/sections/${section.id}`}
                flex="1 0 auto"
                textDecoration="none"
                paddingBottom={minorScale(2)}
                paddingTop={minorScale(2)}
            >{section.name}</Link>
            <IconButton appearance="minimal" icon="trash" onClick={() => setRemovingSection(section)} />
          </ListItem>
        ))}
      </UnorderedList>
    </Page>
  )
};

export default Sections;
