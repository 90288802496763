//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

//Components
import EntityList from '../../components/EntityList.js/EntityList';
import GroupsEdit from './components/GroupsEdit'; 
import { TabNavigation, Tab } from 'components/ui';

//Files
import { actions } from '../../store/actions'

const ManageGroups = () => {
    const accountId = useSelector((state) => state.currentAccount);
    const account = useSelector((state) => state.accounts[accountId]);
    const userProfile = useSelector((state) => state.profile);
    const userAccounts = useSelector((state) => state.accounts);
    const [currentTab, setCurrentTab] = useState('product-groups');

    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    
    return (
    <Pane>
        <Pane padding={majorScale(2)}>
            <TabNavigation marginBottom={majorScale(2)}>
                <Tab
                    id="product-groups"
                    onSelect={() => handleTabChange('product-groups')}
                    isSelected={currentTab === 'product-groups'}
                >
                    Product groups
                </Tab>
                <Tab
                    id="group-categories"
                    onSelect={() => handleTabChange('group-categories')}
                    isSelected={currentTab === 'group-categories'}
                >
                    Group categories
                </Tab>
            </TabNavigation>
            
            {currentTab === 'group-categories' && (
            <EntityList
                label="Group Categories"
                buttonLabel="group category"
                actions={{
                add: actions.departments.addDepartment,
                update: actions.departments.updateDepartment,
                remove: actions.departments.removeDepartment,
                }}
                accountId={accountId}
                type={'departments'}
            />
            )}
            {currentTab === 'product-groups' && (
            <GroupsEdit
                account={account}
                userAccounts={userAccounts}
                userProfile={userProfile}
            />
            )}
        </Pane>
    </Pane>
    );
  }
  
ManageGroups.propTypes = {
    accountId: PropTypes.string.isRequired,
};
  
export default ManageGroups;
