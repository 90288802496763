import fb from './';
import { getCollectionRef } from './common';

export const addOrderAttachment = async (attachment, bucket, accountId) => {
  const orderAttachmentsRef = getCollectionRef('orderAttachments', accountId);
  const { file, ...otherProps } = attachment;
  const { id } = await orderAttachmentsRef.add({
    ...otherProps,
    filename: file.name,
    created: fb.serverTime(),
    lastModified: fb.serverTime()
  });
  const ext = file.name.split('.').pop();
  const filename = `${bucket}/images/orderAttachments/${id}.${ext}`;
  orderAttachmentsRef.doc(id).update({ 'original_imagefilename': filename });
  return fb.storage.ref().child(filename).put(file);
};

export const removeOrderAttachment = async (attachmentId, accountId) => {
  const orderAttachmentsRef = getCollectionRef('orderAttachments', accountId);
  const attachmentDoc = await orderAttachmentsRef.doc(attachmentId).get();
  const attachment = attachmentDoc.data();
  fb.storage.ref().child(attachment.original_imagefilename).delete();
  // TODO: How to delete the resized versions ('main_imageURL')?
  return orderAttachmentsRef.doc(attachmentId).delete();
};

export const updateOrderAttachment = (attachmentId, order, accountId) => {
  const orderAttachmentsRef = getCollectionRef('orderAttachments', accountId);
  return orderAttachmentsRef.doc(attachmentId).update({ ...order, lastModified: fb.serverTime() });
};
