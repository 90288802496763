//React
import React from 'react';

//Libraries
import _ from 'lodash';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Accordion from '../../../components/ui/Accordion/Accordion';
import ValuesPane from '../../../pages/Stocks/components/ValuesPane';

//Files
import { filterActiveIngredients } from '../../../utils/ingredients';

const StockTakeDetailIngredients = ({ tableHeaders, mobileTableHeaders, categoryOpt, ingredients, isMobile, filters, isOpen, setCategory }) => {
  // First, filter out inactive ingredients
  const activeIngredients = filterActiveIngredients(ingredients);

  // Filter ingredients based on category and check if all specified fields are zero
  const ingList = _.filter(activeIngredients, (ing) => (
    (categoryOpt.value === 'uncategorized' ? _.isEmpty(ing.categoryId) : _.includes(ing.categoryId, categoryOpt.value)) &&
    !(ing.unitStockAmount === 0 && ing.thisStockAmount === 0 && ing.wastageQuantity === 0 && ing.transferQuantity === 0 && ing.orderQuantity === 0 && ing.saleQuantity === 0)
  ));

  if (ingList.length === 0) {
    return null;
  }

  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt.value);

  return (
    <Accordion
      label={categoryOpt.label}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
      displayBottom={<ValuesPane ings={ingList} tableHeaders={tableHeaders} mobileTableHeaders={mobileTableHeaders} isMobile={isMobile} />}
    >
      <Pane maxHeight="450px" overflowY="hidden">
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
          overflowY="auto"
          height="100%" 
        >
          <DataTable
            items={ingList}
            filters={filters}
            headers={(isMobile) ? mobileTableHeaders : tableHeaders}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
            listHeight={400}
          />
        </Pane>
      </Pane>
    </Accordion>
  );
}

export default StockTakeDetailIngredients;
