import { getCollectionRef } from './common';

export const addSupplierCategory = (supplierCategory, accountId) => {
  const supplierCategoriesRef = getCollectionRef('supplierCategories', accountId);
  return supplierCategoriesRef.add(supplierCategory);
};

export const removeSupplierCategory = (supplierCategoryId, accountId) => {
  const supplierCategoriesRef = getCollectionRef('supplierCategories', accountId);
  return supplierCategoriesRef.doc(supplierCategoryId).delete();
};

export const updateSupplierCategory = (supplierCategoryId, supplierCategory, accountId) => {
  const supplierCategoriesRef = getCollectionRef('supplierCategories', accountId);
  return supplierCategoriesRef.doc(supplierCategoryId).update(supplierCategory);
};
