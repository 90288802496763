import fb from './';
import { getCollectionRef } from './common';

export const addReportsComment = (comment, accountId) => {
  const reportsCommentsRef = getCollectionRef('reportsComments', accountId);
  return reportsCommentsRef.add({ ...comment, created: fb.serverTime() });
};

export const removeReportsComment = (commentId, accountId) => {
  const reportsCommentsRef = getCollectionRef('reportsComments', accountId);
  return reportsCommentsRef.doc(commentId).delete();
};

export const updateReportsComment = (commentId, comment, accountId) => {
  const reportsCommentsRef = getCollectionRef('reportsComments', accountId);
  return reportsCommentsRef.doc(commentId).update(comment);
};
