//React
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import DataTable from '../../../components/DataTable/DataTable';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { getWE } from '../../../utils/time';
import { colors, stockRecordingUnits, TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { datePeriodFormat, currency, shortDateDisplayFormat } from '../../../utils/format';
import { getWastageDate } from '../../../utils/functions';
import { current } from '../../../utils/selectors';
import { actions } from '../../../store/actions'
 
const renderUOM = (item) => {
  // Ingredients may have a "display" value already, default to that
  if (item.displayRecordUOM) {
    return item.displayRecordUOM;
  }
  // Ingredient or recipe, has the UOM recorded so we don't really care either way
  if (item.recordUOM) {
    return stockRecordingUnits[item.recordUOM];
  }
  // No recorded UOM, check if it's an ingredient
  if (item.ingredient) {
    return stockRecordingUnits[item.ingredient.recipeunit];
  }

  // No recorded UOM and it's not an ingredient, so show UOM for a recipe
  return stockRecordingUnits[item.recipe.yieldDescription || 'unit'];
};

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 4 },
  { label: 'UOM', type: 'calc', calc: renderUOM, width: 2 },
  { label: 'Qty', field: 'amount', type: 'numeric', width: 2 },
  { label: 'Reason', field: 'reason', type: 'text', width: 3 },
  { label: 'Value', field: 'cost', type: 'numeric', format: 'currency', prefix: '£ ', width: 2 },
  { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 },
];


const WastageDetail = () => {
  const { accountId, weekStart } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [deletingWastage, setDeletingWastage] = useState(null);

  const periodStart = moment.utc(weekStart).toDate();
  const periodEnd = getWE(periodStart);

  const wastages = useSelector((state) => _.reverse(
    _.sortBy(
      _.filter(current(state, 'wastages', accountId), (wastage) => (moment(getWastageDate(wastage)).isBetween(periodStart, periodEnd, 'day', '[]'))),
      (wastage) => getWastageDate(wastage)
    )
  ));

  const totalCost = _.reduce(wastages, (sum, wastage) => (sum + (isNaN(wastage.cost) ? 0 : wastage.cost)), 0);
  const periodFormatted = datePeriodFormat(periodStart, periodEnd);

  const doDeleteWastage = (wastage) => {
    dispatch(actions.wastages.removeWastage(accountId, wastage.id));
  };

  return (
    <React.Fragment>
      <Block
          background={theme.colors.offwhite}
          height={48}
          display="flex"
          alignItems="center"
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginBottom={majorScale(2)}
      >
        <IconWrapper
            appearance="clickable"
            is={Link}
            to={`/${accountId}/review/wastage`}
            name="arrowLeft"
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3">Wastage - {periodFormatted.from} to {periodFormatted.to}</CustomHeading>
      </Block>

      <Block display="flex" flexDirection="column">
        <Pane
            display="flex"
            alignItems="center"
            paddingX={majorScale(2)}
            marginY={majorScale(2)}
        >
          <CustomHeading level="4" marginRight={majorScale(2)}>Wastage Worth</CustomHeading>
          <Text size={600} color={theme.colors.tertiary100}>£ {currency(totalCost)}</Text>
        </Pane>
        <DataTable
            headers={tableHeaders}
            items={wastages}
            tableProps={{ minHeight: TABLE_MIN_HEIGHT }}
            onDelete={(wastage) => setDeletingWastage(wastage)}
        />
      </Block>

      <CustomDialog
        isOpen={!!deletingWastage}
        title="Delete Wastage?"
        intent="danger"
        onClose={() => setDeletingWastage(null)}
        onConfirm={() => {
          doDeleteWastage(deletingWastage);
          setDeletingWastage(null);
        }}
      >
        {!!deletingWastage && (
          <Pane marginBottom={majorScale(4)}>
            <Text>Are you sure you wish to delete the Wastage dated {shortDateDisplayFormat(getWastageDate(deletingWastage))}?</Text>
          </Pane>
        )}
      </CustomDialog>

    </React.Fragment>
  );
};

export default WastageDetail;
