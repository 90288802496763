//React
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Table, Text, Pane } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { currency, datePeriodFormat, shortDateDisplayFormat } from '../../../utils/format';
import { getWastageDate, getTransferStringDate } from '../../../utils/functions';
import { current } from '../../../utils/selectors';
import { dateFormat, getWC, getWE } from '../../../utils/time';
import { colors } from '../../../utils/constants';
import { actions } from '../../../store/actions/index';
//
const TransferArchive = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [deletingPO, setDeletingPO] = useState(false);

  const weeklyTransfers = useSelector((state) => {
    return _.mapValues(
      _.groupBy(
        _.reverse(
          _.sortBy(current(state, 'transfers', accountId), (transfer) => getTransferStringDate(transfer))
        ),
        (transfer) => dateFormat(getWC(getTransferStringDate(transfer)))
      )
    );
  });
  
  const toggleRow = (rowId) => {
    if (_.includes(expandedRows, rowId)) {
      setExpandedRows(_.without(expandedRows, rowId));
    }
    else {
      setExpandedRows(_.concat(expandedRows, rowId));
    }
  };

  const doDeleteTransfer = (transfer) => {
    dispatch(actions.transfers.removeTransfer(accountId, transfer.id));
  };


  return (
    <React.Fragment>
      <CustomDialog
          isOpen={!!deletingPO}
          title="Delete Transfer?"
          intent="danger"
          onClose={() => setDeletingPO(false)}
          onConfirm={() => {
            doDeleteTransfer(deletingPO);
            setDeletingPO(false);
          }}
      >
        {!!deletingPO && (
          <Pane marginBottom={majorScale(4)} >
            <Text>Are you sure you wish to delete the Transfer dated {shortDateDisplayFormat(getTransferStringDate(deletingPO))}?</Text>
          </Pane>
        )}
      </CustomDialog>

      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="3" marginY={majorScale(2)} paddingX={majorScale(2)}>Transfers</CustomHeading>
        <Table>
          <Table.Head height={48} paddingLeft={0}>
            <Table.TextHeaderCell>Period</Table.TextHeaderCell>
            <Table.TextHeaderCell>Nb transfer</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={100} flexGrow={0} flexShrink={0}>View</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(weeklyTransfers) && (
              <Table.Row height={48}>
                <Table.TextCell>No transfers to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(weeklyTransfers, (weekTransfers, week) => {
              const isExpanded = _.includes(expandedRows, week);
              const startDate = getWC(getTransferStringDate(_.first(weekTransfers)));
              const periodFormat = datePeriodFormat(startDate, getWE(startDate));
              return (
                <React.Fragment key={`week_${week}`}>
                  <Table.Row height={48} isSelectable onSelect={() => toggleRow(week)}>
                    <Table.TextCell>{periodFormat.from} - {periodFormat.to}</Table.TextCell>
                    <Table.TextCell flex="1 0 auto">{weekTransfers.length}</Table.TextCell>
                    <Table.Cell flexBasis={50} flexGrow={0}>
                      <IconWrapper 
                        name={isExpanded ? 'caretUp' : 'caretDown'}
                        appearance="clickable" />
                    </Table.Cell>
                  </Table.Row>
                  {isExpanded && (
                    <Table>
                      <Table.Head height={48}>
                        <Table.TextHeaderCell>Date</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Description</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Nb of items</Table.TextHeaderCell>
                        <Table.TextHeaderCell>Worth</Table.TextHeaderCell>
                        <Table.TextHeaderCell>View</Table.TextHeaderCell>
                      </Table.Head>
                      <Table.Body>
                        {_.map(weekTransfers, (transfer) => (
                          <Table.Row height={48} key={transfer.id}>
                            <Table.TextCell>{shortDateDisplayFormat(getTransferStringDate(transfer))}</Table.TextCell>
                            <Table.TextCell>{transfer.transferType}</Table.TextCell>
                            <Table.TextCell>{transfer.records.length}</Table.TextCell>
                            <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(transfer.totalCost)}</Table.TextCell>
                            <Table.Cell flexBasis={50} flexGrow={0} padding={0}>
                              <IconWrapper
                                  name="clipboard"
                                  appearance="clickable"
                                  is={Link}
                                  to={`/${accountId}/review/transfers/${transfer.id}`}
                              />
                            </Table.Cell>
                            <Table.Cell flexBasis={50} flexGrow={0} padding={0}>
                              <IconWrapper
                                  name="trash"
                                  appearance="danger"
                                  intent="danger"
                                  onClick={() => setDeletingPO(transfer)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </Block>
    </React.Fragment>
  );
};

export default TransferArchive;
