import fb from './';
import { getCollectionRef } from './common';

export const addFoodRecord = (record, accountId) => {
  const foodRecordsRef = getCollectionRef('foodRecords', accountId);
  return foodRecordsRef.add({ ...record, created: fb.serverTime() });
};

export const removeFoodRecord = (recordId, accountId) => {
  const foodRecordsRef = getCollectionRef('foodRecords', accountId);
  return foodRecordsRef.doc(recordId).delete();
};

export const updateFoodRecord = (recordId, record, accountId) => {
  const foodRecordsRef = getCollectionRef('foodRecords', accountId);
  return foodRecordsRef.doc(recordId).update({ ...record, lastModified: fb.serverTime() });
};
