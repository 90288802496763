//React
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import { Pane, majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';
import EntityList from '../../components/EntityList.js/EntityList';

//Files
import { actions } from '../../store/actions';

const ManageAreas = () => {
  const navigate = useNavigate();
  const accountId = useSelector((state) => state.currentAccount);

  return (
    <Page containerProps={{ maxWidth: 600, width: '100%' }}>
        <Pane padding={majorScale(2)}>
            <EntityList
                label='areas'
                buttonLabel='area'
                actions={{
                    add: actions.areas.addArea,
                    update: actions.areas.updateArea,
                    remove: actions.areas.removeArea,
                }}
                accountId={accountId}
                type={'areas'}
            />
        </Pane>
    </Page>
  );
};

export default ManageAreas;
