import { getCollectionRef } from './common';

export const addSales = (sales, accountId) => {
  const salesRef = getCollectionRef('sales', accountId);
  return salesRef.add(sales);
};

export const removeSales = (salesId, accountId) => {
  const salesRef = getCollectionRef('sales', accountId);
  return salesRef.doc(salesId).delete();
};

export const updateSales = (salesId, sales, accountId) => {
  const salesRef = getCollectionRef('sales', accountId);
  return salesRef.doc(salesId).update(sales);
};
