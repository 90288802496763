//React
import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Table } from 'evergreen-ui';

//Components
import Button from '../../../components/ui/Button/Button';
import GroupRemoveDialog from '../AssignGroupsComponents/GroupRemoveDialog';
import AreasTabDialog from '../AreasComponents/AreasTabDialog';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { actions } from '../../../store/actions';
import { accountTypes } from '../../../utils/constants';
import { current } from '../../../utils/selectors';

const buildAreaName = (account, areaCat) => `${account.name} - ${areaCat.name}`;

const AreasTab = ({ account, userAccounts, userProfile, errors, setErrors, isOwner }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToArea = (accountId) => navigate(`/areas/${accountId}/account`);

  const [removingDept, setRemovingDept] = useState(false);
  const [addingDept, setAddingDept] = useState(false);
  const [childDeptName, setChildDeptName] = useState(false);
  const [childDeptArea, setChildDeptArea] = useState(false);

  const areas = account.children?.map(areaId => userAccounts[areaId]);
  const areaCats = useSelector(state => current(state, 'areas')).filter(c => (areas || []).findIndex(a => a?.area?.id === c.id) === -1); 
  
  const confirmRemoveDepartment = (deptInfo) => {
    setRemovingDept(deptInfo);
  };

  const removeChildDepartment = (childAccount) => {
    dispatch(actions.accounts.accountClear(childAccount.id));
    dispatch(actions.accounts.accountUpdate({
      ...account,
      children: _.without(account.children, childAccount.id),
    }));
    dispatch(actions.accounts.accountUpdate({
      ...childAccount,
      parents: _.without(childAccount.parents, account.id),
      deleted: true,
    }));
    dispatch(actions.accountUsers.removeAccount(childAccount.id));
  };

  const addNewDepartment = () => {
    if (childDeptArea) {
      const area = areaCats.find(a => a.name === childDeptArea);
      const name = buildAreaName(account, area);
      dispatch(actions.accounts.accountCreate({
        type: accountTypes.DEPARTMENT,
        name,
        area,
        accountName: account.name,
        departments: [],
        parents: _.uniq([ ...(account.parents || []), account.id ]),
        ownerEmail: userProfile.email,
        ownerId: userProfile.uid,
        ownerName: userProfile.name,
      }, (newAccountData) => {
        // Account created successfully, now update the location account info
        dispatch(actions.accounts.accountUpdate({
          ...account,
          children: _.uniq([ ..._(account.children || []), newAccountData.id ]),
        }));
        setAddingDept(false);
        setChildDeptName(false);
      }));
    }
  }

  return (
    <Fragment>
      <Pane>
        <CustomHeading level="3" margin={majorScale(2)}>Areas - {account.name}</CustomHeading>
        <Table marginBottom={majorScale(2)}>
          <Table.Head>
            <Table.TextHeaderCell>Area</Table.TextHeaderCell>
            {isOwner() && <Table.TextHeaderCell flex="0 0 56px">Edit</Table.TextHeaderCell>}
            {isOwner && <Table.TextHeaderCell flex="0 0 56px">Remove</Table.TextHeaderCell>}
          </Table.Head>
          <Table.Body>
            {_.isEmpty(account.children) && (
              <Table.Row>
                <Table.TextCell>This location currently has no departments</Table.TextCell>
              </Table.Row>
            )}
            {!_.isEmpty(areas) && _.map(areas, (dept) => {
              if (!dept) return null;

              return (
                <Table.Row key={dept.id}>
                  <Table.TextCell>{dept.name}</Table.TextCell>
                  {isOwner() &&
                    <Table.Cell flex="0 0 56px">
                    <IconWrapper
                        name="edit"
                        appearance="clickable"
                        onClick={() => goToArea(dept.id)}
                    />
                    </Table.Cell>
                  }
                  {isOwner() &&
                    <Table.Cell flex="0 0 56px">
                      <IconWrapper
                          name="trash"
                          appearance="danger"
                          onClick={() => confirmRemoveDepartment(dept)}
                          disabled={true}
                      />
                    </Table.Cell>
                  }
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        {isOwner() && areaCats.length > 0 && (
          <Button
              appearance="primary"
              onClick={() => setAddingDept(true)}
              disabled={false}
          >Add area</Button>
        )}
      </Pane>
      <GroupRemoveDialog
        removingDept={removingDept}
        removingTemp={false}
        setRemovingDept={setRemovingDept}
        removeChildDepartment={removeChildDepartment}
      />
      <AreasTabDialog
        addingDept={addingDept}
        childDeptName={childDeptName}
        childDeptArea={childDeptArea}
        setChildDeptArea={setChildDeptArea}
        setChildDeptName={setChildDeptName}
        updateChildDepartment={addNewDepartment}
        setAddingDept={setAddingDept}
        setErrors={setErrors}
        areaCats={areaCats}
      />
    </Fragment>
  )
}

export default AreasTab;
