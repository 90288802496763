//React
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, FormField, FilePicker, Table, Text, useTheme } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { TODAY } from '../../utils/time';
import { shortDateDisplayFormat } from '../../utils/format';
import { actions } from '../../store/actions';
import { current } from '../../utils/selectors';


const SupplierOptionUpload = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const uploadHistory = useSelector((state) => _.reverse(
    _.sortBy(
      _.filter(
        current(state, 'uploads'),
        { 'uploadtype': 'supplierOptions' }
      ),
      (upload) => (upload.created && upload.created.toDate) ? upload.created.toDate() : TODAY
    )
  ));

  const handleUpload = (csvFile) => {
    if (csvFile) {
      dispatch(actions.supplierOptions.addUpload(csvFile));
    }
  };

  return (
    <Page title="Supplier Ingredient Upload">
      <Block
          background={theme.colors.offwhite}
          height={48}
          display="flex"
          alignItems="center"
          paddingY={majorScale(1)}
          paddingX={majorScale(2)}
          marginBottom={majorScale(2)}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={Link}
            to="/suppliers"
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3">Upload suppliers & ingredients</CustomHeading>
      </Block>

      <Block
          padding={majorScale(2)}
          marginBottom={majorScale(2)}
      >
        <FormField
            label="Upload suppliers & ingredients from file (must be a csv file)"
            required
            marginBottom={majorScale(2)}
        >
          <FilePicker
              multiple={false}
              accept="*.csv"
              onChange={(files) => handleUpload(files[0] || false)}
          />
        </FormField>
        <Pane display="flex" alignItems="center">
          <Button
              appearance="primary"
              iconBefore="download"
              is="a"
              href="/csv/Misenplace-supplier-ingredient-upload-template.xlsx"
              download="Misenplace-supplier-ingredient-upload-template.xlsx"
          >Download template</Button>
        </Pane>
      </Block>

      <Block>
        <CustomHeading level="3" margin={majorScale(2)}>Uploads</CustomHeading>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell flexBasis={200} flexGrow={0}>Import Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Details</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={150} flexGrow={0}>Status</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(uploadHistory) && (
              <Table.Row>
                <Table.TextCell>No uploads to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(uploadHistory, (upload) => (
              <Table.Row key={upload.id}>
                <Table.TextCell flexBasis={200} flexGrow={0}>{shortDateDisplayFormat((upload.created && upload.created.toDate) ? upload.created.toDate() : TODAY)}</Table.TextCell>
                <Table.Cell
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                  <Text>{upload.uploadname}</Text>
                  <Text is="em" marginLeft={majorScale(2)}>
                    {upload.status === 'processing' && 'Please wait while the file is processed'}
                    {upload.status === 'error' && `There has been an error processing this file, please check the formatting is correct (${upload.error})`}
                    {upload.status === 'success' && `The file has been processed and ${upload.count} supplier ingredient(s) added`}
                  </Text>
                </Table.Cell>
                <Table.TextCell flexBasis={150} flexGrow={0}>{upload.status}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>
    </Page>
  );
};

export default SupplierOptionUpload;
