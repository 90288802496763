//React
import React from 'react';
import { useMediaQuery } from 'react-responsive';

//Libraries
import { minorScale, Tab as EvergreenTab, useTheme } from 'evergreen-ui';


const Tab = ({ isSelected, children, ...otherProps }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const theme = useTheme();

  const tabStyle = {
    backgroundColor: isSelected ? theme.colors.secondary10 : theme.colors.offwhite,
    color: isSelected ? theme.colors.black : theme.colors.black ,
    borderRadius: "5px",
    boxShadow: "none",
    height: isMobile ? 32 : 40,
    flex: `1 1 ${isMobile ? 150 : 220}px`,
    paddingX: isMobile ? minorScale(2) : minorScale(5),
    marginBottom: isMobile ? minorScale(1) : 0,
    fontSize: isMobile ? "14px" : "16px",
    fontWeight: "400",
    boxShadow: "-1px 1px 3px rgba(0, 0, 0, 0.1)",
  };

  return (
    <EvergreenTab
        className="mep-tab"
        isSelected={isSelected}
        style={tabStyle}
        {...otherProps}
    >
      {children}
    </EvergreenTab>
  );
};

export default Tab;
