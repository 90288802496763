import { messageTypes } from "../../utils/constants";

const USER_SET = 'USER_SET';
const USER_CLEAR = 'USER_CLEAR';
const APP_INIT = 'APP_INIT';
const APP_LOADING = 'APP_LOADING';
const APP_LOADED = 'APP_LOADED';
const APP_LOAD_STARTED = 'APP_LOAD_STARTED';
const APP_LOAD_COMPLETE = 'APP_LOAD_COMPLETE';
const APP_MESSAGE_SET = 'APP_MESSAGE_SET';
const APP_MESSAGE_CLEAR = 'APP_MESSAGE_CLEAR';
const NAV_MENU_EXPANDED_SET = 'NAV_MENU_EXPANDED_SET';
const PAGE_TITLE_SET = 'PAGE_TITLE_SET';
const PAGE_TITLE_CLEAR = 'PAGE_TITLE_CLEAR';
const WORKING_ACCOUNT_SET = 'WORKING_ACCOUNT_SET';
const WORKING_ACCOUNT_CLEAR = 'WORKING_ACCOUNT_CLEAR';

export const actionTypes = {
  USER_SET,
  USER_CLEAR,
  APP_INIT,
  APP_LOADING,
  APP_LOADED,
  APP_LOAD_STARTED,
  APP_LOAD_COMPLETE,
  APP_MESSAGE_SET,
  APP_MESSAGE_CLEAR,
  NAV_MENU_EXPANDED_SET,
  PAGE_TITLE_SET,
  PAGE_TITLE_CLEAR,
  WORKING_ACCOUNT_SET,
  WORKING_ACCOUNT_CLEAR,
};


const userSet = (userInfo) => {
  return {
    type: USER_SET,
    userInfo
  };
};

const userClear = () => {
  return {
    type: USER_CLEAR
  };
};

const applicationInit = () => {
  return {
    type: APP_INIT
  };
};

const applicationLoaded = () => {
  return {
    type: APP_LOADED
  };
};

const applicationLoading = () => {
  return {
    type: APP_LOADING
  };
};

const applicationLoadStart = (task) => {
  return {
    type: APP_LOAD_STARTED,
    task
  };
};

const applicationLoadComplete = (task) => {
  return {
    type: APP_LOAD_COMPLETE,
    task
  };
};

const appMessageClear = () => {
  return {
    type: APP_MESSAGE_CLEAR
  };
};

const appMessageSet = (messageText, messageType = 'info') => {
  return {
    type: APP_MESSAGE_SET,
    messageText,
    messageType
  };
};

const appMessageSuccess = (messageText) => {
  return appMessageSet(messageText, messageTypes.SUCCESS);
};

const appMessageError = (messageText) => {
  return appMessageSet(messageText, messageTypes.ERROR);
};

const navMenuExpandedSet = (expanded) => {
  return {
    type: NAV_MENU_EXPANDED_SET,
    expanded
  };
};

const navMenuExpand = () => {
  return {
    type: NAV_MENU_EXPANDED_SET,
    expanded: true
  };
};

const navMenuCollapse = () => {
  return {
    type: NAV_MENU_EXPANDED_SET,
    expanded: false
  };
};

const pageTitleSet = (title) => {
  return {
    type: PAGE_TITLE_SET,
    title
  };
};

const pageTitleClear = () => {
  return {
    type: PAGE_TITLE_CLEAR
  };
};

const workingAccountSet = (accountId) => {
  return {
    type: WORKING_ACCOUNT_SET,
    accountId,
  };
};

const workingAccountClear = () => {
  return {
    type: WORKING_ACCOUNT_CLEAR,
  };
};

export const actions = {
  userSet,
  userClear,
  applicationInit,
  applicationLoading,
  applicationLoaded,
  applicationLoadStart,
  applicationLoadComplete,
  appMessageClear,
  appMessageSet,
  appMessageSuccess,
  appMessageError,
  navMenuExpandedSet,
  navMenuExpand,
  navMenuCollapse,
  pageTitleClear,
  pageTitleSet,
  workingAccountClear,
  workingAccountSet,
};
