//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import Block from '../Block/Block';
import IconWrapper from '../../Icons/Icons';

const Accordion = (props) => {
  const { children, isOpen, toggleOpen, label, displayBottom, labelPane, paneProps = {}, isVisible = true, toggleVisibility, textProps = {}, ...otherProps } = props;
  const theme = useTheme();

  return (
    <Block
        display="flex"
        flexDirection="column"
        marginBottom={majorScale(2)}
    >
      <Pane
        cursor={isOpen ? 'auto' : 'pointer'}
        padding={majorScale(2)}
        onClick={toggleOpen}
        background={theme.colors.offwhite}
        {...paneProps}
        {...otherProps}
      >
        <Pane
          display='flex'
          alignItems='center'
        >
          {toggleVisibility && (
            <IconWrapper
              size={18}
              name={isVisible ? 'eye' : 'eyeSlash'}
              color={theme.colors.black}
              marginX={majorScale(2)}
              onClick={(e) => {
                e.stopPropagation();
                toggleVisibility();
              }}
            />
          )}
          <Pane
            display='flex'
            flex={1}
            marginLeft={majorScale(1)}
          >
            <Text color={theme.colors.black}  {...textProps}>{label}</Text>
          </Pane>
          {isVisible && (
            <IconWrapper
              size={18}
              name={isOpen ? 'caretUp' : 'caretDown'}
              color={theme.colors.black}
              marginX={majorScale(2)}
            />
          )}
        </Pane>
      </Pane>
      { isOpen && children }
      {displayBottom}
    </Block>
  );
};

Accordion.propTypes = {
  children: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  displayBottom: PropTypes.element,
  isVisible: PropTypes.bool,
  toggleVisibility: PropTypes.func,
  paneProps: PropTypes.object,
  textProps: PropTypes.object
}

export default Accordion;
