import { getCollectionRef } from './common'

export const addArea = (area, accountId) => {
  const areasRef = getCollectionRef('areas', accountId);
  return areasRef.add(area);
};

export const removeArea = (areaId, accountId) => {
  const areasRef = getCollectionRef('areas', accountId);
  return areasRef.doc(areaId).delete();
};

export const updateArea = (areaId, area, accountId) => {
  const areasRef = getCollectionRef('areas', accountId);
  return areasRef.doc(areaId).update(area);
};
