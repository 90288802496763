import { getCollectionRef } from './common';

export const addMiseEnPlace = (miseenplace, accountId) => {
  const miseenplacesRef = getCollectionRef('miseEnPlaces', accountId);
  return miseenplacesRef.add(miseenplace);
};

export const removeMiseEnPlace = (miseenplaceId, accountId) => {
  const miseenplacesRef = getCollectionRef('miseEnPlaces', accountId);
  return miseenplacesRef.doc(miseenplaceId).delete();
};

export const updateMiseEnPlace = (miseEnPlaceId, miseenplace, accountId) => {
  const miseenplacesRef = getCollectionRef('miseEnPlaces', accountId);
  return miseenplacesRef.doc(miseEnPlaceId).update(miseenplace);
};
