import { getCollectionRef } from './common'

export const addMenuCategory = (menuCategory, accountId) => {
  const menuCategoriesRef = getCollectionRef('menuCategories', accountId);
  return menuCategoriesRef.add(menuCategory);
};

export const removeMenuCategory = (menuCategoryId, accountId) => {
  const menuCategoriesRef = getCollectionRef('menuCategories', accountId);
  return menuCategoriesRef.doc(menuCategoryId).delete();
};

export const updateMenuCategory = (menuCategoryId, menuCategory, accountId) => {
  const menuCategoriesRef = getCollectionRef('menuCategories', accountId);
  return menuCategoriesRef.doc(menuCategoryId).update(menuCategory);
};
