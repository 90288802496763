//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

//Libraries
import { majorScale, minorScale, Pane, SelectField, Text } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { actions } from '../../../store/actions';
import { buildFinanacialsErrors, buildInitFinancialData } from '../../../utils/financials';


const FinancialYearTab = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const year = moment.utc().year();
  const [currentYear, setCurrentYear] = useState(year);
  const years = [year - 1, year, year + 1]
  const financials = useSelector(state => (state.financials[accountId] || []).filter(f => years.includes(parseInt(f.year))));
 //console.log(financials)
  const financial = financials.find(f => parseInt(f.year) === parseInt(currentYear));
  //console.log(financial)
  const [financialInfo, setFinancialInfo] = useState({});

  useEffect(() => {
    const financialData = financial || buildInitFinancialData(currentYear);
    setFinancialInfo(financialData);
  }, [financial, currentYear, setFinancialInfo]);

  const saveFinancials = () => {
    if (!validate()) {
      dispatch(actions.appMessageError('Periods are not valid.'));
      return;
    }

    if (financialInfo.id) {
      dispatch(actions.financials.updateFinancial(accountId, financialInfo))
    }
    else {
      dispatch(actions.financials.addFinancial(accountId, financialInfo))
    }
  }

  const onFieldChange = (field, newValue) => {
    setFinancialInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  //const currentYearYear = moment().year(currentYear);

  const yearStart = financialInfo?.start_of_year ?
    moment.utc(financialInfo.start_of_year, 'YYYY-MM-DD').toDate() :
    moment.utc().year(currentYear).startOf('year').toDate();

  const validate = () => {
    const errors = buildFinanacialsErrors(financialInfo, currentYear);

    for (const key in errors) {
      if (!_.isEmpty(errors[key])) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <Pane display='flex' alignItems='center' height={70} paddingY={majorScale(1)} paddingX={majorScale(2)}>
        <Pane flex={1} >
          <SelectField
              maxWidth={130}
              placeholder="Select year"
              value={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
          >
            {years.reverse().map((year) => (<option key={year} value={year}>{year}</option>))}
          </SelectField>
        </Pane>
        <Button
          appearance="primary"
          onClick={saveFinancials}
        >
          Save
        </Button>
      </Pane>
      <Pane>
        <Pane padding={minorScale(4)}>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Annual Information</CustomHeading>
          <DatePickerInput
              required
              label="Start of the year"
              placeholder="Please choose a date"
              value={yearStart}
              onChange={(newDate) => onFieldChange('start_of_year', moment.utc(newDate).format('YYYY-MM-DD'))}
          />
          <CustomHeading level='4' paddingY={majorScale(2)}>Period information</CustomHeading>
          <Pane>
          {[...Array(12).keys()].map(num => {
            const periodKey = `period_${num}`;
            const period = financialInfo[periodKey] || {};
            const changePeriodDate = (field, date) => {
              const newPeriod = {...period, [field]: moment.utc(date).format('YYYY-MM-DD')};
              onFieldChange(periodKey, newPeriod);
            }
            const start = num === 0 ?
              yearStart :
              period.start ?
              moment.utc(period.start, 'YYYY-MM-DD').toDate() :
              moment.utc().month(num).year(currentYear).startOf('month').toDate();
            const end = period.end ?
              moment.utc(period.end, 'YYYY-MM-DD').toDate() :
              moment.utc().month(num).year(currentYear).endOf('month').toDate();
            return (
              <Pane key={periodKey} paddingY={majorScale(2)}>
                <Pane marginBottom={minorScale(2)}>
                  <Text>Period {num + 1} (start - finish)</Text>
                </Pane>
                <Pane display='flex' alignItems='center'>
                  <DatePickerInput
                    placeholder="30/01/2022"
                    // min={currentYearYear.startOf('year').toDate()}
                    // max={currentYearYear.endOf('year').toDate()}
                    value={start}
                    onChange={(newDate) => changePeriodDate('start', newDate)}
                    inputProps={{disabled: num === 0}}
                    marginRight={majorScale(2)}
                  />
                  <DatePickerInput
                    placeholder="30/01/2022"
                    // min={currentYearYear.startOf('year').toDate()}
                    // max={currentYearYear.endOf('year').toDate()}
                    value={end}
                    onChange={(newDate) => changePeriodDate('end', newDate)}
                    marginLeft={majorScale(2)}
                  />
                </Pane>
              </Pane>
            )
          })}
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export default FinancialYearTab;
