//React
import React, { useState } from 'react';

//Libraries
import PropTypes from 'prop-types';
import { majorScale, Textarea, Pane } from 'evergreen-ui';

//Components
import { Button } from '../../components/ui';


const Comment = (props) => {
  const { toggle, onSave } = props;
  const [showAddButton, setShowAddButton] = useState(toggle);
  const [commentText, setCommentText] = useState('');

  const onCommentSave = () => {
    onSave(commentText);
    onCommentCancel();
  };
  const onCommentCancel = () => {
    setCommentText('');
    setShowAddButton(toggle);
  };


  if (showAddButton) {
    return (
      <Button
          appearance="primary"
          onClick={() => setShowAddButton(false)}
      >Add Comment</Button>
    );
  }

  return (
    <React.Fragment>
      <Textarea
          marginBottom={majorScale(2)}
          onChange={(e) => setCommentText(e.target.value)}
      ></Textarea>
      <Pane display="flex" justifyContent="flex-end">
        <Button
            appearance="primary"
            marginRight={majorScale(2)}
            onClick={onCommentSave}
        >Save</Button>
        <Button
            appearance="minimal"
            //intent="danger"
            onClick={onCommentCancel}
        >Cancel</Button>
      </Pane>
    </React.Fragment>
  );
};

Comment.propTypes = {
  toggle: PropTypes.bool,
  onSave: PropTypes.func.isRequired
};

Comment.defaultProps = {
  toggle: true
};

export default Comment;
