//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Select, Text, Paragraph, useTheme } from 'evergreen-ui';
import { nanoid } from 'nanoid';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog'

//Files
import { actions } from '../../../store/actions';
import { TODAY } from '../../../utils/time';
import { dateFormat } from '../../../utils/format';
import { filterActiveIngredients } from '../../../utils/ingredients'; 
import { filterActiveRecipes } from '../../../utils/recipes'; 
import { generateSearchFieldsFn, getIngredientUOMOptions, getRecipeUOMOptions, calcStocktakeIngredientCost, 
  calcStocktakeRecipeCost, normalizeIngredientQuantity, normalizeRecipeQuantity, getLocationAccounts } from '../../../utils/functions';
import { currency, toFixed } from '../../../utils/format';
import { stockRecordingUnits } from '../../../utils/constants';
import { current } from '../../../utils/selectors';

const isRecipe = (item) => !!(item.ingredients || item.yield || item.yieldDescription);
const getFoodUOMOptions = (ing) => isRecipe(ing) ? getRecipeUOMOptions(ing) : getIngredientUOMOptions(ing);
const calcFoodWorth = (ing) => isRecipe(ing) ? calcStocktakeRecipeCost(ing, true) : calcStocktakeIngredientCost(ing, true);
const normalizeFoodQuantity = (food) => isRecipe(food) ? normalizeRecipeQuantity(food) : normalizeIngredientQuantity(food);
const isValidAmount = (amount) => amount && parseFloat(amount) > 0;
const isValidRecords = (records) => records.findIndex(r => (!isValidAmount(r.inputAmount) || !r.recordUOM)) === -1;

const TransferTab = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const [filters, setFilters] = useState({});
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [transferType, setTransferType] = useState('IN');
  const [transferLocation, setTransferLocation] = useState(null);
  const [transferDate, setTransferDate] = useState(TODAY);
  const [newRecords, setNewRecords] = useState([]);
  const [locations, setLocations] = useState([]);
  
  const ingredients = useSelector((state) => {
    // Retrieve all ingredients and recipes
    const allIngredients = current(state, 'ingredients', accountId);
    const allRecipes = current(state, 'recipes', accountId);
    
    // Filter out archived items
    const activeIngredients = filterActiveIngredients(allIngredients);
    const activeRecipes = filterActiveRecipes(allRecipes);
    
    // Combine the active ingredients and recipes
    const combinedItems = _.concat(activeIngredients, activeRecipes);
    
    // Sort the combined items by name
    return _.sortBy(combinedItems, 'name');
  });
  
  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters({});
    }
    else {
      setFilters({ 'search': generateSearchFieldsFn(['name'], newSearchValue)});
    }
  };

  const addFoodRecord = (ing) => {
    setNewRecords((records) => ([ ...records, { ...ing, 'recordUOM': _.first(getFoodUOMOptions(ing)).value, } ]));
  };

  const removeFoodRecord = (ing) => {
    setNewRecords((records) => (_.filter([...records], (i) => i.id !== ing.id)));
  };

  const finalRecords = newRecords.map(i => ({...i, worth: calcFoodWorth({ ...i, amount: i.inputAmount }) }));
  const totalTransfer = _.sumBy(finalRecords, (i) => i.worth || 0);
  const disabled = finalRecords.length === 0;
  const transferLabel = transferType === 'IN' ? 'From' : 'To';

  const ingOnFieldChange = (ing, field, newValue) => {
    setNewRecords((prevIngredients) => {
      const index = prevIngredients.findIndex(i => i.id === ing.id);
      if (index > -1) {
        prevIngredients[index][field] = newValue;
        return [...prevIngredients];
      }
      if (field !== 'recordUOM') {
        // Add the default UOM
        return [...prevIngredients, { 'recordUOM': _.first(getFoodUOMOptions(ing)).value, [field]: newValue }];
      }
      return [...prevIngredients, { [field]: newValue }];
    });
  };

  const ingOnRecordUOMChange = (ing, newValue) => {
    let uomOptions = getFoodUOMOptions(ing);
    ingOnFieldChange(ing, 'recordUOM', newValue);
    if (!_.includes(_.keys(stockRecordingUnits), newValue)) {
      // Record UOM is not a default UOM, so stash the display value as well
      let selected = _.find(uomOptions, { 'value': newValue });
      ingOnFieldChange(ing, 'displayRecordUOM', selected.label);
    }
    else {
      // Clear the displayRecordUOM field in case the user has previously selected
      //  a "unit" or "lot" option
      ingOnFieldChange(ing, 'displayRecordUOM', '');
    }
  };

  // build final array
  const saveFoodTransfer = () => {
    if (transferLocation && isValidRecords(finalRecords)) {
      const records = finalRecords.map((record) => {
        const { inputAmount: amount, recordUOM, displayRecordUOM, worth, ...ing } = record;
        return {
          name: record.name,
          [isRecipe(ing) ? 'recipe' : 'ingredient']: ing,
          recordUOM,
          amount,
          normalQty: normalizeFoodQuantity({ ...ing, amount, recordUOM }),
          cost: parseFloat(toFixed(worth, 4))
        };
      });
      const finalTransfer = {
        records,
        date: dateFormat(transferDate),
        totalCost: parseFloat(toFixed(totalTransfer, 4)),
        transferLocation: transferLocation.id,
        transferType,
        accountId,
        transferId: nanoid()
      }
      dispatch(actions.transfers.addTransfer(accountId, finalTransfer));

      setNewRecords([]);
    }
    else {
      const error = !transferLocation ?
        `You must choose a location to transfer ${transferLabel.toLowerCase()}.` :
        'You must provide an amount for each transfer item.'
      setConfirmAdd(false);
      dispatch(actions.appMessageError(error));
    }
  }

  useEffect(() => {
    const locations = getLocationAccounts(accountId);
    setLocations(locations);
    setTransferLocation(_.first(locations))
  }, [accountId])
  
  const transferLocationName = transferLocation ? transferLocation.name : '';
  const setTransferLocationFromName = (name) => {
    const location = locations.find(l => l.name === name);
    if (location) setTransferLocation(location);
  }

  const ingTableHeaders = [
    { label: 'Name', field: 'name', width: 5 },
    { label: 'Yield', field: 'yield', width: 2 },
    { label: 'Yield Desc.', field: 'yieldDescription', width: 2 },
    { label: 'UOM', field: 'recordUOM', type: 'dropdown', optionsFn: getFoodUOMOptions, onChange: (item, e) => ingOnRecordUOMChange(item, e.target.value) , width: 2},
    { label: 'Qty', field: 'inputAmount', type: 'input', onChange: (item, e) => ingOnFieldChange(item, 'inputAmount', e.target.value), width: 2 },
    { label: 'Value', field: 'worth', format: 'currency', width: 2 },
    { label: 'Remove', field: 'remove', type: 'action', icon: 'trash', appearance: 'danger', width: 1 },
  ];

  const mobileTableHeaders = [
    { label: 'Name', field: 'name', width: 9 },
    { label: 'Remove', field: 'remove', type: 'action', icon: 'trash', appearance: 'danger', width: 3 },
    { label: 'Yield', field: 'yield', width: 2, row: 2 },
    { label: 'Y. Desc.', field: 'yieldDescription', width: 3, row: 2 },
    { label: 'UOM', field: 'recordUOM', type: 'dropdown', optionsFn: getFoodUOMOptions, onChange: (item, e) => ingOnRecordUOMChange(item, e.target.value), width: 3, row: 2 },
    { label: 'Qty', field: 'inputAmount', type: 'input', onChange: (item, e) => ingOnFieldChange(item, 'inputAmount', e.target.value), columnProps: { flex: '0 0 7rem' }, width: 2, row: 2  },
    { label: 'Value', field: 'worth', format: 'currency', width: 2, row: 2  },
    
  ];

  const tableHeaders = [
    { label: 'Name', field: 'name', width: 7 },
    { label: 'Record', field: 'record', type: 'action', icon: 'plus', width: 3 }
  ];


  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <Pane display="flex" alignItems="center" marginBottom={majorScale(2)} marginRight={majorScale(2)}>
            <CustomHeading level="3" Heading marginRight={majorScale(2)}>Transfer</CustomHeading>
            <Select
                value={transferType}
                onChange={(e) => setTransferType(e.target.value)}
                // isInvalid={!isValid('type', record.type)}
            >
              <option value="IN">In</option>
              <option value="OUT">Out</option>
            </Select>
          </Pane>
          <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
            <CustomHeading level="3" marginRight={majorScale(2)}>{transferLabel}</CustomHeading>
            <Select
                value={transferLocationName}
                onChange={(e) => setTransferLocationFromName(e.target.value)}
                // isInvalid={!isValid('type', record.type)}
            >
              {_.map(locations, (value, key) => (
                <option value={value.name} key={key}>{value.name}</option>
              ))}
            </Select>
          </Pane>
        </Pane>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="3" marginRight={majorScale(2)}>Date</CustomHeading>
          <DatePickerInput
            value={transferDate}
            onChange={(newDate) => setTransferDate(newDate)}
          />
          <Pane display="flex" flex={1} alignItems="center" justifyContent='flex-end'>
            <Button
              appearance='primary'
              onClick={() => setConfirmAdd(true)}
              disabled={disabled}
            >
              Transfer
            </Button>
          </Pane>
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)} flex={`1 0 auto`} display="flex" flexDirection="column">
        <FilterBar
          marginBottom={majorScale(2)}
          searchPlaceholder="Search Ingredients and Recipes"
          searchOnChange={searchOnChange}
        />
        <Pane height={"280px"} overflowY="auto">
        <DataTable
          listHeight={220}
          items={ingredients}
          headers={tableHeaders}
          filters={filters}
          onRecord={(item) => addFoodRecord(item)}
        />
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)} flex={`1 0 auto`} display="flex" flexDirection="column">
        <CustomHeading level="4" margin={majorScale(2)}>Transfer List</CustomHeading>
        <DataTable
          items={finalRecords}
          headers={(isMobile) ? mobileTableHeaders : ingTableHeaders}
          onRemove={removeFoodRecord}
          listHeight={300}
        />
        <Pane display="flex" alignItems="center" margin={majorScale(1)} justifyContent='flex-end'>
          <CustomHeading level="4" marginRight={majorScale(2)}>Total</CustomHeading>
          <Text color={theme.colors.tertiary100}>£ {currency(totalTransfer)}</Text>
        </Pane>
      </Block>

      <CustomDialog
        isOpen={confirmAdd}
        title="Save transfer"
        confirmLabel="Save"
        onClose={() => setConfirmAdd(false)}
        onConfirm={() => {
          saveFoodTransfer();
          setConfirmAdd(false);
        }
      }
      >
        <Pane>
          <React.Fragment>
            <Paragraph marginBottom={majorScale(3)}>Are you sure you wish to confirm the transfer {transferLabel} {transferLocationName}?</Paragraph>
          </React.Fragment>
        </Pane>
      </CustomDialog>
    </React.Fragment>
  );
};

export default TransferTab;
