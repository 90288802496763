//React
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, SelectMenu, Table, Badge } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { recipeCompleteAllergens, recipeIngredientAllergens } from '../../../utils/functions';
import { current } from '../../../utils/selectors';

const AllergenView = ({...otherProps}) => {
  const { accountId } = useParams();
  const allergens = useSelector((state) => state.allergens);
  //console.log(allergens)
  const menus = useSelector((state) => current(state, 'menus', accountId));

  const [recipeFilter, setRecipeFilter] = useState('');
  const [selectedMenu, setSelectedMenu] = useState(menus[0]);

  const recipeMap = useSelector((state) => {
    if (!_.isEmpty(recipeFilter)) {
      return _.keyBy(_.filter(current(state, 'recipes', accountId), (recipe) => (_.includes(_.lowerCase(recipe.name), _.lowerCase(recipeFilter)))), 'id');
    }
    return _.keyBy(current(state, 'recipes', accountId), 'id');
  });  
  //console.log(recipeMap)

  useEffect(() => {
    if (!selectedMenu && !_.isEmpty(menus)) {
      setSelectedMenu(menus[0]);
    }
  }, [menus, selectedMenu]);


  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Allergens Charts</CustomHeading>
        <Pane display="flex">
          <SelectMenu
              hasTitle={false}
              hasFilter={false}
              title="Menu"
              options={_.map(menus, (menu) => ({ label: menu.name, value: menu.id }))}
              selected={selectedMenu ? selectedMenu.id : ''}
              onSelect={(item) => {
                setSelectedMenu(_.find(menus, { 'id': item.value }));
              }}
              closeOnSelect={true}
          >
            <Button
                width= "200px" 
                iconBefore="filter"
                appearance="flat"
                {...otherProps}
            >
              {(selectedMenu) ? selectedMenu.name : 'Select a menu'}
            </Button>
          </SelectMenu>
          <FilterBar
              searchPlaceholder="Search menu items"
              searchOnChange={(searchText) => setRecipeFilter(searchText)}
              flex="1 1 0"
              marginLeft={majorScale(2)}
          />
        </Pane>
      </Block>
  
      {!_.isEmpty(selectedMenu) && _.map(selectedMenu.sections, (menuSection, index) => (
        <Block marginBottom={majorScale(2)} key={_.kebabCase(index + ' ' + menuSection.name)}>
          <Table>
            <Table.Head height={100}>
              <Table.TextHeaderCell></Table.TextHeaderCell>
              {_.map(allergens, (allergen) => (
                <Table.HeaderCell
                    key={allergen.id}
                    width={40}
                    maxWidth={48}
                    alignItems="flex-end"
                    justifyContent="flex-center"
                >
                  <Badge
                      transform="rotateZ(-60deg)"
                      transformOrigin="bottom left"
                      isSolid
                  >{allergen.name}</Badge>
                </Table.HeaderCell>
              ))}
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell padding={majorScale(2)}>
                  <CustomHeading level="4">{menuSection.name}</CustomHeading>
                </Table.Cell>
              </Table.Row>
              {_.map(menuSection.recipes, (recipeId) => {
                if (!recipeMap[recipeId]) {
                  return null;
                }
                const recipe = recipeMap[recipeId];
                const allergenList = recipeIngredientAllergens(recipe);

                return (
                  <Table.Row key={`recipe-${recipeId}`}>
                    <Table.TextCell>{recipe.name}</Table.TextCell>
                    {_.map(allergens, (allergen) => (
                      <Table.Cell
                          key={`recipe-${recipeId}-allergen-${allergen.id}`}
                          borderLeft="default"
                          width={40}
                          maxWidth={48}
                          paddingX={majorScale(1)}
                      >
                        {_.includes(allergenList, allergen.name) && (
                          <IconWrapper
                            name='ring'
                            appearance='warning' 
                            marginLeft={majorScale(1)}/>
                        )}
                      </Table.Cell>
                    ))}
                    <Table.TextCell width={10} maxWidth={10}></Table.TextCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Block>
      ))}
    </React.Fragment>
  );
};

export default AllergenView;
