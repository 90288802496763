const STOCKTAKES_LOAD_SUCCESS = 'STOCKTAKES_LOAD_SUCCESS';
const STOCKTAKES_LOAD_FAILURE = 'STOCKTAKES_LOAD_FAILURE';
const STOCKTAKES_CLEAR = 'STOCKTAKES_CLEAR';
const ADD_STOCKTAKE = 'ADD_STOCKTAKE';
const REMOVE_STOCKTAKE = 'REMOVE_STOCKTAKE';
const UPDATE_STOCKTAKE = 'UPDATE_STOCKTAKE';

export const actionTypes = {
  STOCKTAKES_LOAD_SUCCESS,
  STOCKTAKES_LOAD_FAILURE,
  STOCKTAKES_CLEAR,
  ADD_STOCKTAKE,
  REMOVE_STOCKTAKE,
  UPDATE_STOCKTAKE
};


const stockTakeLoadSuccess = (payload, account) => {
  return {
    type: STOCKTAKES_LOAD_SUCCESS,
    payload,
    account
  };
};

const stockTakeLoadFailure = (error) => {
  return {
    type: STOCKTAKES_LOAD_FAILURE,
    error
  };
};

const stockTakeClear = () => {
  return {
    type: STOCKTAKES_CLEAR
  };
};

const addStockTake = (accountId, stockTake, callback) => {
  return {
    type: ADD_STOCKTAKE,
    stockTake,
    accountId,
    callback,
  };
};

const removeStockTake = (accountId, stockTakeId) => {
  return {
    type: REMOVE_STOCKTAKE,
    stockTakeId,
    accountId,
  };
};

const updateStockTake = (accountId, stockTake, callback) => {
  return {
    type: UPDATE_STOCKTAKE,
    stockTake,
    accountId,
    callback,
  };
};

export const actions = {
  stockTakeLoadSuccess,
  stockTakeLoadFailure,
  stockTakeClear,
  addStockTake,
  removeStockTake,
  updateStockTake
};
