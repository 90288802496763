// LoadingIndicator.js
import React from 'react';
import { Spinner, Pane, Text, majorScale } from 'evergreen-ui';

const LoadingIndicator = ({ message }) => {
    const textStyle = {
        whiteSpace: 'pre-line',
      };

    return (
    <Pane display="flex" alignItems="center" justifyContent="center" flexDirection="column" height={400}>
        <Spinner />
        <Text style={textStyle} marginTop={16}>
        {message}
        </Text>
    </Pane>
    );
};

export default LoadingIndicator;
