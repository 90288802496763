//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import { majorScale, Pane, Text, TextInputField } from 'evergreen-ui';
import _ from 'lodash';

//Components
import DataTable from '../../components/DataTable/DataTable';
import { Block, Button } from '../../components/ui';
import CustomDialog from '../../components/Dialog/Dialog';
import IconWrapper from '../../components/Icons/Icons';
import CustomHeading from '../../components/Headings/Headings';

//Files
import { current } from '../../utils/selectors';


const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 5 },
  { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', buttonProps: { appearance: 'clickable' }, width: 1 },
  { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 }
];


const EntityList = ({ list, type, actions, accountId, label, buttonLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const categoryList = useSelector((state) => _.sortBy(current(state, type, accountId), 'name'));
  list = list || categoryList;

  const [removingCategory, setRemovingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);

  const saveCategory = (cat) => {
    if (cat.id) {
      dispatch(actions.update(accountId, cat));
    }
    else {
      dispatch(actions.add(accountId, cat));
    }
  };

  return (
    <React.Fragment>
      <CustomDialog
          isOpen={!!editingCategory}
          title={(editingCategory && editingCategory.name) || `New ${label.toLowerCase()}`}
          confirmLabel={(editingCategory && editingCategory.id) ? 'Update' : 'Add'}
          onConfirm={() => {
            saveCategory(editingCategory)
            setEditingCategory(false);
          }}
          onClose={() => setEditingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="Name"
              placeholder={`${label} name`}
              value={editingCategory.name || ''}
              onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!removingCategory}
          title={`Remove ${label.toLowerCase()}`}
          intent="danger"
          confirmLabel="Remove"
          onConfirm={(close) => {
            dispatch(actions.remove(accountId, removingCategory.id));
            setRemovingCategory(false);
          }}
          onClose={() => setRemovingCategory(false)}
      > 
        <Pane marginBottom={majorScale(2)}>
          <Text>Are you sure you wish to remove the {label.toLowerCase()} {removingCategory.name}?</Text>
        </Pane>
      </CustomDialog>

      <Block>
        <Pane display="flex" alignItems="center" justifyContent="space-between" padding={majorScale(2)}>
          <Pane display="flex" alignItems="center" justifyContent="flex-start">
              <IconWrapper
                  appearance="clickable"
                  name="arrowLeft"
                  onClick={() => navigate(-1)}  
                  marginRight={majorScale(2)}
              />
              <CustomHeading level="3">Manage {label.toLowerCase()}</CustomHeading>
          </Pane>
          <Button 
            onClick={() => setEditingCategory({})}
            appearance="primary"
          >
            New {buttonLabel.toLowerCase() || label.toLowerCase()}
          </Button>
        </Pane>
        <Pane>
          <DataTable
              items={list}
              headers={tableHeaders}
              onDelete={(cat) => setRemovingCategory(cat)}
              onEdit={(cat) => setEditingCategory(cat)}
              flex="1 0 auto"
          />
        </Pane>
      </Block>
    </React.Fragment>
  )
}

export default EntityList;
