//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormField, majorScale, SelectMenu, TextInput } from 'evergreen-ui';

//Components
import CustomDialog from '../../../components/Dialog/Dialog';


const GroupEditDialog = ({ addingTemp, setAddingTemp, childDeptTemplate, setChildDeptTemplate, updateChildDepartment,
  setErrors, userDepartmentTemplates, getTemplateName, setEditingDept, errors, type }) => {
  if (!addingTemp) return null;

  return (
    <CustomDialog
      isOpen={addingTemp && !_.isEmpty(userDepartmentTemplates)}
      title="Add Template"
      confirmLabel="Add"
      onConfirm={() => {
        if (childDeptTemplate) {
          updateChildDepartment();
          setAddingTemp(false);
          setChildDeptTemplate(null);
          setEditingDept(null);
        }
        else {
          setErrors((prevValues) => ({ ...prevValues, newDept: 'You must select one department to add'}));
        }
      }}
      onClose={() => {
        setAddingTemp(false);
        setChildDeptTemplate(null);
        setEditingDept(null)
      }}
    >
      <FormField
        label="Department Template"
        isRequired
        validationMessage={errors['newDept']}
        marginBottom={majorScale(4)}
      >
        <SelectMenu
          hasTitle={false}
          options={_.map(userDepartmentTemplates, (account) => ({ label: account.name, value: account.id }))}
          selected={childDeptTemplate}
          onSelect={(item) => setChildDeptTemplate(item.value)}
          onDeselect={(item) => setChildDeptTemplate(null)}
        >
          <TextInput
            readOnly
            width="100%"
            value={(!childDeptTemplate) ? 'Select one ...' : getTemplateName(childDeptTemplate)}
          />
        </SelectMenu>
      </FormField>
    </CustomDialog>
  );
}

GroupEditDialog.propTypes = {
  addingTemp: PropTypes.bool,
  setAddingTemp: PropTypes.func.isRequired,
  childDeptTemplate: PropTypes.string,
  setChildDeptTemplate: PropTypes.func.isRequired,
  updateChildDepartment: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  userDepartmentTemplates: PropTypes.array.isRequired,
  getTemplateName: PropTypes.func.isRequired,
  setEditingDept: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

export default GroupEditDialog;
