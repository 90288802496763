import fb from './'
import { getCollectionRef } from './common';

export const addTransfer = (transfer, accountId) => {
  const transfersRef = getCollectionRef('transfers', accountId);
  return transfersRef.add({ ...transfer, created: fb.serverTime() });
};

export const removeTransfer = (transferId, accountId) => {
  const transfersRef = getCollectionRef('transfers', accountId);
  return transfersRef.doc(transferId).delete();
};

export const updateTransfer = (transferId, transfer, accountId) => {
  const transfersRef = getCollectionRef('transfers', accountId);
  return transfersRef.doc(transferId).update(transfer);
};
