import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addReportsComment, removeReportsComment, updateReportsComment } from '../firebase/reportsComments';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* reportsCommentsSaga() {
  yield takeEvery(actionTypes.reportsComments.ADD_REPORT_COMMENT, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addReportsComment, action.comment, accountId);
      yield put(actions.appMessageSuccess('Report Comment Added'));
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Report Comment could not be added'));
    }
  });
  yield takeEvery(actionTypes.reportsComments.REMOVE_REPORT_COMMENT, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeReportsComment, action.commentId, accountId);
      yield put(actions.appMessageSuccess('Report Comment Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Report Comment could not be removed'));
    }
  });
  yield takeEvery(actionTypes.reportsComments.UPDATE_REPORT_COMMENT, function* (action) {
    const { id, ...comment } = action.comment;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateReportsComment, id, comment, accountId);
      yield put(actions.appMessageSuccess('Report Comment Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Report Comment could not be updated'));
    }
  });
};
