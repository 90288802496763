const UPLOADS_LOAD_SUCCESS = 'UPLOADS_LOAD_SUCCESS';
const UPLOADS_LOAD_FAILURE = 'UPLOADS_LOAD_FAILURE';
const UPLOADS_CLEAR = 'UPLOADS_CLEAR';

export const actionTypes = {
  UPLOADS_LOAD_SUCCESS,
  UPLOADS_LOAD_FAILURE,
  UPLOADS_CLEAR
};


const uploadsLoadSuccess = (payload, account) => {
  return {
    type: UPLOADS_LOAD_SUCCESS,
    payload,
    account
  };
};

const uploadsLoadFailure = (error) => {
  return {
    type: UPLOADS_LOAD_FAILURE,
    error
  };
};

const uploadsClear = () => {
  return {
    type: UPLOADS_CLEAR
  };
};

export const actions = {
  uploadsLoadSuccess,
  uploadsLoadFailure,
  uploadsClear
};
