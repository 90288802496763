import React  from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { actions } from '../store/actions';
import { TODAY } from '../utils/time';
import { current } from '../utils/selectors';

const useTasks = ({accountId, date}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const openTasks = useSelector((state) => {
    // Filter tasks for 'OPEN' type
    return _.filter(current(state, 'tasks', accountId), { 'type': 'OPEN' });
  });
  const closeTasks = useSelector((state) => {
    // Filter tasks for 'CLOSE' type
    return _.filter(current(state, 'tasks', accountId), { 'type': 'CLOSE' });
  });
  const fridgeTasks = useSelector((state) => {
    // Filter tasks for 'FRIDGE' type
    return _.filter(current(state, 'tasks', accountId), { 'type': 'FRIDGE' });
  });
  const probeTasks = useSelector((state) => {
    // Filter tasks for 'PROBE' type
    return _.sortBy(_.filter(current(state, 'tasks', accountId), { 'type': 'PROBE' }),'date');
  });
  const tasksHistory = useSelector((state) => {
    // Filter task history for current tasks, current date
    return _.keyBy(
      _.filter(current(state, 'tasksHistory', accountId), (taskItem) => {
        if ( taskItem.date && moment(taskItem.date.toDate()).isSame(date, 'day') ) {
          return (
            _.find(openTasks, { id: taskItem.taskId }) ||
            _.find(closeTasks, { id: taskItem.taskId }) ||
            _.find(fridgeTasks, { id: taskItem.taskId }) ||
            _.find(probeTasks, { id: taskItem.taskId })
          );
        }
        return false;
      }),
      'taskId'
    );
  });
  const userInfo = useSelector((state) => ({ name: state.profile?.name, uid: state.profile?.uid }));
  const editable = Math.abs(moment(date).diff(moment(TODAY), 'days')) <= 1;
  const completedOnDay = (task) => (!!tasksHistory[task.id]);

  const toggleComplete = (task) => {
    if (!tasksHistory[task.id]) {
      // No existing task history item, add it now, and track the user marking as complete
      dispatch(actions.tasksHistory.addTaskHistoryItem(accountId, {
        taskId: task.id,
        action: task.action,
        type: task.type,
        uid: userInfo.uid,
        userName: userInfo.name,
        date
      }));
    }
    else {
      // Existing task history item; remove it
      dispatch(actions.tasksHistory.removeTaskHistoryItem(accountId, tasksHistory[task.id].id));
    }
  };

  return {
    tasksHistory,
    toggleComplete,
    completedOnDay,
    openTasks,
    editable,
    isMobile,
    fridgeTasks,
    closeTasks,
    probeTasks
  }
}

export default useTasks;
