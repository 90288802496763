//React
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Libraries
import { majorScale, minorScale, Pane, useTheme } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

// Components
import IconWrapper from '../../../components/Icons/Icons';
import Header from '../components/Header';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block';
import { CustomBarChart } from '../components/Recharts';

// Files
import { actions } from '../../../store/actions';
import { orderStatuses } from '../../../utils/constants';
import { shortDateDisplayFormat, shortDayMonthDisplayFormat } from '../../../utils/format';
import { calcOrderTotal, requestPdfLink } from '../../../utils/functions';
import CustomHeading from 'components/Headings/Headings';


const buildOrders = (orders, area, location) => {
  return orders.map(order => {
    return {
      ...order,
      area,
      location,
      totalCost: calcOrderTotal(order) || order.estimatedCost
    };
  });
};

const OrdersTab = ({ accounts, accountsData }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const orders = _.reverse(
    _.sortBy(
      accounts.locations.reduce((orders, location) => {
        const areas = accounts.areas.filter(a => a.location.id === location.id);
        for (const area of areas) {
          const data = accountsData[area.id];
          if (data.weekOrders) orders = orders.concat(buildOrders(data.weekOrders, area, location));
        }
        return orders;
      }, []),
      (order) => moment.utc((order.deliveryDate) ? order.deliveryDate : order.date, 'YYYY-MM-DD').format('MMMM YYYY')
    )
  );
  console.log(orders, "ORDERTABS")

  const orderAttachments = useSelector((state) => {
    let attachments = [];
    for (const accountId in state.orderAttachments) {
      attachments = attachments.concat(state.orderAttachments[accountId]);
    }
    return _.groupBy(
      attachments,
      'orderId'
    );
  });

  const orderStatus = (order) => {
    return order.status === orderStatuses.FINALISED ?
      'Finalised' :
      order.status === orderStatuses.RECEIVED ?
        'Approved' :
        'Sent';
  };

  const toggleFinal = (order) => {
    if (![orderStatuses.FINALISED, orderStatuses.RECEIVED].includes(order.status)) {
      dispatch(actions.appMessageError('Order must be approved first.'));
      return;
    }
    dispatch(actions.orders.toggleOrderFinal(order.area.id, order));
  };

  const getDayIndex = (dateStr) => {
    const date = moment(dateStr, 'DD/MM').day();
    return date === 0 ? 7 : date; // Treat Sunday (0) as 7 for sorting purposes
  };

  const aggregateOrdersByDay = (orders) => {
    const counts = {};
    orders.forEach(order => {
      const date = shortDayMonthDisplayFormat(order.deliveryDate || order.date);
      if (counts[date]) {
        counts[date].count += 1;
      } else {
        counts[date] = { name: date, count: 1 };
      }
    });

    const sortedCounts = Object.values(counts).sort((a, b) => getDayIndex(a.name) - getDayIndex(b.name));
    return sortedCounts;
  };

  const chartData = useMemo(() => aggregateOrdersByDay(orders), [orders]);

  const tableHeaders = [
    { label: 'Date', field: 'date', type: 'text', width: 1, format: shortDayMonthDisplayFormat },
    { label: 'Location', field: 'locationName', type: 'text', width: 2 },
    { label: 'Area', field: 'areaName', type: 'text', width: 2 },
    { label: 'Type', field: 'type', type: 'text', width: 1 },
    { label: 'Net value', field: 'totalCost', type: 'numeric', prefix: '£', width: 2 },
    { label: 'Inv. Nb.', field: 'invoice_number', type: 'text', width: 2 },
    { label: 'Supplier', field: 'supplier', type: 'text', width: 2 },
    { label: 'Status', field: 'status', type: 'text', width: 1, format: orderStatus },
    { label: 'Fin', field: 'fin', type: 'text', width: 1 },
    { label: 'Att.', field: 'attachments', type: 'text', width: 1 },
    { label: 'View', field: 'view', type: 'text', width: 1 },
  ];

  const formattedOrders = orders.map(order => {
    const areaData = order.area || {};
    const locationData = order.location || {};
  
    return {
      ...order,
      date: shortDateDisplayFormat(order.deliveryDate || order.date),
      locationName: locationData.name || 'Unknown Location',
      areaName: areaData.area.name || 'Unknown Area',
      supplier: accounts.supplierMap[order.supplierId] ? accounts.supplierMap[order.supplierId].name : 'Unknown Supplier',
      fin: (
        <IconWrapper
          name="tick"
          backgroundColor={order.status === orderStatuses.FINALISED ? `${theme.colors.tertiary100} !important` : null}
          backgroundImage={order.status === orderStatuses.FINALISED ? `none !important` : null}
          appearance="clickable"
          intent="none"
          onClick={() => toggleFinal(order)}
        />
      ),
      attachments: !_.isEmpty(orderAttachments[order.id]) && (
        <IconWrapper
          name="paperclip"
          appearance="clickable"
          onClick={() => requestPdfLink(order.area?.id, order.id)}
        />
      ),
      view: (
        <IconWrapper
          name="clipboard"
          appearance="clickable"
          is={Link}
          to={`/${order.area?.id}/supplier-statements/${order.id}`}
        />
      ),
    };
  });
  

  return (
      <>
        <Block marginBottom={majorScale(2)}>
          <Pane width="80%%" height="400px" >
            <Pane padding={majorScale(2)}>
                <CustomHeading level="3" >Number of order & order net value per day</CustomHeading>
            </Pane>
            <CustomBarChart 
                data={chartData} 
                barKey="count" 
                xKey="name"
                yKey="count"
                xType="category"
                yType="number"
                height='90%'
                width='100%'
                layout="horizontal"
                valueType="count"
            />
          </Pane>
        </Block>

        <Block marginBottom={majorScale(2)}>
          <Header title='Purchasing orders' />
          <DataTable
            headers={tableHeaders}
            items={formattedOrders}
            listSize={formattedOrders.length}
          />
        </Block>
      </>
  );
};

export default OrdersTab;
