const ACCOUNT_USERS_LOAD_SUCCESS = 'ACCOUNT_USERS_LOAD_SUCCESS';
const ACCOUNT_USERS_LOAD_FAILURE = 'ACCOUNT_USERS_LOAD_FAILURE';
const ACCOUNT_USERS_CLEAR = 'ACCOUNT_USERS_CLEAR';
const ADD_USER = 'ADD_USER';
const REMOVE_USER = 'REMOVE_USER';
const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';

export const actionTypes = {
  ACCOUNT_USERS_LOAD_SUCCESS,
  ACCOUNT_USERS_LOAD_FAILURE,
  ACCOUNT_USERS_CLEAR,
  ADD_USER,
  REMOVE_USER,
  REMOVE_ACCOUNT
};

const accountUsersLoadSuccess = (payload, account) => {
  return {
    type: ACCOUNT_USERS_LOAD_SUCCESS,
    payload,
    account
  };
};

const accountUsersLoadFailure = (error) => {
  return {
    type: ACCOUNT_USERS_LOAD_FAILURE,
    error
  };
};

const accountUsersClear = () => {
  return {
    type: ACCOUNT_USERS_CLEAR
  };
};

const addUser = (userInfo) => {
  return {
    type: ADD_USER,
    userInfo
  };
};

const removeUser = (userId) => {
  return {
    type: REMOVE_USER,
    userId
  };
};

const removeAccount = (accountId) => {
  return {
    type: REMOVE_ACCOUNT,
    accountId,
  };
};

export const actions = {
  accountUsersClear,
  accountUsersLoadSuccess,
  accountUsersLoadFailure,
  addUser,
  removeUser,
  removeAccount,
};
