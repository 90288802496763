import { useLocation } from 'react-router-dom';
//import { navigate } from 'hookrouter';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { accountTypes } from '../utils/constants';


const useSuppliersAuth = (page = 'supplier') => {
  const account = useSelector((state) => state.accounts[state.currentAccount]);
  const navigate = useLocation();
  const suppliersPage = useCallback(() => {
    return account?.type === accountTypes.SUPPLIER ? 'supplier' : 'suppliers';
  }, [account])
  useEffect(() => {
    if (suppliersPage() !== page) {
      navigate(page === 'supplier' ? '/suppliers' : '/')
    }
  },[page, suppliersPage])
  return {
    suppliersPage
  };
};

export default useSuppliersAuth;
