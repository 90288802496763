import fb from './';
import { getCollectionRef } from './common';

export const addOrderRecord = (record, accountId) => {
  const orderRecordsRef = getCollectionRef('orderRecords', accountId);
  return orderRecordsRef.add({ ...record, created: fb.serverTime() });
};

export const removeOrderRecord = (recordId, accountId) => {
  const orderRecordsRef = getCollectionRef('orderRecords', accountId);
  return orderRecordsRef.doc(recordId).delete();
};

export const updateOrderRecord = (recordId, record, accountId) => {
  const orderRecordsRef = getCollectionRef('orderRecords', accountId);
  return orderRecordsRef.doc(recordId).update({ ...record, lastModified: fb.serverTime() });
};
