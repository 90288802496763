//React
import React from 'react';

//Libraries
import { majorScale, minorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Header from '../components/Header';
import ViewMore from '../components/ViewMore';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block'

//Files
import { buildAggregatedSalesData } from '../../../utils/reports';


const LocationSales = ({ accounts, accountsData, limit = true }) => {
  const locationSales = accounts.locations.reduce((sales, location) => {
    const areas = accounts.areas.filter(a => a.location.id === location.id);
    const data = areas.reduce((data, area) => {
      data[area.id] = accountsData[area.id];
      return data;
    }, {})
    const locationSales = buildAggregatedSalesData(data);
    const processed = {
      name: location.name,
      gross: locationSales.gross,
      net: locationSales.net,
      id: location.id
    }
    return sales.concat(processed);
  }, []);

  let final = _.sortBy(locationSales, (s) => 1/s.net);
  //if (limit) final = final.slice(0, 4);

  const tableHeaders = [
    { label: 'Location', field: 'name', type: 'text', width: 5 },
    { label: 'Gross', field: 'gross', type: 'numeric', prefix: '£', width: 3 },
    { label: 'Net', field: 'net', type: 'numeric', prefix: '£', width: 3 }
  ]

  return (
    <Block width='100%' marginBottom={majorScale(2)}>
        <Header title='Sales by location' />
        <Pane maxHeight="480px" overflow="auto">
          <DataTable
            headers={tableHeaders}
            items={final}
            listHeight={320}
          />
          {limit && (
            <ViewMore to='locations' />
          )}
      </Pane>
    </Block>
  )
}

export default LocationSales;

