//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { current } from '../../../utils/selectors';
import { buildGroupRecipes } from '../../../utils/departments';

const tableHeaders = [
  { label: 'Img', field: 'thumb_imageURL', type: 'thumbnail', width: 2 },
  { label: 'Name', field: 'name', type: 'text', width: 5 },
  { label: 'Category', field: 'category', type: 'text', width: 3 }
];

const mobileTableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 8 },
  { label: 'Category', field: 'category', type: 'text', width: 4 }
];

const buildRecipeCategory = (recipe, categories) => {
  const category = recipe.categoryId ?
    categories.find(c =>  c.id ===  recipe.categoryId)?.name :
    recipe.category
  return {
    ...recipe,
    category
  }
}

//TODO - change the isLoading to the actual isLoading from collection and add a no recipes
//At the moment keep loading if no recipe
const Recipes = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const categoryList = useSelector((state) => current(state, 'recipeCategories', accountId));
  const recipeList = _.sortBy(buildGroupRecipes(accountId), 'name').map(r => buildRecipeCategory(r, categoryList));
  const [filters, setFilters] = useState({});

  const categoryOptions = () => {
    if (categoryList) {
      return _.sortBy(categoryList, 'name').map((cat) => ({ label: cat.name, value: cat.name }));
    }
    return [];
  };

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  const filterFields = [
    { label: 'Recipe Category', name: 'category', options: categoryOptions() }
  ];

  const handleRowSelect = (item) => {
    const path = `/${accountId}/front-of-house/recipes/${item.id}`;
    navigate(path);
  };


  return (
    <React.Fragment>
      <Pane marginBottom={majorScale(2)}>
      <Block marginBottom={majorScale(1)} padding={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Recipes</CustomHeading>
        <FilterBar
            flex="1 0 auto"
            filterFields={filterFields}
            filters={filters}
            setFilter={updateFilters}
            searchPlaceholder="Search Recipes"
            searchOnChange={searchOnChange}
        />
      </Block>

      <Block flex={`1 0 auto`} display="flex" height="420px" flexDirection="column">
      <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
        <DataTable
            borderRadius={5}
            items={recipeList}
            filters={filters}
            headers={(isMobile) ? mobileTableHeaders : tableHeaders}
            onRowSelect={handleRowSelect}
            flex="1 0 auto"
            listHeight={400}
        />  
        </Pane>
      </Block>
      </Pane>
    </React.Fragment>
  );
};

export default Recipes;
