//Reactt
import React from 'react';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectMenu, TextInput, Pane } from 'evergreen-ui';

//Components
import { Button } from '../../components/ui';


const MultiselectFilter = (props) => {
  const { name, label, options, selected = [], setFilter, isInvalid, required, ...otherProps } = props;
  //console.log(selected, "SELECTEDDD")
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const selectedItemsLength = selected.length;

  let selectedNames; 
  if (selectedItemsLength === 0) {
    selectedNames = ''
  } else if (selectedItemsLength === 1) {
    selectedNames = selected.toString()
  } else if (selectedItemsLength > 1) {
    selectedNames = selectedItemsLength.toString() + ' selected...'
  }

  return (
    <Pane style={{ border: isInvalid ? '1px solid red' : 'none', borderRadius: 4 }}>
    <SelectMenu
        isMultiSelect
        hasTitle={false}
        hasFilter={false}
        title={label}
        options={options}
        selected={selected}
        onSelect={(item) => {
          setFilter(name, item.value);
        }}
        onDeselect={(item) => {
          setFilter(name, item.value);
        }}
        closeOnSelect={true}
    >
      <Button
          width={(isMobile) ? 'auto' : 200}
          iconBefore="filter"
          appearance="flat"
          border="none"
          {...otherProps}
      >{selectedNames || label}</Button>
    </SelectMenu>
    </Pane>
  );
};

MultiselectFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
};

export default MultiselectFilter;
