//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Text, TextInputField, UnorderedList, ListItem, Paragraph, Strong, useTheme } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { generateSearchFieldsFn, getModifierRecipes } from '../../../utils/functions';
import { current, selectNonDeletedModifiers} from '../../../utils/selectors';


const Modifiers = ({ accountId }) => {
  const dispatch = useDispatch();
  const { modifierId } = useParams()
  const theme = useTheme();

  const categoryList = useSelector((state) => _.sortBy(current(state, 'modifierCategories', accountId), 'name'));
  const modifierList = useSelector((state) => _.sortBy(selectNonDeletedModifiers(state, accountId), 'name'));
  const [filters, setFilters] = useState({});
  const [modifierToRemove, setModifierToRemove] = useState(false);
  const [removingCategory, setRemovingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);
  const [manageCategories, setManageCategories] = useState(false);

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Category', field: 'category', type: 'text', width: 3 },
    { label: 'Nb modifier', type: 'calc', calc: (item) => item.ingredients ? item.ingredients.length : 0, width: 2  },
    { label: 'Nb recipes', type: 'calc', calc: (item) => getModifierRecipes(item, accountId).length, width: 2 },
    { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', link: `/products/${accountId}/setup/modifiers/`, buttonProps: { appearance: 'clickable' }, width: 1 },
    { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 }
  ];

  const categoryOptions = () => {
    if (categoryList) {
      return _.map(categoryList, (cat) => ({ label: cat.name, value: cat.name }));
    }
    return [];
  };

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  const confirmRemoveModifier = (modifier) => {
    setModifierToRemove(modifier);
  };

  const removeModifier = (modifierId) => {
    dispatch(actions.modifiers.removeModifier(accountId, modifierId));
  };

  const saveCategory = (cat) => {
    if (cat.id) {
      dispatch(actions.modifierCategories.updateCategory(accountId, cat));
    } else {
      dispatch(actions.modifierCategories.addCategory(accountId, cat));
    }
  };
  
  const filterFields = [
    { label: 'Category', name: 'category', options: categoryOptions() }
  ];


  return (
    <React.Fragment>
      <CustomDialog
          isOpen={!!modifierToRemove}
          title="Delete group modifier"
          intent="danger"
          confirmLabel="Delete group modifier"
          onConfirm={() => {
            removeModifier(modifierToRemove.id);
            setModifierToRemove(false);
          }}
          onClose={() => setModifierToRemove(false)}
      >
        <Paragraph marginBottom={majorScale(2)}><Strong color={theme.colors.warning}>Important: </Strong>Are you sure you wish to remove '{modifierToRemove.name}'?</Paragraph>
        <Paragraph marginBottom={majorScale(2)}>This action cannot be undone and will impact all associated recipes. Deleting this group modifier will affect the cost calculations of these recipes and may influence related reports.</Paragraph>
        <Paragraph marginBottom={majorScale(3)}>Please confirm if you wish to proceed with this deletion.</Paragraph>
      </CustomDialog>

      <CustomDialog
          isOpen={manageCategories}
          title="Modifier Categories"
          hasCancel={false}
          confirmLabel="Close"
          onConfirm={() => {
            setManageCategories(false);
          }}
          onClose={() => setManageCategories(false)}
      >
        <Pane textAlign="right" marginBottom={majorScale(2)}>
          <Button 
            appearance="primary"
            onClick={() => setEditingCategory({})}
          >New Category</Button>
        </Pane>
        <UnorderedList listStyle="none" marginY={majorScale(4)} padding={0}>
          {categoryList && categoryList.map((cat) => (
            <ListItem key={cat.id} display="flex" alignItems="center">
              <Text flex="1 1 auto" marginRight={majorScale(2)}>{cat.name}</Text>
              <IconWrapper
                  name="edit"
                  appearance="clickable"
                  onClick={() => setEditingCategory(cat)} marginRight={majorScale(2)}
              />
              <IconWrapper
                  name="trash"
                  appearance="danger"
                  //intent="danger"
                  onClick={() => setRemovingCategory(cat)}
              />
            </ListItem>
          ))}
        </UnorderedList>
      </CustomDialog>

      <CustomDialog
          isOpen={!!editingCategory}
          title={(editingCategory && editingCategory.name) || 'New Modifier Category'}
          confirmLabel={(editingCategory && editingCategory.id) ? 'Update' : 'Add'}
          onConfirm={() => {
            saveCategory(editingCategory);
            setEditingCategory(false);
          }}
          onClose={() => setEditingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="Name"
              placeholder="Category Name"
              value={editingCategory.name || ''}
              onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!removingCategory}
          title="Remove Category"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={() => {
            dispatch(actions.modifierCategories.removeCategory(accountId, removingCategory.id));
            setRemovingCategory(false);
          }}
          onClose={() => setRemovingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <Text>Are you sure you wish to remove the category {removingCategory.name}?</Text>
        </Pane>
      </CustomDialog>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="3" flex="1 0 auto">Group modifiers</CustomHeading>
          <Button
              appearance="primary"
              is={Link}
              to={`/products/${accountId}/setup/modifiers/add`}
              marginRight={majorScale(2)}
          >New group modifier</Button>
          <Button
            appearance="primary"
            onClick={() => setManageCategories(true)}
          >Group modifier categories</Button>
        </Pane>
        <FilterBar
            flex="1 0 auto"
            filterFields={filterFields}
            filters={filters}
            setFilter={updateFilters}
            searchPlaceholder="Search Modifiers"
            searchOnChange={searchOnChange}
        />
      </Block>

      <Block flex={`1 0 auto`} display="flex" height="500px" flexDirection="column" marginBottom={majorScale(2)}>
        <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
          <DataTable
              items={modifierList}
              filters={filters}
              headers={tableHeaders}
              onDelete={(modifier) => confirmRemoveModifier(modifier)}
              flex="1 0 auto"
              listHeight={480}
          />
        </Pane>
      </Block>
    </React.Fragment>
  );
};

Modifiers.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default Modifiers;
