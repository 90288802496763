//React
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

//Libraries
import { Dialog, majorScale, minorScale, Pane, Switch, Text } from 'evergreen-ui';
import { nanoid } from 'nanoid';

//Components
import Button from '../../../components/ui/Button/Button';
import Block from '../../../components/ui/Block/Block';

//Files
import { actions } from '../../../store/actions';
import { FIREBASE_URL } from '../../../utils/functions';


const UrlDisplay = ({webhookUrl, deliverectId}) => {
  return (
    <Pane>
      <Pane marginBottom={minorScale(2)}>
        <Text>Mise En Place Location ID:</Text>
      </Pane>
      <Pane marginBottom={minorScale(2)}>
        <Text>{deliverectId}</Text>
      </Pane>

      <Pane marginBottom={minorScale(2)}>
        <Text>Registr POS url:</Text>
      </Pane>
      <Pane marginBottom={minorScale(2)}>
        <Text>{webhookUrl}</Text>
      </Pane>

      <Pane marginBottom={minorScale(2)}>
        <Text>Reporting endpoint url:</Text>
      </Pane>
      <Pane marginBottom={minorScale(2)}>
        <Text>{`${FIREBASE_URL}/receiveOrders`}</Text>
      </Pane>
    </Pane>
  )
}

const DeliverectOwner = ({account, accountInfo, onFieldChange}) => {
  const dispatch = useDispatch();
  const [openInfo, setOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const deliverectId = accountInfo.deliverectId || nanoid();
  const doDeactivate = () => {
    dispatch(actions.accounts.accountUpdate({
      ...accountInfo,
      has_deliverect: false,
      deliverectId: null,
      deliverect_customer_id: null
    }));
  };
  const doActivate = () => {
    dispatch(actions.accounts.accountUpdate({
      ...accountInfo,
      has_deliverect: true,
      deliverectId
    }));
  };
  const toggleDeliverect = () => {
    if (!accountInfo.has_deliverect) {
      doActivate();
    }
    else doDeactivate();
  }
  const webhookUrl = `${FIREBASE_URL}/registerDeliverectPos`;
  return (
    <Block marginY={majorScale(2)} padding={majorScale(2)}>
      <Pane display='flex'>
        <Text fontWeight='bold' marginBottom={majorScale(2)}>Deliverect products sync</Text>
        <Switch
          onChange={() => setOpenToggle(true)}
          checked={!!accountInfo.has_deliverect}
          marginX={majorScale(2)}
        />
      </Pane>
      <Button margin={minorScale(2)} appearance="primary" type='button' onClick={() => setOpenToggle(true)}>{accountInfo.has_deliverect ? 'De-a' : 'A'}ctivate</Button>
      {
        account.has_deliverect && (
          <Button margin={minorScale(2)} appearance="primary" type='button' onClick={() => setOpen(true)}>Info</Button>
        )
      }
      <Dialog
          isShown={openInfo}
          title="Deliverect Integration"
          onCloseComplete={() => setOpen(false)}
      >
        <UrlDisplay webhookUrl={webhookUrl} deliverectId={deliverectId} />
      </Dialog>
      <Dialog
          isShown={openToggle}
          title="Deliverect Integration"
          confirmLabel="confirm"
          onCloseComplete={() => setOpenToggle(false)}
          onConfirm={(close) => {
            close();
            toggleDeliverect();
          }}
      >
        <Pane>
          <UrlDisplay deliverectId={deliverectId} webhookUrl={webhookUrl} />
          <Pane marginBottom={minorScale(2)}>
            <Text>Are you sure you want to {accountInfo.has_deliverect ? 'de-a' : 'a'}ctivate this Deliverect integration?</Text>
          </Pane>
        </Pane>
      </Dialog>
    </Block>
  )
}

export default DeliverectOwner;
