const SUPPLIERS_LOAD_START = 'SUPPLIERS_LOAD_START';
const SUPPLIERS_LOAD_SUCCESS = 'SUPPLIERS_LOAD_SUCCESS';
const SUPPLIERS_LOAD_REPLACE = 'SUPPLIERS_LOAD_REPLACE';
const SUPPLIERS_LOAD_PROCESSED = 'SUPPLIERS_LOAD_PROCESSED';
const SUPPLIERS_LOAD_FAILURE = 'SUPPLIERS_LOAD_FAILURE';
const SUPPLIERS_CLEAR = 'SUPPLIERS_CLEAR';
const ADD_SUPPLIER = 'ADD_SUPPLIER';
const IMPORT_SUPPLIER = 'IMPORT_SUPPLIER';
const REMOVE_SUPPLIER = 'REMOVE_SUPPLIER';
const ARCHIVE_SUPPLIER = 'ARCHIVE_SUPPLIER';
const UNARCHIVE_SUPPLIER = 'UNARCHIVE_SUPPLIER';
const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
const TOGGLE_ARCHIVE_SUPPLIER = 'TOGGLE_ARCHIVE_SUPPLIER';

export const actionTypes = {
  SUPPLIERS_LOAD_START,
  SUPPLIERS_LOAD_SUCCESS,
  SUPPLIERS_LOAD_PROCESSED,
  SUPPLIERS_LOAD_FAILURE,
  SUPPLIERS_CLEAR,
  ADD_SUPPLIER,
  IMPORT_SUPPLIER,
  REMOVE_SUPPLIER,
  UPDATE_SUPPLIER,
  TOGGLE_ARCHIVE_SUPPLIER,
  SUPPLIERS_LOAD_REPLACE
};

const suppliersLoadStart = (payload, account) => ({
  type: actionTypes.suppliers.SUPPLIERS_LOAD_START,
  payload,
  account
});

const suppliersLoadSuccess = (payload, account) => {
  //console.log("Dispatching SUPPLIERS_LOAD_SUCCESS", payload, account);
  return {
    type: SUPPLIERS_LOAD_SUCCESS,
    payload,
    account
  };
};

const suppliersLoadProcessed = (payload, account) => {
  //console.log('Dispatching SUPPLIERS_LOAD_REPLACE', payload, account);
  return {
    type: SUPPLIERS_LOAD_REPLACE,
    payload,
    account
  };
};

const suppliersLoadFailure = (error) => {
  return {
    type: SUPPLIERS_LOAD_FAILURE,
    error
  };
};

const suppliersClear = () => {
  return {
    type: SUPPLIERS_CLEAR
  };
};

const addSupplier = (supplierInfo) => {
  return {
    type: ADD_SUPPLIER,
    supplierInfo
  };
};

const importSupplier = (supplierInfo, callback) => {
  return {
    type: IMPORT_SUPPLIER,
    supplierInfo,
    callback
  };
};

const removeSupplier = (supplierId) => {
  return {
    type: REMOVE_SUPPLIER,
    supplierId
  };
};

const archiveSupplier = (supplierId) => {
  return {
    type: ARCHIVE_SUPPLIER,
    supplierId
  };
};

const unarchiveSupplier = (supplierId) => {
  return {
    type: UNARCHIVE_SUPPLIER,
    supplierId
  };
};

const toggleArchiveSupplier = (supplierInfo) => {
  return {
    type: TOGGLE_ARCHIVE_SUPPLIER,
    supplierInfo
  };
};

const updateSupplier = (supplierInfo) => {
  return {
    type: UPDATE_SUPPLIER,
    supplierInfo
  };
};

export const actions = {
  suppliersLoadStart,
  suppliersLoadSuccess,
  suppliersLoadFailure,
  suppliersClear,
  addSupplier,
  removeSupplier,
  updateSupplier,
  archiveSupplier,
  unarchiveSupplier,
  toggleArchiveSupplier,
  importSupplier,
  suppliersLoadProcessed
};
