import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paragraph, Heading } from 'evergreen-ui';

import Page from '../../components/Page/Page';

const NotFound = (props) => {
  const navigate = useNavigate();
  
  // Redirect to the home page after a short delay
  /*React.useEffect(() => {
    setTimeout(() => navigate('/404'), 3000);
  }, [navigate]);*/
  
  return (
    <Page title="404 Not Found" containerProps={{ maxWidth: 600 }}>
      <Heading is="h2">Sorry</Heading>
      <Paragraph>The page requested could not be found.</Paragraph>
    </Page>
  );
};

export default NotFound;
