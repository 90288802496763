//React
import React, { forwardRef } from 'react';

//Libraries
import PropTypes from 'prop-types';
import { Button as EvergreenButton, useTheme } from 'evergreen-ui';


const Button = forwardRef((props, ref) => {
  const { appearance, children, ...otherProps } = props;
  const theme = useTheme();

let buttonStyle = {
  display: 'inline-flex',
  alignItems: 'center',  
  justifyContent: 'center',  
  height: '32px',  
  fontSize: '14px', 
  fontWeight: "400",

};

  switch (appearance) {
    case 'primary': //MainButton
      buttonStyle = { 
        ...buttonStyle, 
        backgroundColor: theme.colors.primary100, 
        color: theme.colors.black ,
        minWidth: "120px",
        border: "none"
      };
      break;
    case 'danger': //MainButton
      buttonStyle = { 
        ...buttonStyle, 
        backgroundColor: theme.colors.warning, 
        color: theme.colors.black ,
        minWidth: "120px",
        border: "none"
      };
      break;
    case 'minimal': //SecondButton
      buttonStyle = { 
        ...buttonStyle, 
        backgroundColor: theme.colors.secondary30, 
        color: theme.colors.black,
        minWidth: "120px",
      };
      break;
    case 'navMainButton': //NavBar
      buttonStyle = { 
        ...buttonStyle,
        backgroundColor: theme.colors.secondary10,
        color: theme.colors.black,
        height: '40px', 
      };
      break;
      case 'flat': //Filter
        buttonStyle = {
          ...buttonStyle,
          backgroundColor: theme.colors.grey1,
          color: theme.colors.black,
          justifyContent: 'start', 
          height: '32px',  
          border: "none",
          boxShadow: "-1px 4px 7px rgba(0, 0, 0, 0.0.5)",
          minWidth: "200px",
          //fontWeight: "200",
        }
        break;
    default:
      buttonStyle = {
        ...buttonStyle,
        backgroundColor: theme.colors.primary100,
        textColor: theme.colors.black,
      }
      break;
  }


  return (
    <EvergreenButton
        ref={ref}
        style={buttonStyle}
        //className={`mep-button mep-${appearance}`}
        {...otherProps}
    >
      {children}
    </EvergreenButton>
  );
});

Button.propTypes = {
  appearance: PropTypes.oneOf(['primary', 'minimal', 'default', 'flat', 'navMainButton', 'danger'])
};

Button.defaultProps = {
  appearance: 'default'
};

export default Button;
