//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { majorScale, Paragraph } from 'evergreen-ui';

//Components
import CustomDialog from '../../../components/Dialog/Dialog';


const GroupRemoveDialog = ({ removingTemp, setRemovingDept, setRemovingTemp, removeDepartmentTemplate, type = 'department' }) => {
  const clear = () => {
    if (setRemovingDept) setRemovingDept(null);
    setRemovingTemp(null);
  }

  return (
    <CustomDialog
      isOpen={!!removingTemp}
      title="Remove Department"
      intent="danger"
      confirmLabel="Remove Department"
      onConfirm={(close) => {
        removeDepartmentTemplate();
        clear();
      }}
      onClose={clear}
    >
      <Paragraph marginBottom={majorScale(4)}>Are you sure you wish to remove 
        {' '}{removingTemp ? removingTemp.name : ''} from this {type}?
      </Paragraph>
    </CustomDialog>
  )
}

GroupRemoveDialog.propTypes = {
  removingTemp: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  setRemovingDept: PropTypes.func,
  setRemovingTemp: PropTypes.func.isRequired,
  removeDepartmentTemplate: PropTypes.func.isRequired
}

export default GroupRemoveDialog;
