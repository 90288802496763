//React
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, IconButton, Table, Heading, Pane, TextInput, Text } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import DataTable from "../../../components/DataTable/DataTable";
import FilterBar from "../../../components/FilterBar/FilterBar";
import DatePickerInput from "../../../components/DatePickerInput/DatePickerInput";
import CommentPane from "./CommentPane";
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { orderStatuses, TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import moment from "moment";
import useValidation, { validationRules } from "../../../hooks/useValidation";
import { dateFormat } from "../../../utils/format";

const recordRules = {
  invoice_number: [
    // validationRules.NotEmpty
  ],
  temperature: [
    validationRules.NotEmpty,
    validationRules.IsFloat
  ],
  name: [
    validationRules.NotEmpty
  ]
};

const DeliveryTab = ({ date }) => {
  const { accountId } = useParams();
  const [filters, setFilters] = useState();
  const [newRecords, setNewRecords] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const supplierOptionMap = useSelector((state) => _.keyBy(current(state, 'supplierOptions', accountId), 'id'));
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const userInfo = useSelector((state) => ({ userName: state.profile.name, profileId: state.profile.uid }));
  const recentOrders = useSelector((state) => {
    return _.map(
      _.reverse(
        _.sortBy(
          _.filter({ ...current(state, 'orders', accountId) }, (order) => (order.status !== orderStatuses.DRAFT && order.status !== orderStatuses.RECEIVED)),
          'date'
        )
      ),
      (item) => {
        let estimatedCost = _.reduce(item.items, (sum, ing) => {
          let ingOrdered = supplierOptionMap[ing.id];
          const itemAmount = parseFloat(ing.quantity) * parseFloat(ingOrdered.unitprice || 0)
          return item.type && item.type === 'CREDIT' ? sum - itemAmount : sum + itemAmount;
        }, 0);
        const name = supplierMap[item.supplierId] ? supplierMap[item.supplierId].name : '[undefined]';
        const date = item.deliveryDate || item.date;
        const itemDate = moment(date).format("DD/MM/YYYY")

        return { ...item, estimatedCost, name, itemDate }
      }
    );
  });

  const setRecordField = (recordIndex, fieldName, value) => {
    setNewRecords((records) => {
      records[recordIndex] = {
        ...records[recordIndex],
        [fieldName]: value
      };
      return [ ...records ];
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters({});
    }
    else {
      setFilters({ 'search': generateSearchFieldsFn(['name'], newSearchValue)});
    }
  };
  const addOrderRecord = (order) => {
    if (newRecords.findIndex(o => o.id === order.id) === -1) {
      setNewRecords([...newRecords, order])
    }
  };
  const addManualRecord = () => {
    setNewRecords([...newRecords, { }])
  };
  const { allValid, isValid } = useValidation(recordRules);

  const updateOrderFromRecord = (record) => {
    dispatch(actions.orders.updateOrder(accountId, { ...record, ...userInfo, deliveryDate: dateFormat(record.deliveryDate || record.date) }));
  }

  const saveOrderRecord = (record) => {
    const date = dateFormat(record.deliveryDate || record.date);
    let orderRecord = {
      ...record,
      ...userInfo,
      date
    };
    dispatch(actions.orderRecords.addOrderRecord(accountId, orderRecord));
  }

  const saveNewOrderRecord = (record) => {
    if (!allValid(record) ) {
      return;
    }

    if (record.id) {
      updateOrderFromRecord(record);
    }
    else {
      saveOrderRecord(record)
    }
    setNewRecords((records) => _.without(records, record));
  };

  const estimatedString = isMobile ? 'Est.' : 'Estimated';

  const tableHeaders = [
    { label: 'Name', field: 'name', width: 5 },
    { label: `${estimatedString} ${isMobile ? '' : 'Delivery'} Date`, field: 'itemDate', width: 3  },
    { label: `${estimatedString} Cost`, field: 'estimatedCost', format: 'currency', prefix: '£', width: 3 },
    { label: 'Record', field: 'record', type: 'action', icon: 'plus', width: 1  }
  ];

  const renderOrderRow = (po, index) => {
    return (
      <Table.Row
          key={index}
          flexFlow="row wrap"
          height="auto"
          minHeight={48}
          paddingY={majorScale(1)}
      >
        <Table.Cell
            {...( (isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {} )}
        >
          {po.id && (
            <Text>{po.name}</Text>
          )}
          {!po.id && (
            <TextInput
                value={po.name ||''}
                onChange={(e) => setRecordField(index, 'name', e.target.value)}
                isInvalid={!isValid('name', po.name)}
                maxWidth="100%"
                // width="6rem"
                // maxLength={5}
            />
          )}
        </Table.Cell>
        <Table.TextCell
            {...( (isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {} )}
        >
          <DatePickerInput
              min={moment(po.date).toDate()}
              value={(po.deliveryDate) ? moment(po.deliveryDate).toDate() : moment(po.date).toDate()}
              onChange={(newDate) => setRecordField(index, 'deliveryDate', (newDate) ? newDate : moment(po.date).toDate())}
          />
        </Table.TextCell>
        {!isMobile && (
          <Table.TextCell
          >{po.id ? po.items?.length || 0 : ''}</Table.TextCell>
        )}
        <Table.Cell
            {...( (isMobile) ? { flexBasis: '25%' } : {} )}
        >
          <TextInput
              value={po.temperature ||''}
              onChange={(e) => setRecordField(index, 'temperature', e.target.value)}
              isInvalid={!isValid('temperature', po.temperature)}
              maxWidth="100%"
              width="6rem"
              maxLength={5}
          />
        </Table.Cell>
        <Table.Cell
            {...( (isMobile) ? { flexBasis: '25%' } : {} )}
        >
          <TextInput
              value={po.invoice_number ||''}
              onChange={(e) => setRecordField(index, 'invoice_number', e.target.value)}
              isInvalid={!isValid('invoice_number', po.invoice_number)}
              maxWidth="100%"
              width="6rem"
              maxLength={10}
          />
        </Table.Cell>
        <Table.Cell>
          <IconWrapper
              name="tick"
              appearance="clickable"
              onClick={() => saveNewOrderRecord(po)}
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  return (
    <React.Fragment >
      <Block marginBottom={majorScale(2)}>
        <Pane padding={majorScale(2)}>
          <Pane
              display="flex"
              alignItems="center"
              marginBottom={majorScale(2)}
          >
            <CustomHeading level="3" flex="1 0 auto">Delivery temperature logs</CustomHeading>
            <Button
                appearance="primary"
                onClick={addManualRecord}
            >Add manually</Button>
          </Pane>
          <FilterBar
              searchPlaceholder="Search for a PO"
              searchOnChange={searchOnChange}
          />
        </Pane>
        <DataTable
            listHeight={200}
            items={recentOrders}
            headers={tableHeaders}
            filters={filters}
            onRecord={(item) => addOrderRecord(item)}
        />
        <Table>
          <Table.Head
              paddingRight={0}
              flexFlow={(isMobile) ? 'row wrap' : 'row nowrap'}
              height="auto"
              paddingY={majorScale(1)}
          >
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {} )}
            >Supplier Name</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '100%' } : {} )}
            >Delivery Date</Table.TextHeaderCell>
            {!isMobile && (
              <Table.TextHeaderCell
                  {...( (isMobile) ? { flexBasis: '25%' } : {} )}
              >Items</Table.TextHeaderCell>
            )}
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '25%' } : {} )}
            >Temp.</Table.TextHeaderCell>
            <Table.TextHeaderCell
                {...( (isMobile) ? { flexBasis: '25%', marginTop: majorScale(1) } : {} )}
            >Invoice Nb.</Table.TextHeaderCell>
            <Table.TextHeaderCell>Save</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(newRecords) && (
              <Table.Row>
                <Table.TextCell>No orders to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(newRecords, renderOrderRow)}
          </Table.Body>
        </Table>
      </Block>
      <Block>
        <CommentPane
          accountId={accountId}
          date={date}
          type={'DELIVERY'}
        />
      </Block>
    </React.Fragment>
  )
}

export default DeliveryTab;
