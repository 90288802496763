//React
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import DataTable from '../../../components/DataTable/DataTable';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { stockRecordingUnits, TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { currency, shortDateDisplayFormat } from '../../../utils/format';
import { current } from '../../../utils/selectors';
import { displayOrderIngredient, getWastageDate, getTransferStringDate } from '../../../utils/functions';

const renderUOM = (item) => {
  // Ingredients may have a "display" value already, default to that
  if (item.displayRecordUOM) {
    return item.displayRecordUOM;
  }
  // // Ingredient or recipe, has the UOM recorded so we don't really care either way
  // if (item.recordUOM) {
  //   return stockRecordingUnits[item.recordUOM];
  // }
  // No recorded UOM, check if it's an ingredient
  if (item.ingredient) {
    return stockRecordingUnits[item.ingredient.recipeunit];
  }

  // No recorded UOM and it's not an ingredient, so show UOM for a recipe
  return stockRecordingUnits[item.recipe.yieldDescription || 'unit'];
};

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', columnProps: { flexGrow: 2 }, width: 4 },
  { label: 'UOM', type: 'calc', calc: renderUOM, columnProps: { flex: '0 0 10rem' }, width: 2 },
  { label: 'Qty', field: 'normalQty', type: 'numeric', columnProps: { flex: '0 0 7rem' }, width: 2 },
  { label: 'Value', field: 'cost', type: 'numeric', format: 'currency', prefix: '£ ', width: 3 }
];

const TransferDetail = () => {
  const { accountId, transferId } = useParams();
  const theme = useTheme();
  const transfer = useSelector((state) => _.find(current(state, 'transfers', accountId), { 'id': transferId }));

  return (
    <React.Fragment>
      <Block
          background={theme.colors.offwhite}
          height={48}
          display="flex"
          alignItems="center"
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginBottom={majorScale(2)}
      >
        <IconWrapper
            appearance="clickable"
            is={Link}
            to={`/${accountId}/review/transfers`}
            name="arrowLeft"
            marginRight={majorScale(2)}
        />
        <CustomHeading level="4">Transfer - {transfer.transferType} - {shortDateDisplayFormat(getTransferStringDate(transfer))}</CustomHeading>
      </Block>

      <Block display="flex" flexDirection="column">
        <Pane
            display="flex"
            alignItems="center"
            paddingX={majorScale(2)}
            marginY={majorScale(2)}
        >
          <CustomHeading level="4" marginRight={majorScale(2)}>Transfer Value</CustomHeading>
          <Text size={600} color={theme.colors.tertiary100}>£ {currency(transfer.totalCost)}</Text>
        </Pane>
        <DataTable
            headers={tableHeaders}
            items={transfer.records.map((r, index) => ({ ...displayOrderIngredient(r), id: index }))}
            tableProps={{ minHeight: TABLE_MIN_HEIGHT }}
        />
      </Block>
    </React.Fragment>
  );
};

export default TransferDetail;
