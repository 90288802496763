// CustomPieChart.js
import React from 'react';

//Libraries
import { Card, useTheme, Text as EvergreenText, Pane, minorScale, majorScale } from 'evergreen-ui';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Text } from 'recharts';

//Files
import { currency } from '../../../utils/format'

//PIE CHART
// Custom Tooltip Component
const CustomTooltipPieChart = ({ active, payload, tooltipFields, tooltipTitles }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Pane padding={20} backgroundColor={theme.colors.white} borderRadius={3} border="1px solid" borderColor={theme.colors.muted}>
        {tooltipFields.map((field, index) => (
          <Pane key={field} marginBottom={4}>
            <Text style={{ fontSize: '14px', fontFamily: 'Outfit' }}>
              {`${tooltipTitles[index]}: ${payload[0].payload[field]}`}
            </Text>
          </Pane>
        ))}
      </Pane>
    );
  }
  return null;
};

// Render Customized Label inside the Pie
//Still need some work
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, labelFields, labelPosition }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * (labelPosition === 'inside' ? 1 : 1); // Increase the factor for more space
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Outfit' }}
    >
      {labelFields.map((field, idx) => (
        <tspan key={field} x={x} dy={idx === 0 ? 0 : 18}>{payload[field]}</tspan> // Adjust dy for more spacing
      ))}
      <tspan x={x} dy={18}>{`${(percent * 100).toFixed(0)}%`}</tspan>
    </text>
  );
};


// Custom Pie Chart Component
export const CustomPieChart = ({ data, colors, width = '100%', height = 'auto', 
  labelFields = ['name'], tooltipFields, tooltipTitles, showLegend = true, labelPosition = 'inside'  }) => {
  const theme = useTheme();
  const defaultColors = [
    theme.colors.secondary100, theme.colors.secondary90, theme.colors.secondary80, 
    theme.colors.secondary70, theme.colors.secondary60, theme.colors.secondary50, 
    theme.colors.secondary40, theme.colors.secondary30, theme.colors.secondary20, 
    theme.colors.secondary10, theme.colors.secondary5
  ];
  const chartColors = colors || defaultColors;

  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  const tooltipFormatter = (value) => value.toFixed(2);

  const legendFormatter = (value, entry) => {
    const percentage = ((entry.payload.value / totalValue) * 100).toFixed(2);
    return `${value} (${percentage}%)`;
  };

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" width={width} height={height}>
      <ResponsiveContainer width="90%" aspect={1}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={labelPosition === 'outside'}
            label={(props) => renderCustomizedLabel({ ...props, labelFields, labelPosition })}
            outerRadius="80%"
            innerRadius="50%"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
            ))}
          </Pie>
          <Tooltip content={(props) => <CustomTooltipPieChart {...props} tooltipFields={tooltipFields} tooltipTitles={tooltipTitles} />} formatter={tooltipFormatter} />
          {showLegend && (
            <Legend 
              layout="horizontal" 
              verticalAlign="bottom" 
              align="center"
              formatter={legendFormatter} 
              wrapperStyle={{ paddingBottom: '20px', fontSize: "14px", fontFamily: theme.fontFamilies.ui }}
              iconSize={10}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </Pane>
  );
};

//BAR CHART
const CustomBarTooltip = ({ active, payload, valueType }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    const value = valueType === 'number' ? `£${payload[0].value.toFixed(2)}` : payload[0].value;
    return (
      <Pane padding={20} backgroundColor={theme.colors.white} borderRadius={3} border="1px solid" borderColor={theme.colors.muted}>
        <Text style={{ fontSize: theme.fontSizes.medium, fontFamily: theme.fontFamilies.ui }}>
          {`${payload[0].payload.name}: ${value}`}
        </Text>
      </Pane>
    );
  }
  return null;
};

// Custom BarChart Component
export const CustomBarChart = ({ data, barKey, barColors, height = '100%', width = "100%", layout = 'horizontal', xKey, yKey, yType, xType, valueType }) => {
  const theme = useTheme();
  const chartColors = [theme.colors.secondary70];

  return (
    <Pane display="flex" justifyContent="center" alignItems="center" height={height} width={width}>
    <ResponsiveContainer width="90%" height={height} >
      <BarChart data={data} layout={layout} margin={{ top: 20, right: 0, left: 50, bottom: 10 }}>
        <XAxis 
          type={xType}
          dataKey={xKey}
          tick={{ fontSize: '12px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
          //style={{ paddingTop: '10px', paddingBottom: '10px' }}  // Adjust padding as necessary
        />
        <YAxis 
          type={yType}
          dataKey={yKey}
          tickLine={false} 
          tick={{ fontSize: '12px', fontFamily: 'Outfit', fill: '#333', fontWeight: 500 }}
          width={layout === "vertical" ? 70 : 20} 
        />
        <Tooltip content={CustomBarTooltip} />
        <Bar dataKey={barKey} barSize={16} radius={layout === "vertical" ? [0, 10, 10, 0] : [10, 10, 0, 0]}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
          ))}
        </Bar>
      </BarChart>
      {/*<Legend 
          layout="horizontal" 
          verticalAlign="bottom" 
          align="center"
          formatter={legendFormatter} 
          wrapperStyle={{ paddingBottom: '20px', fontSize: "14px", fontFamily: theme.fontFamilies.ui }}
          iconSize={10}
        />*/}
    </ResponsiveContainer>
    </Pane>
  );
};


//CUSTOM CARD
// Helper function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(value);
};

// Helper function to format percentages
const formatPercentage = (value) => {
  return `${parseFloat(value).toFixed(1)}%`;
};

export const CustomCard = ({ title, data, prevData, infoData }) => {
  const theme = useTheme();

  // Function to determine if the value is an array with two elements (value and type)
  const isValueTypeArray = (value) => Array.isArray(value) && value.length === 2;

  // Destructure value and type from tuples, provide default values if not present
  const [dataValue, dataType] = isValueTypeArray(data) ? data : [data, ''];
  const [prevDataValue, prevDataType] = isValueTypeArray(prevData) ? prevData : [prevData, ''];
  const [infoDataValue, infoDataType] = isValueTypeArray(infoData) ? infoData : [infoData, ''];

  // Check if infoData is a valid number
  const isInfoDataNumber = !isNaN(parseFloat(infoDataValue)) && isFinite(infoDataValue);
  const isPositive = parseFloat(infoData) > 0;

  // Format data based on type
  const formatValue = (value, type) => {
    switch (type) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return formatPercentage(value);
      default:
        return value;
    }
  };

  const formattedData = formatValue(dataValue, dataType);
  const formattedPrevData = formatValue(prevDataValue, prevDataType);
  const formattedInfoData = formatValue(infoDataValue, infoDataType);

  return (
    <Card 
      width='100%' 
      boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
      background={theme.colors.white}
      radius={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingY={majorScale(2)}
    >
      <Pane display='flex' justifyContent="center"  width='100%' marginBottom={majorScale(2)} paddingX={majorScale(2)}>
        <EvergreenText flex='1' color={theme.colors.black} >{title}</EvergreenText>
        <EvergreenText flex='1' color={isInfoDataNumber ? (isPositive ? theme.colors.success : theme.colors.warning) : theme.colors.black} textAlign="right">{formattedInfoData}</EvergreenText>
      </Pane>
      <Pane display='flex' justifyContent="center" width='100%' paddingX={majorScale(2)}>
        <EvergreenText flex='1' fontSize="20px" color={theme.colors.tertiary100} >{formattedData}</EvergreenText>
        <EvergreenText flex='1' color={theme.colors.grey2} textAlign="right">{formattedPrevData}</EvergreenText>
      </Pane>
    </Card>
  )
}

export const CustomCardOneLine = ({ title, data }) => {
  const theme = useTheme();

  // Function to determine if the value is an array with two elements (value and type)
  const isValueTypeArray = (value) => Array.isArray(value) && value.length === 2;

  // Destructure value and type from tuples, provide default values if not present
  const [dataValue, dataType] = isValueTypeArray(data) ? data : [data, ''];

  // Format data based on type
  const formatValue = (value, type) => {
    switch (type) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return formatPercentage(value);
      default:
        return value;
    }
  };

  const formattedData = formatValue(dataValue, dataType);

  return (
    <Card 
      width='100%' 
      boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
      background={theme.colors.white}
      radius={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingY={majorScale(2)}
    >

      <Pane display='flex' justifyContent="center" width='100%' paddingX={majorScale(2)}>
        <EvergreenText flex='1' color={theme.colors.black} >{title}</EvergreenText>
        <EvergreenText flex='1' color={theme.colors.black} textAlign="right">{formattedData}</EvergreenText>
      </Pane>
    </Card>
  )
}
