//React
import React from 'react'

//Libraries
import { majorScale, minorScale, Pane, Text, useTheme } from 'evergreen-ui'

//Componenents
import CustomHeading from '../../../components/Headings/Headings'

const Header = ({
  title,
  subtitle
}) => {
  const theme = useTheme();

  return (
    <Pane display='flex' alignItems='center' minHeight={80}>
      <CustomHeading level="3" fontSize={21} fontStyle='bold' margin={majorScale(2)} paddingLeft={majorScale(2)}>{title}</CustomHeading>
      {subtitle && (
        <Text color={theme.colors.tertiary100} marginLeft={minorScale(2)} fontSize={18}>{subtitle}</Text>
      )}
    </Pane>
  )
}

export default Header;
