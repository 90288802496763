const FOOD_RECORDS_LOAD_SUCCESS = 'FOOD_RECORDS_LOAD_SUCCESS';
const FOOD_RECORDS_CLEAR = 'FOOD_RECORDS_CLEAR';
const ADD_FOOD_RECORD = 'ADD_FOOD_RECORD';
const REMOVE_FOOD_RECORD = 'REMOVE_FOOD_RECORD';
const UPDATE_FOOD_RECORD = 'UPDATE_FOOD_RECORD';

export const actionTypes = {
  FOOD_RECORDS_LOAD_SUCCESS,
  FOOD_RECORDS_CLEAR,
  ADD_FOOD_RECORD,
  REMOVE_FOOD_RECORD,
  UPDATE_FOOD_RECORD
};


const foodRecordsLoadSuccess = (payload, account) => {
  return {
    type: FOOD_RECORDS_LOAD_SUCCESS,
    payload,
    account
  };
};

const foodRecordsClear = () => {
  return {
    type: FOOD_RECORDS_CLEAR
  };
};

const addFoodRecord = (accountId, record) => {
  return {
    type: ADD_FOOD_RECORD,
    record,
    accountId,
  };
};

const removeFoodRecord = (accountId, recordId) => {
  return {
    type: REMOVE_FOOD_RECORD,
    recordId,
    accountId,
  };
};

const updateFoodRecord = (accountId, record) => {
  return {
    type: UPDATE_FOOD_RECORD,
    record,
    accountId,
  };
};

export const actions = {
  foodRecordsLoadSuccess,
  foodRecordsClear,
  addFoodRecord,
  removeFoodRecord,
  updateFoodRecord
};
