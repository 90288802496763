import fb from './';
import { getCollectionRef } from './common';

export const addInvoiceUpload = (record, accountId) => {
  const invoiceUploadsRef = getCollectionRef('invoiceUploads', accountId);
  return invoiceUploadsRef.add({ ...record, created: fb.serverTime() });
};

export const removeInvoiceUpload = (recordId, accountId) => {
  const invoiceUploadsRef = getCollectionRef('invoiceUploads', accountId);
  return invoiceUploadsRef.doc(recordId).delete();
};

export const updateInvoiceUpload = (recordId, record, accountId) => {
  const invoiceUploadsRef = getCollectionRef('invoiceUploads', accountId);
  return invoiceUploadsRef.doc(recordId).update({ ...record, lastModified: fb.serverTime() });
};

export const addInvoiceAttachment = async (attachment, bucket, accountId) => {
  const invoiceUploadsRef = getCollectionRef('invoiceUploads', accountId);
  const { file, filename, invoiceUploadId } = attachment;
  // const ext = file.name.split('.').pop();
  // const filename = `${bucket}/images/invoiceUploads/${invoiceUploadId}.${ext}`;
  invoiceUploadsRef.doc(invoiceUploadId).update({ 'original_imagefilename': filename });
  return fb.storage.ref().child(filename).put(file);
};
