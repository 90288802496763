//React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import { minorScale, Pane, TextInputField, Combobox, TextInput, FormField, majorScale } from 'evergreen-ui';

//Components
import TabNavigation from '../../../../components/ui/TabNavigation/TabNavigation'
import Tab from '../../../../components/ui/Tab/Tab'
import DataTable from '../../../../components/DataTable/DataTable';
import FilterBar  from '../../../../components/FilterBar/FilterBar';
import CustomDialog from '../../../../components/Dialog/Dialog';
import IconWrapper from '../../../../components/Icons/Icons';
import CustomHeading from '../../../../components/Headings/Headings';

//Files
import { generateSearchFieldsFn, getRecipeById, getIngredientById, recipeYieldPerPortion } from '../../../../utils/functions';
import { filterActiveRecipes } from '../../../../utils/recipes';
import { filterActiveIngredients } from '../../../../utils/ingredients';
import { current, selectNonDeletedModifiers } from '../../../../utils/selectors';

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'actionField', action: 'select', width: 8 },
];

const modifierHeaders = () => [
  ...tableHeaders,
  { label: 'Category', field: 'category', type: 'field', width: 8 },
]


const EditIngredientDialog = ({ accountId, onClose, isShown, onConfirm, existingIngredient }) => {
  const ingredientList = useSelector((state) => filterActiveIngredients(current(state, 'ingredients', accountId)));
  const recipeList = useSelector((state) => filterActiveRecipes(current(state, 'recipes', accountId)));
  const modifierList = useSelector(state => _.sortBy(selectNonDeletedModifiers(state, accountId), 'name'));
  const [currentTab, setCurrentTab] = useState('ingredient');
  const [selectedIng, selectIngredient] = useState(false);
  const [selectedRecipe, selectRecipe] = useState(false);
  const [selectedModifier, selectModifier] = useState(false);
  const [filters, setFilters] = useState({});
  const [newIng, setNewIng] = useState({});

  useEffect(() => {
    if (_.isEmpty(newIng) && existingIngredient && existingIngredient.item) {
      const item = existingIngredient.item;
      if (item.type) {
        if (item.type === 'subrecipe') {
          setCurrentTab('recipe');
          selectRecipe(getRecipeById(item.id));
        }
        else {
          setCurrentTab('modifier');
          selectModifier(modifierList.find(m => m.id === item.id))
        }
      }
      else {
        setCurrentTab('ingredient');
        selectIngredient(getIngredientById(item.id));
      }
      let newIngObject = {
        quantity: item.quantity
      };
      if (item.measureQty) {
        newIngObject.measureQty = item.measureQty;
      }
      if (item.measureUnit) {
        newIngObject.measureUnit = item.measureUnit;
      }
      setNewIng(newIngObject);
    }
  }, [newIng, existingIngredient, modifierList]);

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };
  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };
  const onFieldChange = (field, newValue) => {
    setNewIng((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  // Lookup function
  /*const getCategoryNameById = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : '';
  };*/

  const dialogConfirm = () => {
    if (currentTab === 'ingredient') {
      onConfirm({ ...newIng, 
        id: selectedIng.id, 
        ingredient: { ...selectedIng } });
    }
    else if (currentTab === 'modifier') {
      onConfirm({ ...selectedModifier, type: 'modifier' });
    }
    else {
      onConfirm({ ...newIng, 
        id: selectedRecipe.id, 
        type: 'subrecipe' });
    }
    resetDialog();
  };
  
  const dialogClose = () => {
    resetDialog();
    if (onClose) {
      onClose();
    }
  };

  const resetDialog = () => {
    setNewIng({});
    selectIngredient(false);
    selectRecipe(false);
    selectModifier(false);
    setFilters({});
    searchOnChange('');
    if (onClose) {
      onClose();
    }
  };

  const renderCurrentTab = () => {
    if (currentTab === 'recipe') {
      return (
        <Pane>
          <FormField label="Recipe Name" marginBottom={majorScale(2)}>
            <Combobox
                placeholder="Recipe Name"
                items={recipeList}
                itemToString={(item) => item ? `${item.name}` : ''}
                selectedItem={selectedRecipe}
                onChange={(selected) => selectRecipe(selected || false)}
                width="100%"
            />
          </FormField>
          <TextInputField
              required
              label="Quantity"
              value={newIng.quantity || ''}
              onChange={(e) => onFieldChange('quantity', e.target.value)}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Yield"
              value={selectedRecipe.yield || 1}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Yield Description"
              value={selectedRecipe.yieldDescription}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Portion"
              value={selectedRecipe.portion || 1}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Yield / Portion"
              value={recipeYieldPerPortion(selectedRecipe)}
          />
        </Pane>
      );
    }
    else if (currentTab === 'modifier') {
      return (
        <Pane>
          <Pane display="flex" flexDirection="column">
            <FilterBar searchPlaceholder="Search Modifiers" searchOnChange={searchOnChange} paddingY={majorScale(2)} />
            <DataTable filters={filters} headers={modifierHeaders()} items={modifierList} onRowSelect={(ing) => selectModifier(ing)} columnProps={{justifyContent: 'flex-start'}} />
          </Pane>
        </Pane>
      )
    }
    else {
      if (!selectedIng) {
        return (
          <Pane display="flex" flexDirection="column" minHeight="60vh">
            <FilterBar searchPlaceholder="Search Ingredients" searchOnChange={searchOnChange} paddingY={majorScale(2)}/>
            <DataTable filters={filters} headers={tableHeaders} items={ingredientList} onRowSelect={(ing) => selectIngredient(ing)} />
          </Pane>
        );
      }
      else {
        return (
          <Pane>
            <FormField label="Name" marginBottom={majorScale(2)}>
              <Pane display="flex">
                <TextInput
                    disabled
                    value={selectedIng.name}
                    flex="1 1 auto"
                />
                <IconWrapper name="cross" onClick={() => selectIngredient(false)} />
              </Pane>
            </FormField>
            <TextInputField
                required
                label="Quantity"
                value={newIng.quantity || ''}
                onChange={(e) => onFieldChange('quantity', e.target.value)}
                marginBottom={majorScale(2)}
            />
            <TextInputField
                disabled
                label="Unit of Measure (UOM)"
                value={selectedIng.recipeunit}
                marginBottom={majorScale(2)}
            />
            <CustomHeading level="5" marginBottom={majorScale(2)}>MEASURE (Optional)</CustomHeading>
            <TextInputField
                label="Qty (number only, leave blank if using costing quantity"
                value={newIng.measureQty || ''}
                onChange={(e) => onFieldChange('measureQty', e.target.value)}
                marginBottom={majorScale(2)}
            />
            <TextInputField
                label="Unit or description"
                value={newIng.measureUnit || ''}
                onChange={(e) => onFieldChange('measureUnit', e.target.value)}
                marginBottom={majorScale(2)}
            />
          </Pane>
        );
      }
    }
  };


  return (
    <CustomDialog
        isOpen={isShown}
        hasHeader={false}
        intent="none"
        confirmLabel={(currentTab === 'ingredient') ? 'Add Ingredient' : 'Add SubRecipe'}
        onConfirm={dialogConfirm}
        onClose={dialogClose}
        onCloseComplete={dialogClose}
        preventBodyScrolling
        width={700}
    >
      <Pane marginBottom={minorScale(3)}>
        <Pane borderBottom="default" marginBottom={majorScale(2)}>
          <TabNavigation
              display="flex"
              flexShrink={0}
              overflowX="auto"
              paddingBottom={majorScale(2)}
          >
            <Tab isSelected={currentTab === 'ingredient'} onSelect={() => setCurrentTab('ingredient')}>Ingredient</Tab>
            <Tab isSelected={currentTab === 'recipe'} onSelect={() => setCurrentTab('recipe')}>Subrecipe</Tab>
            <Tab isSelected={currentTab === 'modifier'} onSelect={() => setCurrentTab('modifier')}>Modifier</Tab>
          </TabNavigation>
        </Pane>
        {renderCurrentTab()}
      </Pane>
    </CustomDialog>
  );
};

EditIngredientDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  isShown: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  existingIngredient: PropTypes.object
};


export default EditIngredientDialog;
