//React
import React from 'react';
import { useSelector } from 'react-redux';

//Libraries
import moment from 'moment';
import { majorScale, Pane, TextInputField, FormFieldLabel, Select, SelectField } from 'evergreen-ui';

//Components
import TutoLink from '../../../components/TutoLink/TutoLink';
import CSelectField from '../../../components/CustomSelectField/CustomSelectField';
import Button from '../../../components/ui/Button/Button';

//Files
import { current } from '../../../utils/selectors';


const timeOptions = () => {
  const options = []
  for(let hour = 0; hour < 24; hour++) {
    options.push(moment({ hour }).minutes(0).format('HH:mm'));
    options.push(moment({ hour }).minutes(30).format('HH:mm'));
  }
  return options;
}

const tutorialAccountsType = {
  OWNER: {link: 'https://www.misenplace.ai/support/create-a-head-office', title: 'a head office'},
  LOCATION: {link: 'https://www.misenplace.ai/support/create-a-location', title: 'a location'},
  DEPARTMENT: {link: 'https://www.misenplace.ai/support/area-settings', title: 'an area'},
};

const DetailsTab = ({ accountInfo, onFieldChange, isOwner, isDepartment, doSave, location, locationAreas }) => {
  const areaCats = useSelector(state => current(state, 'areas'));
  const changeDepartmentName = (catName) => {
    const cat = areaCats.find(c => c.name === catName);
    onFieldChange('area', cat);
    onFieldChange('name', `${location.name} - ${catName}`);
  }

  console.log(accountInfo)

  const remainingAreas = areaCats.filter(c => locationAreas?.findIndex(a => a.area?.id === c.id) === -1);
  const cat = isDepartment ? areaCats.find(a => accountInfo.area?.id === a.id) : null;
  const areas = cat ? [...remainingAreas, cat] : remainingAreas;

  const tutorial = tutorialAccountsType[accountInfo.type]

  return (
    <>
      <Pane paddingRight={majorScale(2)} paddingTop={majorScale(2)} width="100%" display="flex" justifyContent="flex-end">
        <TutoLink tutorialLink={tutorial?.link} title={`Watch demo video: how to set up ${tutorial?.title}`}/>
      </Pane>
      <Pane>
        {isDepartment && (
          <SelectField
              flex="1 1 0"
              label="Area name"
              placeholder="Select from areas ..."
              value={accountInfo.area?.name || ''}
              onChange={(e) => changeDepartmentName(e.target.value)}
              disabled={!isOwner() || remainingAreas.length === 0}
          >
            <option value=""></option>
            {areas.map((category) => (<option key={category.id} value={category.name}>{location.name} - {category.name}</option>))}
          </SelectField>
        )}
        {!isDepartment && (
          <TextInputField
              label={accountInfo.type && accountInfo.type === 'LOCATION' ? 'Location name' : accountInfo?.type === 'OWNER' ? 'Head office name' : "Account Name"}
              value={accountInfo.name || ''}
              onChange={(e) => onFieldChange('name', e.target.value)}
              disabled={!isOwner()}
          />
        )}
        <TextInputField
            label="Contact email"
            type="email"
            value={accountInfo.email || (accountInfo?.type === 'OWNER' ? accountInfo.ownerEmail : '')}
            onChange={(e) => onFieldChange('email', e.target.value)}
            disabled={!isOwner()}
        />
        <TextInputField
            label="Address line 1"
            value={accountInfo.address1 || ''}
            onChange={(e) => onFieldChange('address1', e.target.value)}
            disabled={!isOwner()}
        />
        <TextInputField
            label="Address line 2"
            value={accountInfo.address2 || ''}
            onChange={(e) => onFieldChange('address2', e.target.value)}
            disabled={!isOwner()}
        />
        {/* <TextInputField
            label="Address line 3"
            value={accountInfo.address3 || ''}
            onChange={(e) => onFieldChange('address3', e.target.value)}
            disabled={!isOwner()}
        /> */}
        <TextInputField
            label="Postcode"
            value={accountInfo.postcode || ''}
            onChange={(e) => onFieldChange('postcode', e.target.value)}
            disabled={!isOwner()}
        />
        { isDepartment &&
          <>
            <TextInputField
                label="Location Prefix"
                value={accountInfo.location_prefix || ''}
                onChange={(e) => onFieldChange('location_prefix', e.target.value)}
                disabled={!isOwner()}
            />
            
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={majorScale(2)}
            >
              <FormFieldLabel
                  marginRight={majorScale(2)}
              >Opening Time</FormFieldLabel>
              <Select
                  value={accountInfo.opening_time || '08:00'}
                  onChange={(e) => onFieldChange('opening_time', e.target.value)}
                  maxWidth={400}
                  disabled={!isOwner()}
              >
                {
                  timeOptions().map(opt => (
                    <option key={opt} value={opt}>{opt}</option>
                  ))
                }
              </Select>
              <FormFieldLabel
                  marginRight={majorScale(2)}
              >Closing Time</FormFieldLabel>
              <Select
                  value={accountInfo.closing_time || '22:00'}
                  onChange={(e) => onFieldChange('closing_time', e.target.value)}
                  maxWidth={400}
                  disabled={!isOwner()}
              >
                {
                  timeOptions().map(opt => (
                    <option key={opt} value={opt}>{opt}</option>
                  ))
                }
              </Select>
            </Pane>
          </>
        }

        {/* <TextInputField
            label="Accountant name"
            value={accountInfo.accountantName || ''}
            onChange={(e) => onFieldChange('accountantName', e.target.value)}
            disabled={!isOwner()}
        />
        <TextInputField
            label="Accountant email"
            type="email"
            value={accountInfo.accountantEmail || ''}
            onChange={(e) => onFieldChange('accountantEmail', e.target.value)}
            disabled={!isOwner()}
        /> */}

        <Pane display="flex" justifyContent="center">
          <Button disabled={!isOwner()} appearance="primary" onClick={doSave}>Save</Button>
        </Pane>
      </Pane>
    </>
  )
}

export default DetailsTab;
