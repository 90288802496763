// CustomDialog.js
import React from 'react';
import { Dialog, Pane, majorScale, Heading, useTheme } from 'evergreen-ui';

import Button from '../../components/ui/Button/Button';

const CustomDialog = ({ isOpen, children, title, onClose, onCloseComplete, onConfirm, confirmLabel = 'Confirm', 
  hasFooter = false, hasHeader = true, width = '500px', intent = 'none', foot }) => {
    const theme = useTheme();

    // Inline style for the header
    const headerStyle = {
        fontSize: '18px',
        fontWeight: '400',
        textAlign: 'center',
        color: theme.colors.black,
        margin: 0,
        padding: 0,
        marginLeft: majorScale(1)
    };


    // Custom header component
    const headerComponent = (
        <Pane  display="flex" alignItems="center" justifyContent="start" margin={0} padding={0}>
            <Heading style={headerStyle}>{title}</Heading>
        </Pane>
    );

    const defaultFooter = (
        <Pane display="flex" justifyContent="space-between" paddingX={majorScale(1)} paddingBottom={majorScale(2)}>
          <Button 
            appearance="minimal" 
            onClick={onClose}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            maxWidth="100%" 
            >Cancel
          </Button>
          <Button 
            appearance="primary" 
            onClick={onConfirm}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            maxWidth="100%" 
          >
            {confirmLabel}
          </Button>
        </Pane>
      );

  return (
    <Dialog
      isShown={isOpen}
      hasHeader={hasHeader}
      onCloseComplete={onCloseComplete}
      header={headerComponent}
      hasFooter={hasFooter}
      width={width}
      intent={intent}
      foot
    >  
    <Pane maxHeight="350" display="flex" flexDirection="column" marginBottom={majorScale(2)}>
        <Pane marginLeft={majorScale(1)} flex="1">
        {children}
        </Pane>
        {foot || defaultFooter}
    </Pane>
    </Dialog>
  );
};

export default CustomDialog;
