import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addSupplierCategory, removeSupplierCategory, updateSupplierCategory } from '../firebase/supplierCategories';
import { actions, actionTypes } from '../actions';
import { activeAccount, current } from '../../utils/selectors';
import _ from 'lodash';

export default function* supplierCategoriesSaga() {
  yield takeEvery(actionTypes.supplierCategories.ADD_SUPPLIER_CATEGORY, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addSupplierCategory, action.categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Supplier Category Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier Category could not be added'));
    }
  });
  yield takeEvery(actionTypes.supplierCategories.REMOVE_SUPPLIER_CATEGORY, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      const catMap = yield select((state) => _.groupBy(current(state, 'supplierCategories'), 'parent'));
      const recursiveRemove = function* (catId) {
        if (catMap[catId]) {
          for (let i = 0, ln = catMap[catId].length; i < ln; i++) {
            yield call(recursiveRemove, catMap[catId][i].id);
          }
        }
        return yield call(removeSupplierCategory, catId, accountId);
      };
      yield call(recursiveRemove, action.categoryId);
      yield put(actions.appMessageSuccess('Supplier Category Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier Category could not be removed'));
    }
  });
  yield takeEvery(actionTypes.supplierCategories.UPDATE_SUPPLIER_CATEGORY, function* (action) {
    const { id, ...categoryInfo } = action.categoryInfo;
    try {
      const accountId = yield select(activeAccount);
      yield call(updateSupplierCategory, id, categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Supplier Category Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier Category could not be updated'));
    }
  });
};

