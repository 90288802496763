//React
import React from 'react';

//Libraries
import { majorScale, useTheme } from 'evergreen-ui';

//Components
import PeriodWeeks from '../../../components/PeriodDisplay/PeriodWeeks';
import Block from '../../../components/ui/Block/Block';

//TODO: Be able to update currentWeek when going backward or forward
const PeriodNavigation = ({ weekInfo, seeNextWeek, seePrevWeek }) => {
  const theme = useTheme();

  return (
    <Block
        elevation={1}
        background={theme.colors.white}
        marginBottom={majorScale(2)}
        padding={majorScale(2)}
        paddingY={majorScale(4)}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
    >
      <PeriodWeeks
        weekInfo={weekInfo}
        seeNextWeek={seeNextWeek}
        seePrevWeek={seePrevWeek}
      />
    </Block>
  )
}

export default PeriodNavigation;
