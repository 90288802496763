//React
import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table, Text, Pane } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import MatchesDialog from './MatchesDialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { accountTypes} from '../../../utils/constants'
import { supplierOptionUOM } from '../../../utils/functions';
import { currency, shortDateDisplayFormat } from '../../../utils/format';
import { orderStatuses } from '../../../utils/constants';
import { dateYearFormat } from '../../../utils/time';
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';


const RecentOrders = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });

  const account = useSelector((state) => state.accounts[state.currentAccount]);
  const userAccounts = useSelector((state) => state.accounts); 
  const [locationAccountId, setLocationAccountId] = useState(null);
 
  useEffect(() => {
     const currentAccount = userAccounts[accountId];
     if (currentAccount && currentAccount.parents) {
       // Loop through each parent ID in the current account's parents array
       for (const parentId of currentAccount.parents) {
         const parentAccount = userAccounts[parentId];
         // Check if the parent account is of type 'LOCATION'
         if (parentAccount && parentAccount.type?.includes(accountTypes.LOCATION)) {
           setLocationAccountId(parentAccount.id);
           //console.log("Location Account ID:", parentAccount.id); // Debugging
           break;
         }
       }
     }
  }, [userAccounts, accountId]); 

  const supplierOptionMap = useSelector((state) => _.keyBy(current(state, 'supplierOptions', accountId), 'id'));
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));

  //get all the orders that have a Status = SENT
  const recentOrders = useSelector((state) => {
    return _.map(
      _.reverse(
        _.sortBy(
          _.filter({ ...current(state, 'orders', accountId) },
            (order) => (![orderStatuses.DRAFT, orderStatuses.RECEIVED, orderStatuses.FINALISED].includes(order.status))),
          'date'
        )
      ),
      (item) => {
        let estimatedCost = _.reduce(item.items, (sum, ing) => {
          let ingOrdered = supplierOptionMap[ing.id];
          // Checking if ingOrdered exists
          if (!ingOrdered) {
            console.error('No supplier option found for:', ing.id);
            return sum; // Skip this iteration to prevent crashing, or handle as necessary
          }
          //console.log('this is a unitprice', ingOrdered.unitprice)
          const itemAmount = parseFloat(ing.quantity) * parseFloat(ingOrdered.unitprice || 0)
          //console.log('this is the amount', itemAmount)
          return item.type && item.type === 'CREDIT' ? sum - itemAmount : sum + itemAmount;
        }, 0);
        const date = item.deliveryDate || item.date;

        return { ...item, estimatedCost, date }
      }
    );
  });

  //Toggle an order with all the supplierOptions visible
  const [expandedRows, setExpandedRows] = useState([]);
  
  const toggleRow = (orderId) => {
    if (_.includes(expandedRows, orderId)) {
      setExpandedRows(_.without(expandedRows, orderId));
    }
    else {
      setExpandedRows(_.concat(expandedRows, orderId));
    }
  };

  //Remove an order (delete it)
  const [removingOrder, setRemovingOrder] = useState(false);

  const doOrderDelete = (order) => {
    dispatch(actions.orders.removeOrder(accountId, order.id));
  };

  //Match vs Unmatch POs
  const [matchPo, setMatchPo] = useState(null);
  const [unmatchPo, setUnmatchPo] = useState(null);
  const [showUnmatchDialog, setShowUnmatchDialog] = useState(false);

  //console.log("Current matchPo:", matchPo);
  //console.log("Current unmatchPo:", unmatchPo);

  const doUnmatchPo = (item) => {
    if (!unmatchPo) {
      console.error("UnmatchPo is null or undefined");
      return;
    }
    // Extract the original data from unmatched_data
    const originalDate = unmatchPo.unmatched_data?.date || null;
    const originalItems = unmatchPo.unmatched_data?.items || [];
  
    dispatch(actions.orders.updateOrder(accountId, {
      ...unmatchPo,
      date: originalDate, // Restore the original date
      items: originalItems, // Restore the original items
      invoice_upload_id: null, // Remove the invoice ID when unmatching
      invoice_number: "", // Reset invoice number
      subtotal: "", // Reset subtotal
      total_tax: "",
      invoice_amount: "", // Reset total tax
      status: orderStatuses.SENT,
      unmatched_data: {},
    }, () => {
      setMatchPo(null); // Reset matchPo to close and reset the "Match PO" dialog
      setUnmatchPo(null); // Reset unmatchPo to close and reset the "unmatch PO" dialog
    }));

    // Update the invoice upload status back to 'PROCESSED'
    if (unmatchPo.invoice_upload_id) {
      dispatch(actions.invoiceUploads.updateInvoiceUpload(locationAccountId, {
        id: unmatchPo.invoice_upload_id, // Use the invoice upload ID from unmatchPo
        status: 'PROCESSED'
      }));
    }
  };

  const openMatches = useCallback((po) => {
    setUnmatchPo(po); 
    setMatchPo(po); // Set the current PO for matching
  }, []);

  // When the unmatch button (-) is clicked
  const handleUnmatchClick = (po, event) => {
    setShowUnmatchDialog(true); // Show the unmatch dialog
    setUnmatchPo(po); // Set the PO to be unmatched
  };

  //Renders the primary row for each order, including supplier details and action buttons.
  const renderOrderRow = (po) => {
    const isExpanded = _.includes(expandedRows, po.id);
    return (
      <React.Fragment key={po.id}>
        <Table.Row
            key={po.id}
            isSelectable
            onSelect={() => toggleRow(po.id)}
            flexFlow={(isMobile) ? 'row wrap' : 'row nowrap'}
            height={48}
            paddingY={majorScale(1)}
        >
          <Table.TextCell
              flexGrow={3}
              flexBasis={(isMobile) ? '100%' : 'auto'}
          >{(supplierMap[po.supplierId]) ? supplierMap[po.supplierId].name : '[undefined]'}</Table.TextCell>
          <Table.TextCell flexGrow={1}>{dateYearFormat(moment(po.date, 'YYYY-MM-DD').toDate())}</Table.TextCell>
          {!isMobile && (<Table.TextCell>{(po.items) ? po.items.length : 0}</Table.TextCell>)}
          {!isMobile && (<Table.TextCell justifyContent="flex-end" textProps={{ textAlign: 'right' }}>£ {currency(po.estimatedCost)}</Table.TextCell>)}
          {account?.has_nanonets && (
            <Table.Cell flexBasis={56} flexGrow={0} flexShrink={0}>
              {po.status === orderStatuses.SENT && (
                <IconWrapper
                    name="plus"
                    appearance="clickable"
                    //intent="none"
                    onClick={() => openMatches(po)}
                />
              )}
              {po.status === orderStatuses.MATCHED && (
                <IconWrapper
                    name="minus"
                    appearance="clickable"
                    intent="danger"
                    onClick={() => handleUnmatchClick(po)}
                />
              )}
            </Table.Cell>
          )}
          <Table.Cell flexBasis={56} flexGrow={0} flexShrink={0}>
            {[orderStatuses.SENT, orderStatuses.MATCHED].includes(po.status) && (
              <IconWrapper
                  name="confirm"
                  appearance="clickable"
                  intent="none"
                  is={Link}
                  to={`/${accountId}/procurement/receive/${po.id}`}
              />
            )}
          </Table.Cell>
          <Table.Cell flexBasis={56} flexGrow={0} flexShrink={0}>
            <IconWrapper
                name="trash"
                appearance="danger"
                intent="danger"
                onClick={() => setRemovingOrder(po)}
            />
          </Table.Cell>
          <Table.Cell flexBasis={majorScale(5)} flexGrow={0} flexShrink={0}>
            <IconWrapper 
              name={isExpanded ? 'caretUp' : 'caretDown'} 
              appearance="clickable"
              marginRight={majorScale(2)}
              size={18} 
            />
          </Table.Cell>
        </Table.Row>
        {isExpanded && (
          <Table.Row
              key={`${po.id}-details`}
              height={'auto'}
              paddingLeft={(isMobile) ? majorScale(4) : majorScale(8)}
              paddingRight={(isMobile) ? 0 : majorScale(8)}
          >
            {renderOrderDetails(po.items)}
          </Table.Row>
        )}
      </React.Fragment>
    );
  };

  //Component to get the order details
  const renderOrderDetails = (items) => {
    return (
      <Table flex="1 0 auto">
        <Table.Head>
          <Table.TextHeaderCell flexBasis={5}>Name</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={5}>UOM</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={1} justifyContent="flex-end" textProps={{ textAlign: 'right' }}>Qty</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.map(items, (orderItem) => {
            const option = supplierOptionMap[orderItem.id];
            return (
              <Table.Row key={option.id}>
                <Table.TextCell flexBasis={5}>{option.name}</Table.TextCell>
                <Table.TextCell flexBasis={5}>{supplierOptionUOM(option)}</Table.TextCell>
                <Table.TextCell flexBasis={1} justifyContent="flex-end" textProps={{ textAlign: 'right' }}>{orderItem.quantity}</Table.TextCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  //Putting everything together for that page
  return (
    <React.Fragment>
      {matchPo && <MatchesDialog matchPo={matchPo} accountId={accountId} setMatchPo={setMatchPo} />}
      <CustomDialog
          isOpen={!!removingOrder}
          title="Delete PO"
          intent="danger"
          confirmLabel="Delete Permanently"
          onClose={() => setRemovingOrder(false)}
          onConfirm={() => {
            doOrderDelete(removingOrder);
            setRemovingOrder(false);
          }}
      >
        {!!removingOrder && (
          <Text>Are you sure you wish to delete the order for {(supplierMap[removingOrder.supplierId]) ? supplierMap[removingOrder.supplierId].name : '<Unknown Supplier>'} dated {shortDateDisplayFormat(moment(removingOrder.date, 'YYYY-MM-dd').toDate())}?</Text>
        )}
      </CustomDialog>

      <CustomDialog
        isOpen={showUnmatchDialog}
        title="Unmatch PO"
        intent="danger"
        confirmLabel="Unmatch"
        onClose={() => {
          console.log("Unmatch Dialog closed, resetting showUnmatchDialog");
          setShowUnmatchDialog(false)
        }}
        onConfirm={() => {
          doUnmatchPo();
          setShowUnmatchDialog(false); // Close the dialog after confirming unmatch
        }}
      >
        <Pane marginBottom={majorScale(2)}>
          <Text>Are you sure you wish to unmatch this PO?</Text>
        </Pane>
      </CustomDialog>

      <Block heigh={"500px"}>
        <CustomHeading level="3" paddingLeft={majorScale(1)} margin={majorScale(2)}>Issued POs</CustomHeading>
        <Table>
          <Table.Head
              paddingRight={0}
              flexFlow={(isMobile) ? 'row wrap' : 'row nowrap'}
              height={48}
              paddingY={majorScale(1)}
          >
            <Table.TextHeaderCell
                flexGrow={3}
                flexBasis={(isMobile) ? '100%' : 'auto'}
            >Supplier</Table.TextHeaderCell>
            <Table.TextHeaderCell flexGrow={1}>Selected Date</Table.TextHeaderCell>
            {!isMobile && (<Table.TextHeaderCell>Nb Items</Table.TextHeaderCell>)}
            {!isMobile && (<Table.TextHeaderCell justifyContent="flex-end" textProps={{ textAlign: 'right' }}>Est. Cost</Table.TextHeaderCell>)}
            {account?.has_nanonets && (
              <Table.TextHeaderCell flexBasis={56} flexGrow={0} flexShrink={0}>Match</Table.TextHeaderCell>
            )}
            <Table.TextHeaderCell flexBasis={56} flexGrow={0} flexShrink={0}>Receive</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0} flexShrink={0}>Delete</Table.TextHeaderCell>
            <Table.TextHeaderCell
                flexBasis={majorScale(5)}
                flexGrow={0}
                flexShrink={0}
            ></Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(recentOrders) && (
              <Table.Row height={48}>
                <Table.TextCell>No orders to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(recentOrders, renderOrderRow)}
          </Table.Body>
        </Table>
      </Block>
    </React.Fragment>
  );
};

RecentOrders.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default RecentOrders;
