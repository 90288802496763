//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { Card, majorScale, Pane, Text } from 'evergreen-ui';

//Pages
//import Account from '../../pages/Account/Account';
//import AccountCreate from '../../pages/AccountCreate/AccountCreate';

//Components
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import CustomHeading from '../../components/Headings/Headings';
import EntityList from '../../components/EntityList.js/EntityList';
import CustomDialog from '../../components/Dialog/Dialog';

//Files
import { accountTypes } from '../../utils/constants';
import { actions } from '../../store/actions';
import useAuth from 'hooks/useAuth';

const LocationSelect = () => {
  const { hasOwnership } = useAuth();
  const navigate = useNavigate();
  const [isDialogShown, setIsDialogShown] = useState(false);
  const accountId = useSelector((state) => state.currentAccount);
  console.log(accountId, "ACCONTSELECT")

  const locations = useSelector((state) => {
    const currentAccount = state.currentAccount;
    return _.sortBy(
      _.filter(
        state.accounts,
        (dept) => (
          (dept.type === accountTypes.LOCATION) && 
          _.includes(dept.parents, currentAccount)
        )
      ),
      'name'
    );
  });

  return (
    <>
      <Page>
        <Block padding={majorScale(2)}>
          <CustomHeading level="3" marginBottom={majorScale(2)}>Locations</CustomHeading>
          <Pane
              display="grid"
              alignItems="stretch"
              gridTemplateColumns="repeat(3, 1fr)"
              columnGap={majorScale(2)}
              rowGap={majorScale(2)}
              marginBottom={majorScale(2)}
          >
            {_.isEmpty(locations) && (
              <Text>No items to display</Text>
            )}
            {_.map(locations, (loc) => (
              <Card
                  key={loc.id}
                  border="default"
              >
                <Pane
                    paddingTop={majorScale(2)}
                    paddingX={majorScale(2)}
                >
                  <CustomHeading level="3">{loc.name}</CustomHeading>
                </Pane>
                <Pane padding={majorScale(2)}>
                  <Button
                      appearance="minimal"
                      onClick={() => {
                        navigate(`/locations/${loc.id}/account`);
                      }}
                  >Access Location</Button>
                </Pane>
              </Card>
            ))}
          </Pane>
          {hasOwnership() && (
            <Pane display="flex" justifyContent="flex-start" alignItems="center">
              <Pane>
                <Button
                    appearance="primary"
                    is={Link}
                    to="/locations/new"
                    width="150px"
                >
                  Create location
                </Button>
              </Pane>
              <Pane padding={majorScale(2)}>
                <Button 
                  appearance="primary" 
                  onClick={() => navigate(`/locations/manage-areas`)}
                  width="150px"
                >
                  Manage areas
                </Button>
              </Pane>
            </Pane>
          )}
        </Block>
      </Page>
    </>
  );
};

export default LocationSelect;
