const RECIPE_CATEGORIES_LOAD_SUCCESS = 'RECIPE_CATEGORIES_LOAD_SUCCESS';
const RECIPE_CATEGORIES_LOAD_FAILURE = 'RECIPE_CATEGORIES_LOAD_FAILURE';
const RECIPE_CATEGORIES_CLEAR = 'RECIPE_CATEGORIES_CLEAR';
const ADD_RECIPE_CATEGORY = 'ADD_RECIPE_CATEGORY';
const REMOVE_RECIPE_CATEGORY = 'REMOVE_RECIPE_CATEGORY';
const UPDATE_RECIPE_CATEGORY = 'UPDATE_RECIPE_CATEGORY';

export const actionTypes = {
  RECIPE_CATEGORIES_LOAD_SUCCESS,
  RECIPE_CATEGORIES_LOAD_FAILURE,
  RECIPE_CATEGORIES_CLEAR,
  ADD_RECIPE_CATEGORY,
  REMOVE_RECIPE_CATEGORY,
  UPDATE_RECIPE_CATEGORY
};


const recipeCategoriesLoadSuccess = (payload, account) => {
  return {
    type: RECIPE_CATEGORIES_LOAD_SUCCESS,
    payload,
    account
  };
};

const recipeCategoriesLoadFailure = (error) => {
  return {
    type: RECIPE_CATEGORIES_LOAD_FAILURE,
    error
  };
};

const recipeCategoriesClear = () => {
  return {
    type: RECIPE_CATEGORIES_CLEAR
  };
};

const addCategory = (accountId, categoryInfo, callback) => {
  return {
    type: ADD_RECIPE_CATEGORY,
    categoryInfo,
    callback,
    accountId,
  };
};

const removeCategory = (accountId, categoryId) => {
  return {
    type: REMOVE_RECIPE_CATEGORY,
    accountId,
    categoryId,
  };
};

const updateCategory = (accountId, categoryInfo, callback) => {
  return {
    type: UPDATE_RECIPE_CATEGORY,
    categoryInfo,
    accountId,
    callback,
  };
};

export const actions = {
  recipeCategoriesLoadSuccess,
  recipeCategoriesLoadFailure,
  recipeCategoriesClear,
  addCategory,
  removeCategory,
  updateCategory
};
