import { getCollectionRef } from './common';

export const addPrepTask = (task, accountId) => {
  const prepTasksRef = getCollectionRef('prepTasks', accountId);
  return prepTasksRef.add(task);
};

export const removePrepTask = (taskId, accountId) => {
  const prepTasksRef = getCollectionRef('prepTasks', accountId);
  return prepTasksRef.doc(taskId).delete();
};

export const updatePrepTask = (taskId, task, accountId) => {
  const prepTasksRef = getCollectionRef('prepTasks', accountId);
  return prepTasksRef.doc(taskId).update(task);
};
