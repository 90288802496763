const TRANSFERS_LOAD_SUCCESS = 'TRANSFERS_LOAD_SUCCESS';
const TRANSFERS_LOAD_FAILURE = 'TRANSFERS_LOAD_FAILURE';
const TRANSFERS_CLEAR = 'TRANSFERS_CLEAR';
const ADD_TRANSFER = 'ADD_TRANSFER';
const REMOVE_TRANSFER = 'REMOVE_TRANSFER';
const UPDATE_TRANSFER = 'UPDATE_TRANSFER';

export const actionTypes = {
  TRANSFERS_LOAD_SUCCESS,
  TRANSFERS_LOAD_FAILURE,
  TRANSFERS_CLEAR,
  ADD_TRANSFER,
  REMOVE_TRANSFER,
  UPDATE_TRANSFER
};


const transfersLoadSuccess = (payload, account) => {
  return {
    type: TRANSFERS_LOAD_SUCCESS,
    payload,
    account
  };
};

const transfersLoadFailure = (error) => {
  return {
    type: TRANSFERS_LOAD_FAILURE,
    error
  };
};

const transfersClear = () => {
  return {
    type: TRANSFERS_CLEAR
  };
};

const addTransfer = (accountId, transfer) => {
  return {
    type: ADD_TRANSFER,
    transfer,
    accountId,
  };
};

const removeTransfer = (accountId, transferId) => {
  return {
    type: REMOVE_TRANSFER,
    transferId,
    accountId,
  };
};

const updateTransfer = (accountId, transfer) => {
  return {
    type: UPDATE_TRANSFER,
    transfer,
    accountId,
  };
};

export const actions = {
  transfersLoadSuccess,
  transfersLoadFailure,
  transfersClear,
  addTransfer,
  removeTransfer,
  updateTransfer
};
