import { getCollectionRef } from './common'

export const addDepartment = (department, accountId) => {
  const departmentsRef = getCollectionRef('departments', accountId);
  return departmentsRef.add(department);
};

export const removeDepartment = (departmentId, accountId) => {
  const departmentsRef = getCollectionRef('departments', accountId);
  return departmentsRef.doc(departmentId).delete();
};

export const updateDepartment = (departmentId, department, accountId) => {
  const departmentsRef = getCollectionRef('departments', accountId);
  return departmentsRef.doc(departmentId).update(department);
};
