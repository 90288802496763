const ORDER_RECORDS_LOAD_SUCCESS = 'ORDER_RECORDS_LOAD_SUCCESS';
const ORDER_RECORDS_CLEAR = 'ORDER_RECORDS_CLEAR';
const ADD_ORDER_RECORD = 'ADD_ORDER_RECORD';
const REMOVE_ORDER_RECORD = 'REMOVE_ORDER_RECORD';
const UPDATE_ORDER_RECORD = 'UPDATE_ORDER_RECORD';

export const actionTypes = {
  ORDER_RECORDS_LOAD_SUCCESS,
  ORDER_RECORDS_CLEAR,
  ADD_ORDER_RECORD,
  REMOVE_ORDER_RECORD,
  UPDATE_ORDER_RECORD
};


const orderRecordsLoadSuccess = (payload, account) => {
  return {
    type: ORDER_RECORDS_LOAD_SUCCESS,
    payload,
    account
  };
};

const orderRecordsClear = () => {
  return {
    type: ORDER_RECORDS_CLEAR
  };
};

const addOrderRecord = (accountId, record) => {
  return {
    type: ADD_ORDER_RECORD,
    record,
    accountId,
  };
};

const removeOrderRecord = (accountId, recordId) => {
  return {
    type: REMOVE_ORDER_RECORD,
    recordId,
    accountId,
  };
};

const updateOrderRecord = (accountId, record) => {
  return {
    type: UPDATE_ORDER_RECORD,
    record,
    accountId,
  };
};

export const actions = {
  orderRecordsLoadSuccess,
  orderRecordsClear,
  addOrderRecord,
  removeOrderRecord,
  updateOrderRecord
};
