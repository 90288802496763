//React
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import moment from 'moment';

//Components
import DatePanelReport from './components/DatePanel';
import SummaryPage from './OverviewComponents/SummaryPage';
import DetailedBreakdown from './OverviewComponents/DetailedBreakdown';
import Page from '../../components/Page/Page';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../components/ui/Tab/Tab';

//Files
import { getWE, getWC, TODAYC, TODAYE, minDate } from '../../utils/time';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';

const tabs = {
  'summary': {
    label: 'Summary',
    Component: SummaryPage,
  },
  'detailed': {
    label: 'Detailed',
    Component: DetailedBreakdown,
  },
};

const OverviewReports = () => {
  const { accountId } = useParams();
  const { requiresWorkingAccount } = useConditionalRedirect();
  requiresWorkingAccount();

  const [weekStart, setWeekStart] = useState(getWC(TODAYC));
  const weekEnd = minDate(getWE(weekStart), TODAYE);
  const prevWeekStart = moment.utc(weekStart).subtract(1, 'weeks').startOf('isoWeek');
  const prevWeekEnd = moment.utc(prevWeekStart).endOf('isoWeek');

  const [activeTab, setActiveTab] = useState('summary');
  const ActiveTabComponent = tabs[activeTab].Component;

  const handleWeekChange = (newStart) => {
    const updatedWeekStart = getWC(newStart);
    setWeekStart(updatedWeekStart);
  };

  //console.log(weekStart)
  //console.log(weekEnd)
  
  return (
    <React.Fragment>
      <Page>
        <DatePanelReport onWeekChange={handleWeekChange}/>
        <TabNavigation>
          {Object.entries(tabs).map(([key, { label }]) => (
            <Tab
              key={key}
              isSelected={activeTab === key}
              onSelect={() => setActiveTab(key)}
              children={label}
            />
          ))}
        </TabNavigation>

        {/* Render the component corresponding to the active tab */}
        <ActiveTabComponent 
          weekStart={moment.utc(weekStart).format('YYYY-MM-DD')}
          weekEnd={moment.utc(weekEnd).format('YYYY-MM-DD')} 
          prevWeekStart={prevWeekStart.format('YYYY-MM-DD')}
          prevWeekEnd={prevWeekEnd.format('YYYY-MM-DD')}
          accountId={accountId} />
        
      </Page>
    </React.Fragment>
  );
};

export default OverviewReports;
