//React
import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Checkbox, FormField, majorScale, Pane, SelectField, useTheme, TextInputField, Text, toaster } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import DataTable from '../../components/DataTable/DataTable';
import FilterBar from '../../components/FilterBar/FilterBar';
import IngredientEditForm from '../../pages/Ingredients/IngredientEditForm';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';
import CustomDialog from '../../components/Dialog/Dialog';

//Files
import { accountTypes, TABLE_MIN_HEIGHT } from '../../utils/constants';
import { current, selectIngredientCategoryHierarchy } from '../../utils/selectors';
import { formatDate } from '../../utils/format';
import { actions } from '../../store/actions';
import { generateSearchFieldsFn } from '../../utils/functions';
import { filterActiveIngredients } from '../../utils/ingredients';

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 6 },
  { label: 'UID', field: 'itemcode', type: 'text', width: 4 },
  { label: 'Category', field: 'category', type: 'text', width: 4 },
];

//TODO - When click save, don't update the state
//if ingredient is Archive and defaultOption, then update defaultOption for Ingredient
//Avoid having to change page to see changes
const SupplierOptionEdit = (props) => {
  const { supplierId, optionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme()

  const account = useSelector(state => state.accounts[state.currentAccount]);
  //See if needs to be re-implemented
  const isMepSupplier = account?.type?.includes(accountTypes.SUPPLIER);

  const allergenList = useSelector((state) => _.sortBy(state.allergens, 'name'));
  const ingredientList = useSelector((state) => _.keyBy(filterActiveIngredients(current(state, 'ingredients')), 'id'));

  const supplier = useSelector((state) => {
    return _.find(current(state, 'suppliers'), { 'id': supplierId });
  });

  const supplierOption = useSelector((state) => {
    if (optionId) {
      return _.find(current(state, 'supplierOptions'), { 'id': optionId });
    }
    return {};
  });

  const categoryList = useSelector(selectIngredientCategoryHierarchy);

  const getCategoryName = () => {
    if (newIngInfo && Object.keys(newIngInfo).length > 0) {
        const category = categoryList.find(cat => cat.id === newIngInfo.categoryId);
        return category ? category.name : '';
    } else if (optionInfo.ingredientId && ingredientList[optionInfo.ingredientId]) {
        const category = categoryList.find(cat => cat.id === ingredientList[optionInfo.ingredientId].categoryId);
        return category ? category.name : '';
    }
    return '';
  };
  
  const supplierCategories = useSelector(state => current(state, 'supplierCategories'));

  // Safely access ingredientId, returning null if supplierOption is not an object or ingredientId is not defined
  const ingredientInit = supplierOption && supplierOption.ingredientId ? ingredientList[supplierOption.ingredientId] : null;

  const [optionInfo, setOptionInfo] = useState({
      ...supplierOption,
      vat: supplierOption?.vat ||  0,
      base_uom: ingredientInit?.recipeunit || supplierOption?.base_uom || 'unit',
      categoryId: ingredientInit?.categoryId || supplierOption?.categoryId || ''
    } ||
    {}
  );

  const ingredient = optionInfo.ingredientId ? ingredientList[optionInfo.ingredientId] : null;

  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [linkedIngredient, setLinkedIngredient] = useState(null);
  const [newIngInfo, setNewIngInfo] = useState(null);
  const [tempNewIngInfo, setTempNewIngInfo] = useState(null);
  
  const [ingFilters, setIngFilters] = useState({});
  const [errors, setErrors] = useState({});

  const pageTitle = (supplierOption) ? supplierOption.name : 'New supplier option';
  const returnUrl = `/suppliers/${supplierId}/database`;
  const isSlave = supplierOption?.type?.includes('SLAVE');

  const onFieldChange = (fieldname, value, intOnly = false) => {
    if (intOnly && value !== '' && isNaN(value)) return;
  
    if (fieldname === 'unitprice') {
      value = enforceNumeric(value);
    }

    if (fieldname === 'vat') {
      value = Number(value); 
    }
    
    setOptionInfo((prevValues) => ({
      ...prevValues,
      [fieldname]: value,
    }));
    
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldname]: undefined,
    }));
  };

  const updateFilters = (filterName, value) => {
    setIngFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  const toggleAllergen = (allergenName, include) => {
    setOptionInfo((prevValues) => {
      let allergens = prevValues.allergens || [];
      if (include) {
        // Add the allergen to the list
        allergens = _.uniq(_.concat(allergens, allergenName));
      }
      else {
        // Remove the allergen from the list
        allergens = _.without(allergens, allergenName);
      }
      return { ...prevValues, allergens };
    });
  };

  const saveSupplierOption = async () => {
    if (!validateSupplierOption()) {
      dispatch(actions.appMessageError('Please enter all required fields.'));
      console.log('Validation failed');
      return;
    }
  
    const oldIngredientId = optionInfo.ingredientId;
    let newIngredientId = null;
    let newIng = null;
  
    // If there's an existing ingredient and it's not the same as the new linked ingredient, remove the supplier option from it
    if (oldIngredientId && newIngInfo && oldIngredientId !== newIngInfo?.id && !isMepSupplier) {
        dispatch(actions.supplierOptions.removeSupplierOptionFromCollection(account.id, oldIngredientId, optionId, false));
        console.log('Supplier option removed from existing ingredient:', oldIngredientId);
    }
  
    // Check if there's new ingredient info or create a new ingredient from optionInfo
    if (newIngInfo && !newIngInfo.id && !isMepSupplier) {
      // Create the new ingredient and get the ID
      newIng = {
          name: newIngInfo.name,
          recipeunit: newIngInfo.recipeunit,
          categoryId: newIngInfo.categoryId || null,
          supplierOptions: [],
      };
      await new Promise((resolve) => {
          dispatch(actions.ingredients.addIngredient(newIng, (ingredient) => {
              newIngredientId = ingredient.id;
              newIng = ingredient;
              console.log('New ingredient created with ID:', newIngredientId);
              setOptionInfo((prev) => ({
                  ...prev,
                  ingredientId: newIngredientId,
              }));
              resolve();
          }));
      });
    } else if (!isMepSupplier && !ingredient && !newIngInfo) {
        // Create a new ingredient from optionInfo if no ingredient exists
        newIng = {
            name: optionInfo.name,
            recipeunit: optionInfo.base_uom,
            categoryId: null,
            supplierOptions: [],
        };
        await new Promise((resolve) => {
            dispatch(actions.ingredients.addIngredient(newIng, (ingredient) => {
                newIngredientId = ingredient.id;
                newIng = ingredient;
                console.log('New ingredient created with ID:', newIngredientId);
                setOptionInfo((prev) => ({
                    ...prev,
                    ingredientId: newIngredientId,
                }));
                resolve();
            }));
        });
    } else if (newIngInfo && newIngInfo.id) {
      // If the linked ingredient has an ID, update the ingredientId to the new one
      newIngredientId = newIngInfo.id;
    }

    // Ensure the base_uom is set to the current recipeunit
    const updatedOptionInfo = {
      ...optionInfo,
      ingredientId: isMepSupplier ? null : newIngredientId ? newIngredientId : oldIngredientId,
      base_uom: newIng ? newIng.recipeunit : newIngInfo ? newIngInfo.recipeunit : optionInfo.base_uom,
      categoryId: newIng ? newIng.categoryId : newIngInfo ? newIngInfo.categoryId : optionInfo.categoryId,
      archived: false,
    };
    console.log('Updated supplier option info:', updatedOptionInfo);
  

    const updateIngredientIfNeeded = async (ing) => {
      console.log('Checking if ingredient needs update:', ing);
  
      const needsRecipeUnitUpdate = optionInfo.base_uom !== ing?.recipeunit;
      const needsCategoryIdUpdate = optionInfo.categoryId !== ing?.categoryId;
      const needsSupplierOptionUpdate = !ing.supplierOptions || !ing.supplierOptions.some(opt => opt.id === optionId);
  
      if (needsRecipeUnitUpdate || needsCategoryIdUpdate || needsSupplierOptionUpdate) {
          // Initialize supplierOptions if it's not already
          if (!ing.supplierOptions) {
              ing.supplierOptions = [];
          }

          // If there are existing supplier options, determine if any is set to default
          const existingDefaultOption = ing.supplierOptions.some(opt => opt.defaultOption);

          // Push the new supplier option if needed
          if (needsSupplierOptionUpdate) {
              ing.supplierOptions.push({
                  id: optionId,
                  name: optionInfo.name,
                  defaultOption: !existingDefaultOption // Set to true only if no other default exists
              });
          }
  
          const updatedIngredient = {
              ...ing,
              id: ing.id,
              recipeunit: needsRecipeUnitUpdate ? optionInfo.base_uom : ing.recipeunit,
              categoryId: needsCategoryIdUpdate ? optionInfo.categoryId : ing.categoryId,
              supplierOptions: ing.supplierOptions,
              archived: false,
          };

          console.log('Updated Ingredient:', updatedIngredient);
          await dispatch(actions.ingredients.updateIngredient(updatedIngredient));
      } else {
          console.log('No update needed for ingredient:', ing);
      }
    };  
  
    if (optionId) {
      // Update an existing supplier option
      console.log('Updating existing supplier option:', optionId);
      await new Promise((resolve) => {
        dispatch(actions.supplierOptions.updateSupplierOption({ id: optionId, ...updatedOptionInfo }, newIng, async () => {
        console.log('Supplier option updated, navigating to return URL');

          // Exit early if newIngInfo is null
          if (!newIngInfo && isMepSupplier) {
            console.log('newIngInfo is null, exiting without updating the ingredient.');
            navigate(returnUrl); 
            resolve();
            return;
          }

          // Update the newly created ingredient with the supplier option ID
          if (newIng) {
            newIng.id = newIngredientId;
            console.log('Updating new ingredient with supplier option ID:', newIng);
            await updateIngredientIfNeeded(newIng);
          } else {
            console.log('Updating existing ingredient:', newIngInfo);
            await updateIngredientIfNeeded(newIngInfo);
          }
          navigate(returnUrl);
          resolve();
        }));
      });

    } else if (!optionId) {
      // Add a new supplier ingredient
      console.log('Adding new supplier ingredient');
      await new Promise((resolve) => {
        dispatch(actions.supplierOptions.addSupplierOption({ ...updatedOptionInfo, supplierId }, newIng, async (id) => {
          const newOptionId = id;
          console.log('New supplier ingredient added with ID:', newOptionId, 'navigating to return URL');
  
          // Directly update the ingredient with the new supplier option ID
          if (newIng) {
            // Ensure supplierOptions exists and add the new supplier option ID
            newIng.supplierOptions = [
                ...(newIng.supplierOptions || []),
                {
                    id: newOptionId,
                    name: optionInfo.name,
                    defaultOption: true,
                }
            ];

            console.log('Directly updating ingredient with new supplier option ID:', newIng);

            // Directly call the updateIngredient function
            await dispatch(actions.ingredients.updateIngredient(newIng));
          } else {
              console.log('Directly updating existing ingredient:', newIngInfo);

              // Similarly, update the existing ingredient info directly
              await dispatch(actions.ingredients.updateIngredient(newIngInfo));
        }
  
            navigate(returnUrl);
            resolve();
          }));
      });
    } else {
      console.log('No changes detected, skipping update.');
      navigate(returnUrl);
    }
  };

  const saveIngredient = (info) => {
    setNewIngInfo(info);
  };

  const enforceNumeric = (value) => {
    const validValue = value.replace(/[^0-9.]/g, '');
    const dotIndex = validValue.indexOf('.');
  
    if (dotIndex !== -1) {
      const beforeDot = validValue.slice(0, dotIndex + 1);
      const afterDot = validValue.slice(dotIndex + 1).replace(/\./g, '').slice(0, 2);
      return beforeDot + afterDot;
    }
  
    return validValue;
  };

  const validateSupplierOption = () => {
    const newErrors = {};
  
    if (!optionInfo.name || optionInfo.name.trim() === '') {
      newErrors.name = 'Name is required.';
    }
    if (!optionInfo.supplier_code || optionInfo.supplier_code.trim() === '') {
      newErrors.supplier_code = 'Supplier Code is required.';
    }
    if (!optionInfo.unitprice) {
      newErrors.unitprice = 'Unit Price is required.';
    } else if (isNaN(optionInfo.unitprice) || !isFinite(optionInfo.unitprice)) {
      newErrors.unitprice = 'Unit Price must be a valid number.';
    }
    if (!optionInfo.gramperportion) {
      newErrors.gramperportion = 'Grams per portion is required.';
    } else if (isNaN(optionInfo.gramperportion) || !isFinite(optionInfo.gramperportion)) {
      newErrors.gramperportion = 'Grams per portion must be a valid number.';
    }
    if (!optionInfo.numperportion) {
      newErrors.numperportion = 'Number per portion is required.';
    } else if (isNaN(optionInfo.numperportion) || !isFinite(optionInfo.numperportion)) {
      newErrors.numperportion = 'Number per portion must be a valid number.';
    }
    if (!optionInfo.uom || optionInfo.uom.trim() === '') {
      newErrors.uom = 'Unit of Measure (UOM) is required.';
    }
    if (!optionInfo.base_uom || optionInfo.base_uom.trim() === '') {
      newErrors.base_uom = 'Base UOM is required.';
    }
    if (optionInfo.vat === null || optionInfo.vat === undefined || isNaN(optionInfo.vat)) {
      newErrors.vat = 'VAT is required and must be a valid number.';
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length > 0) {
      toaster.danger(
        <div>
          {Object.values(newErrors).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      );
      return false;
    }
  
    return true;
  };

  const backNav = () => {
    window?.history.back() || navigate(returnUrl);
  }

  const lastEditedText = () => {
    if (supplierOption?.updatedBy || supplierOption?.createdBy) {
      const editedBy = supplierOption?.updatedBy ? supplierOption?.updatedBy : supplierOption.createdBy;
      const editedAt = supplierOption?.updatedAt ? formatDate(supplierOption?.updatedAt) : formatDate(supplierOption?.createdAt);
      if (editedAt) {
        return `Last edited by: ${editedBy} on: ${editedAt}`;
      }
    }
    return '';
  };

  return (
    <Page title={pageTitle}>
      <>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(2)}
      >
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center">
            <IconWrapper
                appearance="minimal"
                onClick={backNav}
                name="arrowLeft"
                marginRight={majorScale(2)}
            />
            <CustomHeading level="3" flex="1 0 auto">{pageTitle}</CustomHeading>
          </Pane>
          {lastEditedText() && (
            <Text marginTop={majorScale(1)}>{lastEditedText()}</Text>
          )}
        </Pane>
        <Pane display="flex" alignItems="center">
          <Button
              appearance="primary"
              marginLeft={majorScale(2)}
              onClick={saveSupplierOption}
          >Save</Button>
        </Pane>
      </Block>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level='4' marginBottom={majorScale(2)}>Supplier option</CustomHeading>
        <Pane
          display="grid"
          alignItems="center"
          gridTemplateColumns="auto auto auto auto"
          columnGap={majorScale(2)}
        >
          <TextInputField
              required
              isInvalid={!!errors.name}
              label="Supplier option name"
              value={optionInfo.name || ''}
              onChange={(e) => onFieldChange('name', e.target.value)}
              gridColumnEnd="span 3"
              disabled={isSlave}
          />
          <TextInputField
              required
              isInvalid={!!errors.supplier_code}
              label="Supplier code"
              value={optionInfo.supplier_code || ''}
              onChange={(e) => onFieldChange('supplier_code', e.target.value)}
              disabled={isSlave}
          />
          <TextInputField
              label="Supplier"
              gridColumnEnd="span 2"
              value={supplier?.name || ''}
              disabled
          />
          <TextInputField
              required
              isInvalid={!!errors.unitprice}
              label="Unit price"
              value={optionInfo.unitprice || ''}
              onChange={(e) => onFieldChange('unitprice', e.target.value, true)}
          />
          <SelectField
              required
              isInvalid={!!errors.vat}
              label="VAT"
              value={optionInfo.vat}
              onChange={(e) => onFieldChange('vat', e.target.value)}
              disabled={isSlave}
          >
            <option value={0}>0%</option>
            <option value={20}>20%</option>
          </SelectField>
          <TextInputField
              required
              isInvalid={!!errors.uom}
              label="Supplier UOM"
              value={optionInfo.uom || ''}
              onChange={(e) => onFieldChange('uom', e.target.value)}
              disabled={isSlave}
          />
          <TextInputField
              required
              isInvalid={!!errors.numperportion}
              label="Units per lot"
              value={optionInfo.numperportion || ''}
              onChange={(e) => onFieldChange('numperportion', e.target.value, true)}
          />
          <TextInputField
              required
              isInvalid={!!errors.gramperportion}
              label="g/ml/units per item"
              value={optionInfo.gramperportion || ''}
              onChange={(e) => onFieldChange('gramperportion', e.target.value, true)}
          />
          <SelectField
              required
              isInvalid={!!errors.base_uom }
              label="Base UOM"
              value={optionInfo.base_uom || 'unit'}
              onChange={(e) => onFieldChange('base_uom', e.target.value)}
          >
            <option value="g">g</option>
            <option value="ml">ml</option>
            <option value="unit">unit</option>
          </SelectField>
          {isMepSupplier && (
            <SelectField
                label={"Category"}
                value={optionInfo.categoryId}
                onChange={(e) => onFieldChange('categoryId', e.target.value)}
            >
                <option value="">Please Select...</option>
                {(supplierCategories).map(cat =>
                  <option key={cat.id} value={cat.id}>{cat.name}</option>
                )}
            </SelectField>
          )}
        </Pane>
      </Block>
      
      {!isMepSupplier && (
        <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
          <CustomHeading level='4' marginBottom={majorScale(2)}>Ingredient linked to</CustomHeading>
          <Pane
            display="grid"
            alignItems="center"
            gridTemplateColumns="auto auto auto auto"
            columnGap={majorScale(2)}
          >
            <Pane 
              display='flex' 
              gridColumnEnd="span 2" 
              alignItems="center"
              justifyContent='space-around'
            >
              <TextInputField
                label="Ingredient name"
                value={newIngInfo?.name || (optionInfo.ingredientId && ingredientList[optionInfo.ingredientId]?.name) || ''}
                disabled
                flex="0.95"
                marginRigh={majorScale(2)}
              />
              {(newIngInfo?.name || optionInfo.ingredientId) && (
                  <IconWrapper
                      appearance="minimal"
                      as={Link}
                      size={20}
                      to={`/ingredients/${ingredient?.id}`}
                      name="eye"
                      color={theme.colors.black}
                      flexShrink={0}
                  />
              )}
            </Pane>
            <TextInputField
              label="Recipe UOM"
              value={newIngInfo?.recipeunit || (optionInfo?.ingredientId && ingredientList[optionInfo.ingredientId]?.recipeunit) || ''}
              disabled
            />
            
            <TextInputField
              label={'Ingredient category'}
              value={getCategoryName()}
              disabled
            />
          </Pane>
          <Pane display='flex' alignItems='center' justifyContent='flex-start' marginBottom={majorScale(2)}>
              <Button
                appearance='primary'
                //marginLeft={(optionInfo.ingredientId && ingredientList[optionInfo.ingredientId]) ? majorScale(2) : 0}
                onClick={() => setLinkDialogOpen(true)}
              >
                Link to ingredient
              </Button>
              <Button
                appearance='primary'
                marginLeft={majorScale(2)}
                onClick={() => {
                  setTempNewIngInfo({});
                  setCreateDialogOpen(true);
                }}
              >
                Create new ingredient
              </Button>
          </Pane>
        </Block>
      )}

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level='4' marginBottom={majorScale(1)}>Allergens</CustomHeading>
        <FormField>
          <Pane
              display="flex"
              flexFlow="row wrap"
              alignItems="flex-start"
          >
            {allergenList.map((allergen) => {
              const isChecked = optionInfo.allergens && (_.includes(optionInfo.allergens, allergen.name));
              return (
                <Checkbox
                    key={allergen.id}
                    label={allergen.name}
                    checked={isChecked}
                    onChange={(e) => toggleAllergen(allergen.name, e.target.checked)}
                    flexBasis="15%"
                    marginRight={majorScale(2)}
                />
              );
            })}
          </Pane>
        </FormField>
      </Block>

      <CustomDialog
          isOpen={linkDialogOpen}
          title="Link to ingredient"
          confirmLabel="Link"
          isConfirmDisabled={!linkedIngredient}
          width='700px'
          onConfirm={() => {
            //onFieldChange('ingredientId', linkedIngredient.id);
            setNewIngInfo(linkedIngredient);
            setLinkedIngredient(null);
            setLinkDialogOpen(false);
          }}
          onClose={() => setLinkDialogOpen(false)}
          onCloseComplete={() => setLinkDialogOpen(false)}
          contentContainerProps={{ padding: 0 }}
      >
        <Pane marginBottom={majorScale(2)}>
          <FilterBar
              searchPlaceholder="Search for an Ingredient"
              searchOnChange={searchOnChange}
              marginBottom={majorScale(2)}
          />
          <DataTable
              tableProps={{ minHeight: TABLE_MIN_HEIGHT, }}
              headers={tableHeaders}
              items={ingredientList}
              filters={ingFilters}
              onRowSelect={(item) => setLinkedIngredient(item)}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={createDialogOpen}
          title="New ingredient"
          confirmLabel="Add ingredient"
          width='700px'
          onConfirm={() => {
            saveIngredient(tempNewIngInfo);
            setCreateDialogOpen(false);
          }}
          onClose={() => setCreateDialogOpen(false)}
          onCloseComplete={() => setCreateDialogOpen(false)}
      >
        <IngredientEditForm ingInfo={tempNewIngInfo} onChange={setTempNewIngInfo} displayAllergens={false} />
      </CustomDialog>
      </>
    </Page>
  );
};

SupplierOptionEdit.propTypes = {
  optionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  supplierId: PropTypes.string.isRequired,
};

SupplierOptionEdit.defaultProps = {
  optionId: false,
};

export default SupplierOptionEdit;
