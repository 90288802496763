//React
import React from 'react';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Accordion from '../../../components/ui/Accordion/Accordion';
import ValuesPane from './ValuesPane';

//Files
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';


const LivestockCategory = ({ tableHeaders, mobileTableHeaders, categoryOpt, isMobile, filters, isOpen, setCategory, items, isSearching }) => {
  const catItems = items.filter(i => i.categoryId === categoryOpt.id);
  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt.value);
  return (
    <Accordion
      label={categoryOpt.label}
      toggleOpen={toggleOpen}
      isOpen={isOpen || (isSearching && !_.isEmpty(catItems))}
      displayBottom={<ValuesPane ings={catItems} tableHeaders={tableHeaders} mobileTableHeaders={mobileTableHeaders} isMobile={isMobile} justify />}
    >
      <Pane>
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
        >
          <DataTable
            items={catItems}
            filters={filters}
            headers={(isMobile) ? mobileTableHeaders : tableHeaders}
            tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
          />
        </Pane>
      </Pane>
    </Accordion>
  );
}

export default LivestockCategory;
