import _ from 'lodash';
import { useSelector } from 'react-redux';

import { accountTypes } from '../utils/constants';
import { current } from '../utils/selectors';


const useAuth = (accountId) => {
  const user = useSelector((state) => state.user);
  const account = useSelector((state) => {
    if (accountId && state.accounts) {
      return state.accounts[accountId];
    }
    if (state.currentAccount && state.accounts) {
      return current(state, 'accounts');
    }
    return null;
  });
  const accounts = useSelector((state) => state.accounts);

  const isOwner = () => (account && user.email === account.ownerEmail);
  const hasOwnership = () => {
    if (!user) {
      return false;
    }
    const ownedAccounts = _.filter(accounts, { 'type': accountTypes.OWNER, 'ownerEmail': user.email });
    return (ownedAccounts.length > 0);
  };
  return {
    isOwner,
    hasOwnership,
  };
};

export default useAuth;
