import { getCollectionRef } from './common'

export const addMenu = (menu, accountId) => {
  const menusRef = getCollectionRef('menus', accountId);
  return menusRef.add(menu);
};

export const removeMenu = (menuId, accountId) => {
  const menusRef = getCollectionRef('menus', accountId);
  return menusRef.doc(menuId).delete();
};

export const updateMenu = (menuId, menu, accountId) => {
  const menusRef = getCollectionRef('menus', accountId);
  return menusRef.doc(menuId).update(menu);
};
