//React
import React, { useState, useEffect } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, minorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog';
import Button from '../../../components/ui/Button/Button';
import LoadingIndicator from '../../../components/IsLoading/IsLoading';

//Files
import { actions } from '../../../store/actions';
import { generateSearchFieldsFn, supplierOptionUOM } from '../../../utils/functions';
import { dateFormat } from '../../../utils/format';
import { current } from '../../../utils/selectors';


const now = new Date();
const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);


const buildSuppliersMap = (accountId, state) => {
  const suppliers = current(state, 'suppliers', accountId);
  return _.keyBy(suppliers.filter(s => !s.archive), 'id');
}

const buildSupplierOptionsList = (accountId, state, suppliers, ingQuantities) => {
  const optionList = _.sortBy(current(state, 'supplierOptions', accountId).filter(s => {
    return !!suppliers[s.supplierId]
  }), 'name');
  for (let i in optionList) {
    if (ingQuantities[optionList[i].id]) {
      optionList[i] = { ...optionList[i], quantity: ingQuantities[optionList[i].id] };
    }
  }
  return optionList;
}


const QuickOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const tableHeight = 200;

  const supplierMap = useSelector((state) => buildSuppliersMap(accountId, state));

  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [ingQuantities, setIngQuantities] = useState({});
  const [quickAdd, setQuickAdd] = useState(false);
  const [quickOrderDate, setQuickOrderDate] = useState(tomorrow);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dateSelectOpen, setDateSelectOpen] = useState(false);

  const supplierOptions = useSelector((state) => buildSupplierOptionsList(accountId, state, supplierMap, ingQuantities))

  const tableHeaders = [
    { label: 'Name', field: 'name' },
    { label: 'Supplier', field: 'supplierId', format: (supplierId) => ((supplierMap[supplierId]) ? supplierMap[supplierId].name : '') },
    { label: 'UOM', field: 'uom', type: 'calc', calc: supplierOptionUOM },
    { label: 'Qty to ord.', field: 'quantity', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value)},
    { label: 'Add', field: 'add', type: 'action', icon: 'plus' }
  ];
  const mobileTableHeaders = [
    { label: 'Name', field: 'name', columnProps: { flexBasis: '100%', marginBottom: majorScale(1) } },
    { label: 'Supplier', field: 'supplierId', format: (supplierId) => ((supplierMap[supplierId]) ? supplierMap[supplierId].name : '') },
    { label: 'UOM', field: 'uom', type: 'calc', calc: supplierOptionUOM },
    { label: 'Qty to o.', field: 'quantity', type: 'input', onChange: (item, e) => onQuantityChange(item, e.target.value)},
    { label: 'Add', field: 'add', type: 'action', icon: 'plus' }
  ];

  useEffect(() => {
    if (supplierOptions.length > 0) {
      setIsLoading(false);
    }
  }, [supplierOptions]);

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters({});
    }
    else {
      setFilters({ 'search': generateSearchFieldsFn(['name', 'category'], newSearchValue)});
    }
  };

  const onQuantityChange = (item, newQty) => {
    setIngQuantities((prevQuantities) => ({ ...prevQuantities, [item.id]: newQty }));
  };

  const handleOpenDateSelect = () => {
    setPopupOpen(false); // Ensure the first dialog is closed
    setDateSelectOpen(true); // Open the date selection dialog
  };

  const addQuickOrder = (item) => {
    const supplier = supplierMap[item.supplierId];
    if (!supplier) {
      dispatch(actions.appMessageError(`Could not find supplier for "${item.name}". Please check the ingredient and make sure the supplier name is correct.`));
      return;
    }
    if (!item.quantity) {
      dispatch(actions.appMessageError('Please enter a quantity!'));
      return;
    }
    setQuickAdd(item);
    setPopupOpen(true);
  };
  
  const doNextDayOrder = () => {
    dispatch(actions.orders.quickOrderSet(quickAdd.id, quickAdd.quantity, quickAdd.ingredientId));
    navigate(`/${accountId}/procurement/newOrder/${quickAdd.supplierId}`);
  };
  const doOrder = () => {
    if (!quickOrderDate) {
      doNextDayOrder();
      return;
    }
    dispatch(actions.orders.quickOrderSet(quickAdd.id, quickAdd.quantity, quickAdd.ingredientId));
    navigate(`${accountId}/procurement/newOrder/${quickAdd.supplierId}/${dateFormat(quickOrderDate)}`);
  };
  

  return (
    <React.Fragment>
      <Pane marginBottom={majorScale(2)} paddingX={majorScale(2)} paddingTop={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Quick Order</CustomHeading>
        <FilterBar
            searchPlaceholder="Search Supplier Ingredients"
            searchOnChange={searchOnChange}
        />
      </Pane>

      {isLoading ? (
        <LoadingIndicator message={'We are loading your data'}/> // Show loading indicator while data is loading
      ) : (
      <Pane height={240} maxHeight={240} marginBottom={majorScale(2)} overflowY="auto">
        <DataTable
            flex="1 0 auto"
            items={supplierOptions}
            headers={(isMobile) ? mobileTableHeaders : tableHeaders}
            filters={filters}
            onAdd={(item) => addQuickOrder(item)}
            listHeight={tableHeight}
        />
      </Pane>
      )}

      <CustomDialog
          isOpen={popupOpen}
          title="Select Order Date"
          hasFooter={false}
          onClose={() => setPopupOpen(false)}
      >
        {({ close }) => (
          <Pane padding={majorScale(2)}>
            <Button 
              appearance="primary" 
              onClick={doNextDayOrder}
            >
              Next Day Order
            </Button>
            <Button 
              appearance="minimal" 
              onClick={handleOpenDateSelect}
            >
              Select Date
            </Button>
          </Pane>
        )}
      </CustomDialog>

      <CustomDialog
          isOpen={dateSelectOpen}
          title="Select Order Date"
          confirmLabel="Go to Order"
          onCloseComplete={() => setDateSelectOpen(false)}
          onConfirm={doOrder}
      >
        <Pane padding={minorScale(4)}>
          <DatePickerInput
              required
              label="Delivery Date"
              placeholder="Please choose a date"
              min={tomorrow}
              value={quickOrderDate}
              onChange={setQuickOrderDate}
          />
        </Pane>
      </CustomDialog>
    </React.Fragment>
  );
};

export default QuickOrder;
