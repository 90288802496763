//React
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

//Libraries
import { majorScale, Pane } from 'evergreen-ui';

//Components
import SupplierOrderDetail from '../../../pages/Archive/components/SupplierOrderDetail';

//Files
import { accountTypes } from '../../../utils/constants';


const OrderView = (props) => {
  const account = useSelector(state => state.accounts[state.currentAccount]);
  const navigate = useNavigate();

  useEffect(() => {
    if (account && account.type === accountTypes.OWNER) return;

    navigate('/')
  }, [account])
  
  return (
    <Pane paddingX={majorScale(4)} paddingY={majorScale(2)}>
      <SupplierOrderDetail {...props} />
    </Pane>
  )
}

export default OrderView;
