import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import fb from './firebase';
import { actions } from './actions';

import rootSaga from './sagas';
import { loadAllergens } from './firebase/allergens';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
  );
sagaMiddleware.run(rootSaga);

loadAllergens();
fb.auth.onAuthStateChanged(user => {
  if (user) {
    console.log('user logged in ' + user.email)
    store.dispatch(actions.userSet(user));
    store.dispatch(actions.accounts.accountsLoadRequest(user.email));
  } else {
    store.dispatch(actions.userClear());
    store.dispatch(actions.profile.profileClear());
    store.dispatch(actions.accounts.accountsClear());
  }
});

export default store;
