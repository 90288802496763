const REPORTS_COMMENTS_LOAD_SUCCESS = 'REPORTS_COMMENTS_LOAD_SUCCESS';
const REPORTS_COMMENTS_CLEAR = 'REPORTS_COMMENTS_CLEAR';
const ADD_REPORT_COMMENT = 'ADD_REPORT_COMMENT';
const REMOVE_REPORT_COMMENT = 'REMOVE_REPORT_COMMENT';
const UPDATE_REPORT_COMMENT = 'UPDATE_REPORT_COMMENT';

export const actionTypes = {
  REPORTS_COMMENTS_LOAD_SUCCESS,
  REPORTS_COMMENTS_CLEAR,
  ADD_REPORT_COMMENT,
  REMOVE_REPORT_COMMENT,
  UPDATE_REPORT_COMMENT
};


const reportsCommentsLoadSuccess = (payload, account) => {
  return {
    type: REPORTS_COMMENTS_LOAD_SUCCESS,
    payload,
    account
  };
};

const reportsCommentsClear = () => {
  return {
    type: REPORTS_COMMENTS_CLEAR
  };
};

const addReportComment = (accountId, comment) => {
  return {
    type: ADD_REPORT_COMMENT,
    comment,
    accountId,
  };
};

const removeReportComment = (accountId, commentId) => {
  return {
    type: REMOVE_REPORT_COMMENT,
    commentId,
    accountId,
  };
};

const updateReportComment = (accountId, comment) => {
  return {
    type: UPDATE_REPORT_COMMENT,
    comment,
    accountId,
  };
};

export const actions = {
  reportsCommentsLoadSuccess,
  reportsCommentsClear,
  addReportComment,
  removeReportComment,
  updateReportComment
};
