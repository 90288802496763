//React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, SelectMenu, useTheme } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import DataTable from '../../components/DataTable/DataTable'
import FilterBar from '../../components/FilterBar/FilterBar';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';
import LoadingIndicator from '../../components/IsLoading/IsLoading';

// Utils
import { current } from '../../utils/selectors';


const Marketplace = () => {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [supplierList, setSupplierList] = useState([]);

    const userSupplierList = useSelector((state) => (
        _.filter(
          _.sortBy(
            current(state, 'suppliers'),
            (s) => !s.archive && s.name
          ),
          (i) => (!i.archive && (!i.status || i.status === 'ACCEPTED')) && i.type ==='SLAVE'
    )));
    console.log(userSupplierList, 'supplierList')

    const accessibleSupplierNames = userSupplierList.map(supplier => supplier.name);
    console.log(accessibleSupplierNames, "Access")

    const tableHeaders = [
        { label: 'Effective Date', field: 'effectiveDate', type: 'text', width: 2 },
        { label: 'Name', field: 'supplierOptionName', type: 'text', width: 5 },
        { label: 'Supplier', field: 'supplierName', type: 'text', width: 3 },
        { label: 'UOM', field: 'uom', type: 'text', width: 2 },
        { label: 'Price', field: 'price', type: 'text', prefix: '£', width: 2 },
        { label: 'VAT', field: 'vat', type: 'text', suffix: '%', width: 2 }
    ];
    
    useEffect(() => {
        const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://us-central1-miseenplace-online.cloudfunctions.net/queryMarketPrices`);
            if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            setData(result);
            setFilteredData(result);

            // Generate a list of unique suppliers
            const suppliers = _.uniqBy(result.map(item => ({
                label: item.supplierName,
                value: item.supplierName
            })), 'value');
            setSupplierList(suppliers);

        } catch (error) {
            console.error("Error fetching data: ", error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
        };
        fetchData();
    }, []);

    useEffect(() => {
        let tempData = data;
        // Filter by search query
        if (searchQuery) {
            tempData = tempData.filter(item =>
                item.supplierOptionName && item.supplierOptionName.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        // Filter by selected suppliers
        if (selectedSuppliers.length > 0) {
            tempData = tempData.filter(item =>
                selectedSuppliers.includes(item.supplierName)
            );
        };
        setFilteredData(tempData);
    }, [searchQuery, selectedSuppliers, data]);

    const handleSearchChange = (newSearchValue) => {
        setSearchQuery(newSearchValue);
    };

    const handleSupplierSelect = (item) => {
        setSelectedSuppliers([...selectedSuppliers, item.value]);
    };

    const handleSupplierDeselect = (item) => {
        setSelectedSuppliers(selectedSuppliers.filter(supplier => supplier !== item.value));
    };

    const blurStyle = {
        filter: 'blur(5px)',
        pointerEvents: 'none'
    };

    /*const isSupplierAccessible = (supplierName) => {
        return userSupplierList.some(supplier => supplier.name === supplierName);
    };*/
    
    if (error) return <div>Error: {error}</div>;

    return (
        <Page title="Marketplace">
            <Block padding={majorScale(2)} marginBottom={majorScale(2)} >
                <Pane
                    display="flex"
                    alignItems="center"
                    marginBottom={majorScale(2)}
                >
                    <CustomHeading level="3" flex="1 0 auto">Marketplace</CustomHeading>
                </Pane>
                <Pane
                    display="flex"
                    alignItems="center"
                >
                    <SelectMenu
                        hasFilter={false}
                        hasTitle={false}
                        isMultiSelect={true}
                        selected={selectedSuppliers}
                        options={supplierList}
                        onSelect={handleSupplierSelect}
                        onDeselect={handleSupplierDeselect}
                    >
                        <Button
                            marginRight={majorScale(2)}
                            iconBefore="filter"
                            appearance="flat"
                        >
                            {(!_.isEmpty(selectedSuppliers)) ? selectedSuppliers.join('') : 'Suppliers'}
                        </Button>
                    </SelectMenu>
                    <FilterBar
                        searchPlaceholder="Search for an Ingredient"
                        searchOnChange={handleSearchChange}
                        flex="1 0 auto"
                    />
                </Pane>
            </Block>
            <Block marginBottom={majorScale(2)}>
                {isLoading ? 
                <LoadingIndicator message={"We are loading the data..."}/> 
                : <Pane>
                    <DataTable 
                        items={filteredData.map(item => ({
                            ...item,
                            supplierName: accessibleSupplierNames.includes(item.supplierName) ? 
                                item.supplierName : 
                                <span style={blurStyle}>{item.supplierName}</span>
                        }))} 
                        headers={tableHeaders}  
                        flex="1 0 auto" 
                        listHeight={480} 
                        //isSupplierAccessible={isSupplierAccessible}
                    />
                </Pane>
                }
            </Block>
        </Page>
    );
};

export default Marketplace;
