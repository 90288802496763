import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addRecipe, updateRecipe, archiveRecipe, removeRecipe, uploadRecipeImage, 
  removeRecipeFromMenus, removeRecipeFromRecipes, removeRecipeFromModifiers } from '../firebase/recipes';
import { actionTypes, actions } from '../actions';
import { activeAccount, currentUserName } from '../../utils/selectors';

export default function* recipesSaga() {
  yield takeEvery(actionTypes.recipes.ADD_RECIPE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      const response = yield call(addRecipe, action.recipeInfo, accountId, userName);
      if (action.recipeImg) {
        yield call(uploadRecipeImage, response.id, accountId, action.recipeImg, accountId);
      }
      yield put(actions.appMessageSuccess('Recipe Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe could not be saved'));
    }
  });
  yield takeEvery(actionTypes.recipes.UPDATE_RECIPE, function* (action) {
    const { id, ...recipe } = action.recipeInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      yield call(updateRecipe, id, recipe, accountId, userName);
      if (action.recipeImg) {
        yield call(uploadRecipeImage, id, accountId, action.recipeImg, accountId);
      }
      yield put(actions.appMessageSuccess('Recipe Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe could not be updated'));
    }
  });
  yield takeEvery(actionTypes.recipes.ARCHIVE_RECIPE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      yield call(archiveRecipe, action.recipeId, accountId, userName);
      yield put(actions.appMessageSuccess('Recipe Archived'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe could not be archived'));
    }
  });
  yield takeEvery(actionTypes.recipes.REMOVE_RECIPE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      yield call(removeRecipe, action.recipeId, accountId, userName);
      yield put(actions.appMessageSuccess('Recipe Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe could not be removed'));
    }
  });
  yield takeEvery(actionTypes.recipes.REMOVE_RECIPE_FROM_COLLECTIONS, function* (action) {
    try {
      const { accountId, recipeId, usageInfo } = action;
      yield call(removeRecipeFromMenus, accountId, recipeId, usageInfo.menusUsingRecipe);
      yield call(removeRecipeFromRecipes, accountId, recipeId, usageInfo.recipesUsingRecipe);
      yield call(removeRecipeFromModifiers, accountId, recipeId, usageInfo.modifiersUsingRecipe);
      yield put(actions.appMessageSuccess('Recipe removed from collections successfully'));
    } catch (error) {
      console.error(error);
      yield put(actions.appMessageError('Failed to remove recipe from collections'));
    }
  });
};
