//React
import React, { Fragment } from 'react';

//Libraries
import { majorScale, Pane, Text } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Block from '../../components/ui/Block/Block';
import CustomHeading from '../../components/Headings/Headings';
import AddOn from './AddOn';

//Files
import { getModifierById } from '../../utils/functions';


const AddOns = ({
  recipe,
  accountId,
  ...rest
}) => {

  return (
    <Fragment>
      {_.isEmpty(recipe.addOns) && (
        <Block
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
        >
          <CustomHeading level="4" marginBottom={majorScale(2)}>Add-Ons</CustomHeading>
          <Pane paddingY={majorScale(1)}>
            <Text>No Add Ons available for this recipe.</Text>
          </Pane>
        </Block>
      )}
      {recipe.addOns && _.map(recipe.addOns, (item, index) => (
        <AddOn
          key={`add_on_${index}`}
          item={getModifierById(item.id, accountId)}
          index={index}
          accountId={accountId}
          {...rest}
        />
      ))}
    </Fragment>
  )
}

export default AddOns;
