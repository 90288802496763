//React
import React from 'react';

//Libraries
import { majorScale, minorScale, Pane } from 'evergreen-ui';
import _ from 'lodash';

//Components
import Header from '../components/Header';
import DataTable from '../../../components/DataTable/DataTable';
import Block from '../../../components/ui/Block/Block'

//Files
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { calcPercentage } from '../../../utils/functions';
import { aggregateFilterWeekSales } from '../../../utils/reports';


const CategorySales = ({ accounts, accountsData, sales, limit = true }) => {
  const dSales = accounts.categories.reduce((salesData, category) => {
    const catSales = aggregateFilterWeekSales(accountsData, 'weekSales', {categories: [category.name]});
    const processed = {
      name: category.name,
      sales: calcPercentage(catSales.weekSalesNet, sales.net, 1),
      net: catSales.weekSalesNet,
      id: category.id
    }
    return salesData.concat(processed);
  }, []);
  let final = _.sortBy(dSales, (s) => 1/s.net);
  //if (limit) final = final.slice(0, 4);

  const tableHeaders = [
    { label: 'Category Name', field: 'name', type: 'text', width: 5 },
    { label: 'Sales %', field: 'sales', type: 'text', suffix: '%', width: 3 },
    { label: 'Net Sales', field: 'net', type: 'numeric', prefix: '£', width: 3 }
  ]
  return (
    <Block width='100%'>
        <Header title='Sales by category' />
        <Pane height="500px" overflow="auto" marginBottom={majorScale(2)}>
          <DataTable
            headers={tableHeaders}
            items={final}
            listHeight={320}
          />
        </Pane>
    </Block>
  )
}

export default CategorySales;
