// Start with React + Libraries
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route, Navigate } from 'react-router-dom';

// Import styles + store + 
import store from './store/redux';
import { appRoutes } from './routing';
import { ThemeProvider } from 'evergreen-ui';
import theme from './theme'; 

//Pages
import App from './App';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
//import AccountSelect from './pages/AccountSelect/AccountSelect';
import NotFound from './pages/NotFound/NotFound';

//Files
import { DateProvider } from './context/DateContext';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<NotFound />}>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      {appRoutes.map(route => (
        <Route key={route.path} path={route.path} element={route.element} />  
      ))}
      <Route path="*" element={<NotFound />} />
    </Route>
    
  )
);

const root = createRoot(document.getElementById('app'));
root.render(
  <Provider store={store}>
    <ThemeProvider value={theme}>
      <DateProvider>
        <RouterProvider router={router} />
      </DateProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app')
);
