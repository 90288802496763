//React
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link as LinkRouter } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { minorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import IconWrapper from '../../components/Icons/Icons';

//Files
import { colors } from '../../utils/constants';
import { actions } from '../../store/actions';


const NavLinkMobile = (props) => {
  const { icon, name, label, to, ...rest } = props;
  const location = useLocation();
  const theme = useTheme();
  const isActive = (to === location.pathname || (to !== '/' && location.pathname.startsWith(to)));

  const linkStyle = {
    textDecoration: "none",
    alignItems:"center",
    justifyContent:"center",
    backgroundColor: isActive ? theme.colors.primary100 : theme.colors.offwhite,
    borderRadius: 20,
    padding: 7,
  };

  return (
    <LinkRouter
        {...rest}
        to={to}
        is={LinkRouter}
        display="flex"
        flexDirection="column"
        textDecoration="none"
        //alignItems="center"
        style={linkStyle}
    >
      {icon && (
        <IconWrapper
            name={icon}
            appearance="clickable"
            size={18}
            marginBottom={minorScale(2)}
            color={theme.colors.black}
        />
      )}
      {/*{label && (
        <Text
            size={16}
            color={theme.colors.black}
            textDecoration="none"
        >
          {label}</Text>
      )}*/}
    </LinkRouter>
  );
};

const NavMenuMobile = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: 768, minWidth: 600 });
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const user = useSelector((state) => state.user);
  const navMenuExpanded = useSelector((state) => state.navMenuExpanded);
  const workingAccount = useSelector((state) => {
    if (state.workingAccount) {
      return _.find(state.accounts, { 'id': state.workingAccount });
    }
    return false;
  });

  if ((!isTablet && !isMobile) || !user) {
    return null;
  }

  const toggleMenu = () => {
    dispatch(actions.navMenuExpandedSet(!navMenuExpanded));
  };

  return (
    <Pane
        is="nav"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        padding={minorScale(2)}
        elevation={1}
        background={theme.colors.offwhite}
        flex="0 0 auto"
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        zIndex={2}
    >
      {isTablet && (
        <Text
            is="button"
            background="transparent"
            border="0"
        >
          <IconWrapper
              size={18}
              name={(navMenuExpanded) ? 'cross' : 'menu'}
              color={theme.colors.black}
              onClick={toggleMenu}
              cursor="pointer"
          />
        </Text>
      )}
      {workingAccount && (
        <>
          <NavLinkMobile
              to={`/${workingAccount.id}/tasks`}
              icon="checkFat"
              label="Tasks & HACCP"
          />
          <NavLinkMobile
              to={`/${workingAccount.id}/procurement`}
              icon="shoppingCart"
              label="Procurement"
          />
          <NavLinkMobile
              to={`/${workingAccount.id}/front-of-house`}
              icon="squareFour"
              label="Product Hub"
          />
          <NavLinkMobile
              to={`/${workingAccount.id}/inventory/stocktake`}
              icon="bookMarks"
              label="Inventory"
          />
        </>
      )}
      <NavLinkMobile
          to="/departments"
          icon="buildingOffice"
          label="Areas"
      />
    </Pane>
  );
};

export default NavMenuMobile;
