import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addBudget, updateBudget, removeBudget } from '../firebase/budgets';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* budgetsSaga() {
  yield takeEvery(actionTypes.budgets.ADD_BUDGET, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addBudget, action.budgetInfo, accountId);
      yield put(actions.appMessageSuccess('Budget Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Budget could not be added'));
    }
  });
  yield takeEvery(actionTypes.budgets.REMOVE_BUDGET, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeBudget, action.budgetId, accountId);
      yield put(actions.appMessageSuccess('Budget Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Budget could not be removed'));
    }
  });
  yield takeEvery(actionTypes.budgets.UPDATE_BUDGET, function* (action) {
    const { id, ...budgetInfo } = action.budgetInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateBudget, id, budgetInfo, accountId);
      yield put(actions.appMessageSuccess('Budget Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Budget could not be updated'));
    }
  });
};
