//React
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import { Table, Heading, Dialog, TableHeaderCell, Pane, Text, Spinner, majorScale, minorScale } from 'evergreen-ui';
//import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { accountTypes} from '../../../utils/constants'
import { generateSearchFieldsFn } from '../../../utils/functions';
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import { orderStatuses } from '../../../utils/constants';


const MatchesDialog = ({ matchPo, setMatchPo, }) => {
  const { accountId } = useParams();
  //This could maybe be refactor using current
  const userAccounts = useSelector((state) => state.accounts); 
  const [locationAccountId, setLocationAccountId] = useState(null);

  useEffect(() => {
    const currentAccount = userAccounts[accountId];
  
    if (currentAccount && currentAccount.parents) {
      // Loop through each parent ID in the current account's parents array
      for (const parentId of currentAccount.parents) {
        const parentAccount = userAccounts[parentId];
        // Check if the parent account is of type 'LOCATION'
        if (parentAccount && parentAccount.type?.includes(accountTypes.LOCATION)) {
          setLocationAccountId(parentAccount.id);
          //console.log("Location Account ID:", parentAccount.id); // Debugging
          break;
        }
      }
    }
  }, [userAccounts, accountId]);

  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const supplierOptions = useSelector((state) => _.keyBy(current(state, 'supplierOptions', accountId), 'id'));
  const dispatch = useDispatch();

  const invoicUploads = useSelector(state => {
    const uploads = _.filter(current(state, 'invoiceUploads', locationAccountId), (i) => {
      return i.supplierId === matchPo?.supplierId && i.status === 'PROCESSED';
    });
    const matchPoDate = moment.utc(matchPo?.date, 'YYYY-MM-DD');
    const startDate = matchPoDate.clone().subtract(7, 'days');
    const endDate = matchPoDate.clone().add(7, 'days');

    return uploads
      .filter(i => {
        const invoiceDate = moment.utc(i.date, 'YYYY-MM-DD');
        return invoiceDate.isBetween(startDate, endDate, undefined, '[]'); // inclusive of start and end dates
      })
      .sort((a, b) => moment.utc(b.date, 'YYYY-MM-DD').diff(moment.utc(a.date, 'YYYY-MM-DD')));
  });

  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  //console.log("Fetched Invoice Uploads:", invoicUploads);

  const tableHeaders = [
    { label: 'Name', field: 'supplierId', format: (supplierId) => ((supplierMap[supplierId]) ? supplierMap[supplierId].name : ''), width: 4 },
    { label: 'Date', field: 'date', type: 'field', format: (date) => moment.utc(date, 'YYYY-MM-DD').format('DD/MM/YY'), width: 3 },
    { label: 'Inv. Nb', field: 'invoiceNumber', type: 'field', width: 3 },
    { label: 'Sub-total', field: 'subtotal', type: 'calc', calc: (i) => i.results?.subtotal ?? '', width: 2 },
    { label: 'Total', field: 'total', type: 'calc', calc: (i) => i.results?.invoice_amount, width: 2 },
    // { label: 'View invoice', field: 'view', type: 'action', icon: 'clipboard' },
    { label: 'Match', field: 'match', type: 'action', icon: 'plus', width: 1 },
  ];

  const finishLoading = () => {
    setLoading(false);
    setMatchPo(false);
  }

  //console.log('is that one working',)

  const mapInvoiceToPoFormat = (invoiceItem) => {
    if (!supplierOptions) {
      console.error("Supplier options are not available.");
      return; // or handle this scenario appropriately
    }
  
    const mappedLineItems = invoiceItem.results.lineItems.map(item => {
      const supplierOption = Object.values(supplierOptions).find(option => option.parent === item.supplier_option_id);
  
      return {
        id: supplierOption ? supplierOption.id : "Not Found",
        quantity: item.Quantity,
        quantityreceived: item.Quantity,
        ingredientId: supplierOption ? supplierOption.ingredientId : "Not Found",
        parent: item.supplier_option_id,
        price: item.Price,
        finalprice: item.Price,
        // ... other fields
      };
    });
  
    // Extract the items array from matchPo
    const items = matchPo.items.map(item => ({
      id: item.id,
      ingredientId: item.ingredientId,
      quantity: item.quantity
    }));
  
    // Use the existing date from matchPo
    const date = matchPo.date;
  
    // Create the unmatched_data object
    const unmatched_data = {
      date: date,
      items: items
    };
  
    return {
      ...matchPo,
      items: mappedLineItems,
      invoice_upload_id: invoiceItem.id,
      invoice_number: invoiceItem.invoiceNumber,
      date: invoiceItem.results.invoice_date,
      invoice_amount: invoiceItem.results.invoice_amount,
      subtotal: invoiceItem.results.subtotal,
      total_tax: invoiceItem.results.total_tax,  
      unmatched_data: unmatched_data,
    };
  };
  
  const createPoMatch = (item) => {
    
    setLoading(true);
    const updatedPo = mapInvoiceToPoFormat(item);
    console.log("Updated PO data:", updatedPo);
    setMatchPo(updatedPo); // Update the matchPo state with the mapped invoice data
    dispatch(actions.orders.updateOrder(accountId, {
      ...updatedPo,
      status: orderStatuses.MATCHED
    }, finishLoading));
    // Update the invoice upload status to MATCHED
    dispatch(actions.invoiceUploads.updateInvoiceUpload(locationAccountId, {
      ...item,
      status: 'MATCHED'
    }));
  };

  const viewInvoiceUpload = (item) => {

  }

  const Loading = (
    <Pane
      display="flex"
      flex="1 0 auto"
      alignItems="center"
      justifyContent="center"
    >
      <Text>Loading ...</Text><Spinner />
    </Pane>
  );

  const searchInvoiceUploads = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters({});
    }
    else {
      setFilters({ 'search': generateSearchFieldsFn(['invoiceNumber'], newSearchValue)});
    }
  }

  const InvoiceUploads = (
      <Pane paddingBottom={majorScale(4)}>
        <CustomHeading level="4" paddingY={minorScale(1)}>Invoices to match</CustomHeading>
        <FilterBar
            searchPlaceholder="Search for an invoice number"
            searchOnChange={searchInvoiceUploads}
            paddingY={minorScale(1)}
        />
        <DataTable
          flex="1 0 auto"
          items={invoicUploads}
          headers={tableHeaders}
          filters={filters}
          onMatch={(item) => createPoMatch(item)}
          onView={(item) => viewInvoiceUpload(item)}
        />
      </Pane>
  )

  const PoInfo = () => {
    return (
      <Pane paddingY={majorScale(1)}>
        <CustomHeading level="4" paddingY={minorScale(1)}>PO Info</CustomHeading>
        <Table>
          <Table.Head height={40}>
            <TableHeaderCell>Supplier</TableHeaderCell>
            <TableHeaderCell>Delivery date</TableHeaderCell>
            <TableHeaderCell>Nb items</TableHeaderCell>
          </Table.Head>
          <Table.Body>
            <Table.Row height={40}>
              <Table.TextCell>{supplierMap[matchPo?.supplierId]?.name}</Table.TextCell>
              <Table.TextCell>{moment.utc(matchPo.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Table.TextCell>
              <Table.TextCell>{matchPo.items?.length || 0}</Table.TextCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Pane>
    )
  }

  return (
    <CustomDialog
      isOpen={!!matchPo}
      title="Match PO"
      confirmLabel="close"
      onClose={() => setMatchPo(false)}
      onConfirm={() => {
        setMatchPo(false);
      }}
      width='750px'
      //hasCancel={false}
    >
      <>
        {loading && matchPo && Loading}
        {!loading && (
          <>
            <PoInfo />
            {InvoiceUploads}
          </>
        )}
      </>
    </CustomDialog>
  )
}

export default MatchesDialog;
