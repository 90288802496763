const ACCOUNTS_LOAD_REQUESTED = 'ACCOUNTS_LOAD_REQUESTED';
const ACCOUNTS_LOAD_FAILURE = 'ACCOUNTS_LOAD_FAILURE';
const ACCOUNTS_LOAD_SUCCESS = 'ACCOUNTS_LOAD_SUCCESS';
const ACCOUNTS_CLEAR = 'ACCOUNTS_CLEAR';
const ACCOUNT_LOAD_REQUEST = 'ACCOUT_LOAD_REQUEST';
const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
const ACCOUNT_CLEAR = 'ACCOUNT_CLEAR';
const CURRENT_ACCOUNT_SET = 'CURRENT_ACCOUNT_SET';
const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
const ACCOUNT_REMOVE = 'ACCOUNT_REMOVE';
const ACCOUNT_CREATE = 'ACCOUNT_CREATE';
const ACCOUNT_MESSAGES_SEEN = 'ACCOUNT_MESSAGES_SEEN';
const ALL_ACCOUNTS_LOADED = 'ALL_ACCOUNTS_LOADED';

export const actionTypes = {
  ACCOUNTS_LOAD_REQUESTED,
  ACCOUNTS_LOAD_FAILURE,
  ACCOUNTS_LOAD_SUCCESS,
  ACCOUNTS_CLEAR,
  ACCOUNT_LOAD_REQUEST,
  ACCOUNT_LOADED,
  ACCOUNT_CLEAR,
  CURRENT_ACCOUNT_SET,
  ACCOUNT_UPDATE,
  ACCOUNT_REMOVE,
  ACCOUNT_CREATE,
  ACCOUNT_MESSAGES_SEEN,
  ALL_ACCOUNTS_LOADED,
};

const accountsMessagesSeen = (profile) => {
  return {
    type: ACCOUNT_MESSAGES_SEEN,
    profile
  };
};

const accountsLoadRequest = (userEmail) => {
  return {
    type: ACCOUNTS_LOAD_REQUESTED,
    userEmail
  };
};

const accountsLoadFailure = () => {
  return {
    type: ACCOUNTS_LOAD_FAILURE
  };
};

const accountsLoadSuccess = (payload) => {
  return {
    type: ACCOUNTS_LOAD_SUCCESS,
    payload
  };
};

const allAccountsLoaded = () => {
  return {
  type: ALL_ACCOUNTS_LOADED,
  }
};

const accountsClear = () => {
  return {
    type: ACCOUNTS_CLEAR
  };
};

const accountLoadRequest = (accountId) => {
  return {
    type: ACCOUNT_LOAD_REQUEST,
    accountId
  };
};

const accountLoaded = (payload) => {
  return {
    type: ACCOUNT_LOADED,
    payload
  };
};

const accountClear = (payload) => {
  return {
    type: ACCOUNT_CLEAR,
    payload,
  };
};

const currentAccountSet = (payload) => {
  return {
    type: CURRENT_ACCOUNT_SET,
    payload
  };
};

const accountUpdate = (accountInfo, callback) => {
  return {
    type: ACCOUNT_UPDATE,
    accountInfo,
    callback
  };
};

const accountRemove = (accountId) => {
  return {
    type: ACCOUNT_REMOVE,
    accountId
  };
};

const accountCreate = (accountInfo, callback) => {
  return {
    type: ACCOUNT_CREATE,
    accountInfo,
    callback
  };
};

export const actions = {
  accountsLoadRequest,
  accountsLoadFailure,
  accountsLoadSuccess,
  accountsClear,
  accountLoadRequest,
  accountLoaded,
  accountClear,
  currentAccountSet,
  accountUpdate,
  accountRemove,
  accountCreate,
  allAccountsLoaded,
};
