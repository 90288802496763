//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Librries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Tablist, UnorderedList, ListItem, Text, useTheme, Pane } from 'evergreen-ui';

//Components
import Tab from '../../../components/ui/Tab/Tab';
import CustomDialog from '../../../components/Dialog/Dialog';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { current } from '../../../utils/selectors';

export const buildSuppliersList = (accountId, state) => {
  console.log(accountId, "FUnction buildsuplier")
  const suppliers = current(state, 'suppliers', accountId);
  return _.sortBy(suppliers.filter(s => !s.archive), 'name');
}


const UploadModal = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const { accountId } = useParams();
  console.log(accountId)
  const [reconcileTab, setReconcileTab] = useState('invoice');
  const suppliers = useSelector((state) => buildSuppliersList(accountId, state));
  console.log(suppliers)

  return (
      <CustomDialog
          isOpen={isOpen}
          //hasFooter={false}
          title="Issue PO"
          onClose={onClose}
      >
        <Pane marginBottom={majorScale(2)}>
        <Text color={theme.colors.warning}>Issue POs without sending an email to your Supplier</Text>
        </Pane>
        <Tablist
            display="flex"
            flexShrink={0}
            overflowX="auto"
            paddingBottom={majorScale(2)}
        >
          <Tab isSelected={reconcileTab === 'invoice'} onSelect={() => setReconcileTab('invoice')}>Invoice</Tab>
          <Tab isSelected={reconcileTab === 'credit'} onSelect={() => setReconcileTab('credit')}>Credit Note</Tab>
        </Tablist>
        <UnorderedList padding={0} marginBottom={majorScale(4)}>
          {_.map(suppliers, (supplier) => (
            <ListItem
                key={supplier.id}
                margin={0}
                is={Link}
                to={`/${accountId}/procurement/newOrderPO/${supplier.id}/${reconcileTab}`}
                display="flex"
                alignItems="center"
                textDecoration="none"
                padding={majorScale(2)}
            >
              <Text flex="1 0 auto">{supplier.name}</Text>
              <IconWrapper name="plus" appearance='clickable' marginRight={majorScale(2)} />
            </ListItem>
          ))}
        </UnorderedList>
      </CustomDialog>
  );
};

UploadModal.propTypes = {
  accountId: PropTypes.string.isRequired,
  reconcileModal: PropTypes.bool,
  setReconcileModal: PropTypes.func.isRequired
};

export default UploadModal;
