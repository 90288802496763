//React
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Checkbox, Table, Text, Paragraph, TextInput, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import AddOns from '../../Modifiers/AddOns';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';
import Button from '../../../components/ui/Button/Button'

//Files
import { recipeCosting, recipeCostPerPortion, recipeYieldPerPortion, getRecipeItemDetails, getRecipeCalculatedFields, recipeIngredientAllergens } from '../../../utils/functions';
import { currency, toFixed } from '../../../utils/format';
import { colors } from '../../../utils/constants';
import { current } from '../../../utils/selectors';
import { recipeBelongsToArea } from '../../../utils/departments';
import { generatePDF } from '../../../utils/exportContent'

const dietaryOptions = [
  'Gluten Free',
  'Vegetarian',
  'Vegan'
];


const RecipeView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const contentRef = useRef();
  const { accountId, recipeId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const [exitUrl, setExitUrl] = useState(`/${accountId}/front-of-house/recipes`);
  
  // Parse batch query parameter as a number and default to 1 if undefined or invalid
  const batch = Number(searchParams.get('batch')) || 1;
  const [batchMultiplier, setBatchMultiplier] = useState(batch);

  useEffect(() => {
    if (batch > 1) {
      setExitUrl(`/${accountId}/tasks/prep`);
    }
  }, [accountId, batch, setExitUrl]);

  useEffect(() => {
    if (searchParams.has('batch')) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('batch');
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const allergenList = useSelector((state) => state.allergens);

  const recipe = useSelector((state) => {
    if (recipeId) {
      return _.find(current(state, 'recipes', accountId), { id: recipeId }) || false;
    }
    return false;
  });
  const belongs = recipe ? recipeBelongsToArea(accountId, recipe) : false;

  /*useEffect(() => {
    console.log('belongs:', belongs); // Log the value to see what it is on redirect
    if (!belongs) {
      console.log('Redirecting to root because belongs is falsy.');
      navigate('/');
    }
  }, [belongs, navigate]);*/

  const ingredientAllergens = recipe ? recipeIngredientAllergens(recipe) : [];

  const isSuitable = (item) => {
    if (recipe && recipe.suitable) {
      return _.includes(recipe.suitable, item);
    }
    return false;
  };

  const containsAllergen = (allergen) => {
    if (ingredientAllergens?.length > 0) {
      return _.includes(ingredientAllergens, allergen.name) || _.includes(ingredientAllergens, allergen.id);
    }
    return false;
  };

  const incrementBatch = () => {
    setBatchMultiplier((prevValue) => (prevValue + 1));
  };

  const decrementBatch = () => {
    setBatchMultiplier((prevValue) => Math.max(prevValue - 1, 1));
  };

  const setBatchValue = (newValue) => {
    const intValue = parseInt(newValue) || 1;
    if (batchMultiplier !== intValue) {
      setBatchMultiplier((prevValue) => Math.max(intValue, 1));
    }
  };

  const exit = () => {
    navigate(exitUrl);
  }

  const headerCellStyle = {
    textTransform: 'none',
    fontSize: "16px",
    color: theme.colors.black,
    fontWeight: "400"
  };


  return (
    <React.Fragment>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          height={48}
      >
        <Pane display="flex" alignItems="center" flex="1">
          <IconWrapper
              appearance="clickable"
              onClick={exit}
              name="arrowLeft"
              marginRight={majorScale(2)}
              color={theme.colors.black}
          />
          <CustomHeading level="3" flex="1 1 auto">{recipe.name}</CustomHeading>
        </Pane>
        <Button 
            name="Download"
            onClick={() => generatePDF(contentRef.current, recipe.name)}
            appearance='primary'
          >
            Download as PDF
          </Button>
      </Block>

      {/* TODO Add print button outside the ref to avoid printing the button itself */}
      {/*<Block paddingX={majorScale(2)} paddingY={majorScale(2)} marginBottom={majorScale(2)}>
        {/*<Button
            name="Print"
            onClick={() => printContent(contentRef.current)}
            appearance="primary"
            marginRight={16}
          >
            Print Recipe
          </Button>
          <Button 
            name="Download"
            onClick={() => generatePDF(contentRef.current, recipe.name)}
            appearance='primary'
          >
            Download as PDF
          </Button>
      </Block>*/}

      <Block marginBottom={majorScale(2)} ref={contentRef} >
        <Pane
            display="flex"
            alignItems="stretch"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <Pane
              flex="0 0 auto"
              height={(isMobile) ? 100 : 200}
              width={(isMobile) ? 100 : 200}
              backgroundPosition="center 0"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              backgroundImage={(recipe.main_imageURL) ? `url(${recipe.main_imageURL})` : 'none'}
              border="default"
              borderRadius={3}
              marginRight={majorScale(2)}
              alignSelf="flex-start"
          />
          <Pane
              border="muted"
              borderRadius={3}
              flex="1 1 auto"
              padding={majorScale(2)}
          >
            {(recipe.description) ? <Paragraph>{recipe.description}</Paragraph> : "No description available."}
          </Pane>
        </Pane>

        <CustomHeading level="4" paddingX={majorScale(2)} marginBottom={majorScale(2)}>Ingredients</CustomHeading>
        <Pane
            display="flex"
            alignItems="center"
            marginX={majorScale(2)}
            marginBottom={majorScale(1)}
        >
          <Text
              flex="1 0 auto"
              marginRight={majorScale(2)}
          >Batch Multiplier</Text>
          <IconWrapper
              name="minus"
              color={theme.colors.black}
              appearance="clickable"
              onClick={() => decrementBatch()}
          />
          <TextInput
              value={batchMultiplier}
              onChange={(e) => setBatchValue(e.target.value)}
              width={50}
              textAlign="center"
              marginX={majorScale(2)}
          />
          <IconWrapper
              name="plus"
              color={theme.colors.black}
              appearance="clickable"
              onClick={() => incrementBatch()}
          />
        </Pane>
        <Table marginBottom={majorScale(2)}>
          <Table.Head
              flexFlow="row wrap"
              height="48px"
              paddingY={majorScale(1)}
          >
            <Table.TextHeaderCell
                {...((isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1)} : {})}
                style={headerCellStyle}
            >Ing</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={100} flexGrow={0} style={headerCellStyle}>Qty</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={100} flexGrow={0} style={headerCellStyle}>UOM</Table.TextHeaderCell>
            <Table.TextHeaderCell style={headerCellStyle}>Measure (optional)</Table.TextHeaderCell>
            {!isMobile && (
              <>
                 <Table.TextHeaderCell
                    flexBasis={150}
                    flexGrow={0}
                    justifyContent="flex-end"
                    textProps={{ textAlign: 'right' }}
                    style={headerCellStyle}
                >Cost</Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={100} flexGrow={0} style={headerCellStyle}>
                  Extra
                </Table.TextHeaderCell>
              </> 
            )}
          </Table.Head>
          <Table.Body>
            {recipe.ingredients && recipe.ingredients.map((item, index) => {
              //console.log(`Render Item ${index}:`, item);
              const itemDetails = getRecipeItemDetails(item, accountId);
              const calculatedFields = getRecipeCalculatedFields(item, itemDetails);
              //console.log(`Ingredient ${index} Details:`, itemDetails);
              //console.log(`Ingredient ${index} Calculated Fields:`, calculatedFields);
              const isModifier = item.type === 'modifier' && itemDetails.ingredients;
              return (
                <Fragment key={index}>
                  <Table.Row
                      flexFlow="row wrap"
                      height="auto"
                      minHeight={48}
                      paddingY={majorScale(1)}
                      //borderBottom={isModifier ? '1px solid #edf0f2' : `1px solid ${colors.orangeBorder}`}
                  >
                    <Table.TextCell
                        {...((isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1) } : {})}
                    >{itemDetails.name ? itemDetails.name : 'N/A'}</Table.TextCell>
                    <Table.TextCell flexBasis={100} flexGrow={0}>{item.quantity ? item.quantity * batchMultiplier : calculatedFields.multiselect}</Table.TextCell>
                    <Table.TextCell flexBasis={100} flexGrow={0}>{calculatedFields.recipeUnit}</Table.TextCell>
                    <Table.TextCell>{item.measureQty && item.measureQty * batchMultiplier} {item.measureUnit}</Table.TextCell>
                    {!isMobile && (
                      <>
                        <Table.TextCell
                            flexBasis={150}
                            flexGrow={0}
                            justifyContent="flex-end"
                            textProps={{ textAlign: 'right' }}
                        >{toFixed(calculatedFields.cost, 4)}</Table.TextCell>
                        <Table.TextCell flexBasis={100} flexGrow={0}>{item.price}</Table.TextCell>
                      </>
                    )}
                  </Table.Row>
                  {
                    isModifier && itemDetails.ingredients.map((ing, itemIndex) => {
                      const details = getRecipeItemDetails(ing, accountId);
                      const calculatedFields = getRecipeCalculatedFields(ing, details);
                      const isLast = itemIndex === itemDetails.ingredients.length - 1;
                      return (
                        <Table.Row key={`mod_i_${itemIndex}`} borderBottom={isLast ? `1px solid ${colors.orangeBorder}` : '1px solid #edf0f2'}>
                          <Table.TextCell
                            {...((isMobile) ? { flexBasis: '100%', marginBottom: majorScale(1)} : {})}
                          >{ing.name}</Table.TextCell>
                          <Table.TextCell flexBasis={100} flexGrow={0}>{ing.quantity * batchMultiplier}</Table.TextCell>
                          <Table.TextCell flexBasis={100} flexGrow={0}>{calculatedFields.recipeUnit}</Table.TextCell>
                          <Table.TextCell ></Table.TextCell>
                          {
                            !isMobile && (
                              <>
                                <Table.TextCell
                                  flexBasis={150}
                                  flexGrow={0}
                                  justifyContent="flex-end"
                                  textProps={{ textAlign: 'right' }}
                                >{toFixed(calculatedFields.cost, 4)}</Table.TextCell>
                                <Table.TextCell flexBasis={100} flexGrow={0}>{ing.price}</Table.TextCell>
                              </>
                            )
                          }
                          
                        </Table.Row>
                      )
                    }
                  )}
                </Fragment>
              );
            })}
          </Table.Body>
        </Table>

        <CustomHeading level="4" paddingX={majorScale(2)} marginBottom={majorScale(2)}>Recipe Breakdown</CustomHeading>
        <Pane
            display="flex"
            justifyContent="space-evenly"
            background={colors.twilightBlue10}
            padding={majorScale(2)}
            marginBottom={1}
        >
          <Pane
              display="flex"
              {...(
                (isMobile) ? {
                  flex: `1 0 calc(20% - ${majorScale(4)}px)`,
                  flexDirection: 'column',
                  marginRight: majorScale(2),
                } : {
                  flex: `1 0 calc(20% - ${majorScale(8)}px)`,
                  marginRight: majorScale(4),
                  minWidth: 75
                }
              )}
          >
            <Text flex="1 0 auto" marginRight={(isMobile) ? 0 : majorScale(4)}>Yield</Text>
            <Text color={colors.orange}>{recipe.yield || '1'}</Text>
          </Pane>
          <Pane
              display="flex"
              {...(
                (isMobile) ? {
                  flex: `1 0 calc(45% - ${majorScale(4)}px)`,
                  flexDirection: 'column',
                  marginRight: majorScale(2)
                } : {
                  flex: `1 0 calc(45% - ${majorScale(8)}px)`,
                  marginRight: majorScale(4),
                  minWidth: 200
                })}
          >
            <Text flex="1 0 auto" marginRight={(isMobile) ? 0 : majorScale(4)}>Yield Description</Text>
            <Text color={colors.orange}>{recipe.yieldDescription}</Text>
          </Pane>
          <Pane
              display="flex"
              {...(
                (isMobile) ? {
                  flex: `1 0 calc(35% - ${majorScale(4)}px)`,
                  flexDirection: 'column'
                } : {
                  flex: `1 0 calc(35% - ${majorScale(8)}px)`,
                  minWidth: 175
                })}
          >
            <Text flex="1 0 auto" marginRight={(isMobile) ? 0 : majorScale(4)}>Cost / Yield</Text>
            <Text color={colors.orange}>£ {currency(recipeCosting(recipe, false, true))}</Text>
          </Pane>
        </Pane>
        <Pane
            display="flex"
            justifyContent="space-evenly"
            background={colors.twilightBlue10}
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <Pane
              display="flex"
              {...(
                (isMobile) ? {
                  flex: `1 0 calc(20% - ${majorScale(4)}px)`,
                  flexDirection: 'column',
                  marginRight: majorScale(2)
                } : {
                  flex: `1 0 calc(20% - ${majorScale(8)}px)`,
                  marginRight: majorScale(4),
                  minWidth: 75
                }
              )}
          >
            <Text flex="1 0 auto" marginRight={(isMobile) ? 0 : majorScale(2)}>Portion</Text>
            <Text color={colors.orange}>{recipe.portion || 1}</Text>
          </Pane>
          <Pane
              display="flex"
              {...(
                (isMobile) ? {
                  flex: `1 0 calc(45% - ${majorScale(4)}px)`,
                  flexDirection: 'column',
                  marginRight: majorScale(2)
                } : {
                  flex: `1 0 calc(45% - ${majorScale(8)}px)`,
                  marginRight: majorScale(4),
                  minWidth: 200
                }
              )}
          >
            <Text flex="1 0 auto" marginRight={(isMobile) ? 0 : majorScale(2)}>Yield / Portion</Text>
            <Text color={colors.orange}>{toFixed(recipeYieldPerPortion(recipe), 4)}</Text>
          </Pane>
          <Pane
              display="flex"
              {...(
                (isMobile) ? {
                  flex: `1 0 calc(35% - ${majorScale(4)}px)`,
                  flexDirection: 'column'
                } : {
                  flex: `1 0 calc(35% - ${majorScale(8)}px)`,
                  minWidth: 175
                }
              )}
          >
            <Text flex="1 0 auto" marginRight={(isMobile) ? 0 : majorScale(4)}>Cost / Portion</Text>
            <Text color={colors.orange}>£ {currency(recipeCostPerPortion(recipe))}</Text>
          </Pane>
        </Pane>

        <Pane
            display="flex"
            flexFlow="row wrap"
            alignItems="stretch"
            paddingX={majorScale(2)}
            marginBottom={majorScale(2)}
            minHeight={majorScale(25)}
        >
          <Pane
              display="flex"
              flexDirection="column"
              {...(
                (isMobile) ? {
                  flex: '1 1 100%',
                  marginBottom: majorScale(2)
                } : {
                  flex: `1 1 calc(50% - ${majorScale(2)}px)`,
                  marginRight: majorScale(2)
                }
              )}
          >
            <CustomHeading level="4" marginBottom={majorScale(2)}>Method</CustomHeading>
            <Pane flex="1 0 auto" border="muted" padding={majorScale(2)} minHeight={100}>
              <Paragraph whiteSpace="pre-line">{recipe.method}</Paragraph>
            </Pane>
          </Pane>
          <Pane
              display="flex"
              flexDirection="column"
              {...(
                (isMobile) ? {
                  flex: '1 1 100%'
                } : {
                  flex: `1 1 calc(50% - ${majorScale(2)}px)`,
                  marginLeft: majorScale(2)
                }
              )}
          >
            <CustomHeading level="4" marginBottom={majorScale(2)}>Note</CustomHeading>
            <Pane flex="1 0 auto" border="muted" padding={majorScale(2)} minHeight={100}>
              <Paragraph whiteSpace="pre-line">{recipe.notes}</Paragraph>
            </Pane>
          </Pane>
        </Pane>

        <CustomHeading level="4" marginBottom={majorScale(2)} paddingX={majorScale(2)}>Suitable For</CustomHeading>
        <Pane
            marginBottom={majorScale(2)}
            paddingLeft={majorScale(4)}
            display="flex"
        >
          {dietaryOptions.map((item) => (
            <Checkbox
                disabled
                key={_.kebabCase(item)}
                label={item}
                checked={isSuitable(item)}
                flexBasis="15%"
                marginRight={majorScale(2)}
            />
          ))}
        </Pane>

        <CustomHeading level="4" marginBottom={majorScale(2)} paddingX={majorScale(2)}>Allergens</CustomHeading>
        <Pane
            paddingLeft={majorScale(4)}
            display="flex"
            flexFlow="row wrap"
        >
          {allergenList.map((allergen) => (
            <Checkbox
                disabled
                key={allergen.id}
                label={allergen.name}
                checked={containsAllergen(allergen)}
                flexBasis="15%"
                marginRight={majorScale(2)}
            />
          ))}
        </Pane>
        <AddOns
          recipe={recipe}
          hideEdit
          blockProps={{border:'muted'}}
          accountId={accountId}
        />
      </Block>
    </React.Fragment>
  )
};

RecipeView.defaultProps = {
  recipeId: '',
};

export default RecipeView;
