const SUPPLIER_OPTIONS_LOAD_REQUESTED = 'SUPPLIER_OPTIONS_LOAD_REQUESTED';
const SUPPLIER_OPTIONS_LOAD_SUCCESS = 'SUPPLIER_OPTIONS_LOAD_SUCCESS';
const SUPPLIER_OPTIONS_LOAD_FAILURE = 'SUPPLIER_OPTIONS_LOAD_FAILURE';
const SUPPLIER_OPTIONS_LOAD_REPLACE = 'SUPPLIER_OPTIONS_LOAD_REPLACE';
const SUPPLIER_OPTIONS_CLEAR = 'SUPPLIER_OPTIONS_CLEAR';
const ADD_SUPPLIER_OPTION = 'ADD_SUPPLIER_OPTION';
const REMOVE_SUPPLIER_OPTION = 'REMOVE_SUPPLIER_OPTION';
const ARCHIVE_SUPPLIER_OPTION = 'ARCHIVE_SUPPLIER_OPTION';
const UPDATE_SUPPLIER_OPTION = 'UPDATE_SUPPLIER_OPTION';
const REMOVE_SUPPLIER_OPTION_FROM_COLLECTION = 'REMOVE_SUPPLIER_OPTION_FROM_COLLECTION';
const IMPORT_SUPPLIER_OPTIONS = 'IMPORT_SUPPLIER_OPTIONS';
const ADD_UPLOAD = 'ADD_SUPPLIER_OPTIONS_UPLOAD';

export const actionTypes = {
  SUPPLIER_OPTIONS_LOAD_REQUESTED,
  SUPPLIER_OPTIONS_LOAD_SUCCESS,
  SUPPLIER_OPTIONS_LOAD_FAILURE,
  SUPPLIER_OPTIONS_LOAD_REPLACE,
  SUPPLIER_OPTIONS_CLEAR,
  ADD_SUPPLIER_OPTION,
  UPDATE_SUPPLIER_OPTION,
  ARCHIVE_SUPPLIER_OPTION,
  REMOVE_SUPPLIER_OPTION,
  REMOVE_SUPPLIER_OPTION_FROM_COLLECTION,
  ADD_UPLOAD,
  IMPORT_SUPPLIER_OPTIONS
};


const supplierOptionsLoadRequest = (account) => {
  return {
    type: SUPPLIER_OPTIONS_LOAD_REQUESTED,
    account
  };
};

const supplierOptionsLoadSuccess = (payload, account) => {
  return {
    type: SUPPLIER_OPTIONS_LOAD_SUCCESS,
    payload,
    account
  };
};

const supplierOptionsLoadProcessed = (payload, account) => {
  return {
    type: SUPPLIER_OPTIONS_LOAD_REPLACE,
    payload,
    account
  };
};

const supplierOptionsLoadFailure = (error) => {
  return {
    type: SUPPLIER_OPTIONS_LOAD_FAILURE,
    error
  };
};

const supplierOptionsClear = () => {
  return {
    type: SUPPLIER_OPTIONS_CLEAR
  };
};

const addSupplierOption = (ingredientData, newIng, callback) => {
  return {
    type: ADD_SUPPLIER_OPTION,
    ingredient: ingredientData,
    newIngredient: newIng,
    callback
  };
};

const updateSupplierOption = (ingredientData, newIng, callback) => {
  return {
    type: UPDATE_SUPPLIER_OPTION,
    ingredient: ingredientData,
    newIngredient: newIng,
    callback
  };
};

const archiveSupplierOption = (ingredientId) => {
  return {
    type: ARCHIVE_SUPPLIER_OPTION,
    ingredientId
  };
};

const removeSupplierOption = (ingredientId) => {
  return {
    type: REMOVE_SUPPLIER_OPTION,
    ingredientId
  };
};

const removeSupplierOptionFromCollection = (accountId, ingredientId, optionId, shouldArchive = false) => {
  return {
    type: REMOVE_SUPPLIER_OPTION_FROM_COLLECTION,
    payload: { accountId, ingredientId, optionId, shouldArchive }
  }
};

const addUpload = (file, callback) => {
  return {
    type: ADD_UPLOAD,
    file,
    callback
  };
};

const importSupplierOptions = (options, callback) => {
  return {
    type: IMPORT_SUPPLIER_OPTIONS,
    options,
    callback
  };
};

export const actions = {
  supplierOptionsLoadRequest,
  supplierOptionsLoadSuccess,
  supplierOptionsLoadFailure,
  supplierOptionsLoadProcessed,
  supplierOptionsClear,
  addSupplierOption,
  updateSupplierOption,
  archiveSupplierOption,
  removeSupplierOption,
  removeSupplierOptionFromCollection,
  addUpload,
  importSupplierOptions
};
