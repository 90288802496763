//React
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Text, UnorderedList, ListItem, TextInput, Dialog, Table, useTheme } from 'evergreen-ui';

//Components
import EditableText from '../../../components/EditableText/EditableText';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';


const fieldContains = (field, needle) => {
  return (item) => {
    return (item[field]) ? _.includes(_.lowerCase(item[field]), _.lowerCase(needle)) : false;
  };
};


const MenuEdit = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { accountId, menuId } = useParams();
  const recipeMap = useSelector((state) => _.keyBy(current(state, 'recipes', accountId), 'id'));
  const originalMenu = useSelector((state) => _.find(current(state, 'menus', accountId), { 'id': menuId }));
  const [menuInfo, setMenuInfo] = useState(originalMenu);
  const [newSectionName, setNewSectionName] = useState('');
  const [addingToSection, setAddingToSection] = useState(false);
  const [recipeFilter, setRecipeFilter] = useState('');
  const [removingSection, setRemovingSection] = useState(false);
  const filteredRecipes = (recipeFilter) ? _.filter(recipeMap, fieldContains('name', recipeFilter)) : recipeMap;

  useEffect(() => {
    if (_.isEmpty(menuInfo) && !_.isEmpty(originalMenu)) {
      setMenuInfo(originalMenu);
    }
  }, [originalMenu, menuInfo]);

  const saveMenu = () => {
    dispatch(actions.menus.updateMenu(accountId, menuInfo));
  };

  const addSection = () => {
    if (newSectionName) {
      setMenuInfo((prevValues) => ({
        ...prevValues,
        sections: _.concat(prevValues.sections, { name: newSectionName, recipes: [] })
      }));
      setNewSectionName('');
    }
    else {
      dispatch(actions.appMessageError('You must provide a name for the new section'));
    }
  };

  const removeSection = () => {
    setMenuInfo((prevValues) => ({
      ...prevValues,
      sections: _.concat(_.slice(prevValues.sections, 0, removingSection), _.slice(prevValues.sections, removingSection + 1))
    }));
    setRemovingSection(false);
  };

  const addRecipeToSection = (recipeId) => {
    setMenuInfo((prevValues) => {
      const sectionIndex = addingToSection.sectionIndex;
      const sections = [...prevValues.sections];
      if (addingToSection.atIndex !== false) {
        sections[sectionIndex].recipes[addingToSection.atIndex] = recipeId;
      }
      else {
        sections[sectionIndex].recipes.push(recipeId);
      }

      return { ...prevValues, sections };
    });
    setAddingToSection(false);
    setRecipeFilter('');
  };

  const removeRecipeFromSection = (sectionIndex, recipeId) => {
    setMenuInfo((prevValues) => {
      const sections = [...prevValues.sections];
      const editSection = sections[sectionIndex];
      const recipeIndex = _.findIndex(editSection.recipes, (rec) => rec === recipeId);
      const recipes = _.concat(_.slice(editSection.recipes, 0, recipeIndex), _.slice(editSection.recipes, recipeIndex + 1));
      sections[sectionIndex] = { ...editSection, recipes };

      return { ...prevValues, sections };
    });
  };

  const onMenuNameChange = (newValue) => {
    setMenuInfo((prevValues) => ({ ...prevValues, name: newValue }));
  };

  const onSectionNameChange = (section, sectionIndex, newValue) => {
    setMenuInfo((prevValues) => {
      const sections = [...prevValues.sections];
      sections[sectionIndex] = { ...section, name: newValue };

      return { ...prevValues, sections };
    });
  };


  if (!menuInfo) {
    return (
      <Block display="flex" alignItems="center" padding={majorScale(2)}>
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={Link}
            to={`/products/${accountId}/setup/menu`}
            marginRight={majorScale(2)}
        />
        <Text flex="1 0 auto">The requested menu could not be found.</Text>
      </Block>
    );
  }

  return (
    <React.Fragment>
      <Block
          backgroundColor={theme.colors.offwhite}
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
          display="flex"
          alignItems="center"
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={Link}
            to={`/products/${accountId}/setup/menu`}
            marginRight={majorScale(2)}
        />
        <EditableText flex="1 0 auto" value={menuInfo.name} onChange={(newValue) => onMenuNameChange(newValue)} />
        <Button appearance="primary" onClick={() => saveMenu()}>Save Changes</Button>
      </Block>

      {_.isEmpty(menuInfo.sections) && (
        <Block 
          backgroundColor={theme.colors.offwhite}
          elevation={1} 
          padding={majorScale(2)} 
          marginBottom={majorScale(2)}>
          <Text>No sections to display</Text>
        </Block>
      )}
      {_.map(menuInfo.sections, (section, index) => (
        <Block
            key={index}
            elevation={1}
            padding={majorScale(2)}
            marginBottom={majorScale(2)}
        >
          <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
            <EditableText
                appearance="clickable"
                name="edit"
                flex="1 0 auto"
                value={section.name}
                onChange={(newValue) => onSectionNameChange(section, index, newValue)}
            />
            <IconWrapper
                name="trash"
                appearance="danger"
                marginX={majorScale(1)}
                onClick={() => setRemovingSection(index)}
            />
          </Pane>
          <UnorderedList
              listStyle="none"
              marginX={0}
              paddingLeft={majorScale(2)}
              marginBottom={majorScale(2)}
          >
            {_.map(section.recipes, (recipeId, recipeIndex) => {
              const recipe = recipeMap[recipeId];
              if (!recipe) {
                console.error(`Recipe with ID ${recipeId} not found in recipeMap.`);
                return null;
              }
              return (
                <ListItem key={`${recipeIndex}-${recipeId}`} display="flex" alignItems="center" marginBottom={majorScale(2)}>
                  <Text flex="1 0 auto">{recipe.name}</Text>
                  <IconWrapper
                      name="edit"
                      appearance="clickable"
                      marginX={majorScale(1)}
                      onClick={() => setAddingToSection({ section, sectionIndex: index, atIndex: recipeIndex })}
                  />
                  <IconWrapper
                      name="trash"
                      appearance="danger"
                      marginX={majorScale(1)}
                      onClick={() => removeRecipeFromSection(index, recipeId)}
                  />
                </ListItem>
              );
            })}

          </UnorderedList>
          <Button 
            appearance="minimal"
            onClick={() => setAddingToSection({ section, sectionIndex: index, atIndex: false })}
          >New recipe</Button>
        </Block>
      ))}

      <Block elevation={1} paddingX={majorScale(2)} paddingY={majorScale(1)} marginBottom={majorScale(2)}>
        <TextInput
            placeholder="Section Name"
            marginRight={majorScale(2)}
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
        />
        <Button 
          appearance="minimal"
          onClick={() => addSection()}>Add section</Button>
      </Block>

      <CustomDialog
          isOpen={!!addingToSection}
          onClose={() => setAddingToSection(false)}
          hasFooter={false}
          title="New recipe"
      >
        <Pane marginBottom={majorScale(2)} >
          <FilterBar searchPlaceholder="Search recipes" searchOnChange={setRecipeFilter} />
          <Table>
            {_.isEmpty(filteredRecipes) && (
              <Table.Row>
                <Table.TextCell>No recipes found</Table.TextCell>
              </Table.Row>
            )}
            {_.map(filteredRecipes, (recipe) => (
              <Table.Row key={recipe.id} isSelectable onSelect={() => addRecipeToSection(recipe.id)}>
                <Table.TextCell>{recipe.name}</Table.TextCell>
              </Table.Row>
            ))}
          </Table>
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={removingSection !== false}
          onClose={() => setRemovingSection(false)}
          intent="danger"
          title="Remove Menu Section"
          confirmLabel="Remove"
          onConfirm={() => removeSection()}
      >
        {removingSection !== false && (
          <Pane marginBottom={majorScale(2)} >
            <Text>Are you sure you wish to remove the entire "{menuInfo.sections[removingSection].name}" section?</Text>
          </Pane>
        )}
      </CustomDialog>
    </React.Fragment>
  );
};

MenuEdit.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default MenuEdit;
