const PREP_TASKS_LOAD_SUCCESS = 'PREP_TASKS_LOAD_SUCCESS';
const PREP_TASKS_CLEAR = 'PREP_TASKS_CLEAR';
const ADD_PREP_TASK = 'ADD_PREP_TASK';
const REMOVE_PREP_TASK = 'REMOVE_PREP_TASK';
const UPDATE_PREP_TASK = 'UPDATE_PREP_TASK';

export const actionTypes = {
  PREP_TASKS_LOAD_SUCCESS,
  PREP_TASKS_CLEAR,
  ADD_PREP_TASK,
  REMOVE_PREP_TASK,
  UPDATE_PREP_TASK
};


const prepTasksLoadSuccess = (payload, account) => {
  return {
    type: PREP_TASKS_LOAD_SUCCESS,
    payload,
    account
  };
};

const prepTasksClear = () => {
  return {
    type: PREP_TASKS_CLEAR
  };
};

const addPrepTask = (accountId, task) => {
  return {
    type: ADD_PREP_TASK,
    task,
    accountId,
  };
};

const removePrepTask = (accountId, taskId) => {
  return {
    type: REMOVE_PREP_TASK,
    taskId,
    accountId,
  };
};

const updatePrepTask = (accountId, task) => {
  return {
    type: UPDATE_PREP_TASK,
    task,
    accountId,
  };
};

export const actions = {
  prepTasksLoadSuccess,
  prepTasksClear,
  addPrepTask,
  removePrepTask,
  updatePrepTask
};
