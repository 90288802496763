//React
import React from 'react';

//Libraries
import { Pane, majorScale } from 'evergreen-ui'

//Components
import Period from './Period';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { TODAY } from '../../utils/time';


const PeriodWeeks = ({ seeNextWeek, seePrevWeek, weekInfo }) => {
  //console.log(weekInfo, "PERIODWEKK")

  return (
    <Pane
        alignSelf="center"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex="1 0 auto"
        maxWidth={460}
        width="100%"
    >
      <IconWrapper
          appearance="clickable"
          name="arrowLeft"
          onClick={() => seePrevWeek()}
          marginRight={majorScale(2)}
      />
      <Period periodStart={weekInfo.start} periodEnd={weekInfo.end} />
      <IconWrapper
          appearance="clickable"
          name="arrowRight"
          onClick={() => seeNextWeek()}
          marginLeft={majorScale(2)}
          disabled={weekInfo.end >= TODAY}
      />
    </Pane>
  )
}

export default PeriodWeeks;
