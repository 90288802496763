import { getCollectionRef } from './common';

export const addReceipts = (receipts, accountId) => {
  const receiptsRef = getCollectionRef('receipts', accountId);
  return receiptsRef.add(receipts);
};

export const removeReceipts = (receiptsId, accountId) => {
  const receiptsRef = getCollectionRef('receipts', accountId);
  return receiptsRef.doc(receiptsId).delete();
};

export const updateReceipts = (receiptsId, receipts, accountId) => {
  const receiptsRef = getCollectionRef('receipts', accountId);
  return receiptsRef.doc(receiptsId).update(receipts);
};

export const toggleArchiveReceipt = (receiptId, receipt, accountId) => {
  const receiptsRef = getCollectionRef('receipts', accountId);
  return receiptsRef.doc(receiptId).update({ archive: receipt.archive ? false : true  });
};
