const REPORTS_INCIDENTALS_LOAD_SUCCESS = 'REPORTS_INCIDENTALS_LOAD_SUCCESS';
const REPORTS_INCIDENTALS_LOAD_FAILURE = 'REPORTS_INCIDENTALS_LOAD_FAILURE';
const REPORTS_INCIDENTALS_CLEAR = 'REPORTS_INCIDENTALS_CLEAR';
const SET_WEEKLY_INCIDENTALS = 'SET_WEEKLY_INCIDENTALS';
const SET_YEARLY_INCIDENTALS = 'SET_YEARLY_INCIDENTALS';

export const actionTypes = {
  REPORTS_INCIDENTALS_LOAD_SUCCESS,
  REPORTS_INCIDENTALS_LOAD_FAILURE,
  REPORTS_INCIDENTALS_CLEAR,
  SET_WEEKLY_INCIDENTALS,
  SET_YEARLY_INCIDENTALS,
};


const reportsIncidentalsLoadSuccess = (payload, account) => {
  return {
    type: REPORTS_INCIDENTALS_LOAD_SUCCESS,
    payload,
    account,
  };
};

const reportsIncidentalsLoadFailure = (error) => {
  return {
    type: REPORTS_INCIDENTALS_LOAD_FAILURE,
    error,
  };
};

const reportsIncidentalsClear = () => {
  return {
    type: REPORTS_INCIDENTALS_CLEAR,
  };
};

const setWeeklyIncidentals = (accountId, incidentals) => {
  return {
    type: SET_WEEKLY_INCIDENTALS,
    incidentals,
    accountId,
  };
};

const setYearlyIncidentals = (accountId, incidentals) => {
  return {
    type: SET_YEARLY_INCIDENTALS,
    incidentals,
    accountId,
  };
};

export const actions = {
  reportsIncidentalsLoadSuccess,
  reportsIncidentalsLoadFailure,
  reportsIncidentalsClear,
  setWeeklyIncidentals,
  setYearlyIncidentals,
};
