import { getCollectionRef } from './common'

export const addFinancial = (financial, accountId) => {
  const financialsRef = getCollectionRef('financials', accountId);
  return financialsRef.add(financial);
};

export const removeFinancial = (financialId, accountId) => {
  const financialsRef = getCollectionRef('financials', accountId);
  return financialsRef.doc(financialId).delete();
};

export const updateFinancial = (financialId, financial, accountId) => {
  const financialsRef = getCollectionRef('financials', accountId);
  return financialsRef.doc(financialId).update(financial);
};
