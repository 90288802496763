//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { fullDateDisplayFormat } from '../../../utils/format';

const DatePanel = (props) => {
  const { date, decrementDate, incrementDate } = props;
  const theme = useTheme();


  return (
    <Pane
        background={theme.colors.primary10}
        elevation={1}
        borderRadius={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={majorScale(2)}
    >
      <Pane
          maxWidth={360}
          flex="1 0 auto"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY={majorScale(1)}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            marginRight={majorScale(2)}
            onClick={() => decrementDate()}
        />
        <Text color={theme.colors.black} size={600}>{fullDateDisplayFormat(date)}</Text>
        <IconWrapper
            appearance="clickable"
            name="arrowRight"
            marginLeft={majorScale(2)}
            onClick={() => incrementDate()}
        />
      </Pane>
    </Pane>
  );
};

DatePanel.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  incrementDate: PropTypes.func.isRequired,
  decrementDate: PropTypes.func.isRequired
};


export default DatePanel;
