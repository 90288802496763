const RECEIPTS_LOAD_FAILURE = 'RECEIPTS_LOAD_FAILURE';
const RECEIPTS_LOAD_SUCCESS = 'RECEIPTS_LOAD_SUCCESS';
const RECEIPT_LOAD_SUCCESS = 'RECEIPT_LOAD_SUCCESS';
const RECEIPTS_CLEAR = 'RECEIPTS_CLEAR';
const ADD_RECEIPTS = 'ADD_RECEIPTS';
const REMOVE_RECEIPTS = 'REMOVE_RECEIPTS';
const UPDATE_RECEIPTS = 'UPDATE_RECEIPTS';
const REQUEST_RECEIPTS_LOAD = 'REQUEST_RECEIPTS_LOAD';
const TOGGLE_ARCHIVE_RECEIPT = 'TOGGLE_ARCHIVE_RECEIPT';
const RECEIPT_TOGGLE_SUCCESS = 'RECEIPT_TOGGLE_SUCCESS';

export const actionTypes = {
  RECEIPTS_LOAD_FAILURE,
  RECEIPTS_LOAD_SUCCESS,
  RECEIPT_LOAD_SUCCESS,
  RECEIPTS_CLEAR,
  ADD_RECEIPTS,
  REMOVE_RECEIPTS,
  UPDATE_RECEIPTS,
  REQUEST_RECEIPTS_LOAD,
  TOGGLE_ARCHIVE_RECEIPT,
  RECEIPT_TOGGLE_SUCCESS
};

const receiptLoadSuccess = (payload, account) => {
  return {
    type: RECEIPT_LOAD_SUCCESS,
    payload,
    account
  };
};

const receiptsLoadSuccess = (payload, account) => {
  return {
    type: RECEIPTS_LOAD_SUCCESS,
    payload,
    account
  };
};

const receiptsLoadFailure = () => {
  return {
    type: RECEIPTS_LOAD_FAILURE
  };
};

const receiptsClear = () => {
  return {
    type: RECEIPTS_CLEAR
  };
};

const addReceipts = (accountId, receipts) => {
  return {
    type: ADD_RECEIPTS,
    receipts,
    accountId,
  };
};

const removeReceipts = (accountId, receiptsId) => {
  return {
    type: REMOVE_RECEIPTS,
    receiptsId,
    accountId,
  };
};

const updateReceipts = (accountId, receipts) => {
  return {
    type: UPDATE_RECEIPTS,
    receipts,
    accountId,
  };
};

const receiptsLoadRequest = (accountId) => {
  return {
    type: REQUEST_RECEIPTS_LOAD,
    accountId,
  };
};

const toggleArchiveReceipt = (receipt, accountId) => {
  return {
    type: TOGGLE_ARCHIVE_RECEIPT,
    receipt,
    accountId
  };
};

const receiptToggleSuccess = (payload, account) => {
  return {
    type: RECEIPT_TOGGLE_SUCCESS,
    payload,
    account
  };
};

export const actions = {
  receiptsLoadFailure,
  receiptsLoadSuccess,
  receiptLoadSuccess,
  receiptsClear,
  addReceipts,
  removeReceipts,
  updateReceipts,
  receiptsLoadRequest,
  toggleArchiveReceipt,
  receiptToggleSuccess
};
