const SALES_LOAD_FAILURE = 'SALES_LOAD_FAILURE';
const SALES_LOAD_SUCCESS = 'SALES_LOAD_SUCCESS';
const SALE_LOAD_SUCCESS = 'SALE_LOAD_SUCCESS';
const SALES_CLEAR = 'SALES_CLEAR';
const ADD_SALES = 'ADD_SALES';
const REMOVE_SALES = 'REMOVE_SALES';
const UPDATE_SALES = 'UPDATE_SALES';
const REQUEST_SALES_LOAD = 'REQUEST_SALES_LOAD';

export const actionTypes = {
  SALES_LOAD_FAILURE,
  SALES_LOAD_SUCCESS,
  SALE_LOAD_SUCCESS,
  SALES_CLEAR,
  ADD_SALES,
  REMOVE_SALES,
  UPDATE_SALES,
  REQUEST_SALES_LOAD
};

const saleLoadSuccess = (payload, account) => {
  return {
    type: SALE_LOAD_SUCCESS,
    payload,
    account
  };
};

const salesLoadSuccess = (payload, account) => {
  return {
    type: SALES_LOAD_SUCCESS,
    payload,
    account
  };
};

const salesLoadFailure = () => {
  return {
    type: SALES_LOAD_FAILURE
  };
};

const salesClear = () => {
  return {
    type: SALES_CLEAR
  };
};

const addSales = (accountId, sales) => {
  return {
    type: ADD_SALES,
    sales,
    accountId,
  };
};

const removeSales = (accountId, salesId) => {
  return {
    type: REMOVE_SALES,
    salesId,
    accountId,
  };
};

const updateSales = (accountId, sales) => {
  return {
    type: UPDATE_SALES,
    sales,
    accountId,
  };
};

const salesLoadRequest = (accountId) => {
  return {
    type: REQUEST_SALES_LOAD,
    accountId,
  };
};

export const actions = {
  salesLoadFailure,
  salesLoadSuccess,
  saleLoadSuccess,
  salesClear,
  addSales,
  removeSales,
  updateSales,
  salesLoadRequest
};
