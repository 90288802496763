//React
import React from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { Heading, majorScale, Pane, Text } from 'evergreen-ui';

//Components
import LightspeedLSeries from '../IntegrationsComponents/LightspeedLSeries';
import DeliverectOwner from '../IntegrationsComponents/DeliverectOwner';
import Deliverectlocations from '../IntegrationsComponents/DeliverectLocations';

//Files
import { accountTypes } from '../../../utils/constants';


const IntegrationsTab = ({accountInfo, ...rest}) => {
  const hasDeliverectOwner = useSelector((state) => {
    if (accountInfo.parents?.length > 0) {
      for (const pid of accountInfo.parents) {
        if (state.accounts[pid] && state.accounts[pid].has_deliverect) {
          return true;
        }
      }
    }

    return false;
  });
  return (
    <>
      <Pane>
        <Heading is="h2" marginY={majorScale(2)}>POS  Integrations</Heading>
      </Pane>
      <Pane>
        <Text>Select your POS systems</Text>
      </Pane>
      <Pane display='grid' gridTemplateColumns={'repeat(3, 1fr)'} gap={majorScale(2)}>
        {accountInfo.type === accountTypes.DEPARTMENT && (
          <>
            <LightspeedLSeries
              accountInfo={accountInfo}
              {...rest}
            />
            {hasDeliverectOwner && (
              <Deliverectlocations
                accountInfo={accountInfo}
                {...rest}
              />
            )}
          </>
        )}
        {accountInfo.type === accountTypes.OWNER && (
          <>
            <DeliverectOwner
              accountInfo={accountInfo}
              {...rest}
            />
          </>
        )}
      </Pane>
    </>
  )
}

export default IntegrationsTab;
