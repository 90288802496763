import _ from 'lodash';
import store from '../redux';
import fb from './';
//
import { actions } from '../actions';

let accountUsersListener
const accountListeners = {}
const accountUsersRef = fb.firestore.collection('accountUsers')

const unlistenAccounts = () => {
  for (let id in accountListeners) {
    accountListeners[id]();
    delete accountListeners[id];
  }
};

// exported methods & fields
export const accountsRef = fb.firestore.collection('accounts')

// Adds a listener for a specific account, dispatching Redux actions on updates.
const addAccountListener = (accountId) => {
  store.dispatch(actions.accounts.accountLoadRequest(accountId));
  store.dispatch(actions.applicationLoadStart(accountId));
  const accountListener = accountsRef.doc(accountId).onSnapshot((doc) => {
    const account = { ...doc.data(), id: doc.id };
    if (account.children) {
      _.forEach(account.children, (childAccountId) => {
        if (!accountListeners[childAccountId]) {
          addAccountListener(childAccountId);
        }
      });
    }
    if (!account.deleted) {
      store.dispatch(actions.accounts.accountLoaded(account));
    }
    store.dispatch(actions.applicationLoadComplete(accountId));
  });
  accountListeners[accountId] = accountListener;
};

const setAccountNull = () => {
  store.dispatch(actions.applicationLoaded());
  store.dispatch(actions.profile.profileLoadRequest());
}

// Loads all account documents associated with the provided user email.
export const loadAccounts = (userEmail) => {
  //console.log(`Attempting to load accounts for user: ${userEmail}`);
  if (accountUsersListener) { 
    //console.log("Detaching existing account users listener.");
    accountUsersListener() 
  }
  unlistenAccounts()
  store.dispatch(actions.accounts.accountsClear());
  //console.log(`loading: accounts using accountUsers`)
  accountUsersListener = accountUsersRef
    .where('email', '==', userEmail)
    .onSnapshot((querySnapshot) => {
      //console.log(`Received account user data for email: ${userEmail}`);
      querySnapshot.forEach((audoc) => {
        const accountUser = audoc.data();
        //console.log(`Processing account user: ${accountUser.account}`);
        if (!accountListeners[accountUser.account]) {
          //console.log(`Adding listener for account: ${accountUser.account}`);
          addAccountListener(accountUser.account);
        }
      });
    });
  if (_.isEmpty(accountListeners)) setAccountNull();
}

// Removes all event listeners and clears cached data for account documents.
export const clearAccount = (accountId) => {
  if (accountListeners[accountId]) {
    accountListeners[accountId]();
  }
};

// This function removes all active listeners for accounts and clears any cached data.
export const clearAccounts = () => {
  unlistenAccounts()
}

// Adds a new account document to Firestore and returns the reference.
export const addAccount = (account) => {
  return accountsRef.add(account)
}

// Removes an account document from Firestore.
export const removeAccount = (accountId) => {
  return accountsRef.doc(accountId).delete()
}

// Updates a specific account document in Firestore with new data.
export const updateAccount = (accountId, account) => {
  return accountsRef.doc(accountId).update(account)
}
