//React
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table, Heading, Avatar, Tablist, Pane } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Tab from '../../../components/ui/Tab/Tab';
import TaskCommentList from '../../../components/TaskCommentList/TaskCommentList';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';
import Button from '../../../components/ui/Button/Button';

//Files
import { getWE, minDate, TODAY } from '../../../utils/time';
import { foodRecordTypes, foodRecordTargets } from '../../../utils/constants';
import { current } from '../../../utils/selectors';
import { generatePDF } from '../../../utils/exportContent'


const DueDiligenceDetail = () => {
  const { accountId, weekOf } = useParams();
  const contentRef = useRef();
  const weekStart = moment.utc(weekOf, 'YYYY-WW').toDate();
  const weekEnd = getWE(weekStart);
  const taskHistory = useSelector((state) =>
    _.filter(current(state, 'tasksHistory', accountId), (item) => 
      moment((item.date) ? item.date.toDate() : '').isBetween(weekStart, weekEnd, undefined, '[]')
    )
  );
  const taskComments = useSelector((state) => 
    _.orderBy(
      _.filter(current(state, 'tasksComments', accountId), (comment) => 
        moment((comment.date) ? comment.date.toDate() : '').isBetween(weekStart, weekEnd, undefined, '[]')
      ),
      'created'
    )
  );
  const foodRecords = useSelector((state) => 
    _.orderBy(
      _.filter(current(state, 'foodRecords', accountId), (record) =>
        moment((record.date) ? record.date.toDate() : '').isBetween(weekStart, weekEnd, undefined, '[]') && !record.ongoing
      ),
      'time'
    )
  );
  const orderRecords = useSelector((state) => 
    _.orderBy(
      _.filter(current(state, 'orderRecords', accountId), (record) =>
        moment((record.date) ? moment(record.date, "YYYY-MM-DD").toDate() : '').isBetween(weekStart, weekEnd, undefined, '[]')
      ),
      'time'
    )
  );
  const orders = useSelector((state) => 
    _.orderBy(
      _.filter(current(state, 'orders', accountId), (orders) =>
        moment((orders.deliveryDate) ? moment(orders.deliveryDate).toDate() : '').isBetween(weekStart, weekEnd, undefined, '[]')
      ),
      'time'
    )
  );

  // Initialise the tabs for weekdays, up to and including the current date
  let dayTabs = {};
  let dayIndex = moment(weekStart);
  while (dayIndex.isSameOrBefore(minDate(weekEnd, TODAY))) {
    dayTabs[dayIndex.format('YYYY-MM-DD')] = dayIndex.format('dddd');
    dayIndex.add(1, 'day');
  }
  const [tabIndex, setTabIndex] = useState(_.keys(dayTabs)[0]);
  // Split tasks into Open/Close/Fridge/etc.
  const openTasks = _.filter(taskHistory, (item) => {
    return (moment((item.date) ? item.date.toDate() : '').isSame(tabIndex, 'day') && item.type === 'OPEN');
  });
  const closeTasks = _.filter(taskHistory, (item) => {
    return (moment((item.date) ? item.date.toDate() : '').isSame(tabIndex, 'day') && item.type === 'CLOSE');
  });
  const fridgeTasks = _.filter(taskHistory, (item) => {
    return (moment((item.date) ? item.date.toDate() : '').isSame(tabIndex, 'day') && item.type === 'FRIDGE');
  });
  const probeTasks = _.filter(taskHistory, (item) => {
    return (moment((item.date) ? item.date.toDate() : '').isSame(tabIndex, 'day') && item.type === 'PROBE');
  });
  // Do the same for comments
  const comments = _.groupBy(
    _.filter(taskComments, (comment) => 
      moment((comment.date) ? comment.date.toDate() : '').isSame(tabIndex, 'day')
    ),
    'type'
  );
  // Filter food records for the date
  const records = _.filter(foodRecords, (record) => moment((record.date) ? record.date.toDate() : '').isSame(tabIndex, 'day'));
  const dateOrders = _.filter(orderRecords, (record) => moment((record.date) ? moment(record.date, "YYYY-MM-DD").toDate() : '').isSame(tabIndex, 'day'))
  // Filter deliveries for the date
  const deliveries = _.filter(orders, (order) => moment((order.deliveryDate) ? moment(order.deliveryDate).toDate() : '').isSame(tabIndex, 'day'))

  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));


  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)} display="flex" flexDirection="column">
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <Pane display="flex" alignItems="center">
            <IconWrapper
                appearance="clickable"
                name="arrowLeft"
                is={Link}
                to={`/${accountId}/review/due-diligence`}
                marginRight={majorScale(2)}
            />
            <CustomHeading level="3">Due Diligence - {moment(weekStart).format('Do MMMM YYYY')} to {moment(weekEnd).format('Do MMMM YYYY')}</CustomHeading>
          </Pane>
          <Button 
            name="Download"
            onClick={() => generatePDF(contentRef.current, weekOf)}
            appearance='primary'
          >
            Download as PDF
          </Button>
        </Pane>
        <Tablist display="flex" marginTop={majorScale(2)}>
          {_.map(dayTabs, (_, tabKey) => (
            <Tab
                key={tabKey}
                isSelected={tabIndex === tabKey}
                onSelect={() => setTabIndex(tabKey)}
                height={32}
                paddingX={majorScale(1)}
                minWidth="auto"
                flexBasis="calc(100% / 7)"
            >{moment(tabKey, 'YYYY-MM-DD').format('dddd Do')}</Tab>
          ))}
        </Tablist>
      </Block>

      
      <Pane ref={contentRef}>
      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4" padding={majorScale(2)}>Opening Checklist</CustomHeading>
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell flex="0 1 50%">Task</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initial</Table.TextHeaderCell>
          </Table.Head>
          {_.isEmpty(openTasks) && (
            <Table.Row height={48}>
              <Table.TextCell>No tasks completed</Table.TextCell>
            </Table.Row>
          )}
          {_.map(openTasks, (task) => (
            <Table.Row height={48} key={task.id}>
              <Table.TextCell flex="0 1 50%">{task.action}</Table.TextCell>
              <Table.Cell>
                <Avatar size={24} name={task.userName} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
        {!_.isEmpty(comments['OPEN']) && (
          <Pane padding={majorScale(2)}>
            <TaskCommentList comments={comments['OPEN']} />
          </Pane>
        )}
      </Block>

      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4" padding={majorScale(2)}>Fridge Temperatures</CustomHeading>
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Target</Table.TextHeaderCell>
            <Table.TextHeaderCell>AM</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initials</Table.TextHeaderCell>
            <Table.TextHeaderCell>PM</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initials</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(fridgeTasks) && (
              <Table.Row height={48}>
                <Table.TextCell>No temperatures to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(fridgeTasks, (record) => (
              <React.Fragment key={record.id}>
                <Table.Row height={48}>
                  <Table.TextCell>{record.action}</Table.TextCell>
                  <Table.TextCell>{record.target}</Table.TextCell>
                  <Table.TextCell>{record.amTemperature}°C</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.amUsername} />
                  </Table.Cell>
                  <Table.TextCell>{record.pmTemperature}°C</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.pmUsername} />
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        {!_.isEmpty(comments['FRIDGE']) && (
          <Pane padding={majorScale(2)}>
            <TaskCommentList comments={comments['FRIDGE']} />
          </Pane>
        )}
      </Block>

      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4"  padding={majorScale(2)}>Probe Temperatures</CustomHeading>
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>BOILING</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initials</Table.TextHeaderCell>
            <Table.TextHeaderCell>ICED</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initials</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(probeTasks) && (
              <Table.Row height={48}>
                <Table.TextCell>No temperatures to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(probeTasks, (record) => (
              <React.Fragment key={record.id}>
                <Table.Row height={48}>
                  <Table.TextCell>{record.action}</Table.TextCell>
                  <Table.TextCell>{record.boilingTemperature}°C</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.boilingUsername} />
                  </Table.Cell>
                  <Table.TextCell>{record.icedTemperature}°C</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.icedUsername} />
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        {!_.isEmpty(comments['PROBE']) && (
          <Pane padding={majorScale(2)}>
            <TaskCommentList comments={comments['PROBE']} />
          </Pane>
        )}
      </Block>

      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4"  padding={majorScale(2)}>Delivery Record</CustomHeading>
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell>Supplier</Table.TextHeaderCell>
            <Table.TextHeaderCell>Target</Table.TextHeaderCell>
            <Table.TextHeaderCell>Product</Table.TextHeaderCell>
            <Table.TextHeaderCell>Invoice No</Table.TextHeaderCell>
            <Table.TextHeaderCell>Temp</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initials</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(deliveries) && _.isEmpty(dateOrders) && (
              <Table.Row height={48}>
                <Table.TextCell>No deliveries to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(deliveries, (record) => (
              <React.Fragment key={record.id}>
                <Table.Row height={48}>
                  <Table.TextCell>{supplierMap[record.supplierId].name}</Table.TextCell>
                  <Table.TextCell>{supplierMap[record.supplierId].targettemperature}</Table.TextCell>
                  <Table.TextCell>{supplierMap[record.supplierId].typeofproducts}</Table.TextCell>
                  <Table.TextCell>{record.invoice_number}</Table.TextCell>
                  <Table.TextCell>{record.temperature}</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.userName} />
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
            {_.map(dateOrders, (record) => (
              <React.Fragment key={record.id}>
                <Table.Row>
                  <Table.TextCell>{record.name}</Table.TextCell>
                  <Table.TextCell></Table.TextCell>
                  <Table.TextCell></Table.TextCell>
                  <Table.TextCell>{record.invoice_number}</Table.TextCell>
                  <Table.TextCell>{record.temperature}</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.userName} />
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        {!_.isEmpty(comments['DELIVERY']) && (
          <Pane padding={majorScale(2)}>
            <TaskCommentList comments={comments['DELIVERY']} />
          </Pane>
        )}
      </Block>

      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4" padding={majorScale(2)}>Food Record</CustomHeading>
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell>Food</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Target</Table.TextHeaderCell>
            <Table.TextHeaderCell>Time</Table.TextHeaderCell>
            <Table.TextHeaderCell>Temp</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initials</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(records) && (
              <Table.Row height={48}>
                <Table.TextCell>No records to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(records, (record) => (
              <React.Fragment key={record.id}>
                <Table.Row height={48}>
                  <Table.TextCell>{record.ingredient}</Table.TextCell>
                  <Table.TextCell>{foodRecordTypes[record.type]}</Table.TextCell>
                  <Table.TextCell>{foodRecordTargets[record.type]}</Table.TextCell>
                  <Table.TextCell>{record.time}</Table.TextCell>
                  <Table.TextCell>{record.temperature}°C</Table.TextCell>
                  <Table.Cell>
                    <Avatar size={24} name={record.userName} />
                  </Table.Cell>
                </Table.Row >
                {_.map(record.steps, (step, index) => (
                  <Table.Row height={48} key={index}>
                    <Table.TextCell></Table.TextCell>
                    <Table.TextCell>{foodRecordTypes[step.type]}</Table.TextCell>
                    <Table.TextCell>{foodRecordTargets[step.type]}</Table.TextCell>
                    <Table.TextCell>{step.time}</Table.TextCell>
                    <Table.TextCell>{step.temperature}°C</Table.TextCell>
                    <Table.Cell>
                      <Avatar size={24} name={step.userName || record.userName} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        {!_.isEmpty(comments['FOOD']) && (
          <Pane padding={majorScale(2)}>
            <TaskCommentList comments={comments['FOOD']} />
          </Pane>
        )}
      </Block>

      <Block marginBottom={majorScale(2)}>
        <CustomHeading level="4"  padding={majorScale(2)}>Closing Checklist</CustomHeading>
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell flex="0 1 50%">Task</Table.TextHeaderCell>
            <Table.TextHeaderCell>Initial</Table.TextHeaderCell>
          </Table.Head>
          {_.isEmpty(closeTasks) && (
            <Table.Row height={48}>
              <Table.TextCell>No tasks completed</Table.TextCell>
            </Table.Row>
          )}
          {_.map(closeTasks, (task) => (
            <Table.Row height={48} key={task.id}>
              <Table.TextCell flex="0 1 50%">{task.action}</Table.TextCell>
              <Table.Cell>
                <Avatar size={24} name={task.userName} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
        {!_.isEmpty(comments['CLOSE']) && (
          <Pane padding={majorScale(2)}>
            <TaskCommentList comments={comments['CLOSE']} />
          </Pane>
        )}
      </Block>
      </Pane>
    </React.Fragment>
  );
};

export default DueDiligenceDetail;
