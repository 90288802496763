import moment from 'moment';

export function dateDisplayFormat (d) {
  let dayAbr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  let monthAbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  try {
    return dayAbr[d.getUTCDay()] + ' ' + d.getUTCDate() + ' ' + monthAbr[d.getUTCMonth()];
  } catch (err) {
    d = new Date(d);
    return dayAbr[d.getUTCDay()] + ' ' + d.getUTCDate() + ' ' + monthAbr[d.getUTCMonth()];
  }
}

export function fullDateDisplayFormat (ts) {
  return moment.utc(ts).format('dddd DD MMM YYYY');
}

export const formatDate = (date) => {
  if (!date) return '';
  const formattedDate = date.toDate ? date.toDate() : new Date(date);
  return moment(formattedDate).format('DD/MM/YYYY');
};

export function shortDateDisplayFormat (ts) {
  return moment.utc(ts).format('DD MMM YYYY');
}

export function shortDayMonthDisplayFormat (ts) {
  return moment.utc(ts).format('DD/MM');
}
export function timeDisplayFormat (ts) {
  return moment.utc(ts).format('HH:mm');
}
export function datetimeDisplayFormat (ts) {
  return dateDisplayFormat(ts) + ' ' + timeDisplayFormat(ts)
}
export function dateFormat (ts) {
  return moment.utc(ts).toISOString();
}
export function dateStoreFormat (ts) {
  return moment.utc(ts).format('YYYY-MM-DD');
}
export function datetimeFormat (ts) {
  return moment.utc(ts).toISOString().split('.')[0];
}

export const datePeriodFormat = (start, end) => {
  const startMoment = moment.utc(start);
  //console.log(startMoment, 'STARTMOMENT')
  const endMoment = moment.utc(end);
  //console.log(endMoment, 'ENDMOMENT')
  let period = {
    from: startMoment.format('DD MMM'),
    to: endMoment.format('DD MMM YYYY')
  };

  // Adjust for the same month and year case
  if (startMoment.year() === endMoment.year()) {
    if (startMoment.month() === endMoment.month()) {
      period.from = startMoment.format('DD');  // Only day is needed if month and year are the same
    } else {
      period.from = startMoment.format('DD MMM');  // Include month if different within the same year
    }
  } else {
    period.from = startMoment.format('DD MMM YYYY');  // Include full date if different years
  }
  return period;
};

// DEPRECATED
export function onedp (num) {
  if (num) {
    return Number(Math.round(num + 'e1') + 'e-1').toFixed(1)
  } else {
    return ''
  }
}

// DEPRECATED
export function fourdp (num) {
  if (!num) return ''
  return num.toFixed(4)
}

export function toFixed (num, decimalPlaces = 0) {
  if (!isNaN(parseFloat(num))) {
    return parseFloat(num).toFixed(decimalPlaces);
  }
  return '';
}

export function currency (num) {
  if (num === null) {
    return ''
  }
  if (num === '') {
    return ''
  }
  if (isNaN(num)) {
    return ''
  }
  return Number(Math.round(num + 'e2') + 'e-2').toFixed(2);
}

/**
 * Converts number into currency format
 * @param {number} number    Number that should be converted.
 * @param {string} [decimalSeparator]    Decimal separator, defaults to '.'.
 * @param {string} [thousandsSeparator]    Thousands separator, defaults to ','.
 * @param {int} [nDecimalDigits]    Number of decimal digits, defaults to `2`.
 * @return {string} Formatted string (e.g. numberToCurrency(12345.67) returns '12,345.67')
 */
export function financialCurrency(number, decimalSeparator, thousandsSeparator, nDecimalDigits){
  //default values
  decimalSeparator = decimalSeparator || '.';
  thousandsSeparator = thousandsSeparator || ',';
  nDecimalDigits = nDecimalDigits == null? 2 : nDecimalDigits;

  var fixed = number.toFixed(nDecimalDigits), //limit/add decimal digits
      parts = new RegExp('^(-?\\d{1,3})((?:\\d{3})+)(\\.(\\d{'+ nDecimalDigits +'}))?$').exec( fixed ); //separate begin [$1], middle [$2] and decimal digits [$4]

  if(parts){ //number >= 1000 || number <= -1000
      return parts[1] + parts[2].replace(/\d{3}/g, thousandsSeparator + '$&') + (parts[4] ? decimalSeparator + parts[4] : '');
  }else{
      return fixed.replace('.', decimalSeparator);
  }
}

// DEPRECATED
export function reverseSort (data) {
  var keys = Object.keys(data).sort(function keyOrder (k1, k2) {
    if (k1 < k2) return +1
    else if (k1 > k2) return -1
    else return 0
  })
  var after = {}
  for (let i = 0; i < keys.length; i++) {
    after[keys[i]] = data[keys[i]]
    delete data[keys[i]]
  }
  for (let i = 0; i < keys.length; i++) {
    data[keys[i]] = after[keys[i]]
  }
  return data
}
