import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addMiseEnPlace, removeMiseEnPlace, updateMiseEnPlace } from '../firebase/miseEnPlaces';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* miseEnPlacesSaga() {
  yield takeEvery(actionTypes.miseEnPlaces.ADD_MISE_EN_PLACES, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addMiseEnPlace, action.miseEnPlace, accountId);
      yield put(actions.appMessageSuccess('Mise En Place Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Mise En Place could not be added'));
    }
  });
  yield takeEvery(actionTypes.miseEnPlaces.REMOVE_MISE_EN_PLACES, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(removeMiseEnPlace, action.miseEnPlacesId, accountId);
      yield put(actions.appMessageSuccess('Mise En Place Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Mise En Place could not be removed'));
    }
  });
  yield takeEvery(actionTypes.miseEnPlaces.UPDATE_MISE_EN_PLACES, function* (action) {
    const { id, ...miseEnPlace } = action.miseEnPlace;
    try {
      const accountId = yield select(activeAccount);
      yield call(updateMiseEnPlace, id, miseEnPlace, accountId);
      yield put(actions.appMessageSuccess('Mise En Place Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Mise En Place could not be updated'));
    }
  });
};
