//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Table, Checkbox, TextInputField, Paragraph, IconButton } from 'evergreen-ui';

//Components
import OpenClose from './OpenClose';
import RemovingTask from './RemovingTask';
import EditableText from '../../../components/EditableText/EditableText';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import useTaskSettings from '../../../hooks/useTaskSettings';

const dayOptions = [
  { label: 'Monday', value: 'M' },
  { label: 'Tuesday', value: 'T' },
  { label: 'Wednesday', value: 'W' },
  { label: 'Thursday', value: 'Th' },
  { label: 'Friday', value: 'F' },
  { label: 'Saturday', value: 'S' },
  { label: 'Sunday', value: 'Su' },
];

const DailyTab = () => {
  const { accountId } = useParams();
  console.log(accountId)
  const dispatch = useDispatch();
  const sections = useSelector((state) => _.sortBy(
    _.filter(
      current(state, 'sections', accountId),
      { 'type': 'DAILY' }
    ),
    (section) => (_.lowerCase(section.name))
  ));
  const [addingSection, setAddingSection] = useState(false);
  const [removingSection, setRemovingSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');
  const dailyTasks = useSelector((state) => _.filter(
    current(state, 'tasks', accountId),
    { 'type': 'DAILY', repeats: true }
  ));

  const filterFields = [
    { label: 'Section', name: 'section', options: sections.map((section) => ({ label: section.name, value: section.name })) },
    { label: 'Day', name: 'weekdayRepeats', options: dayOptions }
  ];
  const onWeekdayToggle = (weekday, isChecked) => {
    setEditingTask((prevValues) => {
      let repeats = (prevValues.weekdayRepeats) ? prevValues.weekdayRepeats.split('/') : [];
      if (isChecked) {
        repeats.push(weekday);
      }
      else {
        repeats = _.without(repeats, weekday);
      }

      return { ...prevValues, weekdayRepeats: repeats.join('/') };
    });
  };
  const doesRepeatOnDay = (weekdayRepeats, day) => {
    if (_.isEmpty(weekdayRepeats)) {
      return false;
    }
    return weekdayRepeats.split('/').includes(day);
  };
  const saveTask = () => {
    if (editingTask.id) {
      dispatch(actions.tasks.updateTask(accountId, editingTask));
    }
    else {
      dispatch(actions.tasks.addTask(accountId, { ...editingTask, type: 'DAILY', repeats: true }));
    }
    setEditingTask(false);
  };

  const cancelNewSection = () => {
    setAddingSection(false);
    setNewSectionName('');
  };
  const createNewSection = () => {
    if (!newSectionName) {
      dispatch(actions.appMessageError('You must enter a name for the section'));
      return;
    }
    dispatch(actions.sections.addSection(accountId, { name: newSectionName, type: 'DAILY' }));
  };
  const onSectionNameChange = (section, newValue) => {
    dispatch(actions.sections.updateSection(accountId, { ...section, displayName: newValue }));
  };
  const removeSectionConfirmed = (close) => {
    // TODO: Remove all tasks here as well for cleanup?
    dispatch(actions.sections.removeSection(accountId, removingSection.id));
    setRemovingSection(false);
    close();
  };

  const {
    setEditingTask,
    editingTask,
    filterTasks,
    setRemovingTask,
    removingTask,
    onFieldChange,
    removeConfirmed,
    onFilterChange,
    filters
  } = useTaskSettings({ accountId });

  const filteredSections = (filters['section']) ? _.filter(sections, { 'name': filters['section']}) : sections;
  const filteredTasks = _.groupBy(filterTasks(dailyTasks), 'section');

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Task lists</CustomHeading>
          <Button appearance="primary" onClick={() => setAddingSection(true)}>New Section</Button>
        </Pane>
        <FilterBar
            filterFields={filterFields}
            filters={filters}
            setFilter={onFilterChange}
            searchPlaceholder="Search ..."
            searchOnChange={(newSearchValue) => onFilterChange('search', newSearchValue)}
        />
      </Block>

      <OpenClose
        accountId={accountId}
        filterTasks={filterTasks}
      />

      {_.map(filteredSections, (section) => (
        <Block marginBottom={majorScale(2)} key={section.id}>
          <Pane
              display="flex"
              alignItems="center"
              justifyContent='space-between'
              marginBottom={majorScale(2)}
              padding={majorScale(2)}
          >
            <Pane display="flex">
              <EditableText
                  marginRight={majorScale(2)}
                  value={section.displayName || section.name}
                  onChange={(newValue) => onSectionNameChange(section, newValue)}
              />
              <IconWrapper
                  appearance="danger"
                  name="trash"
                  marginRight="auto"
                  onClick={() => setRemovingSection(section)}
              />
            </Pane>
            <Button 
              appearance="primary" 
              marginRight={majorScale(2)}
              onClick={() => setEditingTask({ section: section.name, repeats: true })}
            >New Task</Button>
          </Pane>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell flex="0 1 25%">Task</Table.TextHeaderCell>
              {_.map(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], (weekday) => (
                <Table.TextHeaderCell key={weekday}>{weekday}</Table.TextHeaderCell>
              ))}
              <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
              <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {_.isEmpty(filteredTasks[section.name]) && (
                <Table.Row>
                  <Table.TextCell padding={majorScale(1)}>No tasks to display</Table.TextCell>
                </Table.Row>
              )}
              {_.map(filteredTasks[section.name], (item) => {
                const weekdayRepeats = (item.weekdayRepeats) ? item.weekdayRepeats.split('/') : [];

                return (
                  <Table.Row key={item.id}>
                    <Table.TextCell flex="0 1 25%">{item.action}</Table.TextCell>
                    {_.map(['M', 'T', 'W', 'Th', 'F', 'S', 'Su'], (weekday) => (
                      <Table.Cell key={weekday}>{weekdayRepeats.includes(weekday) && <IconWrapper name="tick" size={24} />}</Table.Cell>
                    ))}
                    <Table.Cell>
                      <IconWrapper name="edit" appearance="clickable" onClick={() => setEditingTask(item)} />
                    </Table.Cell>
                    <Table.Cell>
                      <IconWrapper name="trash" appearance="danger" onClick={() => setRemovingTask(item)} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Block>
      ))}

      <CustomDialog
          isOpen={addingSection}
          title="New Section"
          confirmLabel="Add"
          onClose={() => cancelNewSection()}
          onConfirm={() => {
            createNewSection();
            cancelNewSection();
          }}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              label="Section Name"
              value={newSectionName || ''}
              onChange={(e) => setNewSectionName(e.target.value)}
              marginRight={majorScale(2)}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={removingSection}
          intent="danger"
          title="Remove Section"
          confirmLabel="Remove"
          onClose={() => setRemovingSection(false)}
          onConfirm={removeSectionConfirmed}
      >
        <Pane marginBottom={majorScale(2)}>
          {removingSection && (
            <React.Fragment>
              <Paragraph>Are you sure you wish to remove the section "{removingSection.displayName || removingSection.name}"?</Paragraph>
              <Paragraph color="danger">This will permanently remove the section and any tasks associated with it!</Paragraph>
            </React.Fragment>
          )}
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!editingTask && !['OPEN','CLOSE'].includes(editingTask.type)}
          title={(editingTask.id) ? 'Update Task' : 'Add New Task'}
          confirmLabel="Save"
          onClose={() => setEditingTask(false)}
          onConfirm={saveTask}
      >
        <Pane marginBottom={majorScale(4)}>
          <TextInputField
              required
              label="TO DO"
              value={editingTask.action || ''}
              onChange={(e) => onFieldChange('action', e.target.value)}
          />
          <Pane display="flex" justifyContent="center" alignItems="center">
            {_.map(['M', 'T', 'W', 'Th', 'F', 'S', 'Su'], (weekday, index) => (
              <Checkbox
                  key={weekday}
                  label={weekday}
                  checked={doesRepeatOnDay(editingTask.weekdayRepeats, weekday)}
                  onChange={(e) => onWeekdayToggle(weekday, e.target.checked)}
                  disabled={!editingTask.repeats}
                  marginLeft={index && majorScale(2)}
              />
            ))}
          </Pane>
        </Pane>
      </CustomDialog>

      <RemovingTask
        removingTask={removingTask}
        setRemovingTask={setRemovingTask}
        removeConfirmed={removeConfirmed}
      />
    </React.Fragment>
  );
};

DailyTab.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default DailyTab;
