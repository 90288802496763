import { getCollectionRef } from './common';

export const addTask = (task, accountId) => {
  const tasksRef = getCollectionRef('tasks', accountId);
  return tasksRef.add(task);
};

export const removeTask = (taskId, accountId) => {
  const tasksRef = getCollectionRef('tasks', accountId);
  return tasksRef.doc(taskId).delete();
};

export const updateTask = (taskId, task, accountId) => {
  const tasksRef = getCollectionRef('tasks', accountId);
  return tasksRef.doc(taskId).update(task);
};

export const completeTask = (taskId, date) => {
  // TODO: What does this look like??
};
