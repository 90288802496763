//React
import React from 'react';
import { Link, useLocation, Routes, Route, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

//Libraries
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import MenuList from './components/MenuList';
import MenuView from './components/MenuView';
import RecipeList from './components/RecipeList';
import RecipeView from './components/RecipeView';
import IngredientList from './components/IngredientList';
import IngredientView from './components/IngredientView';
import AllergenView from './components/AllergenView';

//Files
import useConditionalRedirect from '../../hooks/useConditionalRedirect';

const tabs = {
  menu: 'Menus',
  recipes: 'Recipes',
  ingredients: 'Ingredients',
  allergens: 'Allergens Charts'
};

const mobileTabs = {
  menu: 'Menus',
  recipes: 'Recipes',
  ingredients: 'Ingredients'
};

const FrontOfHouse = () => {
  const { requiresWorkingAccount } = useConditionalRedirect();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const location = useLocation();

  requiresWorkingAccount();
  const currentTab = location.pathname.split('/')[3] || 'recipes';

  return (
    <Page title={tabs[currentTab]} flex="1 0 auto" display="flex" flexDirection="column">
      <TabNavigation
          display="flex"
          flexShrink={0}
          overflowX="auto"
          paddingBottom={majorScale(2)}
      >
        {_.map((isMobile) ? mobileTabs : tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/${accountId}/front-of-house/${tabKey}`}
              isSelected={currentTab === tabKey}
          >
            {tabName}
          </Tab>
        ))}
      </TabNavigation>

      <Routes>
        <Route path="menu" element={<MenuList accountId={accountId} />} />
        <Route path="menu/:menuId" element={<MenuView accountId={accountId} />} />
        <Route path="recipes" element={<RecipeList accountId={accountId} />} />
        <Route path="recipes/:recipeId" element={<RecipeView accountId={accountId} />} />
        <Route path="ingredients" element={<IngredientList accountId={accountId} />} />
        <Route path="ingredients/:ingredientId" element={<IngredientView accountId={accountId} />} />
        { !isMobile && <Route path="allergens" element={<AllergenView accountId={accountId} />} /> }
        <Route path="*" element={<RecipeList accountId={accountId} />} />
      </Routes>

    </Page>
  );
};

export default FrontOfHouse;
