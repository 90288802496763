import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addTasksHistoryItem, removeTasksHistoryItem, updateTasksHistoryItem } from '../firebase/tasksHistory';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* taskSaga() {
  yield takeEvery(actionTypes.tasksHistory.ADD_TASK_HISTORY_ITEM, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addTasksHistoryItem, action.taskItem, accountId);
      yield put(actions.appMessageSuccess('Task History Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task History could not be added'));
    }
  });
  yield takeEvery(actionTypes.tasksHistory.REMOVE_TASK_HISTORY_ITEM, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeTasksHistoryItem, action.taskItemId, accountId);
      yield put(actions.appMessageSuccess('Task History Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task History could not be removed'));
    }
  });
  yield takeEvery(actionTypes.tasksHistory.UPDATE_TASK_HISTORY_ITEM, function* (action) {
    const { id, ...taskItem } = action.taskHistoryItem;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateTasksHistoryItem, id, taskItem, accountId);
      yield put(actions.appMessageSuccess('Task History Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task History could not be updated'));
    }
  });
};
