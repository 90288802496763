//React
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import { majorScale, Pane, Text, TextInputField } from 'evergreen-ui';
import _ from 'lodash';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Button from '../../../components/ui/Button/Button';
import Block from '../../../components/ui/Block/Block';
import CustomDialog from '../../../components/Dialog/Dialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { accountTypes } from '../../../utils/constants';


const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 5 },
  //{ label: 'Categories', field: 'settings', type: 'action', buttonProps: { appearance: 'default' }, width: 3 },
  { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', buttonProps: { appearance: 'default' }, width: 1 },
  { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 }
];


const GroupsEdit = ({ userAccounts, account, accountId, userProfile, label = 'product groups', buttonLabel="product group" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const groups = _.filter(
    userAccounts, 
    (dept) =>
      (!dept.type || dept.type === accountTypes.DEPARTMENT) &&
      !dept.departments &&
      _.includes(dept.parents, account.id)  
  );

  const [removingCategory, setRemovingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);

  const saveCategory = (cat) => {
    if (cat.id) {
      dispatch(actions.accounts.accountUpdate(cat));
    }
    else {
      addNewDepartment(cat);
    }
  };
  const addNewDepartment = (cat) => {
    if (cat) {
      dispatch(actions.accounts.accountCreate({
        type: accountTypes.DEPARTMENT,
        name: cat.name,
        parents: _.uniq([ ...(account.parents || []), account.id ]),
        ownerEmail: userProfile.email,
        ownerId: userProfile.uid,
        ownerName: userProfile.name,
      }, (newAccountData) => {
        // Account created successfully, now update the location account info
        dispatch(actions.accounts.accountUpdate({
          ...account,
          children: _.uniq([ ..._(account.children || []), newAccountData.id ]),
        }));
      }));
    }
  }
  const removeGroup = (cat) => {
    dispatch(actions.accounts.accountClear(cat.id));
    dispatch(actions.accounts.accountUpdate({
      ...account,
      children: _.without(account.children, cat.id),
    }));
    dispatch(actions.accounts.accountUpdate({
      ...cat,
      parents: _.without(cat.parents, account.id),
      deleted: true,
    }));
    dispatch(actions.accountUsers.removeAccount(cat.id));
  };

  return (
    <React.Fragment>
      <CustomDialog
          isOpen={!!editingCategory}
          title={(editingCategory && editingCategory.name) || `New ${label}`}
          confirmLabel={(editingCategory && editingCategory.id) ? 'Update' : 'Add'}
          onConfirm={() => {
            saveCategory(editingCategory)
            setEditingCategory(false)
          }}
          onClose={() => setEditingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="Name"
              placeholder={`${label} name`}
              value={editingCategory.name || ''}
              onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!removingCategory}
          title={`Remove ${label}`}
          intent="danger"
          confirmLabel="Remove"
          onConfirm={() => {
            removeGroup(removingCategory);
            setRemovingCategory(false);
          }}
          onClose={() => setRemovingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <Text>Are you sure you wish to remove the {label} {removingCategory.name}?</Text>
        </Pane>
      </CustomDialog>

      <Block>
      <Pane display="flex" alignItems="center" justifyContent="space-between" padding={majorScale(2)}>
          <Pane display="flex" alignItems="center" justifyContent="flex-start">
              <IconWrapper
                  appearance="clickable"
                  name="arrowLeft"
                  onClick={() => navigate(-1)}  
                  marginRight={majorScale(2)}
              />
              <CustomHeading level="3">Manage {label.toLowerCase()}</CustomHeading>
          </Pane>
          <Button 
            onClick={() => setEditingCategory({})}
            appearance="primary"
          >
            New {buttonLabel.toLowerCase()}
          </Button>
        </Pane>
        <Pane>
          <DataTable
              items={groups}
              headers={tableHeaders}
              onDelete={(cat) => setRemovingCategory(cat)}
              onEdit={(cat) => setEditingCategory(cat)}
              flex="1 0 auto"
              onSettings={(cat) => navigate(`/products/${cat.id}/settings`)}
          />
        </Pane>
      </Block>
    </React.Fragment>
  )
}

export default GroupsEdit;
