import fb from './'
import { getCollectionRef } from './common';

export const addWastage = (wastage, accountId) => {
  const wastagesRef = getCollectionRef('wastages', accountId);
  return wastagesRef.add({ ...wastage, created: fb.serverTime() });
};

export const removeWastage = (wastageId, accountId) => {
  const wastagesRef = getCollectionRef('wastages', accountId);
  return wastagesRef.doc(wastageId).delete();
};

export const updateWastage = (wastageId, wastage, accountId) => {
  const wastagesRef = getCollectionRef('wastages', accountId);
  return wastagesRef.doc(wastageId).update(wastage);
};
