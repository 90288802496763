const MENUS_LOAD_SUCCESS = 'MENUS_LOAD_SUCCESS';
const MENUS_LOAD_FAILURE = 'MENUS_LOAD_FAILURE';
const MENUS_CLEAR = 'MENUS_CLEAR';
const ADD_MENU = 'ADD_MENU';
const UPDATE_MENU = 'UPDATE_MENU';
const REMOVE_MENU = 'REMOVE_MENU';

export const actionTypes = {
  MENUS_LOAD_SUCCESS,
  MENUS_LOAD_FAILURE,
  MENUS_CLEAR,
  ADD_MENU,
  UPDATE_MENU,
  REMOVE_MENU
};


const menusLoadSuccess = (payload, account) => {
  return {
    type: MENUS_LOAD_SUCCESS,
    payload,
    account
  };
};

const menusLoadFailure = (error) => {
  return {
    type: MENUS_LOAD_FAILURE,
    error
  };
};

const menusClear = () => {
  return {
    type: MENUS_CLEAR
  };
};

const addMenu = (accountId, menu, callback) => {
  return {
    type: ADD_MENU,
    menu,
    callback,
    accountId,
  };
};

const updateMenu = (accountId, menu, callback) => {
  return {
    type: UPDATE_MENU,
    menu,
    callback,
    accountId,
  };
};

const removeMenu = (accountId, menuId, callback) => {
  return {
    type: REMOVE_MENU,
    menuId,
    callback,
    accountId,
  };
};

export const actions = {
  menusLoadSuccess,
  menusLoadFailure,
  menusClear,
  addMenu,
  updateMenu,
  removeMenu
};
