//React
import React, { useState, useEffect } from 'react';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text } from 'evergreen-ui';

//Components
import Accordion from '../../../components/ui/Accordion/Accordion';
import StocktakeTable from './StocktakeTable';

//Files
import { generateSearchFieldsFn, calcStocktakeRecipeCost, getRecipeUOMOptions, normalizeRecipeQuantity, denormalizeIngredientQuantity, denormalizeIngredientUnit, getCategoryNameById } from '../../../utils/functions';
import { colors } from '../../../utils/constants';
import { currency } from '../../../utils/format';


const StocktakeRecipeCategories = ({ recipes, recipeSearch, categoryOpt, setCategory, stockTakeInfo, isOpen, isSearching, isVisible, toggleVisibility, ...rest }) => {
  const recipeStock = (stockTakeInfo.recipes ?? []).filter(i => recipes.findIndex(ing => ing.id === i.id) > -1);
  
  // Calculate the total worth of the recipes in the current category
  const recipeTotal = _.reduce(recipeStock, (sum, item) => sum + (item.worth || 0), 0);
  
  // Filter recipes based on the search query
  const filteredRecipes = (recipeSearch) ? _.filter(recipes, generateSearchFieldsFn(['name'], recipeSearch)) : recipes;

  const toggleOpen = () => {
    if (isVisible) {
      setCategory(isOpen ? null : categoryOpt);
    }
  };

  // Auto-open the accordion if searching and there are matching recipes
  useEffect(() => {
    if (isSearching && filteredRecipes.length > 0 && !isOpen) {
      setCategory(categoryOpt);
    }
  }, [isSearching, filteredRecipes, categoryOpt, isOpen, setCategory]);

  // Only render the category if there are matching recipes
  if (filteredRecipes.length === 0) {
    return null;
  }

  return (
    <Accordion
      label={categoryOpt === 'undefined' ? '<No Category>' : categoryOpt}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
      isVisible={isVisible}
      toggleVisibility={() => {
        if (!isOpen) {
          toggleVisibility(categoryOpt);
        }
      }}
    >
      <Pane>
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
        >
          <StocktakeTable
            ings={filteredRecipes}
            stockItems={recipeStock}
            getUnits={getRecipeUOMOptions}
            denorm={{
              quantity: denormalizeIngredientQuantity,
              unit: denormalizeIngredientUnit
            }}
            recipe
            {...rest}
          />
        </Pane>
        <Pane
          background={colors.twilightBlue10}
          padding={majorScale(1)}
          display="flex"
          justifyContent="flex-end"
        >
          <Text marginRight={majorScale(2)}>Total</Text>
          <Text flexBasis={150} flexGrow={0} color={colors.orange}>£ {currency(recipeTotal)}</Text>
        </Pane>
      </Pane>
    </Accordion>
  );
}

const StocktakeRecipes = ({ setStockTakeInfo, updateForm, recipes, recipeSearch, setCategory, category, recipeCategories, ...rest }) => {
  // Group recipes by category name
  const recipesGroupedByCategory = recipes.reduce((acc, recipe) => {
    const categoryName = getCategoryNameById(recipe.categoryId, recipeCategories);
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(recipe);
    return acc;
  }, {});

  // Filter categories based on the name containing "batch" or "sub-recipes" in lowercase
  const filteredCategoryOptions = Object.keys(recipesGroupedByCategory).filter(categoryName =>
    categoryName.toLowerCase().includes('batch') || categoryName.toLowerCase().includes('sub-recipes')
  );

  // State to manage which category is currently open
  const [openCategory, setOpenCategory] = useState(null);
  const [visibleCategories, setVisibleCategories] = useState(filteredCategoryOptions);
  
  // Toggle the open category
  const handleToggleOpen = (categoryName) => {
    setOpenCategory(openCategory === categoryName ? null : categoryName);
  };

  const isSearching = recipeSearch.length > 0;

  const toggleVisibility = (category) => {
    setVisibleCategories((prevVisibleCategories) => {
      const newVisibleCategories = prevVisibleCategories.includes(category)
        ? prevVisibleCategories.filter((cat) => cat !== category)
        : [...prevVisibleCategories, category];

      // Close the category if it is being locked
      if (!newVisibleCategories.includes(category)) {
        setOpenCategory((prevOpenCategories) => prevOpenCategories.filter((cat) => cat !== category));
      }

      return newVisibleCategories;
    });
  };
  
  const onRecipeAdd = (ing) => {
    setStockTakeInfo((prevStockTake) => {
      let newIngList = _.concat([], prevStockTake.recipes || []);
      const ingIndex = _.findIndex(newIngList, { 'id': ing.id, 'recordUOM': ing.recordUOM });
      if (ingIndex >= 0) {
        newIngList[ingIndex] = { ...ing, normalQty: normalizeRecipeQuantity({ ...ing, amount: ing.amount }) };
        newIngList[ingIndex].worth = calcStocktakeRecipeCost(newIngList[ingIndex], true);
      }
      else {
        const item = { ...ing, normalQty: normalizeRecipeQuantity({ ...ing, amount: ing.amount }) };
        item.worth = calcStocktakeRecipeCost(item, true);
        newIngList.push(item);
      }

      const form = { ...prevStockTake, recipes: newIngList };
      updateForm(form);
      return form;
    });
  };
  
  const onRecipeRemove = (ing) => {
    setStockTakeInfo((prevStockTake) => {
      let newIngList = _.concat([], prevStockTake.recipes || []);
      const ingIndex = _.findIndex(newIngList, { 'id': ing.id, 'recordUOM': ing.recordUOM });
      if (ingIndex >= 0) {
        newIngList.splice(ingIndex, 1);
        const form = { ...prevStockTake, recipes: newIngList };
        updateForm(form);
        return form;
      }
    });
  };

  return (
    filteredCategoryOptions.map(categoryName => (
      <StocktakeRecipeCategories
        key={categoryName}
        categoryOpt={categoryName}
        isOpen={openCategory === categoryName}
        setCategory={handleToggleOpen}
        recipes={recipesGroupedByCategory[categoryName]}
        recipeSearch={recipeSearch}
        addItem={onRecipeAdd}
        removeItem={onRecipeRemove}
        stockTakeInfo={rest.stockTakeInfo}
        isSearching={isSearching}
        toggleVisibility={() => {
          if (!openCategory.includes(categoryName)) {
            toggleVisibility(categoryName);
          }
        }}
        isVisible={visibleCategories.includes(categoryName)}
        {...rest}
      />
    ))
  );
}

export default StocktakeRecipes;
