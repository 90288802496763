//React
import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table, Avatar, Pane, Dialog, TextInputField, Text } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Comment from '../../../components/Comment/Comment';
import TaskCommentList from '../../../components/TaskCommentList/TaskCommentList';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import useTasks from '../../../hooks/useTasks';
import useTaskComments from '../../../hooks/useTaskComments';


const ProbeTab = ({ date }) => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const {
    completedOnDay,
    isMobile,
    probeTasks
  } = useTasks({ accountId, date });

  const tasksHistory = useSelector((state) => {
    // Filter task history for current tasks, current date
    return _.keyBy(
      _.filter(current(state, 'tasksHistory', accountId), (taskItem) => {
        if ( taskItem.date && moment(taskItem.date.toDate()).isSame(date, 'day') ) {
          return (_.find(probeTasks, { id: taskItem.taskId }));
        }
        return false;
      }),
      'taskId'
    );
  });

  
  const {
    onCommentSave,
    tasksComments
  } = useTaskComments({ accountId, date });

  const userInfo = useSelector((state) => ({ name: state.profile.name, uid: state.profile.uid }));
  const [editingTask, setEditingTask] = useState(false);
  // const completedOnDay = (task) => (!!tasksHistory[task.id]);

  const onFieldChange = (field, newValue) => {
    setEditingTask((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const saveTask = (close) => {
    if (!tasksHistory[editingTask.id] ) {

      const taskHistoryItem = {
        taskId: editingTask.id,
        action: editingTask.action,
        type: editingTask.type,
        boilingUid: '',
        boilingUsername: '',
        boilingTemperature: '',
        icedUid: '',
        icedUsername: '',
        icedTemperature: '',
        date
      }

      const taskUser = editingTask.edit === 'boiling' ? {
        boilingUid: userInfo.uid, 
        boilingUsername: userInfo.name, 
        boilingTemperature: editingTask.boilingTemperature
      } : {
        icedUid: userInfo.uid, 
        icedUsername: userInfo.name, 
        icedTemperature: editingTask.icedTemperature
      }

      dispatch(actions.tasksHistory.addTaskHistoryItem(accountId, {
        ...taskHistoryItem,
        ...taskUser
      }));
      if (close) close();
    }
    else {

      const taskUser = editingTask.edit === 'boiling' ? {
        boilingUid: userInfo.uid, 
        boilingUsername: userInfo.name, 
        boilingTemperature: editingTask.boilingTemperature
      } : {
        icedUid: userInfo.uid, 
        icedUsername: userInfo.name, 
        icedTemperature: editingTask.icedTemperature
      }
      
      dispatch(actions.tasksHistory.updateTaskHistoryItem(accountId, {
        ...tasksHistory[editingTask.id],
        ...taskUser
      }));
      if (close) close();
    }

    close();
  };

  return (
    <React.Fragment>
      <Block>
        <CustomHeading level="4" padding={majorScale(2)}>Probe temperature logs</CustomHeading>
        <Table>
          {!isMobile && (
            <Table.Head>
              <Table.TextHeaderCell flex="0 1 20%">Description</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 20%">Boiling</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 10%">Initials</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 20%">Iced</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 10%">Initials</Table.TextHeaderCell>
            </Table.Head>
          )}
          {isMobile && (
            <Table.Head height={48}>
              <Table.TextHeaderCell flex="0 1 40%">
                Description<br />
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 30%">Boiling</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 1 30%">Iced</Table.TextHeaderCell>
            </Table.Head>
          )}
          <Table.Body>
            {_.isEmpty(probeTasks) && (
              <Table.Row>
                <Table.TextCell>No tasks to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(probeTasks, (task) => {
              const isComplete = completedOnDay(task);
              const boilingTemp = isComplete && tasksHistory[task.id].boilingTemperature ? tasksHistory[task.id].boilingTemperature : '';
              const amUsername = isComplete && tasksHistory[task.id].boilingUsername ? tasksHistory[task.id].boilingUsername : '';
              const icedTemp = isComplete && tasksHistory[task.id].icedTemperature ? tasksHistory[task.id].icedTemperature : '';
              const pmUsername = isComplete && tasksHistory[task.id].boilingUsername ? tasksHistory[task.id].boilingUsername : '';

              if (isMobile) {
                return (
                  <Table.Row key={task.id}>
                    <Table.TextCell flex="0 1 40%">
                      {task.action}<br />
                    </Table.TextCell>
                    <Table.Cell flex="0 1 30%">
                      {boilingTemp && (<Text>{boilingTemp}°C</Text>)}
                      <IconWrapper
                          marginLeft={majorScale(1)}
                          name="edit"
                          appearance="clickable"
                          onClick={() =>{  
                            console.log('Edit clicked for task:', task);
                            setEditingTask({...task, edit: 'boiling', boilingTemperature: boilingTemp});
                            console.log('Editing task set to:', {...task, edit: 'boiling', boilingTemperature: boilingTemp});
                        }}
                        
                      />
                    </Table.Cell>
                    <Table.Cell flex="0 1 30%">
                      {icedTemp && (<Text>{icedTemp}°C</Text>)}
                      <IconWrapper
                          marginLeft={majorScale(1)}
                          name="edit"
                          appearance="clickable"
                          onClick={() =>{  
                            console.log('Edit clicked for task:', task);
                            setEditingTask({...task, edit: 'iced', boilingTemperature: boilingTemp});
                            console.log('Editing task set to:', {...task, edit: 'iced', boilingTemperature: boilingTemp});
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              }

              return (
                <Table.Row key={task.id}>
                  <Table.TextCell flex="0 1 20%">{task.action}</Table.TextCell>
                  <Table.Cell flex="0 1 20%">
                    {boilingTemp && (<Text>{boilingTemp}°C</Text>)}
                    <IconWrapper
                      marginLeft={majorScale(1)}
                      name="edit" 
                      appearance="clickable"
                      onClick={() => setEditingTask({...task, edit: 'boiling', boilingTemperature: boilingTemp})} 
                    />
                  </Table.Cell>
                  <Table.Cell flex="0 1 10%">
                    {amUsername && (<Avatar size={24} name={amUsername} />)}
                  </Table.Cell>
                  <Table.Cell flex="0 1 20%">
                    {icedTemp && (<Text>{icedTemp}°C</Text>)}
                    <IconWrapper
                      marginLeft={majorScale(1)}
                      name="edit" 
                      appearance='clickable'
                      onClick={() => setEditingTask({...task, edit: 'iced', icedTemperature: icedTemp})} 
                    />
                  </Table.Cell>
                  <Table.Cell flex="0 1 10%">
                    {pmUsername && (<Avatar size={24} name={pmUsername} />)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pane padding={majorScale(1)}>
          <TaskCommentList comments={tasksComments['PROBE']} />
          <Comment onSave={(text) => onCommentSave({ type: 'PROBE', comment: text })} />
        </Pane>
      </Block>

      <CustomDialog
          isOpen={!!editingTask && editingTask.type === "PROBE" && (editingTask.edit === 'boiling' || editingTask.edit === 'iced')}
          title="Update"
          confirmLabel="Save"
          onClose={() => setEditingTask(false)}
          onConfirm={() => saveTask(() => setEditingTask(false))}

      >
        <Pane>
          {/*<CustomHeading level="4" marginBottom={majorScale(2)}>Update</CustomHeading>*/}
          <TextInputField
            //required
            label={editingTask.edit === 'boiling' ? "Boiling temperature" : "Iced temperature"}
            value={editingTask.edit === 'boiling' ? editingTask.boilingTemperature : editingTask.icedTemperature}
            onChange={(e) => onFieldChange(editingTask.edit === 'boiling' ? 'boilingTemperature' : 'icedTemperature', e.target.value)}
          />
        </Pane>
      </CustomDialog>

    </React.Fragment>
  );
};

ProbeTab.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
};

export default ProbeTab;
