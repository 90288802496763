//Libraries
import _ from 'lodash';
import moment from 'moment';

//Files
import store from '../store/redux';
import { buildOrderReportsWeekData, calculateReportsWeekData } from './reports';
import { getWE } from './time';

const dateFormat = 'YYYY-MM-DD';
export const monthsArray = [...Array(12).keys()];

export const buildFinanacialsErrors = (financialInfo, currentYear) => {
  return monthsArray.reduce((errors, num) => {
    const pErrors = {};
    const periodKey = `period_${num}`;
    const period = financialInfo[periodKey];
    if (!period) pErrors.period = 'Required.'
    else {
      const {start, end} = period;
      if (!start || !end) {
        if (!start) pErrors.start = 'Required';
        if (!end) pErrors.end = 'Required';
      }
      else {
        if (moment.utc(end, dateFormat).isBefore(moment.utc(start, dateFormat))) {
          pErrors.end = 'End can not be before the period start.';
        }
        if (num > 0) {
          const prevPeriodKey = `period_${num - 1}`;
          const prevPeriod = financialInfo[prevPeriodKey];
          if (moment.utc(start, dateFormat).isBefore(moment.utc(prevPeriod.end, ))) {
            pErrors.start = 'Start can not be before the previous period end.';
          }
        }
      }
    }
    errors[periodKey] = pErrors;
    return errors;
  }, []);
}

export const buildInitFinancialData = (year) => {
  const yearStart = moment.utc().year(year).startOf('year').format(dateFormat);
  return monthsArray.reduce((data, num) => {
    const periodKey = `period_${num}`;
    const start = moment.utc().month(num).year(year).startOf('month').format("YYYY-MM-DD");
    const end = moment.utc().month(num).year(year).endOf('month').format("YYYY-MM-DD");
    const period = { start, end };
    data[periodKey] = period;
    return data;
  }, { start_of_year: yearStart, year })
}

export const findFinancePeriod = (financial, date = moment.utc()) => {
  if (!financial) return null;

  if (parseInt(financial.year) < moment.utc().year()) {
    // return last period of the year
    return {period_key: 'period_11', num: 11}
  }

  for (const num of monthsArray) {
    const periodKey = `period_${num}`;
    const period = financial[periodKey];
    const start = moment.utc(period.start, dateFormat);
    const end = moment.utc(period.end, dateFormat);
    if (date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
      return {periodKey, num};
    }
  };  
}

export const buildPeriodsArray = (financial) => {
  return monthsArray.map(num => {
    const periodKey = `period_${num}`;
    const period = financial[periodKey];
    const item =  {...period, periodKey};
    if (num === 0) {
      item.start = financial.start_of_year;
    }
    return item;
  })
}

export const filterPeriodsToNow = (periods, financial) => {
  const current = findFinancePeriod(financial);
  return periods.filter((p, i) => i <= current?.num);
}

export const buildFinancialPriodsToNow = (financial) => {
  return filterPeriodsToNow(buildPeriodsArray(financial), financial);
}

const buildPeriodWeeks = (period) => {
  let periodStart = moment.utc(period.start, 'YYYY-MM-DD');
  const periodEnd = moment.utc(period.end, 'YYYY-MM-DD');
  const days = periodEnd.add(1,'day').diff(periodStart,'days')
  const weeks = [];
  let x = 0;
  while(periodStart.isBefore(periodEnd, 'date')) {
    const weekEnd = getWE(periodStart.toDate());
    const week = {
      weekStart: moment.utc(periodStart),
      weekEnd: moment.utc(weekEnd),
      days: moment.utc(weekEnd).add(1,'day').diff(periodStart, 'days'),
      num: x
    }
    weeks.push(week);
    x++;
    periodStart = moment.utc(weekEnd).add(1,'day');
  }
  return {weeks, days};
}

export const buildFinancialPeriodsAccountsData = ({financial, accounts, currentPeriod, currentYear, ...rest}) => {
  const {filterProps, areas} = accounts;
  const state = store.getState();
  const {filters} = filterProps;
  if (areas) {
    return areas.reduce((areas, area) => {
      const props = {accountId: area.id, filters, area, ...rest};
      const periods = buildFinancialPriodsToNow(financial);
      const budget = state.budgets[area.id].find(b => parseInt(b.year) === parseInt(currentYear));
      const periodsData = periods.map(period => {
        const pBudget = budget ? budget[period.periodKey].value : null;
        const pMargin = budget ? budget[period.periodKey].margin : null;
        let weekData;
        if (currentPeriod === period.periodKey) {
          const {weeks, days} = buildPeriodWeeks(period);
          weekData = weeks.map(week => {
            const {weekStart, weekEnd} = week;
            const salesData = calculateReportsWeekData({weekStart, weekEnd, ...props});
            const orderData = buildOrderReportsWeekData({weekStart, weekEnd, ...props});
            return {
              ...salesData,
              ...orderData,
              budget: pBudget ? ((pBudget / days) * week.days) : null,
              margin: pMargin,
              week
            }
          });
        }
        const weekStart = moment.utc(period.start, 'YYYY-MM-DD');
        const weekEnd = moment.utc(period.end, 'YYYY-MM-DD');
        const salesData = calculateReportsWeekData({weekStart, weekEnd, ...props});
        const orderData = buildOrderReportsWeekData({weekStart, weekEnd, ...props});
        return {
          ...salesData,
          ...orderData,
          budget: pBudget,
          margin: pMargin,
          period,
          weeks: weekData
        }
      });
      areas[area.id] = {periodsData, area};
      return areas;
    }, {});
  }

  return {};
}

const aggregatePeriodWeek = (week, period, index) => {
  const periodWeek = period?.weeks[index] || {};
  const openingStock = (parseFloat(periodWeek?.openingStock) || 0) + (parseFloat(week.theoreticalStock?.openingStocktakeCost || 0));
  const netSales = (parseFloat(periodWeek?.netSales) || 0) + (parseFloat(week.weekSalesNet) || 0);
  const costOfSales = (parseFloat(periodWeek?.costOfSales || 0)) + (parseFloat(week.procurementTotal) || 0);
  const closingValue = (parseFloat(periodWeek?.closingValue || 0)) +
                       (parseFloat(week.closingStocktakeCosts || week.theoreticalStock?.expectedStockValue || 0));
  const stockVar = openingStock - closingValue;
  const totalCostOfSales = costOfSales + stockVar;
  const periodActualCostOfSales = (parseFloat(week.theoreticalStock?.openingStocktakeCost || 0)) +
                                  (parseFloat(week.procurementTotal) || 0) -
                                  (parseFloat(week.closingStocktakeCosts || week.theoreticalStock?.expectedStockValue || 0));
  const actualCostOfSales = (parseFloat(periodWeek?.actualCostOfSales) || 0) + periodActualCostOfSales;
  const grossProfits = netSales - actualCostOfSales;
  const grossProfitsPercent = netSales ?  (100*(parseFloat(grossProfits)/parseFloat(netSales))) : 0;
  const thCostOfSales = (parseFloat(week.theoreticalStock?.weekSalesCost || 0)) +
                        (parseFloat(periodWeek?.thCostOfSales || 0));

  const totalCostOfSalesPercent = netSales ? (100*(parseFloat(totalCostOfSales)/parseFloat(netSales))) : 0;
  const thValue = netSales - thCostOfSales;
  const thVariance = grossProfits - thValue;

  const thCostOfSalesPercent = netSales ? (100*(parseFloat(thCostOfSales) / parseFloat(netSales))) : 0; // calcPercentage(thCostOfSales, netSales, 2) ;

  const thCostOfSalesVar = totalCostOfSales - thCostOfSales
  const thCostOfSalesVarPercent = totalCostOfSales ? (100*(1-(thCostOfSales/totalCostOfSales))) : 0;
  const grossProfitsThVar = grossProfits - thValue;
  const grossProfitsThVarPercent = (100*(1 - (thValue / grossProfits)));
  const budget = (parseFloat(periodWeek?.budget || 0)) + (parseFloat(week?.budget || 0));
  const budgetVariance = budget ? netSales - budget : 0;
  // const periodData.netSales - periodData.budget
  //const budgetVariancePercent = budget && netSales ? ((100*(1 - (budget / netSales))))  : 0;
  const budgetVariancePercent = budget && netSales ? (100 * ((netSales - budget) / netSales)).toFixed(2) : 0;

  const margin = week.margin;
  const periodBudgetMargin = week?.budget && margin ? week?.budget * ((100-margin)/100) : 0;
  const periodBudgetMarginTotal = periodBudgetMargin + (parseFloat(periodWeek?.periodBudgetMarginTotal) || 0);
  const periodBudgetMarginTotalPercent = periodBudgetMarginTotal && budget ? (100*(parseFloat(periodBudgetMarginTotal)/parseFloat(budget))) : 0;
  const salesMargin = margin ? costOfSales * ((100-margin)/100) : null;
  const budgetCostOfSales = budget ? budget * ((100-margin)/100) : 0;
  const budgetGrossProfits = budget * (margin/100);
  const periodBudgetGrossProfit = week?.budget && margin ? week?.budget * (margin/100) : 0;
  const periodBudgetGrossProfitTotal = periodBudgetGrossProfit + (parseFloat(periodWeek?.periodBudgetGrossProfitTotal) || 0)
  // const budgetCostOfSalesPercent = budget ? budgetCostOfSales / budgetVariancePercent : 0;
  const sumBudgetCostOfSales = budgetCostOfSales + (parseFloat(periodWeek?.sumBudgetCostOfSales || 0));
  const budgetCostOfSalesPercent = (sumBudgetCostOfSales / budget);
  const sumBudgetGrossProfit = sumBudgetCostOfSales + (periodWeek?.sumBudgetGrossProfit || 0);
  const grossProfitPercent = (grossProfits / sumBudgetGrossProfit);
  const grossProfitsBudgetVar = grossProfits - periodBudgetGrossProfitTotal;
  const grossProfitsBudgetVarPercent = (100*(1-(periodBudgetGrossProfitTotal / grossProfits)));
  const periodBudgetMarginVar = totalCostOfSales - periodBudgetMarginTotal;
  const periodBudgetMarginVarPercent = totalCostOfSales ? (100*(1-(periodBudgetMarginTotal/totalCostOfSales))) : 0

  return {
    netSales,
    costOfSales,
    grossProfits,
    week,
    actualCostOfSales,
    closingValue,
    thValue,
    thCostOfSales,
    thVariance,
    openingStock,
    salesMargin,
    budgetVariance,
    budget,
    budgetVariancePercent,
    budgetCostOfSales,
    budgetCostOfSalesPercent,
    sumBudgetCostOfSales,
    sumBudgetGrossProfit,
    grossProfitPercent,
    totalCostOfSales,
    stockVar,
    budgetGrossProfits,
    periodBudgetMarginTotal,
    grossProfitsBudgetVar,
    periodBudgetGrossProfit,
    periodBudgetGrossProfitTotal,
    grossProfitsBudgetVarPercent,
    grossProfitsThVar,
    grossProfitsThVarPercent,
    totalCostOfSalesPercent,
    thCostOfSalesPercent,
    periodBudgetMarginTotalPercent,
    grossProfitsPercent,
    thCostOfSalesVar,
    thCostOfSalesVarPercent,
    periodBudgetMarginVar,
    periodBudgetMarginVarPercent
  }
}

const periodInit = {
  netSales: 0,
  costOfSales: 0,
  grossProfits: 0,
  actualCostOfSales: 0,
  closingValue: 0,
  thValue: 0,
  thCostOfSales: 0,
  thVariance: 0,
  openingStock: 0,
  budget: 0,
  margin: 0,
  salesMargin: 0,
  budgetVariancePercent: 0,
  budgetVariance: 0,
  budgetCostOfSalesPercent: 0,
  budgetCostOfSales: 0,
  sumBudgetCostOfSales: 0,
  sumBudgetGrossProfit: 0,
  grossProfitPercent: 0,
  totalCostOfSales: 0,
  stockVar: 0,
  budgetGrossProfits: 0,
  periodBudgetMarginTotal: 0,
  grossProfitsBudgetVar: 0,
  periodBudgetGrossProfit: 0,
  periodBudgetGrossProfitTotal: 0,
  grossProfitsBudgetVarPercent: 0,
  grossProfitsThVarPercent: 0,
  grossProfitsThVar: 0,
  totalCostOfSalesPercent: 0,
  thCostOfSalesPercent: 0,
  periodBudgetMarginTotalPercent: 0,
  grossProfitsPercent: 0,
  thCostOfSalesVar: 0,
  thCostOfSalesVarPercent: 0,
  periodBudgetMarginVar: 0,
  periodBudgetMarginVarPercent: 0
}

const aggregatePeriods = (period, totals) => {
  for (const key in period) {
    if (totals[key] >= 0) {
      totals[key] += period[key];
    }
  }
  return totals;
}


const aggregatePeriodsData = (periodsData = [], periods = []) => {
  periodsData.forEach((period, index) => {
    let weeks = [];
    if (period.weeks) {
      period.weeks.forEach((week, weekIndex) => {
        weeks.push(aggregatePeriodWeek(week, periods[index], weekIndex));
      });
    }
    const openingStock = (parseFloat(periods[index]?.openingStock) || 0) + (parseFloat(period.theoreticalStock?.openingStocktakeCost || 0));
    const netSales = (parseFloat(periods[index]?.netSales) || 0) + (parseFloat(period.weekSalesNet) || 0);
    const costOfSales = (parseFloat(periods[index]?.costOfSales || 0)) + (parseFloat(period.procurementTotal) || 0);
   
    const closingValue = (parseFloat(periods[index]?.closingValue || 0)) +
                         (parseFloat(period.closingStocktakeCosts || period.theoreticalStock?.expectedStockValue || 0));
    const stockVar = openingStock - closingValue;
    const totalCostOfSales = costOfSales + stockVar;
    const periodActualCostOfSales = (parseFloat(period.theoreticalStock?.openingStocktakeCost || 0)) +
                                    (parseFloat(period.procurementTotal) || 0) -
                                    (parseFloat(period.closingStocktakeCosts || period.theoreticalStock?.expectedStockValue || 0));
    const actualCostOfSales = (parseFloat(periods[index]?.actualCostOfSales) || 0) + periodActualCostOfSales;
    const grossProfits = netSales - actualCostOfSales;
    const grossProfitsPercent = netSales ? (100 * (parseFloat(grossProfits) / parseFloat(netSales))).toFixed(2) : 0;
    const thCostOfSales = (parseFloat(period.theoreticalStock?.weekSalesCost || 0)) +
                          (parseFloat(periods[index]?.thCostOfSales || 0));
    const totalCostOfSalesPercent = netSales ? (100 * (parseFloat(totalCostOfSales) / parseFloat(netSales))).toFixed(2) : 0;
    const thCostOfSalesPercent = netSales ? (100 * (parseFloat(thCostOfSales) / parseFloat(netSales))).toFixed(2) : 0;
    const thValue = netSales - thCostOfSales;
    const thVariance = grossProfits - thValue;
    const thCostOfSalesVar = totalCostOfSales - thCostOfSales;
    const thCostOfSalesVarPercent = totalCostOfSales ? (100 * (1 - (thCostOfSales / totalCostOfSales))).toFixed(2) : 0;
    const budget = (parseFloat(periods[index]?.budget) || 0) + (parseFloat(period.budget) || 0);
    const budgetVariance = budget ? netSales - budget : 0;
    const budgetVariancePercent = budget && netSales ? (100 * ((netSales - budget) / netSales)).toFixed(2) : 0;

    const grossProfitsThVar = grossProfits - thValue;
    const grossProfitsThVarPercent = (100 * (1 - (thValue / grossProfits))).toFixed(2);

    const margin = period.margin;
    const salesMargin = margin ? costOfSales * ((100 - margin) / 100) : 0;
    const periodBudgetMargin = period.budget && margin ? period.budget * ((100 - margin) / 100) : 0;
    const periodBudgetMarginTotal = periodBudgetMargin + (parseFloat(periods[index]?.periodBudgetMarginTotal) || 0);
    const periodBudgetMarginTotalPercent = periodBudgetMarginTotal && budget ? (100 * (parseFloat(periodBudgetMarginTotal) / parseFloat(budget))).toFixed(2) : 0;
    const periodBudgetMarginVar = totalCostOfSales - periodBudgetMarginTotal;
    const periodBudgetMarginVarPercent = totalCostOfSales ? (100 * (1 - (periodBudgetMarginTotal / totalCostOfSales))).toFixed(2) : 0;

    const budgetCostOfSales = budget ? budget * ((100 - margin) / 100) : 0;
    const sumBudgetCostOfSales = budgetCostOfSales + (parseFloat(periods[index]?.sumBudgetCostOfSales || 0));
    const budgetCostOfSalesPercent = (periodBudgetMarginTotal / budget).toFixed(2);
    const budgetGrossProfits = periodBudgetMarginTotal - budget;
    const periodBudgetGrossProfit = period.budget && margin ? period.budget * (margin / 100) : 0;
    const periodBudgetGrossProfitTotal = periodBudgetGrossProfit + (parseFloat(periods[index]?.periodBudgetGrossProfitTotal) || 0);
    const sumBudgetGrossProfit = sumBudgetCostOfSales + (periods[index]?.sumBudgetGrossProfit || 0);
    const grossProfitPercent = (grossProfits / periodBudgetGrossProfitTotal).toFixed(2);
    const grossProfitsBudgetVar = grossProfits - periodBudgetGrossProfitTotal;
    const grossProfitsBudgetVarPercent = grossProfits ? (100 * (1 - (periodBudgetGrossProfitTotal / grossProfits))).toFixed(2) : 0;

    periods[index] = {
      period,
      openingStock,
      netSales,
      costOfSales,
      closingValue,
      actualCostOfSales,
      grossProfits,
      thCostOfSales,
      thValue,
      thVariance,
      weeks,
      budgetVariance,
      salesMargin,
      budget,
      budgetVariancePercent,
      sumBudgetCostOfSales,
      budgetCostOfSalesPercent,
      sumBudgetGrossProfit,
      grossProfitPercent,
      totalCostOfSales,
      stockVar,
      budgetCostOfSales,
      budgetGrossProfits,
      periodBudgetMarginTotal,
      grossProfitsBudgetVar,
      periodBudgetGrossProfit,
      periodBudgetGrossProfitTotal,
      grossProfitsBudgetVarPercent,
      grossProfitsThVar,
      grossProfitsThVarPercent,
      totalCostOfSalesPercent,
      thCostOfSalesPercent,
      periodBudgetMarginTotalPercent,
      grossProfitsPercent,
      thCostOfSalesVar,
      thCostOfSalesVarPercent,
      periodBudgetMarginVar,
      periodBudgetMarginVarPercent
    };
  });
  return periods;
};


export const aggregateFinancialPeriodsAreasData = (areas) => {
  let areaCats = {};
  let periods = [];
  for (const areaId in areas) {
    const data = areas[areaId];
    const area = data.area;
    
    periods = aggregatePeriodsData(data.periodsData, periods);
    if (area.area?.id) {
      areaCats[area.area.id] = aggregatePeriodsData(data.periodsData, areaCats[area.area.id]);
    }
  }
  return {
    periods,
    areaCats
  }
}

export const aggregateFinanacialPeriods = (periods, periodNum) => {
  return periods.reduce((totals, period, index) => {
    if (index <= periodNum) {
      totals = aggregatePeriods(period, totals);
    }
    return totals;
  }, {...periodInit});
}
