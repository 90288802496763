import _ from 'lodash';
import moment from 'moment';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addReportsIncidentals, updateReportsIncidentals } from '../firebase/reportsIncidentals';
import { actionTypes, actions } from '../actions';
import { getWC } from '../../utils/time';
import { activeAccount } from '../../utils/selectors';

export default function* reportsIncidentalsSaga() {
  yield takeEvery(actionTypes.reportsIncidentals.SET_WEEKLY_INCIDENTALS, function* (action) {
    try {
      const { weekOf, ...incidentals } = action.incidentals;
      const accountId = yield action.accountId || select(activeAccount);
      const existing = yield select((state) => (
        _.find(state.reportsIncidentals[accountId], { 'weekOf': moment(weekOf).format('YYYY-MM-DD') })
      ));
      if (existing) {
        const { id, ...values } = existing;
        yield call(updateReportsIncidentals, id, { ...values, ...incidentals }, accountId);
      }
      else {
        yield call(addReportsIncidentals, { weekOf: moment(weekOf).format('YYYY-MM-DD'), ...incidentals }, accountId);
      }
      yield put(actions.appMessageSuccess('Report information saved'));
    }
    catch (err) {
      yield put(actions.appMessageError('Report information could not be saved'));
    }
  });
  yield takeEvery(actionTypes.reportsIncidentals.SET_YEARLY_INCIDENTALS, function* (action) {
    try {
      const { year, ...incidentals } = action.incidentals;
      const weekOf = getWC(moment().year(year).month(0).date(1).toDate());
      const accountId = yield action.accountId || select(activeAccount);
      const existing = yield select((state) => (
        _.find(state.reportsIncidentals[accountId], { 'weekOf': moment(weekOf).format('YYYY-MM-DD') })
      ));
      if (existing) {
        const { id, ...values } = existing;
        yield call(updateReportsIncidentals, id, { ...values, ...incidentals }, accountId);
      }
      else {
        yield call(addReportsIncidentals, { weekOf: moment(weekOf).format('YYYY-MM-DD'), ...incidentals }, accountId);
      }
      yield put(actions.appMessageSuccess('Report information saved'));
    }
    catch (err) {
      yield put(actions.appMessageError('Report information could not be saved'));
    }
  });
};
