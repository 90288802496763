//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Heading, majorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar'
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { filterActiveIngredients } from '../../../utils/ingredients';
import { current } from '../../../utils/selectors';


const Ingredients = ({ accountId }) => {
  const ingredientsList = useSelector((state) => filterActiveIngredients(current(state, 'ingredients', accountId)));
  const supplierOptionList = useSelector((state) => _.groupBy(
    current(state, 'supplierOptions', accountId),
    'ingredientId'
  ));
  const recipeIngredientList = useSelector((state) => _.map(
    current(state, 'recipes', accountId),
    (recipe) => (_.map(recipe.ingredients, (ing) => (ing.id)))
  ));
  const categoryMap = useSelector((state) => _.keyBy(current(state, 'ingredientCategories', accountId), 'id'));
  const [filters, setFilters] = useState({});

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Item Code', field: 'itemcode', type: 'text', width: 3 },
    { label: 'Articles', field: 'optionCount', type: 'calc', calc: (item) => {
      // Retrieve a count of available Supplier Options
      const options = supplierOptionList[item.id] || [];
      return options.length;
    }, width: 2 },
    { label: 'Suppliers', field: 'supplierCount', type: 'calc', calc: (item) => {
      // Retrieve a count of unique suppliers
      const options = supplierOptionList[item.id] || [];
      return (_.uniqBy(options, 'supplierId')).length;
    }, width: 2 },
    { label: 'Recipes', field: 'recipeCount', type: 'calc', calc: (item) => {
      // Retrieve a count of recipes that contain the item as an ingredient
      const recipes = _.filter(recipeIngredientList, (ingList) => (_.includes(ingList, item.id)));
      return (recipes.length || 0);
    }, width: 2 },
    { label: 'Main Category', field: 'categoryId', type: 'calc', calc: (item) => {
      // Recurse to the top-most category
      let categoryId = item.categoryId;
      while (categoryMap[categoryId] && categoryMap[categoryId].parent) {
        categoryId = categoryMap[categoryId].parent;
      }
      return (categoryMap[categoryId] && categoryMap[categoryId].name) || '';
    }, format: (value) => (value || ''), width: 2 },
  ];

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };
  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };
  

  return (
    <>
      <Block
          padding={majorScale(2)}
          marginBottom={majorScale(2)}
      >
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading
              level="3"
              flex="1 0 auto"
          >Ingredients</CustomHeading>
        </Pane>
        <FilterBar
            searchPlaceholder="Search for an Ingredient"
            searchOnChange={searchOnChange}
        />
      </Block>

      <Block flex={`1 0 auto`} display="flex" height="500px" flexDirection="column" marginBottom={majorScale(2)}>
        <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
          <DataTable
              items={ingredientsList}
              headers={tableHeaders}
              filters={filters}
              flex="1 0 auto"
              listHeight={480}
          />
        </Pane>
      </Block>
    </>
  );
};

Ingredients.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default Ingredients;
