//React
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, TabNavigation } from 'evergreen-ui';

//Components
import Tab from '../../components/ui/Tab/Tab';
import DailyTab from './components/DailyTab';
import OpenCloseTab from './components/OpenCloseTab';
import PrepTab from './components/PrepTab';

const tabs = {
  daily: 'Task lists',
  prep: 'Preparation lists',
  temp: 'Temperature logs'
};

const renderTab = (currentTab, accountId) => {
  switch (currentTab) {
    case 'temp':
      return <OpenCloseTab accountId={accountId} />;
    case 'prep':
      return <PrepTab accountId={accountId} />
    case 'daily':
    default:
      return <DailyTab accountId={accountId} />;
  }
}


const TaskSetup = () => {
  const location = useLocation();
  const { accountId } = useParams();
  const currentTab = location.pathname.split('/')[5] || 'daily';
  return (
    <Pane>
      <TabNavigation
          display="flex"
          flexShrink={0}
          overflowX="auto"
          paddingBottom={majorScale(2)}
      >
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/areas/${accountId}/account/tasks/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>
      {renderTab(currentTab, accountId)}
    </Pane>
  );
};

TaskSetup.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default TaskSetup;
