//React
import React from 'react';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import moment from 'moment';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Accordion from '../../../components/ui/Accordion/Accordion';
import Block from '../../../components/ui/Block/Block'

//Files
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { getIngredientStockUOM, getOptionOrdersFromDate, getOrderDate } from '../../../utils/functions';
import { buildIngOptions } from '../Costs';

const orderIngPrice = (opt, order) => {
  const item = order.items.find(i => i.ingredientId === opt.ingredientId);
  return item ? parseFloat(item.finalprice) : 0;
}

export const createOptPrice = (opt, order) => ({
  unitprice: orderIngPrice(opt, order),
  date: moment(getOrderDate(order))
});

const createNullPrice = { unitprice: null, date: null }

const buildOptionCurrentPrice = ({ orders, weekStart, opt }) => {
  if (!orders || !orders[0]) return { unitprice: opt.unitprice, date: moment(weekStart) };
  // first option ordered
  return createOptPrice(opt, orders[0])
}

const buildOptionPrevPrice = ({ orders, opt }) => {
  const initPrice = orders[0] ? orderIngPrice(opt, orders[0]) : parseFloat(opt.unitprice);
  const nextIndex = orders.findIndex(o => orderIngPrice(opt, o) !== initPrice);
  if (nextIndex > -1) return createOptPrice(opt, orders[nextIndex])

  return createNullPrice;
}

const findOrderPrice = (start, orders, opt, subtract) => {
  const subDate = moment(start).subtract(subtract.num, subtract.unit);
  const index = orders.findIndex(o => moment(getOrderDate(o)).isSameOrBefore(subDate));
  if (index > -1) return createOptPrice(opt, orders[index])
  
  return createNullPrice;
}

const buildOptionMonthPrice = ({ orders, weekStart, opt }) => {
  return findOrderPrice(weekStart, orders, opt, { num: 1, unit: 'month' });
}

const buildOptionThreeMonthPrice = ({ orders, weekStart, opt }) => {
  return findOrderPrice(weekStart, orders, opt, { num: 3, unit: 'months' });
}

const buildOptionYearPrice = ({ orders, weekStart, opt }) => {
  return findOrderPrice(weekStart, orders, opt, { num: 1, unit: 'year' });
}

const buildOptionPrices = ({ opt, ing, accountId, weekStart, supplierMap, show, ...rest }) => {
  // options ordered from start sorted by date
  const orders = getOptionOrdersFromDate(accountId, opt, moment(weekStart));
  const props = {opt, orders, weekStart, ing, ...rest}
  const currentPrice = buildOptionCurrentPrice(props);
  const lastPrice = buildOptionPrevPrice(props);
  const lastMonthPrice = buildOptionMonthPrice(props);
  const threeMonthPrice = buildOptionThreeMonthPrice(props);
  const lastYearPrice = buildOptionYearPrice(props);

  // Check if supplierMap and the specific supplier exist before trying to access the name property
  const supplierName = supplierMap && supplierMap[opt.supplierId] ? supplierMap[opt.supplierId].name : 'Unknown Supplier';

  return {
    currentPrice: currentPrice.unitprice,
    lastPrice: lastPrice.unitprice || '-',
    lastDate: lastPrice.date ? lastPrice.date.format('DD/MM') : '-',
    lastMonthPrice: lastMonthPrice.unitprice || '-',
    threeMonthPrice: threeMonthPrice.unitprice || '-',
    lastYearPrice: lastYearPrice.unitprice || '-',
    opt,
    id: opt.id,
    supplierName: supplierName,
  }
}

const buildIngOptionPrices = ({ ing, optionList, ...rest }) => {
  const ingsOpts = buildIngOptions(ing, optionList);
  const uom = getIngredientStockUOM(ing);
  const optsData = ingsOpts.map(opt => buildOptionPrices({opt, ing, ...rest}));
  return {
    ...ing,
    uom,
    hideLink: true,
    opts: optsData
  }
}

const buildPricesList = ({ings, ...rest}) => {
  return ings.reduce((list, ing) => {
    const ingData = buildIngOptionPrices({ ing, ...rest });
    list.push({ ...ingData, opts: null});
    list = list.concat(ingData.opts);
    return list;
  }, [])
}

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 4 },
  { label: 'Supplier', field: 'supplierName', type: 'text', width: 3 },
  { label: 'UOM', type: 'text', field: 'uom', width: 2 },
  { label: 'Price', type: 'text', field: 'currentPrice', width: 2 },
  { label: 'P-1', type: 'text', field: 'lastPrice', width: 2 },
  { label: 'Date', type: 'text', field: 'lastDate', width: 2 },
  //{ label: 'LM', type: 'text', field: 'lastMonthPrice', width: 2 },
  //{ label: 'L3M', type: 'text', field: 'threeMonthPrice', width: 2 },
  //{ label: 'LY', type: 'text', field: 'lastYearPrice', width: 2 },
  //{ label: 'View', type: 'action', link: 'reports/procurement/', icon: 'clipboard', width: 1 }
]

const ProcurementPricingTab = ({ ings, isOpen, setCategory, categoryOpt, isMobile, ...rest }) => {
  // const ingOptionsPrices = buildIngsOptionPrices(props);
  const priceList = buildPricesList({ ings, ...rest });
  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt.value);

  return (
    <Accordion
      label={categoryOpt.label}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
    >
        <Block
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
        >
          <DataTable
            items={priceList}
            // filters={filters}
            headers={tableHeaders}
            tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
          />
        </Block>
    </Accordion>
  );
}

export default ProcurementPricingTab;
