import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addReceipts, removeReceipts, toggleArchiveReceipt, updateReceipts } from '../firebase/receipts';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* receiptsSaga() {
  yield takeEvery(actionTypes.receipts.ADD_RECEIPTS, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addReceipts, action.receipts, accountId);
      yield put(actions.appMessageSuccess('Receipts Information Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Receipts Information could not be added'));
    }
  });
  yield takeEvery(actionTypes.receipts.REMOVE_RECEIPTS, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeReceipts, action.receiptsId, accountId);
      yield put(actions.appMessageSuccess('Receipts Information Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Receipts Information could not be removed'));
    }
  });
  yield takeEvery(actionTypes.receipts.UPDATE_RECEIPTS, function* (action) {
    const { id, ...receipts } = action.receipts;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateReceipts, id, receipts, accountId);
      yield put(actions.appMessageSuccess('Receipts Information Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Receipts Information could not be updated'));
    }
  });
  yield takeEvery(actionTypes.receipts.TOGGLE_ARCHIVE_RECEIPT, function* (action) {
    const { id, ...receipt } = action.receipt;
    try {
      yield call(toggleArchiveReceipt, id, receipt, action.accountId);
      yield put(actions.appMessageSuccess(`Receipt ${receipt.archive ? 'un': ''}archived`));
      window.location.reload();
    }
    catch (err) {
      yield put(actions.appMessageError(`Receipt could not be ${receipt.archive ? 'un': ''}archived`));
    }
  });
};
