//React + Firebase
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import fb from 'firebase/app';

//Libraries
import { minorScale, Pane, Text, Paragraph, TextInputField, Alert, majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';

//Files
import firebase from '../../store/firebase';
import { actions } from '../../store/actions';
import { messageTypes } from '../../utils/constants';


const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});

  const onFieldChange = (field, newValue) => {
    if (errorMsg) {
      setErrorMsg(false);
    }
    setLoginInfo((prevValues) => {
      return { ...prevValues, [field]: newValue };
    });
  }

  const signIn = () => {
    const { displayName, email, password } = loginInfo;
    if (!displayName) {
      setErrorMsg('Please enter your name');
      return;
    }
    if (!email) {
      setErrorMsg('Please enter a valid email address');
      return;
    }
    if (!password) {
      setErrorMsg('Please enter your password');
      return;
    }
    firebase.auth.createUserWithEmailAndPassword(email, password)
      .then(() => {
        const user = firebase.auth.currentUser;
        user.updateProfile({ displayName });
        user.sendEmailVerification();
        dispatch(actions.appMessageSet('Welcome to Mise En Place', messageTypes.SUCCESS));
        dispatch(actions.profile.profileAdd(user.uid, {
          currentaccount: null,
          email: user.email,
          name: displayName,
          provider: 'password',
          uid: user.uid
        }));
        dispatch(actions.applicationLoading());
        navigate('/', true);
      })
      .catch((err) => {
        setErrorMsg(err.message);
        console.log(err);
      });
  };

  const signInWithGoogle = () => {
    firebase.auth.signInWithPopup(new fb.auth.GoogleAuthProvider())
      .then(() => {
        const user = firebase.auth.currentUser;
        dispatch(actions.appMessageSet('Welcome to Misenplace', messageTypes.SUCCESS));
        dispatch(actions.profile.profileAdd(user.uid, {
          currentaccount: null,
          email: user.email,
          name: user.displayName,
          provider: 'google',
          uid: user.uid
        }));
        dispatch(actions.applicationLoading());
        navigate('/', true);
      })
      .catch((err) => {
        setErrorMsg(err.message);
        console.log(err);
      });
  };


  return (
    <Page title="Misenplace" display="flex" justifyContent="center" alignItems="center" width="100%">
      <Block margin={majorScale(2)} padding={majorScale(3)} width={600} maxWidth={940}>
        <Pane textAlign="initial" marginBottom={minorScale(4)}>
          <CustomHeading level="1" marginBottom={majorScale(2)} textAlign='center'>Sign up</CustomHeading>
          <Pane width={300} marginX='auto' marginY={majorScale(2)}>
            <TextInputField
                required
                label="Full name"
                placeholder="Enter your full name"
                value={loginInfo.displayName || ''}
                onChange={(e) => onFieldChange('displayName', e.target.value)}
            />
            <TextInputField
                required
                type="email"
                label="Email address"
                placeholder="Enter your email address"
                value={loginInfo.email || ''}
                onChange={(e) => onFieldChange('email', e.target.value)}
            />
            <TextInputField
                required
                type="password"
                label="Password"
                placeholder="Enter your password"
                value={loginInfo.password || ''}
                onChange={(e) => onFieldChange('password', e.target.value)}
            />
            {errorMsg && <Alert intent="danger" appearance="card" marginBottom={minorScale(4)}>{errorMsg}</Alert>}
            <Pane marginBottom={majorScale(2)} width='100%'>
              <Button width='100%' appearance="primary" onClick={signIn} marginBottom={minorScale(4)}>Continue with email</Button>
              <Pane marginBottom={minorScale(4)}>
                <Pane borderBottom={'1px solid black'}>
                </Pane>
              </Pane>
              <Pane marginBottom={minorScale(4)}>
                <Button width='100%' appearance="primary" onClick={signInWithGoogle}>Continue with Google</Button>
              </Pane>
            </Pane>
            <Pane marginBottom={majorScale(2)}>
              <Link to='/signin'><Text>Have an account?</Text></Link>
            </Pane>
          </Pane>
          <Paragraph textAlign="center" color="rgba(0, 0, 0, 0.5)" margin={minorScale(2)}>By clicking “Continue with Email or Google” above, you acknowledge that you agree to Misenplace's Terms & Conditions.</Paragraph>
        </Pane>
      </Block>
    </Page>
  );
};

export default SignUp;
