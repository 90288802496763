//React
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

//Libraries
import { Dialog, majorScale, minorScale, Pane, Switch, Text, TextInputField } from 'evergreen-ui';
//import { nanoid } from 'nanoid';

//Components
import Button from '../../../components/ui/Button/Button';
import Block from '../../../components/ui/Block/Block';

//Files
import { actions } from '../../../store/actions';


const InfoDisplay = ({
  accountInfo
}) => {

  return (
    <Pane>

      {accountInfo && (
        <Pane marginBottom={minorScale(2)}>
          <Pane>
            <Text>Location ID: {accountInfo.deliverectLocationId}</Text>
          </Pane>
          
          <Pane>
            <Text>Channels:</Text>
            {accountInfo.deliverectLocationChannels.map(channel =>
              <Pane key={channel.id} display='flex' marginBottom={minorScale(2)}>
                <Text>ID: {channel.id}, name: {channel.name}</Text>
              </Pane>
            )}
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}

const ChannelPane = ({
  channel,
  index,
  editChannelField,
  removeChannel
}) => {

  return (
    <Pane display='flex' flex='1' alignItems='center' minHeight={55}>
      <TextInputField
          label="Deliverect channel ID"
          value={channel.id || ''}
          onChange={(e) => editChannelField('id', e.target.value, index)}
          marginRight={minorScale(2)}
      />
      <TextInputField
          label="Deliverect channel name"
          value={channel.name || ''}
          onChange={(e) => editChannelField('name', e.target.value, index)}
          marginRight={minorScale(2)}
      />
      <Button onClick={() => removeChannel(index)}>Remove</Button>
    </Pane>
  )
}

const ChannelsEdit = ({
  channels,
  channelControls
}) => {

  return (
    <Pane>
      {channels.map((channel, index) =>
        <ChannelPane key={`c_${index}`} channel={channel} index={index} {...channelControls} />
      )}
      <Button onClick={channelControls.addChannel}>Add channel</Button>
    </Pane>
  )
}

const Deliverectlocations = ({
  accountInfo,
  account,
  onFieldChange,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [openInfo, setOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [channels, setChannels] = useState(accountInfo.deliverectLocationChannels || [{}]);

  const addChannel = () => {
    setChannels((prevChannels) => ([...prevChannels, {}]))
  }

  const removeChannel = (index) => {
    if (index > -1) {
      const newChannels = [...channels];
      newChannels.splice(index, 1);
      setChannels(newChannels);
    };
  }

  const validateChannels = () => {
    if (channels.length === 0) return false;

    if (channels.findIndex(c => !c.id || !c.name) > -1) {
      return false;
    }

    return true;
  }

  const editChannelField = (field, value, index) => {
    const channel = {...channels[index], [field]: value};
    channels[index] = channel;
    setChannels([...channels])
  }

  const channelControls = {
    addChannel,
    removeChannel,
    editChannelField
  }

  const doDeactivate = () => {
    dispatch(actions.accounts.accountUpdate({
      ...accountInfo,
      has_deliverect: false
    }));
  };
  const doActivate = () => {
    dispatch(actions.accounts.accountUpdate({
      ...accountInfo,
      has_deliverect: true,
      deliverectLocationChannels: channels
    }));
  };
  const toggleDeliverect = () => {
    if (!accountInfo.has_deliverect) {
      if (!validateChannels(channels)) {
        dispatch(actions.appMessageError('Please add at least one Deliverect channel'));
        return
      }

      if (!accountInfo.deliverectLocationId) {
        dispatch(actions.appMessageError('Please add the Deliverect location ID'));
        return
      }

      doActivate();
    }
    else doDeactivate();
  }
  return (
    <Block marginY={majorScale(2)} padding={majorScale(2)}>
      <Pane display='flex'>
        <Text fontWeight='bold' marginBottom={majorScale(2)}>Deliverect reports sync</Text>
        <Switch
          onChange={() => setOpenToggle(true)}
          checked={!!accountInfo.has_deliverect}
          marginX={majorScale(2)}
        />
      </Pane>
      <Button margin={minorScale(2)} appearance="primary" type='button' onClick={() => setOpenToggle(true)}>{accountInfo.has_deliverect ? 'De-a' : 'A'}ctivate</Button>
      {
        account.has_deliverect && (
          <Button margin={minorScale(2)} appearance="primary" type='button' onClick={() => setOpen(true)}>Info</Button>
        )
      }
      <Dialog
          isShown={openInfo}
          title="Deliverect Integration"
          onCloseComplete={() => setOpen(false)}
      >
        <InfoDisplay accountInfo={accountInfo} />
      </Dialog>
      <Dialog
          isShown={openToggle}
          title="Deliverect Integration"
          confirmLabel="confirm"
          onCloseComplete={() => setOpenToggle(false)}
          onConfirm={(close) => {
            close();
            toggleDeliverect();
          }}
      >
        <Pane>
          {!account.has_deliverect && (
            <>
              <Text>Add your Deliverect location ID.</Text>
              <TextInputField
                  label="Deliverect location ID"
                  value={accountInfo.deliverectLocationId || ''}
                  onChange={(e) => onFieldChange('deliverectLocationId', e.target.value)}
                  marginRight={minorScale(2)}
              />
              <Text>Add your channels to start collecting reports.</Text>
              <ChannelsEdit channelControls={channelControls} channels={channels} />
            </>
          )}
          
          <Pane marginBottom={minorScale(2)}>
            <Text>Are you sure you want to {accountInfo.has_deliverect ? 'de-a' : 'a'}ctivate this Deliverect integration?</Text>
          </Pane>
        </Pane>
      </Dialog>
    </Block>
  )
}

export default Deliverectlocations;
