//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Routes, Route, useNavigate, useLocation, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Text, useTheme } from 'evergreen-ui';

//Pages
import TaskSetup from '../../pages/TaskSetup/TaskSetup';
import AssignGroupsTab from '../../pages/Account/AccountEditComponents/AssignGroupsTab';
import IntegrationsTab from '../../pages/Account/AccountEditComponents/IntegrationsTab';
import DetailsTab from '../../pages/Account/AccountEditComponents/DetailsTab';
import BudgetTab from '../../pages/Account/AccountEditComponents/BudgetTab';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../components/ui/Tab/Tab';
import IconWrapper from '../../components/Icons/Icons'
import PermissionChecker from '../../components/PermissionChecker/PermissionChecker';

//Files
import { accountTypes, colors } from '../../utils/constants';
import { actions } from '../../store/actions';
import useAuth from '../../hooks/useAuth';


const tabs = {
  details: { name: 'Details', permission: 'detailsArea' },
  'assign-groups': { name: 'Assign groups', permission: 'assignmentArea' },
  budget: { name: 'Budget', permission: 'financialsArea' },
  integrations: { name: 'Integrations', permission: 'integrationsArea' },
  tasks: { name: 'Tasks & HACCP', permission: 'tasksArea' }
};


const AreaEdit = () => {
  const { accountId } = useParams()
  //console.log(accountId, "ARREAA ACCOUNTID")
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const account = useSelector((state) => state.accounts[accountId]);
  const currentTab = routerLocation.pathname.split('/')[4] || 'details';
  const pageTitle = (account && account.name) ? `${account.name} - ${tabs[currentTab]}` : tabs[currentTab];
  const userAccounts = useSelector((state) => (state.accounts));
  
  let location;
  for (const id in userAccounts) {
    const uAccount = userAccounts[id];
    const isLocation = uAccount && uAccount.type?.includes(accountTypes.LOCATION);
    const isParent = uAccount.children?.includes(account.id);
    if (isLocation && isParent) location = uAccount;
  }
  const locationAreas = _.filter(userAccounts, (u) => u.type?.includes(accountTypes.DEPARTMENT) && location?.children?.includes(u.id));

  const headAccount = _.find(userAccounts, (ac) => {
    if (ac.id !== accountId &&
        ac.id !== location?.id &&
        location?.parents.indexOf(ac.id) > -1 &&
        ac.type?.includes(accountTypes.OWNER) &&
        ac.children?.indexOf(location?.id) > -1
      ) {
        return true;
      }
  });

  const dispatch = useDispatch();
  const { isOwner } = useAuth(accountId);
  const [accountInfo, setAccountInfo] = useState(account || {});
  const [errors, setErrors] = useState({});
  const isLocation = (account && account.type === accountTypes.LOCATION);
  const isDepartment = (account && (!account.type || account.type === accountTypes.DEPARTMENT));

  useEffect(() => {
    if (isOwner()) return
    navigate('/');
  }, [isOwner])

  useEffect(() => {
    if ((_.isEmpty(accountInfo) && account) || accountInfo?.id !== account?.id) {
      setAccountInfo(account);
    }
  }, [accountInfo, account]);

  const onFieldChange = (field, newValue) => {
    setAccountInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const doSave = () => {
    dispatch(actions.accounts.accountUpdate(accountInfo));
  };

  const props = {
    accountId,
    accountInfo,
    onFieldChange,
    isOwner,
    isDepartment,
    doSave,
    userAccounts,
    account,
    errors,
    setErrors,
    locationAreas,
    location,
    headAccount
  }

  if (!location) return null;

  return (
    <Page title={pageTitle} flex="1 0 auto">
      <Block
          background={colors.twilightBlue10}
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
          display="flex"
          alignItems="center"
          height={48}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={Link}
            to={`/locations/${location.id}/account`}
            marginRight={majorScale(2)}
        />
        <Text fontSize={18} color={theme.colors.black}>{account.name}</Text>
      </Block>
      <TabNavigation
          display="flex"
          flexShrink={0}
          overflowX="auto"
          paddingBottom={majorScale(2)}
      >
        {_.map(tabs, (tab, tabKey) => (
          <PermissionChecker key={tabKey} requiredPermission={tab.permission}>
            <Tab
              key={tabKey}
              is={Link}
              to={`${tabKey}`} // This is a relative path
              isSelected={currentTab === tabKey}
            >
              {tab.name}
            </Tab>
          </PermissionChecker>
        ))}
      </TabNavigation>
      
      <Block  background={'white'} padding={majorScale(2)} >
      <Routes>
        <Route path="details" element={<DetailsTab {...props} />} />
        <Route path="budget" element={<BudgetTab {...props} />} />
        <Route path="integrations" element={<IntegrationsTab {...props} />} />
        <Route path="tasks" element={<TaskSetup {...props} />} />
        <Route path="tasks/*" element={<TaskSetup {...props} />} />
        <Route path="assign-groups" element={<AssignGroupsTab {...props} />} />
        <Route path="*" element={<DetailsTab {...props} />} />
      </Routes>
      </Block>
    </Page>
  );
};

AreaEdit.defaultProps = {
  accountId: '',
};

export default AreaEdit;
