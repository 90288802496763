//React
import React from 'react';

//Files
import { accountTypes } from './utils/constants';

import {
  Account,
  AccountCreate,
  Archive,
  Dashboard,
  GroupSelect,
  FrontOfHouse,
  HOReports,
  IngredientEdit,
  IngredientUpload,
  Ingredients,
  IngredientCategories,
  LocationSelect,
  Lightspeed,
  Marketplace,
  MenuSetup,
  Messages,
  MessagesView,
  Ordering,
  OrderingEdit,
  OrderingReceive,
  OrderingReconcileCredit,
  OrderingReconcileInvoice,
  Profile,
  Suppliers,
  SupplierEdit,
  SupplierOptionEdit,
  Sections,
  SectionEdit,
  SalesImport,
  SignIn,
  SignUp,
  Stocks,
  SupplierOptionUpload,
  SupplierCategories,
  Tasks,
} from 'pages';

//Components
import ProcurementPricingDetail from './pages/Reports/CostsComponents/ProcurementPricingDetail';
import ReportPeriods from './pages/Reports/ReportPeriods';
import ReportPeriodDetail from './pages/Reports/ReportPeriodDetail';
import IngredientsPricing from './pages/HeadOfficeReports/CostsComponents/IngredientsPricing';
import OrderView from './pages/HeadOfficeReports/CostsComponents/OrderView';
import OverviewReports from './pages/Reports/Overview';
import CostsReports from './pages/Reports/Costs';
import SalesReports from './pages/Reports/Sales';
import Reports from './pages/HeadOfficeReports/Reports';
import ManageAreas from './pages/LocationSelect/ManageAreas';
import AreaEdit from './pages/Account/AreaEdit';
import ManageGroups from './pages/GroupSelect/ManageGroups';
 
export const appRoutes = [
  // Top-level pages (accessible for Head Office and Location accounts)
  { path: "/", element: <Dashboard /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/profile", element: <Profile /> },

  // Owner-specific pages -> Head office
  //Account type pages
  { path: "/account", element: <Account /> },
  { path: "/account/new", element: <AccountCreate accountType={accountTypes.OWNER} /> },

  //Analyse section
  { path: "/analytics/procurement/:id", element: <IngredientsPricing /> },
  { path: "/analytics/*", element: <Reports /> },

  //Products section
  { path: "/products/:accountId/setup/*", element: <MenuSetup /> },
  { path: "/departments/*", element: <GroupSelect /> }, //TO REVIEW
  { path: "/products/*", element: <GroupSelect /> },
  { path: "/products/manage-groups", element: <ManageGroups /> },
  { path: "/products/new", element: <AccountCreate accountType={accountTypes.DEPARTMENT} /> },

  //Ingredients section
  { path: "/ingredients/*", element: <Ingredients /> },
  { path: "/ingredients/add", element: <IngredientEdit /> },
  { path: "/ingredients/:ingredientId", element: <IngredientEdit /> },
  { path: "/ingredient-upload", element: <IngredientUpload /> },
  { path: "/ingredient-categories", element: <IngredientCategories /> },

  //Supplier section
  { path: "/suppliers/*", element: <Suppliers /> },
  { path: "/suppliers/add", element: <SupplierEdit /> },
  { path: "/suppliers/:supplierId/*", element: <SupplierEdit /> },
  { path: "/supplier-ingredient/:supplierId/add", element: <SupplierOptionEdit /> },
  { path: "/supplier-ingredient/:supplierId/:optionId", element: <SupplierOptionEdit /> },
  { path: "/supplier-ingredient-upload", element: <SupplierOptionUpload /> },
  { path: "/supplier-categories", element: <SupplierCategories /> },

  //Marketplace section
  { path: "/marketplace", element: <Marketplace /> },

  //Set up section
  //{ path: ":accountId/account/*", element: <Account accountType={accountTypes.OWNER}/> },
  //{ path: "/products/:accountId/settings/*", element: <GroupSettings /> },
  { path: "/locations/*", element: <LocationSelect /> },
  //{ path:"/locations", element: <LocationSelect />},
  { path:"/locations/new", element: <AccountCreate accountType={accountTypes.LOCATION} />},
  { path:"/locations/manage-areas", element: <ManageAreas />},
  { path:"/locations/:locationId/account", element: <Account accountType={accountTypes.LOCATION}/> },
  { path: "/areas/:accountId/account/*", element: <AreaEdit /> },
  
  //Message section
  { path: "/messages/*", element: <Messages /> },
  { path: "/messages/group", element: <MessagesView /> },

  // Department-specific pages -> Location
  //Reports Section
  { path: ":accountId/reports/overview", element: <OverviewReports /> },
  { path: ":accountId/reports/gross-profit/:periodStart/:periodEnd", element: <ReportPeriodDetail /> },
  { path: ":accountId/reports/gross-profit", element: <ReportPeriods /> },
  { path: ":accountId/reports/sales", element: <SalesReports /> },
  { path: ":accountId/reports/costs", element: <CostsReports /> },
  { path: ":accountId/reports/sales-import", element: <SalesImport /> },
  { path: ":accountId/reports/procurement/:id", element: <ProcurementPricingDetail /> },

  //Tasks & HACCP section
  { path: ":accountId/tasks/*", element: <Tasks /> },
  { path: ":accountId/sections/*", element: <Sections /> },
  { path: ":accountId/sections/add", element: <SectionEdit /> },
  { path: ":accountId/sections/:id", element: <SectionEdit /> },

  //Front-of-house section -> Product hub
  { path: ":accountId/front-of-house/*", element: <FrontOfHouse /> },

  //Ordering section -> Procurement
  { path: ":accountId/procurement/receive/:orderID", element: <OrderingReceive /> },
  { path: ":accountId/procurement/newOrder/:supplierId", element: <OrderingEdit /> },
  { path: ":accountId/procurement/newOrder/:supplierId/:date", element: <OrderingEdit /> },
  { path: ":accountId/procurement/newOrderPO/:supplierId/:type", element: <OrderingEdit /> },
  { path: ":accountId/procurement/reconcile-invoice/:supplierId", element: <OrderingReconcileInvoice /> },
  { path: ":accountId/procurement/reconcile-credit/:supplierId", element: <OrderingReconcileCredit /> },
  { path: ":accountId/procurement/*", element: <Ordering /> },

  //Stocks section -> Inventory
  { path: ":accountId/inventory/*", element: <Stocks /> },

  //Archive section -> Review
  { path: ":accountId/review/*", element: <Archive /> },
  { path: ":accountId/supplier-statements/:id", element: <OrderView /> },

  //Integration
  { path: "/ls", element: <Lightspeed /> },
];
