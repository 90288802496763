import React  from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../store/actions';
import { current } from '../utils/selectors';

const useTaskComments = ({accountId, date}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => ({ name: state.profile?.name, uid: state.profile?.uid }));
  const tasksComments = useSelector((state) => {
    // Filter task comments for current date
    return _.groupBy(
      _.orderBy(
        _.filter(current(state, 'tasksComments', accountId), (taskComment) => {
          return ( taskComment.date && moment(taskComment.date.toDate()).isSame(date, 'day') );
        }),
        'created'
      ),
      'type'
    );
  });

  const onCommentSave = (newComment) => {
    // Include common bits of info
    const commentInfo = {
      ...newComment,
      date,
      uid: userInfo.uid,
      userName: userInfo.name
    };
    // Do the save
    dispatch(actions.tasksComments.addTaskComment(accountId, commentInfo));
  };

  return {
    onCommentSave,
    tasksComments
  }
}

export default useTaskComments;
