//React 
import React from 'react';

//Libraries
import { majorScale, Pane, Table } from 'evergreen-ui';

//Components
import { Block } from '../../components/ui';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';

//Files
import { getRecipeCalculatedFields, getRecipeItemDetails } from '../../utils/functions';
import { toFixed } from '../../utils/format';


const AddOn = ({item,
  index,
  setRemovingAddon,
  setEditingAddon,
  isReadonly,
  hideEdit,
  blockProps,
  accountId
}) => {

  return (
    <Block
      marginBottom={majorScale(2)}
      padding={majorScale(2)}
      {...blockProps}
    >
      <Pane>
        <Pane display='flex'>
          <CustomHeading level="4" marginBottom={majorScale(2)} flex={1}>{item.name}</CustomHeading>
          {!hideEdit &&
            <>
              <IconWrapper marginRight={majorScale(1)} name="edit" appearance="clickable" onClick={() => setEditingAddon({ item, index })} disabled={isReadonly} />
              <IconWrapper name="trash" appearance="danger" onClick={() => setRemovingAddon({ item, index })} disabled={isReadonly} />
            </>
          }
        </Pane>
          <Table>
            <Table.Head paddingRight={0}>
              <Table.TextHeaderCell>Ing</Table.TextHeaderCell>
              <Table.TextHeaderCell>Qty</Table.TextHeaderCell>
              <Table.TextHeaderCell>UOM</Table.TextHeaderCell>
              <Table.TextHeaderCell>Cost</Table.TextHeaderCell>
              <Table.TextHeaderCell>PLU</Table.TextHeaderCell>
              <Table.TextHeaderCell>Price</Table.TextHeaderCell>
              <Table.TextHeaderCell>Vat</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              { item.ingredients && item.ingredients.map((ing, itemIndex) => {
                  const details = getRecipeItemDetails(ing, accountId);
                  const calculatedFields = getRecipeCalculatedFields(ing, details);
                  return (
                    <Table.Row key={`mod_i_${itemIndex}`}>
                      <Table.TextCell>{ing.name}</Table.TextCell>
                      <Table.TextCell>{ing.quantity}</Table.TextCell>
                      <Table.TextCell>{calculatedFields.recipeUnit}</Table.TextCell>
                      <Table.TextCell>{toFixed(calculatedFields.cost, 4)}</Table.TextCell>
                      <Table.TextCell>{ing.plu}</Table.TextCell>
                      <Table.TextCell>{ing.price}</Table.TextCell>
                      <Table.TextCell>{ing.vat}</Table.TextCell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
      </Pane>
    </Block>
  )
}

export default AddOn;
