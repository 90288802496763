import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addTasksComment, removeTasksComment, updateTasksComment } from '../firebase/tasksComments';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* taskSaga() {
  yield takeEvery(actionTypes.tasksComments.ADD_TASK_COMMENT, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addTasksComment, action.comment, accountId);
      yield put(actions.appMessageSuccess('Task Comment Added'));
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Task Comment could not be added'));
    }
  });
  yield takeEvery(actionTypes.tasksComments.REMOVE_TASK_COMMENT, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeTasksComment, action.commentId, accountId);
      yield put(actions.appMessageSuccess('Task Comment Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task Comment could not be removed'));
    }
  });
  yield takeEvery(actionTypes.tasksComments.UPDATE_TASK_COMMENT, function* (action) {
    const { id, ...comment } = action.comment;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateTasksComment, id, comment, accountId);
      yield put(actions.appMessageSuccess('Task Comment Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task Comment could not be updated'));
    }
  });
};
