//React
import React from 'react';

//Libraries
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text, Paragraph, useTheme } from 'evergreen-ui';

//Files
import { datePeriodFormat } from '../../../utils/format';


const PeriodDisplay = ({ periodStart, periodEnd }) => {
  const periodFormatted = datePeriodFormat(periodStart, periodEnd);
  //console.log(periodStart, periodEnd, 'DATEPANELPERIOD')
  const theme= useTheme();
  return (
    <Paragraph size={500}>Week
      <Text size={500} color={theme.colors.tertiary100}> {moment(periodStart).week()}</Text> - 
      <Text size={500} color={theme.colors.tertiary100}> {periodFormatted.from}</Text> to 
      <Text size={500} color={theme.colors.tertiary100}> {periodFormatted.to}</Text>
    </Paragraph>
  );
};

PeriodDisplay.propTypes = {
  periodStart: PropTypes.instanceOf(Date).isRequired,
  periodEnd: PropTypes.instanceOf(Date).isRequired
};

export default PeriodDisplay;
