//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, UnorderedList, ListItem, Text, useTheme} from 'evergreen-ui';

//Components
import QuickOrder from './QuickOrder';
import UploadModal from './UploadModal';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import Button from '../../../components/ui/Button/Button'
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { buildSuppliersList } from '../Ordering';


const PlaceOrder = () => {
  const { accountId } = useParams();
  const theme = useTheme();
  const suppliers = useSelector((state) => buildSuppliersList(accountId, state));
  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <React.Fragment>
      {/*<Block
          flex="1 1 auto"
          marginBottom={majorScale(2)}
          overflowY="auto"
      >
        <QuickOrder accountId={accountId} />
      </Block>*/}
      <Block
          display="flex"
          flexDirection="column"
          flex="1 1 auto"
          overflowY="auto"
          marginBottom={majorScale(2)}
          maxHeight="75vh"
      >
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
        <CustomHeading level="3" padding={majorScale(2)}>Suppliers</CustomHeading>
        <Button 
          appearance='primary'
          onClick={() => setIsModalOpen(true)}
          marginRight={majorScale(2)}
        >
          Issue PO
        </Button>
        </Pane>

        <Pane 
          flex="1" 
          overflowX="auto" 
        >
          <UnorderedList 
            listStyle="none" 
            marginLeft={majorScale(1)} 
            padding={0} 
            maxWidth={360}>
            {_.map(suppliers, (supplier) => (
              <ListItem
                  key={supplier.id}
                  is={Link}
                  to={`/${accountId}/procurement/newOrder/${supplier.id}`}
                  display="flex"
                  alignItems="center"
                  paddingX={majorScale(2)}
                  margin={0}
                  textDecoration="none"
                  height={40}
              >
                <Text
                    color={theme.colors.black}
                    flex="1 0 auto"
                    paddingY={majorScale(1)}
                >{supplier.name}</Text>
                <IconWrapper name="plus" appearance="clickable" />
              </ListItem>
            ))}
          </UnorderedList>
        </Pane>
      </Block>
      {isModalOpen && (
        <UploadModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} accountId={accountId} />
      )}
    </React.Fragment>
  );
};

export default PlaceOrder;
