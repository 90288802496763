//React
import React, { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
//import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Table } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { minDate, TODAY, getWE } from '../../../utils/time';
import { shortDateDisplayFormat } from '../../../utils/format';
import { current } from '../../../utils/selectors';


const DueDiligenceArchive = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();

  // Determine the earliest date that we have to return to, which is today's date minus 4 months in UTC
  const earliestTask = useMemo(() => {
    const fourMonthsAgo = moment.utc().subtract(4, 'months').toDate();
    return getWE(fourMonthsAgo);
  }, []);

  const weeks = useMemo(() => {
    const result = [];
    let weekEndDate = moment.utc(getWE(TODAY));
    while (weekEndDate.isSameOrAfter(earliestTask)) {
      result.push({
        week: weekEndDate.format('[Week ] WW'),
        weekEnding: weekEndDate.clone()
      });
      weekEndDate.subtract(1, 'week');
    }
    return result;
  }, [earliestTask]);

  return (
    <Block marginBottom={majorScale(2)}>
      <CustomHeading level="3" marginY={majorScale(2)} padding={majorScale(2)}>Due Diligence</CustomHeading>
      <Table>
        <Table.Head height={48} paddingRight={majorScale(2)}>
          <Table.TextHeaderCell flex="0 0 20%">Period</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="1 0 auto"></Table.TextHeaderCell>
          <Table.TextHeaderCell flex="0 0 20%">View</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.map(weeks, (week) => (
            <Table.Row 
              key={week.weekEnding.format('YYYY-WW')} 
              isSelectable 
              height={48}
              paddingRight={majorScale(2)} 
              onSelect={() => navigate(`/${accountId}/review/due-diligence/${week.weekEnding.format('YYYY-WW')}`)}>
              <Table.TextCell flex="0 0 20%">{week.week}</Table.TextCell>
              <Table.TextCell flex="1 0 auto">Ending - {shortDateDisplayFormat(week.weekEnding.toDate())}</Table.TextCell>
              <Table.Cell flex="0 0 20%">
                <IconWrapper
                    name="clipboard"
                    appearance="clickable"
                    is={Link}
                    to={`/${accountId}/archive/due-diligence/${week.weekEnding.format('YYYY-WW')}`}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Block>
  );
};

export default DueDiligenceArchive;
