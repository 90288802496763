import { accountsRef } from './accounts'
import fb from './'
import { getCollectionRef } from './common'
import { getGroupDepartments } from '../../utils/accounts'

export const addIngredient = async (ingredient, accountId, userName) => {
  const ingredientsRef = getCollectionRef('ingredients', accountId);

  // Set default values if not provided
  const defaultIngredient = {
    ...ingredient,
    allergens: ingredient.allergens || [],
    supplierOptions: ingredient.supplierOptions || [],
    createdBy: userName,
    createdAt: fb.serverTime(),
    updatedBy: null,
    updatedAt: null,
    archived: false,
    deleted: false
  };

  try {
    const docRef = await ingredientsRef.add(defaultIngredient);
    const ingredientId = docRef.id; // Retrieve the generated document ID

    // Update the document with the itemcode
    await docRef.update({ itemcode: ingredientId });

    // Return the ingredient with ID and itemcode
    return { id: ingredientId, itemcode: ingredientId, ...defaultIngredient }; 
  } catch (error) {
    console.error('Error adding ingredient:', error);
    throw error;
  }
};

export const updateIngredient = async (ingredientId, ingredient, accountId, userName) => {
  const ingredientsRef = getCollectionRef('ingredients', accountId);
  await ingredientsRef.doc(ingredientId).update({
    ...ingredient,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
  console.log(`Ingredient ${ingredientId} updated in ingredients collection.`);
};

export const archiveIngredient = async (ingredientId, accountId, userName) => {
  const ingredientsRef = getCollectionRef('ingredients', accountId);
  await ingredientsRef.doc(ingredientId).update({
    archived: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
  });
  console.log(`Ingredient ${ingredientId} archived in ingredients collection.`);
};

export const removeIngredient = async (ingredientId, accountId, userName) => {
  const ingredientsRef = getCollectionRef('ingredients', accountId);
  await ingredientsRef.doc(ingredientId).update({
    deleted: true,
    updatedBy: userName,
    updatedAt: fb.serverTime(),
    supplierOptions: [],
  });
  console.log(`Ingredient ${ingredientId} marked as deleted in ingredients collection.`);
};

/**
 * This function removes an ingredient from all recipes within relevant departments.
 * It iterates through the departments, retrieves the recipes, and updates them to remove the specified ingredient.
 *
 * @param {string} ingredientId - The ID of the ingredient to be removed.
 * @param {string} accountId - The ID of the current account.
 * @param {string} userName - The name of the user performing the action.
 * @param {object} state - The application's state.
 */
export const removeIngredientFromRecipes = async (ingredientId, accountId, userName, state) => {
  const relevantDepartments = getGroupDepartments(state);

  for (const department of relevantDepartments) {
    const recipesRef = getCollectionRef('recipes', department.id);
    const snapshot = await recipesRef.get();

    const batch = recipesRef.firestore.batch();

    snapshot.forEach(doc => {
      const recipe = doc.data();
      let ingredientFound = false;

      if (!Array.isArray(recipe.ingredients)) {
        return; // Skip to the next recipe if ingredients are not an array
      }

      const updatedIngredients = recipe.ingredients.filter(ingredient => {
        if (ingredient.id === ingredientId) {
          ingredientFound = true;
          return false; // Remove the ingredient with the matching ingredientId
        }
        return true; // Keep the ingredient
      });

      if (ingredientFound) {
        batch.update(doc.ref, {
          ingredients: updatedIngredients,
          updatedBy: userName,
          updatedAt: fb.serverTime(),
        });
      }
    });

    await batch.commit();
    console.log(`Ingredient ${ingredientId} removed from related recipes in department ${department.id}.`);
  }

  console.log(`Ingredient ${ingredientId} removed from related recipes in account ${accountId}.`);
};

export const uploadIngredients = (accountId, bucket, file) => {
  const uploadRef = accountsRef.doc(accountId).collection('uploads')
  const upload = {
    uploadtype: 'ingredients',
    uploadname: file.name,
    status: 'processing',
    created: fb.serverTime()
  }
  return uploadRef.add(upload).then(function (docRef) {
    const uploadId = docRef.id
    const filename = bucket + '/uploads/ingredients/' + uploadId + '.csv'
    docRef.update({filename: filename})
    return fb.storage.ref().child(filename).put(file).then(snapshot => {
      return 'Ingredients file uploaded'
    }).catch(err => {
      console.log(err)
      return 'Error uploading file'
    })
  })
}
