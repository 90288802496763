//React
import React from 'react';
import { useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';

//Components
import { Page } from '../../components';
import AccountEdit from './AccountEdit'


const Account = (props) => {
  const workingAccount = useSelector((state) => state.workingAccount);
  //console.log('workingAccount', workingAccount)
  const currentAccount = useSelector((state) => state.currentAccount);
  //console.log('currentAccount', currentAccount)
  const accountId = props.accountId || workingAccount || currentAccount;
  //console.log('accountId', accountId)
  const accountName = useSelector((state) => (state.accounts[accountId] && state.accounts[accountId].name));
  //console.log('accountName', accountName)

  return (
    <Page title={`${accountName ? `${accountName} Account` : ''}`} >
      <AccountEdit accountId={accountId} />
    </Page>
  );
};

Account.propTypes = {
  accountId: PropTypes.string,
};

export default Account;
