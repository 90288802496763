import { getCollectionRef } from './common';

export const addSection = (section, accountId) => {
  const sectionsRef = getCollectionRef('sections', accountId);
  return sectionsRef.add(section);
};

export const removeSection = (sectionId, accountId) => {
  const sectionsRef = getCollectionRef('sections', accountId);
  return sectionsRef.doc(sectionId).delete();
};

export const updateSection = (sectionId, section, accountId) => {
  const sectionsRef = getCollectionRef('sections', accountId);
  return sectionsRef.doc(sectionId).update(section);
};
