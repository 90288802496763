const RECIPES_LOAD_SUCCESS = 'RECIPES_LOAD_SUCCESS';
const RECIPES_LOAD_PROCESSED = 'RECIPES_LOAD_PROCESSED';
const RECIPES_LOAD_FAILURE = 'RECIPES_LOAD_FAILURE';
const RECIPES_CLEAR = 'RECIPES_CLEAR';
const ADD_RECIPE = 'ADD_RECIPE';
const UPDATE_RECIPE = 'UPDATE_RECIPE';
const ARCHIVE_RECIPE = 'ARCHIVE_RECIPE'
const REMOVE_RECIPE = 'REMOVE_RECIPE';
//This action applies for Archive and Delete actions
const REMOVE_RECIPE_FROM_COLLECTIONS = 'REMOVE_RECIPE_FROM_COLLECTIONS';

export const actionTypes = {
  RECIPES_LOAD_SUCCESS,
  RECIPES_LOAD_PROCESSED,
  RECIPES_LOAD_FAILURE,
  RECIPES_CLEAR,
  ADD_RECIPE,
  UPDATE_RECIPE,
  ARCHIVE_RECIPE,
  REMOVE_RECIPE,
  REMOVE_RECIPE_FROM_COLLECTIONS
};

const recipesLoadSuccess = (payload, account) => {
  return {
    type: RECIPES_LOAD_SUCCESS,
    payload,
    account
  };
};

const recipesLoadProcessed = (payload, account) => {
  return {
    type: RECIPES_LOAD_PROCESSED,
    payload,
    account
  };
};

const recipesLoadFailure = (error) => {
  return {
    type: RECIPES_LOAD_FAILURE,
    error
  };
};

const recipesClear = () => {
  return {
    type: RECIPES_CLEAR
  };
};

const addRecipe = (accountId, recipeInfo, recipeImg, callback) => {
  return {
    type: ADD_RECIPE,
    accountId,
    recipeInfo,
    recipeImg,
    callback,
  };
};

const updateRecipe = (accountId, recipeInfo, recipeImg, callback) => {
  return {
    type: UPDATE_RECIPE,
    accountId,
    recipeInfo,
    recipeImg,
    callback,
  };
};

const archiveRecipe = (accountId, recipeId) => {
  return {
    type: ARCHIVE_RECIPE,
    accountId,
    recipeId,
  };
};

const removeRecipe = (accountId, recipeId) => {
  return {
    type: REMOVE_RECIPE,
    accountId,
    recipeId,
  };
};

const removeRecipeFromCollections = (accountId, recipeId, usageInfo) => {
  return {
    type: REMOVE_RECIPE_FROM_COLLECTIONS,
    accountId,
    recipeId,
    usageInfo,
  };
};

export const actions = {
  recipesLoadSuccess,
  recipesLoadProcessed,
  recipesLoadFailure,
  recipesClear,
  addRecipe,
  updateRecipe,
  archiveRecipe,
  removeRecipe,
  removeRecipeFromCollections
};
