import _ from 'lodash';
import store from 'store/redux';
import { current, selectIngredientCategoryHierarchy } from './selectors';

//Function to filter out archived ingredients
export const filterActiveIngredients = (ingredients) => {
  return _.sortBy(ingredients.filter(ingredient => !ingredient.isArchived && !ingredient.archived && !ingredient.deleted), 'name');
};

// Function to filter out archived or deleted supplier options
export const filterActiveSupplierOptions = (supplierOptions) => {
  return _.sortBy(
    supplierOptions.filter(option => !option.isArchived && !option.archived && !option.deleted),
    'name'
  );
};

export const buildRecipeIngs = (state) => {
  const account = state.accounts[state.currentAccount];
  if (!account || !account.children) {
    return [];
  }

  const recipes = account.children.reduce((final, child) => {
    // Safely check for recipes and only process if they exist
    const childRecipes = state.recipes[child];
    if (Array.isArray(childRecipes)) {
      const childRecipesIngs = childRecipes.map(
        (recipe) => recipe.ingredients ? recipe.ingredients.map(ing => ing.id) : []
      );
      final = final.concat(childRecipesIngs);
    }
    // If it's not an array, simply continue without altering 'final'
    return final;
  }, []);

  return recipes.flat();  // Flatten to a single array of ingredient IDs
};

export const findRecipesUsingIngredient = (state, ingredientId) => {
  // Check if currentAccount is defined and valid
  if (!state.currentAccount || !state.accounts[state.currentAccount]) {
    console.error('Current account is undefined or invalid');
    return [];
  }
  const account = state.accounts[state.currentAccount];
  let recipesUsingIngredient = [];

  if (account?.children) {
    account.children.forEach(child => {
      const childRecipes = state.recipes[child] || [];
      childRecipes.forEach(recipe => {
        if (Array.isArray(recipe.ingredients) && recipe.ingredients.some(ing => ing.id === ingredientId)) {
          recipesUsingIngredient.push({
            id: recipe.id,
            name: recipe.name,
            groupId: child
            // Add any other recipe details you need
          });
        }
      });
    });
  }

  return recipesUsingIngredient;
};

//Not in use yet
export const findSupplierOptionsUsingIngredient = (state, ingredientId) => {
  const activeAccountId = state.currentAccount;

  // Log the entire state and the active account ID
  console.log('State:', state);
  console.log('Active Account ID:', activeAccountId);

  const supplierOptions = _.get(state, ['accounts', activeAccountId, 'supplierOptions'], {});

  if (!supplierOptions) {
    console.error('Supplier options for the active account are undefined in state');
    return [];
  }

  const supplierOptionsUsingIngredient = [];

  // Debugging to check the structure and content of supplierOptions
  console.log('supplierOptions:', supplierOptions);

  Object.values(supplierOptions).forEach(option => {
    if (option.ingredientId === ingredientId) {
      supplierOptionsUsingIngredient.push(option);
    }
  });

  return supplierOptionsUsingIngredient;
};

export const buildIngredientsData = () => {
  const state = store.getState();

  // Safely access the ingredients list, ensuring it defaults to an empty array if not found
  const allIngredientsList = current(state, 'ingredients') || [];

  // Filters out archived ingredients and sorts them; this chain is safe as we default to an array above
  const ingredientsList = _
    .chain(allIngredientsList)
    .filter(ingredient => !ingredient.isArchived && !ingredient.archived && !ingredient.deleted)
    .sortBy('name')
    .value();

  // Ensure supplierOptions and suppliers are accessed safely
  const supplierOptionList = _.groupBy(current(state, 'supplierOptions') || [], 'ingredientId');
  const suppliers = _.keyBy(current(state, 'suppliers') || [], 'id');

  // Safely build recipe ingredients, ensuring the function handles potential undefined values within
  const recipesIngs = buildRecipeIngs(state);

  // Safely access category data, ensuring defaults prevent errors
  const categoryMap = _.keyBy(current(state, 'ingredientCategories') || [], 'id');
  const categoryHierarchy = selectIngredientCategoryHierarchy(state);

  return {
    ingredientsList,
    supplierOptionList,
    suppliers,
    recipesIngs,
    categoryMap,
    categoryHierarchy
  };
}


const columns = ['ingrdient_name', 'u_id', 'category', 'recipe_unit', 'num_recipes', 'num_article', 'num_supplier', 'supplier', 'itemcode', 'supplier_ingredient_name', 'uom', 'unit_price','numperportion','gramperportion','selected', 'celery','crustaceans','eggs','fish','gluten','lupin','milk','molluscs','mustard','nuts','peanuts','sesame','soybeans','sulphites']

const buildSupplierIngrdientExport = ({opt, suppliers}) => {
  const supplier = opt.supplierId ? suppliers[opt.supplierId] : null;
  const supplierIng = {
    ...opt,
    supplier_ingredient_name: opt.name,
    supplier: supplier ? supplier.name : null,
    itemcode: opt.supplier_code,
    unit_price: opt.unitprice
  }
  if (opt.allergens && opt.allergens.length > 0) {
    for (const allergen of opt.allergens) {
      supplierIng[allergen.toLowerCase()] = 1
    }
  }
  return supplierIng;
}

const buildIngExport = ({ing, categoryMap}) => ({
  ...ing,
  ingrdient_name: ing.name,
  u_id: ing.itemcode,
  category: categoryMap[ing.categoryId] ? categoryMap[ing.categoryId].name : null,
  recipe_unit: ing.recipeunit
})

const processColumnsData = (data) => {
  return columns.reduce((obj, column) => {
    if (data[column]) obj[column] = data[column];
    return obj;
  }, {})
}

const buildSupplierOptionData = ({
  opt,
  recipesIngs,
  supplierOptions,
  ing,
  ...rest
}) => {
  const supplierIng = buildSupplierIngrdientExport({opt, ...rest})
  const recipes = ing ? _.filter(recipesIngs, (ingList) => (_.includes(ingList, ing.id))) : [];
  const ingProcessed = ing ? buildIngExport({ing, ...rest}) : {}
  const optData = {
    ...supplierIng,
    ...ingProcessed,
    num_supplier: (_.uniqBy(supplierOptions, 'supplierId')).length,
    num_recipes: recipes.length || 0,
    num_article: supplierOptions.length
  }
  return processColumnsData(optData)
}

const buildIngredientData = ({ing, supplierOptions, ...rest}) => { 
  if (!supplierOptions) {
    return [processColumnsData(buildIngExport({ing, ...rest}))]
  }

  return supplierOptions.map(opt => {
    return buildSupplierOptionData({
      supplierOptions,
      opt,
      ing,
      ...rest
    })
  })
}

const buildCsvData = ({ingredientsList, supplierOptionList, ...rest}) => {
  let ingsData = ingredientsList.reduce((arr, ing) => {
    const supplierOptions = supplierOptionList[ing.id]
    return arr.concat(buildIngredientData({ing, supplierOptions, ...rest}));
  }, []);

  if (supplierOptionList.undefined) {
    ingsData = ingsData.concat(supplierOptionList.undefined.map(opt => {
      return processColumnsData(buildSupplierIngrdientExport({opt, ...rest}))
    }));
  }

  return ingsData;
}

const exportIngredients = (data) => {
  data = data || buildIngredientsData();
  return {
    data: buildCsvData(data),
    columns
  }
}

export default exportIngredients