import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addWastage, removeWastage, updateWastage } from '../firebase/wastages';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* wastageSaga() {
  yield takeEvery(actionTypes.wastages.ADD_WASTAGE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addWastage, action.wastage, accountId);
      yield put(actions.appMessageSuccess('Wastage Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Wastage could not be added'));
    }
  });
  yield takeEvery(actionTypes.wastages.REMOVE_WASTAGE, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeWastage, action.wastageId, accountId);
      yield put(actions.appMessageSuccess('Wastage Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Wastage could not be removed'));
    }
  });
  yield takeEvery(actionTypes.wastages.UPDATE_WASTAGE, function* (action) {
    const { id, ...wastage } = action.wastage;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateWastage, id, wastage, accountId);
      yield put(actions.appMessageSuccess('Wastage Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Wastage could not be updated'));
    }
  });
};
