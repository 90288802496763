const TASKS_HISTORY_LOAD_SUCCESS = 'TASKS_HISTORY_LOAD_SUCCESS';
const TASKS_HISTORY_CLEAR = 'TASKS_HISTORY_CLEAR';
const ADD_TASK_HISTORY_ITEM = 'ADD_TASK_HISTORY_ITEM';
const REMOVE_TASK_HISTORY_ITEM = 'REMOVE_TASK_HISTORY_ITEM';
const UPDATE_TASK_HISTORY_ITEM = 'UPDATE_TASK_HISTORY_ITEM';

export const actionTypes = {
  TASKS_HISTORY_LOAD_SUCCESS,
  TASKS_HISTORY_CLEAR,
  ADD_TASK_HISTORY_ITEM,
  REMOVE_TASK_HISTORY_ITEM,
  UPDATE_TASK_HISTORY_ITEM,
};


const tasksHistoryLoadSuccess = (payload, account) => {
  return {
    type: TASKS_HISTORY_LOAD_SUCCESS,
    payload,
    account
  };
};

const tasksHistoryClear = () => {
  return {
    type: TASKS_HISTORY_CLEAR
  };
};

const addTaskHistoryItem = (accountId, taskItem) => {
  return {
    type: ADD_TASK_HISTORY_ITEM,
    taskItem,
    accountId,
  };
};

const removeTaskHistoryItem = (accountId, taskItemId) => {
  return {
    type: REMOVE_TASK_HISTORY_ITEM,
    taskItemId,
    accountId,
  };
};

const updateTaskHistoryItem = (accountId, taskHistoryItem) => {
  return {
    type: UPDATE_TASK_HISTORY_ITEM,
    taskHistoryItem,
    accountId,
  };
};

export const actions = {
  tasksHistoryLoadSuccess,
  tasksHistoryClear,
  addTaskHistoryItem,
  removeTaskHistoryItem,
  updateTaskHistoryItem,
};
