import { takeEvery, put, call, select } from 'redux-saga/effects';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';
import { addMessagesSeenTimes, updateMessagesSeenTimes } from '../firebase/messagesSeenTimes';

export default function* messagesSeenTimesSaga() {
  yield takeEvery(actionTypes.messagesSeenTimes.MESSAGES_SEEN_UPDATE, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      const messagesSeen = yield select(state => state.messagesSeenTimes[action.profile.currentaccount]);
      if (messagesSeen?.length) {
        // update
        messagesSeen[0][action.profile.id] = new Date();
        const { id, ...moreInfo } = messagesSeen[0];
        yield call(updateMessagesSeenTimes, id, moreInfo, accountId);
      }
      else {
        // new
        const messagesSeen = {};
        messagesSeen[action.profile.id] = new Date();
        yield call(addMessagesSeenTimes, messagesSeen, accountId);
      }
    }
    catch (err) {
      console.log(err,'err')
      yield put(actions.appMessageError('Message seen time could not be updated.'));
    }
  });
};
