//React
import React, { useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import ModifierEditForm from '../../Modifiers/ModifierEditForm';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import  { formatDate } from '../../../utils/format';


const ModifierEdit = ({ accountId }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { modifierId } = useParams();
  const parentPage = `/products/${accountId}/setup/modifiers`;
  const modifier = useSelector((state) => {
    if (modifierId) {
      return _.find(current(state, 'modifiers', accountId), { id: modifierId }) || false;
    }
    return false;
  });
  const formId = modifierId ? `modifier_${modifierId}` : 'new_modifier';
  const modifierForm = useSelector((state) => {
    return state.forms[formId] ? state.forms[formId] : {}
  });
  const modifierInfo = modifierForm.data;

  useEffect(() => {
    if (_.isEmpty(modifierInfo) && modifier) {
      dispatch(actions.forms.formChange(modifier || {}, formId))
    }
  }, []);

  const changeModifier = (modifier) => dispatch(actions.forms.formChange(modifier, formId));
  const isReadonly = !!modifier.readonly;

  const callback = () => {
    dispatch(actions.forms.formClear(formId))
    navigate(parentPage);
  }

  const saveModifier = () => {
    if (modifierId) {
      dispatch(actions.modifiers.updateModifier(accountId, modifierInfo, callback));
    }
    else {
      dispatch(actions.modifiers.addModifier(accountId, modifierInfo, callback));
    }
  };

  const resetModifier = () => {
    const resetTo = modifier || {};
    dispatch(actions.forms.formChange(resetTo, formId));
  }

  const lastEditedText = () => {
    if (modifier?.updatedBy || modifier?.createdBy) {
      const editedBy = modifier?.updatedBy ? modifier?.updatedBy : modifier?.createdBy;
      const editedAt = modifier?.updatedAt ? formatDate(modifier?.updatedAt) : formatDate(modifier?.createdAt);
      if (editedAt) {
        return `Last edited by: ${editedBy} on: ${editedAt}`;
      }
    }
    return '';
  };

  const backNav = () => {
    window.history.back();
  }

  return (
    <>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(2)}
      >
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center">
            <IconWrapper
                onClick={backNav}
                name="arrowLeft"
                appearance="clickable"
                marginRight={majorScale(2)}
            />
            <CustomHeading level="3" flex="1 0 auto" color={theme.colors.black}>{(modifierId) ? modifier.name || 'Group modifier name' : 'New group modifier'}</CustomHeading>
          </Pane>
          {lastEditedText && (
              <Text marginTop={majorScale(1)}>{lastEditedText()}</Text>
          )}
        </Pane>
        <Pane display="flex" alignItems="center">
          <Button
              appearance="primary"
              onClick={() => saveModifier()}
              disabled={isReadonly || _.isEmpty(modifierInfo)}
          >Save</Button>
          <Button
              appearance="minimal"
              onClick={resetModifier}
              disabled={isReadonly || _.isEmpty(modifierInfo)}
              marginLeft={majorScale(2)}
          >{modifierId ? 'reset' : 'clear'}</Button>
        </Pane>
      </Block>

      <ModifierEditForm
          accountId={accountId}
          modifier={modifierInfo}
          onChange={changeModifier}
      />
    </>
  )
};

ModifierEdit.propTypes = {
  accountId: PropTypes.string.isRequired,
  modifierId: PropTypes.string,
};

ModifierEdit.defaultProps = {
  modifierId: '',
};

export default ModifierEdit;
