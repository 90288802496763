//React
import React, { useState, useEffect } from 'react';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, Icon } from 'evergreen-ui';

//Components
import Accordion from '../../../components/ui/Accordion/Accordion';
import StocktakeTable from './StocktakeTable';

//Files
import { calcStocktakeIngredientCost, denormalizeIngredientQuantity, denormalizeIngredientUnit, filterItems, 
  generateSearchFieldsFn, getIngredientUOMOptions, normalizeIngredientQuantity } from '../../../utils/functions';
import { colors } from '../../../utils/constants';
import { currency } from '../../../utils/format';


const StocktakeIngredientsCategory = ({ categoryOpt, ingredients, filters, isOpen, toggleVisibility,
  setCategory, stockTakeInfo, isVisible, ...rest }) => {
  const ingList = _.filter(ingredients, (ing) => {
    // Check if the ingredient is not archived
    const notArchived = !ing.isArchived;
    // Check for category condition
    const categoryCondition = (categoryOpt.value === 'uncategorized') 
      ? _.isEmpty(ing.categoryId) 
      : _.includes(ing.categoryId, categoryOpt.value);
    // Return true if the ingredient is not archived and meets the category condition
    return notArchived && categoryCondition;
  });

  if (ingList.length === 0) {
    return null;
  }

  const ingStock = (stockTakeInfo.ingredients ?? []).filter(i => ingList.findIndex(ing => ing.id === i.id) > -1);
  const categoryTotal = _.reduce(ingStock, (sum, item) => {
    return sum + (item.worth || 0)
  }, 0);

  const toggleOpen = () => {
    if (isVisible) {
      setCategory(isOpen ? null : categoryOpt.value);
    }
  };

  return (
    <Accordion
      label={categoryOpt.label}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
      isVisible={isVisible}
      toggleVisibility={() => {
        if (!isOpen) {
          toggleVisibility(categoryOpt.value);
        }
      }}
    >
      <Pane>
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
        >
          <StocktakeTable
            ings={ingList}
            stockItems={ingStock}
            getUnits={getIngredientUOMOptions}
            denorm={{
              quantity: denormalizeIngredientQuantity,
              unit: denormalizeIngredientUnit
            }}
            {...rest}
          />
        </Pane>
        <Pane
          background={colors.twilightBlue10}
          padding={majorScale(1)}
          display="flex"
          justifyContent="flex-end"
        >
          <Text marginRight={majorScale(2)}>Total</Text>
          <Text flexBasis={150} flexGrow={0} color={colors.orange}>£ {currency(categoryTotal)}</Text>
        </Pane>
      </Pane>
    </Accordion>
  );
}

const StocktakeIngredients = ({  setStockTakeInfo, updateForm, categoryOptions, filters, category, setCategory, ingredients, ...rest }) => {  
  const [visibleCategories, setVisibleCategories] = useState(() => {
    const saved = localStorage.getItem('visibleCategories');
    return saved ? JSON.parse(saved) : categoryOptions.map(opt => opt.value);
  });

  useEffect(() => {
    localStorage.setItem('visibleCategories', JSON.stringify(visibleCategories));
  }, [visibleCategories]);

  const toggleVisibility = (category) => {
    setVisibleCategories((prevVisibleCategories) => {
      if (prevVisibleCategories.includes(category)) {
        return prevVisibleCategories.filter((cat) => cat !== category);
      } else {
        return [...prevVisibleCategories, category];
      }
    });
  };

  const onIngredientAdd = (ing) => {
    setStockTakeInfo((prevStockTake) => {
      let newIngList = _.concat([], prevStockTake.ingredients || []);
      const item = { ...ing, normalQty: normalizeIngredientQuantity({ ...ing, amount: ing.amount }) };
      item.worth = calcStocktakeIngredientCost(item, true);
      newIngList.push(item);
  
      const form = { ...prevStockTake, ingredients: newIngList };
      updateForm(form);
      return form;
    });
  };  

  const onIngredientRemove = (ing) => {
    setStockTakeInfo((prevStockTake) => {
      let newIngList = _.concat([], prevStockTake.ingredients || []);
      const ingIndex = _.findIndex(newIngList, { 'id': ing.id, 'recordUOM': ing.recordUOM });
      if (ingIndex >= 0) {
        newIngList.splice(ingIndex, 1);
        const form = { ...prevStockTake, ingredients: newIngList };
        updateForm(form);
        return form;
      }
      return prevStockTake;
    });
  };  

  const filteredIngs = filterItems(ingredients, filters);
  const sortedCategories = _.sortBy(categoryOptions, (cat) => !visibleCategories.includes(cat.value));

  return (
    _.map(sortedCategories, (categoryOpt) => {
      const isCategoryOpen = (category === categoryOpt.value) || (filters.search && visibleCategories.includes(categoryOpt.value));
      return (
        <StocktakeIngredientsCategory
          key={categoryOpt.value}
          categoryOpt={categoryOpt}
          filters={filters}
          addItem={onIngredientAdd}
          removeItem={onIngredientRemove}
          isOpen={isCategoryOpen}
          setCategory={setCategory}
          ingredients={filteredIngs}
          stockTakeInfo={rest.stockTakeInfo}
          toggleVisibility={() => toggleVisibility(categoryOpt.value)}
          isVisible={visibleCategories.includes(categoryOpt.value)}
          {...rest} 
        />
      )
    })
  )
}

export default StocktakeIngredients;
