import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addTransfer, removeTransfer, updateTransfer } from '../firebase/transfers';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* transferSaga() {
  yield takeEvery(actionTypes.transfers.ADD_TRANSFER, function* (action) {
    try {
      const { transfer } = action;
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addTransfer, transfer, accountId);
      yield put(actions.appMessageSuccess('Transfer Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Transfer could not be added'));
    }
  });
  yield takeEvery(actionTypes.transfers.REMOVE_TRANSFER, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeTransfer, action.transferId, accountId);
      yield put(actions.appMessageSuccess('Transfer Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Transfer could not be removed'));
    }
  });
  yield takeEvery(actionTypes.transfers.UPDATE_TRANSFER, function* (action) {
    const { id, ...transfer } = action.transfer;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateTransfer, id, transfer, accountId);
      yield put(actions.appMessageSuccess('Transfer Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Transfer could not be updated'));
    }
  });
};
