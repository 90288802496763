import _ from 'lodash';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addOrder, removeOrder, toggleOrderFinal, updateOrder } from '../firebase/orders';
import { addOrderAttachment, removeOrderAttachment } from '../firebase/orderAttachments';
import { actions, actionTypes } from '../actions';
import { orderStatuses } from '../../utils/constants';
import { activeAccount } from '../../utils/selectors';

export default function* ordersSaga() {
  yield takeEvery(actionTypes.orders.ADD_ORDER, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const { id:orderId } = yield call(addOrder, action.order, accountId);
      if (action.order.status === orderStatuses.DRAFT) {
        yield put(actions.appMessageSuccess('Order Drafted'));
      }
      else {
        yield put(actions.appMessageSuccess('Order Placed'));
      }
      if (action.callback) {
        yield call(action.callback, orderId, action.order);
      }
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Order could not be added'));
    }
  });
  yield takeEvery(actionTypes.orders.REMOVE_ORDER, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeOrder, action.orderId, accountId);
      yield put(actions.appMessageSuccess('Order Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Order could not be removed'));
    }
  });
  yield takeEvery(actionTypes.orders.UPDATE_ORDER, function* (action) {
    const { id, ...order } = action.order;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateOrder, id, order, accountId);
      yield put(actions.appMessageSuccess('Order Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Order could not be updated'));
    }
  });
  yield takeEvery(actionTypes.orderAttachments.UPDATE_ORDER_ATTACHMENTS, function* (action) {
    const { orderId, attachments } = action;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const existing = yield select((state) => _.map(_.filter(state.orderAttachments, { orderId }), (attachment) => attachment.id));
      // First, sort out any attachments that need removed
      if (!_.isEmpty(existing)) {
        const pendingDelete = _.difference(existing, _.map(_.filter(attachments, 'id'), (attachment) => attachment.id));
        for ( let x = 0, ln = pendingDelete.length; x < ln; x++ ) {
          yield call(removeOrderAttachment, pendingDelete[x], accountId);
        }
      }
      // Next, add any new attachments
      const pendingAdd = _.filter(attachments, 'file');
      for ( let x = 0, ln = pendingAdd.length; x < ln; x++ ) {
        yield call(addOrderAttachment, {
          orderId,
          ...pendingAdd[x]
        }, accountId, accountId);
      }
      yield put(actions.appMessageSuccess('Order attachments updated'));

      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Order attachments could not be updated'));
    }
  });
  yield takeEvery(actionTypes.orders.TOGGLE_ORDER_FINAL, function* (action) {
    const { id, ...order } = action.order;
    try {
      yield call(toggleOrderFinal, id, order, action.accountId);
      if (action.callback) {
        yield call(action.callback);
      }
      yield put(actions.appMessageSuccess('Order status updated'));
    }
    catch (err) {
      console.log(err);
      yield put(actions.appMessageError('Order status could not be updated'));
    }
  });
};
