const MISE_EN_PLACES_LOAD_SUCCESS = 'MISE_EN_PLACES_LOAD_SUCCESS';
const MISE_EN_PLACES_LOAD_FAILURE = 'MISE_EN_PLACES_LOAD_FAILURE';
const MISE_EN_PLACES_CLEAR = 'MISE_EN_PLACES_CLEAR';
const ADD_MISE_EN_PLACES = 'ADD_MISE_EN_PLACES';
const REMOVE_MISE_EN_PLACES = 'REMOVE_MISE_EN_PLACES';
const UPDATE_MISE_EN_PLACES = 'UPDATE_MISE_EN_PLACES';

export const actionTypes = {
  MISE_EN_PLACES_LOAD_SUCCESS,
  MISE_EN_PLACES_LOAD_FAILURE,
  MISE_EN_PLACES_CLEAR,
  ADD_MISE_EN_PLACES,
  REMOVE_MISE_EN_PLACES,
  UPDATE_MISE_EN_PLACES
};


const miseEnPlacesLoadSuccess = (payload, account) => {
  return {
    type: MISE_EN_PLACES_LOAD_SUCCESS,
    payload,
    account
  };
};

const miseEnPlacesLoadFailure = (error) => {
  return {
    type: MISE_EN_PLACES_LOAD_FAILURE,
    error
  };
};

const miseEnPlacesClear = () => {
  return {
    type: MISE_EN_PLACES_CLEAR
  };
};

const addMiseEnPlaces = (miseEnPlace, callback) => {
  return {
    type: ADD_MISE_EN_PLACES,
    miseEnPlace,
    callback
  };
};

const removeMiseEnPlaces = (miseEnPlacesId) => {
  return {
    type: REMOVE_MISE_EN_PLACES,
    miseEnPlacesId
  };
};

const updateMiseEnPlaces = (miseEnPlace, callback) => {
  return {
    type: UPDATE_MISE_EN_PLACES,
    miseEnPlace,
    callback
  };
};

export const actions = {
  miseEnPlacesLoadSuccess,
  miseEnPlacesLoadFailure,
  miseEnPlacesClear,
  addMiseEnPlaces,
  removeMiseEnPlaces,
  updateMiseEnPlaces
};
