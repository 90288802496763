//React
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//Libraries
import _ from 'lodash';
import { Pane, TextInputField, majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button'
import Block from '../../components/ui/Block/Block'

//Files
import { actions } from '../../store/actions';


const Profile = (props) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const [profileInfo, setProfileInfo] = useState(profile || {});
  
  useEffect(() => {
    if (_.isEmpty(profileInfo) && profile) {
      setProfileInfo(profile);
    }
  }, [profileInfo, profile]);

  const changeField = (field, newValue) => {
    setProfileInfo((prevValues) => {
      return { ...prevValues, [field]: newValue};
    });
  };
  const doProfileSave = () => {
    dispatch(actions.profile.profileUpdate(profileInfo));
  };

  return (
    <Page title="Profile" containerProps={{ maxWidth: 600 }}>
      <Block marginBottom={majorScale(2)} padding={majorScale(3)}>
      <TextInputField
          required
          label="Name"
          value={profileInfo.name}
          onChange={(e) => changeField('name', e.target.value)}
          placeholder="Name"
      />
      <TextInputField
          disabled
          label="E-mail"
          value={profileInfo.email}
          placeholder="E-mail"
      />
      <TextInputField
          disabled
          label="Login type"
          value={profileInfo.provider}
          placeholder="Login type"
      />
      <Pane display="flex" justifyContent="center">
        <Button appearance="primary" onClick={doProfileSave}>Save</Button>
      </Pane>
      </Block>
    </Page>
  );
};

export default Profile;
