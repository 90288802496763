const MODIFIER_CATEGORIES_LOAD_SUCCESS = 'MODIFIER_CATEGORIES_LOAD_SUCCESS';
const MODIFIER_CATEGORIES_LOAD_FAILURE = 'MODIFIER_CATEGORIES_LOAD_FAILURE';
const MODIFIER_CATEGORIES_CLEAR = 'MODIFIER_CATEGORIES_CLEAR';
const ADD_MODIFIER_CATEGORY = 'ADD_MODIFIER_CATEGORY';
const REMOVE_MODIFIER_CATEGORY = 'REMOVE_MODIFIER_CATEGORY';
const UPDATE_MODIFIER_CATEGORY = 'UPDATE_MODIFIER_CATEGORY';

export const actionTypes = {
  MODIFIER_CATEGORIES_LOAD_SUCCESS,
  MODIFIER_CATEGORIES_LOAD_FAILURE,
  MODIFIER_CATEGORIES_CLEAR,
  ADD_MODIFIER_CATEGORY,
  REMOVE_MODIFIER_CATEGORY,
  UPDATE_MODIFIER_CATEGORY
};


const modifierCategoriesLoadSuccess = (payload, account) => {
  return {
    type: MODIFIER_CATEGORIES_LOAD_SUCCESS,
    payload,
    account
  };
};

const modifierCategoriesLoadFailure = (error) => {
  return {
    type: MODIFIER_CATEGORIES_LOAD_FAILURE,
    error
  };
};

const modifierCategoriesClear = () => {
  return {
    type: MODIFIER_CATEGORIES_CLEAR
  };
};

const addCategory = (accountId, categoryInfo, callback) => {
  return {
    type: ADD_MODIFIER_CATEGORY,
    categoryInfo,
    callback,
    accountId,
  };
};

const removeCategory = (accountId, categoryId) => {
  return {
    type: REMOVE_MODIFIER_CATEGORY,
    accountId,
    categoryId,
  };
};

const updateCategory = (accountId, categoryInfo, callback) => {
  return {
    type: UPDATE_MODIFIER_CATEGORY,
    categoryInfo,
    accountId,
    callback,
  };
};

export const actions = {
  modifierCategoriesLoadSuccess,
  modifierCategoriesLoadFailure,
  modifierCategoriesClear,
  addCategory,
  removeCategory,
  updateCategory
};
