//React
import React from 'react';

//Librries
import PropTypes from 'prop-types';
import { majorScale, Text, Table } from 'evergreen-ui';

//Components
import IconWrapper from '../../../components/Icons/Icons'

//Files
import { colors, TABLE_MAX_WIDTH } from '../../../utils/constants';
import { calcPercentage } from '../../../utils/functions';
import { currency } from '../../../utils/format';

const SalesTable = ({
  weekSalesTotal,
  salesVariance,
  reportIncidentals,
  setEditingValue,
  saleType
}) =>
  <Table maxWidth={TABLE_MAX_WIDTH} width='100%' marginBottom={majorScale(2)}>
    <Table.Body>
      <Table.Row borderBottom="none">
        <Table.TextCell borderBottom="muted">{saleType} Sales</Table.TextCell>
        <Table.TextCell textProps={{ color: colors.orange, size: 500 }}>£ {currency(weekSalesTotal)}</Table.TextCell>
        <Table.TextCell textProps={{ color: colors.lightTeal }}>100 %</Table.TextCell>
      </Table.Row>
      <Table.Row borderBottom="none">
        <Table.TextCell borderBottom="muted">POS {saleType} Sales</Table.TextCell>
        <Table.Cell>
          <Text
              flex={1}
              color={colors.lightTeal}
          >£ {currency(reportIncidentals[`salesPOS${saleType}`] || 0)}</Text>
          <IconWrapper
              name="edit"
              appearance='clickable'
              marginLeft={majorScale(2)}
              onClick={() => setEditingValue({
                label: `POS ${saleType} Sales`,
                incidental: `salesPOS${saleType}`,
                value: reportIncidentals[`salesPOS${saleType}`],
              })}
          />
        </Table.Cell>
        <Table.TextCell textProps={{ color: colors.lightTeal }}>{calcPercentage(reportIncidentals[`salesPOS${saleType}`] || 0, weekSalesTotal)} %</Table.TextCell>
      </Table.Row>
      <Table.Row borderBottom="none">
        <Table.TextCell borderBottom="muted">Gross Complimentary</Table.TextCell>
        <Table.Cell>
          <Text
              flex={1}
              color={colors.lightTeal}
          >£ {currency(reportIncidentals[`salesComp${saleType}`] || 0)}</Text>
          <IconWrapper
              name="edit"
              appearance='clickable'
              marginLeft={majorScale(2)}
              onClick={() => setEditingValue({
                label: `${saleType} Complimentary`,
                incidental: `salesComp${saleType}`,
                value: reportIncidentals[`salesComp${saleType}`],
              })}
          />
        </Table.Cell>
        <Table.TextCell textProps={{ color: colors.lightTeal }}>{calcPercentage(reportIncidentals[`salesComp${saleType}`] || 0, weekSalesTotal)} %</Table.TextCell>
      </Table.Row>
      <Table.Row borderBottom="none">
        <Table.TextCell borderBottom="muted">Variance</Table.TextCell>
        <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(salesVariance)}</Table.TextCell>
        <Table.TextCell textProps={{ color: colors.lightTeal }}>{calcPercentage(salesVariance, weekSalesTotal)} %</Table.TextCell>
      </Table.Row>
    </Table.Body>
  </Table>

SalesTable.propTypes = {
  weekSalesTotal: PropTypes.number.isRequired,
  salesVariance: PropTypes.number.isRequired,
  reportIncidentals: PropTypes.object.isRequired,
  setEditingValue: PropTypes.func.isRequired,
  saleType: PropTypes.string.isRequired
}

export default SalesTable;
