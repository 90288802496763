//React
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import IconWrapper from '../../components/Icons/Icons';
import CustomHeading from '../../components/Headings/Headings';

//Files
import { calcStocktakeCost, getSalesForPeriod, getOrdersForPeriod, getStocktakeDate, calcPercentage, 
    periodTransferCosts, getProcurementTotal } from '../../utils/functions';
import { shortDateDisplayFormat, dateFormat } from '../../utils/format';
import { orderStatuses } from '../../utils/constants';
import { current, selectReportIncidentals } from '../../utils/selectors';


const calcGP = ({ openingStocktake, spending, closingStocktake, netSales }) => {
  return _.round(100 - calcPercentage((openingStocktake + spending - closingStocktake), netSales, 1), 1);
};


const ReportPeriods = () => {
  const { accountId } = useParams();
  // Grab all approved stock takes, reverse sort by date, calculate the total 
  //  cost of each, and then key by date
  const stocktakes = useSelector((state) => 
    _.keyBy(
      _.map(
        _.reverse(
          _.sortBy(
            _.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }),
            (stocktake) => getStocktakeDate(stocktake)
          )
        ),
        (stocktake) => ({ ...stocktake, totalCost: calcStocktakeCost(stocktake) })
      ),
      (stocktake) => (dateFormat(getStocktakeDate(stocktake)))
    )
  );
  const stocktakeDates = _.keys(stocktakes);
  // Pair all the stocktake dates (open -> close), then filter out any empty items
  const periods = _.filter(_.map(stocktakeDates, (latestDate, index, arr) => {
    if (index === arr.length - 1) {
      return false;
    }
    return { start: arr[index + 1], end: dateFormat(moment.utc(latestDate).add(-1, 'day').toDate()) };
  }));
  //console.log(periods, "PERIODDSSSS")
  // Grab all orders received between each stock take, calculate the total 
  //  cost, then key by the opening stock take date
  const orderTotals = useSelector((state) => {
    return _.keyBy(
      _.map(periods, (period) => {
        const start =  moment(period.start);
        const end = moment(period.end);
        const reportIncidentals = selectReportIncidentals({ state, accountId, weekStart: start.toDate() });
        const weekTransfers = periodTransferCosts(accountId, start, end);
        const weekOrders = getOrdersForPeriod(accountId, start.toDate(), end.toDate(), orderStatuses.RECEIVED);
        const procurementTotal = getProcurementTotal(weekOrders, weekTransfers, reportIncidentals)
        return { ...period, totalCost: procurementTotal };
      }),
      'start'
    );
  });
  const sales = useSelector((state) => {
    return _.keyBy(
      _.map(periods, (period) => {
        const periodSales = getSalesForPeriod(accountId, moment.utc(period.start), moment.utc(period.end));
        return { ...period, sales: _.reduce(periodSales, (info, salesInfo) => {
          return {
            totalcost: info.totalcost + salesInfo.totalcost,
            totalnet: info.totalnet + salesInfo.totalnet,
            totalgross: info.totalgross + salesInfo.totalgross
          };
        }, { totalcost: 0, totalnet: 0, totalgross: 0 }) };
      }),
      'start'
    );
  });

  return (
    <Page>
    <Block marginBottom={majorScale(2)}>
      <CustomHeading level="3" paddingX={majorScale(2)} marginY={majorScale(2)}>Report Periods</CustomHeading>
      <Table>
        <Table.Head height={48}>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>To</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actual GP%</Table.TextHeaderCell>
          <Table.TextHeaderCell>Theoretical GP%</Table.TextHeaderCell>
          <Table.TextHeaderCell>View</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(periods) && (
            <Table.Row height={48}>
              <Table.TextCell>No reports to display</Table.TextCell>
            </Table.Row>
          )}
          {!_.isEmpty(periods) && _.map(periods, (period) => {
            const gp = calcGP({
              openingStocktake: stocktakes[period.start].totalCost,
              spending: orderTotals[period.start].totalCost,
              closingStocktake: stocktakes[dateFormat(moment.utc(period.end).add(1, 'day').toDate())] ?
                stocktakes[dateFormat(moment.utc(period.end).add(1, 'day').toDate())].totalCost :
                null,
              netSales: sales[period.start].sales.totalnet
            });
            const expectedGP = calcPercentage((sales[period.start].sales.totalnet - sales[period.start].sales.totalcost), sales[period.start].sales.totalnet, 1);
            return (
              <Table.Row height={48} key={period.start} isSelectable>
                <Table.TextCell>{shortDateDisplayFormat(period.start)}</Table.TextCell>
                <Table.TextCell>{shortDateDisplayFormat(period.end)}</Table.TextCell>
                <Table.TextCell>{gp} %</Table.TextCell>
                <Table.TextCell>{expectedGP} %</Table.TextCell>
                <Table.Cell>
                  <IconWrapper
                      name="clipboard"
                      appearance='clickable'
                      is={Link}
                      to={`/${accountId}/reports/gross-profit/${period.start}/${period.end}`}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Block>
    </Page>
  );
};

export default ReportPeriods;
