import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addOrderRecord, removeOrderRecord, updateOrderRecord } from '../firebase/orderRecords';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* orderRecordsSaga() {
  yield takeEvery(actionTypes.orderRecords.ADD_ORDER_RECORD, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addOrderRecord, action.record, accountId);
      yield put(actions.appMessageSuccess('Order Record Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Order Record could not be added'));
    }
  });
  yield takeEvery(actionTypes.orderRecords.REMOVE_ORDER_RECORD, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeOrderRecord, action.recordId, accountId);
      yield put(actions.appMessageSuccess('Order Record Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Order Record could not be removed'));
    }
  });
  yield takeEvery(actionTypes.orderRecords.UPDATE_ORDER_RECORD, function* (action) {
    const { id, ...record } = action.record;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateOrderRecord, id, record, accountId);
      yield put(actions.appMessageSuccess('Order Record Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Order Record could not be updated'));
    }
  });
};
