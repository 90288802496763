const TASKS_LOAD_SUCCESS = 'TASKS_LOAD_SUCCESS';
const TASKS_CLEAR = 'TASKS_CLEAR';
const ADD_TASK = 'ADD_TASK';
const REMOVE_TASK = 'REMOVE_TASK';
const UPDATE_TASK = 'UPDATE_TASK';
const COMPLETE_TASK = 'COMPLETE_TASK';

export const actionTypes = {
  TASKS_LOAD_SUCCESS,
  TASKS_CLEAR,
  ADD_TASK,
  REMOVE_TASK,
  UPDATE_TASK,
  COMPLETE_TASK,
};


const tasksLoadSuccess = (payload, account) => {
  return {
    type: TASKS_LOAD_SUCCESS,
    payload,
    account
  };
};

const tasksClear = () => {
  return {
    type: TASKS_CLEAR
  };
};

const addTask = (accountId, task) => {
  return {
    type: ADD_TASK,
    task,
    accountId,
  };
};

const removeTask = (accountId, taskId) => {
  return {
    type: REMOVE_TASK,
    taskId,
    accountId,
  };
};

const updateTask = (accountId, task) => {
  return {
    type: UPDATE_TASK,
    task,
    accountId,
  };
};

const completeTask = (accountId, { taskId, date }) => {
  return {
    type: COMPLETE_TASK,
    taskId,
    date,
    accountId,
  };
};

export const actions = {
  tasksLoadSuccess,
  tasksClear,
  addTask,
  removeTask,
  updateTask,
  completeTask,
};
