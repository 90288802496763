import { getCollectionRef } from './common'

// exported methods
/*
export const loadRecipeCategories = (accountId) => {
  if (recipeCategoriesListener) { recipeCategoriesListener() }
  recipeCategoriesRef = accountsRef.doc(accountId)
    .collection('recipeCategories')
  console.log(`loading: accounts/${accountId}/recipeCategories`)
  recipeCategoriesListener = recipeCategoriesRef
    .onSnapshot((querySnapshot) => {
      const results = []
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() })
      })
      store.dispatch(actions.recipeCategories.recipeCategoriesLoadSuccess(results));
    })
  
  // Ensure the "Mise en Place" category exists - create it if it doesn't!
  let query = recipeCategoriesRef.where('name', '==', 'Mise en Place')
  query.get().then((querySnapshot) => {
    if (querySnapshot.empty) {
      // No category 'Mise en Place' found
      recipeCategoriesRef.add({ name: 'Mise en Place' })
    }
  })
}*/

export const addRecipeCategory = (recipeCategory, accountId) => {
  const recipeCategoriesRef = getCollectionRef('recipeCategories', accountId);
  return recipeCategoriesRef.add(recipeCategory);
};

export const removeRecipeCategory = (recipeCategoryId, accountId) => {
  const recipeCategoriesRef = getCollectionRef('recipeCategories', accountId);
  return recipeCategoriesRef.doc(recipeCategoryId).delete();
};

export const updateRecipeCategory = (recipeCategoryId, recipeCategory, accountId) => {
  const recipeCategoriesRef = getCollectionRef('recipeCategories', accountId);
  return recipeCategoriesRef.doc(recipeCategoryId).update(recipeCategory);
};
