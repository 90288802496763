//React
import React from 'react';
import { useLocation, Link, Routes, Route, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Tab from '../../../components/ui/Tab/Tab';
import TabNavigation from '../../../components/ui/TabNavigation/TabNavigation';
import FoodRecording from './FoodRecording';
import FridgeTab from './FridgeTab';
import ProbeTab from './ProbeTab';
import DeliveryTab from './DeliveryTab';


const tabs = {
  food: 'Food',
  fridge: 'Fridge & freezer',
  delivery: 'Delivery',
  probe: 'Probe'
};


const TempLists = ({ date, DatePanel }) => {
  const location = useLocation();
  const { accountId } = useParams();
  const currentTab = location.pathname.split('/')[4] || 'food';

  return (
    <>
      <TabNavigation paddingBottom={majorScale(2)}>
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/${accountId}/tasks/temp/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>

      <Routes>
        <Route path="/food" element={<FoodRecording accountId={accountId} date={date} />} />
        <Route path="/fridge" element={<FridgeTab accountId={accountId} date={date} />} />
        <Route path="/delivery" element={<DeliveryTab accountId={accountId} date={date} />} />
        <Route path="/probe" element={<ProbeTab accountId={accountId} date={date} />} />
        <Route path="/*" element={<FoodRecording accountId={accountId} date={date} />} /> 
      </Routes>
    </>
  );
};

TempLists.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  DatePanel: PropTypes.node.isRequired
};

export default TempLists;
