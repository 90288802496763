//React
import React from 'react';

//Libraries
import { Pane, FormField, FilePicker, majorScale } from 'evergreen-ui';

//Components
import Button from '../../components/ui/Button/Button'
import Block from '../../components/ui/Block/Block'
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';
import DataTable from '../../components/DataTable/DataTable';


const SalesImportManually = ({ setSelectedFile, extractReport, doImport, parsedData, setParsedData }) => {

    const handleFileChange = (files) => {
      if (files.length > 0) {
        setSelectedFile(files[0]);
        extractReport(files[0]);
      } else {
        setSelectedFile(null);
      }
    };
  
    const clearImportData = () => {
      setParsedData(false);
    };

  const matchedItemsHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 4 },
    { label: 'PLU', field: 'plu', type: 'text', width: 4 },
    { label: 'Qty', field: 'qty', type: 'text', width: 4 },
  ];

  const unmatchedItemsHeaders = [
    { label: 'PLU', field: 'plu', type: 'text', width: 4 },
    { label: 'Qty', field: 'qty', type: 'text', width: 4 },
  ];

  const renderMatchedItemsRow = (row) => ({
    ...row,
  });

  const renderUnmatchedItemsRow = (row) => ({
    ...row,
  });

  return (
    <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
      <Pane display="flex" alignItems="center" justifyContent="space-between" marginBottom={majorScale(1)}>
        <CustomHeading level="3">Upload a file manually</CustomHeading>
        <Button
          appearance="primary"
          iconBefore="download"
          is="a"
          href="/csv/Misenplace-product-sales-upload-template.xlsx"
          download="Misenplace-product-sales-upload-template.xlsx"
        >
          Download Excel template
        </Button>
      </Pane>
      <Pane>
        <FormField maxWidth="200px">
          <FilePicker
            multiple={false}
            accept="*.xls,*.xlsx"
            onChange={handleFileChange}
          />
        </FormField>
      </Pane>

      <CustomDialog
        isOpen={!!parsedData}
        title="Confirm Import"
        onClose={() => clearImportData()}
        onConfirm={() => {
          doImport(parsedData, 'user');
          clearImportData();
        }}
      >
        <Pane display="flex" flexDirection="column" marginBottom={majorScale(2)}>
            <CustomHeading level="4" marginBottom={majorScale(2)}>Matched Items</CustomHeading>
            <Pane flex="1" overflowY="auto" display="flex" flexDirection="column" height={220}>
                <DataTable
                    borderRadius={5}
                    items={parsedData ? parsedData.items.map(renderMatchedItemsRow) : []}
                    headers={matchedItemsHeaders}
                    listHeight={200}
                />
            </Pane>
                
            <CustomHeading level="4" marginBottom={majorScale(2)}>Unmatched Items</CustomHeading>
            <Pane flex="1" overflowY="auto" display="flex" flexDirection="column" height={220}>
                <DataTable
                    borderRadius={5}
                    items={parsedData ? parsedData.unmatched.map(renderUnmatchedItemsRow) : []}
                    headers={unmatchedItemsHeaders}
                    listHeight={200}
                />
            </Pane>
        </Pane>
      </CustomDialog>
    </Block>
  );
};

export default SalesImportManually;