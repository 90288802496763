//React
import React from 'react';

//Libraries
import { Text, Pane, majorScale } from 'evergreen-ui';

//Components
import CustomDialog from '../../../components/Dialog/Dialog';

const RemovingTask = ({
  removingTask,
  setRemovingTask,
  removeConfirmed
}) => {

  return (
    <CustomDialog
        isOpen={!!removingTask}
        //intent="danger"
        title="Remove Task"
        confirmLabel="Remove"
        onClose={() => setRemovingTask(false)}
        onConfirm={removeConfirmed}
    >
      <Pane marginBottom={majorScale(2)}>
        {removingTask && <Text>Are you sure you wish to remove the task "{removingTask.action}"?</Text>}
      </Pane>
    </CustomDialog>
  )
}

export default RemovingTask;
