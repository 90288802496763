import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addTask, removeTask, updateTask } from '../firebase/tasks';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* taskSaga() {
  yield takeEvery(actionTypes.tasks.ADD_TASK, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addTask, action.task, accountId);
      // yield put(actions.appMessageSuccess('Task Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task could not be added'));
    }
  });
  yield takeEvery(actionTypes.tasks.REMOVE_TASK, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeTask, action.taskId, accountId);
      yield put(actions.appMessageSuccess('Task Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task could not be removed'));
    }
  });
  yield takeEvery(actionTypes.tasks.UPDATE_TASK, function* (action) {
    const { id, ...task } = action.task;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateTask, id, task, accountId);
      // yield put(actions.appMessageSuccess('Task Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task could not be updated'));
    }
  });
};
