//React
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

//Libraries
import _ from 'lodash';
import { majorScale, Pane, TextInputField, Table, Paragraph } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import IconWrapper from '../../components/Icons/Icons';
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';

//Files
import { actions } from '../../store/actions';
import { selectIngredientCategoryHierarchy } from '../../utils/selectors';


const IngredientCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryHierarchy = useSelector(selectIngredientCategoryHierarchy);
  const [editingCategory, setEditingCategory] = useState(false);
  const [removingCategory, setRemovingCategory] = useState(false);

  /* TODO: Determine category hierarchy?
  const setCategoryParent = (newParent) => {
    setEditingCategory((prevValues) => {
      if (newParent) {
        return ({ ...prevValues, parent: newParent });
      }
      else {
        const { parent, ...remainingValues } = prevValues;
        return remainingValues;
      }
    });
  };
  */

  const saveCategory = (cat, callback) => {
    if (cat.id) {
      dispatch(actions.ingredientCategories.updateCategory(cat, () => callback()));
    }
    else {
      dispatch(actions.ingredientCategories.addCategory(cat, () => callback()));
    }
  };

  const removeCategory = (cat) => {
    // TODO: Handle child categories!
    dispatch(actions.ingredientCategories.removeCategory(cat.id));
  };


  return (
    <>
      <Page title="Ingredient Categories">
        <Block width="600px" marginBottom={majorScale(2)}>
          <Pane
              padding={majorScale(2)}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
          >
            <Pane display="flex">
              <IconWrapper
                name="arrowLeft"
                appearance="clickable"
                onClick={() => navigate(-1)}
                marginRight={majorScale(2)}
              />
              <CustomHeading level="3" flex="1 0 auto">Ingredient Categories</CustomHeading>
            </Pane>
            <Button
                marginLeft={majorScale(2)}
                appearance="primary"
                onClick={() => setEditingCategory({})}
            >Add Category</Button>
          </Pane>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Category</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 0 56px">Edit</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 0 56px">Delete</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {_.isEmpty(categoryHierarchy) && (
                <Table.Row>
                  <Table.TextCell>No items to display.</Table.TextCell>
                </Table.Row>
              )}
              {_.map(categoryHierarchy, (cat) => (
                <Table.Row key={cat.id}>
                  <Table.TextCell
                      paddingLeft={(12 + majorScale(4 * (cat.level || 0)))}
                  >{cat.name}</Table.TextCell>
                  <Table.Cell flex="0 0 56px">
                    <IconWrapper
                        name="edit"
                        appearance="default"
                        onClick={() => setEditingCategory(cat)}
                    />
                  </Table.Cell>
                  <Table.Cell flex="0 0 56px">
                    <IconWrapper
                        name="trash"
                        appearance="danger"
                        onClick={() => setRemovingCategory(cat)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Block>
      </Page>

      <CustomDialog
          isOpen={!!editingCategory}
          title={(editingCategory && editingCategory.id) ? 'Edit Category' : 'New Category'}
          confirmLabel={(editingCategory && editingCategory.id) ? 'Update' : 'Add'}
          onConfirm={() => {
            saveCategory(editingCategory);
            setEditingCategory(false);
          }}
          onClose={() => setEditingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="Name"
              placeholder="Category Name"
              value={editingCategory.name || ''}
              onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
          />
        </Pane>
        {/* TODO: Determine category hierarchy?
        <SelectField
            label="Nest Under ..."
            value={editingCategory.parent || ''}
            onChange={(e) => setCategoryParent(e.target.value)}
        >
          <option value=""></option>
          {_.map(_.reject(categoryHierarchy, { 'id': editingCategory.id }), (cat) => (
            <option
                key={cat.id}
                value={cat.id}
            >{_.repeat('--', cat.level || 0)} {cat.name}</option>
          ))}
        </SelectField>
        */}
      </CustomDialog>

      <CustomDialog
          isOpen={!!removingCategory}
          title="Remove Ingredient Category"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={() => {
            removeCategory(removingCategory);
            setRemovingCategory(false);
          }}
          onClose={() => setRemovingCategory(false)}
      >
        <Pane marginBottom={majorScale(2)}>
          <Paragraph>Are you sure you wish to remove the category {removingCategory.name}?</Paragraph>
          {/*<Paragraph color="danger">This will remove any sub-categories as well!</Paragraph>*/}
        </Pane>
      </CustomDialog>
    </>
  );
};

export default IngredientCategories;
