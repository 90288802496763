import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addMessage } from '../firebase/messages';
import fb from '../firebase';

import { actions, actionTypes } from '../../store/actions';
import { activeAccount } from '../../utils/selectors';

export default function* messagesSaga() {
  yield takeEvery(actionTypes.messages.ADD_MESSAGE, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addMessage, { ...action.message, created: fb.serverTime() }, accountId);
      if (action.callback) action.callback();
    }
    catch (err) {
      yield put(actions.appMessageError('Message could not be sent'));
    }
  });
};
