//React
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import { majorScale, minorScale, Pane, SelectField, Text, useTheme } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import ReportFilters from './components/ReportFilters';
import Block from '../../components/ui/Block/Block';
import DataTable from '../../components/DataTable/DataTable'

//Files
import { colors } from '../../utils/constants';
import { aggregateFinanacialPeriods, aggregateFinancialPeriodsAreasData, buildFinancialPeriodsAccountsData, 
  buildPeriodsArray, filterPeriodsToNow, findFinancePeriod } from '../../utils/financials';
import { financialCurrency } from '../../utils/format';
import { calcPercentage } from '../../utils/functions';
import { current } from '../../utils/selectors';


const buildPeriodDateLabel = (period) => {
  if (!period) return 'Invalid period';
  return `${moment.utc(period.start, 'YYYY-MM-DD').format('DD/MM/YY')} to ${moment.utc(period.end, 'YYYY-MM-DD').format('DD/MM/YY')}`;
}

const buildWeekDateLabel = (week) => {
  if (!week) return 'Invalid week';
  return `W${week.num + 1}: ${week.weekStart.format('DD/MM')} to ${week.weekEnd.format('DD/MM')}`;
}

const buildYearPeriodLabel = (periodStart, periodEnd) => {
  if (!periodStart || !periodEnd) return 'Invalid period';
  return `${moment.utc(periodStart.start, 'YYYY-MM-DD').format('DD/MM/YY')} to ${moment.utc(periodEnd.end, 'YYYY-MM-DD').format('DD/MM/YY')}`;
}

const findPeriodNumFromPeriodKey = (currentPeriod) => {
  if (!currentPeriod) return -1;
  return parseInt(currentPeriod.split('_')[1]);
}


const Financials = ({ accounts, filterProps }) => {
  const theme = useTheme();
  const year = moment.utc().year();
  const [currentYear, setCurrentYear] = useState(year);
  const financials = useSelector((state) => _.sortBy(state.financials[accounts.accountId], 'year'));
  const financial = financials.find(f => parseInt(f.year) === parseInt(currentYear));
  //console.log(financial)
  const [currentPeriod, setCurrentPeriod] = useState(findFinancePeriod(financial)?.periodKey);
  const [periodsData, setPeriodsData] = useState(null);

  const newAccounts = useMemo(() => ({ ...accounts, filterProps }), [accounts, filterProps]);

  const changeYear = useCallback((year) => {
    setCurrentPeriod('period_0');
    setCurrentYear(year);
  }, []);

  const areaCats = useSelector(state => _.keyBy(current(state, 'areas'), 'id'));

  useEffect(() => {
    const buildFinancialsData = () => {
      const data = buildFinancialPeriodsAccountsData({financial, accounts: newAccounts, currentPeriod, currentYear});
      setPeriodsData(data);
    }
    if (financial) buildFinancialsData();
  }, [financial, newAccounts, currentPeriod, currentYear]);

  const periods = financial ? buildPeriodsArray(financial) : null;
  //console.log(periods, 'finanacialPeriod')
  const aggregated = aggregateFinancialPeriodsAreasData(periodsData);
  const periodNum = currentPeriod ? findPeriodNumFromPeriodKey(currentPeriod) : -1;
  const totals = aggregateFinanacialPeriods(aggregated.periods, periodNum);
  //console.log(totals, 'TOTALSS')
  const aggregatedPeriodData = aggregated?.periods[periodNum >= 0 ? periodNum : aggregated.periods.length - 1];
  //console.log(aggregatedPeriodData, "AggPDate")

  const summaryTableHeaders = [
    { label: 'Summary', field: 'label', type: 'text', width: 3 },
    { label: 'Actual', field: 'actual', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'actualPercent', type: 'text', suffix:'%', width: 2 },
    { label: 'Th. Var.', field: 'thVar', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'thVarPercent', type: 'text', suffix:'%', width: 2 },
    { label: 'Bud. Var.', field: 'budgetVar', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'budgetVarPercent', type: 'text', suffix:'%', width: 2 },
  ];

  const areaTableHeaders = [
    { label: 'Net Revenues', field: 'areaName', type: 'text', width: 3 },
    { label: 'Actual', field: 'actual', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'actualPercent', type: 'text', suffix:'%', width: 2 },
    { label: 'Budget', field: 'budget', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'budgetPercent', type: 'text', suffix:'%', width: 2 },
    { label: 'Bud. Var.', field: 'budgetVar', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'budgetVarPercent', type: 'text', suffix:'%', width: 2 },
  ];
  

  return (
    <>
      <Block marginBottom={majorScale(2)} display='flex' alignItems='center' justifyContent='start'>
        <Pane padding={majorScale(2)}>
          <SelectField
              label='Year'
              maxWidth={130}
              placeholder="Select year"
              value={currentYear}
              onChange={(e) => changeYear(e.target.value)}
              //padding={majorScale(2)}
          >
            {financials.reverse().map((obj) => (<option key={obj.year} value={obj.year}>{obj.year}</option>))}
          </SelectField>
        </Pane>
        {financial && (
          <Pane padding={majorScale(2)}>
            <SelectField
                label='Period'
                maxWidth={250}
                placeholder="Select period"
                value={currentPeriod}
                onChange={(e) => setCurrentPeriod(e.target.value)}
                //padding={majorScale(2)}
            >
              {filterPeriodsToNow(periods, financial).map((period, index) => (
                <option key={`period_${index}`} value={`period_${index}`}>
                  Period {index + 1} - {buildPeriodDateLabel(period)}
                </option>)
              )}
            </SelectField>
          </Pane>
        )}
      </Block>

      {financial && (
        <>
          <ReportFilters accounts={newAccounts} />

            <Block width="100%" marginBottom={majorScale(2)}>
              <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                  Period to date: {buildPeriodDateLabel(financial[currentPeriod])}
              </Text>
              <DataTable
                headers={summaryTableHeaders}
                items={[
                  {
                    label: 'Net Revenues',
                    actual: aggregatedPeriodData?.netSales,
                    actualPercent: '100',
                    thVar: null,
                    thVarPercent: null,
                    budgetVar: aggregatedPeriodData?.budgetVariance,
                    budgetVarPercent: aggregatedPeriodData?.budgetVariancePercent,
                  },
                  {
                    label: 'Cost of Sales',
                    actual: aggregatedPeriodData?.totalCostOfSales,
                    actualPercent: aggregatedPeriodData?.totalCostOfSalesPercent,
                    thVar: aggregatedPeriodData?.thCostOfSalesVar,
                    thVarPercent: aggregatedPeriodData?.thCostOfSalesVarPercent,
                    budgetVar: aggregatedPeriodData?.periodBudgetMarginVar,
                    budgetVarPercent: aggregatedPeriodData?.periodBudgetMarginVarPercent,
                  },
                  {
                    label: 'Gross Profit',
                    actual: aggregatedPeriodData?.grossProfits,
                    actualPercent: aggregatedPeriodData?.grossProfitsPercent,
                    thVar: aggregatedPeriodData?.grossProfitsThVar,
                    thVarPercent: aggregatedPeriodData?.grossProfitsThVarPercent,
                    budgetVar: aggregatedPeriodData?.grossProfitsBudgetVar,
                    budgetVarPercent: aggregatedPeriodData?.grossProfitsBudgetVarPercent,
                  },
                ]}
                listHeight={300}
              />
            </Block>

            <Block width="100%" marginBottom={majorScale(2)}>
              <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                  Year to date: {buildYearPeriodLabel(periods[0], periods[periodNum])}
              </Text>
              <DataTable
                headers={summaryTableHeaders}
                items={[
                  {
                    label: 'Net Revenues',
                    actual: totals?.netSales,
                    actualPercent: '100',
                    thVar: null,
                    thVarPercent: null,
                    budgetVar: totals?.budgetVariance,
                    budgetVarPercent: totals?.budgetVariancePercent,
                  },
                  {
                    label: 'Cost of Sales',
                    actual: totals?.totalCostOfSales,
                    actualPercent: totals?.totalCostOfSalesPercent,
                    thVar: totals?.thCostOfSalesVar,
                    thVarPercent: totals?.thCostOfSalesVarPercent,
                    budgetVar: totals?.periodBudgetMarginVar,
                    budgetVarPercent: totals?.periodBudgetMarginVarPercent,
                  },
                  {
                    label: 'Gross Profit',
                    actual: totals?.grossProfits,
                    actualPercent: totals?.grossProfitsPercent,
                    thVar: totals?.grossProfitsThVar,
                    thVarPercent: totals?.grossProfitsThVarPercent,
                    budgetVar: totals?.grossProfitsBudgetVar,
                    budgetVarPercent: totals?.grossProfitsBudgetVarPercent,
                  },
                ]}
                listHeight={300}
              />
            </Block>
          
          <Block width={'100%'} overflowX='scroll' marginBottom={majorScale(2)}>
            <Pane display='flex' alignItems='center'>
              {aggregatedPeriodData?.weeks.map((week, index) => {
                return (
                  <Pane key={`week_${index}`} minWidth={750} marginRight={minorScale(2)}>
                    <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                      {buildWeekDateLabel(week.week.week)}
                    </Text>
                    <DataTable
                      headers={areaTableHeaders}
                      items={_.map(aggregated.areaCats, (area, areaId) => {
                        const areaPeriod = week && week.weeks && week.weeks[index];
                        console.log(areaPeriod)
                        if (!areaPeriod) return {};
                        const areaBudgetSales = areaPeriod.budget && areaPeriod.netSales ? (100*(1 - (areaPeriod.budget / areaPeriod.netSales))) : 0;
                        console.log(areaBudgetSales)
                        return {
                          areaName: areaCats[areaId]?.name,
                          actual: areaPeriod.netSales,
                          actualPercent: calcPercentage(areaPeriod.netSales, week.netSales, 2),
                          budget: areaPeriod.budget,
                          budgetPercent: calcPercentage(areaPeriod.budget, week.budget, 2),
                          budgetVar: areaPeriod.netSales - areaPeriod.budget,
                          budgetVarPercent: areaBudgetSales ? areaBudgetSales.toFixed(2): '',
                        }
                      })}
                      listHeight={340}
                    />
                  </Pane>
                )
              })}
            </Pane>
          </Block>

          <Block marginBottom={majorScale(2)}>
            <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                Period to date: {buildPeriodDateLabel(periods?.[findPeriodNumFromPeriodKey(currentPeriod)])}
            </Text>
            <DataTable
              headers={areaTableHeaders}
              items={_.map(aggregated.areaCats, (area, areaId) => {
                const areaPeriod = aggregated.periods[periodNum];
                if (!areaPeriod) return {};
                const areaBudgetSales = areaPeriod.budget && areaPeriod.netSales ? (100*(1 - (areaPeriod.budget / areaPeriod.netSales))) : 0;
                return {
                  areaName: areaCats[areaId]?.name,
                  actual: areaPeriod.netSales,
                  actualPercent: calcPercentage(areaPeriod.netSales, aggregatedPeriodData.netSales, 2),
                  budget: areaPeriod.budget,
                  budgetPercent: calcPercentage(areaPeriod.budget, aggregatedPeriodData.budget, 2),
                  budgetVar: areaPeriod.netSales - areaPeriod.budget,
                  budgetVarPercent: areaBudgetSales ? areaBudgetSales.toFixed(2): '',
                }
              })}
              listHeight={340}
            />
          </Block>
        </>
      )}

      {!financial && (
        <Block>
          <Pane padding={majorScale(3)}>
            <Text style={{fontSize:"16px"}} >Please set your financials to use this feature.</Text>
          </Pane>
        </Block>
      )}
    </>
  );
}

export default Financials;
