import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addModifierCategory, updateModifierCategory, removeModifierCategory } from '../firebase/modifierCategories';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* modifierCategoriesSaga() {
  yield takeEvery(actionTypes.modifierCategories.ADD_MODIFIER_CATEGORY, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addModifierCategory, action.categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Modifier Category Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Modifier Category could not be added'));
    }
  });
  yield takeEvery(actionTypes.modifierCategories.REMOVE_MODIFIER_CATEGORY, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeModifierCategory, action.categoryId, accountId);
      yield put(actions.appMessageSuccess('Modifier Category Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Modifier Category could not be removed'));
    }
  });
  yield takeEvery(actionTypes.modifierCategories.UPDATE_MODIFIER_CATEGORY, function* (action) {
    const { id, ...categoryInfo } = action.categoryInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateModifierCategory, id, categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Modifier Category Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Modifier Category could not be updated'));
    }
  });
};
