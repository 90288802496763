import _ from 'lodash';
import moment from 'moment';
import store from 'store/redux';
import { orderStatuses } from './constants';
import {
  buildRecipeCategory,
  calcOrdersTotal,
  calcStocktakeCost,
  calcStocktakeIngredientCost,
  calcTransfersLiveBalance,
  castFloat,
  denormalizeIngredientQuantity,
  findSubRecipeAmount,
  getIngredientStockUOM,
  getOrdersForPeriod,
  getSalesForPeriod,
  getStocktakeDate,
  getStocktakeMoment,
  getTransfersForPeriod,
  getWastageDate,
  ingOrderAmount,
  ingredientUnitAmount,
  ingSaleAmount,
  ingWastageAmount,
  periodTransferCosts,
  recipeIngredientsUseAmount
} from './functions';
import { current, selectPeriodReportIncidentals, selectReportIncidentals } from './selectors';

const dateFormat = "YYYY-MM-DD";

const buildPeriodData = (accountId, initialStocktake, previousStocktake = null) => {
  const periodStart = previousStocktake ?
    getStocktakeMoment(previousStocktake).startOf('day') :
    getStocktakeMoment(initialStocktake).startOf('day'); 
    //console.log(periodStart.format(), "PERIODSTARTTTT")

  const state = store.getState();

  const periodEnd = previousStocktake ? 
    getStocktakeMoment(initialStocktake).subtract(1, 'days').endOf('day') : 
    moment().utc().endOf('day');
    //console.log(periodEnd.format(), "PERIODENDDD")

  const periodOrders = getOrdersForPeriod(accountId, periodStart, periodEnd, orderStatuses.RECEIVED);
  //console.log(periodOrders, 'PERIODORDERS')
  const periodSales = getSalesForPeriod(accountId, periodStart, periodEnd);
  //console.log(periodSales, "PERIODSALES")

  // refactor
  const periodWastage = _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      //console.log(wastageDate, "WASTAGEDATE")
      if (!wastageDate) {
        // Ignore any wastage records without a date
        return false;
      }
      return (moment.utc(wastageDate).isBetween(periodStart, periodEnd, 'day', '[]'));
    });

  const recipeCategories = current(state, 'recipeCategories', accountId);
  const recipeList = _.sortBy(current(state, 'recipes', accountId), 'name').map(r => buildRecipeCategory(r, recipeCategories));

  const periodTransfers = getTransfersForPeriod(accountId, periodStart, periodEnd);
  return {
    periodOrders,
    periodSales,
    periodWastage,
    recipeList,
    periodTransfers
  }
}

const buildPeriodStockIngredients = (stock) => {
  if (!stock) return [];

  let ingredients = stock.ingredients || [];
  if (stock.recipes && !_.isEmpty(stock.recipes)) {
    const recipeIngs = stock.recipes.reduce((array, recipe) => {
      if (!recipe || !recipe.ingredients || typeof recipeIngredientsUseAmount !== 'function') {
        return array;
      }
      const recipeIngredients = recipeIngredientsUseAmount(recipe, recipe.normalQty) || [];
      return array.concat(_.flatten(recipeIngredients));
    }, []);
    ingredients = ingredients.concat(recipeIngs);
  }
  return ingredients;
};

const buildAllIngredients = (stock, ingredients) => {
  const sIngredients = buildPeriodStockIngredients(stock);
  return ingredients.concat(sIngredients.filter(sI => ingredients.findIndex(i => i.id === sI.id) > -1));
}

const buildPeriodSales = ({periodSales, recipeList}) => {
  const livestockPeriodSales = _.flatten(periodSales.map((sale) => {
    const recipe = _.find(recipeList, { id: sale.recipeId });
    const recipeIngredients = recipe && recipe.ingredients ? recipeIngredientsUseAmount(recipe, sale.qty) : [];
    return [..._.flatten(recipeIngredients)];
  })).sort((a,b) => a.id > b.id);
  //console.log('Livestock Period Sales:', livestockPeriodSales);
  return _.compact(livestockPeriodSales);
}

const wastageIng = (w, ing) => ({ ...ing, amount: w.amount, recordUOM: w.recordUOM })

const buildPeriodWastage = ({ periodWastage }) => {
  const livestockPeriodWastage = _.flatten(periodWastage.map((wastage) => {
    const recipeIngredients = wastage.recipe ? recipeIngredientsUseAmount(wastage.recipe, wastage.amount) : [];
    // ing amount for the recipe x wastage amount
    const recipeIngredient = recipeIngredients.map(recipeIngredient => recipeIngredient);
    const ingredients = wastage.ingredients ? _.map(wastage.ingredients, (ing) => wastageIng(wastage, ing)) : [];
    const ingredient = wastage.ingredient ? [wastageIng(wastage, wastage.ingredient)] : [];
    return [...recipeIngredient, ...ingredients, ...ingredient];
  })).sort((a,b) => a.id > b.id);
  return _.compact(livestockPeriodWastage);
}

const addTransferType = (ings, transferType) => ings.map(i => ({ ...i, transferType }));

const transferIng = (t, ing) => ({ ...ing, amount: t.normalQty, recordUOM: ing.recipeunit })

const buildPeriodTransfers = (periodTransfers) => {
  const livestockPeriodTransfers = _.flatten(periodTransfers.map((transfer) => {
    const { transferType } = transfer;
    const recipeIngredients = transfer.recipe ? recipeIngredientsUseAmount(transfer.recipe, transfer.normalQty) : [];
    // ing amount for the recipe x wastage amount
    const ingredient = transfer.ingredient ? [transferIng(transfer, transfer.ingredient)] : [];
    return [...addTransferType(recipeIngredients, transferType), ...addTransferType(ingredient, transferType)];
  }));
  return _.compact(livestockPeriodTransfers);
}

const ingredientStock = (ingredient, ingredients, startRecordUOM) => {
  const ingredientsStartStock = ingredients.filter(ing => ing.id === ingredient.id);
  const unitStockAmount = ingredientsStartStock.reduce((amount, ing) => {
    return amount + ingredientUnitAmount(ing)
  }, 0);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const unitStockDisplay = !_.isEmpty(ingredientsStartStock) ? denormalizeIngredientQuantity({ ...denormIng, amount: unitStockAmount, recordUOM: startRecordUOM }).toFixed(2) : 0;
  return {
    unitStockAmount,
    unitStockDisplay: unitStockDisplay || '0.00'
  }
}

const buildLivestockIngredientStock = (ingredient, periodStartStock) => {
  const startRecordUOM = getIngredientStockUOM(ingredient);
  const data = ingredientStock(ingredient, periodStartStock, startRecordUOM);
  return {...ingredient, ...data, startRecordUOM};
}

const buildLivestockIngredientOrders = (ingredient, livestockPeriodOrders) => {
  const ingredientOrders = livestockPeriodOrders.filter(order => order.ingredientId === ingredient.id);
  let amount = 0;
  if (!_.isEmpty(ingredientOrders)) {
    amount = ingredientOrders.reduce((num, item) => item.type && item.type === 'CREDIT' ?
      num - (ingOrderAmount(item)) :
      num + (ingOrderAmount(item)), 0);
  }

  const orderQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const orderQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);
  return {...ingredient, orderQuantity, orderQuantityDisplay};
}

const buildLivestockIngredientSales = (ingredient, livestockPeriodSales) => {
  let amount = 0;
  const ingredientSales = livestockPeriodSales.filter(sale => sale.id === ingredient.id);
  if (!_.isEmpty(ingredientSales)) {
    amount = ingredientSales.reduce((num, item) => num + ingSaleAmount(item), 0);
  }
  const saleQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const saleQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);
  return {
    ...ingredient,
    saleQuantity,
    saleQuantityDisplay: saleQuantityDisplay || '0.00'
  };
}

const buildLivestockIngredientWastage = (ingredient, livestockPeriodWastage) => {
  const ingredientWastage = livestockPeriodWastage.filter(wastage => wastage.id === ingredient.id);
  let amount = 0;
  if (!_.isEmpty(ingredientWastage)) {
    amount = ingredientWastage.reduce((num, item) => num + ingWastageAmount(item), 0);
  }
  const wastageQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const wastageQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);
  return {
    ...ingredient,
    wastageQuantity,
    ingredientWastage,
    wastageQuantityDisplay: wastageQuantityDisplay || '0.00'
  };
}

const buildLivestockIngredientTransfer = (ingredient, livestockPeriodTransfer) => {
  const ingredientTransfer = livestockPeriodTransfer.filter(t => t.id === ingredient.id);
  let amount = 0;
  if (!_.isEmpty(ingredientTransfer)) {
    amount = calcTransfersLiveBalance(ingredientTransfer);
  }
  const transferQuantity = parseFloat(amount);
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const transferQuantityDisplay = denormalizeIngredientQuantity({ ...denormIng, amount, recordUOM: ingredient.startRecordUOM }).toFixed(2);
  return {
    ...ingredient,
    transferQuantity,
    ingredientTransfer,
    transferQuantityDisplay: transferQuantityDisplay || '0.00'
  };
}

const calculateIngredientLivestock = (ingredient) => {
  const livestock =
    ingredient.unitStockAmount + ingredient.orderQuantity - ingredient.saleQuantity - ingredient.wastageQuantity + ingredient.transferQuantity;
  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  const livestockDisplay = denormalizeIngredientQuantity({ ...denormIng, amount: livestock, recordUOM: ingredient.startRecordUOM }).toFixed(2);
  return {
    ...ingredient,
    livestock,
    livestockDisplay: livestockDisplay || '0.00'
  };
}

const calculateIngredientVariance = (ingredient, initialStock) => {
  const data = ingredientStock(ingredient, initialStock, ingredient.startRecordUOM);
  const variance = data.unitStockAmount - ingredient.livestock;

  const denormIng = ingredient.ingredient ? ingredient.ingredient : ingredient;
  //console.log('Denormalized Ingredient:', denormIng);

  const varianceDisplay = denormalizeIngredientQuantity({
    ...denormIng,
    amount: variance,
    recordUOM: ingredient.startRecordUOM
  }).toFixed(2);
  //console.log('Variance Display:', varianceDisplay);

  const varianceWorth = calcStocktakeIngredientCost({
    ...ingredient,
    amount: parseFloat(varianceDisplay),
    recordUOM: ingredient.startRecordUOM,
    recipeunit: ingredient.ingredient ? ingredient.ingredient.recipeunit : ingredient.recipeunit
  });
  //console.log('Variance Worth:', varianceWorth);

  return {
    ...ingredient,
    varianceWorth,
    variance,
    varianceDisplay,
    thisStockAmount: data.unitStockAmount,
    thisStockDisplay: data.unitStockDisplay || '0.00',
  };
};

const finalOrderItems = ({items, type}) => items ? items.map(item => ({ ...item, type })) : [];
const finalTransferItems = ({records, transferType}) => records ? records.map(item => ({ ...item, transferType })) : [];

export const calculateTotalWastageCost = (accountId, initialStocktake, previousStocktake = null) => {
  const data = buildPeriodData(accountId, initialStocktake, previousStocktake);
  console.log(data)
  const totalWastageCost = _.sumBy(data.periodSales, (sale) => sale.totalWastageCost || 0);
  console.log(totalWastageCost)
  return totalWastageCost;
};

export const buildLivestock = (accountId, initialStocktake, archive = false, previousStocktake = null) => {
  const data = buildPeriodData(accountId, initialStocktake, previousStocktake);
  console.log(data, "buildPErdioData")
  let livestock = [];
  const periodStartStock = archive ? previousStocktake ? buildPeriodStockIngredients(previousStocktake) : [] : buildPeriodStockIngredients(initialStocktake);
  //console.log(periodStartStock, 'PERIODSTART')
  const ingredientsList = archive ? buildAllIngredients(initialStocktake, current(store.getState(), 'ingredients', accountId)) : current(store.getState(), 'ingredients', accountId);
  const livestockIngredientsList = _.uniqBy(ingredientsList, 'id');
  const livestockPeriodOrders = _.compact(_.flatten(data.periodOrders.map(finalOrderItems)));
  const livestockPeriodSales = buildPeriodSales(data);
  console.log(livestockPeriodSales, 'SALESSS')
  const livestockPeriodWastage = buildPeriodWastage(data);
  //console.log(livestockPeriodWastage, "PerdioWastage")
  const livestockPeriodTransfers = buildPeriodTransfers(_.flatten(data.periodTransfers.map(finalTransferItems)));

  // Calculate the total wastage cost from periodSales
  const totalWastageCost = _.sumBy(data.periodSales, (sale) => sale.totalWastageCost || 0);
  console.log("Total Wastage Cost:", totalWastageCost);

  livestock = livestockIngredientsList.map(ingredient =>
    buildLivestockIngredientStock(ingredient, periodStartStock)
  ).map(ingredient =>
    buildLivestockIngredientOrders(ingredient, livestockPeriodOrders)
  ).map(ingredient => 
    buildLivestockIngredientSales(ingredient, livestockPeriodSales)
  ).map(ingredient =>
    buildLivestockIngredientWastage(ingredient, livestockPeriodWastage)
  ).map(ingredient =>
    buildLivestockIngredientTransfer(ingredient, livestockPeriodTransfers)
  ).map(ingredient =>
    calculateIngredientLivestock(ingredient, (archive ? periodStartStock : null))  
  );
  if (archive) {
    livestock = livestock.map(ingredient => calculateIngredientVariance(ingredient, ingredientsList))
  }
  return livestock;
}

export const buildRecipeLive = (accountId, initialStocktake, previousStocktake = null) => {
  const data = buildPeriodData(accountId, initialStocktake, previousStocktake);
  const initRecipes = initialStocktake && initialStocktake.recipes ? initialStocktake.recipes : [];
  //console.log(`Processing ${initRecipes.length} initial recipes.`);

  let livestock = [];
  livestock = initRecipes.map((recipe, index) => {
    //console.log(`Processing recipe ${index + 1}/${initRecipes.length}:`, recipe.name);
    const startRecordUOM = getIngredientStockUOM({ recipeunit: recipe.yieldDescription });
    const salesAmountNew = data.periodSales.reduce((total, sale) => {
      const amount = findSubRecipeAmount(recipe, sale.recipeId, sale.qty);
      return total + (amount || 0)
    }, 0);
    //console.log(`Calculated sales amount for recipe ${recipe.name}: ${salesAmountNew}`);

    const saleDisplay = denormalizeIngredientQuantity({ ...recipe, amount: salesAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      saleAmount: salesAmountNew || '0',
      saleDisplay,
      startRecordUOM
    }
  }).map(recipe => {
    const wastageAmountNew = data.periodWastage.reduce((total, waste) => {
      const amount = waste.recipe ? findSubRecipeAmount(recipe, waste.recipe.id, waste.normalQty) : 0;
      return total + (amount || 0)
    }, 0);
    const wasteDisplay = denormalizeIngredientQuantity({ ...recipe, amount: wastageAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      wastageAmount: wastageAmountNew || '0',
      wasteDisplay
    }
  }).map(recipe => {
    const transferAmountNew = data.periodTransfers.reduce((total, transfer) => {
      const amount = transfer.recipe ? findSubRecipeAmount(recipe, transfer.recipe.id, transfer.normalQty) : 0;
      if (transfer.transferType === 'IN') return total + (amount || 0);

      return total - (amount || 0)
    }, 0);
    const transferDisplay = denormalizeIngredientQuantity({ ...recipe, amount: transferAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      trasnferAmount: transferAmountNew || '0',
      transferDisplay
    }
  }).map(recipe => {
    const previousAmountNew = previousStocktake && previousStocktake.recipes ?
      previousStocktake.recipes.reduce((total, item) => {
        const amount = findSubRecipeAmount(recipe, item.id, item.normalQty);
        return total + (amount || 0)
      }, 0) : 0;
    const prevDisplay = denormalizeIngredientQuantity({ ...recipe, amount: previousAmountNew, recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      previousAmount: previousAmountNew || '0',
      prevDisplay
    }
  }).map(recipe => {
    const closingDisplay = denormalizeIngredientQuantity({ ...recipe, amount: parseFloat(recipe.normalQty), recipeunit: recipe.yieldDescription }).toFixed(2);
    return {
      ...recipe,
      closing: recipe.normalQty || '0',
      closingDisplay
    }
  });
  //console.log('buildRecipeLive completed.');
  return livestock;
}

const findMostRecentStocktake = (state, accountId, weekEnd) => (
  _.last(
    _.sortBy(
      _.filter(current(state, 'stockTakes', accountId), (stocktake) => (!stocktake.isDraft && moment.utc(getStocktakeDate(stocktake)).isSameOrBefore(weekEnd, 'date'))),
      (stocktake) => getStocktakeDate(stocktake)
    )
  )
);

export const findNextStocktake = (state, accountId, weekEnd) => (
  _.first(
    _.sortBy(
      _.filter(current(state, 'stockTakes', accountId), (stocktake) => (!stocktake.isDraft && moment.utc(getStocktakeDate(stocktake)).isAfter(weekEnd, 'date'))),
      (stocktake) => getStocktakeDate(stocktake)
    )
  )
);

export const calculcateWeekWastageCost = (state, accountId, weekStart, weekEnd) => (
  _.sumBy(
    _.filter(current(state, 'wastages', accountId), (wastage) => {
      const wastageDate = getWastageDate(wastage);
      if (!wastageDate) {
        // Ignore any wastage records without a date
        return false;
      }
      return (moment.utc(wastageDate).isBetween(weekStart, weekEnd, 'day', '[]'));
    }),
    (wastage) => ((isNaN(wastage.cost)) ? 0 : wastage.cost)
  )
)

const buildPeriodReportIncidentals = (state, accountId, periodStart, weekEnd) => {
  const periodIncidentals = selectPeriodReportIncidentals({ state, accountId, periodStart, weekEnd });
  return periodIncidentals.reduce((obj, item) => {
    for (const key in obj) {
      if (item[key]) obj[key] += parseFloat(item[key]); 
    }
    return obj;
  }, {procurementTransferIn: 0, procurementTransferOut: 0, procurementPettyCash: 0, stockAllowances: 0})
}

export const buildExpectedStockValue = ({accountId, weekStart, weekEnd}) => {
  const state = store.getState();
  const mostRecentStocktake = findMostRecentStocktake(state, accountId, weekEnd)
  const periodStart = (mostRecentStocktake) ? getStocktakeDate(mostRecentStocktake) : weekStart;
  const openingStocktakeCost = (mostRecentStocktake) ? calcStocktakeCost(mostRecentStocktake) : 0;
  const periodOrdersCost = calcOrdersTotal(getOrdersForPeriod(accountId, periodStart, weekEnd));
  const periodTransfers = periodTransferCosts(accountId, periodStart, weekEnd);
  const perdiodIncidentalCosts = buildPeriodReportIncidentals(state, accountId, periodStart, weekEnd);
  const periodWastageCost = calculcateWeekWastageCost(state, accountId, periodStart, weekEnd);
  const periodSales = getSalesForPeriod(accountId, periodStart, weekEnd);
  const periodSalesCost = _.sumBy(periodSales, 'totalcost');
  //console.log('Period Sales Cost:', periodSalesCost);
  const expectedStockValue = openingStocktakeCost + (
    periodOrdersCost
    + castFloat(periodTransfers.transfersIn)
    - castFloat(periodTransfers.transfersOut)
    + castFloat(perdiodIncidentalCosts.procurementPettyCash)
  ) - periodWastageCost - periodSalesCost - castFloat(perdiodIncidentalCosts.stockAllowances);
  //console.log('Expected Stock Value:', expectedStockValue);
  return {
    expectedStockValue,
    periodTransfers,
    openingStocktakeCost,
    periodStart
  }
}

export const calculcateWeekTheoreticalStock = (weekStart, weekEnd, accountId) => {
  const state = store.getState();
  const { periodStart, expectedStockValue, openingStocktakeCost, periodTransfers } =
    buildExpectedStockValue({accountId, weekStart, weekEnd});
  if (moment.utc(periodStart).isAfter(weekStart,'day')) weekStart = periodStart;
  const weekSales = getSalesForPeriod(accountId, weekStart, weekEnd);
  const weekProcurement = getOrdersForPeriod(accountId, weekStart, weekEnd);
  const weekWastageCost = calculcateWeekWastageCost(state, accountId, weekStart, weekEnd);
  const reportIncidentals = selectReportIncidentals({ state, accountId, weekStart });
  const weekTransfers = periodTransferCosts(accountId, weekStart, weekEnd);
  const weekSalesCost = _.sumBy(weekSales, 'totalcost');
  //console.log('Week Sales Cost:', weekSalesCost);
  const weekSalesNet = _.sumBy(weekSales, 'totalnet');
  const weekOrdersCost = calcOrdersTotal(weekProcurement);
  const procurementTotal = (
    weekOrdersCost
    + castFloat(weekTransfers.transfersIn)
    - castFloat(weekTransfers.transfersOut)
    + castFloat(reportIncidentals.procurementPettyCash)
  );

  const openingStockValue = 
    expectedStockValue -
    procurementTotal +
    weekSalesCost +
    weekWastageCost +
    castFloat(reportIncidentals.stockAllowances);
  return {
    expectedStockValue,
    openingStocktakeCost,
    procurementTotal,
    weekSalesCost,
    weekSalesNet,
    openingStockValue,
    weekWastageCost,
    periodStart,
    stockType: (moment.utc(periodStart).isSame(moment.utc(weekStart), 'day')) ? 'Open Stocktake' : 'Th. Stocktake',
    weekTransfers,
    periodTransfers
  };
}
