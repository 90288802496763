import store from '../store/redux';
import { current } from './selectors';
import { filterActiveRecipes } from './recipes';

// Retrieves departments associated with a specific account from Redux state.
export const buildGroupAccountDepartments = (account) => {
  const state = store.getState();
  return state.departments[account.id];
}

// Aggregates department IDs from a group structure, supporting the organization of departments into broader categories or groupings.
const buildAllowedDepartments = (groups) => {
  let departments = [];
  for (const groupKey in groups) {
    if (groups[groupKey] && groups[groupKey].length) {
      departments =  departments.concat(groups[groupKey].map(d => d.id));
    }
  }
  return departments;
}

// Constructs a list of department IDs for a given account, considering the account's specific departmental associations.
const buildDepartmentsList = (accountId) => {
  const state = store.getState();
  const account = state.accounts[accountId];
  if (!account) {
    // Handle the undefined account here, possibly by returning an empty array or logging an error
    console.error(`Account with ID ${accountId} not found.`);
    return [];
  }
  const groupDepartments = buildAllowedDepartments(account.group_departments || {});
  const departments = current(state, 'departments', accountId);
  return groupDepartments.filter(d => departments.findIndex(dep => d === dep.id) > -1);
}

// Determines if a recipe is associated with any department within the specified or derived list, 
//aiding in contextual filtering based on departmental affiliations.
export const recipeBelongsToArea = (accountId, recipe, departments = null) => {
  if (!departments) {
    departments = buildDepartmentsList(accountId);
  }
  const belongsToAll = recipe.belongs_to_all;
  // Check if any department ID in the recipe matches any ID in the allowed departments
  const departmentMatch = recipe.departments?.some(rD => departments.includes(rD.id));
  return belongsToAll ? true : departmentMatch;
}

// Filters and returns recipes associated with an account, 
//considering departmental affiliations to tailor content to specific account contexts.
export const buildGroupRecipes = (accountId) => {
  const state = store.getState();
  const recipes = current(state, 'recipes', accountId);
  const allowedDepartments = buildDepartmentsList(accountId);
  // Filter out archived and deleted recipes using the utility function
  const activeRecipes = filterActiveRecipes(recipes);
  const filteredRecipes = activeRecipes.filter(r => recipeBelongsToArea(accountId, r, allowedDepartments));
  return filteredRecipes;
}
