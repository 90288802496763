//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Heading, Dialog, Text, SelectMenu, Table, TextInputField, Checkbox, SelectField } from 'evergreen-ui';


//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { current } from '../../../utils/selectors';
import { filterItems, generateSearchFieldsFn } from '../../../utils/functions';
import { actions } from '../../../store/actions';


const findHighestOptionPercent = (option) => {
  let percent;
  for(const key in option.option_matches) {
    const val = option.option_matches[key];
    if (!percent || val > percent) percent = val;
  }
  return percent ? (percent * 100).toFixed(0) + '%' : '';
}


const UnapprovedEdit = ({ supplierId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const supplierOptions = useSelector((state) => {
    return (
      _.sortBy(
        _.filter(
          current(state, 'supplierOptions'),
          { 'supplierId': supplierId }
        ),
        'name'
      )
    );
  }).filter(s => s.un_verified);
  const categoryOptions = useSelector((state) => {
    return (
      _.sortBy(
        current(state, 'supplierCategories'),
        'name'
      )
    );
  });
  const supplier = useSelector((state) => state.suppliers[state.currentAccount] && state.suppliers[state.currentAccount].find(s => s.id === supplierId));
  // const isSlave = supplier?.type?.includes('SLAVE');
  const [options, setOptions] = useState(supplierOptions);
  const [addOptions, setAddOptions] = useState([]);
  const [catFilters, setCatFilters] = useState([]);
  const [catOptions, setCatOptions] = useState(categoryOptions);
  const categoryMap = _.keyBy(catOptions, 'id');
  const supplierCategories = useSelector(state => state.supplierCategories[state.currentAccount])

  const toggleAddOptions = (id) => {
    const index = addOptions.indexOf(id);
    if (index > -1) setAddOptions(_.without(addOptions, id));
    else setAddOptions([...addOptions, id]);
  }

  const [removingOption, setRemovingOption] = useState(false);
  const [filters, setFilters] = useState({});
  // const sortedOptions = isSlave ?
  //   _.sortBy(options, (opt) => supplierOptions.findIndex(o => o.parent === opt.id) === -1) :
  //   options;

  const removeIngredientOption = (optionId) => {
    dispatch(actions.supplierOptions.removeSupplierOption(optionId));
  };
  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      setFilters((prevFilters) => {
        const { search, ...newFilters } = prevFilters;
        return newFilters;
      });
    }
    else {
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          search: generateSearchFieldsFn(['name'], newSearchValue),
        };
      });
    }
  };

  const categoryFilterFn = (catFilters) => (item) => {
    if (!_.isEmpty(catFilters)) {
      const itemCategories = [];
      let catId = item.categoryId;
      itemCategories.push(catId);
      while (categoryMap[catId] && categoryMap[catId].parent) {
        catId = categoryMap[catId].parent;
        itemCategories.push(catId);
      }

      for (let x = 0, ln = catFilters.length; x < ln; x++) {
        // Match any category selected
        if (_.includes(itemCategories, catFilters[x])) {
          return true;
        }
      }
      // No categories matched
      return false;
    }
    return true;
  };
  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };
  const addCategoryToFilter = (catId) => {
    let newList = [];
    setCatFilters((prev) => {
      newList = [...prev, catId];
      return newList;
    });
    updateFilters('category', categoryFilterFn(newList));
  };
  const removeCategoryFromFilter = (catId) => {
    let newList = [];
    setCatFilters((prev) => {
      newList = _.without(prev, catId);
      return newList;
    });
    updateFilters('category', categoryFilterFn(newList));
  };

  const isValidOption = (optionInfo) => {
    const keys = ['gramperportion', 'numperportion', 'uom', 'name', 'supplier_code'];
    const oKeys = Object.keys(optionInfo)
    if (keys.findIndex(key => oKeys.indexOf(key) === -1) > -1) return false

    return true
  }

  const saveAddOptions = () => {
    if (addOptions.length === 0) {
      dispatch(actions.appMessageError('Please add at least one item to save.'));
      return
    }

    const notValidIndex = addOptions.findIndex(o => {
      const optIndex = options.findIndex(opt => opt.id === o);
      if (optIndex > -1) {
        return isValidOption(options[optIndex]) ? false : true;
      }

      return false;
    });

    if (notValidIndex > -1) {
      dispatch(actions.appMessageError('Items are not valid.'));
      return 
    }

    for (const id of addOptions) {
      const option = options.find(opt => opt.id === id);
      dispatch(actions.supplierOptions.updateSupplierOption({...option, un_verified: false, ingredientId: null}, null))
    }
    setOptions([...supplierOptions]);
    setAddOptions([]);
  }

  useEffect(() => {
    if (options.length !== supplierOptions.length) {
      setOptions([...supplierOptions]);
    }

    if (supplierOptions.length === 0) {
      navigate(`/suppliers/${supplier?.id}`)
    }
  }, [supplierOptions, options, supplier]);

  const height = 338;
  return (
    <>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading flex="1 0 auto" level='3'>Ingredients</CustomHeading>
          <Button
            appearance='primary'
            marginLeft={majorScale(2)}
            onClick={saveAddOptions}
          >Save</Button>
        </Pane>

        <Pane display='flex' alignItems='center'>
          <SelectMenu
              hasFilter={false}
              hasTitle={false}
              isMultiSelect={true}
              selected={catFilters}
              options={_.map(catOptions, (option) => ({ label: _.repeat('--', option.level) + option.name, value: option.id }))}
              onSelect={(item) => addCategoryToFilter(item.value)}
              onDeselect={(item) => removeCategoryFromFilter(item.value)}
              marginRight={majorScale(2)}
            >
              <Button
                  marginRight={majorScale(2)}
                  appearance='flat'
                  iconBefore="filter"
              >{(!_.isEmpty(catFilters)) ? _.join(_.map(catFilters, (catId) => categoryMap[catId].name), ', ') : 'Categories'}</Button>
            </SelectMenu>
          <FilterBar
              filters={filters}
              searchPlaceholder="Search for an Ingredient"
              searchOnChange={searchOnChange}
              flex="1 0 auto"
          />
        </Pane>
      </Block>

      <Block
          flex={`1 0 ${TABLE_MIN_HEIGHT}px`}
          display="flex"
          flexDirection="column"
      >
        <Pane>
          <Table display="flex" flexDirection="column" flex="1 0 auto">
            <Table.Head>
              <Table.TextHeaderCell>
                Name
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Product Code
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Unit price
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Supplier UOM
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Nb_Unit_Lot
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Qty_Uom
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                UOM
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Category
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                VAT
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Add
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                Pct.
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>
                View
              </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body height={height} maxHeight={height} overflowY='scroll'>
              {filterItems(options, filters).map(option => {
                // const userOption = supplierOptions.find(o => o.parent === option.id);
                // const isAdded = !!userOption;
                const optIndex = options.findIndex(o => o.id === option.id);
                const isChecked = addOptions.indexOf(option.id) > -1;
                const onFieldChange = (field, value) => {
                  const opt = { ...options[optIndex], [field]: value };
                  options[optIndex] = opt;
                  setOptions([...options]);
                }
                return (
                  <Table.Row key={option.id}>
                    <Table.TextCell>{option.name}</Table.TextCell>
                    <Table.TextCell>{option.supplier_code}</Table.TextCell>
                    <Table.TextCell>{option.unitprice}</Table.TextCell>
                    <Table.TextCell>{option.uom}</Table.TextCell>
                    <Table.Cell>
                      <TextInputField
                          label=''
                          value={option.numperportion || ''}
                          onChange={(e) => onFieldChange('numperportion', e.target.value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextInputField
                          label=''
                          value={option.gramperportion || ''}
                          onChange={(e) => onFieldChange('gramperportion', e.target.value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <TextInputField
                          label=''
                          value={option.base_uom || ''}
                          onChange={(e) => onFieldChange('base_uom', e.target.value)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <SelectField
                        label=''
                        value={option.categoryId || ''}
                        onChange={(e) => onFieldChange('categoryId', e.target.value)}
                        // flexBasis={`calc(50% - ${majorScale(8)}px)`}
                        // marginRight={majorScale(8)}
                      >
                        <option value="">Please Select...</option>
                        {supplierCategories.map(cat =>
                          <option key={cat.id} value={cat.id}>{cat.name}</option>
                        )}
                      </SelectField>
                    </Table.Cell>
                    <Table.TextCell>{option.vat ? '20%' : '0%'}</Table.TextCell>
                    
                    <Table.Cell>
                      <Checkbox
                          checked={isChecked}
                          onChange={(e) => toggleAddOptions(option.id)}
                          flexBasis="15%"
                      />
                    </Table.Cell>
                    <Table.TextCell>{option.option_matches ? findHighestOptionPercent(option) : ''}</Table.TextCell>
                    <Table.Cell>
                      <IconWrapper
                          name='edit'
                          appearance='clickable'
                          onClick={() => navigate(`/supplier-ingredient/${supplierId}/${option.id}`)} />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Pane>
      </Block>

      <Dialog
          isShown={!!removingOption}
          title="Delete Supplier Ingredient"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={(close) => {
            removeIngredientOption(removingOption.id);
            close();
          }}
          onCloseComplete={() => setRemovingOption(false)}
      >
        <Text>Are you sure you wish to remove '{removingOption.name}'?</Text>
      </Dialog>
    </>
  );
};

UnapprovedEdit.propTypes = {
  supplierId: PropTypes.string.isRequired,
};

export default UnapprovedEdit;
