//React
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Table } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { current } from '../../../utils/selectors';


const OrderArchive = () => {
  const { accountId } = useParams();
  const suppliers = useSelector((state) => _.sortBy(current(state, 'suppliers', accountId), 'name'));
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const filteredSuppliers = (searchText) ? _.filter(suppliers, (item) => (_.includes(_.lowerCase(item.name), _.lowerCase(searchText)))) : suppliers;


  return (
    <Block marginBottom={majorScale(2)} >
      <Pane paddingX={majorScale(2)} marginY={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Orders by supplier</CustomHeading>
        <FilterBar
            searchPlaceholder="Search Suppliers"
            searchOnChange={(newSearch) => setSearchText(newSearch)}
        />
      </Pane>

      <Table>
        <Table.Head height={48} paddingRight={majorScale(2)}>
          <Table.TextHeaderCell flex="1 0 auto">Name</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={50} flexGrow={0}>View</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.isEmpty(filteredSuppliers) && (
            <Table.Row paddingRight={majorScale(2)}>
              <Table.TextCell>No suppliers to display.</Table.TextCell>
            </Table.Row>
          )}
          {_.map(filteredSuppliers, (supplier) => (
            <Table.Row height={48} key={supplier.id} isSelectable paddingRight={majorScale(2)} onSelect={() => navigate(`/${accountId}/review/supplier-statements/${supplier.id}`)}>
              <Table.TextCell flex="1 0 auto">{supplier.name}</Table.TextCell>
              <Table.Cell flexBasis={50} flexGrow={0}>
                <IconWrapper
                    name="clipboard"
                    appearance="clickable"
                    is={Link}
                    to={`/${accountId}/reviewsupplier-statements/${supplier.id}`}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Block>
  );
};

export default OrderArchive;
