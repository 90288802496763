//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, TextInputField, SelectField, FormField, Checkbox, Text, Paragraph, Strong, useTheme } from 'evergreen-ui';

//Components
import DataTable from '../../components/DataTable/DataTable';
import Button from '../../components/ui/Button/Button';
import LinkOptionDialog from './IngredientEditFormComponents/LinkOptionDialog';
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';
import Block from '../../components/ui/Block/Block'

//Files
import { current, selectIngredientCategoryHierarchy, activeAccount } from '../../utils/selectors';
import { ingredientOptionAllergens } from '../../utils/functions';
import { findRecipesUsingIngredient } from '../../utils/ingredients'
import { actions } from '../../store/actions';


const IngredientEditForm = ({ ingredientId, ingInfo, onChange, linkedOptions, linkOptionToThis, unlinkOptionFromThis, displayAllergens, errors, setErrors }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  
  const allergenList = useSelector((state) => state.allergens);
  const categoryList = useSelector(selectIngredientCategoryHierarchy);
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers'), 'id'));

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Supplier', field: 'supplierId', type: 'text', format: (supplierId) => ((supplierMap[supplierId]) ? supplierMap[supplierId].name : ''), width: 3 },
    { label: 'UOM', field: 'uom', type: 'text', width: 2 },
    { label: 'Price', field: 'unitprice', type: 'numeric', prefix: '£', width: 2 },
    { label: 'View', field: 'view', type: 'action', icon: 'eye', width: 1, buttonProps: { appearance: 'clickable' }, },
    { label: 'Unlinked', field: 'unliked', type: 'action', icon: 'unlock', width: 1, row: 1, buttonProps: { appearance: 'warning' }},
  ];

  const recipesUsingIngredient = useSelector(state => findRecipesUsingIngredient(state, ingredientId));
  
  const recipeTableHeaders = [
    { label: 'Recipe name', field: 'name', type: 'text', width: 12 },
    //{ label: 'Category', field: 'category', type: 'text', width: 7 },
    {label: 'View', field: 'view', type: 'action', icon: 'eye', width: 1, buttonProps: { appearance: 'clickable' }, },
  ];

  const ingredientMap = useSelector((state) => _.keyBy(current(state, 'ingredients'), 'id'));
  const optionAllergens = ingredientOptionAllergens(ingInfo);

  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [confirmLinkDialogOpen, setConfirmLinkDialogOpen] = useState(false);
  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState(false);
  const [confirmOptionToUnlink, setConfirmOptionToUnlink] = useState(null);
  const [pendingLinkedOption, setPendingLinkedOption] = useState(null);

  const changeField = (fieldName, newValue) => {
    onChange({ ...ingInfo, [fieldName]: newValue });
    if (errors[fieldName]) {
      setErrors({ ...errors, [fieldName]: '' });
    }
  };

  const containsAllergen = (allergen) => {
    if (ingInfo.allergens) {
      return _.includes(ingInfo.allergens, allergen.name) || _.includes(optionAllergens, allergen.name);
    }
    return _.includes(optionAllergens, allergen.name);
  };

  const toggleAllergen = (allergenName, include) => {
    let allergens = ingInfo.allergens || [];
    if (include) {
      // Add the allergen to the list
      allergens = _.uniq(_.concat(allergens, allergenName));
    }
    else if (!_.includes(optionAllergens, allergenName)) {
      // Remove the allergen from the list, but only if it's not an allergen in a supplier option
      allergens = _.without(allergens, allergenName);
    }
    onChange({ ...ingInfo, allergens });
  };

  const handleRowSelectSupplierOption = (combinedSupplierOptions) => {
    const supplierId = combinedSupplierOptions.supplierId;
    const optionId = combinedSupplierOptions.id;
    const path = `/supplier-ingredient/${supplierId}/${optionId}`;
    navigate(path);
  };

  const handleRowSelectRecipe = (recipesUsingIngredient) => {
    const groupId = recipesUsingIngredient.groupId;
    const recipeId = recipesUsingIngredient.id;
    const path = `/products/${groupId}/setup/recipes/${recipeId}`;
    navigate(path);
  };

  const handleUnlinkOption = (option) => {
    setConfirmOptionToUnlink(option);
    setUnlinkDialogOpen(true);
  };

  const confirmUnlinkOption = async () => {
    if (confirmOptionToUnlink) {
      unlinkOptionFromThis(confirmOptionToUnlink);
    }
    setUnlinkDialogOpen(false);
    setConfirmOptionToUnlink(null);
  };

  return (
    <>
    <Block marginBottom={majorScale(2)}>
      <CustomHeading level='4' padding={majorScale(2)}>Ingredient</CustomHeading>
      <Pane
          display="flex"
          flexFlow="row wrap"
          paddingX={majorScale(2)}
      >
        <TextInputField
            required
            label="Ingredient name"
            value={ingInfo.name || ''}
            onChange={(e) => changeField('name', e.target.value)}
            flexBasis={`calc(50% - ${majorScale(8)}px)`}
            marginRight={majorScale(8)}
            isInvalid={!!errors.name}
        />
       <TextInputField
          label="Item code"
          value={ingredientId || ''}
          disabled
          flexBasis={`calc(50% - ${majorScale(8)}px)`}
          marginRight={majorScale(8)}
        />
        <SelectField
            required
            label="Category"
            value={ingInfo.categoryId || ''}
            onChange={(e) => changeField('categoryId', e.target.value)}
            flexBasis={`calc(50% - ${majorScale(8)}px)`}
            marginRight={majorScale(8)}
            isInvalid={!!errors.categoryId}
        >
          <option value="">Please Select...</option>
          {_.map(categoryList, (category) => (
            <option
                value={category.id}
                key={category.id}
            >{_.repeat('--', (category.level || 0))} {category.name}</option>
          ))}
        </SelectField>
        <SelectField
          required
          label="Recipe UOM"
          value={ingInfo.recipeunit || ''}
          onChange={(e) => changeField('recipeunit', e.target.value)}
          flexBasis={`calc(50% - ${majorScale(8)}px)`}
          marginRight={majorScale(8)}
          isInvalid={!!errors.recipeunit}
        >
          <option value="">Please Select...</option>
          <option value="g">g</option>
          <option value="ml">ml</option>
          <option value="unit">unit</option>
        </SelectField>
      </Pane>
      </Block>
      
      <Block marginBottom={majorScale(2)}>
      {ingredientId && (
        <Pane
            marginBottom={majorScale(4)}
            display="flex"
            flexDirection="column"
       >
          <CustomHeading level='4' padding={majorScale(2)}>Supplier option linked to</CustomHeading>
          <DataTable
              headers={tableHeaders}
              items={linkedOptions}
              onView={handleRowSelectSupplierOption}
              onUnliked={handleUnlinkOption}
          />
          <Button
              appearance="primary"
              onClick={() => setLinkDialogOpen(true)}
              alignSelf="flex-start"
              marginX={majorScale(2)}
              marginTop={majorScale(2)}
          >Link to supplier option</Button>
        </Pane>
      )}
      </Block>

      <Block marginBottom={majorScale(2)}>
      {ingredientId && recipesUsingIngredient.length > 0 && (
        <Pane
        marginBottom={majorScale(4)}
        display="flex"
        flexDirection="column"
        //flex={`1 0 ${TABLE_MIN_HEIGHT}px`}
        >
          <CustomHeading level='4' padding={majorScale(2)}>Recipes used in</CustomHeading>
          <DataTable
              headers={recipeTableHeaders}
              items={recipesUsingIngredient}
              onView={handleRowSelectRecipe}
          />
        </Pane>
      )}
      </Block>

      <Block marginBottom={majorScale(2)}>
      {displayAllergens && (
        <>
        <CustomHeading level='4' padding={majorScale(2)}>Allergens</CustomHeading>
          <Pane
              marginLeft={majorScale(2)}
              marginBottom={majorScale(2)}
              display="flex"
              flexFlow="row wrap"
              alignItems="flex-start"
          >
            {allergenList.map((allergen) => (
              <Checkbox
                  key={allergen.id}
                  label={allergen.name}
                  checked={containsAllergen(allergen)}
                  disabled={_.includes(optionAllergens, allergen.name)}
                  onChange={(e) => toggleAllergen(allergen.name, e.target.checked)}
                  flexBasis="15%"
                  marginRight={majorScale(2)}
              />
            ))}
          </Pane>
        </>
      )}
      </Block>

      <LinkOptionDialog
        ingredientId={ingredientId}
        isShown={linkDialogOpen}
        onCloseComplete={() => setLinkDialogOpen(false)}
        onConfirm={(option) => {
          if (option.ingredientId) {
            setPendingLinkedOption(option);
            setConfirmLinkDialogOpen(true);
          } else {
            linkOptionToThis(option);
            setLinkDialogOpen(false);
          }
        }}
      />

      <CustomDialog
        isOpen={confirmLinkDialogOpen}
        intent="danger"
        confirmLabel="Link"
        onClose={() => {
          setConfirmLinkDialogOpen(false);
          setPendingLinkedOption(null);
        }}
        onConfirm={() => {
          linkOptionToThis(pendingLinkedOption);
          setPendingLinkedOption(null);
          setConfirmLinkDialogOpen(false);
        }}
      >
        <Pane marginBottom={majorScale(2)}>
          <Text>This Supplier Ingredient is already linked to{' '}
            {(ingredientMap[pendingLinkedOption?.ingredientId]) ? ingredientMap[pendingLinkedOption.ingredientId].name : 'an ingredient'}!
            Are you sure you want to link it to this ingredient instead?
          </Text>
        </Pane>
      </CustomDialog>

      <CustomDialog
        isOpen={unlinkDialogOpen}
        title="Unlinking supplier option"
        intent="danger"
        onClose={() => setUnlinkDialogOpen(false)}
        confirmLabel="Unlink"
        onConfirm={confirmUnlinkOption}
      >
        <Pane marginBottom={majorScale(3)}>
          <Paragraph marginBottom={majorScale(2)}>
            The link between {confirmOptionToUnlink?.name} and the associated ingredient will be removed.
          </Paragraph>
          <Paragraph marginBottom={majorScale(2)}>
            {confirmOptionToUnlink?.name} option will be automatically deactivated, and will be unavailable for this account.
          </Paragraph>
          <Paragraph>
            This action may impact past or future reports.
          </Paragraph>
        </Pane>
      </CustomDialog>

    </>
  );
};

IngredientEditForm.propTypes = {
  ingredientId: PropTypes.string,
  ingInfo: PropTypes.object,
  onChange: PropTypes.func,
  displayAllergens: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

IngredientEditForm.defaultProps = {
  ingredientId: '',
  ingInfo: { recipeunit: '', categoryId: '' },
  onChange: () => {},
  displayAllergens: true,
  errors: {},
  setErrors: () => {},
};

export default IngredientEditForm;
