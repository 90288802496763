import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addSection, removeSection, updateSection } from '../firebase/sections';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* sectionsSaga() {
  yield takeEvery(actionTypes.sections.ADD_SECTION, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addSection, action.sectionInfo, accountId);
      yield put(actions.appMessageSuccess('Section Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Section could not be added'));
    }
  });
  yield takeEvery(actionTypes.sections.REMOVE_SECTION, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeSection, action.sectionId, accountId);
      yield put(actions.appMessageSuccess('Section Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Section could not be removed'));
    }
  });
  yield takeEvery(actionTypes.sections.UPDATE_SECTION, function* (action) {
    const { id, ...sectionInfo } = action.sectionInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateSection, id, sectionInfo, accountId);
      yield put(actions.appMessageSuccess('Section Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Section could not be updated'));
    }
  });
};
