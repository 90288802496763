//FOR SUPPLIERS
//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, Table } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import CustomHeading from '../../components/Headings/Headings';
import IconWrapper from '../../components/Icons/Icons';
import CustomDialog from '../../components/Dialog/Dialog';

//Files
import { actions } from '../../store/actions';
import { current } from '../../utils/selectors';
import useSuppliersAuth from '../../hooks/useSuppliersAuth';


const Supplier = (props) => {
  useSuppliersAuth('supplier');
  const dispatch = useDispatch();
  const supplier = useSelector((state) => (_.first(current(state, 'suppliers'))));
  const [archiveSupplier, toggleArchiveSupplier] = useState(false);

  return (
    <Page title={supplier?.name || 'Supplier'}>
      <CustomDialog
          isOpen={!!archiveSupplier}
          title={`${archiveSupplier.archive ? 'Una' : 'A'}rchive Supplier`}
          intent="danger"
          confirmLabel={`${archiveSupplier.archive ? 'Una' : 'A'}rchive`}
          onConfirm={() => {
            dispatch(actions.suppliers.toggleArchiveSupplier(archiveSupplier));
            toggleArchiveSupplier(false);
          }}
          onClose={() => toggleArchiveSupplier(false)}
      >
        <Text>Are you sure you wish to {`${archiveSupplier.archive ? 'una' : 'a'}rchive`} the supplier {archiveSupplier.name}?</Text>
      </CustomDialog>

      <Block width="100%"  maxWidth={600}>
        <Pane
            display="flex"
            alignItems="center"
            marginY={majorScale(2)}
            paddingX={majorScale(2)}
        >
          <CustomHeading level="2" flex="1 0 auto">Supplier</CustomHeading>
          <Button
              is={Link}
              appearance="primary"
              to="/supplier-ingredient-upload"
              marginLeft={majorScale(2)}
          >Upload CSV</Button>
          <Button
              appearance="primary"
              marginLeft={majorScale(2)}
              is={Link}
              to="/supplier-categories"
          >Categories</Button>
        </Pane>
        <Table>
          <Table.Head paddingRight={0}>
            <Table.TextHeaderCell>Supplier name</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={56} flexGrow={0}>Archive</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(supplier) && (
              <Table.Row>
                <Table.TextCell>No supplier details found.</Table.TextCell>
              </Table.Row>
            )}
            {supplier && (
              <Table.Row
                  key={supplier.id}
              >
                <Table.TextCell>{supplier.name}</Table.TextCell>
                <Table.Cell flexBasis={56} flexGrow={0}>
                  {!supplier.archive &&
                    <IconWrapper
                      name="edit"
                      appearance='clickable'
                      is={Link}
                      to={`/suppliers/${supplier.id}`}
                    />
                  }
                </Table.Cell>
                <Table.Cell flexBasis={56} flexGrow={0}>
                  <IconWrapper
                      intent={!supplier.archive ? 'danger' : null}
                      appearance='clickable'
                      name={!supplier.archive ? 'lock' : 'unlock'}
                      onClick={() => toggleArchiveSupplier(supplier)}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Block>
    </Page>
  );
};

export default Supplier;
