//React
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _, { debounce } from 'lodash';
import { majorScale, Pane, Text, TextInputField, UnorderedList, ListItem, Paragraph, Checkbox, Strong, useTheme } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Accordion from '../../../components/ui/Accordion/Accordion';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';
import UsageInfoForDialog from '../../../components/UsageInfoForDialog/UsageInfoForDialog';

//Files
import { actions } from '../../../store/actions';
import { buildRecipeCategory, generateSearchFieldsFn, recipeMargin, recipeCosting, recipeWastePercentage } from '../../../utils/functions';
import { current, selectMenus, selectRecipes, selectModifiers } from '../../../utils/selectors';
import  { getMenusUsingRecipe, getRecipesUsingRecipe, getModifiersUsingRecipe, filterActiveRecipes } from '../../../utils/recipes';


const Recipes = ( {accountId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const categoryList = useSelector((state) => _.sortBy(current(state, 'recipeCategories', accountId), 'name'));
  const departments = useSelector(state => current(state, 'departments'));

  const menus = useSelector((state) => selectMenus(state, accountId));
  const recipes = useSelector((state) => selectRecipes(state, accountId));
  const modifiers = useSelector((state) => selectModifiers(state, accountId));

  const [filters, setFilters] = useState({});
  const [recipeToRemove, setRecipeToRemove] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [recipeToArchive, setRecipeToArchive] = useState(null);
  const [archiveDialogShown, setArchiveDialogShown] = useState(false);

  const [removingCategory, setRemovingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);
  const [manageCategories, setManageCategories] = useState(false);

  const [usageInfo, setUsageInfo] = useState({ menusUsingRecipe: [], recipesUsingRecipe: [], modifiersUsingRecipe: [] });
  const [removeLinksOnArchive, setRemoveLinksOnArchive] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const filterActiveRecipesMemo = useMemo(() => filterActiveRecipes(recipes), [recipes]);

  //Function used to get the categoryName, departmentsName and nbOfDepartments
  const buildFilterableRecipe = useCallback((recipe, cats, departmentList) => {
    const gp = recipeMargin(recipe, true);
    const cost = recipeCosting(recipe, true, true);
    const wastage = recipeWastePercentage(recipe) * cost;
    const totalCost = wastage ? cost + wastage : cost;

    // Add category details to the recipe
    const rec = buildRecipeCategory(recipe, cats);
    // Map department IDs to department names
    const departments = recipe.departments ? recipe.departments.map(dep => departmentList.find(d => d.id === dep.id)?.name) : [];
    // Count the number of departments
    const numberDept = departments ? departments.length : 0;
    // Return the augmented recipe object with department names
    return {
      ...rec,
      departments,
      numberDept,
      gp: gp.toFixed(2),
      cost: totalCost.toFixed(2),
    }
  }, []);

  const recipeList = useMemo(() => {
    return filterActiveRecipesMemo.map(recipe => buildFilterableRecipe(recipe, categoryList, departments));
  }, [filterActiveRecipesMemo, categoryList, departments, buildFilterableRecipe]);
  
  const archivedRecipeList = useMemo(() => {
    return _.sortBy(recipes.filter(r => r.archived), 'name').map(r => {
      return {
        ...buildFilterableRecipe(r, categoryList),
      };
    });
  }, [recipes, categoryList]);

  const tableHeaders = [
    { label: 'Img', field: 'thumb_imageURL', type: 'thumbnail', width: 2 },
    { label: 'Name', field: 'name', type: 'text', width: 4 },
    { label: 'Cost', field: 'cost', type: 'text', width: 2, prefix: '£' },
    { label: 'GP %', field: 'gp', type: 'text', width: 2, suffix: '%' },
    { label: 'Category', field: 'category', type: 'text', width: 2 },
    { label: 'Nb Dept.', field: 'numberDept', type: 'text', width: 1 },
    { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', link: `/products/${accountId}/setup/recipes/`, buttonProps: { appearance: 'clickable' }, width: 1 },
    { label: 'Deactivate', field: 'deactivate', type: 'action', icon: 'lock', buttonProps: { appearance: 'warning' }, width: 1 },
    { label: 'Delete', field: 'delete', type: 'action', icon: 'trash', buttonProps: { appearance: 'danger' }, width: 1 },
  ];

  const tableArchiveHeaders = [
    { label: 'Img', field: 'thumb_imageURL', type: 'thumbnail', width: 2 },
    { label: 'Name', field: 'name', type: 'text', width: 8 },
    { label: 'Category', field: 'category', type: 'text', width: 4 },
    { label: 'Activate', field: 'activate', type: 'action', icon: 'unlock', buttonProps: { appearance: 'warning' }, width: 1 }
  ]

  const categoryOptions = useMemo(() => {
    if (categoryList) {
      return _.map(categoryList, (cat) => ({ label: cat.name, value: cat.name }));
    }
    return [];
  }, [categoryList]);

  const departmentOptions = useMemo(() => {
    if (departments) {
      return _.map(departments, (cat) => ({ label: cat.name, value: cat.name }));
    }
    return [];
  }, [departments]);

  const filterFields = useMemo(() => [
    { label: 'Recipe Category', name: 'category', options: categoryOptions },
    { label: 'Department', name: 'departments', options: departmentOptions }
  ], [categoryOptions, departmentOptions]);

  const updateFilters = useCallback((filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  }, []);

  const debounceSearch = debounce((newSearchValue, updateFilters) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    } else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  }, 250);

  const searchOnChange = useCallback((newSearchValue) => {
    debounceSearch(newSearchValue, updateFilters);
  }, [updateFilters]);

  const handleArchiveRecipe = (recipe) => {
    const recipeId = recipe.id;
    const menusUsingRecipe = getMenusUsingRecipe(menus, recipeId);
    const recipesUsingRecipe = getRecipesUsingRecipe(recipes, recipeId);
    const modifiersUsingRecipe = getModifiersUsingRecipe(modifiers, recipeId);

    setUsageInfo({ menusUsingRecipe, recipesUsingRecipe, modifiersUsingRecipe });
    setRecipeToArchive(recipe); 
    setArchiveDialogShown(true); 
  };

  const handleUnarchiveRecipe = (recipeId) => {
    dispatch(actions.recipes.updateRecipe(accountId, { id: recipeId, archived: false }));
  };

  const confirmArchiveRecipe = () => {
    const recipeId = recipeToArchive.id;
    dispatch(actions.recipes.archiveRecipe(accountId, recipeId));
    if (removeLinksOnArchive) {
      dispatch(actions.recipes.removeRecipeFromCollections(accountId, recipeId, usageInfo));
    }
    setArchiveDialogShown(false);
  };

  const handleRemoveRecipe = (recipe) => {
    const recipeId = recipe.id;
    const menusUsingRecipe = getMenusUsingRecipe(menus, recipeId);
    const recipesUsingRecipe = getRecipesUsingRecipe(recipes, recipeId);
    const modifiersUsingRecipe = getModifiersUsingRecipe(modifiers, recipeId);

    setUsageInfo({ menusUsingRecipe, recipesUsingRecipe, modifiersUsingRecipe });
    setRecipeToRemove(recipe);
    setRemoveDialogShown(true);
  };

  const confirmRemoveRecipe = () => {
    const recipeId = recipeToRemove.id;
    dispatch(actions.recipes.removeRecipe(accountId, recipeId));
    dispatch(actions.recipes.removeRecipeFromCollections(accountId, recipeId, usageInfo));
    setRemoveDialogShown(false);
  };

  const saveCategory = (cat, close) => {
    if (cat.id) {
      dispatch(actions.recipeCategories.updateCategory(accountId, cat, () => close()));
    }
    else {
      dispatch(actions.recipeCategories.addCategory(accountId, cat, () => close()));
    }
  };


  return (
    <React.Fragment>

      <CustomDialog
          isOpen={removeDialogShown}
          title="Deleting recipe"
          confirmLabel="Delete"
          onClose={() => setRemoveDialogShown(false)}
          onCloseComplete={() => setRemoveDialogShown(false)}
          onConfirm={confirmRemoveRecipe}
      >
        <UsageInfoForDialog marginBottom={majorScale(2)} usageInfo={usageInfo} />
        <Pane marginBottom={majorScale(3)}>
          <Paragraph marginBottom={majorScale(3)}>Deleting {recipeToRemove?.name} will make it unavailable for this account.</Paragraph>
          {(usageInfo.menusUsingRecipe.length > 0 || usageInfo.recipesUsingRecipe.length > 0 || usageInfo.modifiersUsingRecipe.length > 0) && (
              <Pane marginTop={majorScale(3)}> 
                <Paragraph marginBottom={majorScale(2)}>- {recipeToRemove?.name} will be removed from any menus, recipes, or modifiers it is currently used in, which may impact their respective costs.</Paragraph>
                <Paragraph>- This action may also affect past and future reports.</Paragraph>
              </Pane>
          )}
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={archiveDialogShown}
          title="Deactivating recipe"
          confirmLabel="Deactivate"
          onClose={() => setArchiveDialogShown(false)}
          onCloseComplete={() => setArchiveDialogShown(false)}
          onConfirm={confirmArchiveRecipe}
      >
        <UsageInfoForDialog marginBottom={majorScale(2)} usageInfo={usageInfo} />
        <Pane marginBottom={majorScale(3)}>
            <Paragraph marginBottom={majorScale(3)}>Deactivating {recipeToArchive?.name} will make it unavailable for this account.</Paragraph>
            {(usageInfo.menusUsingRecipe.length > 0 || usageInfo.recipesUsingRecipe.length > 0 || usageInfo.modifiersUsingRecipe.length > 0) && (
              <Pane marginTop={majorScale(3)}> 
                <Checkbox
                  label="Remove this recipe from any associated menus, recipes or modifiers:"
                  checked={removeLinksOnArchive}
                  onChange={(e) => setRemoveLinksOnArchive(e.target.checked)}
                />
                <Paragraph marginBottom={majorScale(2)}>- If checked, {recipeToArchive?.name} will be removed from any menus, recipes, or modifiers it is currently used in, which may impact their respective costs.</Paragraph>
                <Paragraph>- This action may also affect past and future reports.</Paragraph>
            </Pane>
            )}
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={manageCategories}
          title="Recipe Categories"
          hasCancel={false}
          confirmLabel="Close"
          onConfirm={() => {
            setManageCategories(false);
          }}
          onClose={() => setManageCategories(false)}
          onCloseComplete={() => setManageCategories(false)}
      >
        <Pane textAlign="left" marginBottom={majorScale(4)}>
          <Button 
            appearance="primary"
            onClick={() => setEditingCategory({})}
          >New Category</Button>
        </Pane>
        <UnorderedList heigth={40} listStyle="none" marginY={majorScale(2)} padding={0}>
          {categoryList && categoryList.map((cat) => (
            <ListItem key={cat.id} display="flex" alignItems="center">
              <Text flex="1 1 auto" marginRight={majorScale(2)} marginBottom={majorScale(2)}>{cat.name}</Text>
              <IconWrapper
                  name="edit"
                  appearance="clickable"
                  onClick={() => setEditingCategory(cat)} marginRight={majorScale(2)}
              />
              <IconWrapper
                  name="trash"
                  appearance="danger"
                  intent="danger"
                  onClick={() => setRemovingCategory(cat)}
              />
            </ListItem>
          ))}
        </UnorderedList>
      </CustomDialog>

      <CustomDialog
          isOpen={!!editingCategory}
          title={(editingCategory && editingCategory.name) || 'New Recipe Category'}
          confirmLabel={(editingCategory && editingCategory.id) ? 'Update' : 'Add'}
          onConfirm={() => {
            saveCategory(editingCategory);
            setEditingCategory(false);
          }}
          onClose={() => setEditingCategory(false)}
          onCloseComplete={() => setEditingCategory(false)}
      >
        <Pane marginBottom={majorScale(4)}>
        <TextInputField
            required
            label="Name"
            placeholder="Category Name"
            value={editingCategory.name || ''}
            onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
        />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!removingCategory}
          title="Remove Recipe Category"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={(close) => {
            dispatch(actions.recipeCategories.removeCategory(accountId, removingCategory.id));
            close();
          }}
          onClose={() => setRemovingCategory(false)}
          onClosComplete={() => setRemovingCategory(false)}
      >
        <Pane marginBottom={majorScale(4)}>
          <Text>Are you sure you wish to remove the category {removingCategory.name}?</Text>
        </Pane>
      </CustomDialog>

      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
          <CustomHeading level="3" flex="1 0 auto">Recipes</CustomHeading>
          <Button
              appearance="primary"
              is={Link}
              to={`/products/${accountId}/setup/recipes/add`}
              marginRight={majorScale(2)}
          >New recipe</Button>
          <Button
            appearance="primary"
            onClick={() => setManageCategories(true)}
          >Recipe categories</Button>
        </Pane>
        <FilterBar
            flex="1 0 auto"
            filterFields={filterFields}
            filters={filters}
            setFilter={updateFilters}
            searchPlaceholder="Search Recipes"
            searchOnChange={searchOnChange}
        />
      </Block>

      <Block flex={`1 0 auto`} display="flex" height="500px" flexDirection="column" marginBottom={majorScale(2)}>
        <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
          <DataTable
              items={recipeList}
              filters={filters}
              headers={tableHeaders}
              onDeactivate={(recipe) => handleArchiveRecipe(recipe) }
              onDelete={(recipe) => handleRemoveRecipe(recipe)}
              flex="1 0 auto"
              listHeight={480}
          />
        </Pane>
      </Block>

      <Block flex={`1 0 auto`} display="flex" maxHeight="500px" flexDirection="column" marginBottom={majorScale(2)}>
        <Accordion
          isOpen={isAccordionOpen}
          toggleOpen={() => setIsAccordionOpen(!isAccordionOpen)}
          label="Deactivated recipes"
          marginBottom={0}
        >
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
            <DataTable
                items={archivedRecipeList}
                filters={filters}
                headers={tableArchiveHeaders}
                onActivate={(recipe) => handleUnarchiveRecipe(recipe.id)} 
                flex="1 0 auto"
                listHeight={480}
            />
          </Pane>
        </Accordion>
      </Block>
    </React.Fragment>
  );
};

Recipes.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default Recipes;
