//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Files
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, UnorderedList, ListItem, TextInput, Text, Dialog, useTheme } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';


const MenuList = ({ accountId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [addingMenu, setAddingMenu] = useState(false);
  const [newMenuName, setNewMenuName] = useState('');
  const [searchText, setSearchText] = useState('');
  const menus = useSelector((state) => {
    if (searchText) {
      return _.filter(current(state, 'menus', accountId), (item) => _.includes(_.lowerCase(item.name), _.lowerCase(searchText)));
    }
    return current(state, 'menus', accountId);
  });

  const createMenu = () => {
    if (newMenuName) {
      dispatch(actions.menus.addMenu(accountId, { name: newMenuName, sections: [] }, () => {
        setAddingMenu(false);
        setNewMenuName('');
      }));
    }
    else {
      dispatch(actions.appMessageError('You must enter a name for the new menu'));
    }
  };

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Menus</CustomHeading>
          <Button appearance="primary" onClick={() => setAddingMenu(true)}>New menu</Button>
        </Pane>
        <FilterBar searchOnChange={setSearchText} />
      </Block>

      <UnorderedList listStyle="none" marginX={0} padding={0}>
        {_.isEmpty(menus) && (
          <ListItem>
            <Pane
                elevation={1}
                border="default"
                background={theme.colors.secondary10}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
            >
              <Text>No menus to display</Text>
            </Pane>
          </ListItem>
        )}
        {_.map(menus, (menu) => (
          <ListItem key={menu.id}>
            <Pane
                is={Link}
                to={`/products/${accountId}/setup/menu/${menu.id}`}
                textDecoration="none"
                display="block"
                alignContent="center"
                borderRadius={5}
                boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
                border="default"
                background={theme.colors.offwhite}
                height={48}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
            >
              <Text>{menu.name}</Text>
            </Pane>
          </ListItem>
        ))}
      </UnorderedList>

      <CustomDialog
          isOpen={addingMenu}
          title="New Menu"
          confirmLabel="Create Menu"
          onClose={() => {
            setAddingMenu(false);
            setNewMenuName('');
          }}
          onConfirm={() => {
            createMenu();
            setAddingMenu(false);
            setNewMenuName('');
          }}
      >
        <Pane marginTop={majorScale(2)} marginBottom={majorScale(4)}>
          <TextInput
              value={newMenuName}
              placeholder="Menu Name"
              onChange={(e) => setNewMenuName(e.target.value)}
          />
        </Pane>
      </CustomDialog>
    </React.Fragment>
  );
};

MenuList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default MenuList;
