const INVOICE_UPLOADS_LOAD_SUCCESS = 'INVOICE_UPLOADS_LOAD_SUCCESS';
const INVOICE_UPLOADS_CLEAR = 'INVOICE_UPLOADS_CLEAR';
const ADD_INVOICE_UPLOAD = 'ADD_INVOICE_UPLOAD';
const REMOVE_INVOICE_UPLOAD = 'REMOVE_INVOICE_UPLOAD';
const UPDATE_INVOICE_UPLOAD = 'UPDATE_INVOICE_UPLOAD';
const UPDATE_INVOICE_UPLOAD_ATTACHMENTS = 'UPDATE_INVOICE_UPLOAD_ATTACHMENTS'

export const actionTypes = {
  INVOICE_UPLOADS_LOAD_SUCCESS,
  INVOICE_UPLOADS_CLEAR,
  ADD_INVOICE_UPLOAD,
  REMOVE_INVOICE_UPLOAD,
  UPDATE_INVOICE_UPLOAD,
  UPDATE_INVOICE_UPLOAD_ATTACHMENTS
};


const invoiceUploadsLoadSuccess = (payload, account) => {
  return {
    type: INVOICE_UPLOADS_LOAD_SUCCESS,
    payload,
    account
  };
};

const invoiceUploadsClear = () => {
  return {
    type: INVOICE_UPLOADS_CLEAR
  };
};

const addInvoiceUpload = (accountId, invoiceUpload, callback) => {
  return {
    type: ADD_INVOICE_UPLOAD,
    invoiceUpload,
    accountId,
    callback
  };
};

const removeInvoiceUpload = (accountId, recordId) => {
  return {
    type: REMOVE_INVOICE_UPLOAD,
    recordId,
    accountId,
  };
};

const updateInvoiceUpload = (accountId, record) => {
  return {
    type: UPDATE_INVOICE_UPLOAD,
    record,
    accountId,
  };
};

const updateInvoiceUploadAttachments = (accountId, invoiceUploadId, upload, attachments, callback) => {
  return {
    type: UPDATE_INVOICE_UPLOAD_ATTACHMENTS,
    invoiceUploadId,
    accountId,
    upload,
    attachments,
    callback
  };
}

export const actions = {
  invoiceUploadsLoadSuccess,
  invoiceUploadsClear,
  addInvoiceUpload,
  removeInvoiceUpload,
  updateInvoiceUpload,
  updateInvoiceUploadAttachments
};
