// Utility function to check if a recipe is used in any menu
export const getMenusUsingRecipe = (menus, recipeId) => {
    const menusUsingRecipe = menus.filter(menu => {
      return (menu.sections || []).some(section => {
        return (section.recipes || []).includes(recipeId);
      });
    }).map(menu => ({ id: menu.id, name: menu.name }));

    return menusUsingRecipe;
};

// Utility function to check if a recipe is used in any other recipes
export const getRecipesUsingRecipe = (recipes, recipeId) => {
    const recipesUsingRecipe = recipes.filter(recipe => {
      return (recipe.ingredients || []).some(ingredient =>
        ingredient.type === 'subrecipe' && ingredient.id === recipeId
      );
    }).map(recipe => ({ id: recipe.id, name: recipe.name }));

    return recipesUsingRecipe;
};

// Utility function to check if a recipe is used in any modifiers
export const getModifiersUsingRecipe = (modifiers, recipeId) => {
    const modifiersUsingRecipe = modifiers.filter(modifier => {
      return (modifier.ingredients || []).some(ingredient =>
        ingredient.type === 'subrecipe' && ingredient.id === recipeId
      );
    }).map(modifier => ({ id: modifier.id, name: modifier.name }));

    return modifiersUsingRecipe;
};

//Function to filter out deleted or archived recipes
export const filterActiveRecipes = (recipes) => {
  return recipes.filter(recipe => !recipe.deleted && !recipe.archived);
};
