import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addSales, removeSales, updateSales } from '../firebase/sales';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';
import { requestAccountSales } from '../../utils/functions';

const delay = (ms) => new Promise(res => setTimeout(res, ms))
let accountsLoad = {};

function* callSelfOnTimer({ accountId }) {
  const value = accountsLoad[accountId];
  try {
    if (value && value === true) {
      yield call(requestAccountSales, accountId);
      yield delay(600000);  
      yield call(callSelfOnTimer, { accountId });  
    }
  }
  catch (err) {
    console.log(err,'error requestAccountSales')
    yield put(actions.appMessageError('Lightspeed Sales could not be added'));
  }
}

export default function* salesSaga() {
  yield takeEvery(actionTypes.sales.REQUEST_SALES_LOAD, function* (action) {
    const accountId = yield action.accountId;
    const isRunning = accountsLoad[accountId];
    if (isRunning && isRunning === true) {
      try {
        yield call(requestAccountSales, accountId);
        yield put(actions.appMessageSuccess('Lightspeed Sales Information Added'));
      }
      catch (err) {
        console.log(err,'error requestAccountSales')
        yield put(actions.appMessageError('Lightspeed Sales could not be updated'));
      }
    }
    else {
      accountsLoad[accountId] = true;
      try {
        yield call(callSelfOnTimer, {accountId});
        yield put(actions.appMessageSuccess('Lightspeed Sales Information Added'));
      }
      catch (err) {
        console.log(err,'error requestAccountSales')
        yield put(actions.appMessageError('Lightspeed Sales could not be updated'));
      }
    }
  });
  yield takeEvery(actionTypes.sales.SALES_LOAD_SUCCESS, function* (action) {
    const accountId = yield action.account;
    const isRunning = accountsLoad[accountId];
    if (isRunning && isRunning === true) {
      try {
        yield call(requestAccountSales, accountId);
      }
      catch (err) {
        console.log(err,'error requestAccountSales')
        yield put(actions.appMessageError('Lightspeed Sales could not be updated'));
      }
    }
    else {
      accountsLoad[accountId] = true;
      try {
        yield call(callSelfOnTimer, {accountId});
      }
      catch (err) {
        console.log(err,'error requestAccountSales')
        yield put(actions.appMessageError('Lightspeed Sales could not be updated'));
      }
    }
  });
  yield takeEvery(actionTypes.sales.SALES_CLEAR, function* (action) {
    yield accountsLoad = {};
  })
  yield takeEvery(actionTypes.sales.ADD_SALES, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addSales, action.sales, accountId);
      yield put(actions.appMessageSuccess('Sales Information Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Sales Information could not be added'));
    }
  });
  yield takeEvery(actionTypes.sales.REMOVE_SALES, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeSales, action.salesId, accountId);
      yield put(actions.appMessageSuccess('Sales Information Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Sales Information could not be removed'));
    }
  });
  yield takeEvery(actionTypes.sales.UPDATE_SALES, function* (action) {
    const { id, ...sales } = action.sales;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateSales, id, sales, accountId);
      yield put(actions.appMessageSuccess('Sales Information Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Sales Information could not be updated'));
    }
  });
};
