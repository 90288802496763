import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addRecipeCategory, updateRecipeCategory, removeRecipeCategory } from '../firebase/recipeCategories';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* recipeCategoriesSaga() {
  yield takeEvery(actionTypes.recipeCategories.ADD_RECIPE_CATEGORY, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addRecipeCategory, action.categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Recipe Category Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe Category could not be added'));
    }
  });
  yield takeEvery(actionTypes.recipeCategories.REMOVE_RECIPE_CATEGORY, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeRecipeCategory, action.categoryId, accountId);
      yield put(actions.appMessageSuccess('Recipe Category Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe Category could not be removed'));
    }
  });
  yield takeEvery(actionTypes.recipeCategories.UPDATE_RECIPE_CATEGORY, function* (action) {
    const { id, ...categoryInfo } = action.categoryInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateRecipeCategory, id, categoryInfo, accountId);
      yield put(actions.appMessageSuccess('Recipe Category Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Recipe Category could not be updated'));
    }
  });
};
