const SECTIONS_LOAD_SUCCESS = 'SECTIONS_LOAD_SUCCESS';
const SECTIONS_LOAD_FAILURE = 'SECTIONS_LOAD_FAILURE';
const SECTIONS_CLEAR = 'SECTIONS_CLEAR';
const ADD_SECTION = 'ADD_SECTION';
const REMOVE_SECTION = 'REMOVE_SECTION';
const UPDATE_SECTION = 'UPDATE_SECTION';

export const actionTypes = {
  SECTIONS_LOAD_SUCCESS,
  SECTIONS_LOAD_FAILURE,
  SECTIONS_CLEAR,
  ADD_SECTION,
  REMOVE_SECTION,
  UPDATE_SECTION
};


const sectionsLoadSuccess = (payload, account) => {
  return {
    type: SECTIONS_LOAD_SUCCESS,
    payload,
    account
  };
};

const sectionsLoadFailure = (error) => {
  return {
    type: SECTIONS_LOAD_FAILURE,
    error
  };
};

const sectionsClear = () => {
  return {
    type: SECTIONS_CLEAR
  };
};

const addSection = (accountId, sectionInfo) => {
  return {
    type: ADD_SECTION,
    sectionInfo,
    accountId,
  };
};

const removeSection = (accountId, sectionId) => {
  return {
    type: REMOVE_SECTION,
    sectionId,
    accountId,
  };
};

const updateSection = (accountId, sectionInfo) => {
  return {
    type: UPDATE_SECTION,
    sectionInfo,
    accountId,
  };
};

export const actions = {
  sectionsLoadSuccess,
  sectionsLoadFailure,
  sectionsClear,
  addSection,
  removeSection,
  updateSection
};
