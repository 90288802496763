//React
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Checkbox, FormFieldLabel, Heading, majorScale, minorScale, Pane, Table, Textarea, TextInputField } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import Accordion from '../../../components/ui/Accordion/Accordion';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import { accountTypes } from '../../../utils/constants';

const weekdays = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
]


const DetailsEdit = ({ supplierId }) => {
  const dispatch = useDispatch();
  const supplier = useSelector((state) => {
    if (supplierId) {
      return _.find(current(state, 'suppliers'), { 'id': supplierId });
    }
    return false;
  });
  const account = useSelector((state) => state.accounts[state.currentAccount]);
  const isSupplierAccount = account?.type && account.type === accountTypes.SUPPLIER;
  const [supplierInfo, setSupplierInfo] = useState(supplier || {});
  const isSlave = supplier?.type?.includes('SLAVE')
  const onFieldChange = (field, newValue) => {
    setSupplierInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };
  const saveSupplier = () => {
    if (!_.isEmpty(supplier)) {
      dispatch(actions.suppliers.updateSupplier(supplierInfo));
    }
    else {
      dispatch(actions.suppliers.addSupplier(supplierInfo));
    }
  };

  const toggleDay = (day, checked) => {
    let days = supplierInfo.deliveryDays || [];
    const index = days.indexOf(day);
    if (!checked && index > -1) {
      days = _.without(days, day);
    }
    else if (checked && index === -1) {
      days.push(day);
    }
    onFieldChange('deliveryDays', days);
  }

  const [openType, setOpenType] = useState(null);
  const toggleOpen = useCallback((type) => () => {
    if (openType && openType === type) {
      setOpenType(null);
    }
    else {
      setOpenType(type);
    }    
  }, [openType]);
  return (
    <Block
        padding={majorScale(2)}
    >
      <Accordion
        label={'Supplier information'}
        toggleOpen={toggleOpen('supplier')}
        isOpen={!!openType && openType === 'supplier'}
      >
        <Pane margin={majorScale(2)}>
          <Pane display="flex">
            <Pane
                flex="0 0 50%"
                paddingRight={majorScale(4)}
            >
              <TextInputField
                  required
                  label="Supplier name"
                  value={supplierInfo.name || ''}
                  onChange={(e) => onFieldChange('name', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  label="Phone number"
                  type="tel"
                  value={supplierInfo.phone || ''}
                  onChange={(e) => onFieldChange('phone', e.target.value)}
                  disabled={isSlave}
              />
            </Pane>
            <Pane
                flex="0 0 50%"
                paddingLeft={majorScale(4)}
            >
              <TextInputField
                  label="Contact name"
                  value={supplierInfo.contact || ''}
                  onChange={(e) => onFieldChange('contact', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  label="Website"
                  type="url"
                  value={supplierInfo.website || ''}
                  onChange={(e) => onFieldChange('website', e.target.value)}
                  disabled={isSlave}
              />
            </Pane>
          </Pane>
          <Pane
          >
            <FormFieldLabel
                htmlFor="emailAddresses"
                marginBottom={majorScale(2)}
            >Email addresses (used by supplier)</FormFieldLabel>
            <Textarea
                placeholder="Supplier email addresses"
                value={supplierInfo.emailAddresses || ''}
                marginBottom={majorScale(2)}
                onChange={(e) => onFieldChange('emailAddresses', e.target.value)}
                disabled={isSlave}
            ></Textarea>
          </Pane>
          <TextInputField
              label="Address"
              value={supplierInfo.address1 || ''}
              onChange={(e) => onFieldChange('address1', e.target.value)}
              disabled={isSlave}
          />
          <TextInputField
              label="Postcode"
              value={supplierInfo.postcode || ''}
              onChange={(e) => onFieldChange('postcode', e.target.value)}
              maxWidth={`calc(50% - ${majorScale(4)}px)`}
              disabled={isSlave}
          />
        </Pane>
      </Accordion>
      <Accordion
        label={'Delivery information'}
        toggleOpen={toggleOpen('delivery')}
        isOpen={!!openType && openType === 'delivery'}
      >
        <Pane margin={majorScale(2)}>
          <Pane
            marginBottom={majorScale(2)}
          >
            <FormFieldLabel
                htmlFor="deliveryDays"
                marginBottom={minorScale(2)}
            >Delivery days</FormFieldLabel>
            <Pane
                display="flex"
            >
              <Table>
                <Table.Head>
                  {_.map(weekdays, (day) =>
                    <Table.HeaderCell key={day}>{day}</Table.HeaderCell>
                  )}
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    {_.map(weekdays, (day) => {
                      const isChecked = _.includes(supplierInfo.deliveryDays || [], day);
                      return (
                        <Table.Cell key={day}>
                          <Checkbox
                              checked={isChecked}
                              readOnly
                              flexBasis="15%"
                              marginRight={majorScale(2)}
                              onChange={(e) => toggleDay(day, e.target.checked)}
                              disabled={isSlave}
                          />
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                </Table.Body>
              </Table>
            </Pane>
          </Pane>
          <Pane
              display="flex"
          >
            <Pane flex="0 0 50%" paddingRight={majorScale(4)}>
              <TextInputField
                  label="Cut-off time"
                  value={supplierInfo.cutOfTime || ''}
                  onChange={(e) => onFieldChange('cutOfTime', e.target.value)}
                  disabled={isSlave}
              />
            </Pane>
            <Pane flex="0 0 50%" paddingLeft={majorScale(4)}>
              <TextInputField
                  label="Minimum order"
                  value={supplierInfo.minimumSpend || ''}
                  onChange={(e) => onFieldChange('minimumSpend', e.target.value)}
                  disabled={isSlave}
              />
            </Pane>
          </Pane>
        </Pane>
      </Accordion>
      <Accordion
        label={'Product categories'}
        toggleOpen={toggleOpen('products')}
        isOpen={!!openType && openType === 'products'}
      >
        <Pane margin={majorScale(2)}>
          <Pane
              display="flex"
          >
            <Pane flex="0 0 50%" paddingRight={majorScale(4)}>
              <FormFieldLabel
                  htmlFor="typeofproducts"
                  marginBottom={majorScale(2)}
              >Category names</FormFieldLabel>
              <TextInputField
                  value={supplierInfo.typeofproducts || ''}
                  onChange={(e) => onFieldChange('typeofproducts', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.typeofproducts1 || ''}
                  onChange={(e) => onFieldChange('typeofproducts1', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.typeofproducts2 || ''}
                  onChange={(e) => onFieldChange('typeofproducts2', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.typeofproducts3 || ''}
                  onChange={(e) => onFieldChange('typeofproducts3', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.typeofproducts4 || ''}
                  onChange={(e) => onFieldChange('typeofproducts4', e.target.value)}
                  disabled={isSlave}
              />
            </Pane>
            <Pane flex="0 0 50%" paddingLeft={majorScale(4)}>
              <FormFieldLabel
                  htmlFor="targettemperature"
                  marginBottom={majorScale(2)}
              >Target delivery temperature</FormFieldLabel>
              <TextInputField
                  value={supplierInfo.targettemperature || ''}
                  onChange={(e) => onFieldChange('targettemperature', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.targettemperature1 || ''}
                  onChange={(e) => onFieldChange('targettemperature1', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.targettemperature2 || ''}
                  onChange={(e) => onFieldChange('targettemperature2', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.targettemperature3 || ''}
                  onChange={(e) => onFieldChange('targettemperature3', e.target.value)}
                  disabled={isSlave}
              />
              <TextInputField
                  value={supplierInfo.targettemperature4 || ''}
                  onChange={(e) => onFieldChange('targettemperature4', e.target.value)}
                  disabled={isSlave}
              />
            </Pane>
          </Pane>
        </Pane>
      </Accordion>
      
      {!isSupplierAccount && (
        <Pane
        >
          <Pane
                display="flex"
                alignItems="center"
                marginBottom={majorScale(2)}
            >
            <Heading is="h3" flex="1 0 auto">Customer account information</Heading>
          </Pane>
          <Pane
            display="flex"
          >
            <Pane flex="0 0 50%" paddingRight={majorScale(4)}>
              <TextInputField
                  label="Account code"
                  value={supplierInfo.accountNumber || ''}
                  onChange={(e) => onFieldChange('accountNumber', e.target.value)}
              />
              <TextInputField
                  required
                  label="Supplier ordering email"
                  type="email"
                  value={supplierInfo.email || ''}
                  onChange={(e) => onFieldChange('email', e.target.value)}
              />
            </Pane>
            <Pane flex="0 0 50%" paddingLeft={majorScale(4)}>
              <TextInputField
                  label="Supplier contact name"
                  value={supplierInfo.customerContact || ''}
                  onChange={(e) => onFieldChange('customerContact', e.target.value)}
              />
              <TextInputField
                  label="Supplier phone number"
                  type="tel"
                  value={supplierInfo.customerPhone || ''}
                  onChange={(e) => onFieldChange('customerPhone', e.target.value)}
              />
            </Pane>
          </Pane>
          
        </Pane>
      )}
      <Button
          appearance="primary"
          onClick={() => saveSupplier()}
      >Save</Button>
    </Block>
  );
};

DetailsEdit.propTypes = {
  supplierId: PropTypes.string,
};

DetailsEdit.defaultProps = {
  supplierId: '',
};

export default DetailsEdit;
