//React
import React from 'react';

//Libraries
import { Link, Text, useTheme } from 'evergreen-ui';

const TutoLink = ({tutorialLink, title}) => {
  const theme = useTheme();

  return (
    <Link href={tutorialLink} target="_blank" rel="noopener noreferrer">
      <Text size={500} color={theme.colors.secondary70}>{title}</Text>
    </Link>
  );
};

export default TutoLink;