const INGREDIENT_CATEGORIES_LOAD_SUCCESS = 'INGREDIENT_CATEGORIES_LOAD_SUCCESS';
const INGREDIENT_CATEGORIES_LOAD_FAILURE = 'INGREDIENT_CATEGORIES_LOAD_FAILURE';
const INGREDIENT_CATEGORIES_CLEAR = 'INGREDIENT_CATEGORIES_CLEAR';
const ADD_INGREDIENT_CATEGORY = 'ADD_INGREDIENT_CATEGORY';
const REMOVE_INGREDIENT_CATEGORY = 'REMOVE_INGREDIENT_CATEGORY';
const UPDATE_INGREDIENT_CATEGORY = 'UPDATE_INGREDIENT_CATEGORY';

export const actionTypes = {
  INGREDIENT_CATEGORIES_LOAD_SUCCESS,
  INGREDIENT_CATEGORIES_LOAD_FAILURE,
  INGREDIENT_CATEGORIES_CLEAR,
  ADD_INGREDIENT_CATEGORY,
  REMOVE_INGREDIENT_CATEGORY,
  UPDATE_INGREDIENT_CATEGORY
};

const ingredientCategoriesLoadSuccess = (payload, account) => {
  return {
    type: INGREDIENT_CATEGORIES_LOAD_SUCCESS,
    payload,
    account
  };
};

const ingredientCategoriesLoadFailure = (error) => {
  return {
    type: INGREDIENT_CATEGORIES_LOAD_FAILURE,
    error
  };
};

const ingredientCategoriesClear = () => {
  return {
    type: INGREDIENT_CATEGORIES_CLEAR
  };
};

const addCategory = (categoryInfo, callback) => {
  return {
    type: ADD_INGREDIENT_CATEGORY,
    categoryInfo,
    callback
  };
};

const removeCategory = (categoryId) => {
  return {
    type: REMOVE_INGREDIENT_CATEGORY,
    categoryId
  };
};

const updateCategory = (categoryInfo, callback) => {
  return {
    type: UPDATE_INGREDIENT_CATEGORY,
    categoryInfo,
    callback
  };
};

export const actions = {
  ingredientCategoriesLoadSuccess,
  ingredientCategoriesLoadFailure,
  ingredientCategoriesClear,
  addCategory,
  removeCategory,
  updateCategory
};
