const MESSAGES_SEEN_UPDATE = 'MESSAGES_SEEN_UPDATE';
const MESSAGES_SEEN_TIMES_LOAD_SUCCESS = 'MESSAGES_SEEN_TIMES_LOAD_SUCCESS';
const MESSAGES_SEEN_TIMES_LOAD_FAILURE = 'MESSAGES_SEEN_TIMES_LOAD_FAILURE';
const MESSAGES_SEEN_TIMES_CLEAR = 'MESSAGES_SEEN_TIMES_CLEAR';

export const actionTypes = {
  MESSAGES_SEEN_UPDATE,
  MESSAGES_SEEN_TIMES_LOAD_SUCCESS,
  MESSAGES_SEEN_TIMES_LOAD_FAILURE,
  MESSAGES_SEEN_TIMES_CLEAR
}

const messagesSeen = (profile) => {
  return {
    type: MESSAGES_SEEN_UPDATE,
    profile
  };
};

const messagesSeenTimesLoadSuccess = (payload, account) => {
  return {
    type: MESSAGES_SEEN_TIMES_LOAD_SUCCESS,
    payload,
    account
  };
};

const messagesSeenTimesLoadFailure = (error) => {
  return {
    type: MESSAGES_SEEN_TIMES_LOAD_FAILURE,
    error
  };
};

const messagesSeenTimesClear = () => {
  return {
    type: MESSAGES_SEEN_TIMES_CLEAR
  };
};

export const actions = {
  messagesSeen,
  messagesSeenTimesLoadSuccess,
  messagesSeenTimesClear,
  messagesSeenTimesLoadFailure
}