//React
import React from 'react';

//Libraries
import { Pane, useTheme } from 'evergreen-ui';


const Block = React.forwardRef((props, ref) => {
  const { children, ...otherProps } = props;
  const theme = useTheme();

  return (
    <Pane
    ref={ref}
        //className="mep-block"
        background={theme.colors.white}
        borderRadius={5}
        //padding="1px"
        boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
        {...otherProps}
    >{children}</Pane>
  );
});

export default Block;
