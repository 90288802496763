//React
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Table, TextInputField } from 'evergreen-ui';

//Components
import RemovingTask from './RemovingTask';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomDialog from '../../../components/Dialog/Dialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';
import useTaskSettings from '../../../hooks/useTaskSettings';

// TODO: rename to TempTab
const OpenCloseTab = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const fridgeTasks = useSelector((state) => _.filter(current(state, 'tasks', accountId), { 'type': 'FRIDGE' }));
  const probeTasks = useSelector((state) => _.filter(current(state, 'tasks', accountId), { 'type': 'PROBE' }));

  const validTask = (task) => {
    if (['FRIDGE'].includes(task.type) &&
      (!task.target || !task.action)
    ) return false;

    return true;
  }
  const saveTask = (close) => {
    if (!validTask(editingTask)) {
      dispatch(actions.appMessageError('Error this task is not valid.'))
      return
    }

    if (editingTask.id) {
      dispatch(actions.tasks.updateTask(accountId, editingTask));
    }
    else {
      dispatch(actions.tasks.addTask(accountId, { ...editingTask, date: new Date() }));
    }
    close();
  };

  const {
    setEditingTask,
    editingTask,
    filterTasks,
    setRemovingTask,
    removingTask,
    onFieldChange,
    removeConfirmed,
    onFilterChange,
  } = useTaskSettings({ accountId });

  const filteredFridgeTasks = filterTasks(fridgeTasks);
  const filteredProbeTasks = filterTasks(probeTasks);

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Temperature logs</CustomHeading>
        <FilterBar
            searchPlaceholder="Search ..."
            searchOnChange={(newSearchValue) => onFilterChange('search', newSearchValue)}
        />
      </Block>

      <Block marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Fridge &amp; freezer temperature logs</CustomHeading>
          <Button appearance="primary" onClick={() => setEditingTask({ type: 'FRIDGE' })}>New task</Button>
        </Pane>
        <Table marginBottom={majorScale(2)}>
          <Table.Head>
            <Table.TextHeaderCell flex="0 1 20%">Description</Table.TextHeaderCell>
            <Table.TextHeaderCell flex="0 1 60%">Target</Table.TextHeaderCell>
            <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(filteredFridgeTasks) && (
              <Table.Row>
                <Table.TextCell padding={majorScale(1)}>No temperatures to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(filteredFridgeTasks, (item) => (
              <Table.Row key={item.id}>
                <Table.TextCell flex="0 1 20%">{item.action}</Table.TextCell>
                <Table.TextCell flex="0 1 60%">{item.target}</Table.TextCell>
                <Table.Cell>
                  <IconWrapper name="edit" appearance="clickable" onClick={() => setEditingTask(item)} />
                </Table.Cell>
                <Table.Cell>
                  <IconWrapper icon="trash" intent="danger" onClick={() => setRemovingTask(item)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>

      <Block>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
            padding={majorScale(2)}
        >
          <CustomHeading level="4" flex="1 0 auto">Probe temperature logs</CustomHeading>
          <Button appearance="primary" onClick={() => setEditingTask({ type: 'PROBE' })}>New task</Button>
        </Pane>
        <Table marginBottom={majorScale(2)}>
          <Table.Head>
            <Table.TextHeaderCell flex="0 1 20%">Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell>Delete</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(filteredProbeTasks) && (
              <Table.Row>
                <Table.TextCell padding={majorScale(1)}>No probe tasks to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(filteredProbeTasks, (item) => (
              <Table.Row key={item.id}>
                <Table.TextCell flex="0 1 20%">{item.action}</Table.TextCell>
                <Table.Cell>
                  <IconWrapper name="edit" appearance="clickable" onClick={() => setEditingTask(item)} />
                </Table.Cell>
                <Table.Cell>
                  <IconWrapper name="trash" appearance="danger" onClick={() => setRemovingTask(item)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>

      <CustomDialog
          isOpen={!!editingTask && ['OPEN','CLOSE'].includes(editingTask.type)}
          title={(editingTask.id) ? 'Update Task' : 'Add New Task'}
          confirmLabel="Save"
          onClose={() => setEditingTask(false)}
          onConfirm={saveTask}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="TO DO"
              value={editingTask.action || ''}
              onChange={(e) => onFieldChange('action', e.target.value)}
          />
        </Pane>
      </CustomDialog>

      <CustomDialog
          isOpen={!!editingTask && ['FRIDGE','PROBE'].includes(editingTask.type)}
          title={(editingTask.id) ? 'Update Task' : 'Add New Task'}
          confirmLabel="Save"
          onClose={() => setEditingTask(false)}
          onConfirm={saveTask}
      >
        <Pane marginBottom={majorScale(2)}>
          <TextInputField
              required
              label="Description"
              value={editingTask.action || ''}
              onChange={(e) => onFieldChange('action', e.target.value)}
          />
          {editingTask.type === 'FRIDGE' && (
            <TextInputField
              required
              label="Target"
              value={editingTask.target || ''}
              onChange={(e) => onFieldChange('target', e.target.value)}
            />
          )}
        </Pane>
      </CustomDialog>

      <RemovingTask
        removingTask={removingTask}
        setRemovingTask={setRemovingTask}
        removeConfirmed={removeConfirmed}
      />
    </React.Fragment>
  );
};

OpenCloseTab.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default OpenCloseTab;
