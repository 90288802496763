const MODIFIERS_LOAD_SUCCESS = 'MODIFIERS_LOAD_SUCCESS';
const MODIFIERS_LOAD_FAILURE = 'MODIFIERS_LOAD_FAILURE';
const MODIFIERS_CLEAR = 'MODIFIERS_CLEAR';
const ADD_MODIFIER = 'ADD_MODIFIER';
const UPDATE_MODIFIER = 'UPDATE_MODIFIER';
const REMOVE_MODIFIER = 'REMOVE_MODIFIER';

export const actionTypes = {
  MODIFIERS_LOAD_SUCCESS,
  MODIFIERS_LOAD_FAILURE,
  MODIFIERS_CLEAR,
  ADD_MODIFIER,
  UPDATE_MODIFIER,
  REMOVE_MODIFIER
};


const modifiersLoadSuccess = (payload, account) => {
  return {
    type: MODIFIERS_LOAD_SUCCESS,
    payload,
    account
  };
};

const modifiersLoadFailure = (error) => {
  return {
    type: MODIFIERS_LOAD_FAILURE,
    error
  };
};

const modifiersClear = () => {
  return {
    type: MODIFIERS_CLEAR
  };
};

const addModifier = (accountId, modifierInfo, callback) => {
  return {
    type: ADD_MODIFIER,
    accountId,
    modifierInfo,
    callback,
  };
};

const updateModifier = (accountId, modifierInfo, callback) => {
  return {
    type: UPDATE_MODIFIER,
    accountId,
    modifierInfo,
    callback,
  };
};

const removeModifier = (accountId, modifierId) => {
  return {
    type: REMOVE_MODIFIER,
    accountId,
    modifierId,
  };
};

export const actions = {
  modifiersLoadSuccess,
  modifiersLoadFailure,
  modifiersClear,
  addModifier,
  updateModifier,
  removeModifier
};
