//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import { majorScale, Tablist, Pane, TextInputField, Alert, Text, UnorderedList, ListItem } from 'evergreen-ui';

//Components
import IntegrationsTab from './AccountEditComponents/IntegrationsTab';
import AreasTab from './AccountEditComponents/AreasTab';
import DetailsTab from './AccountEditComponents/DetailsTab';
//import ProductsEdit from './AccountEditComponents/ProductsEdit';
import FinancialYearTab from './AccountEditComponents/FinancialYearTab';
import BillingTab from './AccountEditComponents/BillingTab';
import IconWrapper from '../../components/Icons/Icons';
import PermissionChecker from '../../components/PermissionChecker/PermissionChecker';
import CustomDialog from '../../components/Dialog/Dialog';
import { Block, Button, Tab } from '../../components/ui';

//Files
import useAuth from '../../hooks/useAuth';
import { actions } from '../../store/actions';
import { accountTypes } from '../../utils/constants';


const AccountEdit = ({ accountId }) => {
  const { locationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const activeAccountId = locationId || accountId;
  const searchParams = new URLSearchParams(location.search);
  const tabFromURL = searchParams.get('tab') || 'details';

  const { isOwner } = useAuth(accountId);
  const accountUsers = useSelector((state) => 
    _.orderBy(
      state.accountUsers[accountId],
      (user) => _.lowerCase(user.email)
    )
  );

  const account = useSelector((state) => {
    if (!_.isEmpty(state.accounts)) {
      return (state.accounts[activeAccountId]);
      }
    return null;
  });

  const userAccounts = useSelector((state) => (state.accounts));
  const userProfile = useSelector((state) => state.profile);

  const [currentTab, setCurrentTab] = useState(tabFromURL || 'details');
  const [accountInfo, setAccountInfo] = useState(account || {});
  const [newUserEmail, setNewUserEmail] = useState(false);
  const [removingUser, setRemovingUser] = useState(false);
  const [errors, setErrors] = useState({});

  const isLocation = (account && account.type === accountTypes.LOCATION);
  const isDepartment = (account && (!account.type || account.type === accountTypes.DEPARTMENT));

  // Update the URL when tab changes
  const changeTab = (newTab) => {
    setCurrentTab(newTab);
    searchParams.set('tab', newTab);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  // Sync internal state with URL changes
  useEffect(() => {
    setCurrentTab(tabFromURL);
  }, [tabFromURL]);

  // Update local state when account changes
  useEffect(() => {
    if (account && account.id) {
      setAccountInfo(account);
    }
  }, [account]);

  const onFieldChange = (field, newValue) => {
    setAccountInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const doSave = () => {
    dispatch(actions.accounts.accountUpdate(accountInfo));
  };

  //TODO: Need to create action for USERTYPES
  const addUser = () => {
    if (!!newUserEmail) {
      dispatch(actions.accountUsers.addUser({ email: newUserEmail, account: accountId }));
      setNewUserEmail(false);
    }
  };

  const confirmRemoveUser = (userInfo) => {
    setRemovingUser(userInfo);
  };

  const removeUser = (userId) => {
    dispatch(actions.accountUsers.removeUser(userId));
  };

  if (_.isEmpty(account)) {
    return <Text>No account data available.</Text>;
  }

  return (
    <>
      <Tablist
          display="flex"
          flexShrink={0}
          overflowX="auto"
          paddingBottom={majorScale(2)}
      >
        <Tab id="details" onSelect={() => changeTab('details')} isSelected={currentTab === 'details'}>
          Details
        </Tab>
        {isOwner() && account.type && [accountTypes.DEPARTMENT, accountTypes.OWNER].includes(account.type) && (
          <>
            {/*<PermissionChecker requiredPermission="layout">
                  <Tab isSelected={(currentTab === 'layout')} onSelect={() => setCurrentTab('layout')}>Layout</Tab>
            </PermissionChecker>*/}
            <PermissionChecker requiredPermission="integrations">
              <Tab isSelected={(currentTab ==='integrations')} onSelect={() => setCurrentTab('integrations')}>Integrations</Tab>
            </PermissionChecker>
            <PermissionChecker requiredPermission="financialAccount">
            <Tab isSelected={(currentTab === 'financial-year')} onSelect={() => setCurrentTab('financial-year')}>Financial year</Tab>
            </PermissionChecker>
            <Tab isSelected={(currentTab === 'billing')} onSelect={() => setCurrentTab('billing')}>Billing</Tab>
          </>
        )}
        {isLocation && (
          <Tab isSelected={(currentTab === 'areas')} onSelect={() => setCurrentTab('areas')}>Areas</Tab>
        )}
        {!isDepartment && (
          isLocation ? (
            <PermissionChecker requiredPermission="manageLocationUsers">
              <Tab isSelected={(currentTab === 'users')} onSelect={() => setCurrentTab('users')}>Users</Tab>
            </PermissionChecker>
          ) : (
            <Tab isSelected={(currentTab === 'users')} onSelect={() => setCurrentTab('users')}>Users</Tab>
          )
        )}
      </Tablist>

      {/*currentTab === 'layout' && (
        <Tablist
            display="flex"
            flexShrink={0}
            overflowX="auto"
            paddingBottom={majorScale(2)}
        >
          <Tab isSelected={(layoutTab === 'groups')} onSelect={() => setLayoutTab('groups')}>Groups</Tab>
          <Tab isSelected={(layoutTab === 'departments')} onSelect={() => setLayoutTab('departments')}>Departments</Tab>
          <Tab isSelected={(layoutTab === 'areas')} onSelect={() => setLayoutTab('areas')}>Areas</Tab>
        </Tablist>
      )*/}

      <Block padding={majorScale(2)} marginBottom={majorScale(2)}>
        {!isOwner() && 
          <Alert appearance="card" intent="warning" marginBottom={majorScale(2)}>
            <Text>Only the account owner can update information on this page.</Text>
          </Alert>
        }
        {currentTab === 'details' && (
          <DetailsTab
            accountInfo={accountInfo}
            onFieldChange={onFieldChange}
            isOwner={isOwner}
            isDepartment={isDepartment}
            doSave={doSave}
          />
        )}
        {currentTab === 'users' && (
          <Pane>
            <UnorderedList listStyle="none" margin={0} padding={0} size={500}>
              {accountUsers.map((userInfo) => (
                <ListItem key={userInfo.id} padding={majorScale(1)} display="flex" alignItems="center">
                  <Text flex="1 0 auto">{userInfo.email}</Text>
                  {isOwner() && <IconWrapper name="banCircle" appearance="danger" onClick={() => confirmRemoveUser(userInfo)} />}
                </ListItem>
              ))}
            </UnorderedList>
            {isOwner() && (
              <Pane>
                <TextInputField
                    label="New User"
                    value={newUserEmail || ''}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                />
                <Button appearance="primary" onClick={addUser}>Add</Button>
              </Pane>
            )}
          </Pane>
        )}
        {currentTab === 'areas' && (
          <AreasTab
            account={account}
            isOwner={isOwner}
            userAccounts={userAccounts}
            setErrors={setErrors}
            userProfile={userProfile}
            errors={errors}
          />
        )}
        {/*currentTab === 'layout' && (
          <ProductsEdit
            accountId={accountId}
            account={account}
            setErrors={setErrors}
            errors={errors}
            accountInfo={accountInfo}
            doSave={doSave}
            onFieldChange={onFieldChange}
            userProfile={userProfile}
            userAccounts={userAccounts}
            currentTab={layoutTab}
            setCurrentTab={setLayoutTab}
          />
        )*/}
         {currentTab === 'financial-year' && (
          <FinancialYearTab
            accountId={accountId}
            account={account}
            setErrors={setErrors}
            errors={errors}
          />
        )}
        {currentTab === 'billing' && (
          <BillingTab
            accountId={accountId}
            account={account}
            setErrors={setErrors}
            errors={errors}
          />
        )}
        {currentTab === 'integrations' && (
          <IntegrationsTab
            accountId={accountId}
            account={account}
            isOwner={isOwner}
            setErrors={setErrors}
            errors={errors}
            accountInfo={accountInfo}
            doSave={doSave}
            onFieldChange={onFieldChange}
          />
        )}
      </Block>

      <CustomDialog
          isOpen={!!removingUser}
          title="Remove User"
          intent="danger"
          confirmLabel="Remove User"
          onConfirm={() => {
            removeUser(removingUser.id);
            setRemovingUser(false);
          }}
          onClose={() => setRemovingUser(false)}
      >
        <Pane marginBottom={majorScale(2)}>
        <Text>Are you sure you wish to remove {removingUser.email} from this account?</Text>
        </Pane>
      </CustomDialog>
    </>
  );
};

AccountEdit.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default AccountEdit;
