import { takeEvery, put, call, select } from 'redux-saga/effects';
//import { navigate } from 'hookrouter';
import { addSupplier, removeSupplier, updateSupplier, toggleArchiveSupplier, processSuppliers } from '../firebase/suppliers';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* suppliersSaga() {
  yield takeEvery(actionTypes.suppliers.ADD_SUPPLIER, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addSupplier, action.supplierInfo, accountId);
      yield put(actions.appMessageSuccess('Supplier Added'));
      //yield call(navigate, '/suppliers');
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier could not be added'));
    }
  });
  yield takeEvery(actionTypes.suppliers.IMPORT_SUPPLIER, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(addSupplier, action.supplierInfo, accountId);
      yield call(action.callback);
      yield put(actions.appMessageSuccess('Supplier requested'));
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier could not be requested'));
    }
  });
  yield takeEvery(actionTypes.suppliers.SUPPLIERS_LOAD_SUCCESS, function* (action) {
    try {
      //console.log('Processing suppliers for account:', action.account);
      const processed = yield call(processSuppliers, action.payload, action.account);
      //console.log('Processed suppliers:', processed);
      if (!processed) return;
      
      yield put(actions.suppliers.suppliersLoadProcessed(processed, action.account));
    }
    catch (err) {
      console.log(err,'error load supplier')
      yield put(actions.appMessageError('Suppliers could not be loaded'));
    }
  });
  yield takeEvery(actionTypes.suppliers.REMOVE_SUPPLIER, function* (action) {
    try {
      const accountId = yield select(activeAccount);
      yield call(removeSupplier, action.supplierId, accountId);
      yield put(actions.appMessageSuccess('Supplier Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier could not be removed'));
    }
  });
  yield takeEvery(actionTypes.suppliers.TOGGLE_ARCHIVE_SUPPLIER, function* (action) {
    const { id, ...supplierInfo } = action.supplierInfo;
    try {
      const accountId = yield select(activeAccount);
      yield call(toggleArchiveSupplier, id, supplierInfo, accountId);
      yield put(actions.appMessageSuccess(`Supplier ${supplierInfo.archive ? 'un': ''}archived`));
    }
    catch (err) {
      yield put(actions.appMessageError(`Supplier could not be ${supplierInfo.archived ? 'un': ''}archived`));
    }
  });
  yield takeEvery(actionTypes.suppliers.UPDATE_SUPPLIER, function* (action) {
    const { id, ...supplierInfo } = action.supplierInfo;
    try {
      const accountId = yield select(activeAccount);
      yield call(updateSupplier, id, supplierInfo, accountId);
      yield put(actions.appMessageSuccess('Supplier Updated'));
      //yield call(navigate, '/suppliers');
    }
    catch (err) {
      yield put(actions.appMessageError('Supplier could not be updated'));
    }
  });
};
