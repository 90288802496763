//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Libraries
import { majorScale, minorScale, Pane, Paragraph, Table, UnorderedList, ListItem } from 'evergreen-ui';
import _ from 'lodash';

//Components
import FilterBar from '../../components/FilterBar/FilterBar';
import Button from '../../components/ui/Button/Button';
import PermissionChecker from '../../components/PermissionChecker/PermissionChecker';

//Files
import { actions } from '../../store/actions';
import { getSupplierAccounts } from '../../store/firebase/suppliers';
import { filterItems, generateSearchFieldsFn } from '../../utils/functions';
import { current } from '../../utils/selectors';


const RequestSupplier = ({callClose}) => {
  const dispatch = useDispatch();
  const supplierList = useSelector((state) =>  current(state, 'suppliers'));
  const [requesting, setIsRequesting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierAccounts, setSupplierAccounts] = useState(null);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const getAccounts = async () => {
      setIsLoading(true);
      const accounts = await getSupplierAccounts();
      const suppliers = accounts?.docs.map(account => {
        return { ...account.data(), id: account.id };
      })
      setSupplierAccounts(suppliers);
      setIsLoading(false);
    }
    getAccounts();
  }, [setSupplierAccounts, setIsLoading]);

  const getSupplierStatus = (account) => {
    const supplierListAccount = _.find(supplierList, (item) => item.name === account.name && item.parent === account.id);
    return supplierListAccount ?
           supplierListAccount.status :
           'NONE'
  }

  const requestSupplier = (account) => {
    const supplier = {
      name: account.name,
      type: 'SLAVE',
      parent: account.id,
      status: 'REQUESTED'
    }
    setIsRequesting((prev) => prev.push(account.id));
    const callback = () => setIsRequesting((prev) => _.without(prev, account.id));
    dispatch(actions.suppliers.importSupplier(supplier, callback))
  }

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };

  const sort = (items, sortOptions = {}) => {
    const { field, dir } = sortOptions;
    if (!field) {
      return items;
    }
    return _.orderBy(items, field, dir);
  };

  const rowItems = sort(filterItems(supplierAccounts, filters), { field: 'name', dir: 'asc' });

  return (
    <Pane>
      <UnorderedList>
        <ListItem>
          <Paragraph marginBottom={minorScale(2)}>Request access to your suppliers’ databases by clicking on the request button. (Please allow up to 24 hours for your request to be accepted.).</Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph marginBottom={minorScale(2)}>Cannot find one of your suppliers? <a href="https://www.misenplace.ai/supplier-enquiry" target='_blank'>Fill in this form</a> and let us do the rest.</Paragraph>
        </ListItem>
        <PermissionChecker requiredPermission='addSupplierManually'>
        <ListItem>
          <Paragraph marginBottom={majorScale(2)}>Want to add a supplier without accessing Misenplace's database? <Link to='/suppliers/add'>Add a supplier manually</Link></Paragraph>
        </ListItem>
        </PermissionChecker>
      </UnorderedList>
      <FilterBar
          searchPlaceholder="Search for a supplier"
          searchOnChange={searchOnChange}
          marginY={majorScale(2)}
      />
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>
            Supplier name
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>
            Status
          </Table.TextHeaderCell>
        </Table.Head>
      </Table>
      <Table.Body>
        {isLoading && (
          <Table.Row>
            <Table.TextCell>Loading suppliers...</Table.TextCell>
          </Table.Row>
        )}
        {!isLoading && rowItems?.length > 0 && rowItems.map(account => {
          const status = getSupplierStatus(account);
          const isRequesting = _.includes(requesting, account.id);
          return (
            <Table.Row key={account.id}>
              <Table.TextCell>{account.name}</Table.TextCell>
              {isRequesting && (
                <Table.TextCell>Requesting...</Table.TextCell>
              )}
              {!isRequesting && status === 'NONE' && (
                <Table.Cell>
                  <Button 
                    onClick={() => requestSupplier(account)}
                    appearance="primary"
                  >
                    Request
                  </Button>
                </Table.Cell>
              )}
              {!isRequesting && status !== 'NONE' && (
                <Table.TextCell>{status}</Table.TextCell>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Pane>
  )
}

export default RequestSupplier;
