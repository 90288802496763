//React
import React, { useEffect, useState } from 'react';

//Libraries
import { Pane, majorScale, Paragraph, Spinner } from 'evergreen-ui';
import moment from 'moment';
import _ from 'lodash';

//Components
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';
import IconWrapper from '../../components/Icons/Icons';


const SalesImportAPI = ({ setSelectedFile, handleFileUpload, selectedFile, doImport, parsedData, setParsedData, locationAccountId }) => {
    const [fetchedFiles, setFetchedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchFiles = async () => {
        const accountId = locationAccountId;
        setIsLoading(true);
      
        try {
          const response = await fetch(
            'https://us-central1-miseenplace-online.cloudfunctions.net/salesImportOne?action=listFiles',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ accountId }),
              mode: 'cors',
            }
          );
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
          // Filter out the unwanted files
          const filteredFiles = data.files.filter(file => 
              file.name !== 'Archived_Misenplace_Sales' && 
              file.name !== 'Archived_POS_Sales' && 
              file.name !== 'Template_Sales_Import'
          );
          setFetchedFiles(filteredFiles);
      } catch (error) {
          console.error('Error fetching files:', error);
      } finally {
          setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchFiles();
    }, []);

    const handleFileSelect = (file) => {
      setSelectedFile(file);
    };

    const clearImportData = () => {
      setParsedData(false);
    };

    const fileHeaders = [
      //{ label: 'Period start', field: 'importDate', type: 'custom', width: 3 },
      { label: 'File Name', field: 'name', type: 'text', width: 6 },
      //{ label: 'MIME Type', field: 'mimeType', type: 'text', width: 3 },
      //{ label: 'Period', field: 'period', type: 'custom', width: 5 },
      { label: 'Modified Time', field: 'modifiedTime', type: 'custom', width: 4 },
      { label: 'Select', field: 'select', type: 'action', icon: 'add', buttonProps: { appearance: 'clickable' }, width: 1 },
    ];

    const matchedHeaders = [
      { label: 'Name', field: 'name', type: 'text', width: 4 },
      { label: 'PLU', field: 'plu', type: 'text', width: 4 },
      { label: 'Qty', field: 'qty', type: 'text', width: 4 },
    ];

    const unmatchedHeaders = [
      { label: 'PLU', field: 'plu', type: 'text', width: 4 },
      { label: 'Qty', field: 'qty', type: 'text', width: 4 },
    ];

    const truncateFileName = (name, maxLength = 40) => {
      if (name.length > maxLength) {
        return name.substring(0, maxLength - 3) + '...';
      }
      return name;
    };

    const renderRow = (file) => ({
      ...file,
      name: truncateFileName(file.name),
      modifiedTime: moment(file.modifiedTime).format('YYYY-MM-DD HH:mm:ss'),
      select: (
        <IconWrapper 
          name= 'add'
          onClick={() => handleFileSelect(file)} />
      ),
    });

    return (
      <Block marginBottom={majorScale(2)}>
        {isLoading ? (
          <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
          </Pane>
        ) : fetchedFiles.length > 0 ? (
          <Pane flex="1" overflowY="auto" display="flex" flexDirection="column" maxHeight={420}>
            <Pane display='flex' alignItems='center' justifyContent='space-between'>
              <CustomHeading level="3" padding={majorScale(2)}>Upload file from GoogleDrive</CustomHeading>
              <Button
                  appearance="primary"
                  onClick={handleFileUpload}
                  disabled={!selectedFile}
                  maxWidth="600px"
                  marginRight={majorScale(2)}
                >
                  Upload {selectedFile?.name ? selectedFile?.name : 'file'}
              </Button>  
            </Pane>
            <DataTable
              borderRadius={5}
              items={fetchedFiles.map(renderRow)}
              headers={fileHeaders}
              listHeight={400}
              onSelect={(file) => handleFileSelect(file)}
            />
          </Pane>
        ) : (
          <Paragraph padding={majorScale(2)}>No files to display</Paragraph>
        )}

        <CustomDialog
          isOpen={!!parsedData}
          title="Confirm Import"
          onClose={() => clearImportData()}
          onConfirm={() => {
            doImport(parsedData, 'userFromGD');
            clearImportData();
          }}
        >
          <Pane display="flex" flexDirection="column">
            <Paragraph marginBottom={majorScale(2)}>Matched Items</Paragraph>
            <DataTable
              borderRadius={5}
              items={parsedData ? parsedData.items : []}
              headers={matchedHeaders}
            />
            <Paragraph marginTop={majorScale(4)} marginBottom={majorScale(2)}>Unmatched Items</Paragraph>
            <DataTable
              borderRadius={5}
              items={parsedData ? parsedData.unmatched : []}
              headers={unmatchedHeaders}
            />
          </Pane>
        </CustomDialog>
      </Block>
    );
};

export default SalesImportAPI;