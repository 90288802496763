import { getCollectionRef } from './common';

export const addIngredientCategory = (ingredientCategory, accountId) => {
  const ingredientCategoriesRef = getCollectionRef('ingredientCategories', accountId);
  return ingredientCategoriesRef.add(ingredientCategory);
};

export const removeIngredientCategory = (ingredientCategoryId, accountId) => {
  const ingredientCategoriesRef = getCollectionRef('ingredientCategories', accountId);
  return ingredientCategoriesRef.doc(ingredientCategoryId).delete();
};

export const updateIngredientCategory = (ingredientCategoryId, ingredientCategory, accountId) => {
  const ingredientCategoriesRef = getCollectionRef('ingredientCategories', accountId);
  return ingredientCategoriesRef.doc(ingredientCategoryId).update(ingredientCategory);
};
