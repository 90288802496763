//React
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

//Libraries
// import PropTypes from 'prop-types';
import _ from 'lodash';

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { current } from '../../../utils/selectors';
import { TAB_INGREDIENTS } from '../../../utils/constants';

const StocktakeFilters = ({ children, accountId, recipeCat, initTab }) => {
  const categoryOptions = useSelector((state) => _.sortBy(
    _.concat(
      [{ label: '<No Category>', value: 'uncategorized' }],
      _.map(current(state, 'ingredientCategories', accountId), (cat) => ({ label: cat.name, value: cat.id }))
    ),
    'label'
  ));
  const [filters, setFilters] = useState({});
  const [recipeSearch, setRecipeSearch] = useState('');
  const [currentTab, setCurrentTab] = useState(initTab || TAB_INGREDIENTS);
  const [category, setCategory] = useState(filters['category'] || ''/*categoryOptions[0].value*/);
  const [recipeCategory, setRecipeCategory] = useState(recipeCat);

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  const filterFields = [
    { label: 'Category', name: 'category', options: categoryOptions }
  ];

  const handleSearch = useCallback(_.debounce(searchOnChange, 600),[]);
  const handleRecipeSearch = useCallback(_.debounce(setRecipeSearch, 600),[]);

  const filteredCategories = (filters['category']) ? _.filter(categoryOptions, { 'value': filters['category'] }) : categoryOptions;
  
  return (
    children({
      handleSearch,
      handleRecipeSearch,
      filters,
      recipeSearch,
      updateFilters,
      categoryOptions,
      filterFields,
      filteredCategories,
      category,
      setCategory,
      currentTab,
      setCurrentTab,
      recipeCategory,
      setRecipeCategory
    })
  )
}

export default StocktakeFilters;
