import { monthsArray } from "./financials";


export const buildBudgetsErrors = (budgetInfo) => {
  return monthsArray.reduce((errors, num) => {
    const pErrors = {};
    const periodKey = `period_${num}`;
    const period = budgetInfo[periodKey];
    if (!period) pErrors.period = 'Required.'
    else {
      const {value, margin} = period;
      if (value && !(parseFloat(value) >= 0)) pErrors.value = 'Required';
      if (margin && !(parseInt(margin) >= 0)) pErrors.margin = 'Required';
    }
    errors[periodKey] = pErrors;
    return errors;
  }, []);
}

export const buildInitBudgetData = (year) => {
  return monthsArray.reduce((data, num) => {
    const periodKey = `period_${num}`;
    const period = {};
    data[periodKey] = period;
    return data;
  }, { year })
}

export const budgetSum = (budget = {}) => {
  return monthsArray.reduce((total, num) => {
    const periodKey = `period_${num}`;
    const value = budget[periodKey] ? (parseFloat(budget[periodKey].value) || 0) : 0;
    return total += value;
  }, 0);
}
