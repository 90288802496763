import React from 'react';
import { useSelector } from 'react-redux';
import { accountTypes } from '../../utils/constants';

import GroupSelect from '../../pages/GroupSelect/GroupSelect';
import NotFound from '../../pages/NotFound/NotFound';
import Supplier from '../../pages/Suppliers/Supplier';


const Dashboard = () => {
  const account = useSelector((state) => {
    const ca = state.currentAccount;
    if (state.accounts[ca]) {
      return state.accounts[ca];
    }
    return false;
  });

  if (account) {
    if (account.type === accountTypes.OWNER || account.type === accountTypes.LOCATION) {
      return (
        <GroupSelect />
      );
    }

    if (account.type === accountTypes.SUPPLIER) {
      return <Supplier />
    }
  }

  return (<NotFound />);
};

export default Dashboard;
