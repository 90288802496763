//React
import React from 'react';
import { useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Table } from 'evergreen-ui';

//Components
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { currency } from '../../../utils/format';
import { colors } from '../../../utils/constants';
import { current } from '../../../utils/selectors';
import { calcPercentage } from '../../../utils/functions';


const SalesByCategory = ({ accountId, salesItems }) => {
  const recipeCategoryMap = useSelector((state) => _.keyBy(
    current(state, 'recipes', accountId),
    'id'
  ));

  const modifiersMap = useSelector((state) => _.keyBy(
    current(state, 'modifiers', accountId),
    'id'
  ));

  const categoriesMap = useSelector((state) => _.keyBy(
    current(state, 'recipeCategories', accountId),
    'id'
  ));

  const totalSales = _.reduce(salesItems, (total, item) => {
    return total + (item.totalnet);
  }, 0);

  const categorySales = _.reverse(
    _.sortBy(
      _.reduce(salesItems, (categories, item) => {
        // Fetch the recipe or modifier based on the item type
        const recipe = item.type === 'modifier' ? modifiersMap[item.modifierId] : recipeCategoryMap[item.recipeId];
        // Get the category ID from the recipe or modifier
        const categoryId = recipe ? recipe.categoryId : null;
        // Fetch the category name using the category ID
        const recipeCategory = categoryId && categoriesMap[categoryId] ? categoriesMap[categoryId].name : '[Uncategorised]';

        if (!_.hasIn(categories, recipeCategory)) {
          categories[recipeCategory] = { name: recipeCategory, total: 0 };
        }
        categories[recipeCategory].total += item.totalnet;

        return categories;
      }, {}),
      'total'
    )
  );


  return (
    <>
      <CustomHeading
          level="4"
          marginBottom={majorScale(4) + 30}
          paddingX={majorScale(2)}
      >Categories Sales Report</CustomHeading>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flex="3">Category Name</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="1">Sales %</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="1" textAlign="right">Net</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height={48 * 5}>
          {_.isEmpty(categorySales) && (
            <Table.Row>
              <Table.TextCell>No items to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(categorySales, (cat) => (
            <Table.Row key={_.kebabCase(cat.name)}>
              <Table.TextCell flex="3">{cat.name}</Table.TextCell>
              <Table.TextCell flex="1">{calcPercentage(cat.total, totalSales)}%</Table.TextCell>
              <Table.TextCell
                  flex="1"
                  textAlign="right"
                  textProps={{ color: colors.lightTeal }}
              >£ {currency(cat.total)}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

SalesByCategory.propTypes = {
  accountId: PropTypes.string.isRequired,
  salesItems: PropTypes.arrayOf(
    PropTypes.shape({
      recipeId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      qty: PropTypes.number.isRequired,
      totalgross: PropTypes.number.isRequired,
    }),
  ),
};

export default SalesByCategory;
