//React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import { minorScale, Pane, TextInputField, Combobox, TextInput, FormField, majorScale } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import CustomDialog from '../../../components/Dialog/Dialog';
import IconWrapper from '../../../components/Icons/Icons';
import TabNavigation from '../../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../../components/ui/Tab/Tab';

//Files
import { generateSearchFieldsFn, getRecipeById, getIngredientById, recipeYieldPerPortion } from '../../../utils/functions';
import { filterActiveIngredients } from '../../../utils/ingredients';
import { filterActiveRecipes } from '../../../utils/recipes';
import { current } from '../../../utils/selectors';

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'actionField', action: 'select', width: 5},
];


const EditIngredientDialog = ({ accountId, onClose, isShown, onConfirm, existingIngredient }) => {
  const ingredientList = useSelector((state) => filterActiveIngredients(current(state, 'ingredients', accountId)));
  const recipeList = useSelector((state) => filterActiveRecipes(current(state, 'recipes', accountId)));
  const [currentTab, setCurrentTab] = useState('ingredient');
  const [selectedIng, selectIngredient] = useState(false);
  const [selectedRecipe, selectRecipe] = useState(false);
  const [filters, setFilters] = useState({});
  const [newIng, setNewIng] = useState({});

  useEffect(() => {
    if (_.isEmpty(newIng) && existingIngredient && existingIngredient.item) {
      const item = existingIngredient.item;
      if (item.type) {
        setCurrentTab('recipe');
        selectRecipe(getRecipeById(item.id));
      }
      else {
        setCurrentTab('ingredient');
        selectIngredient(getIngredientById(item.id));
      }
      let newIngObject = {
        quantity: item.quantity
      };
      if (item.plu) {
        newIngObject.plu = item.plu;
      }
      if (item.price) {
        newIngObject.price = item.price;
      }
      if (item.vat) {
        newIngObject.vat = item.vat;
      }
      setNewIng(newIngObject);
    }
  }, [newIng, existingIngredient]);

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name', 'category'], newSearchValue));
    }
  };

  const onFieldChange = (field, newValue) => {
    setNewIng((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const dialogConfirm = () => {
    if (currentTab === 'ingredient') {
      onConfirm({ ...newIng, name: selectedIng.name, id: selectedIng.id, ingredient: { ...selectedIng } });
    }
    else {
      onConfirm({ ...newIng, name: selectedRecipe.name, id: selectedRecipe.id, type: 'subrecipe' });
    }
    dialogClose();
  };

  const dialogClose = () => {
    setNewIng({});
    selectIngredient(false);
    selectRecipe(false);
    searchOnChange('');
    if (onClose) {
      onClose();
    }
  };

  const renderCurrentTab = () => {
    if (currentTab === 'recipe') {
      return (
        <Pane>
          <FormField label="Recipe Name" marginBottom={majorScale(2)}>
            <Combobox
                placeholder="Recipe Name"
                items={recipeList}
                itemToString={(item) => item ? item.name : ''}
                selectedItem={selectedRecipe}
                onChange={(selected) => selectRecipe(selected || false)}
                width="100%"
            />
          </FormField>
          <TextInputField
              required
              label="Quantity"
              value={newIng.quantity || ''}
              onChange={(e) => onFieldChange('quantity', e.target.value)}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Yield"
              value={selectedRecipe.yield || 1}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Yield Description"
              value={selectedRecipe.yieldDescription}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Portion"
              value={selectedRecipe.portion || 1}
              marginBottom={majorScale(2)}
          />
          <TextInputField
              disabled
              label="Yield / Portion"
              value={recipeYieldPerPortion(selectedRecipe)}
          />
          {/*<TextInputField
            //required
            label="PLU"
            value={newIng.plu || ''}
            onChange={(e) => onFieldChange('plu', e.target.value)}
            marginBottom={majorScale(2)}
          />
          <TextInputField
            label="Price"
            value={newIng.price || 0}
            onChange={(e) => onFieldChange('price', e.target.value)}
            marginBottom={majorScale(2)}
          />
          <TextInputField
            label="VAT"
            value={newIng.vat || 0}
            onChange={(e) => onFieldChange('vat', e.target.value)}
            marginBottom={majorScale(2)}
          />*/}
        </Pane>
      );
    }
    else {
      if (!selectedIng) {
        return (
          <Pane display="flex" flexDirection="column" minHeight="60vh">
            <FilterBar searchPlaceholder="Search Ingredients" searchOnChange={searchOnChange} marginBottom={majorScale(2) }/>
            <DataTable filters={filters} headers={tableHeaders} items={ingredientList} onRowSelect={(ing) => selectIngredient(ing)} />
          </Pane>
        );
      }
      else {
        return (
          <Pane>
            <FormField label="Name" marginBottom={majorScale(2)}>
              <Pane display="flex">
                <TextInput
                    disabled
                    value={selectedIng.name}
                    flex="1 1 auto"
                />
                <IconWrapper name="cross" appearance="danger" onClick={() => selectIngredient(false)} />
              </Pane>
            </FormField>
            <TextInputField
                required
                label="Quantity"
                value={newIng.quantity || ''}
                onChange={(e) => onFieldChange('quantity', e.target.value)}
                marginBottom={majorScale(2)}
            />
            <TextInputField
                disabled
                label="Unit of Measure (UOM)"
                value={selectedIng.recipeunit}
                marginBottom={majorScale(2)}
            />
            {/*<TextInputField
              required
              label="PLU"
              value={newIng.plu || ''}
              onChange={(e) => onFieldChange('plu', e.target.value)}
              marginBottom={majorScale(2)}
            />
            <TextInputField
              label="Price"
              value={newIng.price || 0}
              onChange={(e) => onFieldChange('price', e.target.value)}
              marginBottom={majorScale(2)}
            />
            <TextInputField
              label="VAT"
              value={newIng.vat || 0}
              onChange={(e) => onFieldChange('vat', e.target.value)}
              marginBottom={majorScale(2)}
            />*/}
          </Pane>
        );
      }
    }
  };


  return (
    <CustomDialog
        isOpen={isShown}
        hasHeader={false}
        intent="none"
        confirmLabel={(currentTab === 'ingredient') ? 'Add Ingredient' : 'Add SubRecipe'}
        onConfirm={dialogConfirm}
        onClose={dialogClose}
        preventBodyScrolling
        width={700}
    >
      <Pane marginBottom={majorScale(2)}>
        <Pane borderBottom="default" marginBottom={minorScale(4)}>
          <TabNavigation
              display="flex"
              flexShrink={0}
              overflowX="auto"
              paddingBottom={majorScale(2)}
          >
            <Tab isSelected={currentTab === 'ingredient'} onSelect={() => setCurrentTab('ingredient')}>Ingredient</Tab>
            <Tab isSelected={currentTab === 'recipe'} onSelect={() => setCurrentTab('recipe')}>Subrecipe</Tab>
          </TabNavigation>
        </Pane>
        {renderCurrentTab()}
      </Pane>
    </CustomDialog>
  );
};

EditIngredientDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  isShown: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  existingIngredient: PropTypes.object
};


export default EditIngredientDialog;
