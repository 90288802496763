//React
import React from 'react';

//Libraries
import { majorScale, Pane } from 'evergreen-ui';
import moment from 'moment';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Accordion from '../../../components/ui/Accordion/Accordion';

//Files
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { denormalizeIngredientQuantity, getIngredientStockUOM, 
  getOptionOrdersFromDate, getOrderDate, ingOrderAmount } from '../../../utils/functions';
import { buildIngOptions } from '../Costs';

const orderOptItem = (opt, order, ing) => {
  const item = order.items.find(i => i.ingredientId === opt.ingredientId);
  return item ? ingOrderAmount(item) : 0;
}

const buildPeriodOrders = ( orders, start, end, opt, ing ) => {
  const periodOrders = orders.filter(o => moment(getOrderDate(o)).isBetween(moment(end), moment(start), '[]'));
  const sum = periodOrders.reduce((total, order) => orderOptItem(opt, order, ing) + total,  0);
  const displaySum = denormalizeIngredientQuantity({ recipeunit: ing.recipeunit, amount: sum });
  return displaySum;
}

const buildWeekOrders = ({ orders, weekStart, weekEnd, opt, ing }) => buildPeriodOrders(orders, weekStart, weekEnd, opt, ing);
const buildPrevWeekorders = ({ orders, prevWeekStart, prevWeekEnd, opt, ing }) => buildPeriodOrders(orders, prevWeekStart, prevWeekEnd, opt, ing);
const buildDatePeriodOrders = (orders, weekStart, subtract, opt, ing) => {
  const endDate = moment(weekStart).subtract(subtract.num, subtract.unit);
  return buildPeriodOrders(orders, weekStart, endDate, opt, ing);
}
const buildMonthOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 5, unit: 'weeks'}, opt, ing);
const buildThreeMonthOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 13, unit: 'weeks'}, opt, ing);
const buildYearOrders = ({ orders, weekStart, opt, ing }) => buildDatePeriodOrders(orders, weekStart, { num: 53, unit: 'weeks'}, opt, ing);

const buildOptionProcurement = ({ opt, ing, accountId, weekStart, supplierMap, ...rest }) => {
  const orders = getOptionOrdersFromDate(accountId, opt, moment(weekStart));
  const props = { orders, weekStart, opt, ing, ...rest}
  return {
    weekOrders: buildWeekOrders(props),
    prevWeekOrders: buildPrevWeekorders(props),
    monthOrders: buildMonthOrders(props),
    threeMonthOrders: buildThreeMonthOrders(props),
    yearOrders: buildYearOrders(props),
    id: opt.id,
    supplierName: supplierMap[opt.supplierId].name
  }
}

const calcOptTotal = (item, total) => {
  for (const prop in total) {
    if (item[prop]) {
      total[prop] += item[prop];
    }
  }
  return total;
}

const setFinalZero = (total) => {
  for (const prop in totalInit) {
    if (total[prop] === 0) {
      total[prop] = '0'
    }
    else if (total[prop]) {
      total[prop] = total[prop].toFixed(2)
    }
  }
  return total;
}

const totalInit = {
  weekOrders: 0,
  prevWeekOrders: 0,
  monthOrders: 0,
  threeMonthOrders: 0,
  yearOrders: 0
}

const buildIngOptSum = (ing, uom, optProcurments) => {
  const sum = optProcurments.reduce((total, item) => calcOptTotal(item, total), { ...totalInit });
  return { ...ing, uom, ...setFinalZero(sum), opts: optProcurments.map(o => setFinalZero(o)) };
}

const buildIngOptionsOrders = ({ ing, optionList, ...rest }) => {
  const ingsOpts = buildIngOptions(ing, optionList);
  const uom = getIngredientStockUOM(ing);
  const optProcurments = ingsOpts.map(opt => buildOptionProcurement({ opt, ing, uom, ...rest }));
  return buildIngOptSum(ing, uom, optProcurments);
}

const buildProcurementList = ({ings, ...rest}) => {
  return ings.reduce((list, ing) => {
    const ingData = buildIngOptionsOrders({ ing, ...rest });
    list.push({ ...ingData, opts: null});
    list = list.concat(ingData.opts);
    return list;
  }, []);
}

const tableHeaders = [
  { label: 'Name', field: 'name', type: 'text', width: 4 },
  { label: 'Supplier', field: 'supplierName', type: 'text', width: 2 },
  { label: 'UOM', type: 'text', field: 'uom', width: 2 },
  { label: 'CW', type: 'text', field: 'weekOrders', width: 2 },
  { label: 'vs LW.', type: 'text', field: 'prevWeekOrders', width: 2 },
  { label: 'TM', type: 'text', field: 'monthOrders', width: 2 },
  //{ label: 'TL3M', type: 'text', field: 'threeMonthOrders', width: 2 },
  { label: 'TY', type: 'text', field: 'yearOrders', width: 2 }
];

const ProcurementVolumeTab = ({ ings, isMobile, categoryOpt, setCategory, isOpen, filters, ...rest }) => {
  const procurementList = buildProcurementList({ ings, ...rest });
  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt.value);

  return (
    <Accordion
      label={categoryOpt.label}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
    >
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
        >
          <DataTable
            items={procurementList}
            filters={filters}
            headers={tableHeaders}
            tableProps={{ flex: `1 0 ${TABLE_MIN_HEIGHT}px` }}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
          />
        </Pane>
    </Accordion>
  )
}

export default ProcurementVolumeTab;
