//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane } from 'evergreen-ui';

//Libraries
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { filterActiveSupplierOptions } from '../../../utils/ingredients';
import { current } from '../../../utils/selectors';
import { TABLE_MIN_HEIGHT } from '../../../utils/constants';
import { generateSearchFieldsFn } from '../../../utils/functions';


const LinkOptionDialog = ({ isShown, onConfirm, onCloseComplete, ingredientId }) => {
  const optionList = useSelector((state) => {
    const allSupplierOptions = current(state, 'supplierOptions');
    // Filter out supplier options not linked to the current ingredient and also filter out archived or deleted ones
    return filterActiveSupplierOptions(_.filter(allSupplierOptions, (opt) => opt.ingredientId !== ingredientId));
  });
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers'), 'id'));
  const ingredientMap = useSelector((state) => _.keyBy(current(state, 'ingredients'), 'id'));
  const [linkedOption, setLinkedOption] = useState(false);
  const [filters, setFilters] = useState({});

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Supplier', field: 'supplierId', format: (value) => (supplierMap[value]) ? supplierMap[value].name : '[SUPPLIER MISSING]', width: 3 },
    { label: 'UOM', field: 'uom', type: 'text', width: 2 },
    { label: 'Price', field: 'unitprice', type: 'numeric', format: 'currency', prefix: '£', width: 2 },
    { label: 'Linked to', field: 'ingredientId', type: 'text', format: (value) => (value && ingredientMap[value]) ? ingredientMap[value].name : '', width: 3 },
  ];

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };


  return (
    <>
      <CustomDialog
          isOpen={isShown || false}
          title="Link to Supplier Ingredient"
          confirmLabel="Link"
          isConfirmDisabled={!linkedOption}
          onConfirm={() => {
            onConfirm(linkedOption);
            onCloseComplete();
          }}
          onClose={() => onCloseComplete()}
          contentContainerProps={{ padding: 0 }}
          width='700px'
      >
        <FilterBar
            searchPlaceholder="Search for an Ingredient"
            searchOnChange={searchOnChange}
            marginBottom={majorScale(2)}
        />
        <Pane marginBottom={majorScale(2)}>
        <DataTable
            tableProps={{ minHeight: TABLE_MIN_HEIGHT }}
            headers={tableHeaders}
            items={optionList}
            filters={filters}
            onRowSelect={(item) => setLinkedOption(item)}
        />
        </Pane>
      </CustomDialog>
    </>
  )
};

LinkOptionDialog.propTypes = {
  isShown: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCloseComplete: PropTypes.func,
  ingredientId: PropTypes.string,
};

LinkOptionDialog.defaultProps = {
  isShown: false,
  onCloseComplete: () => {},
};

export default LinkOptionDialog;
