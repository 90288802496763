// theme.js or wherever you define your theme
import { defaultTheme, mergeTheme } from 'evergreen-ui';
import { colors } from './utils/constants'

const misenplaceTheme = {
  colors: {
    //Colours palettes
    primary100: 'rgba(214, 250, 194, 1)',
    primary70: 'rgba(214, 250, 194, 0.7)',
    primary50: 'rgba(214, 250, 194, 0.5)',
    primary30: 'rgba(214, 250, 194, 0.3)',
    primary10: 'rgba(214, 250, 194, 0.1)',
    primary160: 'rgba(86, 100, 78, 1)',
    secondary100: 'rgba(41, 51, 155, 1)',
    secondary90: 'rgba(41, 51, 155, 0.9)',
    secondary80: 'rgba(41, 51, 155, 0.8)',
    secondary70: 'rgba(41, 51, 155, 0.7)',
    secondary60: 'rgba(41, 51, 155, 0.6)',
    secondary50: 'rgba(41, 51, 155, 0.5)',
    secondary40: 'rgba(41, 51, 155, 0.4)',
    secondary30: 'rgba(41, 51, 155, 0.3)',
    secondary20: 'rgba(41, 51, 155, 0.2)',
    secondary10: 'rgba(41, 51, 155, 0.1)',
    secondary5: 'rgba(41, 51, 155, 0.05)',
    secondary160: 'rgba(16, 20, 62, 1)',
    tertiary100: 'rgba(241, 80, 37, 1)',

    //Background & text colours
    white: 'rgba(255, 255, 255, 1)',
    offwhite: 'rgba(248, 248, 248, 1)',
    beige: 'rgba(248, 245, 239, 1)',
    black80: 'rgba(0, 0, 0, 1)',
    black: 'rgba(0, 0, 0, 0.80)',
    grey1: 'rgba(239, 239, 239, 1)',
    grey2: 'rgba(104, 104, 104, 1)',

    //Feedback colours
    success: 'rgba(20, 203, 168, 1)',
    success10: 'rgba(20, 203, 168, 0.1)',
    success130: 'rgba(14, 142, 118, 1)',
    warning: 'rgba(255, 176, 32, 1)',
    warning10: 'rgba(255, 176, 32, 0.1)',
    warning130: 'rgba(179, 123, 22, 1)',
    danger: 'rgba(255, 68, 68, 1)',
    danger10: 'rgba(255, 68, 68, 0.1)',
    danger130: 'rgba(179, 48, 48, 1)',
  },

  fontFamilies: {
    display: 'Outfit, Arial, sans-serif',
    ui: 'Outfit, "Helvetica Neue", Arial, sans-serif',
  },
  fontSize: {
    default: '20px',
  },
  /*typography: {
    ...defaultTheme.typography,
    headings: {
      ...defaultTheme.typography.headings,
      h1: {
        ...defaultTheme.typography.headings.h1,
        fontSize: '32px',
        fontWeight: 600,
      },
      h2: {
        ...defaultTheme.typography.headings.h2,
        fontSize: '28px',
        fontWeight: 500,
      },
      // Similarly for h3, h4, etc.
    },
  },
  paragraph: {
    ...defaultTheme.typography.paragraph,
    marginTop: 0,
    marginBottom: '16px',  // For example, setting a bottom margin
    fontSize: '16px',     // Setting a default font size for text
    fontWeight: 400,      // And a default weight
    lineHeight: '24px',   // Example line height
  },*/
  
};

const theme = mergeTheme(defaultTheme, misenplaceTheme);
export default theme;