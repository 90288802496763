//React//
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, useTheme } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import StocktakeFilters from '../../../pages/Stocks/components/StocktakeFilters';
import StockTakeDetailIngredients from './StockTakeDetailIngredients';
import StockTakeDetalRecipes from './StockTakeDetailRecipes';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import TabNavigation from '../../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../../components/ui/Tab/Tab';


//Files
import { TAB_INGREDIENTS, TAB_RECIPES } from '../../../utils/constants';
import { calcStocktakeCost, calcStocktakeRecipeCost, getStocktakeDate, getStocktakeMoment } from '../../../utils/functions';
import { currency, shortDateDisplayFormat } from '../../../utils/format';
import { current } from '../../../utils/selectors';
import { buildLivestock, buildRecipeLive, calculateTotalWastageCost } from '../../../utils/stock';
import { useMediaQuery } from 'react-responsive';

const MAX_WIDTH = 500;

//TODO - find a way to show more depth about ingredients
//Either by allowing change of unit for each item, or have a onRowSelect that show more depth
const StockTakeDetail = () => {
  const { accountId, stockTakeId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const stocktakeDetails = useSelector((state) => _.find(current(state, 'stockTakes', accountId), { 'id': stockTakeId }));
  //console.log(stocktakeDetails, "stocktakeDetails")
  const periodEnd = getStocktakeMoment(stocktakeDetails);
  const previousStocktake = useSelector((state) => {
    return _.head(
      _.reverse(
        _.sortBy(
          _.filter(
            { ...current(state, 'stockTakes', accountId) },
            (stock) => (
              !stock.isDraft &&
              getStocktakeMoment(stock).isBefore(periodEnd)
            )
          ),
          (stocktake) => getStocktakeDate(stocktake)
        )
      )
    );
  });

  const liveStock = buildLivestock(accountId, stocktakeDetails, true, previousStocktake);
  //console.log(liveStock, "liveStock")
  const totalVarianceIngredient = _.sumBy(liveStock, (item) => item.varianceWorth);
  const totalWastageCost = calculateTotalWastageCost(accountId, stocktakeDetails, previousStocktake)
  const totalVarianceValue = totalVarianceIngredient + totalWastageCost
  //console.log(totalWastageCost, "StockDatilWastage")
  const recipeLive = buildRecipeLive(accountId, stocktakeDetails, previousStocktake);
  //console.log(recipeLive, "RecipeLive")
  const recipeCategories = _.groupBy(recipeLive, 'category');
  //const allStockTakes = useSelector((state) => current(state, 'stockTakes', accountId));
  //const [ ingredientsState, setIngredientsState] = useState(liveStock);

  if (_.isEmpty(stocktakeDetails)) {
    console.log(`No stocktake details found for ID: ${stockTakeId}`);
    return <Text>The requested stocktake could not be found.</Text>;
  }

  const archiveTableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 4 },
    { label: 'UOM', field: 'recordUOM', type: 'text', field: 'startRecordUOM', width: 2 },
    { label: 'Open', type: 'text', field: 'unitStockDisplay', width: 1 },
    { label: 'Purch.', type: 'text', field: 'orderQuantityDisplay', width: 1 },
    { label: 'Sales', type: 'text', field: 'saleQuantityDisplay', width: 1 },
    { label: 'Wast.', type: 'text', field: 'wastageQuantityDisplay', width: 1 },
    { label: 'Trans.', type: 'text', field: 'transferQuantityDisplay', width: 1 },
    { label: 'Close', type: 'text', field: 'thisStockDisplay', width: 1 },
    { label: 'Thero.', type: 'text', field: 'livestockDisplay', width: 1 },
    { label: 'Var.', type: 'text', field: 'varianceDisplay', width: 1 },
    { label: 'Value', field: 'varianceWorth', type: 'text', format: 'currency', prefix: '£', width: 2 }
  ];

  const recipeTableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 3 },
    { label: 'UOM', type: 'field', field: 'startRecordUOM', width: 2 },
    { label: 'Sales', type: 'text', field: 'saleDisplay', width: 1 },
    { label: 'Wast.', type: 'text', field: 'wasteDisplay', width: 1 },
    { label: 'Trans.', type: 'text', field: 'transferDisplay', width: 1 },
    { label: 'Close', type: 'text', field: 'closingDisplay', width: 1 },
    { label: 'Value', field: 'cost', type: 'calc', calc: (recipe) => (calcStocktakeRecipeCost(recipe)), format: 'currency', prefix: '£', width: 2 }
  ];

  return (
    <React.Fragment>
      <StocktakeFilters accountId={accountId} recipeCat={recipeLive[0] ? recipeLive[0].category : null}>
      {({
        handleSearch,
        handleRecipeSearch,
        filters,
        recipeSearch,
        updateFilters,
        filterFields,
        filteredCategories,
        setCategory,
        category,
        currentTab,
        setCurrentTab,
        setRecipeCategory,
        recipeCategory
      }) => (
        <>
          <Block
              background={theme.colors.offwhite}
              height={48}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              marginBottom={majorScale(2)}
              padding={majorScale(2)}
          >
            <IconWrapper
                is={Link}
                to={`/${accountId}/review/stocktake`}
                name="arrowLeft"
                appearance="clickable"
                marginRight={majorScale(4)}
            />
            <CustomHeading level="3">Stocktake {(stocktakeDetails) ? `- ${shortDateDisplayFormat(getStocktakeDate(stocktakeDetails))}` : ''}</CustomHeading>
          </Block>
          {_.isEmpty(stocktakeDetails) && (
            <Block padding={majorScale(2)}>
              <Text>The requested stocktake could not be found.</Text>
            </Block>
          )}
          {!_.isEmpty(stocktakeDetails) && (
            <>
            <Block flex="1 0 auto" display="flex" flexDirection="column" marginBottom={majorScale(2)} maxHeight="130px">
              <Pane display="flex" alignItems='center'>
                <Pane display="flex" padding={majorScale(2)} maxWidth={MAX_WIDTH} width='100%'>
                  <CustomHeading level="4" marginRight={majorScale(2)}>Stock Value</CustomHeading>
                  <Text color={theme.colors.tertiary100}>£ {currency(calcStocktakeCost(stocktakeDetails))}</Text>
                </Pane>
                <Pane display="flex" marginBottom={majorScale(2)} padding={majorScale(2)} maxWidth={MAX_WIDTH} width='100%'>
                  <CustomHeading level="4" marginRight={majorScale(2)}>Variance Value</CustomHeading>
                  <Text color={theme.colors.tertiary100}>£ {currency(totalVarianceValue)}</Text>
                </Pane>
                <Pane display="flex" marginBottom={majorScale(2)} padding={majorScale(2)} maxWidth={MAX_WIDTH} width='100%'>
                  <CustomHeading level="4" marginRight={majorScale(2)}>Variance on Ingredient</CustomHeading>
                  <Text color={theme.colors.tertiary100}>£ {currency(totalVarianceIngredient)}</Text>
                </Pane>
                <Pane display="flex" marginBottom={majorScale(2)} padding={majorScale(2)} maxWidth={MAX_WIDTH} width='100%'>
                  <CustomHeading level="4" marginRight={majorScale(2)}>Recipe Wastage %</CustomHeading>
                  <Text color={theme.colors.tertiary100}>£ {currency(totalWastageCost)}</Text>
                </Pane>
              </Pane>
              <TabNavigation
                display="flex"
                //flexShrink={0}
                overflowX="auto"
                marginLeft={majorScale(2)}
                marginBottom={majorScale(2)}
                maxWidth="40%"
              >
                <Tab
                  isSelected={currentTab === TAB_INGREDIENTS}
                  onSelect={() => setCurrentTab(TAB_INGREDIENTS)}
                >Ingredients</Tab>
                <Tab
                  isSelected={currentTab === TAB_RECIPES}
                  onSelect={() => setCurrentTab(TAB_RECIPES)}
                  disabled={recipeLive.length === 0}
                >Recipes</Tab>
              </TabNavigation>
              </Block>
              <Block marginBottom={majorScale(2)}>
              {
                currentTab === TAB_INGREDIENTS &&
                <Pane flex="1 0 0" display="flex" flexDirection="column" height={450} marginBottom={majorScale(4)}>
                  <FilterBar
                    filterFields={filterFields}
                    filters={filters}
                    setFilter={updateFilters}
                    searchPlaceholder="Search Ingredients"
                    searchOnChange={handleSearch}
                    margin={majorScale(2)}
                  />
                  {
                    _.map(filteredCategories, (categoryOpt) =>
                      <StockTakeDetailIngredients
                        key={categoryOpt.value}
                        categoryOpt={categoryOpt}
                        filters={filters}
                        tableHeaders={archiveTableHeaders}
                        mobileTableHeaders={archiveTableHeaders}
                        isOpen={(category === categoryOpt.value) || !!(filters.search)}
                        setCategory={setCategory}
                        isMobile={isMobile}
                        ingredients={liveStock}
                        //ingOnStockUOMChange={ingOnStockUOMChange}
                      />
                    )
                  }
                </Pane>
              }
              {
                currentTab === TAB_RECIPES &&
                <Pane flex="1 0 0" display="flex" flexDirection="column" minHeight={450}>
                  <FilterBar
                    searchPlaceholder="Search Recipes"
                    searchOnChange={handleRecipeSearch}
                    marginBottom={majorScale(2)}
                  />
                  {
                    Object.keys(recipeCategories).map((categoryOpt) => {
                      const categories = recipeCategories[categoryOpt];
                        return (
                          <StockTakeDetalRecipes
                            key={categoryOpt}
                            categoryOpt={categoryOpt}
                            tableHeaders={recipeTableHeaders}
                            mobileTableHeaders={recipeTableHeaders}
                            isOpen={(recipeCategory === categoryOpt) || !!(recipeSearch)}
                            recipes={categories}
                            recipeSearch={recipeSearch}
                            setCategory={setRecipeCategory}
                            recipeCategory={recipeCategory}
                            isMobile={isMobile}
                          />
                        )
                    })
                  }
                </Pane>
              }
            </Block>
            </>
          )}
        </>
      )}
      </StocktakeFilters>
    </React.Fragment>
  );
};

export default StockTakeDetail;
