//React
import React from 'react';

//Libraries
import _ from 'lodash';
//import PropTypes from 'prop-types';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Accordion from '../../../components/ui/Accordion/Accordion';

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { filterActiveRecipes } from '../../../utils/recipes';


const StockTakeDetalRecipes = ({ recipes, recipeSearch, categoryOpt, setCategory, isOpen, tableHeaders, mobileTableHeaders, isMobile }) => {
  // First, filter out inactive recipes
  const activeRecipes = filterActiveRecipes(recipes);
  const filteredRecipes = (activeRecipes ) ? _.filter(recipes, generateSearchFieldsFn(['name'], recipeSearch)) : recipes;

  const toggleOpen = () => isOpen ? setCategory(null) : setCategory(categoryOpt);

  return (
    <Accordion
      label={categoryOpt === 'undefined' ? '<No Category>' : categoryOpt}
      toggleOpen={toggleOpen}
      isOpen={isOpen}
    >
      <Pane maxHeight="450px" overflowY="hidden">
        <Pane
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(1)}
          maxHeight="450px" 
          overflowY="auto" 
        >
          <DataTable
            items={filteredRecipes}
            headers={(isMobile) ? mobileTableHeaders : tableHeaders}
            listSize={isMobile ? 5 : 7}
            isMobile={isMobile}
            listHeight={400}
          />
        </Pane>
      </Pane>
    </Accordion>
  );
}

export default StockTakeDetalRecipes;
