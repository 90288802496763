//React
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import _ from 'lodash';
import { majorScale, Pane } from 'evergreen-ui';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { generateSearchFieldsFn } from '../../../utils/functions';
import { filterActiveIngredients } from '../../../utils/ingredients';
import { current } from '../../../utils/selectors';


const Ingredients = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  
  const ingredientsList = useSelector((state) => filterActiveIngredients(current(state, 'ingredients', accountId)));
  const supplierOptionList = useSelector((state) => _.groupBy(
    current(state, 'supplierOptions', accountId),
    'ingredientId'
  ));
  const recipeIngredientList = useSelector((state) => _.map(
    current(state, 'recipes', accountId),
    (recipe) => (_.map(recipe.ingredients, (ing) => (ing.id)))
  ));
  const categoryMap = useSelector((state) => _.keyBy(
    current(state, 'ingredientCategories', accountId),
    'id'
  ));
  const categoryList = useSelector((state) => current(state, 'ingredientCategories', accountId));

  const [filters, setFilters] = useState({});

  const filteredIngredients = _.filter(ingredientsList, (ingredient) => {
    if (filters.search && !filters.search(ingredient)) {
      return false;
    }
    if (filters.categoryId) {
      if (filters.categoryId !== ingredient.categoryId) {
        return false;
      }
    } else {
      if (ingredient.categoryId === null) {
        return false;
      }
    }
    return true;
  });

  const tableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 5 },
    { label: 'Item Code', field: 'itemcode', type: 'text', width: 3 },
    { label: 'Articles', field: 'optionCount', type: 'calc', calc: (item) => {
      // Retrieve a count of available Supplier Options
      const options = supplierOptionList[item.id] || [];
      return options.length;
    }, width: 2 },
    { label: 'Suppliers', field: 'supplierCount', type: 'calc', calc: (item) => {
      // Retrieve a count of unique suppliers
      const options = supplierOptionList[item.id] || [];
      return (_.uniqBy(options, 'supplierId')).length;
    }, width: 2 },
    { label: 'Recipes', field: 'recipeCount', type: 'calc', calc: (item) => {
      // Retrieve a count of recipes that contain the item as an ingredient
      const recipes = _.filter(recipeIngredientList, (ingList) => (_.includes(ingList, item.id)));
      return (recipes.length || 0);
    }, width: 2 },
    { label: 'Main Category', field: 'categoryId', type: 'calc', calc: (item) => {
      // Recurse to the top-most category
      let categoryId = item.categoryId;
      while (categoryMap[categoryId] && categoryMap[categoryId].parent) {
        categoryId = categoryMap[categoryId].parent;
      }
      return (categoryMap[categoryId] && categoryMap[categoryId].name) || '';
    }, width: 2 },
  ];

  const mobileTableHeaders = [
    { label: 'Name', field: 'name', type: 'text', width: 6 },
    { label: 'Art.', field: 'optionCount', type: 'calc', calc: (item) => {
      // Retrieve a count of available Supplier Options
      const options = supplierOptionList[item.id] || [];
      return options.length;
    }, width: 2 },
    { label: 'Sup.', field: 'supplierCount', type: 'calc', calc: (item) => {
      // Retrieve a count of unique suppliers
      const options = supplierOptionList[item.id] || [];
      return (_.uniqBy(options, 'supplierId')).length;
    }, width: 2 },
    { label: 'Rec.', field: 'recipeCount', type: 'calc', calc: (item) => {
      // Retrieve a count of recipes that contain the item as an ingredient
      const recipes = _.filter(recipeIngredientList, (ingList) => (_.includes(ingList, item.id)));
      return (recipes.length || 0);
    }, width: 2 },
  ];
  
  const categoryOptions = () => {
    if (categoryList) {
      return _.sortBy(categoryList, 'name').map((cat) => ({ label: cat.name, value: cat.name }));
    }
    return [];
  }

  const filterFields = [
    { label: 'Category', name: 'category', options: categoryOptions() }
  ];

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      if (filterName === 'category') {
        const categoryId = _.findKey(categoryMap, (category) => category.name === value);
        return {
          ...prevFilters,
          categoryId: categoryId,
          [filterName]: value,
        };
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const searchOnChange = (newSearchValue) => {
    if (!newSearchValue) {
      updateFilters('search', '');
    }
    else {
      updateFilters('search', generateSearchFieldsFn(['name'], newSearchValue));
    }
  };

  const handleRowSelect = (item) => {
    const path = `/${accountId}/front-of-house/ingredients/${item.id}`;
    navigate(path);
  };

  return (
    <React.Fragment>
      <Pane marginBottom={majorScale(2)}>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Ingredients</CustomHeading>
        <FilterBar
            filterFields={filterFields}
            filters={{ ...filters, category: filters.category }}
            setFilter={updateFilters}
            searchPlaceholder="Search Ingredients"
            searchOnChange={searchOnChange}
        />
      </Block>
      <Block flex={`1 0 auto`} display="flex" height="420px" flexDirection="column">
        <Pane flex="1" overflowY="auto" display="flex" flexDirection="column">
          <DataTable
              items={filteredIngredients}
              filters={filters}
              headers={(isMobile) ? mobileTableHeaders : tableHeaders}
              onRowSelect={handleRowSelect}
              flex="1 0 auto"
              listHeight={400}
          />
        </Pane>
      </Block>
      </Pane>
    </React.Fragment>
  );
};

export default Ingredients;
