const SUPPLIER_CATEGORIES_LOAD_SUCCESS = 'SUPPLIER_CATEGORIES_LOAD_SUCCESS';
const SUPPLIER_CATEGORIES_LOAD_FAILURE = 'SUPPLIER_CATEGORIES_LOAD_FAILURE';
const SUPPLIER_CATEGORIES_CLEAR = 'SUPPLIER_CATEGORIES_CLEAR';
const ADD_SUPPLIER_CATEGORY = 'ADD_SUPPLIER_CATEGORY';
const REMOVE_SUPPLIER_CATEGORY = 'REMOVE_SUPPLIER_CATEGORY';
const UPDATE_SUPPLIER_CATEGORY = 'UPDATE_SUPPLIER_CATEGORY';

export const actionTypes = {
  SUPPLIER_CATEGORIES_LOAD_SUCCESS,
  SUPPLIER_CATEGORIES_LOAD_FAILURE,
  SUPPLIER_CATEGORIES_CLEAR,
  ADD_SUPPLIER_CATEGORY,
  REMOVE_SUPPLIER_CATEGORY,
  UPDATE_SUPPLIER_CATEGORY
};

const supplierCategoriesLoadSuccess = (payload, account) => {
  return {
    type: SUPPLIER_CATEGORIES_LOAD_SUCCESS,
    payload,
    account
  };
};

const supplierCategoriesLoadFailure = (error) => {
  return {
    type: SUPPLIER_CATEGORIES_LOAD_FAILURE,
    error
  };
};

const supplierCategoriesClear = () => {
  return {
    type: SUPPLIER_CATEGORIES_CLEAR
  };
};

const addCategory = (categoryInfo, callback) => {
  return {
    type: ADD_SUPPLIER_CATEGORY,
    categoryInfo,
    callback
  };
};

const removeCategory = (categoryId) => {
  return {
    type: REMOVE_SUPPLIER_CATEGORY,
    categoryId
  };
};

const updateCategory = (categoryInfo, callback) => {
  return {
    type: UPDATE_SUPPLIER_CATEGORY,
    categoryInfo,
    callback
  };
};

export const actions = {
  supplierCategoriesLoadSuccess,
  supplierCategoriesLoadFailure,
  supplierCategoriesClear,
  addCategory,
  removeCategory,
  updateCategory
};
