import { getCollectionRef } from './common'

export const addModifierCategory = (modifierCategory, accountId) => {
  const modifierCategoriesRef = getCollectionRef('modifierCategories', accountId);
  return modifierCategoriesRef.add(modifierCategory);
};

export const removeModifierCategory = (modifierCategoryId, accountId) => {
  const modifierCategoriesRef = getCollectionRef('modifierCategories', accountId);
  return modifierCategoriesRef.doc(modifierCategoryId).delete();
};

export const updateModifierCategory = (modifierCategoryId, modifierCategory, accountId) => {
  const modifierCategoriesRef = getCollectionRef('modifierCategories', accountId);
  return modifierCategoriesRef.doc(modifierCategoryId).update(modifierCategory);
};
