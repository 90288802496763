//React
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, toaster, Text, useTheme } from 'evergreen-ui';

//Components
import IngredientEditForm from './IngredientEditForm';
import Page from '../../components/Page/Page';
import Button from '../../components/ui/Button/Button';
import Block from '../../components/ui/Block/Block';
import IconWrapper from '../../components/Icons/Icons';
import CustomHeading from '../../components/Headings/Headings';

//Files
import { actions } from '../../store/actions';
import { current, activeAccount } from '../../utils/selectors';
import { formatDate } from '../../utils/format';
import { getIngredientById, getOptionsForIngredient } from '../../utils/functions';


const IngredientEdit = () => {
  const { ingredientId } = useParams();
  const accountId = useSelector(activeAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  
  const ingredient = useSelector((state) => {
    if (ingredientId) {
      return _.find(current(state, 'ingredients'), { id: ingredientId }) || false;
    }
    return {
      recipeunit: '',
      categoryId: '',
    };
  });

  const [ingInfo, setIngInfo] = useState(ingredient || {});
  const [errors, setErrors] = useState({});
  const [linkedOptions, setLinkedOptions] = useState(ingredient.supplierOptions || []);
  console.log(linkedOptions)

  const pageTitle = (ingredient.name) ? ingredient.name : 'New Ingredient';
  const isReadonly = !!ingredient.readonly;
  
  useEffect(() => {
    const fetchLinkedOptions = async () => {
      if (ingredientId) {
        const options = await getOptionsForIngredient(ingredientId);
        setLinkedOptions(options);
      }
    };
    fetchLinkedOptions();
  }, [ingredientId]);

  const validateForm = () => {
    const newErrors = {};
    if (!ingInfo.name || ingInfo.name.trim() === '') newErrors.name = 'Name is required';
    if (!ingInfo.categoryId) newErrors.categoryId = 'Category is required';
    if (!ingInfo.recipeunit) newErrors.recipeunit = 'Base UOM is required';

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toaster.danger(
        <div>
          {Object.values(newErrors).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      );
      return false;
    }
    return true;
  };
  console.log(ingInfo)

  const saveIngredient = async () => {
    if (validateForm()) {
      if (ingredientId) {
        // Update an existing ingredient
        const originalIngredient = await getIngredientById(ingredientId);
        //console.log(originalIngredient)

        // Update an existing ingredient
        dispatch(actions.ingredients.updateIngredient(ingInfo, async () => {
        // Check if base_uom has changed
        if (ingInfo.recipeunit !== originalIngredient.recipeunit || ingInfo.categoryId !== originalIngredient.categoryId) {
          // Fetch related supplier options
          const supplierOptions = await getOptionsForIngredient(ingredientId); // Assume this function fetches supplier options for the given ingredientId
          
          // Update each supplier option with the new base_uom
          for (const option of supplierOptions) {
            const updatedOption = {
              ...option,
              base_uom: ingInfo.recipeunit,
              categoryId: ingInfo.categoryId,
            };
            await dispatch(actions.supplierOptions.updateSupplierOption(updatedOption));
          }
        }

        // Link new supplier options added via the dialog
        for (const option of linkedOptions) {
          const updatedOption = { ...option, ingredientId, archived: false };
          await dispatch(actions.supplierOptions.updateSupplierOption(updatedOption));
        }

        // Remove supplier options that were unlinked
        const currentOptions = await getOptionsForIngredient(ingredientId);
        const unlinkedOptions = currentOptions.filter(opt => !linkedOptions.some(linkedOpt => linkedOpt.id === opt.id));
        for (const option of unlinkedOptions) {
          //shouldArchive = true as 4th argument
          await dispatch(actions.supplierOptions.removeSupplierOptionFromCollection(accountId, ingredientId, option.id, true));
        }

        navigate('/ingredients');

      }));
      } else {
        // Add a new ingredient
        dispatch(actions.ingredients.addIngredient(ingInfo, (ingredient) => navigate(`/ingredients/${ingredient.id}`)));
      }
    }
  };

  const linkOptionToThis = (option) => {
    const updatedOption = { 
      id: option.id, 
      name: option.name, 
      defaultOption: linkedOptions.length === 0 
    };
  
    // Check if the option already exists in linkedOptions
    const existingOptionIndex = linkedOptions.findIndex(opt => opt.id === option.id);
  
    let updatedLinkedOptions = [...linkedOptions];
  
    if (existingOptionIndex === -1) {
      // If the option doesn't exist, add it
      updatedLinkedOptions.push(option); // Keep full data in linkedOptions
    }
  
    setLinkedOptions(updatedLinkedOptions);
  
    // Append the new option to the existing supplierOptions in ingInfo without replacing the existing ones
    const existingSupplierOptions = ingInfo.supplierOptions || [];
    const updatedSupplierOptions = [
      ...existingSupplierOptions.filter(opt => opt.id !== option.id),
      updatedOption // Only include necessary fields
    ];
  
    setIngInfo({ ...ingInfo, supplierOptions: updatedSupplierOptions });
  };
  
  const unlinkOptionFromThis = (option) => {
    // Remove the option from linkedOptions
    const updatedLinkedOptions = linkedOptions.filter(opt => opt.id !== option.id);
    setLinkedOptions(updatedLinkedOptions);
  
    // Remove the option from supplierOptions in ingInfo without replacing the existing ones
    const existingSupplierOptions = ingInfo.supplierOptions || [];
    const updatedSupplierOptions = existingSupplierOptions.filter(opt => opt.id !== option.id);
  
    setIngInfo({ ...ingInfo, supplierOptions: updatedSupplierOptions });
  };  

  const lastEditedText = () => {
    if (ingredient?.updatedBy || ingredient?.createdBy) {
      const editedBy = ingredient?.updatedBy ? ingredient?.updatedBy : ingredient.createdBy;
      const editedAt = ingredient?.updatedAt ? formatDate(ingredient?.updatedAt) : formatDate(ingredient?.createdAt);
      if (editedAt) {
        return `Last edited by: ${editedBy} on: ${editedAt}`;
      }
    }
    return '';
  };

  const backNav = () => {
    window.history.back();
  };

  return (
    <Page title={pageTitle}>
      <Block
          background={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(2)}
      >
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" alignItems="center">
            <IconWrapper
                name="arrowLeft"
                onClick={backNav}
                appearance="clickable"
                marginRight={majorScale(2)}
            />
             <CustomHeading level="3">{ingInfo.name || 'New Ingredient'}</CustomHeading>
          </Pane>
          {lastEditedText() && (
            <Text marginTop={majorScale(1)}>{lastEditedText()}</Text>
          )}
        </Pane>
        <Pane display="flex" alignItems="center">
          <Button 
            appearance='minimal'
            is={Link} 
            to="/ingredients">Cancel</Button>
          <Button 
            appearance="primary" 
            onClick={saveIngredient} 
            marginLeft={majorScale(2)} 
            disabled={isReadonly}>{(ingredientId) ? 'Save' : 'Add'}
          </Button>
        </Pane>
      </Block>

      <IngredientEditForm 
            ingInfo={ingInfo} 
            onChange={setIngInfo} 
            ingredientId={ingredientId}
            ingredient={ingredient}
            displayAllergens={true} 
            linkOptionToThis={linkOptionToThis}
            unlinkOptionFromThis={unlinkOptionFromThis}
            errors={errors} 
            setErrors={setErrors}
            linkedOptions={linkedOptions}
            setLinkedOptions={setLinkedOptions}
      />
    </Page>
  );
};

IngredientEdit.propTypes = {
  ingredientId: PropTypes.string
};

IngredientEdit.defaultProps = {
  ingredientId: ''
};

export default IngredientEdit;
