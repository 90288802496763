import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addInvoiceAttachment, addInvoiceUpload, removeInvoiceUpload, updateInvoiceUpload } from '../firebase/invoiceUploads';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';
import _ from 'lodash';

export default function* invoiceUploadsSaga() {
  yield takeEvery(actionTypes.invoiceUploads.ADD_INVOICE_UPLOAD, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const { id } = yield call(addInvoiceUpload, action.invoiceUpload, accountId);
      yield put(actions.appMessageSuccess('Invoice Upload Added'));
      if (action.callback) {
        yield call(action.callback, id, action.invoiceUpload);
      }
    }
    catch (err) {
      console.log(err,'err')
      yield put(actions.appMessageError('Invoice Upload could not be added'));
    }
  });
  yield takeEvery(actionTypes.invoiceUploads.REMOVE_INVOICE_UPLOAD, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeInvoiceUpload, action.recordId, accountId);
      yield put(actions.appMessageSuccess('Invoice Upload Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Invoice Upload could not be removed'));
    }
  });
  yield takeEvery(actionTypes.invoiceUploads.UPDATE_INVOICE_UPLOAD, function* (action) {
    const { id, ...record } = action.record;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateInvoiceUpload, id, record, accountId);
      // yield put(actions.appMessageSuccess('Invoice Upload Updated'));
    }
    catch (err) {
      console.log(err,'err upload add url')
      yield put(actions.appMessageError('Invoice Upload could not be updated'));
    }
  });
  yield takeEvery(actionTypes.invoiceUploads.UPDATE_INVOICE_UPLOAD_ATTACHMENTS, function* (action) {
    const { invoiceUploadId, upload, attachments } = action;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      // Next, add any new attachments
      const pendingAdd = _.filter(attachments, 'file');
      let filename;
      for ( let x = 0, ln = pendingAdd.length; x < ln; x++ ) {
        const ext = pendingAdd[x].file.name.split('.').pop();
        filename = `${accountId}/images/invoiceUploads/${invoiceUploadId}.${ext}`;
        yield call(addInvoiceAttachment, {
          invoiceUploadId,
          upload,
          ...pendingAdd[x],
          filename
        }, accountId, accountId);
      }
      yield put(actions.appMessageSuccess('Upload attachments added'));

      if (action.callback && filename) {
        yield call(action.callback, filename);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Upload attachments could not be updated'));
    }
  });
};
