import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'store/actions';

import { generateSearchFieldsFn } from '../utils/functions';

const useTaskSettings = ({accountId}) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [editingTask, setEditingTask] = useState(false);
  const [removingTask, setRemovingTask] = useState(false);
  const onFilterChange = (filterName, newValue) => {
    setFilters((prevFilters) => {
      if (!newValue) {
        const { [filterName]:discard, ...filters } = prevFilters;
        return filters;
      }
      else if (filterName === 'search') {
        return { ...prevFilters, [filterName]: generateSearchFieldsFn(['action'], newValue) };
      }
      else {
        return { ...prevFilters, [filterName]: newValue };
      }
    });
  };
  const doesRepeatOnDay = (weekdayRepeats, day) => {
    if (_.isEmpty(weekdayRepeats)) {
      return false;
    }
    return weekdayRepeats.split('/').includes(day);
  };
  const filterTasks = (tasks) => {
    return _.filter(tasks, (item) => {
      for (let i in filters) {
        if (i === 'weekdayRepeats') {
          if (!doesRepeatOnDay(item.weekdayRepeats, filters[i])) {
            return false;
          }
        }
        else if (typeof filters[i] === 'function') {
          if (!filters[i](item)) {
            return false;
          }
        }
        else if (typeof filters[i] === 'string') {
          if (item[i] !== filters[i]) {
            return false;
          }
        }
      }
      return true;
    });
  };
  const onFieldChange = (field, newValue) => {
    setEditingTask((prevValues) => ({ ...prevValues, [field]: newValue }));
  };
  const removeConfirmed = (close) => {
    dispatch(actions.tasks.removeTask(accountId, removingTask.id));
    setRemovingTask(false);
    close();
  };
  return {
    filters,
    setFilters,
    onFieldChange,
    editingTask,
    setEditingTask,
    removingTask,
    setRemovingTask,
    onFilterChange,
    filterTasks,
    removeConfirmed
  }
}

export default useTaskSettings;
