import { LS_ACCOUNT_ID } from "../../utils/constants";
import { requestLightspeedAccessTokens } from "../../utils/functions";

import { useNavigate, useSearchParams } from "react-router-dom";
/*const { navigate } = require("hookrouter");
const { useQueryParams } = require("hookrouter");*/
const { useEffect } = require("react");

const Lightspeed = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  //const [queryParams, setQueryParams] = useQueryParams();
  const code = searchParams.get('code');
  const lsAccountId = localStorage.getItem(LS_ACCOUNT_ID);

  useEffect(() => {
    if (!lsAccountId || !code || lsAccountId === 'null') {
      navigate('/');
      return;
    }

    const updateParamsAndRedirect = async () => {
      // Ensure code parameter is removed from URL after processing.
      searchParams.delete('code');
      setSearchParams(searchParams);

      // Clear the local storage value for account ID after its usage.
      localStorage.setItem(LS_ACCOUNT_ID, undefined);

      await requestLightspeedAccessTokens(lsAccountId, code);
      navigate('/');
    };

    updateParamsAndRedirect();
  }, [lsAccountId, code, navigate, searchParams, setSearchParams]);

  return null;
};

export default Lightspeed;
