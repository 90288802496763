import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addPrepTask, removePrepTask, updatePrepTask } from '../firebase/prepTasks';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* prepTaskSaga() {
  yield takeEvery(actionTypes.prepTasks.ADD_PREP_TASK, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addPrepTask, action.task, accountId);
      yield put(actions.appMessageSuccess('Task Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task could not be added'));
    }
  });
  yield takeEvery(actionTypes.prepTasks.REMOVE_PREP_TASK, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removePrepTask, action.taskId, accountId);
      yield put(actions.appMessageSuccess('Task Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task could not be removed'));
    }
  });
  yield takeEvery(actionTypes.prepTasks.UPDATE_PREP_TASK, function* (action) {
    const { id, ...task } = action.task;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updatePrepTask, id, task, accountId);
      yield put(actions.appMessageSuccess('Task Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Task could not be updated'));
    }
  });
};
