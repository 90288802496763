//React
import React, { useState } from 'react';
import { Link, useLocation, useRoutes, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale } from 'evergreen-ui';

//Pages

//Components
import Page from '../../components/Page/Page';
import Tab from '../../components/ui/Tab/Tab';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import PlaceOrder from './OrderingComponents/PlaceOrder';
import DraftOrders from './OrderingComponents/DraftOrders';
import RecentOrders from './OrderingComponents/RecentOrders';
import Uploads from './OrderingComponents/Uploads';
import UploadNew from './OrderingComponents/UploadNew';

//Files
import { current } from '../../utils/selectors';
import useConditionalRedirect from '../../hooks/useConditionalRedirect';


const orderingTabs = {
  'new-order': 'Create PO',
  'open-orders': 'Draft POs',
  'received-orders': 'Issued POs',
  'uploads': 'Invoice Tracker'
};

const buildRouteObjects = (accountId) => [
  { path: '/', element: <PlaceOrder accountId={accountId} /> },
  { path: 'open-orders', element: <DraftOrders accountId={accountId} /> },
  { path: 'received-orders', element: <RecentOrders accountId={accountId} /> },
  { path: 'uploads', element: <Uploads accountId={accountId} /> },
  { path: 'uploads/new/', element: <UploadNew accountId={accountId} /> },
];

export const buildSuppliersList = (accountId, state) => {
  const suppliers = current(state, 'suppliers', accountId);
  return _.sortBy(suppliers.filter(s => !s.archive), 'name');
}

const Ordering = () => {
  const { accountId } = useParams();
  const location = useLocation();
  const { requiresWorkingAccount } = useConditionalRedirect();
  const routeObjects = buildRouteObjects(accountId);
  const routeResult = useRoutes(routeObjects);
  const currentTab = location.pathname.split('/')[3] || 'new-order';
  const [reconcileModal, setReconcileModal] = useState(false);
  const [reconcileTab, setReconcileTab] = useState('invoice');
  const suppliers = useSelector((state) => buildSuppliersList(accountId, state));
  requiresWorkingAccount();
  return (
    <Page title={orderingTabs[currentTab]} flex="1 0 auto">
      <TabNavigation
          display="flex"
          flexShrink={0}
          overflowX="auto"
          paddingBottom={majorScale(2)}
      >
        <Tab
            isSelected={currentTab === 'new-order'}
            is={Link}
            to={`/${accountId}/procurement`}
        >Create PO</Tab>
        <Tab
            isSelected={currentTab === 'open-orders'}
            is={Link}
            to={`/${accountId}/procurement/open-orders`}
        >Draft POs</Tab>
        <Tab
            isSelected={currentTab === 'received-orders'}
            is={Link}
            to={`/${accountId}/procurement/received-orders`}
        >Issued POs</Tab>
        <Tab
            isSelected={currentTab === 'uploads'}
            is={Link}
            to={`/${accountId}/procurement/uploads`}
        >Invoice Tracker</Tab>
      </TabNavigation>
      {routeResult}
    </Page>
  );
};

export default Ordering;
