//React
import React, { useState } from 'react';
import { Link as LinkRouter, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Libraries
import moment from 'moment';
import _ from 'lodash';
import { majorScale, Heading, Table, Pane, Text, useTheme} from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { calcOrderTotal, createOrderNumber, requestPdfLink } from '../../../utils/functions';
import { orderStatuses, colors, messageTypes } from '../../../utils/constants';
import { shortDateDisplayFormat, currency } from '../../../utils/format';
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';

const orderTotalCalculation = (order, total) => {
  const orderCost = order.totalCost || 0;
  return total + orderCost;
} 

const totalMonthCost = (months) => months.reduce((total, order) => orderTotalCalculation(order, total), 0)

const SupplierOrderArchive = () => {
  const { accountId, supplierId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const supplier = useSelector((state) => _.find(current(state, 'suppliers', accountId), { 'id': supplierId}));
  const receivedOrders = useSelector((state) => {
    return _.groupBy(
      _.map(
        _.reverse(
          _.sortBy(
            _.filter(
              current(state, 'orders', accountId),
              (order) => (order.status === orderStatuses.RECEIVED && order.supplierId === supplierId)
            ),
            (order) => ((order.deliveryDate) ? order.deliveryDate : order.date)
          )
        ),
        (item) => ({ ...item, totalCost: calcOrderTotal(item) })
      ),
      (order) => moment((order.deliveryDate) ? order.deliveryDate : order.date, 'YYYY-MM-DD').format('MMMM YYYY')
    );
  });
  const orderAttachments = useSelector((state) => {
    return _.groupBy(
      current(state, 'orderAttachments', accountId),
      'orderId'
    );
  });
  const account = useSelector((state) => {
    if (!_.isEmpty(state.accounts)) {
      return (state.accounts[accountId]);
    }
    return null;
  });
  const [expandedRows, setExpandedRows] = useState([]);
  const [deletingPO, setDeletingPO] = useState(false);

  const toggleRow = (rowId) => {
    if (_.includes(expandedRows, rowId)) {
      setExpandedRows(_.without(expandedRows, rowId));
    }
    else {
      setExpandedRows(_.concat(expandedRows, rowId));
    }
  };

  const doDeletePO = (order) => {
    dispatch(actions.orders.removeOrder(accountId, order.id));
  };


  if (_.isEmpty(supplier)) {
    return (
      <Block padding={majorScale(2)}>
        <Heading is="h2">Supplier Not Found</Heading>
      </Block>
    );
  }

  const requestPdf = (orderId) => async () => {
    dispatch(actions.appMessageSet('PDF downloading', messageTypes.INFO));
    await requestPdfLink(accountId, orderId);
  }

  return (
    <React.Fragment>
      <CustomDialog
          isOpen={!!deletingPO}
          title="Delete Order?"
          intent="danger"
          onClose={() => setDeletingPO(false)}
          onConfirm={() => {
            doDeletePO(deletingPO);
            setDeletingPO(false);
          }}
      >
        {!!deletingPO && (
          <Pane marginBottom={majorScale(4)}>
          <Text>Are you sure you wish to delete the Order from {supplier.name} dated {shortDateDisplayFormat(deletingPO.deliveryDate || deletingPO.date)}?</Text>
          </Pane>
        )}
      </CustomDialog>

      <Block
          background={theme.colors.offwhite}
          height={48}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
      >
        <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={LinkRouter}
            to={`/${accountId}/review/supplier-statements`}
            marginRight={majorScale(2)}
        />
        <CustomHeading level="3">{supplier.name}</CustomHeading>
      </Block>

      <Block flex="1 0 auto" display="flex" flexDirection="column">
        <CustomHeading level="4" paddingX={majorScale(2)} marginY={majorScale(2)}>Statements</CustomHeading>
        <Table>
          <Table.Head height={48} paddingLeft={0}>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
            <Table.TextHeaderCell>Invoice Nb.</Table.TextHeaderCell>
            <Table.TextHeaderCell>PO Nb.</Table.TextHeaderCell>
            <Table.TextHeaderCell>Attachments</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={100} flexGrow={0} flexShrink={0}>View</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(receivedOrders) && (
              <Table.Row height={48}>
                <Table.TextCell>No orders to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(receivedOrders, (monthOrders, month) => {
              const isExpanded = _.includes(expandedRows, month);
              return (
                <React.Fragment key={month}>
                  <Table.Row height={48} isSelectable onSelect={() => toggleRow(month)}>
                    <Table.TextCell textProps={{ color: colors.orange }} flex="1 0 auto">{month}</Table.TextCell>
                    <Table.TextCell textProps={{ color: colors.orange }} flex="1 0 auto">£{currency(totalMonthCost(monthOrders))}</Table.TextCell>
                    <Table.Cell flexBasis={50} flexGrow={0}>
                      <IconWrapper name={isExpanded ? 'caretUp' : 'caretDown'} />
                    </Table.Cell>
                  </Table.Row >
                  {isExpanded && _.map(monthOrders, (order) => (
                    <Table.Row height={48} key={order.id}>
                      <Table.TextCell>{shortDateDisplayFormat(order.deliveryDate || order.date)}</Table.TextCell>
                      <Table.TextCell>{(order.type && order.type === 'CREDIT') ? 'Credit' : 'Debit'}</Table.TextCell>
                      <Table.TextCell textProps={{ color: colors.lightTeal }}>£ {currency(order.totalCost)}</Table.TextCell>
                      <Table.TextCell>{order.invoice_number}</Table.TextCell>
                      <Table.TextCell>{createOrderNumber(order, account)}</Table.TextCell>
                      <Table.Cell>
                        {!_.isEmpty(orderAttachments[order.id]) &&
                          <IconWrapper
                            name="paperclip"
                            appearance="clickable"
                            onClick={requestPdf(order.id)}
                          />
                        }
                      </Table.Cell>
                      <Table.Cell flexBasis={50} flexGrow={0} padding={0}>
                        <IconWrapper
                            name="clipboard"
                            appearance="clickable"
                            is={LinkRouter}
                            to={`/${accountId}/review/supplier-statements/detail/${order.id}`}
                        />
                      </Table.Cell>
                      <Table.Cell flexBasis={50} flexGrow={0} padding={0}>
                        <IconWrapper
                            name="trash"
                            appearance="danger"
                            intent="danger"
                            onClick={() => setDeletingPO(order)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </Block>
    </React.Fragment>
  );
};

export default SupplierOrderArchive;
