import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { majorScale, Pane, Heading, TextInputField, Table, IconButton, Dialog, Paragraph } from 'evergreen-ui';

import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
import { actions } from '../../store/actions';
import { current } from '../../utils/selectors';
import { accountTypes } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';


const SupplierOptionsCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useSelector(state => state[state.currentAccount]);
  useEffect(() => {
    if (!account || account?.type?.includes(accountTypes.SUPPLIER)) return;

    navigate('/');
  }, [account])
  const categoryHierarchy = useSelector(state => current(state, 'supplierCategories'));
  const [editingCategory, setEditingCategory] = useState(false);
  const [removingCategory, setRemovingCategory] = useState(false);

  const saveCategory = (cat, callback) => {
    if (cat.id) {
      dispatch(actions.supplierCategories.updateCategory(cat, () => callback()));
    }
    else {
      dispatch(actions.supplierCategories.addCategory(cat, () => callback()));
    }
  };
  const removeCategory = (cat) => {
    // TODO: Handle child categories!
    dispatch(actions.supplierCategories.removeCategory(cat.id));
  };


  return (
    <>
      <Page title="Supplier Categories">
        <Block>
          <Pane
              padding={majorScale(2)}
              display="flex"
              alignItems="center"
          >
            <Heading is="h2" flex="1 0 auto">Supplier Categories</Heading>
            <Button
                marginLeft={majorScale(2)}
                appearance="primary"
                onClick={() => setEditingCategory({})}
            >Add Category</Button>
          </Pane>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell>Category</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 0 56px">Edit</Table.TextHeaderCell>
              <Table.TextHeaderCell flex="0 0 56px">Delete</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {_.isEmpty(categoryHierarchy) && (
                <Table.Row>
                  <Table.TextCell>No items to display.</Table.TextCell>
                </Table.Row>
              )}
              {_.map(categoryHierarchy, (cat) => (
                <Table.Row key={cat.id}>
                  <Table.TextCell
                      paddingLeft={(12 + majorScale(4 * (cat.level || 0)))}
                  >{cat.name}</Table.TextCell>
                  <Table.Cell flex="0 0 56px">
                    <IconButton
                        icon="edit"
                        appearance="default"
                        onClick={() => setEditingCategory(cat)}
                    />
                  </Table.Cell>
                  <Table.Cell flex="0 0 56px">
                    <IconButton
                        icon="trash"
                        appearance="default"
                        intent="danger"
                        onClick={() => setRemovingCategory(cat)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Block>
      </Page>

      <Dialog
          isShown={!!editingCategory}
          title={(editingCategory && editingCategory.id) ? 'Edit Category' : 'New Category'}
          confirmLabel={(editingCategory && editingCategory.id) ? 'Update' : 'Add'}
          onConfirm={(close) => {
            saveCategory(editingCategory, close);
          }}
          onCloseComplete={() => setEditingCategory(false)}
      >
        <TextInputField
            required
            label="Name"
            placeholder="Category Name"
            value={editingCategory.name || ''}
            onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
        />
      </Dialog>

      <Dialog
          isShown={!!removingCategory}
          title="Remove Ingredient Category"
          intent="danger"
          confirmLabel="Remove"
          onConfirm={(close) => {
            removeCategory(removingCategory);
            close();
          }}
          onCloseComplete={() => setRemovingCategory(false)}
      >
        <Paragraph>Are you sure you wish to remove the category {removingCategory.name}?</Paragraph>
      </Dialog>
    </>
  );
};

export default SupplierOptionsCategories;
