import _ from 'lodash';
import moment from 'moment';
import { getWC } from './time';
import { dateStoreFormat } from './format';
import { accountTypes } from './constants';

// Retrieves the currently active account ID from the Redux state.
export const activeAccount = (state) => (state.currentAccount);

// Retrieves the current user name from the Redux state.
export const currentUserName = (state) => {
  return state.profile?.name;
};

// Selector to get menus for a given groupId
export const selectMenus = (state, groupId) => state.menus[groupId] || [];

// Selector to get recipes for a given groupId
export const selectRecipes = (state, groupId) => state.recipes[groupId] || [];

// Selector to get modifiers for a given groupId
export const selectModifiers = (state, groupId) => state.modifiers[groupId] || [];

// Selector to get non-deleted modifiers for a given groupId
export const selectNonDeletedModifiers = (state, groupId) => {
  const allModifiers = state.modifiers[groupId] || [];
  //console.log("All Modifiers: ", allModifiers); // Debugging line
  const nonDeletedModifiers = allModifiers.filter(modifier => !modifier.deleted);
  //console.log("Non-Deleted Modifiers: ", nonDeletedModifiers); // Debugging line
  return nonDeletedModifiers;
};

// Retrieves data for a specified account from a given collection within the Redux state.
// This includes data directly associated with the account and, if applicable, data from related parent or department accounts.
export const current = (state, collectionName, accountId = false) => {

  // Default to the active account if no accountId is explicitly provided.
  let results = [];
  const ca = accountId || activeAccount(state);

  // If there's a valid account and it's a department, include items from associated department templates.
  if (ca && state.accounts[ca]) {
    const currentAccount = state.accounts[ca];

    // Handle department-specific logic.
    if ((!currentAccount.type || currentAccount.type === accountTypes.DEPARTMENT)) {
      if (currentAccount.departments) {
        for (let x = 0, ln = currentAccount.departments.length; x < ln; x++) {
          // Include data from all linked departments.
          const deptId = currentAccount.departments[x];
          // Inherit items from the department templates
          results = _.concat(results, _.map(state[collectionName][deptId], (item) => ({ ...item })));
        }
      }
    }
    // Include data from parent accounts, if any.
    if (currentAccount.parents) {
      for (let x = 0, ln = currentAccount.parents.length; x < ln; x++) {
        const parentId = currentAccount.parents[x];
        // Same as above, but from collections from parent entities
        results = _.concat(results, _.map(state[collectionName][parentId], (item) => ({ ...item })));
      }
    }
  }
   // Directly include data associated with the current account.
  if (ca && state[collectionName] && state[collectionName][ca]) {
    results = _.concat(results, state[collectionName][ca]);
  }

  // Filter out duplicate items by ID and return the results.
  return _.uniqBy(_.filter(results), 'id');
};

export const getRelevantDepartments = (state) => {
  const currentAccountId = state.currentAccount;
  const currentAccount = state.accounts[currentAccountId];

  if (!currentAccount) {
    return [];
  }

  if (currentAccount.type === accountTypes.OWNER) {
    // If the account is a head office, get its children accounts as departments
    const childrenDepartments = currentAccount.children
      ? currentAccount.children
          .map((childId) => state.accounts[childId])
          .filter((dept) => dept && dept.type === accountTypes.DEPARTMENT)
      : [];
    return _.sortBy(childrenDepartments, 'name');
  } else {
    // If not a head office, return any account that lists the current account as its parent
    const parentDepartments = _.filter(
      state.accounts,
      (dept) =>
        dept.type === accountTypes.DEPARTMENT &&
        dept.parents &&
        dept.parents.includes(currentAccountId)
    );
    return _.sortBy(parentDepartments, 'name');
  }
};

// Selects report incidental data for a given week and account.
export const selectReportIncidentals = ({ state, accountId, weekStart }) => {
  const weekStartDate = getWC(moment.utc(weekStart).toDate());
  const weekly = _.find(current(state, 'reportsIncidentals', accountId), { 'weekOf': dateStoreFormat(weekStartDate) }) || {};
  const yearStart = dateStoreFormat(getWC(moment.utc(weekStart).month(0).date(1).toDate()));
  const salesPrevYear = _.get(
    _.find(
      current(state, 'reportsIncidentals', accountId),
      { 'weekOf': yearStart }
    ),
    'salesPrevYear',
    0
  );

  return {
    ...weekly,
    salesPrevYear
  };
};

// Selects and aggregates report incidental data for a given account over a specified date range.
export const selectPeriodReportIncidentals = ({ state, accountId, periodStart, weekEnd }) => {
  const weekOf = moment.utc(periodStart, "YYYY-MM-DD");
  const weekStartDate = getWC(weekOf.toDate());
  weekEnd = moment.utc(weekEnd, "YYYY-MM-DD");
  return current(state, 'reportsIncidentals', accountId).filter(report => {
    const reportDate = moment.utc(report.weekOf, "YYYY-MM-DD");
    return reportDate.isSameOrAfter(weekStartDate, 'day') && reportDate.isSameOrBefore(weekEnd, 'day');
  });
};

// Constructs a hierarchical tree of ingredient categories based on parent-child relationships.
export const selectIngredientCategoryHierarchy = (state) => {
  const arrangeHierarchy = (currentLevel, branchMap, list = [], level = 0, path = '') => {
    for (let x = 0, ln = currentLevel.length; x < ln; x++) {
      list.push({
        ...currentLevel[x],
        level,
        hierarchy: path
      });
      if (branchMap[currentLevel[x].id]) {
        arrangeHierarchy(branchMap[currentLevel[x].id], branchMap, list, level + 1, path + currentLevel[x].id + '$');
      }
    }
    return list;
  };
  const [trunks, branches] = _.partition(current(state, 'ingredientCategories'), (cat) => !cat.parent);
  const branchMap = _.groupBy(_.sortBy(branches, (cat) => _.lowerCase(cat.name)), 'parent');
  const hierarchy = arrangeHierarchy(trunks, branchMap);

  return hierarchy;
};
