const TASKS_COMMENTS_LOAD_SUCCESS = 'TASKS_COMMENTS_LOAD_SUCCESS';
const TASKS_COMMENTS_CLEAR = 'TASKS_COMMENTS_CLEAR';
const ADD_TASK_COMMENT = 'ADD_TASK_COMMENT';
const REMOVE_TASK_COMMENT = 'REMOVE_TASK_COMMENT';
const UPDATE_TASK_COMMENT = 'UPDATE_TASK_COMMENT';

export const actionTypes = {
  TASKS_COMMENTS_LOAD_SUCCESS,
  TASKS_COMMENTS_CLEAR,
  ADD_TASK_COMMENT,
  REMOVE_TASK_COMMENT,
  UPDATE_TASK_COMMENT
};


const tasksCommentsLoadSuccess = (payload, account) => {
  return {
    type: TASKS_COMMENTS_LOAD_SUCCESS,
    payload,
    account
  };
};

const tasksCommentsClear = () => {
  return {
    type: TASKS_COMMENTS_CLEAR
  };
};

const addTaskComment = (accountId, comment) => {
  return {
    type: ADD_TASK_COMMENT,
    comment,
    accountId,
  };
};

const removeTaskComment = (accountId, commentId) => {
  return {
    type: REMOVE_TASK_COMMENT,
    commentId,
    accountId,
  };
};

const updateTaskComment = (accountId, comment) => {
  return {
    type: UPDATE_TASK_COMMENT,
    comment,
    accountId,
  };
};

export const actions = {
  tasksCommentsLoadSuccess,
  tasksCommentsClear,
  addTaskComment,
  removeTaskComment,
  updateTaskComment
};
