const DELIVERY_COMPANIES_LOAD_SUCCESS = 'DELIVERY_COMPANIES_LOAD_SUCCESS';
const DELIVERY_COMPANIES_LOAD_FAILURE = 'DELIVERY_COMPANIES_LOAD_FAILURE';
const DELIVERY_COMPANIES_CLEAR = 'DELIVERY_COMPANIES_CLEAR';

export const actionTypes = {
  DELIVERY_COMPANIES_LOAD_SUCCESS,
  DELIVERY_COMPANIES_LOAD_FAILURE,
  DELIVERY_COMPANIES_CLEAR
};


const deliveryCompaniesLoadSuccess = (payload, account) => {
  return {
    type: DELIVERY_COMPANIES_LOAD_SUCCESS,
    payload,
    account
  };
};

const deliveryCompaniesLoadFailure = (error) => {
  return {
    type: DELIVERY_COMPANIES_LOAD_FAILURE,
    error
  };
};

const deliveryCompaniesClear = () => {
  return {
    type: DELIVERY_COMPANIES_CLEAR
  };
};

export const actions = {
  deliveryCompaniesLoadSuccess,
  deliveryCompaniesLoadFailure,
  deliveryCompaniesClear
};
