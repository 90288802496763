import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addModifier, updateModifier, removeModifier, updateRelatedRecipes, removeRelatedRecipes } from '../firebase/modifiers';
import { actionTypes, actions } from '../actions';
import { activeAccount, currentUserName } from '../../utils/selectors';

export default function* modifiersSaga() {
  yield takeEvery(actionTypes.modifiers.ADD_MODIFIER, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      yield call(addModifier, action.modifierInfo, accountId, userName);
      yield put(actions.appMessageSuccess('Modifier Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Modifier could not be saved'));
    }
  });
  yield takeEvery(actionTypes.modifiers.UPDATE_MODIFIER, function* (action) {
    const { id, ...modifier } = action.modifierInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      yield call(updateModifier, id, modifier, accountId, userName);
      yield call(updateRelatedRecipes, id, modifier, accountId, userName);
      yield put(actions.appMessageSuccess('Modifier Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Modifier could not be updated'));
    }
  });
  yield takeEvery(actionTypes.modifiers.REMOVE_MODIFIER, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      const userName = (yield select(currentUserName));
      yield call(removeModifier, action.modifierId, accountId, userName);
      yield call(removeRelatedRecipes, action.modifierId, accountId, userName);
      yield put(actions.appMessageSuccess('Modifier Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Modifier could not be removed'));
    }
  });
};
