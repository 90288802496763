//React
import React from 'react';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, SearchInput } from 'evergreen-ui';

//Components
import FilterSelect from '../../components/FilterSelect/FilterSelect';

const emptyFn = () => {};

const FilterBar = (props) => {
  const { searchPlaceholder, searchOnChange, filterFields, filters, setFilter, hideSearch, ...otherProps } = props;
  const isTablet = useMediaQuery({ maxWidth: 768 });

  return (
    <Pane
        display="flex"
        alignItems="stretch"
        justifyContent="flex-start"
        flexFlow={(isTablet) ? 'row wrap' : 'row'}
        {...otherProps}
    >
      {filterFields && 
        <Pane
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            marginBottom={(isTablet) ? majorScale(2) : 0}
        >
          {filterFields.map((filter) => {
            const selected = _.find(filter.options, { 'value': filters[filter.name] });
            return (
              <FilterSelect
                  key={`filter-${filter.name}`}
                  {...filter}
                  setFilter={setFilter}
                  selected={selected}
                  marginRight={majorScale(1)}
                  flex={(isTablet) ? "1 0 auto" : "0 0 200px"}
              />
            );
          })}
        </Pane>
      }
      {
        !hideSearch &&
        <SearchInput
          flex="1 0 auto"
          placeholder={searchPlaceholder}
          width="100%"
          maxWidth={300}
          onChange={(e) => searchOnChange(e.target.value)}
        />
      }
    </Pane>
  );
};

FilterBar.propTypes = {
  searchPlaceholder: PropTypes.string,
  searchOnChange: PropTypes.func,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  hideSearch: PropTypes.bool,
  filterFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })).isRequired
  }))
};

FilterBar.defaultProps = {
  filters: {},
  searchPlaceholder: 'Search',
  searchOnChange: emptyFn
};

export default FilterBar;
