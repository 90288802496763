//React
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Pane, Paragraph, UnorderedList, ListItem, Text, Table, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { getRecipeItemDetails, recipeCompleteAllergens } from '../../../utils/functions';
import { currency } from '../../../utils/format';
import { current } from '../../../utils/selectors';


const MenuSection = ({ section }) => {
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const { accountId } = useParams();
  const theme = useTheme();
  const recipeMap = useSelector((state) => _.keyBy(current(state, 'recipes', accountId), 'id'));

  if (isMobile) {
    return (
      <Block elevation={1} marginBottom={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)} padding={majorScale(2)}>{section.name}</CustomHeading>
        <Table>
          <Table.Head height="auto" paddingY={majorScale(1)} flexFlow="row wrap">
            <Table.TextHeaderCell flex="1 0 60%">Name</Table.TextHeaderCell>
            <Table.TextHeaderCell flex="1 0 40%">Price</Table.TextHeaderCell>
            <Table.TextHeaderCell flex="1 0 auto" paddingLeft={majorScale(4)}>Allergens</Table.TextHeaderCell>
            <Table.TextHeaderCell flex="0 0 48px"></Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.map(section.recipes, (recipeId) => (
              <MenuRecipe recipe={recipeMap[recipeId]} key={recipeId} accountId={accountId} />
            ))}
          </Table.Body>
        </Table>
      </Block>
    );
  }

  return (
    <Block elevation={1} marginBottom={majorScale(2)}>
      <CustomHeading level="3" marginBottom={majorScale(2)} padding={majorScale(2)}>{section.name}</CustomHeading>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flex="1 0 40%">Name</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="1 0 10%">Allergens</Table.TextHeaderCell>
          <Table.TextHeaderCell>Price</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="0 0 48px"></Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {_.map(section.recipes, (recipeId) => (
            <MenuRecipe recipe={recipeMap[recipeId]} key={recipeId} accountId={accountId} />
          ))}
        </Table.Body>
      </Table>
    </Block>
  );
};

MenuSection.propTypes = {
  accountId: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
};

const IngredientItem = ({
  ing,
  accountId,
  isMobile
}) => {
  const itemDetails = getRecipeItemDetails(ing, accountId);
  const isModifier = ing.type === 'modifier' && itemDetails.ingredients;
  return (
    <>
      <ListItem margin={0}>- {itemDetails.name}</ListItem>
      {
        isModifier &&
        <UnorderedList
          listStyle="none"
          marginLeft={(isMobile) ? majorScale(1) : majorScale(2)}
        >
          {
            itemDetails.ingredients.map((ing, itemIndex) => (
              <ListItem key={`ing_${itemIndex}`} margin={0}>{ing.name}</ListItem>
            ))
          }
        </UnorderedList>
      }
    </>
  )
}

const MenuRecipe = ({ recipe }) => {
  const { accountId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const [isOpen, setIsOpen] = useState(false);
  const imgSize = (isMobile) ? 100 : 150;

  if (_.isEmpty(recipe)) {
    return null;
  }

  const allergenList = recipeCompleteAllergens(recipe);

  return (
    <React.Fragment>
      {!isMobile && (
        <Table.Row isSelectable onSelect={() => setIsOpen(!isOpen)}>
          <Table.TextCell flex="1 0 40%">{recipe.name}</Table.TextCell>
          <Table.TextCell flex="1 0 10%">{_.join(allergenList, ', ')}</Table.TextCell>
          <Table.TextCell>£ {currency(recipe.menuprice)}</Table.TextCell>
          <Table.Cell flex="0 0 48px">
            <IconWrapper
                name={(isOpen) ? 'caretUp' : 'caretDown'}
                appearance="clickable"
                color={theme.colors.black}
                marginRight={majorScale(2)}
                //size={24}
            />
          </Table.Cell>
        </Table.Row>
      )}
      {isMobile && (
        <React.Fragment>
          <Table.Row isSelectable onSelect={() => setIsOpen(!isOpen)} flexFlow="row wrap">
            <Table.TextCell flex="1 0 60%">{recipe.name}</Table.TextCell>
            <Table.TextCell flex="1 0 40%">£ {currency(recipe.menuprice)}</Table.TextCell>
            <Table.TextCell paddingLeft={majorScale(4)}>{_.join(recipeCompleteAllergens(recipe), ', ')}</Table.TextCell>
            <Table.Cell flex="0 0 48px">
              <IconWrapper
                 name={(isOpen) ? 'caretUp' : 'caretDown'}
                 appearance="clickable"
                 color={theme.colors.black}
                 marginRight={majorScale(2)}
                //size={24}
              />
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      )}
      {isOpen && (
        <Table.Row height="auto" intent="warning">
          <Table.Cell
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="stretch"
              padding={majorScale(2)}
          >
            <Pane
                display="flex"
                flexFlow="row wrap"
                alignItems="stretch"
                justifyContent="space-between"
            >
              <Pane
                  flex="0 0 auto"
                  height={imgSize}
                  width={imgSize}
                  backgroundPosition="center 0"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  backgroundImage={`url(${recipe.main_imageURL})`}
                  border="default"
                  borderRadius={3}
                  marginRight={majorScale(2)}
                  marginBottom={majorScale(2)}
                  alignSelf="flex-start"
              />
              <Pane
                  flex={`1 1 calc(100% - ${imgSize + majorScale(2)}px)`}
                  border="muted"
                  borderRadius={3}
                  padding={majorScale(2)}
                  marginBottom={majorScale(2)}
              >
                <Paragraph>{(recipe.description) ? recipe.description : <em>No description available</em>}</Paragraph>
              </Pane>
              <Pane flex="1 0 50%" marginBottom={(isMobile) ? majorScale(2) : 0}>
                <CustomHeading level="5" marginBottom={majorScale(1)}>Ingredients</CustomHeading>
                <UnorderedList
                    listStyle="none"
                    marginLeft={(isMobile) ? majorScale(1) : majorScale(2)}
                >
                  {recipe.ingredients && _.map(recipe.ingredients, (ing, index) => (
                    <IngredientItem key={`${ing.id}-${index}`} ing={ing} accountId={accountId} isMobile={isMobile} />
                  ))}
                </UnorderedList>
              </Pane>
              {(!_.isEmpty(recipe.suitable) || !_.isEmpty(allergenList)) && (
                <Pane
                    flex={`1 0 calc(50% - ${majorScale(2)}px)`}
                    marginLeft={majorScale(2)}
                >
                  {!_.isEmpty(recipe.suitable) && (
                    <>
                      <CustomHeading level="5"  marginBottom={majorScale(1)}>Suitable For</CustomHeading>
                      <Paragraph marginBottom={majorScale(2)}>{_.join(recipe.suitable, ', ')}</Paragraph>
                    </>
                  )}
                  {!_.isEmpty(allergenList) && (
                    <>
                      <CustomHeading level="5"  marginBottom={majorScale(1)}>Allergens</CustomHeading>
                      <Paragraph>{_.join(recipeCompleteAllergens(recipe), ', ')}</Paragraph>
                    </>
                  )}
                </Pane>
              )}
            </Pane>
          </Table.Cell>
        </Table.Row>
      )}
    </React.Fragment>
  );
};

MenuRecipe.propTypes = {
  recipe: PropTypes.object.isRequired,
};


const MenuView = () => {
  const theme = useTheme();
  const { accountId, menuId } = useParams();
  const menuInfo = useSelector((state) => {
    if (!menuId) {
      return false;
    }
    return _.find(current(state, 'menus', accountId), { 'id': menuId });
  });


  if (!menuInfo) {
    return (
      <Block padding={majorScale(2)}>
        <Text>Error - Menu could not be found.</Text>
      </Block>
    );
  }


  return (
    <React.Fragment>
      <Block
          backgroundColor={theme.colors.offwhite}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          height={48}
      >
        <IconWrapper
            is={Link}
            to={`/${accountId}/front-of-house/menu`}
            //appearance="minimal"
            name="arrowLeft"
            marginRight={majorScale(2)}
            color={theme.colors.black}
        />
        <CustomHeading level="3" flex="1 1 auto">{menuInfo.name}</CustomHeading>
      </Block>
      {_.map(menuInfo.sections, (section, index) => (<MenuSection section={section} key={index} accountId={accountId} />))}
    </React.Fragment>
  );
};

export default MenuView;
