//React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, UnorderedList, ListItem, Text, useTheme } from 'evergreen-ui';

//Components
import FilterBar from '../../../components/FilterBar/FilterBar';
import Block from '../../../components/ui/Block/Block';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { current } from '../../../utils/selectors';

const MenuList = ({ accountId }) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');

  const menus = useSelector((state) => {
    if (searchText) {
      return _.filter(current(state, 'menus', accountId), (item) => _.includes(_.lowerCase(item.name), _.lowerCase(searchText)));
    }
    return current(state, 'menus', accountId);
  });


  return (
    <Pane>
      <Block padding={majorScale(2)}>
        <CustomHeading level="3" marginBottom={majorScale(2)}>Menus</CustomHeading>
        <FilterBar searchOnChange={setSearchText} />
      </Block>
        <UnorderedList listStyle="none" marginX={0} marginY={majorScale(2)} padding={0}>
          {_.isEmpty(menus) ? (
            <ListItem>
              <Pane
                  border="default"
                  textDecoration="none"
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  background={theme.colors.offwhite}
                  height={48}
                  borderRadius={5}
                  boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
              >
                <Text>No menus to display</Text>
              </Pane>
            </ListItem>
          ) : (
            _.map(menus, (menu) => (
            <ListItem key={menu.id}>
              <Pane
                  is={Link}
                  to={`/${accountId}/front-of-house/menu/${menu.id}`}
                  textDecoration="none"
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  borderRadius={5}
                  boxShadow="-1px 1px 3px rgba(0, 0, 0, 0.1)"
                  background={theme.colors.offwhite}
                  height={48}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
              >
                <Text>{menu.name}</Text>
              </Pane>
            </ListItem>
          ))
        )}
        </UnorderedList>
    </Pane>
  );
};

export default MenuList;
