import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { minorScale, UnorderedList, ListItem, Link, Text, Icon } from 'evergreen-ui';

import Page from '../../components/Page/Page';

const Messages = (props) => {
  return (
    <Page title="Messages" containerProps={{ maxWidth: 600 }}>
      <UnorderedList listStyle="none" margin={0} padding={0}>
        <ListItem>
          <Link
              is={LinkRouter}
              textDecoration="none"
              to="/messages/group"
              display="flex"
              alignItems="center"
              paddingBottom={minorScale(2)}
              paddingTop={minorScale(2)}
          >
            <Icon icon="chat" marginRight={minorScale(4)} color="default" />
            <Text flex="1 0 auto">Group Messages</Text>
            <Icon icon="chevron-right" marginLeft={minorScale(4)} color="default" />
          </Link>
        </ListItem>
        <ListItem display="flex" alignItems="center" paddingBottom={minorScale(2)} paddingTop={minorScale(2)}>
          <Icon icon="chat" marginRight={minorScale(4)} color="muted" />
          <Text flex="1 0 auto" color="muted">Sub Group Messages (available soon)</Text>
        </ListItem>
        <ListItem display="flex" alignItems="center" paddingBottom={minorScale(2)} paddingTop={minorScale(2)}>
          <Icon icon="chat" marginRight={minorScale(4)} color="muted" />
          <Text flex="1 0 auto" color="muted">Direct Message (available soon)</Text>
        </ListItem>
      </UnorderedList>
    </Page>
  );
};

export default Messages;
