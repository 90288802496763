//React
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, Table, Text, Checkbox, TextInputField, SelectField } from 'evergreen-ui';

//Components
import FilterSelect from '../../../components/FilterSelect/FilterSelect';
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import OpenCloseBlock from './OpenCloseBlock';
//import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';
import CustomDialog from '../../../components/Dialog/Dialog';

//Files
import { actions } from '../../../store/actions';
import { TODAY } from '../../../utils/time';
import { current } from '../../../utils/selectors';

const weekdays = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];

const DailyTab = ({ date, DatePanel }) => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const dailyTasks = useSelector((state) => {
    // Filter tasks for 'DAILY' type, and either set for the given date, or repeats on the 
    //  corresponding day of the week
    return _.filter(current(state, 'tasks', accountId), (item) => {
      if (item.type !== 'DAILY') {
        return false;
      }
      const repeats = (item.repeats && item.weekdayRepeats) ? item.weekdayRepeats.split('/') : [];
      if ((item.date && moment(item.date.toDate()).isSame(date, 'day')) || 
          _.includes(repeats, weekdays[date.getDay()])) {
        return true;
      }
      return false;
    });
  });
  const sectionMap = useSelector((state) => {
    return _.keyBy(
      _.filter(current(state, 'sections', accountId), { type: 'DAILY' }),
      'name'
    );
  });
  const sections = _.map(
    _.sortBy(
      sectionMap,
      (section) => (_.lowerCase(section.displayName || section.name))
    ),
    (section) => ({ label: section.displayName || section.name, value: section.name })
  );
  const [sectionFilter, setSectionFilter] = useState(false);
  const [newTaskDetails, setNewTaskDetails] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const onFieldChange = (fieldname, value) => {
    setNewTaskDetails((prevValues) => ({
      ...prevValues,
      [fieldname]: value
    }));
  };
  const completedOnDay = (task) => {
    if (!task.completed) {
      return false;
    }
    return !!_.find(task.completed, (completeDate) => moment(completeDate.toDate()).isSame(date, 'day'));
  };
  const toggleComplete = (task) => {
    if (!task.completed) {
      // No complete dates yet, makes updating easy
      dispatch(actions.tasks.updateTask(accountId, { ...task, completed: [date] }))
    }
    else {
      const index = _.findIndex(task.completed, (completeDate) => moment(completeDate.toDate()).isSame(date, 'day'));
      if (index >= 0) {
        // Drop the current date from the 'completed' array
        dispatch(actions.tasks.updateTask(accountId, { ...task, completed: _.concat(_.slice(task.completed, 0, index), _.slice(task.completed, index + 1)) }));
      }
      else {
        // Add the current date to the 'completed' array
        dispatch(actions.tasks.updateTask(accountId, { ...task, completed: _.concat(task.completed, date) }));
      }
    }
  };
  const closeDialog = () => {
    setNewTaskDetails(false);
  };
  const saveTask = () => {
    if (_.isEmpty(newTaskDetails.action)) {
      dispatch(actions.appMessageError('You must provide a task description'));
      return;
    }
    if (_.isEmpty(newTaskDetails.section)) {
      dispatch(actions.appMessageError('You must select a section for the new task'));
      return;
    }
    dispatch(actions.tasks.addTask(accountId, {
      ...newTaskDetails
    }));
    closeDialog();
  };
  const getSectionName = (section) => {
    let sectionInfo = sectionMap[section];
    if (sectionInfo) {
      return sectionInfo.displayName || sectionInfo.name;
    }
    return section;
  };

  const filteredTasks = _.groupBy(
    _.sortBy(
      (sectionFilter) ? _.filter(dailyTasks, { section: sectionFilter }) : dailyTasks,
      (task) => (_.lowerCase(task.section))
    ),
    'section'
  );

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Task lists</CustomHeading>
          <Button
              disabled={date < TODAY}
              appearance="primary"
              onClick={() => setNewTaskDetails({ type: 'DAILY', date, section: _.head(sections).value })}
          >New Task</Button>
        </Pane>
        {DatePanel}
        <Pane>
          <FilterSelect
              name="section"
              label="Section"
              options={sections}
              selected={_.find(sections, { value: sectionFilter })}
              setFilter={(name, value) => setSectionFilter(value)}
          />
        </Pane>
      </Block>
      <OpenCloseBlock accountId={accountId} date={date} />
      {_.isEmpty(filteredTasks) && (
        <Block padding={majorScale(2)}>
          <Text>No tasks to display</Text>
        </Block>
      )}
      {_.map(filteredTasks, (tasklist, section) => (
        <Block key={section} marginBottom={majorScale(2)}>
          <CustomHeading level="4" padding={majorScale(2)}>{getSectionName(section)}</CustomHeading>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell flex={`0 1 ${isMobile ? '75%' : '50%'}`}>Task</Table.TextHeaderCell>
              {!isMobile && (<Table.TextHeaderCell flex="1 0">Section</Table.TextHeaderCell>)}
              <Table.TextHeaderCell flex="1 0">Progress</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {_.map(tasklist, (task) => {
                const isComplete = completedOnDay(task);
                return (
                  <Table.Row key={task.id}>
                    <Table.TextCell flex={`0 1 ${isMobile ? '75%' : '50%'}`}>{task.action}</Table.TextCell>
                    {!isMobile && (<Table.TextCell flex="1 0">{getSectionName(task.section)}</Table.TextCell>)}
                    <Table.Cell flex="1 0">
                      <Checkbox
                          checked={isComplete}
                          onChange={() => toggleComplete(task)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Block>
      ))}

      <CustomDialog
          isOpen={!!newTaskDetails}
          title="Add New Task"
          confirmLabel="Save"
          onClose={closeDialog}
          onConfirm={saveTask}
      >
        <Pane>
          <TextInputField
              required
              label="TO DO"
              value={newTaskDetails.action || ''}
              onChange={(e) => onFieldChange('action', e.target.value)}
          />
          <SelectField
              required
              label="Section"
              value={newTaskDetails.section || ''}
              onChange={(e) => onFieldChange('section', e.target.value)}
          >
            {_.map(sections, (section) => (
              <option key={section.value} value={section.value}>{section.label}</option>
            ))}
          </SelectField>
        </Pane>
      </CustomDialog>
    </React.Fragment>
  );
};

DailyTab.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default DailyTab;
