//React
import React, { useEffect, useState } from 'react';
//import { useDispatch } from 'react-redux';

//Libraries
import { Dialog, Heading, Icon, majorScale, minorScale, Pane, Paragraph, Text } from 'evergreen-ui';

//Components
//import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import Button from '../../../components/ui/Button/Button';
import CustomDialog from '../../../components/Dialog/Dialog';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { colors } from '../../../utils/constants';


const planInlcudesData = {
  startPlan: {
    icon: 'heatmap',
    left: [
      'One location',
      'Up to 5 users',
      'Product engineering - Advanced',
      'Procurement - Basic',
      'Inventory - Basic'
    ],
    right: [
      'Tasks & HACCP - Basic',
      'Onboarding support - Limited',
      'Ongoing support - Limited'
    ]
  },
  analyticsPlan: {
    icon: 'charts',
    left: [
      'Unlimited locations',
      'Unlimited users',
      'Product engineering - Advanced',
      'Procurement - Advanced',
      'Inventory - Advanced'
    ],
    right: [
      'Tasks & HACCP - Advanced',
      'Accounting - Basic',
      'Analytics - Basic',
      'Onboarding support - Basic',
      'Ongoing support - Basic',
    ]
  },
  raisePlan: {
    icon: 'layout-auto',
    left: [
      'Unlimited locations',
      'Unlimited users',
      'Product engineering - Advanced',
      'Procurement - Advanced',
      'Inventory - Advanced'
    ],
    right: [
      'Tasks & HACCP - Advanced',
      'Accounting - Basic',
      'Analytics - Basic',
      'Onboarding support - Basic',
      'Ongoing support - Basic',
    ]
  },
  growPlan: {
    icon: 'layout-circle',
    left: [
      'Unlimited locations',
      'Unlimited users',
      'Product engineering - Advanced',
      'Procurement - Advanced',
      'Inventory - Advanced'
    ],
    right: [
      'Tasks & HACCP - Advanced',
      'Accounting - Advanced',
      'Analytics - Advanced',
      'Onboarding support - Advanced',
      'Ongoing support - Advanced',
    ]
  }
}

const BillingTab = ({
  accountId,
  account
}) => {
  const [termsOpen, setTermsOpen] = useState();
  const planType = account?.plan?.type || 'startPlan';
  const planName = account?.plan?.name || 'Start plan';
  const planCost = account?.plan?.cost || 0;
  const planLocations = account?.plan?.nbLocations || 1;
  const iconType = planInlcudesData[planType]?.icon;

  useEffect(() => {
    if (window.Chargebee) {
      window.Chargebee.registerAgain();
    } else {
      console.error('Chargebee is not defined on window');
    }
  }, []);

  const handleManageSubscriptions = () => {
    if (window.Chargebee && window.Chargebee.portal) {
      window.Chargebee.portal.open();
    } else {
      console.error('Chargebee or Chargebee.portal is not defined');
    }
  };

  const customFooter = (
    <Pane display="flex" justifyContent="flex-end" paddingX={majorScale(1)} paddingBottom={majorScale(2)}>
      <Button 
        appearance="minimal" 
        onClick={() => setTermsOpen(false)}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        maxWidth="100%"
      >
        Cancel
      </Button>
    </Pane>
  );

  const PlanType = (
    <Pane margin={majorScale(4)}>
      <Icon
        size={40}
        color={colors.black}
        icon={iconType}
      />
      <Heading marginY={majorScale(2)}>{planName}</Heading>
      <Pane marginY={majorScale(2)}><Text><strong>£{planCost}</strong> / location / month</Text></Pane>
      <Pane marginY={majorScale(2)}>
        <Button appearance='primary' textDecoration='none' >
          <div dangerouslySetInnerHTML={{ __html: '<a style="text-decoration: none; color: inherit" href="javascript:void(0)" data-cb-type="portal" >Manage subscriptions</a>' }} />
        </Button>
      </Pane>
      <Pane marginY={majorScale(2)}><Text>{planLocations} active location</Text></Pane>
      <Pane marginY={majorScale(2)}><Text textDecoration='underline' onClick={() => setTermsOpen(true)} cursor='pointer'>View Miseenplace T&C</Text></Pane>
    </Pane>
  );

  const MapItems = ({items}) => {
    return (
      <Pane marginX={majorScale(2)}>
        {items.map((text, index) => {
            return (
              <Pane display='flex' alignItems='center' key={`include_${index}`} marginY={minorScale(2)}>
                <Icon
                  size={20}
                  icon='tick'
                  color={'rgba(6, 6, 6, 0.6)'}
                />
                <Text marginLeft={minorScale(2)}>{text}</Text>
              </Pane>
            )
          })}
      </Pane>
    )
  }
  
  const planIncludesLeft = planInlcudesData[planType].left;
  const planIncludesRight = planInlcudesData[planType].right;

  const PlanIncludes = (
    <Pane margin={majorScale(4)}>
      <Heading marginX={majorScale(2)} marginY={minorScale(2)}>Includes</Heading>
      <Pane display='flex' alignItems='flex-start' marginY={minorScale(2)}>
        <MapItems items={planIncludesLeft} />
        <MapItems items={planIncludesRight} />
      </Pane>
    </Pane>
  )

  return (
    <>
      <Pane margin={majorScale(2)}>
        <Pane display='flex' alignItems='center'>
          {PlanType}
          {PlanIncludes}
        </Pane>
      </Pane>
      {termsOpen && (
         <CustomDialog
            isOpen
            title="Terms and Conditions"
            // intent="danger"
            hasCancel={false}
            foot={customFooter}
            onClose={() => setTermsOpen(false)}

        >
          <Pane marginBottom={majorScale(2)}>
            <Paragraph marginY={minorScale(2)}>
              <strong>Misenplace</strong> uses <strong>ChargeBee</strong>, a third-party solution, to manage its users' subscriptions. To log in to your subscription account, please enter the email address associated with your Misenplace account on the ChargeBee login page.
            </Paragraph>
            <Paragraph marginY={majorScale(2)}>
              <strong>Misenplace</strong> offers monthly plans with the flexibility to cancel at any time. This means you can switch between plans or cancel your subscription at any point, without being locked into a long-term commitment. Please note that subscription changes may take up to 24 hours to be updated in our system.
            </Paragraph>
          </Pane>
        </CustomDialog>
      )}
    </>
  )
}

export default BillingTab;
