import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addFoodRecord, removeFoodRecord, updateFoodRecord } from '../firebase/foodRecords';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* foodRecordsSaga() {
  yield takeEvery(actionTypes.foodRecords.ADD_FOOD_RECORD, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addFoodRecord, action.record, accountId);
      yield put(actions.appMessageSuccess('Food Record Added'));
    }
    catch (err) {
      yield put(actions.appMessageError('Food Record could not be added'));
    }
  });
  yield takeEvery(actionTypes.foodRecords.REMOVE_FOOD_RECORD, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeFoodRecord, action.recordId, accountId);
      yield put(actions.appMessageSuccess('Food Record Removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('Food Record could not be removed'));
    }
  });
  yield takeEvery(actionTypes.foodRecords.UPDATE_FOOD_RECORD, function* (action) {
    const { id, ...record } = action.record;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateFoodRecord, id, record, accountId);
      yield put(actions.appMessageSuccess('Food Record Updated'));
    }
    catch (err) {
      yield put(actions.appMessageError('Food Record could not be updated'));
    }
  });
};
