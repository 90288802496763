//React
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

//Libraries
import { majorScale, Pane, useTheme } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import DataTable from '../../../components/DataTable/DataTable';
import Header from '../components/Header';
import { CustomPieChart, CustomBarChart } from '../components/Recharts';
import Block from '../../../components/ui/Block/Block'
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { calcOrderTotal } from '../../../utils/functions';

const buildOrders = (orders, area, location) => {
  return orders.map(order => {
    return {
      ...order,
      area,
      location,
      totalCost: calcOrderTotal(order) || order.estimatedCost
    };
  });
};

const SupplierTab = ({ accounts, accountsData }) => {
    const theme = useTheme();

    const orders = _.reverse(
      _.sortBy(
        accounts.locations.reduce((orders, location) => {
          const areas = accounts.areas.filter(a => a.location.id === location.id);
          for (const area of areas) {
            const data = accountsData[area.id];
            if (data.weekOrders) orders = orders.concat(buildOrders(data.weekOrders, area, location));
          }
          return orders;
        }, []),
        (order) => moment((order.deliveryDate) ? order.deliveryDate : order.date, 'YYYY-MM-DD').format('MMMM YYYY')
      )
    );

    const truncateSupplierName = (name) => {
      if (name.length > 8) {
        return `${name.substring(0, 8)}...`;
      }
      return name;
    };
    
    const ordersDataForBarChart = useMemo(() => {
      const supplierValues = {};
      orders.forEach(order => {
        const supplier = accounts.supplierMap[order.supplierId];
        const supplierName = supplier ? supplier.name : 'Unknown Supplier';
        const shortName = supplier ? truncateSupplierName(supplier.name) : 'Unknown Supplier';
        const orderCost = calcOrderTotal(order) || order.estimatedCost;
        const nameKey = `${supplierName}|${shortName}`;
    
        supplierValues[nameKey] = (supplierValues[nameKey] || 0) + orderCost;
      });
    
      const suppliersArray = Object.keys(supplierValues).map(key => {
        const [name, shortName] = key.split('|');
        return {
          name,
          shortName,
          netValue: supplierValues[key]
        };
      });
    
      suppliersArray.sort((a, b) => b.netValue - a.netValue);
      return suppliersArray;
    }, [orders, accounts.supplierMap]);

    const ordersDataForPieChart = useMemo(() => {
      const supplierCosts = {};
    
      orders.forEach(order => {
        const supplier = accounts.supplierMap[order.supplierId];
        const supplierName = supplier ? supplier.name : 'Unknown Supplier';
        const shortName = supplier ? truncateSupplierName(supplier.name) : 'Unknown Supplier';
        const orderCost = calcOrderTotal(order) || order.estimatedCost;
        const nameKey = `${supplierName}|${shortName}`;
    
        supplierCosts[nameKey] = (supplierCosts[nameKey] || 0) + orderCost;
      });
    
      const sortedSuppliers = Object.keys(supplierCosts)
        .map(key => {
          const [name, shortName] = key.split('|');
          return {
            name,
            shortName,
            value: supplierCosts[key]
          };
        })
        .sort((a, b) => b.value - a.value);
    
      const top5Suppliers = sortedSuppliers.slice(0, 7);
      const otherSuppliers = sortedSuppliers.slice(7);
    
      const othersValue = otherSuppliers.reduce((total, supplier) => total + supplier.value, 0);
      if (othersValue > 0) {
        top5Suppliers.push({ name: 'Others', shortName: 'Others', value: othersValue });
      }
    
      return top5Suppliers;
    }, [orders, accounts.supplierMap]);

    // Aggregate Supplier Data
    const aggregatedSupplierData = useMemo(() => {
      const supplierData = {};

      orders.forEach(order => {
        const supplier = accounts.supplierMap[order.supplierId];
        const supplierName = supplier ? supplier.name : 'Unknown Supplier';
        if (!supplierData[supplierName]) {
          supplierData[supplierName] = {
            supplierName,
            totalNetOrder: 0,
            numberOfOrders: 0,
            totalItemsOrdered: 0,
          };
        }
        supplierData[supplierName].totalNetOrder += calcOrderTotal(order) || order.estimatedCost;
        supplierData[supplierName].numberOfOrders += 1;
        supplierData[supplierName].totalItemsOrdered += order.items.length; // assuming each order has an items array
      });

      return Object.values(supplierData).map(supplier => ({
        ...supplier,
        avgPO: supplier.totalNetOrder / supplier.numberOfOrders
      }));
    }, [orders, accounts.supplierMap]);

    const tableHeaders = [
      { label: 'Supplier Name', field: 'supplierName', type: 'text', width: 4 },
      { label: 'Total net order', field: 'totalNetOrder', type: 'numeric', prefix: '£', width: 4 },
      { label: 'Number of orders', field: 'numberOfOrders', type: 'numeric', width: 2 },
      { label: 'Average PO', field: 'avgPO', type: 'numeric', prefix: '£', width: 2 },
      { label: 'Total items ord.', field: 'totalItemsOrdered', type: 'numeric', width: 4 },
    ];

    return (
      <>
        <Pane display="flex">
        <Block width="35%" height="450px" marginBottom={majorScale(2)} display="flex" flexDirection="column" marginRight={majorScale(2)}>
            <Pane padding={majorScale(2)}>
                <CustomHeading level="3" >Percentage order value per suppliers</CustomHeading>
            </Pane>
            <CustomPieChart
                data={ordersDataForPieChart}
                tooltipFields={['name', 'value']}
                tooltipTitles={['Supplier name', 'Order value']}
                labelFields={['shortName']}
                showLegend={false}    
            />
        </Block>
        <Block width="65%" height="450px" marginBottom={majorScale(2)} display="flex" flexDirection="column">
           <Pane padding={majorScale(2)}>
                <CustomHeading level="3" >Total net value per supplier</CustomHeading>
            </Pane>
            <CustomBarChart 
                data={ordersDataForBarChart} 
                barKey="netValue" 
                barColors={[theme.colors.secondary70]}
                height='90%'
                width='100%'
                layout="vertical"
                xKey="netValue"
                yKey="shortName"
                xType="number"
                yType="category"
                valueType="number"
            />
        </Block>
        </Pane>
        <Block marginBottom={majorScale(2)}>
          <Header title="Purchasing orders" />
          <DataTable headers={tableHeaders} items={aggregatedSupplierData} listSize={aggregatedSupplierData.length} />
        </Block>
      </>
    );
  }
  
export default SupplierTab;
