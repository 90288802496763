const MESSAGES_LOAD_SUCCESS = 'MESSAGES_LOAD_SUCCESS';
const MESSAGES_LOAD_FAILURE = 'MESSAGES_LOAD_FAILURE';
const MESSAGES_CLEAR = 'MESSAGES_CLEAR';
const ADD_MESSAGE = 'ADD_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
const UPDATE_MESSAGE = 'UPDATE_MESSAGE';

export const actionTypes = {
  MESSAGES_LOAD_SUCCESS,
  MESSAGES_LOAD_FAILURE,
  MESSAGES_CLEAR,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  UPDATE_MESSAGE
};


const messagesLoadSuccess = (payload, account) => {
  return {
    type: MESSAGES_LOAD_SUCCESS,
    payload,
    account
  };
};

const messagesLoadFailure = (error) => {
  return {
    type: MESSAGES_LOAD_FAILURE,
    error
  };
};

const messagesClear = () => {
  return {
    type: MESSAGES_CLEAR
  };
};

const addMessage = (message, callback) => {
  return {
    type: ADD_MESSAGE,
    message,
    callback
  };
};

const removeMessage = (messageId) => {
  return {
    type: REMOVE_MESSAGE,
    messageId
  };
};

const updateMessage = (message) => {
  return {
    type: UPDATE_MESSAGE,
    message
  };
};

export const actions = {
  messagesLoadSuccess,
  messagesLoadFailure,
  messagesClear,
  addMessage,
  removeMessage,
  updateMessage
};
