export const enforceNumeric = (value) => {
    // Remove any character that is not a digit or a dot
    const validValue = value.replace(/[^0-9.]/g, '');
    const dotIndex = validValue.indexOf('.');
  
    if (dotIndex !== -1) {
      // Limit to two decimal places if a dot is found
      const beforeDot = validValue.slice(0, dotIndex + 1);
      const afterDot = validValue.slice(dotIndex + 1).replace(/\./g, '').slice(0, 2);
      return beforeDot + afterDot;
    }
  
    return validValue;
  };
  