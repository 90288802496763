const BUDGETS_LOAD_SUCCESS = 'BUDGETS_LOAD_SUCCESS';
const BUDGETS_LOAD_FAILURE = 'BUDGETS_LOAD_FAILURE';
const BUDGETS_CLEAR = 'BUDGETS_CLEAR';
const ADD_BUDGET = 'ADD_BUDGET';
const REMOVE_BUDGET = 'REMOVE_BUDGET';
const UPDATE_BUDGET = 'UPDATE_BUDGET';

export const actionTypes = {
  BUDGETS_LOAD_SUCCESS,
  BUDGETS_LOAD_FAILURE,
  BUDGETS_CLEAR,
  ADD_BUDGET,
  REMOVE_BUDGET,
  UPDATE_BUDGET
};


const budgetsLoadSuccess = (payload, account) => {
  return {
    type: BUDGETS_LOAD_SUCCESS,
    payload,
    account
  };
};

const budgetsLoadFailure = (error) => {
  return {
    type: BUDGETS_LOAD_FAILURE,
    error
  };
};

const budgetsClear = () => {
  return {
    type: BUDGETS_CLEAR
  };
};

const addBudget = (accountId, budgetInfo, callback) => {
  return {
    type: ADD_BUDGET,
    budgetInfo,
    callback,
    accountId,
  };
};

const removeBudget = (accountId, budgetId) => {
  return {
    type: REMOVE_BUDGET,
    accountId,
    budgetId,
  };
};

const updateBudget = (accountId, budgetInfo, callback) => {
  return {
    type: UPDATE_BUDGET,
    budgetInfo,
    accountId,
    callback,
  };
};

export const actions = {
  budgetsLoadSuccess,
  budgetsLoadFailure,
  budgetsClear,
  addBudget,
  removeBudget,
  updateBudget
};
