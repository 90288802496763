//React
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Pane, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import PeriodDisplay from './PeriodDisplay';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { TODAYC, TODAYE, getWC, getWE, minDate, maxDate } from '../../../utils/time';
import { current } from '../../../utils/selectors';
import { getStocktakeDate } from '../../../utils/functions';

const dateStoreFormat = 'YYYY-MM-DD';


const DatePanelReport = ({onWeekChange}) => {
  const { accountId } = useParams();
  const theme = useTheme();

  const currentGroup = useSelector((state) => current(state, 'accounts', accountId));
  const firstStockTakeDate = useSelector((state) => {
    const firstStockTake = _.head(
      _.sortBy(
        _.filter(current(state, 'stockTakes', accountId), { 'isDraft': false }),
        (item) => getStocktakeDate(item)
      )
    );

    return (firstStockTake) ? getStocktakeDate(firstStockTake) : TODAYC;
  });

  const [weekInfo, setWeekInfo] = useState({
    start: getWC(TODAYC),
    end: minDate(getWE(TODAYE), TODAYE),
  });
  //console.log(weekInfo.start, 'WeekinfoSTART')
  //console.log(weekInfo.end, 'WeekinfoEND')

  const seePrevWeek = () => {
    // Calculate the start of the previous week based on the current week's start.
    const potentialPrevStart = moment.utc(weekInfo.start).subtract(1, 'weeks').startOf('isoWeek').toDate();
    //console.log("Calculated Start of Previous Week:", potentialPrevStart);
    const lowestDate = firstStockTakeDate ? moment.utc(firstStockTakeDate, dateStoreFormat).toDate() : potentialPrevStart;
    //console.log("Lowest Allowed Date:", lowestDate);
    const prevStart = maxDate(potentialPrevStart, lowestDate);
    //console.log("Resolved Start Date after maxDate:", prevStart);
  
    setWeekInfo({
      start: prevStart,
      end: getWE(prevStart),
    });
    //console.log("Updated Week Info:", {start: prevStart, end: getWE(prevStart)});
    onWeekChange(prevStart);
  };
  
  const seeNextWeek = () => {
    // Calculate the start of the next week based on the current week's start.
    const potentialNextStart = moment.utc(weekInfo.start).add(1, 'weeks').startOf('isoWeek');
  
    if (potentialNextStart.isSameOrBefore(moment.utc(), 'day')) {
      const nextStart = potentialNextStart.toDate(); 
      const endOfWeek = getWE(nextStart);
      const weekEnd = minDate(endOfWeek, TODAYE);
  
      /*console.log("Next Start:", nextStart);
      console.log("Calculated End of Week:", endOfWeek);
      console.log("TODAYE:", TODAYE);
      console.log("Week End after minDate:", weekEnd);*/

      setWeekInfo({
        start: nextStart,
        end: weekEnd
      });
      //console.log(({start: nextStart, end: minDate(getWE(nextStart, TODAYE))}))
      onWeekChange(nextStart);
    }
  };


  return (
    <>
      <Block
          background={theme.colors.offwhite}
          marginBottom={majorScale(2)}
          padding={majorScale(2)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
      >
        <CustomHeading level="3" padding={majorScale(2)}>Weekly View {currentGroup.name}</CustomHeading>
        <Pane
            alignSelf="center"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex="1 0 auto"
            maxWidth={460}
            width="100%"
        >
          <IconWrapper
              appearance="clickable"
              name="arrowLeft"
              onClick={() => seePrevWeek()}
              marginRight={majorScale(2)}
          />
          <PeriodDisplay periodStart={weekInfo.start} periodEnd={weekInfo.end} />
          <IconWrapper
              appearance="clickable"
              name="arrowRight"
              onClick={() => seeNextWeek()}
              marginLeft={majorScale(2)}
              disabled={weekInfo.end >= TODAYE}
          />
        </Pane>
      </Block>
    </>
  );
};

DatePanelReport.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default DatePanelReport;
