import _ from 'lodash';
import moment from 'moment';
import store from 'store/redux';
import { getRecipeByPlu, getModifierItemByPlu, modifierNetPrice, modifierMargin, modifierCost, recipeNetPrice, recipeMargin, recipeCosting } from './functions';
import { current } from './selectors';

const createSaleItem = (recipe, item, modifier = false) => {
  const { id, name, wastage, vat, plu } = recipe;
  const menuprice = modifier ? recipe.price : recipe.menuprice;
  const priceIssues = parseFloat(menuprice) !== parseFloat(item.price);
  const vatIssues = item.vatRate ? parseFloat(vat) !== parseFloat(item.vatRate) : false;
  return {
    qty: item.quantity,
    recipeId: id,
    menuprice: parseFloat(menuprice) || 0,
    name,
    plu,
    wastage: wastage || 0,
    netprice: modifier ? modifierNetPrice(recipe) : recipeNetPrice(recipe),
    margin: modifier ? modifierMargin(recipe) : recipeMargin(recipe),
    cost: modifier ? modifierCost(recipe) : recipeCosting(recipe),
    priceIssues,
    vatIssues,
    itemPrice: parseFloat(item.price),
    itemVat: parseFloat(item.vatRate),
    modifierId: modifier ? recipe.modifierId : null,
  };
}

const convertReceiptsToSales = (accountId, receipts) => {
  const {items} = receipts;
  const salesData = { ...receipts, items: [], unmatched: [] };
  for (const item of items) {
    const plu = item.plu;
    const recipe = getRecipeByPlu(plu, accountId)
    if (!recipe) {
      const modifier = getModifierItemByPlu(plu, accountId);
      if (modifier) {
        const salesItem = createSaleItem(modifier, item, true)
        salesData.items.push(salesItem)
      }
      else {
        salesData.unmatched.push({
          plu,
          qty: item.amount,
          name: item.name
        });
      }
    }
    else {
      const salesItem = createSaleItem(recipe, item)
      salesData.items.push(salesItem);
    }
  }
  return salesData;
}

const itemPlu = (item) => item.productPLU || item.plu;

const createAmount = (item) => item.quantity > 0 ? item.quantity : 1;

const createSaleItemFromReceipt = (receiptItem) => {
  return {
    quantity: createAmount(receiptItem),
    plu: receiptItem.plu,
    vatRate: null,
    name: receiptItem.name,
    price: receiptItem.price
  }
}

const addReceiptItem = (item, saleItem) => {
  const amount = createAmount(item)
  return {
    ...saleItem,
    quantity: saleItem.quantity + amount
  }
}

const addItemToSales = (item, sales) => {
  const index = sales.findIndex(sI => sI.plu === item.plu);
  if (index > -1) sales[index] = addReceiptItem(item, sales[index])
  else sales.push(createSaleItemFromReceipt(item))
  return sales;
}

const convertReceiptsToAnalytics = (receipts) => {
  return receipts.reduce((sales, receipt) => {
    const items = receipt.items;
    if (!items) return sales;

    items.forEach(item => {
      if (item.subItems && item.subItems.length > 0) {
        item.subItems.forEach(mItem => {
          sales = addItemToSales(mItem, sales);
        })
      }
      sales = addItemToSales(item, sales);
    });
    return sales;
  }, []);
}

export const buildReceiptSalesByDate = (accountId, periodStart, periodEnd) => {
  const state = store.getState();
  const qualifyingReceipts = _.filter(current(state, 'receipts', accountId), (receipt) => {
    if (receipt.archive) return false;

    const date = moment.utc(receipt.date, "YYYYMMDD");
    return periodStart.isSameOrBefore(date, 'date') && periodEnd.isSameOrAfter(date, 'date');
  });
  const sales = convertReceiptsToAnalytics(qualifyingReceipts);
  return convertReceiptsToSales(accountId, { items: sales, start: periodStart, end: periodEnd });
}

export default convertReceiptsToSales;
