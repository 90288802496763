//React
import React, { useState } from 'react';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { majorScale, Table, Pane } from 'evergreen-ui';

//Components
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { currency } from '../../../utils/format';
import { colors } from '../../../utils/constants';
import { FilterSelect } from '../../../components';
import { getAllCategories, getSalesRecipeItems } from '../../../utils/functions';


const BestSellers = ({ accountId, salesItems }) => {
  const [categoryFilter, setCategoryFilter] = useState(false);
  const categoryOptions = _.map(
    _.sortBy(
      getAllCategories(accountId),
      'name'
    ),
    (item) => ({ label: item.name, value: item.name })
  );
  const items = _.reverse(
    _.sortBy(
      getSalesRecipeItems(salesItems, accountId),
      'qty'
    )
  );

  const filteredItems = (categoryFilter) ? _.filter(items, { 'category': categoryFilter }) : items;

  return (
    <>
      <Pane paddingX={majorScale(2)}>
        <CustomHeading level="4" marginBottom={majorScale(2)}>Best Sellers</CustomHeading>
        <FilterSelect
            options={categoryOptions}
            name="category"
            label="Category"
            marginBottom={majorScale(2)}
            selected={_.find(categoryOptions, { 'value': categoryFilter })}
            setFilter={(name, newValue) => setCategoryFilter(newValue)}
        />
      </Pane>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flex="3">Recipe Name</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="1">Qty</Table.TextHeaderCell>
          <Table.TextHeaderCell flex="1" textAlign="right">Net</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height={48 * 5}>
          {_.isEmpty(filteredItems) && (
            <Table.Row>
              <Table.TextCell>No items to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(filteredItems, (item) => (
            <Table.Row key={`${item.recipeId}-${item.plu}`}>
              <Table.TextCell flex="3">{item.name}</Table.TextCell>
              <Table.TextCell flex="1">{item.qty}</Table.TextCell>
              <Table.TextCell
                  flex="1"
                  textAlign="right"
                  textProps={{ color: colors.lightTeal }}
              >£ {currency(item.totalnet)}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

BestSellers.propTypes = {
  accountId: PropTypes.string.isRequired,
  salesItems: PropTypes.arrayOf(
    PropTypes.shape({
      recipeId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      qty: PropTypes.number.isRequired,
      totalgross: PropTypes.number.isRequired,
    }),
  ),
};

export default BestSellers;
