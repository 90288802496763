import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PeriodNavigation from './PerdiodNavigation';
import ReportFilters from './ReportFilters';
import { buildAccountsData, buildAggregatedReceipts, buildWeekDates } from '../../../utils/reports';
import moment from 'moment';
import { Dialog, Heading, Icon, IconButton, majorScale, minorScale, Pane, Table, Text } from 'evergreen-ui';
import DataTable from '../../../components/DataTable/DataTable';
import Block from 'components/ui/Block/Block';
import _ from 'lodash';
import { currency } from '../../../utils/format';
import { colors } from '../../../utils/constants';
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/actions';

const receiptStatusString = (receipt) => {
  return receipt.status === 6 ?
    'FAILED' :
    receipt.status < 90 ?
    'ACCEPTED' :
    receipt.status === 90 ?
    'FINALISED' :
    receipt.status === 95 ?
    'AUTO FINALISED' :
    receipt.status === 100 ?
    'CANCEL' :
    receipt.status === 110 ?
    'CANCELLED' :
    receipt.status < 129 ?
    'FAILED' :
    'RESOLVED'
}

const calcReceiptSubtotal = (receipt) => {
  return receipt.items?.reduce((price, item) => {
    price += (item.price * item.quantity);
    const subItemsPrice = item.subItems?.reduce((price, item) => {
      if (item.price) price += (item.price * item.quantity);
      return price;
    }, 0) || 0;
    price += subItemsPrice;
    return price;
  }, 0) || 0;
}

const processReceipts = (receipts) => {
  return receipts.map(receipt => {
    const deliveryDate = moment(receipt.deliveryTime, "YYYY-MM-DDTHH:mm:ssZ");
    return {
      ...receipt,
      totalPrice: receipt.payment?.amount || 0,
      deliveryDate: `${deliveryDate.format('DD/MM/YY')} at ${deliveryDate.format('HH:mm')}`,
      status: receiptStatusString(receipt),
      subTotal: calcReceiptSubtotal(receipt),
      date: deliveryDate
    }
  }).sort((a,b) => a.date.isAfter(b.date) ? 1 : -1);
}

const ReceiptsTable = ({receipts}) => {
  const dispatch = useDispatch();
  const processed = processReceipts(receipts);
  const [expandedRows, setExpandedRows] = useState([]);
  const [archiveReceipt, toggleArchiveReceipt] = useState(false);
  const toggleRow = (rowId) => {
    if (_.includes(expandedRows, rowId)) {
      setExpandedRows(_.without(expandedRows, rowId));
    }
    else {
      setExpandedRows(_.concat(expandedRows, rowId));
    }
  };
  const callToggleReceipt = () => {
    dispatch(actions.receipts.toggleArchiveReceipt(archiveReceipt, archiveReceipt.accountId));
  }
  return (
    <React.Fragment>
      <Dialog
          isShown={!!archiveReceipt}
          title={`${archiveReceipt.archive ? 'Una' : 'A'}rchive receipt`}
          intent="danger"
          confirmLabel={`${archiveReceipt.archive ? 'Una' : 'A'}rchive`}
          onConfirm={(close) => {
            callToggleReceipt();
            close();
          }}
          onCloseComplete={() => toggleArchiveReceipt(false)}
      >
        <Text>Are you sure you wish to {`${archiveReceipt.archive ? 'una' : 'a'}rchive`} the receipt for order {archiveReceipt.channelOrderDisplayId}?</Text>
      </Dialog>
      <Block flex="1 0 auto" display="flex" flexDirection="column">
        <Heading is="h3" margin={majorScale(2)}>Receipts</Heading>
        <Table>
          <Table.Head paddingLeft={0}>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Location</Table.TextHeaderCell>
            <Table.TextHeaderCell>Order Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Channel</Table.TextHeaderCell>
            <Table.TextHeaderCell>Receipt Id</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell>Sub total</Table.TextHeaderCell>
            <Table.TextHeaderCell>Total</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={50} flexGrow={0} flexShrink={0}>Archive</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={50} flexGrow={0} flexShrink={0}>View</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(processed) && (
              <Table.Row>
                <Table.TextCell>No receipts to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(processed, (receipt) => {
              const isExpanded = _.includes(expandedRows, receipt.id);
              return (
                <React.Fragment key={receipt.id}>
                  <Table.Row isSelectable onSelect={() => toggleRow(receipt.id)}>
                    <Table.TextCell >{receipt.deliveryDate}</Table.TextCell>
                    <Table.TextCell >{receipt.location}</Table.TextCell>
                    <Table.TextCell >{receipt.channelOrderDisplayId}</Table.TextCell>
                    <Table.TextCell >{receipt.channel}</Table.TextCell>
                    <Table.TextCell >{receipt.id}</Table.TextCell>
                    <Table.TextCell >{receipt.status}</Table.TextCell>
                    <Table.TextCell >£{currency(receipt.subTotal)}</Table.TextCell>
                    <Table.TextCell >£{currency(receipt.totalPrice)}</Table.TextCell>
                    <Table.Cell flexBasis={50} flexGrow={0}>
                      <IconButton
                        intent={!receipt.archive ? 'danger' : null}
                        icon={!receipt.archive ? 'lock' : 'unlock'}
                        onClick={() => toggleArchiveReceipt(receipt)}
                      />
                    </Table.Cell>
                    <Table.Cell flexBasis={50} flexGrow={0}>
                      <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} color={colors.orange} size={16} />
                    </Table.Cell>
                  </Table.Row>
                  {isExpanded && (
                    <Pane padding={majorScale(2)}>
                      <Pane padding={minorScale(2)}>
                        <Text>Order details</Text>
                      </Pane>
                      
                      <Table width={650} padding={minorScale(2)}>
                        <Table.Head paddingLeft={0} background='none' borderBottom='none'>
                          <Table.TextHeaderCell>Articles</Table.TextHeaderCell>
                          <Table.TextHeaderCell></Table.TextHeaderCell>
                          <Table.TextHeaderCell>Price</Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                          {receipt.items.length > 0 && receipt.items.map((item, iIndex) => {
                            const name = `${item.quantity} ${item.name}`;
                            return (
                              <React.Fragment key={`item_${iIndex}`}>
                                <Table.Row background='none' borderBottom='none' >
                                  <Table.Cell>
                                    <Text fontSize={12} fontWeight={'500'}>{name}</Text>
                                  </Table.Cell>
                                  <Table.TextCell>{item.plu}</Table.TextCell>
                                  <Table.Cell>
                                    <Text fontSize={12} fontWeight={'500'}>£{currency(item.price)}</Text>
                                  </Table.Cell>
                                </Table.Row>
                                {item.subItems?.length > 0 && item.subItems.map((subItem, sIndex) => {
                                  return (
                                    <Table.Row key={`s_${sIndex}`} background='none' borderBottom='none'>
                                       <Table.Cell>
                                        <Text paddingLeft={25} fontSize={12} fontWeight={'400'}>{subItem.quantity} {subItem.name}</Text>
                                      </Table.Cell>
                                      <Table.TextCell>{subItem.plu}</Table.TextCell>
                                      <Table.Cell>
                                        <Text fontSize={12} fontWeight={'500'}>£{currency(subItem.price)}</Text>
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                })}
                              </React.Fragment>
                            )
                          })}
                          <Table.Row background='none' borderBottom='none'>
                            <Table.Cell>
                              <Text fontSize={12} fontWeight={'500'}>Sub-total</Text>
                            </Table.Cell>
                            <Table.TextCell></Table.TextCell>
                            <Table.Cell>
                              <Text fontSize={12} fontWeight={'500'}>£{currency(receipt.subTotal)}</Text>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row background='none' borderBottom='none'>
                            <Table.TextCell>Delivery cost</Table.TextCell>
                            <Table.TextCell></Table.TextCell>
                            <Table.TextCell>£{currency(receipt.deliveryCost)}</Table.TextCell>
                          </Table.Row>
                          <Table.Row background='none' borderBottom='none'>
                            <Table.TextCell>Discount</Table.TextCell>
                            <Table.TextCell></Table.TextCell>
                            <Table.TextCell>£{currency(receipt.discountTotal)}</Table.TextCell>
                          </Table.Row>
                          <Table.Row background='none' borderBottom='none'>
                            <Table.Cell>
                              <Text fontSize={12} fontWeight={'500'}>Total payment</Text>
                            </Table.Cell>
                            <Table.TextCell></Table.TextCell>
                            <Table.Cell>
                              <Text fontSize={12} fontWeight={'500'}>£{currency(receipt.totalPrice)}</Text>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Pane>
                  )}
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </Block>
    </React.Fragment>
  )
}

const Receipts = ({ accounts, weekProps }) => {
  const weekdates = buildWeekDates(weekProps.weekInfo.start);
  const accountsData = buildAccountsData({accounts, options: { receipts: true }, ...weekdates});
  const receipts = buildAggregatedReceipts(accountsData);
  const data = {accounts, accountsData, receipts, weekdates};
  return (
    <>
      <PeriodNavigation {...weekProps} />
      <ReportFilters accounts={accounts} />
      <ReceiptsTable {...data} />
    </>
  );
};

Receipts.propTypes = {
  accounts: PropTypes.object.isRequired,
  weekProps: PropTypes.object.isRequired
};

export default Receipts;
