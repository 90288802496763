//React
import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import { majorScale, Pane, Table } from 'evergreen-ui';

//Components
import Button from '../../../components/ui/Button/Button';
import GroupEditDialog from '../AssignGroupsComponents/GroupEditDialog';
import GroupRemoveDialog from '../AssignGroupsComponents/GroupRemoveDialog';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { actions } from '../../../store/actions';
import { accountTypes } from '../../../utils/constants';
import { buildGroupAccountDepartments } from '../../../utils/departments';


// Add department -> name field only
// Select Group
const AssignGroupsTab = ({ account, isOwner, userAccounts, setErrors, errors, locationAreas, headAccount }) => {
  const dispatch = useDispatch();

  const accountDepartments = useSelector(state => headAccount ?  state.departments[headAccount.id] : []);
  const [addingTemp, setAddingTemp] = useState(false);
  const [removingTemp, setRemovingTemp] = useState(false);
  const [editingDept, setEditingDept] = useState(null);
  const [addingGroupDept, setAddingGroupDept] = useState(false);
  const [addingGroupDeptOptions, setAddingGroupDeptOptions] = useState(false);
  const [removingGroupDept, setRemovingGroupDept] = useState(false);
  const [childDeptTemplate, setChildDeptTemplate] = useState(null);

  const userDepartmentTemplates = _.sortBy(
    _.filter(
      userAccounts,
      (account) =>
        (account.type !== accountTypes.OWNER &&
         account.type !== accountTypes.LOCATION &&
         !account.departments // &&
        //  isLocationGroup(account, location) &&
        //  !belongsToAreaGroup(account, locationAreas)
        )
    ),
    'name'
  );

  const setEditDeptTemp = (dept) => {
    setEditingDept(dept);
    setAddingTemp(true);
  }

  const setEditGroupDeptTemps = (dept, options) => {
    setAddingGroupDept(dept);
    setAddingGroupDeptOptions(options);
  }

  const confirmRemoveTemp = (temp, dept) => {
    setEditingDept(dept);
    setRemovingTemp(temp);
  }

  const confirmRemoveGroupDept = (dept, group) => {
    setEditingDept(group);
    setRemovingGroupDept(dept);
  }

  const updateChildDepartment = () => {
    if (childDeptTemplate && editingDept) {
      const departments = _.uniq([ ...editingDept.departments, childDeptTemplate ]);
      const addGroupAccount = userAccounts[childDeptTemplate];
      const addGroupDepartments = addGroupAccount ? buildGroupAccountDepartments(addGroupAccount) : [];
      const finalGroupDepartments = account.group_departments || {};
      finalGroupDepartments[childDeptTemplate] = addGroupDepartments;
      dispatch(actions.accounts.accountUpdate({
        ...editingDept,
        departments,
        group_departments: finalGroupDepartments
      }));
      setAddingTemp(false);
      setChildDeptTemplate(null);
      setEditingDept(null);
    }
  }

  const removeChildDepartmentDepartment = () => {
    if (editingDept && removingTemp) {
      const departments = _.without(editingDept.departments, removingTemp.id);
      const finalGroupDepartments = account.group_departments || {};
      finalGroupDepartments[removingTemp.id] = [];
      dispatch(actions.accounts.accountUpdate({
        ...editingDept,
        departments,
        group_departments: finalGroupDepartments
      }));
      setRemovingTemp(null);
      setEditingDept(null);
    }
  }

  const updateGroupDepartment = () => {
    if (childDeptTemplate && addingGroupDept) {
      const finalGroupDepartments = account.group_departments || {};
      const departments = finalGroupDepartments[addingGroupDept.id] || [];
      const dept = addingGroupDeptOptions.find(d => d.id === childDeptTemplate);
      departments.push(dept);
      finalGroupDepartments[addingGroupDept.id] = departments;
      dispatch(actions.accounts.accountUpdate({
        ...account,
        group_departments: finalGroupDepartments
      }));
      setAddingGroupDept(false);
      setChildDeptTemplate(null);
      setAddingGroupDeptOptions(null);
    }
  }

  const removeGroupDepartment = () => {
    if (editingDept && removingGroupDept) {
      const finalGroupDepartments = account.group_departments || {};
      const groupDepartments = finalGroupDepartments[editingDept.id] || [];
      const index = groupDepartments.findIndex(d => d.id === removingGroupDept.id);
      if (index > -1) {
        groupDepartments.splice(index, 1);
      }
      finalGroupDepartments[editingDept.id] = groupDepartments;
      dispatch(actions.accounts.accountUpdate({
        ...account,
        group_departments: finalGroupDepartments
      }));
      setRemovingGroupDept(null);
      setEditingDept(null);
    }
  }

  const getTemplateName = (temp) => {
    return userAccounts[temp] ? userAccounts[temp].name : '';
  }

  const getGroupDeptName = (temp) => {
    return addingGroupDeptOptions?.find(a => a.id === temp)?.name
  }
  
  const filterSelectedTemplates = (dept) => {
    if (!dept || !dept.departments) return userDepartmentTemplates;

    return userDepartmentTemplates.filter(t => t.name && dept.departments.indexOf(t.id) === -1);
  }

  const accountHasGroupDep = (dep, group, account) => {
    if (!account.group_departments) return false;

    if (!account.group_departments[group.id]) return false;

    if (account.group_departments[group.id].findIndex(d => d.id === dep.id) > -1) return true
  }

  const buildAllowedGroupDepartments = (group) => {
    // area is the account
    // departments are only allowed once per group
    return accountDepartments.filter(dep => {
      if (locationAreas.findIndex(account => accountHasGroupDep(dep, group, account)) === -1) {
        return true;
      }

      return false;
    });
  }

  const disabled = !isOwner();
  if (!account) return null;

  return (
    <Fragment>
      <Pane>
        <CustomHeading level="3" margin={majorScale(2)}>Groups</CustomHeading>
        <Table marginBottom={majorScale(2)}>
          <Table.Head>
            <Table.TextHeaderCell>Group</Table.TextHeaderCell>
            <Table.TextHeaderCell>Departments</Table.TextHeaderCell>
            <Table.TextHeaderCell flex="0 0 56px">Remove</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
          <React.Fragment key={account.id}>
            { !_.isEmpty(account.departments) && _.map(account.departments, (department) => {
              department = userAccounts[department];
              const groupDepartments = account.group_departments ? account.group_departments[department.id] : [];
              const allGroupDepartments = buildAllowedGroupDepartments(department)
              const remainingGroupDepartments = allGroupDepartments.filter(t => t.name && (groupDepartments || [])?.findIndex(gD => gD.id === t.id) === -1);
              return (
                <React.Fragment key={`${department.id}_dep`}>
                  <Table.Row >
                    <Table.TextCell>{department.name}</Table.TextCell>
                    <Table.TextCell></Table.TextCell>
                    <Table.Cell flex="0 0 56px">
                      <IconWrapper
                          name="trash"
                          appearance="danger"
                          onClick={() => confirmRemoveTemp(department, account)}
                          disabled={disabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                  {groupDepartments?.length > 0 && groupDepartments.map((groupDep) => {
                    return (
                      <React.Fragment key={`${groupDep.id}_dep`}>
                        <Table.Row >
                          <Table.TextCell></Table.TextCell>
                          <Table.TextCell>{groupDep.name}</Table.TextCell>
                          <Table.Cell flex="0 0 56px">
                            <IconWrapper
                                name="trash"
                                appearance="danger"
                                onClick={() => confirmRemoveGroupDept(groupDep, department)}
                                disabled={disabled}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    )
                  })}
                  {remainingGroupDepartments.length > 0 && (
                    <React.Fragment>
                      <Table.Row >
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell flex="0 0 200px">
                          <Button
                            appearance="primary"
                            onClick={() => setEditGroupDeptTemps(department, remainingGroupDepartments)}
                            disabled={disabled}
                            margin={majorScale(2)}
                          >Add department</Button>
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )
            })
          }
          {
            !_.isEmpty(filterSelectedTemplates(account)) &&
            <Pane display='flex' justifyContent='flex-end'>
              <Button
                appearance="primary"
                onClick={() => setEditDeptTemp(account)}
                disabled={disabled}
                margin={majorScale(2)}
              >Add group</Button>
            </Pane>
          }
          </ React.Fragment>
          </Table.Body>
        </Table>
      </Pane>
      <GroupRemoveDialog
        removingTemp={removingTemp}
        setRemovingTemp={setRemovingTemp}
        removeDepartmentTemplate={removeChildDepartmentDepartment}
      />
      <GroupRemoveDialog
        removingTemp={removingGroupDept}
        setRemovingTemp={setRemovingGroupDept}
        removeDepartmentTemplate={removeGroupDepartment}
        type='group'
      />
      <GroupEditDialog
        addingTemp={addingTemp}
        setAddingTemp={setAddingTemp}
        childDeptTemplate={childDeptTemplate}
        setChildDeptTemplate={setChildDeptTemplate}
        updateChildDepartment={updateChildDepartment}
        setErrors={setErrors}
        setEditingDept={setEditingDept}
        userDepartmentTemplates={filterSelectedTemplates(editingDept)}
        getTemplateName={getTemplateName}
        errors={errors}
      />
      <GroupEditDialog
        addingTemp={!!addingGroupDept}
        setAddingTemp={setAddingGroupDept}
        childDeptTemplate={childDeptTemplate}
        setChildDeptTemplate={setChildDeptTemplate}
        updateChildDepartment={updateGroupDepartment}
        setErrors={setErrors}
        setEditingDept={setAddingGroupDept}
        userDepartmentTemplates={addingGroupDeptOptions}
        getTemplateName={getGroupDeptName}
        errors={errors}
        type='test'
      />
    </Fragment>
  )
}

AssignGroupsTab.propTypes = {
  account: PropTypes.object.isRequired,
  isOwner: PropTypes.func.isRequired,
  userAccounts: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

export default AssignGroupsTab;
