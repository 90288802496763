//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale, Table, Pane, minorScale } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import DataTable from '../../../components/DataTable/DataTable'
import UploadModal from './UploadModal';

//Files
import { dateYearFormat } from '../../../utils/time';
import { current } from '../../../utils/selectors';
import FilterBar from '../../../components/FilterBar/FilterBar';
import IconWrapper from '../../../components/Icons/Icons';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { filterItems, requestInvoiceUploadImage } from '../../../utils/functions';
import { actions } from '../../../store/actions';


const Uploads = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const [filters, setFilters] = useState({});
  const supplierMap = useSelector((state) => _.keyBy(current(state, 'suppliers', accountId), 'id'));
  const uploads = useSelector((state) => current(state, 'invoiceUploads', accountId).map(u => ({...u, supplierName: supplierMap[u.supplierId]?.name || '[undefined]' })).sort((a,b) => {
    if (a.date === 'Invalid date') return 1;
    if (b.date === 'Invalid date') return -1;
    const dateA = moment(a.date, 'YYYY-MM-DD');
    const dateB = moment(b.date, 'YYYY-MM-DD');
    return dateA.isAfter(dateB) ? -1 : 1;
  }));
  console.log(uploads,'uploads', accountId)
  //useSelector((state) => console.log(state));
  
  const account = useSelector((state) => state.accounts[state.currentAccount]);
  const [reconcileModal, setReconcileModal] = useState(false);

  const updateFilters = (filterName, value) => {
    setFilters((prevFilters) => {
      if (!value) {
        let newFilters = Object.assign({}, prevFilters);
        delete newFilters[filterName];
        return newFilters;
      }
      return { ...prevFilters, [filterName]: value };
    });
  };

  const requestInvoice = (upload) => {
    //console.log('requestInvoice called with upload:', upload);
    if (upload.results?.nanonets_image_urls?.length > 0) {
      const nanonetsImageUrl = upload.results.nanonets_image_urls[0];
      //console.log('Opening nanonets_image_url:', nanonetsImageUrl);
      window.open(nanonetsImageUrl, '_blank');
      return;
    }
    const filename = upload.original_imagefilename;
    //console.log('Filename:', filename);
    if (!filename) {
      //console.error('File not available.');
      dispatch(actions.appMessageError('File not available.'));
      return;
    }
    //console.log('Requesting invoice upload image with filename:', filename);
    requestInvoiceUploadImage(filename);
  };
  
  const supOptions = () => {
    if (supplierMap) {
      return [{label: 'Undefined', value: '[undefined]'}].concat(_.map(supplierMap, (cat) => ({ label: cat.name, value: cat.name })));
    }
    return [];
  };

  const filterFields = [
    { label: 'Supplier', name: 'supplierName', options: supOptions() }
  ];

  // Define headers for DataTable
  const headers = [
    { label: 'Date', field: 'date', width: 3, format: (value) => dateYearFormat(moment(value, 'YYYY-MM-DD').toDate()) },
    { label: 'Supplier', field: 'supplierName', width: 4 },
    { label: 'Invoice Nb', field: 'invoiceNumber', width: 3 },
    { label: 'Status', field: 'status', width: 4 },
    { label: 'File', field: 'file', width: 1, type: 'action', icon: 'paperclip', onClick: (upload) => requestInvoice(upload)}
  ];

    // Define headers for DataTable
  const mobileHeaders = [
    { label: 'Date', field: 'date', width: 4, format: (value) => dateYearFormat(moment(value, 'YYYY-MM-DD').toDate()) },
    { label: 'Supplier', field: 'supplierName', width: 8 },
    { label: 'Invoice Nb', field: 'invoiceNumber', width: 4, row: 2 },
    { label: 'Status', field: 'status', width: 4, row: 2 },
    { label: 'File', field: 'file', width: 3, row: 2, type: 'action', icon: 'paperclip', onClick: (upload) => requestInvoice(upload)}
  ];

  /*const renderUploadRow = (upload) => {
    return (
      <Table.Row
        key={upload.id}
        flexFlow={(isMobile) ? 'row wrap' : 'row nowrap'}
        height="48px"
        paddingY={majorScale(1)}
      >
        <Table.TextCell >{dateYearFormat(moment(upload.date, 'YYYY-MM-DD').toDate())}</Table.TextCell>
        <Table.TextCell
            flexGrow={3}
            flexBasis={(isMobile) ? '100%' : 'auto'}
        >{upload.supplierName}</Table.TextCell>
        <Table.TextCell >{upload.invoiceNumber}</Table.TextCell>
        <Table.TextCell >{upload.status}</Table.TextCell>
        <Table.TextCell
            flexBasis={(isMobile) ? '100%' : 'auto'}
        >
          <IconWrapper
            name="paperclip"
            appearance="clickable"
            onClick={() => requestInvoice(upload)}
          />
        </Table.TextCell>
      </Table.Row>
    )
  }*/

  const items = filterItems(uploads, filters);
  console.log(items, 'tttte')

  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)}>
        <Pane display='flex' alignItems='center'>
          <Pane flex={1} >
            <CustomHeading level="3" padding={majorScale(2)}>Invoices</CustomHeading>
          </Pane>
          {account?.has_nanonets && <Button appearance="primary" margin={majorScale(2)} is={Link} to={`/${accountId}/procurement/uploads/new`} >Upload</Button>}
        </Pane>
        <Pane marginBottom={majorScale(2)} marginLeft={majorScale(2)}>
          <FilterBar
            filterFields={filterFields}
            filters={filters}
            setFilter={updateFilters}
            // searchPlaceholder="Search for a supplier"
            // searchOnChange={searchOnChange}
            flex="1 0 auto"
            hideSearch
          />
        </Pane>
        <DataTable 
          headers={(isMobile) ? mobileHeaders : headers} 
          items={items} 
          onRowSelect={requestInvoice} 
          emptyText="No uploads to display" 
          listHeight={600}
        />
      </Block>
    </React.Fragment>
  );
};

export default Uploads;
