//React
import React  from 'react';
import { useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Table, Avatar, Checkbox } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import useTasks from '../../../hooks/useTasks';
import CommentPane from './CommentPane';
import CustomHeading from '../../../components/Headings/Headings';


const OpenBlock = ({ tasksHistory, toggleComplete, completedOnDay, isMobile, openTasks, editable, date, closeTasks, type = 'OPEN' }) => {
  const title = type === 'CLOSE' ? 'Closing' : 'Opening';
  const tasks = type === 'CLOSE' ? closeTasks : openTasks;
  const { accountId } = useParams();
  return (
    <Block marginBottom={majorScale(2)}>
      <CustomHeading level="4" padding={majorScale(2)}>{title}</CustomHeading>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell flex={`0 1 ${isMobile ? '75%' : '50%'}`}>Task</Table.TextHeaderCell>
          <Table.TextHeaderCell>Progress</Table.TextHeaderCell>
          {!isMobile && (<Table.TextHeaderCell>Initials</Table.TextHeaderCell>)}
        </Table.Head>
        <Table.Body>
          {_.isEmpty(tasks) && (
            <Table.Row>
              <Table.TextCell>No tasks to display</Table.TextCell>
            </Table.Row>
          )}
          {_.map(tasks, (task) => {
            const isComplete = completedOnDay(task);
            return (
              <Table.Row key={task.id}>
                <Table.TextCell flex={`0 1 ${isMobile ? '75%' : '50%'}`}>{task.action}</Table.TextCell>
                <Table.Cell>
                  <Checkbox
                      checked={isComplete}
                      disabled={!editable}
                      onChange={() => toggleComplete(task)}
                  />
                </Table.Cell>
                {!isMobile && (<Table.Cell>
                  {isComplete && (
                    <Avatar size={24} name={tasksHistory[task.id].userName} />
                  )}
                </Table.Cell>)}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <CommentPane
        accountId={accountId}
        date={date}
        type={type}
      />
    </Block>
  )
}

const OpenCloseBlock = (props) => {
  const taskProps = useTasks(props);
  return (
    <>
      <OpenBlock {...taskProps} {...props} />
      <OpenBlock {...taskProps} {...props} type='CLOSE' />
    </>
  )
}

export default OpenCloseBlock;
