const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS';
const ORDERS_LOAD_FAILURE = 'ORDERS_LOAD_FAILURE';
const ORDERS_CLEAR = 'ORDERS_CLEAR';
const ADD_ORDER = 'ADD_ORDER';
const REMOVE_ORDER = 'REMOVE_ORDER';
const TOGGLE_ORDER_FINAL = 'TOGGLE_ORDER_FINAL';
const UPDATE_ORDER = 'UPDATE_ORDER';
const QUICK_ORDER_SET = 'QUICK_ORDER_SET';
const QUICK_ORDER_CLEAR = 'QUICK_ORDER_CLEAR';

export const actionTypes = {
  ORDERS_LOAD_SUCCESS,
  ORDERS_LOAD_FAILURE,
  ORDERS_CLEAR,
  ADD_ORDER,
  REMOVE_ORDER,
  UPDATE_ORDER,
  QUICK_ORDER_CLEAR,
  QUICK_ORDER_SET,
  TOGGLE_ORDER_FINAL
};


const ordersLoadSuccess = (payload, account) => {
  return {
    type: ORDERS_LOAD_SUCCESS,
    payload,
    account
  };
};

const ordersLoadFailure = (error) => {
  return {
    type: ORDERS_LOAD_FAILURE,
    error
  };
};

const ordersClear = () => {
  return {
    type: ORDERS_CLEAR
  };
};

const addOrder = (accountId, order, callback) => {
  return {
    type: ADD_ORDER,
    order,
    accountId,
    callback,
  };
};

const toggleOrderFinal = (accountId, order) => {
  return {
    type: TOGGLE_ORDER_FINAL,
    order,
    accountId,
  };
};

const removeOrder = (accountId, orderId) => {
  return {
    type: REMOVE_ORDER,
    orderId,
    accountId,
  };
};

const updateOrder = (accountId, order, callback) => {
  return {
    type: UPDATE_ORDER,
    order,
    accountId,
    callback,
  };
};

const quickOrderSet = (optionId, quantity, ingredientId) => {
  return {
    type: QUICK_ORDER_SET,
    optionId,
    ingredientId,
    quantity
  };
};

const quickOrderClear = () => {
  return {
    type: QUICK_ORDER_CLEAR
  };
};

export const actions = {
  ordersLoadSuccess,
  ordersLoadFailure,
  ordersClear,
  addOrder,
  removeOrder,
  updateOrder,
  quickOrderClear,
  quickOrderSet,
  toggleOrderFinal
};
