//React
import React, { useState } from 'react';
import { useNavigate, Link, useParams, Routes, Route } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import moment from 'moment';
import { majorScale } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import TabNavigation from '../../components/ui/TabNavigation/TabNavigation';
import Tab from '../../components/ui/Tab/Tab';
import PrepTab from './components/PrepTab';
import TempLists from './components/TempLists';

//Files
import { TODAY } from '../../utils/time';
import DatePanel from './components/DatePanel';
import DailyTab from './components/DailyTab';


const tabs = {
  daily: 'Task lists',
  prep: 'Preparation lists',
  temp: 'Temperature logs'
};


const Tasks = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const currentTab = window.location.pathname.split('/')[3] || 'daily';
  const [currentDate, setCurrentDate] = useState(TODAY); // Default to today's date (at 00:00:00)

  const decrementDate = () => {
    setCurrentDate(moment(currentDate).add(-1, 'days').toDate());
  };
  const incrementDate = () => {
    setCurrentDate(moment(currentDate).add(1, 'days').toDate());
  };

  
  return (
    <Page title="Tasks & HACCP">
      <DatePanel date={currentDate} incrementDate={incrementDate} decrementDate={decrementDate} />
      <TabNavigation paddingBottom={majorScale(2)}>
        {_.map(tabs, (tabName, tabKey) => (
          <Tab
              key={tabKey}
              is={Link}
              to={`/${accountId}/tasks/${tabKey}`}
              isSelected={currentTab === tabKey}
          >{tabName}</Tab>
        ))}
      </TabNavigation>
      <Routes>
        <Route path="/prep" element={<PrepTab date={currentDate} accountId={accountId} />} />
        <Route path="/temp/*" element={<TempLists date={currentDate} accountId={accountId} />} />
        <Route path="/daily" element={<DailyTab date={currentDate} accountId={accountId} />} />
        <Route path="/*" element={<DailyTab date={currentDate} accountId={accountId} />} /> 
      </Routes>
    </Page>
  );
};


export default Tasks;
