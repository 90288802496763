import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addArea, updateArea, removeArea } from '../firebase/areas';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* areasSaga() {
  yield takeEvery(actionTypes.areas.ADD_AREA, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addArea, action.areaInfo, accountId);
      yield put(actions.appMessageSuccess('Area Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Area could not be added'));
    }
  });
  yield takeEvery(actionTypes.areas.REMOVE_AREA, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeArea, action.areaId, accountId);
      yield put(actions.appMessageSuccess('Area Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Area could not be removed'));
    }
  });
  yield takeEvery(actionTypes.areas.UPDATE_AREA, function* (action) {
    const { id, ...areaInfo } = action.areaInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateArea, id, areaInfo, accountId);
      yield put(actions.appMessageSuccess('Area Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Area could not be updated'));
    }
  });
};
