import { getCollectionRef } from './common';

export const addReportsIncidentals = (incidentals, accountId) => {
  const incidentalsRef = getCollectionRef('reportsIncidentals', accountId);
  return incidentalsRef.add(incidentals);
};

export const removeReportsIncidentals = (incidentalsId, accountId) => {
  const incidentalsRef = getCollectionRef('reportsIncidentals', accountId);
  return incidentalsRef.doc(incidentalsId).delete();
};

export const updateReportsIncidentals = (incidentalsId, incidentals, accountId) => {
  const incidentalsRef = getCollectionRef('reportsIncidentals', accountId);
  return incidentalsRef.doc(incidentalsId).update(incidentals);
};
