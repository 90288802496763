import { getCollectionRef } from './common';

export const addMessage = (message, accountId) => {
  const messagesRef = getCollectionRef('messages', accountId);
  return messagesRef.add(message);
};

export const removeMessage = (messageId, accountId) => {
  const messagesRef = getCollectionRef('messages', accountId);
  return messagesRef.doc(messageId).delete();
};

export const updateMessage = (messageId, message, accountId) => {
  const messagesRef = getCollectionRef('messages', accountId);
  return messagesRef.doc(messageId).update(message);
};
