//Suppliers for USERS interface
//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Text, Table, useTheme, Strong } from 'evergreen-ui';

//Components
import Page from '../../components/Page/Page';
import Block from '../../components/ui/Block/Block';
import Button from '../../components/ui/Button/Button';
//import DataTable from '../../components/DataTable/DataTable';
import RequestSupplier from './RequestSupplier';
import IconWrapper from '../../components/Icons/Icons';
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';
import TutoLink from '../../components/TutoLink/TutoLink';
import useSuppliersAuth from '../../hooks/useSuppliersAuth';
import PermissionChecker from '../../components/PermissionChecker/PermissionChecker';

//Files
import { actions } from '../../store/actions';
import { current } from '../../utils/selectors';

//TODO - change the current table to the default DataTable component
/*const tableHeaders = (supplier) => [
  { label: 'Name', field: 'name', type: 'text', },
  { label: 'Edit', field: 'edit', type: 'action', icon: 'edit', buttonProps: { appearance: 'default' }, },
  { label: 'Archive', field: 'archive', type: 'action', icon: supplier.archive ? 'lock' : 'unlock', buttonProps: { appearance: supplier.archive ? 'danger' : 'primary'}, },
];*/

const Suppliers = (props) => {
  useSuppliersAuth('suppliers');
  const theme = useTheme();
  const dispatch = useDispatch();

  const supplierList = useSelector((state) => {
    const suppliers = current(state, 'suppliers');
    
    // Sort by archive status first and then by name, handling potential undefined names
    const sortedSuppliers = _.sortBy(suppliers, [
      (s) => (s.archive ? 1 : 0), // Treat undefined or null as false, sort false before true
      (s) => s.name?.toLowerCase() || '', // Sort by name, handling undefined names
    ]);
  
    // Filter by status
    return _.filter(sortedSuppliers, (i) => !i.status || i.status === 'ACCEPTED');
  });
  
  const currentAccountId = useSelector((state) => state.currentAccount);

  const invoiceEmail = useSelector((state) => {
      const account = state.accounts[currentAccountId];
      return account ? account.userInvoiceEmail : null;
  });

  const [archiveSupplier, toggleArchiveSupplier] = useState(false);
  const [showSuppliers, toggleShowSuppliers] = useState(false);

  const SupplierTypeCell = ({ supplier }) => {
    let displayText = '';
  
    if (supplier?.type === 'SLAVE') {
      displayText = 'by Misenplace';
    } else if (supplier?.type === 'INTERNAL' || supplier?.type === undefined) {
      displayText = 'Internally';
    }
  
    return (
      <Table.TextCell size={500}>{displayText}</Table.TextCell>
    );
  };

  return (
    <Page title="Suppliers">
      <CustomDialog
          isOpen={!!archiveSupplier}
          title={`${archiveSupplier.archive ? 'Una' : 'A'}rchive Supplier`}
          intent="danger"
          confirmLabel={`${archiveSupplier.archive ? 'Una' : 'A'}rchive`}
          onConfirm={() => {
            dispatch(actions.suppliers.toggleArchiveSupplier(archiveSupplier));
            toggleArchiveSupplier(false);
          }}
          onClose={() => toggleArchiveSupplier(false)}
      >
        <Text>Are you sure you wish to {`${archiveSupplier.archive ? 'una' : 'a'}rchive`} the supplier {archiveSupplier.name}?</Text>
      </CustomDialog>

      <CustomDialog
          isOpen={showSuppliers}
          title={`Request suppliers`}
          intent="danger"
          confirmLabel={`Close`}
          onConfirm={() => {
            toggleShowSuppliers(false);
          }}
          onClose={() => toggleShowSuppliers(false)}
          onCloseComplete={() => toggleShowSuppliers(false)}
          hasCancel={false}
      >
        <Pane marginBottom={majorScale(1)}>
          <RequestSupplier
            supplierList={supplierList}
            callClose={() => toggleShowSuppliers(false)}
          />
        </Pane>
      </CustomDialog>

      <Block
          width="100%"
          maxWidth={600}
          marginBottom={majorScale(2)}
      >
        <Pane
            display="flex"
            alignItems="center"
            marginY={majorScale(2)}
            paddingX={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Suppliers</CustomHeading>
          <Button
              onClick={() => toggleShowSuppliers(true)}
              appearance="primary"
              marginLeft={majorScale(2)}
          >
            Request suppliers
          </Button>
          <PermissionChecker requiredPermission='uploadSuppliersCsv'>
          <Button
              is={Link}
              to="/supplier-ingredient-upload"
              appearance="minimal"
              marginLeft={majorScale(2)}
          >
            Upload CSV
          </Button>
          </PermissionChecker>
        </Pane>
        {invoiceEmail && (
          <Pane paddingLeft={majorScale(2)} paddingBottom={majorScale(2)} width="100%" display="flex" justifyContent="flex-start">
            <Text><Strong>Your Invoice Email:</Strong> {invoiceEmail}</Text>
          </Pane>
        )}
        <Pane paddingLeft={majorScale(2)} paddingBottom={majorScale(2)} width="100%" display="flex" justifyContent="flex-start">
          <TutoLink tutorialLink="https://www.misenplace.ai/support/add-suppliers" title={`Watch demo video: how to add suppliers`}/>
        </Pane>

        {/*<DataTable 
            //tableProps={{ minHeight: TABLE_MIN_HEIGHT, }}
            headers={tableHeaders}
            items={supplierList}
            //onEdit={(item) => setLinkedIngredientId(item.id)}
            onArchive={(supplier) => toggleArchiveSupplier(supplier)}
            //filters={ingFilters}
            //onRowSelect={(item) => setLinkedIngredientId(item.id)}
        />*/}

        <Table>
          <Table.Head 
            height="48px" 
            paddingRight={0}
            backgroundColor={theme.colors.offwhite}
          >
            <Table.TextHeaderCell size="16px">Name</Table.TextHeaderCell>
            <Table.TextHeaderCell size="16px">Managed</Table.TextHeaderCell>
            <Table.TextHeaderCell size="16px" flexBasis={56} flexGrow={0}>Edit</Table.TextHeaderCell>
            <Table.TextHeaderCell size="16px" flexBasis={56} flexGrow={0}>Archive</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.isEmpty(supplierList) && (
              <Table.Row height="48px">
                <Table.TextCell size="16px" >No suppliers to display</Table.TextCell>
              </Table.Row>
            )}
            {_.map(supplierList, (supplier) => (
              <Table.Row
                  key={supplier.id}
                  height="48px"
              >
                <Table.TextCell size={500}>{supplier.name}</Table.TextCell>
                <SupplierTypeCell supplier={supplier} />
                <Table.Cell flexBasis={56} flexGrow={0}>
                  {!supplier.archive &&
                    <IconWrapper
                      name="edit"
                      appearance="clickable"
                      is={Link}
                      to={`/suppliers/${supplier.id}`}
                    />
                  }
                </Table.Cell>
                <Table.Cell flexBasis={56} flexGrow={0}>
                  <IconWrapper
                      appearance={!supplier.archive ? 'danger' : 'clickable'}
                      name={!supplier.archive ? 'lock' : 'unlock'}
                      onClick={() => toggleArchiveSupplier(supplier)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Block>
    </Page>
  );
};

export default Suppliers;
