//React
import React from 'react';

function Dashboard() {
  return (
    <div className="App">
     <p>Hello</p>
    </div>
  );
}

export default Dashboard;