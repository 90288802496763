import _ from 'lodash';

const NotEmpty = ({ value }) => {
  return !_.isEmpty(value);
};

const IsNumeric = ({ value }) => {
  if ( parseInt(value).toString() !== value ) {
    return false;
  }
  return _.isFinite(parseInt(value));
};

const IsFloat = ({ value }) => {
  if ( parseFloat(value).toString() !== value ) {
    return false;
  }
  return parseFloat(value);
};

const MinLength = ({ value, minLength }) => {
  if ( _.isString(value) ) {
    return ( value.length >= minLength );
  }
  return false;
};

export const validationRules = {
  NotEmpty: {
    rule: NotEmpty,
    msg: ({ label }) => `${label} cannot be empty`
  },
  IsNumeric: {
    rule: IsNumeric,
    msg: ({ label }) => `${label} must be numeric`
  },
  IsFloat: {
    rule: IsFloat,
    msg: ({ label }) => `${label} must be numeric`
  },
  MinLength: {
    rule: MinLength,
    msg: ({ label, minLength }) => `${label} must be at least ${minLength} characters long`,
    ruleOpts: {
      minLength: 10
    }
  }
};


const useValidation = (rules) => {
  const validate = (fieldname, value) => {
    if ( !rules[fieldname] ) {
      return true;
    }
    for ( let i = 0, ln = rules[fieldname].length; i < ln; i++ ) {
      const { rule, ruleOpts } = rules[fieldname][i];
      if ( !rule({ value, ...ruleOpts }) ) {
        return false;
      }
    }
    return true;
  };

  const isValid = (fieldname, value) => {
    return validate(fieldname, value);
  };

  const allValid = (values) => {
    for (const fieldname in rules) {
      if ( !isValid(fieldname, values[fieldname]) ) {
        return false;
      }
    }
    return true;
  };

  return {
    allValid,
    isValid
  };
};

export default useValidation;
