//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

//Libraries
import { majorScale, minorScale, Pane, SelectField, Text, TextInputField } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';

//Files
import { actions } from '../../../store/actions';
import { budgetSum, buildBudgetsErrors, buildInitBudgetData } from '../../../utils/budgets';
import { financialCurrency } from '../../../utils/format';


const BudgetTab = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const year = moment.utc().year();
  const [currentYear, setCurrentYear] = useState(year);
  const years = [year - 1, year, year + 1]
  const budgets = useSelector(state => (state.budgets[accountId] || []).filter(f => years.includes(parseInt(f.year))));
  console.log(budgets)
  const budget = budgets.find(f => parseInt(f.year) === parseInt(currentYear));
  const [budgetInfo, setBudgetInfo] = useState({});

  useEffect(() => {
    const budgetData = budget || buildInitBudgetData(currentYear);
    setBudgetInfo(budgetData);
  }, [budget, currentYear, setBudgetInfo]);

  const saveBudgets = () => {
    if (!validate()) {
      dispatch(actions.appMessageError('Periods are not valid.'));
      return;
    }
    if (budgetInfo.id) {
      dispatch(actions.budgets.updateBudget(accountId, budgetInfo))
    }
    else {
      dispatch(actions.budgets.addBudget(accountId, budgetInfo))
    }
  }

  const onFieldChange = (field, newValue) => {
    setBudgetInfo((prevValues) => ({ ...prevValues, [field]: newValue }));
  };

  const validate = () => {
    const errors = buildBudgetsErrors(budgetInfo);
    for (const key in errors) {
      if (!_.isEmpty(errors[key])) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <Pane display='flex' alignItems='center' height={70} paddingY={majorScale(1)} paddingX={majorScale(2)}>
        <Pane flex={1} >
          <SelectField
              // flex="0 0 200px"
              // label="Area name"
              maxWidth={130}
              placeholder="Select from areas ..."
              value={currentYear}
              onChange={(e) => setCurrentYear(e.target.value)}
          >
            {years.reverse().map((year) => (<option key={year} value={year}>{year}</option>))}
          </SelectField>
        </Pane>
        <Button
          onClick={saveBudgets}
          appearance="primary"
        >
          Save
        </Button>
      </Pane>
      
      <Pane>
        <Pane padding={minorScale(4)}>
          <CustomHeading level="4" marginBottom={majorScale(2)}>Year (budget)</CustomHeading>
          <Text>£{financialCurrency(budgetSum(budgetInfo))}</Text>
          <CustomHeading level="4" paddingY={majorScale(2)}>Period information</CustomHeading>
          <Pane>
          {[...Array(12).keys()].map(num => {
            const periodKey = `period_${num}`;
            const period = budgetInfo[periodKey] || {};
            const changePeriodVal = (field, value) => {
              if (value === '' || Number.isFinite(parseFloat(value))) {
                const newPeriod = {...period, [field]: value};
                onFieldChange(periodKey, newPeriod);
              }              
            }
            return (
              <Pane key={periodKey} paddingY={majorScale(2)}>
                <Pane marginBottom={minorScale(2)}>
                  <Text>Period {num + 1}</Text>
                </Pane>
                <Pane display='flex' alignItems='center'>
                  <TextInputField
                      // flex="1 1 0"
                      marginLeft={majorScale(2)}
                      placeholder="10000.00"
                      label={`Period ${num + 1} (budget)`}
                      value={period.value || ''}
                      onChange={(e) => changePeriodVal('value', e.target.value)}
                  />
                  <TextInputField
                      // flex="1 1 0"
                      marginLeft={majorScale(2)}
                      label={`Period ${num + 1} (target margin)`}
                      placeholder="75%"
                      value={period.margin || ''}
                      onChange={(e) => changePeriodVal('margin', e.target.value)}
                  />
                </Pane>
              </Pane>
            )
          })}
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export default BudgetTab;
