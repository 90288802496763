const ALLERGENS_LOAD_SUCCESS = 'ALLERGENS_LOAD_SUCCESS';
const ALLERGENS_LOAD_FAILURE = 'ALLERGENS_LOAD_FAILURE';

export const actionTypes = {
  ALLERGENS_LOAD_SUCCESS,
  ALLERGENS_LOAD_FAILURE
};


const allergensLoadSuccess = (payload) => {
  return {
    type: ALLERGENS_LOAD_SUCCESS,
    payload
  };
};

const allergensLoadFailure = (error) => {
  return {
    type: ALLERGENS_LOAD_FAILURE,
    error
  };
};

export const actions = {
  allergensLoadSuccess,
  allergensLoadFailure
};
