import { takeEvery, put, call, select } from 'redux-saga/effects';
import { addFinancial, updateFinancial, removeFinancial } from '../firebase/financials';
import { actions, actionTypes } from '../actions';
import { activeAccount } from '../../utils/selectors';

export default function* financialsSaga() {
  yield takeEvery(actionTypes.financials.ADD_FINANCIAL, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(addFinancial, action.financialInfo, accountId);
      yield put(actions.appMessageSuccess('Financial Added'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Financial could not be added'));
    }
  });
  yield takeEvery(actionTypes.financials.REMOVE_FINANCIAL, function* (action) {
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(removeFinancial, action.financialId, accountId);
      yield put(actions.appMessageSuccess('Financial Removed'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Financial could not be removed'));
    }
  });
  yield takeEvery(actionTypes.financials.UPDATE_FINANCIAL, function* (action) {
    const { id, ...financialInfo } = action.financialInfo;
    try {
      const accountId = yield action.accountId || select(activeAccount);
      yield call(updateFinancial, id, financialInfo, accountId);
      yield put(actions.appMessageSuccess('Financial Updated'));
      if (action.callback) {
        yield call(action.callback);
      }
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Financial could not be updated'));
    }
  });
};
