import store from '../redux';
import fb from './'
import { actions } from '../actions';
//
// Reference to the 'profiles' collection in Firestore
const profileRef = fb.firestore.collection('profiles')
let profileListener

// Function to create a new profile with default values based on user data
const createNewProfile = (user) => {
  const profile = {
    currentaccount: null,
    email: user.email,
    name: user.displayName,
    provider: 'google',
    uid: user.uid
  }
  addProfile(user.uid, profile)
  profile.id = user.uid
  return profile
}

// exported methods
// Function to load a user's profile from Firestore
export const loadProfile = (user) => {
  if (!user || !user.uid) {
    console.error('Whoops - incomplete user info provided!', user);
    return;
  }

  if (profileListener) {
    profileListener(); // Unsubscribe from any existing profile listener
  }

  console.log(`loading: profile/${user.uid}`)
  store.dispatch(actions.applicationLoadStart(`profile:${user.uid}`)); // Indicates loading start

  // Sets up a real-time listener for the user's profile document in Firestore
  profileListener = profileRef.doc(user.uid).onSnapshot((doc) => {
    const profile = (doc.exists) ? { id: doc.id, ...doc.data() } : createNewProfile(user);
    //Here we extract current account from the profile, this is key too!
    const currentaccount = profile.currentaccount;

    // Dispatch actions for successful profile loading and setting the current account
    store.dispatch(actions.accounts.currentAccountSet(currentaccount));
    store.dispatch(actions.profile.profileLoadSuccess(profile));

    // Optionally, log the loading of related account users here or in the respective logic
    //console.log(`Loading account users related to: ${currentaccount}`);
  });
}

// Function to clear the profile data from the application state
export const clearProfile = () => {
  store.dispatch(actions.profile.profileClear());
}

// Function to add a profile to Firestore
export const addProfile = (profileId, profile) => {
  // Adds or merges the profile document in Firestore
  return profileRef.doc(profileId).set(profile, {merge: true})
}

// Function to remove a profile from Firestore
export const removeProfile = (profileId) => {
  return profileRef.doc(profileId).delete()
}

// Function to update a profile in Firestore
export const updateProfile = (profileId, profile) => {
  return profileRef.doc(profileId).update(profile)
}
