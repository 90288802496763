//React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as LinkRouter, useNavigate, useParams } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { majorScale, Pane, Paragraph, Text, Table, UnorderedList, ListItem, Link, FilePicker, useTheme } from 'evergreen-ui';

//Components
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import { colors } from '../../../utils/constants';
import { shortDateDisplayFormat, currency } from '../../../utils/format';
import { calcOrderTotal, createOrderNumber, supplierOptionUOM } from '../../../utils/functions';
import { current } from '../../../utils/selectors';
import { actions } from '../../../store/actions/index';


const SupplierOrderDetail = () => {
  const { accountId, orderId } = useParams();
  const theme = useTheme();
  const goBack = window?.history ? () =>  window?.history.back() : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState([]);

  const orderInfo = useSelector((state) => _.find(current(state, 'orders', accountId), { 'id': orderId }));
  const supplier = useSelector((state) => (!_.isEmpty(orderInfo) ? _.find(current(state, 'suppliers', accountId), { 'id': orderInfo.supplierId }) : false));
  const orderAttachments = useSelector((state) => _.filter(current(state, 'orderAttachments', accountId), { 'orderId': orderId }));
  const supplierOptionMap = useSelector((state) => _.keyBy(
    current(state, 'supplierOptions', accountId),
    'id'
  ));
  const account = useSelector((state) => {
    if (!_.isEmpty(state.accounts)) {
      return (state.accounts[accountId]);
    }
    return null;
  });
  const addAttachments = (files) => {
    setAttachments((prevAttachments) => {
      return [
        ...prevAttachments,
        ...(_.map(files, (file) => ({ file })))
      ];
    });
  };
  const removeAttachment = (index) => {
    setAttachments((prevAttachments) => {
      if (prevAttachments[index]) {
        return [
          ..._.slice(prevAttachments, 0, index),
          ..._.slice(prevAttachments, index + 1)
        ];
      }
      return prevAttachments;
    });
  };

  const sendAttachments = () => {
    dispatch(actions.orderAttachments.updateOrderAttachments(
      accountId,
      orderId, 
      attachments,
      navigate(`/${accountId}/review/supplier-statements/${orderInfo.supplierId}`)
    ));
  };

  if (_.isEmpty(orderInfo)) {
    return (
      <Block padding={majorScale(2)}>
        <CustomHeading level="3">Order Not Found</CustomHeading>
      </Block>
    );
  }
  if (_.isEmpty(supplier)) {
    return (
      <Block padding={majorScale(2)}>
        <CustomHeading level="3">Supplier Not Found</CustomHeading>
      </Block>
    );
  }

  return (
    <React.Fragment>
      <Block
          background={theme.colors.offwhite}
          height={48}
          display="flex"
          alignItems="center"
          marginBottom={majorScale(2)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
      >
        {goBack && (
          <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            onClick={goBack}
            marginRight={majorScale(2)}
          />
        )}
        {!goBack && (
          <IconWrapper
            appearance="clickable"
            name="arrowLeft"
            is={LinkRouter}
            to={`/${accountId}/archive/supplier-statements/${supplier.id}`}
            marginRight={majorScale(2)}
          />
        )}
        <CustomHeading level="3">{supplier.name}</CustomHeading>
      </Block>

      <Block flex="1 0 auto" display="flex" flexDirection="column" marginBottom={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="flex-start"
            padding={majorScale(2)}
            marginBottom={majorScale(2)}
        >
          <Pane flex="1 0 auto" marginRight={majorScale(2)}>
            <CustomHeading level="4" marginBottom={majorScale(2)}>Delivery Date</CustomHeading>
            <Paragraph marginLeft={majorScale(2)}>{shortDateDisplayFormat((orderInfo.deliveryDate) ? orderInfo.deliveryDate : orderInfo.date)}</Paragraph>
          </Pane>
          <Pane flex={2}>
            <CustomHeading level="4" marginBottom={majorScale(2)}>Total Cost</CustomHeading>
            <Paragraph
                marginLeft={majorScale(2)}
                color={colors.orange}
                fontSize={24}
                lineHeight="28px"
            >£ {currency(calcOrderTotal(orderInfo))}</Paragraph>
          </Pane>
        </Pane>
        <Pane padding={majorScale(2)} marginBottom={majorScale(2)}>
          <CustomHeading level="4"  marginBottom={majorScale(2)}>Price Breakdown</CustomHeading>
          <Pane
            display="flex"
            justifyContent="space-between"
            marginX={majorScale(2)}
            marginBottom={majorScale(2)}
          >
            <Pane
              flex="1 0 0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginRight={majorScale(4)}
              maxWidth={240}
            >
              <Text>Delivery Charge</Text>
              <Text color={colors.lightTeal}>£ {currency(orderInfo.delivery_charge || 0)}</Text>
            </Pane>
            <Pane
              flex="1 0 0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginRight={majorScale(4)}
              maxWidth={240}
            >
              <Text>Discount £</Text>
              <Text color={colors.lightTeal}>£ {currency(orderInfo.flat_discount || 0)}</Text>
            </Pane>
            <Pane
              flex="1 0 0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              maxWidth={240}
            >
              <Text>Discount %</Text>
              <Text color={colors.lightTeal}>{orderInfo.pc_discount || 0} %</Text>
            </Pane>
          </Pane>
          <Pane
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginX={majorScale(2)}
              marginBottom={(!_.isEmpty(orderAttachments)) ? majorScale(2) : 0}
          >
            <Text width="15%">Comment</Text>
            {orderInfo.receiving_comments && (
              <Text flex="1 0 auto" color={colors.lightTeal}>{orderInfo.receiving_comments}</Text>
            )}
            {_.isEmpty(orderInfo.receiving_comments) && (
              <Text flex="1 0 auto" color={colors.lightTeal}><em>No comment</em></Text>
            )}
          </Pane>
          <Pane>
            <CustomHeading level="4"  marginBottom={majorScale(2)}>Attachments</CustomHeading>
              {!_.isEmpty(orderAttachments) && (
                <Pane marginLeft={majorScale(2)}>
                  <UnorderedList marginLeft={0}>
                    {_.map(orderAttachments, (attachment, index) => (
                      <ListItem key={index}>
                        <Link
                            href={attachment.main_imageURL}
                            download={attachment.filename}
                            target="_blank"
                        >{attachment.filename}</Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Pane>
              )}
            <Pane marginLeft={majorScale(2)}>
              {attachments && (
                <UnorderedList marginLeft={0} marginBottom={majorScale(2)}>
                  {_.map(attachments, (attachment, index) => (
                    <ListItem
                        key={index}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                      {attachment.original_imagefilename && (
                        <Link
                            href={attachment.original_imagefilename}
                            target="_blank"
                        >{attachment.filename}</Link>
                      )}
                      {!attachment.original_imagefilename && (
                        <Text>{attachment.file.name}</Text>
                      )}
                      <IconWrapper
                          marginLeft={majorScale(2)}
                          name="cross"
                          appearance="clickable"
                          intent="danger"
                          onClick={() => removeAttachment(index)}
                      />
                    </ListItem>
                  ))}
                </UnorderedList>
              )}
              <Pane display='flex'>
                <FilePicker
                  multiple
                  accept="image/*;capture=camera"
                  onChange={(files) => addAttachments(files)}
                  width={260}
                />
                {!_.isEmpty(attachments) && (
                  <Button onClick={sendAttachments} marginLeft={majorScale(2)}>
                    Approve
                  </Button>
                )}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
        <Pane padding={majorScale(2)} marginBottom={majorScale(2)}>
          <CustomHeading level="4"  marginBottom={majorScale(2)}>Record</CustomHeading>
          <Pane
              display="flex"
              justifyContent="space-between"
              marginX={majorScale(2)}
              marginBottom={majorScale(2)}
          >
            <Pane
                flex="1 0 0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginRight={majorScale(4)}
                maxWidth={240}
            >
              <Text>Invoice Number</Text>
              <Text color={colors.lightTeal}>{orderInfo.invoice_number}</Text>
            </Pane>
          </Pane>
          <Pane
              display="flex"
              justifyContent="space-between"
              marginX={majorScale(2)}
              marginBottom={majorScale(2)}
          >
            <Pane
                flex="1 0 0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginRight={majorScale(4)}
                maxWidth={240}
            >
              <Text>PO Number</Text>
              <Text color={colors.lightTeal}>{createOrderNumber(orderInfo, account)}</Text>
            </Pane>
          </Pane>
        </Pane>          
        <Table>
          <Table.Head height={48}>
            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>UOM</Table.TextHeaderCell>
            <Table.TextHeaderCell>Qty</Table.TextHeaderCell>
            <Table.TextHeaderCell>Price</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {_.map(orderInfo.items, (item) => {
              const ing = supplierOptionMap[item.id];
              return (
                <Table.Row height={48} key={item.id}>
                  <Table.TextCell>{ing.name}</Table.TextCell>
                  <Table.TextCell>{supplierOptionUOM(ing)}</Table.TextCell>
                  <Table.TextCell>{(item.quantityreceived || item.quantityreceived === 0) ? item.quantityreceived : item.quantity}</Table.TextCell>
                  <Table.TextCell>£ {currency((item.finalprice || ing.unitprice))}</Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Block>
    </React.Fragment>
  );
};

export default SupplierOrderDetail;
