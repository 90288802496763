//React
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { majorScale, minorScale, Pane, Table, Text, Checkbox, TextInput } from 'evergreen-ui';

//Components
import FilterSelect from "../../../components/FilterSelect/FilterSelect";
import Block from '../../../components/ui/Block/Block';
import Button from '../../../components/ui/Button/Button';
import CommentPane from './CommentPane';
import CustomHeading from '../../../components/Headings/Headings';
import IconWrapper from '../../../components/Icons/Icons';

//Files
import firebase from '../../../store/firebase';
import { actions } from '../../../store/actions';
import { current } from '../../../utils/selectors';


const hasDueDateToday = (item, givenDate) => {
  // Check for a due date (equal to the given date)
  if (!item.dueDate || !moment(item.dueDate.toDate()).isSame(givenDate, 'day')) {
    // No due date for the item, check for any steps
    if (!_.isEmpty(item.steps)) {
      // If the item has steps, check if any steps have a due date matching the given date
      return _.reduce(item.steps, (bool, step) => (bool || hasDueDateToday(step, givenDate)), false);
    }
    return false;
  }
  return true;
}


const PrepTab = ({ date:currentDate, DatePanel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const recipes = useSelector((state) => current(state, 'recipes', accountId));
  const prepTaskMasterList = useSelector((state) => current(state, 'prepTasks', accountId));
  const prepTasksDue = useSelector((state) => {
    // Filter tasks for 'PREP' type, and occurring on the given day
    return _.filter(current(state, 'tasks', accountId), (item) => {
      if (item.type !== 'PREP') {
        return false;
      }
      if (!hasDueDateToday(item, currentDate)) {
        return false;
      }
      return true;
    });
  });
  // Merge any due tasks into the master list
  // NOTE: This overwrites the id field for when we update!
  const prepTasks = (_.isEmpty(prepTasksDue)) ? prepTaskMasterList : _.map(prepTaskMasterList, (task) => {
    const dueTask = _.find(prepTasksDue, { 'prepId': task.id });
    return (_.isEmpty(dueTask)) ? task : { ...task, ...dueTask };
  });

  const sectionMap = useSelector((state) => {
    return _.keyBy(
      _.filter(current(state, 'sections', accountId), { type: 'PREP' }),
      'name'
    );
  });

  const sections = _.map(
    _.sortBy(
      sectionMap,
      (section) => (_.lowerCase(section.displayName || section.name))
    ),
    (section) => ({ label: section.displayName || section.name, value: section.name })
  );

  const [sectionFilter, setSectionFilter] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const getSectionName = (section) => {
    let sectionInfo = sectionMap[section];
    if (sectionInfo) {
      return sectionInfo.displayName || sectionInfo.name;
    }
    return section;
  }

  const toggleComplete = (task) => {
    if (!task.completed) {
      // No complete dates yet, makes updating easy
      dispatch(actions.tasks.updateTask(accountId, { ...task, completed: currentDate }))
    }
    else {
      // Drop the current completed date
      const { completed, ...otherTaskInfo } = task
      dispatch(actions.tasks.updateTask(accountId, { ...otherTaskInfo, completed: firebase.FieldValue.delete() }));
    }
  };

  const toggleStepComplete = (task, stepIndex) => {
    const steps = [...task.steps];  // Copy the array, so we're not changing it directly!
    const step = steps[stepIndex];
    if (!step.completed) {
      // No complete dates yet, makes updating easy
      step.completed = currentDate;
    }
    else {
      // Drop the current completed date
      const { completed, ...otherStepInfo } = step;
      steps[stepIndex] = { ...otherStepInfo };
    }
    dispatch(actions.tasks.updateTask(accountId, { ...task, steps }));
  };

  const setTaskDueDate = (task, newDueDate, isChecked) => {
    // We're toggling the task "on" for the given date
    if (isChecked) {
      // First check if the task is in the due list
      if (_.isEmpty(task.prepId)) {
        // Not yet - let's add it!
        const { id, ...taskInfo } = task;
        dispatch(actions.tasks.addTask(accountId, { ...taskInfo, prepId: id, dueDate: newDueDate }));
      }
      // Otherwise check if the due date has actually changed
      else if (task.dueDate !== newDueDate) {
        dispatch(actions.tasks.updateTask(accountId, { ...task, dueDate: newDueDate }));
      }
    }
    // We're toggling the task "off" for the given date - if it's not in the due list, 
    //  then we've nothing to do here
    else if (!_.isEmpty(task.prepId)) {
      // Check if any steps have a due date
      const { dueDate, ...taskInfo } = task;
      if (hasDueDateToday(taskInfo)) {
        // One or more steps have a due date still - just update the task (removing the due date)
        dispatch(actions.tasks.updateTask(accountId, { ...taskInfo, dueDate: firebase.FieldValue.delete() }));
      }
      else {
        // No steps with a due date - remove the task entirely
        dispatch(actions.tasks.removeTask(accountId, taskInfo.id));
      }
    }
  };

  const setStepDueDate = (task, stepIndex, newDueDate, isChecked) => {
    const steps = [...task.steps];  // Copy the array, so we're not changing it directly
    const step = steps[stepIndex];
    // We're toggling the step "on" for the given date
    if (isChecked) {
      // First check if the task is in the due list
      if (_.isEmpty(task.prepId)) {
        // It isn't, so add the task
        const { id, ...taskInfo } = task;
        steps[stepIndex] = { ...step, dueDate: newDueDate};
        dispatch(actions.tasks.addTask(accountId, { ...taskInfo, prepId: id, steps }));
      }
      // Otherwise, check if the due date has actually changed
      else if (step.dueDate !== newDueDate) {
        steps[stepIndex] = { ...step, dueDate: newDueDate};
        dispatch(actions.tasks.updateTask(accountId, { ...task, steps }));
      }
    }
    // We're toggling the step "off" for the given date
    else {
      const { dueDate, ...stepInfo } = step;
      steps[stepIndex] = stepInfo;
      // Check for due dates on the task and other steps
      if (hasDueDateToday({ ...task, steps })) {
        // The task or other steps have a due date, so update the task
        dispatch(actions.tasks.updateTask(accountId, { ...task, steps }));
      }
      else {
        // No due dates, so just remove the task entirely
        dispatch(actions.tasks.removeTask(accountId, task.id));
      }
    }
  };

  const setTaskQty = (task, qty) => {
    if (!_.isEmpty(task.prepId)) {
      dispatch(actions.tasks.updateTask(accountId, { ...task, qty }));
    }
  };

  const setTaskStepQty = (task, stepIndex, qty) => {
    const steps = [...task.steps];
    const step = steps[stepIndex];
    if (!_.isEmpty(task.prepId)) {
      steps[stepIndex] = { ...step, qty };
      dispatch(actions.tasks.updateTask(accountId, { ...task, steps }));
    }
  };

  const handleSetTaskQty = useCallback(_.debounce(setTaskQty, 300),[]);
  const handleSetTaskStepQty = useCallback(_.debounce(setTaskStepQty, 300),[]);

  const goToRecipe = (item) => {
    const recipe = recipes.find(r => r.name.toLowerCase() === item.recipe.toLowerCase());
    if (!recipe) return;

    navigate(`/${accountId}/front-of-house/recipes/${recipe.id}`, false, {batch: item.qty || 1})
  }

  const flexSize = isMobile ? '0 1 50%' : '0 1 75%';

  const renderTaskRows = (task) => {
    // TODO: Link recipes to their respective page
    const isComplete = !!task.completed;
    const dueDate = (task.dueDate) ? task.dueDate.toDate() : false;
    const isDueToday = dueDate && moment(dueDate).isSame(currentDate, 'day');
    const qty = task.qty

    return (
      <React.Fragment key={task.id}>
        <Table.Row height={48}>
          <Table.Cell flex={flexSize}>
            <Text size={300}>{task.recipe || task.action}</Text>
          </Table.Cell>
          {editMode && (
            <Table.Cell justifyContent='center' key="today">
              <Checkbox
                  disabled={isComplete}
                  checked={isDueToday}
                  onChange={(e) => setTaskDueDate(task, currentDate, e.target.checked)}
              />
            </Table.Cell>
          )}
          {!editMode && (
            <Table.Cell justifyContent='center' key="actions">
              {isDueToday && (
                <Checkbox
                    //name="tick"
                    //appearance={isComplete ? 'primary' : 'default'}
                    //intent={isComplete ? 'warning' : 'none'}
                    checked={isComplete}
                    onChange={() => toggleComplete(task)}
                />
              )}
            </Table.Cell>
          )}
          <Table.Cell justifyContent='center' key="qty">
            {editMode && (
              <TextInput
                  value={qty || (isDueToday ? 1 : '')}
                  onChange={(e) => handleSetTaskQty(task, e.target.value)}
                  // isInvalid={!isValid('temperature', po.temperature)}
                  disabled={!isDueToday}
                  maxWidth="100%"
                  width="6rem"
                  maxLength={3}
              />
            )}
            {!editMode && isDueToday && (
              <Text size={300}>{qty || (isDueToday ? 1 : '')}</Text>
            )}
          </Table.Cell>
          {!editMode && isDueToday && (
            <Table.Cell justifyContent='center' key="viewRecipe">
              {!editMode && task.recipe && (
                <IconWrapper
                    name="clipboard"
                    appearance='clickable'
                    onClick={() => goToRecipe(task)}
                />
              )}
            </Table.Cell>
          )}
        </Table.Row>
        {_.map(task.steps, (step, index) => renderStepRow(task, step, index))}
      </React.Fragment>
    );
  };

  const renderStepRow = (task, item, index) => {
    const stepComplete = !!item.completed;
    const stepDueDate = (item.dueDate) ? ((item.dueDate.toDate) ? item.dueDate.toDate() : item.dueDate) : false;
    const isDueToday = stepDueDate && moment(stepDueDate).isSame(currentDate, 'day');
    const qty = item.qty;

    if (!stepDueDate && !editMode) {
      // Don't render the step row if it doesn't have a due date and we're not editing
      return null;
    }

    return (
      <Table.Row height={48} key={`${task.id}-${index}`}>
        <Table.Cell flex={flexSize}>
          <Text marginLeft={minorScale(8)} size={300}>{item.recipe || item.action}</Text>
        </Table.Cell>
        {editMode && (
          <Table.Cell justifyContent='center'>
            <Checkbox
                disabled={stepComplete}
                checked={isDueToday}
                onChange={(e) => setStepDueDate(task, index, currentDate, e.target.checked)}
            />
          </Table.Cell>
        )}
        {!editMode && (
          <Table.Cell justifyContent='center'>
            <Checkbox
                //name='tick'
                //appearance={stepComplete ? 'primary' : 'default'}
                //intent={stepComplete ? 'warning' : 'none'}
                checked={stepComplete}
                onChange={() => toggleStepComplete(task, index)}
            />
          </Table.Cell>
        )}
        <Table.Cell key="qty" justifyContent='center'>
          {editMode && (
            <TextInput
                value={qty || ''}
                onChange={(e) => handleSetTaskStepQty(task, index, e.target.value)}
                // isInvalid={!isValid('temperature', po.temperature)}
                maxWidth="100%"
                width="6rem"
                maxLength={3}
                disabled={!isDueToday}
            />
          )}
          {!editMode && isDueToday && (
            <Text size={300}>{qty || (isDueToday ? 1 : '')}</Text>
          )}
        </Table.Cell>
        {!editMode && isDueToday && (
          <Table.Cell key="viewRecipe" justifyContent='center'>
            {!editMode && item.recipe && (
              <IconWrapper
                  name="clipboard"
                  appearance='clickable'
                  onClick={() => goToRecipe(item)}
              />
            )}
          </Table.Cell>
        )}
      </Table.Row>
    );
  };

  const taskList = (editMode) ? prepTasks : prepTasksDue;
  const filteredTasks = _.groupBy(
    _.sortBy(
      (sectionFilter) ? _.filter(taskList, { section: sectionFilter }) : taskList,
      (task) => (_.lowerCase(task.section))
    ),
    'section'
  );

  //Main page
  return (
    <React.Fragment>
      <Block marginBottom={majorScale(2)} padding={majorScale(2)}>
        <Pane
            display="flex"
            alignItems="center"
            marginBottom={majorScale(2)}
        >
          <CustomHeading level="3" flex="1 0 auto">Preparation lists</CustomHeading>
          <Button
              appearance={(editMode) ? 'primary' : 'minimal'}
              iconBefore={(editMode) ? 'confirm' : 'edit'}
              marginLeft={majorScale(2)}
              onClick={() => setEditMode(!editMode)}
          >{(editMode) ? 'Save' : 'Edit'} Prep</Button>
        </Pane>
        {DatePanel}
        <Pane>
          <FilterSelect
              name="section"
              label="Section"
              options={sections}
              selected={_.find(sections, { value: sectionFilter })}
              setFilter={(name, value) => setSectionFilter(value)}
          />
        </Pane>
      </Block>
      <Block>
      <>
      {_.isEmpty(filteredTasks) ? (
        <Block padding={majorScale(2)}>
          <Text>No tasks to display</Text>
        </Block>
      ) : (
        _.map(filteredTasks, (tasklist, section) => (
        <Block key={section} marginBottom={majorScale(2)}>
          <CustomHeading level="4" padding={majorScale(2)}>{getSectionName(section)}</CustomHeading>
          <Table>
            <Table.Head height={48}>
              <Table.TextHeaderCell flex={flexSize}>Tasks</Table.TextHeaderCell>
              {editMode && (
                <Table.TextHeaderCell>Today</Table.TextHeaderCell>
              )}
              {!editMode && (
                <Table.TextHeaderCell>Progress</Table.TextHeaderCell>
              )}
              <Table.TextHeaderCell>Batch{isMobile ? '' : ' to make'}</Table.TextHeaderCell>
              {!editMode && (
                <Table.TextHeaderCell>View{isMobile ? '' : ' recipe'}</Table.TextHeaderCell>
              )}
            </Table.Head>
            <Table.Body>
              {_.map(tasklist, renderTaskRows)}
            </Table.Body>
          </Table>
          {!editMode && (
            <CommentPane
              accountId={accountId}
              date={currentDate}
              type={`PREP_${section}`}
            />
          )}
          </Block>
          ))
        )}
        </>
      </Block>
    </React.Fragment>
  );
};

PrepTab.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  DatePanel: PropTypes.node.isRequired,
};

export default PrepTab;
